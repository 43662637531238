import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Input, Form, Image } from "antd";
import BaseUpload from "../../baseUpload";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import { getBase64 } from "../../../utils/fnUtil";
import {
  actions,
  updateNotification,
} from "../../../slices/notificationsSlice";
import "./style.scss";

const NotificationEditModal = () => {
  const dispatch = useDispatch();
  const { notificationEditModalVisible, activeNotification } = useSelector(
    (state) => state.notifications
  );
  const [errors, setErrors] = useState({ title: false, message: false, first: false });
  const [form] = Form.useForm();
  // console.log("aaa", activeNotification);
  const initialValues = {
    title: activeNotification?.title || "",
    message: activeNotification?.message || "",
    text: activeNotification?.text || "",
    link: activeNotification?.link || "",
    image_url: activeNotification?.image_url || [
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
    ],
  };

  const [imageUrls, setImageUrls] = useState(initialValues.image_url);

  useEffect(() => {
    form.setFieldsValue(initialValues);
    setImageUrls(initialValues.image_url);
    setErrors({ title: false, message: false, first: !activeNotification?.title });
  }, [notificationEditModalVisible]);

  const getImagesJsx = (from, to) => {
    return imageUrls.map((image, i) => {
      if (i < from || to < i) return;
      return image ? (
        <Form.Item noStyle>
          <div>
            <div className="uploadImageBox">
              <Image src={image} width={130} height={130} preview={false} />
              <Icon
                component={Icons.CloseIcon}
                style={{
                  fontSize: 20,
                  position: "absolute",
                  left: 115,
                  top: -10,
                  zIndex: 100,
                }}
                onClick={() => {
                  let newImages = [...imageUrls];
                  newImages[i] = "";
                  setImageUrls(newImages);
                }}
              />
            </div>
            <div className="imageText">{"画像" + (i + 1)}</div>
          </div>
        </Form.Item>
      ) : (
        <Form.Item noStyle>
          <div>
            <div className="uploadImageBox">
              <BaseUpload
                showUploadList={false}
                onChange={(url) => {
                  let newImages = [...imageUrls];
                  newImages[i] = url;
                  setImageUrls(newImages);
                }}
                style={{ margin: 5 }}
              >
                <Button
                  text="画像を追加"
                  theme="white"
                  style={{
                    width: 120,
                    position: "absolute",
                    top: 70,
                    left: -5,
                  }}
                />
              </BaseUpload>
            </div>
            <div className="imageText">{"画像" + (i + 1)}</div>
          </div>
        </Form.Item>
      );
    });
  };
  const isError = (name) => {
    const inputVals = form.getFieldsValue();
    const result = !inputVals.title || !inputVals.message
    if (name) {
      setErrors({ ...errors, [name]: (!inputVals[name]), first: result });
    } else {
      setErrors({ title: (!inputVals.title), message: (!inputVals.message), first: result });
    }
    return result;
  };
  const submit = async () => {
    const inputVals = form.getFieldsValue();
    if (isError()) return;
    let param = {
      division: activeNotification?.title ? "update" : "insert",
      title: inputVals.title,
      message: inputVals.message,
      text: inputVals.text,
      link: inputVals.link,
      images: imageUrls,
    };
    if (param.division === "update")
      param.document_id = activeNotification?.document_id;
    dispatch(updateNotification(param));
  };
  return (
    <>
      <Modal
        visible={notificationEditModalVisible}
        width={900}
        footer={null}
        closable={false}
        maskClosable={true}
        onCancel={() => dispatch(actions.closeEditModal())}
        destroyOnClose={true}
      >
        <div className="notificationEditModal-area">
          <div className="title-area">
            <span className="text-title">
              {activeNotification?.title === undefined
                ? "お知らせ登録"
                : "お知らせ編集"}
            </span>
            <span className="nf-text-required-style">　*は必須項目です</span>
          </div>
          <div className="form-area">
            <Form
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
              form={form}
              colon={false}
              name="editForm"
              initialValues={initialValues}
            >
              {errors.title && (<div className="required-text error-title">必須項目です</div>)}
              <Form.Item
                label={
                  <label className="notificationEditModal-form-title text-required">
                    タイトル
                  </label>
                }
                name="title"
              >
                <Input
                  bordered={true}
                  placeholder={"記事タイトルを入力してください"}
                  onBlur={() => { isError('title'); }}
                  onChange={() => { isError('title'); }}
                />
              </Form.Item>
              {errors.message && (<div className="required-text error-message">必須項目です</div>)}
              <Form.Item
                label={
                  <label className="notificationEditModal-form-title text-required">
                    メッセージ
                  </label>
                }
                name="message"
              >
                <Input
                  bordered={true}
                  placeholder={"記事メッセージを入力してください"}
                  onBlur={() => { isError('message'); }}
                  onChange={() => { isError('message'); }}
                />
              </Form.Item>
              <Form.Item
                label={
                  <label className="notificationEditModal-form-title">
                    本文
                  </label>
                }
                tooltip={
                  <label style={{ whiteSpace: "pre-wrap", fontSize: "12px" }}>
                    画像埋め込み：%image1% ~ %image10%{'\r\n'}{'\r\n'}
                    埋め込みタグ{'\r\n'}
                    リンク{'\r\n'}
                    例) %html%{'<a href="https://xxxx.jp">リンク</a>'}%/html%{'\r\n'}
                    文字色変更{'\r\n'}
                    例) %html%{'<p style="color: blue;">青色</p>'}%/html%
                  </label>
                }
                name="text"
              >
                <Input.TextArea
                  rows={5}
                  placeholder={"本文を入力してください"}
                />
              </Form.Item>
              <Form.Item
                label={
                  <label className="notificationEditModal-form-title">
                    外部リンク
                  </label>
                }
                name="link"
              >
                <Input
                  bordered={true}
                  placeholder={"外部リンクを入力してください"}
                />
              </Form.Item>
              <Form.Item
                label={
                  <label className="notificationEditModal-form-title">
                    画像
                  </label>
                }
              >
                <div className="uploadImageRow" style={{ marginBottom: 5 }}>
                  {getImagesJsx(0, 4)}
                </div>
                <div className="uploadImageRow">{getImagesJsx(5, 9)}</div>
              </Form.Item>
            </Form>
          </div>
          <div className="btn-area">
            <Button
              text="戻る"
              theme="white"
              onClick={() => dispatch(actions.closeEditModal())}
            />
            <Button text={activeNotification?.title ? "更新":"登録" }
              disabled={errors.title || errors.message || errors.first}
              onClick={() => submit()} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NotificationEditModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { actions as loadingActions } from "./loadingSlice";

const createFormData = (params) => {
  const formData = new FormData();
  formData.append("csv_file", params.upload_file);
  formData.append(
    "jsonValue",
    new Blob([JSON.stringify(params)], { type: "application/json" })
  );
  return formData;
};

export const notificationsInit = createAsyncThunk(
  "notificationsInit",
  async (params, {dispatch}) => {
    dispatch(loadingActions.multiLoadingOn());
    const [noticeRes, shopsRes, brandsRes] = await Promise.all([
      axios.get("notice/document/list", { params: initialSearchParams }),
      axios.get("shops/list",{params: { count: 0, sort : "shop_code_asc" }}),
      axios.get("brands/list",{params: { count: 0, sort : "brand_name_asc" }}),
    ]);
    dispatch(loadingActions.multiLoadingOff());
    return {
      notificationsList: noticeRes?.item ?? [],
      brands: (brandsRes?.item ?? []).map((b) => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      shops: (shopsRes?.item ?? []).map((s) => ({
        ...s,
        label: s.shop_code + ":" + s.shop_name,
        value: s.shop_code,
      })),
      total: noticeRes?.total ?? 0,
    };
  }
);

export const fetchNotifications = createAsyncThunk(
  "fetchNotifications",
  async (params) => await axios.get("notice/document/list", { params })
);

export const fetchNotificationDetail = createAsyncThunk(
  "fetchNotificationDetail",
  async (params) => await axios.get("notice/document/detail", { params })
);

export const updateNotification = createAsyncThunk(
  "updateNotification",
  async (params) => {
    let res = await axios.post("notice/document/update", params);
    return (res.division = params.division);
  }
);

export const fetchNotificationReserveDetail = createAsyncThunk(
  "fetchNotificationReserveDetail",
  async (params) => {
    let res = await axios.get("notice/reserve/detail", { params });
    return res?.item ?? { document_id: params.document_id };
    // return { document_id: "0001" };
  }
);

export const updateReserve = createAsyncThunk(
  "updateReserve",
  async (params) => {
    const res = await axios.post(
      "/notice/reserve/update",
      createFormData(params),
      {
        headers: { "content-type": "multipart/form-data" },
        isFileUpload: true,
      }
    );
  }
);
const initialSearchParams = {
  title: "",
  message: "",
  from_create_date: "",
  to_create_date: "",
  show_delete_flag: 0,
  offset: 0,
  count: 10,
  sort: "document_id_desc",
  document_type: 0,
};
const initialState = {
  brands: [],
  shops: [],
  notificationsList: [],
  activeNotification: {},
  activeReserve: {},
  notificationEditModalVisible: false,
  reserveEditModalVisible: false,
  notificationDeleteModalVisible: false,
  infomationModalVisible: false,
  infomationModalTitle: "",
  searchParams: initialSearchParams,
  total: 0,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    clear: () => initialState,
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
        operator_code: state.searchParams.operator_code ?? state.operator_code,
        document_type: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    openEditModal_insert: (state, { payload }) => ({
      ...state,
      notificationEditModalVisible: true,
      activeNotification: {},
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      notificationDeleteModalVisible: true,
      activeNotification: payload,
    }),
    openInfomationModal: (state, { payload }) => ({
      ...state,
      infomationModalTitle: payload,
      infomationModalVisible: true,
    }),
    closeEditModal: (state) => ({
      ...state,
      notificationEditModalVisible: false,
    }),
    closeReserveEditModal: (state) => ({
      ...state,
      reserveEditModalVisible: false,
    }),
    closeDeleteModal: (state) => ({
      ...state,
      notificationDeleteModalVisible: false,
    }),
    closeInfomationModal: (state) => ({
      ...state,
      infomationModalVisible: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(notificationsInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchNotifications.fulfilled, (state, { payload }) => ({
      ...state,
      notificationsList: payload?.item ?? [],
      total: payload?.total || 0,
    }));
    builder.addCase(
      fetchNotificationDetail.fulfilled,
      (state, { payload }) => ({
        ...state,
        activeNotification: payload?.item ?? {},
        notificationEditModalVisible: true,
      })
    );
    builder.addCase(updateNotification.fulfilled, (state, { payload }) => {
      // 登録・削除の時はモーダルを出さない
      return {
        ...state,
        searchParams: { ...state.searchParams },
        notificationEditModalVisible: false,
        notificationDeleteModalVisible: false,
      };
    });
    builder.addCase(
      fetchNotificationReserveDetail.fulfilled,
      (state, { payload }) => ({
        ...state,
        activeReserve: payload,
        reserveEditModalVisible: true,
      })
    );
    builder.addCase(updateReserve.fulfilled, (state, { payload }) => ({
      ...state,
      infomationModalTitle: "配信設定を登録しました",
      infomationModalVisible: true,
      reserveEditModalVisible: false,
    }));
  },
});

export const actions = notificationsSlice.actions;

export default notificationsSlice.reducer;

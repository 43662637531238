import "./style.scss";
import classnames from "classnames";

const BaseProgressBar = ({ progress, activeProgress }) => {
  return (
    <div className="progress-bar-outer">
      <div className="progress-bar">
        {progress.map((p, i) => (
          <span
            key={i}
            className={classnames({
              "progress-first": i === 0,
              "progress-last": i === progress.length - 1,
              "progress-active": `${i}` === activeProgress,
            })}
          >
            {p}
          </span>
        ))}
      </div>
    </div>
  );
};

export default BaseProgressBar;

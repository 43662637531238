import * as Yup from "yup";
import axios from "../utils/axiosUtil";
import { isAllNullOrAllNotNull } from "./fnUtil";

Yup.setLocale({
  mixed: {
    required: "必須項目です",
  },
  string: {
    min: ({ min }) => `${min}文字以上入力してください`,
    max: ({ max }) => `${max}文字以内で入力して下さい`,
  },
  array: {
    min: ({ min }) => `${min}個以上登録必須です`,
    max: ({ max }) => `最大${max}個までです`,
  },
});

Yup.addMethod(Yup.string, "id", function () {
  return this.test(
    "id",
    "形式が異なります",
    value => !value || /^[a-zA-Z0-9]+$/.test(value)
  );
});
Yup.addMethod(Yup.string, "name", function () {
  return this.test(
    "name",
    "形式が異なります",
    value => !value || /^[ぁ-んァ-ヶー一-龠ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰ｡｢｣､]+$/.test(value)
  );
});

Yup.addMethod(Yup.string, "selected", function (msg) {
  return this.test("selected", "必須項目です", value => !!value);
});

Yup.addMethod(Yup.string, "password", function () {
  return this.test(
    "password",
    "英数混在6桁以上で入力してください",
    value => !value || /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{6,}$/i.test(value)
  );
});

Yup.addMethod(Yup.string, "passwordConfirm", function () {
  return this.test(
    "passwordConfirm",
    "パスワードが一致しません",
    (value, context) => value === context.parent.password
  );
});
Yup.addMethod(Yup.string, "mail_local", function () {
  return this.test(
    "mail_local",
    "@より前は64文字以内で入力してください",
    value =>
      !value ||
      /(^[a-zA-Z.0-9-._/#?!%&~+]{1,64})+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
        value
      )
  );
});
Yup.addMethod(Yup.string, "mail", function () {
  return this.test(
    "mail",
    "形式が異なります",
    value =>
      !value ||
      /^[a-zA-Z.0-9-._/#?!%&~+]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(value)
  );
});

Yup.addMethod(Yup.string, "url", function () {
  return this.test(
    "url",
    "形式が異なります",
    value =>
      !value || /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/.test(value)
  );
});

Yup.addMethod(Yup.string, "kana", function () {
  return this.test(
    "kana",
    "形式が異なります",
    value =>
      !value || /^[ア-ヴ゛゜ァ-ォャ-ョー「」、ｱ-ﾝﾞﾟｧ-ｫｬ-ｮｰ｡｢｣､　 ]+$/.test(value)
  );
});

Yup.addMethod(Yup.string, "halfWidthAlphanumeric", function () {
  return this.test("halfWidthAlphanumeric", "形式が異なります", value =>
    /^[0-9a-zA-Z]*$/.test(value)
  );
});

Yup.addMethod(Yup.string, "userExist", function () {
  return this.test("userExist", "既に存在したユーザーです", async value => {
    const res = await axios.get("staff/detail", {
      params: { user_code: value },
    });
    return !(res.code === "1" && !!res.item);
  });
});

Yup.addMethod(Yup.string, "date_required", function () {
  return this.test("date_required", "必須項目です", value => value);
});

Yup.addMethod(Yup.string, "shop_date_valid", function () {
  return this.test(
    "shop_date_valid",
    "日付が無効です",
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.opening_date,
        context.parent.closed_date,
      ]) && context?.parent?.opening_date > context?.parent?.closed_date
  );
});

Yup.addMethod(Yup.string, "tel", function () {
  return this.test(
    "tel",
    "形式が異なります",
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.tel1,
        context.parent.tel2,
        context.parent.tel3,
      ]) &&
      /^(\s*|\d+)$/.test(
        `${context.parent.tel1 ?? ""}${context.parent.tel2 ?? ""}${
          context.parent.tel3 ?? ""
        }`
      )
  );
});
Yup.addMethod(Yup.string, "zip", function () {
  return this.test(
    "zip",
    "形式が異なります",
    (_, context) =>
      isAllNullOrAllNotNull([context.parent.zip1, context.parent.zip2]) &&
      /^(\s*|\d{7})$/.test(
        `${context.parent.zip1 ?? ""}${context.parent.zip2 ?? ""}`
      )
  );
});
Yup.addMethod(Yup.string, "fax", function () {
  return this.test(
    "fax",
    "形式が異なります",
    (_, context) =>
      isAllNullOrAllNotNull([
        context.parent.fax1,
        context.parent.fax2,
        context.parent.fax3,
      ]) &&
      /^(\s*|\d+)$/.test(
        `${context.parent.fax1 ?? ""}${context.parent.fax2 ?? ""}${
          context.parent.fax3 ?? ""
        }`
      )
  );
});
Yup.addMethod(Yup.string, "time", function () {
  return this.test("time", "有効な時間を入力してください", function (value) {
    if (value == null || value === "") return true;
    if (value >= "00:00" && value <= "23:59") return true;
  });
});
Yup.addMethod(Yup.string, "latitude", function () {
  return this.test(
    "latitude",
    "有効な範囲で入力してください",
    function (value) {
      if (value == null || value === "") return true;
      if (parseFloat(value) >= 0 && parseFloat(value) <= 90) return true;
    }
  );
});
Yup.addMethod(Yup.string, "longitude", function () {
  return this.test(
    "longitude",
    "有効な範囲で入力してください",
    function (value) {
      if (value == null || value === "") return true;
      if (parseInt(value) >= 0 && parseInt(value) <= 180) return true;
    }
  );
});

Yup.addMethod(Yup.array, "editorTextRequired", function () {
  return this.test("editorTextRequired", "必須項目です", function (value) {
    return (
      value?.length >= 1 &&
      value.some(
        v =>
          v.type !== "content" ||
          (v.children ?? []).some(c => c.text || c.type === "link")
      )
    );
  });
});
Yup.addMethod(Yup.array, "mallBrand", function () {
    return this.test(
        "mallBrand",
        "1つ以上登録必須です",
        (value, context) => context.parent.mall_flag === 0 ||
            (context.parent.mall_flag === 1 && value.length !== 0)
    );
});

Yup.addMethod(Yup.array, "mallBrands", function () {
    return this.test(
        "mallBrands",
        "1つ以上登録必須です",
        (value, context) => context.parent.mall_flg === 0 ||
            (context.parent.mall_flg === 1 && value.length !== 0)
    );
});

export default Yup;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import axios from "../utils/axiosUtil";

const createFormData = (params) => {
  const formData = new FormData();

  const jsonData = {
    ...params,
    request_from: "2",
    operator_code: params.operator_code,
  };

  formData.append("csv_file", params.upload_file);
  formData.append(
    "jsonValue",
    new Blob([JSON.stringify(jsonData)], { type: "application/json" })
  );

  return formData;
};

export const reservesInit = createAsyncThunk("reservesInit", async (params, {dispatch}) => {
  dispatch(loadingActions.multiLoadingOn());
  const [reserveRes, shopsRes, brandsRes] = await Promise.all([
    axios.get("notice/reserve/list", { params: initialSearchParams }),
    axios.get("shops/list",{params: { count: 0, sort : "shop_code_asc" }}),
    axios.get("brands/list",{params: { count: 0, sort : "brand_name_asc" }}),
  ]);
  dispatch(loadingActions.multiLoadingOff());
  return {
    reservesList: reserveRes?.item ?? [],
    total: reserveRes?.total || 0,
    brands: (brandsRes?.item ?? []).map((b) => ({
      ...b,
      label: b.brand_name,
      value: b.brand_cd,
    })),
    shops: (shopsRes?.item ?? []).map((s) => ({
      ...s,
      label: s.shop_code + ":" + s.shop_name,
      value: s.shop_code,
    })),
  };
});

export const fetchReserves = createAsyncThunk(
  "fetchReserves",
  async (params) => await axios.get("notice/reserve/list", { params })
);

export const fetchReserveDetail = createAsyncThunk(
  "fetchReserveDetail",
  async (params) => await axios.get("notice/reserve/detail", { params })
);

export const fetchReserveDetailEdit = createAsyncThunk(
  "fetchReserveDetailEdit",
  async (params) => await axios.get("notice/reserve/detail", { params })
);

export const updateReserve = createAsyncThunk(
  "updateReserve",
  async (params, { dispatch }) => {
    return await axios.post("/notice/reserve/update", createFormData(params), {
      headers: { "content-type": "multipart/form-data" },
      isFileUpload: true,
    });
  }
);

const initialSearchParams = {
  title: "",
  message: "",
  from_create_date: "",
  to_create_date: "",
  from_send_plan_date: "",
  to_send_plan_date: "",
  show_delete_flag: 0,
  send_status: [],
  offset: 0,
  count: 10,
  sort: "create_date_desc",
  operator_code: null,
  document_type: "0",
};
const initialState = {
  brands: [],
  shops: [],
  reservesList: [],
  activeReserve: null,
  reserveEditModalVisible: false,
  reserveDetailModalVisible: false,
  searchParams: initialSearchParams,
  total: 0
}

export const reservesSlice = createSlice({
  name: "reserves",
  initialState,
  reducers: {
    clear: () => initialState,
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    closeReserveEditModal: (state) => ({
      ...state,
      reserveEditModalVisible: false,
    }),
    closeReserveDetailModal: (state) => ({
      ...state,
      reserveDetailModalVisible: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(reservesInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchReserves.fulfilled, (state, { payload }) => ({
      ...state,
      reservesList: payload?.item ?? [],
      total: payload?.total || 0,
    }));
    builder.addCase(fetchReserveDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeReserve: payload?.item ?? {},
      reserveDetailModalVisible: true,
    }));
    builder.addCase(fetchReserveDetailEdit.fulfilled, (state, { payload }) => ({
      ...state,
      activeReserve: payload?.item ?? {},
      reserveEditModalVisible: true,
    }));
  },
});

export const actions = reservesSlice.actions;

export default reservesSlice.reducer;

import {useEffect, useLayoutEffect, useState} from "react";
import { useFormik } from "formik";
import {
  createNumberOptions,
  createTimeOptions,
  handlerFormikFieldChange,
  spliceArray,
  swapArray,
} from "../../../utils/fnUtil";
import { genderOptionsWithKid } from "../../../constants/options";
import { useDispatch, useSelector } from "react-redux";
import { Modal, DatePicker } from "antd";
import {
  actions, fetchMallBrand,
  fetchStaffs,
  fetchTags,
  registCoordinate,
  registDraftCoordinate,
  saveBaseInfo,
  uploadImage,
} from "../../../slices/coordinateSlice";
import { actions as productActions } from "../../../slices/productSlice";
import Button from "../../button";
import GeneralInput from "../../generalInput";
import SelectModal from "../selectModal";
import classNames from "classnames";
import ProductSearchModal from "../productSearchModal";
import DraggableContainer from "./DraggableContainer";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Yup from "../../../utils/yupUtil";
import moment from "moment";
import "./style.scss";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";

const CoordinateSignUpModal = () => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);

  const [activeIndex, setActiveIndex] = useState(0);

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const [selectMallBrand, setSelectMallBrand] = useState([]);

  const {
    signUpModalVisible,
    brands,
    shops,
    tags,
    categories,
    staffs,
    staffsReady,
    currentCoordinateCode,
    mallBrandList,
  } = useSelector(state => state.coordinate);

  const formik = useFormik({
    initialValues: {
      division: "update",
      coordinate_code: "",
      origin_status_flg: 0,
      brand_code: (account.brand_code ?? [])[0],
      shop_code: account.shop_code,
      user_code: account.user_code,
      coordinate_images: Array(10).fill(""),
      coordinate_products: Array(10).fill(null),
      tag_code: [],
      gender: account.gender,
      height: account.height,
      comment: "",
      mall_flg: 0,
      mall_brand_list:[],
      tag_category_code: null,
      date: moment(),
      hour: moment().format("HH"),
      minute: moment().format("mm"),
      uneditable: true,
    },
    validationSchema: Yup.object({
      brand_code: Yup.string().nullable().selected(),
      shop_code: Yup.string().nullable().selected(),
      user_code: Yup.string()
        .nullable()
        .selected()
        .test("nickNameExist", "サイト表示名を登録してください", value =>
          staffs.some(s => s.user_code === value && s.user_nickname)
        ),
      coordinate_images: Yup.array().test(
        "coordinate_images",
        "2枚以上必須です",
        value => value.filter(v => v).length > 1
      ),
      coordinate_products: Yup.array().test(
        "coordinate_products",
        "1枚以上必須です",
        value => value.filter(v => v).length >= 1
      ),
      tag_code: Yup.array().min(2).max(20),
      gender: Yup.string().nullable().selected("性別"),
      height: Yup.string().nullable().selected("身長"),
      date: Yup.string()
        .nullable()
        .test(
          "date",
          "日時を選択してください",
          (value, testContext) =>
            value && testContext.parent.hour && testContext.parent.minute
        ),
      mall_brand_list: Yup.array().mallBrands(),
    }),
    onSubmit: values => {
      const {
        date,
        minute,
        hour,
        tag_category_code,
        tag_code,
        user_code,
        coordinate_images,
        coordinate_products,
        uneditable,
        ...rest
      } = values;

      dispatch(
        registCoordinate({
          ...rest,
          user_code,
          status_flg: 3,
          accept_at: `${date.format("YYYY-MM-DD")} ${hour}:${minute}:00`,
          tag_code: tag_code.map(t => t.tag_code),
          coordinate_images: coordinate_images.filter(c => c),
          coordinate_products: coordinate_products.filter(p => p),
        })
      );
    },
  });

  const onBack = () => {
    dispatch(actions.closeSignUpModal());
    formik.resetForm();
  };

  const {
    values: {
      tag_category_code,
      tag_code,
      brand_code,
      user_code,
      shop_code,
      date,
      coordinate_images,
      coordinate_products,
      gender,
      height,
      comment,
      uneditable,
      coordinate_code,
      mall_flg,
      mall_brand_list
    },
    setFieldValue,
    validateField,
  } = formik;

  const handleFileChange = (url, index) =>
    dispatch(
      uploadImage({
        image: url,
        content_flag: 0,
        content_code: coordinate_code,
        upload_operator_code: user_code
      })
    ).then(
      ({ payload }) =>
        payload?.code === "0" &&
        handlerFormikFieldChange(
          formik,
          "coordinate_images",
          spliceArray(coordinate_images, index, payload.image_url)
        )
    );

  useEffect(() => {
    signUpModalVisible &&
      brand_code &&
      shop_code &&
      dispatch(
        fetchStaffs({
          brand_code,
          shop_code,
          count: 0,
        })
      );
  }, [brand_code, shop_code, signUpModalVisible, dispatch]);

  useEffect(() => {
    tag_category_code &&
      brand_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, brand_code, dispatch]);

  useEffect(() => {
    signUpModalVisible && dispatch(productActions.saveBrand(brand_code));
  }, [signUpModalVisible, brand_code, dispatch]);

  useEffect(() => {
    if (signUpModalVisible) {
      setFieldValue("date", moment());
      setFieldValue("hour", moment().format("HH"));
      setFieldValue("minute", moment().format("mm"));
    }
  }, [signUpModalVisible, setFieldValue]);

  useEffect(() => {
    categories.length === 1 &&
      setFieldValue("tag_category_code", categories[0]?.tag_category_code);
  }, [categories, setFieldValue]);

  useEffect(() => {
    validateField("user_code");
  }, [validateField, staffs]);

  useEffect(() => {
    if (currentCoordinateCode) {
      setFieldValue("coordinate_code", currentCoordinateCode);
      setFieldValue("uneditable", false);
      document
        .querySelector(".register-modal .ant-modal-body")
        .scrollTo(0, 300);
    }
  }, [currentCoordinateCode, setFieldValue]);

  useEffect(() => {
    setSelectMallBrand(mallBrandList.filter(m=> mall_brand_list.includes(m.brand_code)));
  },[mall_brand_list]);

  useEffect(() => {
    if(account.multi_post_flag === 1 && mallBrandList.length > 0){
      handlerFormikFieldChange(formik, "mall_flg", 1);
      uneditable
          ? handlerFormikFieldChange(formik, "mall_brand_list", mallBrandList.map(m=>{return m.value;}))
          : handlerFormikFieldChange(formik, "mall_brand_list", []);
      uneditable ? setSelectMallBrand(mallBrandList) : setSelectMallBrand([]);
    }else{
      handlerFormikFieldChange(formik, "mall_flg", 0);
      handlerFormikFieldChange(formik, "mall_brand_list", [])
      setSelectMallBrand([])
    }
  },[mallBrandList]);

  const brandOptions = brands.filter(b => b.status === "1");

  const shopOptions = shops.filter(
    s =>
      (s.brand ?? []).some(b => b.brand_code === brand_code) && s.status === "1"
  );

  const staffOptions = staffs.filter(s => s.status === 1);

  const onSaveBaseInfo = () => {
    dispatch(
      saveBaseInfo({
        division: "insert",
        brand_code,
        shop_code,
        user_code,
        coordinate_images: [],
        coordinate_products: [],
        tag_code: [],
        gender,
        height,
        comment: "",
        status_flg: 0,
      })
    );
    dispatch(fetchMallBrand(
        {
          brand_code: brand_code,
          mall_flag:1,
          offset: 0,
          status_flag: 1,
          sort: "brand_code_asc"
        }
    ));
  };

  const onRegistDraft = () => {
    setFieldValue("date", null);
    setFieldValue("hour", null);
    setFieldValue("minute", null);
    const {
      date,
      hour,
      minute,
      tag_category_code,
      uneditable,
      coordinate_images,
      coordinate_products,
      ...rest
    } = formik.values;
    dispatch(
      registDraftCoordinate({
        ...rest,
        status_flg: 0,
        tag_code: tag_code.map(t => t.tag_code),
        coordinate_images: coordinate_images.filter(c => c),
        coordinate_products: coordinate_products.filter(p => p),
      })
    );
  };

  const onTagOk = value => {
    handlerFormikFieldChange(
      formik,
      "tag_code",
      [
        ...tag_code,
        ...tags.filter(
          t =>
            value.includes(t.value) &&
            !tag_code.some(tag => t.value === tag.tag_code)
        ),
      ].filter(
        tag =>
          !tags.map(t => t.value).includes(tag.tag_code) ||
          value.includes(tag.tag_code)
      )
    );
    setSelectTagModalVisible(false);
  };


  const afterClose = () => {
    dispatch(actions.clearCurrentCoordinateCode());
    formik.resetForm();
  };

  return (
    <Modal
      visible={signUpModalVisible}
      closable={false}
      width={850}
      style={{ minWidth: 850 }}
      footer={null}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
      className={"register-modal"}
    >
      <h2 className="signup-title">
        <span className="text-bold">コーディネート新規登録</span>
        <span className="nf-text-required-style">　*は必須項目です</span>
      </h2>
      <div className="coordinate-signup">
        <form onSubmit={formik.handleSubmit}>
          <div
            className={classNames("coordinate-edit-area", {
              "disabled-area": !uneditable,
            })}
          >
            <div className="text-bold text-required">投稿者情報</div>
            <div className="select-container">
              <GeneralInput
                label="ブランド"
                options={
                  brandOptions.length > 0
                    ? brandOptions
                    : [
                        {
                          label: account.brand_name?.[0],
                          value: account.brand_code?.[0],
                        },
                      ]
                }
                placeholder="ブランドを選択して下さい"
                styleType="inline-grey"
                mode={null}
                formik={formik}
                name={"brand_code"}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "shop_code", null);
                  handlerFormikFieldChange(formik, "user_code", null);
                }}
              />
              <GeneralInput
                disabled={!brand_code}
                options={
                  shopOptions.length > 0
                    ? shopOptions
                    : [{ label: account.shop_name, value: account.shop_code }]
                }
                label="店舗"
                placeholder="店舗を選択して下さい"
                styleType="inline-grey"
                mode={null}
                name={"shop_code"}
                formik={formik}
                extraOnChange={() =>
                  handlerFormikFieldChange(formik, "user_code", null)
                }
              />
              <GeneralInput
                label="社員"
                options={
                  staffOptions.length > 0 || staffsReady
                    ? staffOptions
                    : [{ label: account.user_name, value: account.user_code }]
                }
                disabled={!brand_code || !shop_code}
                placeholder="社員を選択して下さい"
                styleType="inline-grey"
                mode={null}
                name={"user_code"}
                extraOnChange={(_, v) => {
                  const { gender, height } =
                    staffs.find(s => s.user_code === v) ?? {};
                  setFieldValue("gender", gender);
                  setFieldValue("height", height);
                }}
                formik={formik}
              />
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="保存"
                disabled={!brand_code || !shop_code || formik.errors.user_code}
                onClick={onSaveBaseInfo}
              />
            </div>
          </div>
          <div
            className={classNames("coordinate-edit-area", {
              "disabled-area": uneditable,
            })}
          >
            <div>
              <span className="text-bold text-required">
                コーディネート画像
              </span>
              <span className="text-tip">
                　2枚以上登録必須
                <span className="text-subtip">
                  ファイル形式 png,gif,jpg,jpeg、ドラッグで入れ替え可。
                </span>
              </span>
              <span className="validation-error error-images">
                {formik.touched.coordinate_images &&
                  formik.errors.coordinate_images}
              </span>
            </div>
            <DraggableContainer
              items={coordinate_images}
              onClick={handleFileChange}
              uneditable={uneditable}
              onCancle={(e, index) => {
                handlerFormikFieldChange(
                  formik,
                  "coordinate_images",
                  spliceArray(coordinate_images, index, "")
                );
                e.stopPropagation();
              }}
              onExchange={(dragIndex, dropIndex) => {
                handlerFormikFieldChange(
                  formik,
                  "coordinate_images",
                  swapArray(coordinate_images, dragIndex, dropIndex)
                );
              }}
            />
            <div>
              <span className="text-bold text-required">商品情報</span>
              <span className="text-tip">
                <span className="text-subtip">
                  10枚まで登録可、ドラッグで入れ替え可。
                </span>
              </span>
              <span className="validation-error error-images">
                {formik.touched.coordinate_products &&
                  formik.errors.coordinate_products}
              </span>
            </div>
            <DraggableContainer
              items={coordinate_products}
              isProduct={true}
              onClick={index => {
                setProductSearchModalVisible(true);
                setActiveIndex(index);
              }}
              uneditable={uneditable}
              onCancle={(e, index) => {
                handlerFormikFieldChange(
                  formik,
                  "coordinate_products",
                  spliceArray(coordinate_products, index, null)
                );
                e.stopPropagation();
              }}
              onExchange={(dragIndex, dropIndex) => {
                handlerFormikFieldChange(
                  formik,
                  "coordinate_products",
                  swapArray(coordinate_products, dragIndex, dropIndex)
                );
              }}
            />
            <div className="tag-area">
              <div className="tag-title">
                <span className="text-bold text-required">タグ情報</span>　
                <span className="text-tip">
                  2個以上登録必須
                  <span className="text-subtip"> 20個まで登録可。</span>
                </span>
              </div>
              <GeneralInput
                label="カテゴリ"
                uneditable={categories.length === 1}
                placeholder="選択してください"
                styleType="block-grey-normal"
                requiredItem
                labelTextAlign="left"
                options={categories}
                disabled={uneditable}
                mode={null}
                name={"tag_category_code"}
                formik={formik}
              />
              <div>
                <span className="text-required text-tag">タグ</span>
                <Button
                  text="タグを選択"
                  disabled={!tag_category_code || uneditable}
                  theme="white"
                  onClick={() => setSelectTagModalVisible(true)}
                />
                <span className="validation-error">
                  {formik.touched.tag_code && formik.errors.tag_code}
                </span>
              </div>
              <div className="tags-container">
                {tag_code.map((t, i) => (
                  <div className="tag" key={i}>
                    {t.tag_name}
                    {!uneditable && (
                      <Icon
                        className="close-icon"
                        component={Icons.CloseIcon}
                        onClick={() => {
                          handlerFormikFieldChange(
                            formik,
                            "tag_code",
                            tag_code.filter(i => i.tag_code !== t.tag_code)
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="user-info-area">
              <span className="text-bold text-required">属性</span>　
              <GeneralInput
                label="性別"
                placeholder="選択してください"
                styleType="block-grey-normal"
                requiredItem
                disabled={uneditable}
                labelTextAlign="left"
                options={genderOptionsWithKid}
                mode={null}
                name={"gender"}
                formik={formik}
              />
              <GeneralInput
                label="身長"
                placeholder="選択してください"
                styleType="block-grey-normal"
                requiredItem
                labelTextAlign="left"
                disabled={uneditable}
                options={createNumberOptions()}
                mode={null}
                name={"height"}
                formik={formik}
                textAfter="cm"
              />
            </div>

            <div className="comment-area">
              <span className="text-bold">コメント</span>　
              <textarea
                placeholder="コメントを入力してください"
                spellCheck={false}
                autoComplete={"false"}
                value={comment}
                disabled={uneditable}
                onChange={e =>
                  handlerFormikFieldChange(formik, "comment", e.target.value)
                }
              />
            </div>
            <div className="mall-brand-area">
              <div className="mall-brand-title">
                <span className="text-bold text-required">同時投稿</span>
              </div>
              <div className="nf-input-wrapper">
                {(mallBrandList.length > 0 && account.multi_post_flag === 1) || uneditable ? (
                    <>
                      <label className="nf-input-label text-align-left label-width-wide label-required">同時投稿</label>
                      <GeneralCheckbox
                          label="同時投稿する"
                          checked={mall_flg === 1}
                          className={"nf-checkbox-wide"}
                          onChange={(value) => handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 1 : 0)}
                      />
                      <GeneralCheckbox
                          label="同時投稿しない"
                          checked={mall_flg === 0}
                          onChange={(value) => {
                            handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 0 : 1)
                            handlerFormikFieldChange(formik, "mall_brand_list", [])
                          }}
                      />
                    </>
                ) : (
                    <>
                      <div className="nf-input-wrapper nf-wrapper-uneditable">
                        <span className="input-value">{mall_flg !== 1 && ("同時投稿しない")}</span>
                      </div>
                    </>
                )}
              </div>
              {mall_flg === 1 && (<>
                <GeneralInput
                    label="同時投稿先"
                    placeholder="選択してください"
                    styleType="block-grey-normal"
                    labelTextAlign="left"
                    requiredItem={mall_flg === 1}
                    options={mallBrandList}
                    disabled={uneditable || mall_flg === 0}
                    name={"mall_brand_list"}
                    formik={formik}
                />
              </>)}
              <div className="mall-brand-container">
                {selectMallBrand.map((b, i) => (
                    <div className="mall-brand" key={i}>
                      {b.brand_name}
                      {!uneditable && (
                          <Icon
                              className="close-icon"
                              component={Icons.CloseIcon}
                              onClick={() => {
                                handlerFormikFieldChange(
                                    formik,
                                    "mall_brand_list",
                                    mall_brand_list.filter(i => i !== b.brand_code)
                                );
                              }}
                          />
                      )}
                    </div>
                ))}
              </div>
            </div>
            <div className="coordinate-approval-area">
              <span className="text-bold text-required">公開設定</span>　
              <div className="public-date-select">
                <span className="text-required">公開日時</span>
                <DatePicker
                  format={"YYYY.MM.DD"}
                  value={date}
                  disabled={uneditable}
                  suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  onChange={value =>
                    handlerFormikFieldChange(formik, "date", value)
                  }
                />
                <GeneralInput
                  mode={null}
                  disabled={uneditable}
                  styleType="block-grey-normal"
                  options={createTimeOptions(24)}
                  name={"hour"}
                  formik={formik}
                  textAfter="時"
                  placeholder="時"
                />
                <GeneralInput
                  mode={null}
                  styleType="block-grey-normal"
                  disabled={uneditable}
                  options={createTimeOptions(60)}
                  name={"minute"}
                  formik={formik}
                  textAfter="分"
                  placeholder="分"
                />
                <span className="validation-error">
                  {formik.touched.date &&
                    formik.touched.hour &&
                    formik.touched.minute &&
                    formik.errors.date}
                </span>
              </div>
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="公開設定"
                type="submit"
                theme={"black"}
                disabled={uneditable || !formik.isValid}
              />
              <Button
                text="下書きとして保存する"
                disabled={uneditable}
                theme="link"
                onClick={onRegistDraft}
              />
            </div>
          </div>
          <SelectModal
            checkBoxName="sign-up-tags"
            modalVisible={selectTagModalVisible}
            onCancel={() => setSelectTagModalVisible(false)}
            onOk={onTagOk}
            title={"タグを選択してください"}
            value={tag_code.map(t => t.tag_code)}
            options={tags}
          />
          <ProductSearchModal
            visible={productSearchModalVisible}
            onCancel={() => setProductSearchModalVisible(false)}
            onOk={selectedProduct => {
              handlerFormikFieldChange(
                formik,
                "coordinate_products",
                spliceArray(coordinate_products, activeIndex, selectedProduct)
              );
              setProductSearchModalVisible(false);
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default CoordinateSignUpModal;

import { Modal } from "antd";
import Button from "../../button";
import "./style.scss";

/* ログイン情報モーダル */

const LoginInfoModal = ({
  modalVisible,
  setModalVisible,
  title = "",
  msg = "",
  onClick = () => setModalVisible(false),
}) => {
  return (
    <Modal
      className={"nf-modal-loginInfo"}
      visible={modalVisible}
      centered
      footer={null}
      width={500}
      closable={false}
      maskClosable={true}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <div className="nf-modal-loginInfo-content">
        <div className="nf-modal-loginInfo-title">{title}</div>
        <div className="nf-modal-loginInfo-msg">{msg}</div>
        <div>
          <Button
            text="閉じる"
            onClick={onClick}
            style={{
              width: 140,
              height: 30,
              fontSize: 14,
              marginTop: 40,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LoginInfoModal;

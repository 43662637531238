import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import resolve from "resolve";
import axios from "../utils/axiosUtil";

export const topInit = createAsyncThunk("articlesInit", async (params, {dispatch}) => {
  dispatch(loadingActions.multiLoadingOn());
  const [
    infomationRes,
    coordinateRes,
    articleRes,
    tagRes,
    summaryRes,
  ] = await Promise.all([
    axios.get("information/list"),
    params.userAuth === "0" ||
    params.userAuth === "1" ||
    params.userAuth === "2" ||
    params.userAuth === "3"
      ? axios.get("coordinate/list", { params: { display_item : 0} })
      : {},
    params.userAuth === "0" ||
    params.userAuth === "1" ||
    params.userAuth === "2" ||
    params.userAuth === "3"
      ? axios.get("articles/list", { params: { display_item : 0} })
      : {},
    params.userAuth === "0" ||
    params.userAuth === "1" ||
    params.userAuth === "2"
      ? axios.get("tags/list", { params: { display_item : 0} })
      : {},
    axios.get("analyze/summary"),
  ]);
  dispatch(loadingActions.multiLoadingOff());

  return {
    infomationData: infomationRes?.item ?? [],
    statusAggregate: {
      coordinate: coordinateRes.status_aggregate ?? {},
      article: articleRes?.status_aggregate ?? {},
      tag: tagRes.status_count ?? {},
    },
    contentsDaily: summaryRes?.contents_daily_compare ?? {},
    contentsMonthly: summaryRes?.contents_monthly_compare ?? {},
    instaDaily: summaryRes?.insta_daily_compare ?? {},
    instaMonthly: summaryRes?.insta_monthly_compare ?? {},
    twDaily: summaryRes?.twitter_daily_compare ?? {},
    twMonthly: summaryRes?.twitter_monthly_compare ?? {},
    liveMonthly: summaryRes?.live_monthly_compare ?? {},
    archiveDaily: summaryRes?.archive_daily_compare ?? {},
    archiveMonthly: summaryRes?.archive_monthly_compare ?? {},
    movieDaily: summaryRes?.movie_daily_compare ?? {},
    movieMonthly: summaryRes?.movie_monthly_compare ?? {},
  };
});
export const topScroll = createAsyncThunk("topScroll", async (params, {dispatch}) => {
  dispatch(loadingActions.multiLoadingOn());
  const [
    coordinateRankRes,
    blogRankRes,
    instaRankRes,
    twRankRes,
    liveRankRes,
    archiveRankRes,
    movieRankRes,
  ] = await Promise.all([
    axios.get("analyze/contents/list", {
      params: {
        ...params.analyze,
        sort: "sales_desc",
        contents_type: "coordinate",
        filter_response: false,
      },
    }),
    axios.get("analyze/contents/list", {
      params: {
        ...params.analyze,
        sort: "sales_desc",
        contents_type: "blog",
        filter_response: false,
      },
    }),
    axios.get("analyze/sns/list", {
      params: {
        ...params.analyze,
        sort: "sales_amount_desc",
        sns_type: 0,
        filter_response: false,
      },
    }),
    axios.get("analyze/sns/list", {
      params: {
        ...params.analyze,
        sort: "sales_amount_desc",
        sns_type: 1,
        filter_response: false,
      },
    }),
    axios.get("analyze/contents/list", {
      params: {
        ...params.analyze,
        sort: "sales_desc",
        contents_type: "live",
        filter_response: false,
      },
    }),
    axios.get("analyze/contents/list", {
      params: {
        ...params.analyze,
        sort: "sales_desc",
        contents_type: "archive",
        filter_response: false,
      },
    }),
    axios.get("analyze/contents/list", {
      params: {
        ...params.analyze,
        sort: "sales_desc",
        contents_type: "movie",
        filter_response: false,
      },
    }),
  ]);
  dispatch(loadingActions.multiLoadingOff());

  return {
    coordinateRank: coordinateRankRes?.item ?? [],
    blogRank: blogRankRes?.item ?? [],
    instaRank: instaRankRes?.item ?? [],
    twRank: twRankRes?.item ?? [],
    liveRank: liveRankRes?.item ?? [],
    archiveRank: archiveRankRes?.item ?? [],
    movieRank: movieRankRes?.item ?? [],
  };
});



export const topSlice = createSlice({
  name: "topSlice",
  initialState: {
    infomationData: [],
    statusAggregate: {},
    contentsDaily: {},
    contentsMonthly: {},
    instaDaily: {},
    instaMonthly: {},
    twDaily: {},
    twMonthly: {},
    liveMonthly: {},
    archiveDaily: {},
    archiveMonthly: {},
    movieDaily: {},
    movieMonthly: {},
    coordinateRank: [],
    blogRank: [],
    instaRank: [],
    twRank: [],
    liveRank: [],
    archiveRank: [],
    movieRank: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(topInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(topScroll.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
  },

});

export const actions = topSlice.actions;

export default topSlice.reducer;

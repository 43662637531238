import { Modal } from "antd";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../button";
import { shopActions } from "../../../slices/shopsSlice";
import { uploadActions } from "../../../slices/fileUploadSlice";

const CompleteModal = ({ }) => {
  const dispatch = useDispatch();
  const { FileUploadCompleteModalVisible } = useSelector( state => state.fileUpload );
  const close = () =>{
    dispatch(shopActions.closeFileUploadModal());
    dispatch(uploadActions.formatParam());
  }
  return (
    <Modal
      visible={FileUploadCompleteModalVisible}
      closable={false}
      width={350}
      centered
      footer={null}
      title={
        <h3 className="text-bold nf-complete-title">
          登録が完了しました
        </h3>
      }
    >
      <div className="nf-complete-btn">
        <form className="upload-area" >
          <Button text="戻る" onClick={() => close()} />
        </form>
      </div>　
    </Modal>
  );
};

export default CompleteModal;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Table, DatePicker, Checkbox } from "antd";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  paymentsInit,
  fetchPayments,
  updateCsvPaymentsFavoriteItem,
  csvDownloadPayments,
} from "../../slices/paymentsSlice";
import InfomationModal from "../../components/modal/infomationModal";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import CsvDownloadModal from "../../components/modal/csvDownloadModal";
import GenernalInput from "../../components/generalInput";
import GeneralCheckbox from "../../components/cusCheckbox/genernalCheckbox";
import { toMoneyString, validDatePickChecker } from "../../utils/fnUtil";
import "./style.scss";
const Payments = () => {
  const dispatch = useDispatch();
  const {
    brands = [],
    shops = [],
    paymentsList = [],
    paymentsCsvDownloadModal,
    favoriteItem,
    searchParams,
    inflowCheckBoxes,
    infomationModal,
    total,
  } = useSelector((state) => state.payments);
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) =>
      dispatch(
        actions.saveSearchParam({
          ...values,
          from_date: salesDate.from?.format("YYYY-MM-DD"),
          to_date: salesDate.to?.format("YYYY-MM-DD"),
        })
      ),
  });
  const [salesDate, setSalesDate] = useState({ from: null, to: null });

  useEffect(() => {
    return () => dispatch(actions.clear());
  }, []);

  useEffect(() => {
    dispatch(paymentsInit());
  }, []);

  useUpdateEffect(
    () => dispatch(fetchPayments(searchParams)),
    [searchParams, dispatch]
  );

  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      formik.values.sort === desc
        ? asc
        : formik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const createOnInflow = (field, cheked) => {
    const newState = { ...inflowCheckBoxes, [field]: cheked };
    let newInflows = [];
    for (const flow in newState) {
      if (newState[flow])
        newInflows.push(
          flow === "coordinate"
            ? "1"
            : flow === "blog"
            ? "2"
            : flow === "live"
            ? "3"
            : flow === "archive"
            ? "4"
            : flow === "movie"
            ? "5"
            : flow === "instagram"
            ? "6"
            : flow === "twitter"
            ? "7"
            : ""
        );
    }
    dispatch(
      actions.saveCheckBoxes({
        inflowCheckBoxes: newState,
      })
    );
    formik.setFieldValue("inflow", newInflows);
  };
  const getSortIcon = (sort) => {
    return sort ? (
      <Icon component={Icons.SortAscendingIcon} style={{ fontSize: 10 }} />
    ) : (
      <Icon component={Icons.SortDescendingIcon} style={{ fontSize: 10 }} />
    );
  };
  const columns = [
    {
      title: () => (
        <>
          <span>売上日 </span>
          <span onClick={createOnClikSort("sales_date")}>
            {getSortIcon(formik.values.sort === "sales_date_asc")}
          </span>
        </>
      ),
      dataIndex: "date",
      key: "date",
      align: "center",
      width: TableColumnWidth.date,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>社員CD </span>
          <span onClick={createOnClikSort("user_code")}>
            {getSortIcon(formik.values.sort === "user_code_asc")}
          </span>
        </>
      ),
      dataIndex: "user_code",
      key: "user_code",
      align: "center",
      width: TableColumnWidth.payments.cd,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>社員名 </span>
          <span onClick={createOnClikSort("user_name")}>
            {getSortIcon(formik.values.sort === "user_name_asc")}
          </span>
        </>
      ),
      key: "user_name",
      dataIndex: "user_name",
      align: "center",
      width: TableColumnWidth.userName,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>店舗 </span>
          <span onClick={createOnClikSort("shop_name")}>
            {getSortIcon(formik.values.sort === "shop_name_asc")}
          </span>
        </>
      ),
      key: "shop_name",
      dataIndex: "shop_name",
      align: "center",
      width: TableColumnWidth.shopName,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>流入元 </span>
          <span onClick={createOnClikSort("inflow")}>
            {getSortIcon(formik.values.sort === "inflow_asc")}
          </span>
        </>
      ),
      key: "inflow",
      dataIndex: "inflow",
      align: "center",
      width: TableColumnWidth.payments.inflow,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>種別 </span>
          <span onClick={createOnClikSort("sales_flag")}>
            {getSortIcon(formik.values.sort === "sales_flag_asc")}
          </span>
        </>
      ),
      key: "type",
      dataIndex: "type",
      align: "center",
      width: TableColumnWidth.payments.type,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>商品名 </span>
          <span onClick={createOnClikSort("product_name")}>
            {getSortIcon(formik.values.sort === "product_name_asc")}
          </span>
        </>
      ),
      key: "product_name",
      dataIndex: "product_name",
      align: "center",
      width: TableColumnWidth.payments.productName,
      render: (val) => (
        <div style={{ maxWidth: 200 }} className="table-colum-title-cell">
          {val || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span onClick={createOnClikSort("brand_name")}>
            {getSortIcon(formik.values.sort === "brand_name_asc")}
          </span>
        </>
      ),
      key: "brand_name",
      dataIndex: "brand_name",
      align: "center",
      width: TableColumnWidth.brandName,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>数量 </span>
          <span onClick={createOnClikSort("order_qty")}>
            {getSortIcon(formik.values.sort === "order_qty_asc")}
          </span>
        </>
      ),
      key: "quantity",
      dataIndex: "quantity",
      align: "center",
      width: TableColumnWidth.payments.qty,
      render: (val) => (
        <div className="table-colum-title-cell">
          {toMoneyString(val) || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>上代金額(合計) </span>
          <span onClick={createOnClikSort("price")}>
            {getSortIcon(formik.values.sort === "price_asc")}
          </span>
        </>
      ),
      key: "retail_price",
      dataIndex: "retail_price",
      align: "center",
      width: TableColumnWidth.payments.retailPrice,
      render: (val) => (
        <div className="table-colum-title-cell">
          {toMoneyString(val) || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>報酬金額 </span>
          <span onClick={createOnClikSort("incentive_price")}>
            {getSortIcon(formik.values.sort === "incentive_price_asc")}
          </span>
        </>
      ),
      key: "reward_price",
      dataIndex: "reward_price",
      align: "center",
      width: TableColumnWidth.payments.reward,
      render: (val) => (
        <div className="table-colum-title-cell">
          {toMoneyString(val) || "-"}
        </div>
      ),
    },
  ];
  const options = [
    { label: "売上日", value: "sales_date", colSpan: 8 },
    { label: "ブランドCD", value: "brand_cd", colSpan: 8 },
    { label: "SKUコード", value: "sku_cd", colSpan: 8 },
    { label: "社員CD", value: "user_cd", colSpan: 8 },
    { label: "ブランド名", value: "brand_name", colSpan: 8 },
    { label: "JANコード", value: "jan_cd", colSpan: 8 },
    { label: "社員名", value: "user_name", colSpan: 8 },
    { label: "商品CD", value: "product_cd", colSpan: 8 },
    { label: "数量", value: "order_qty", colSpan: 8 },
    { label: "店舗CD", value: "shop_cd", colSpan: 8 },
    { label: "商品名", value: "product_name", colSpan: 8 },
    { label: "上代金額(合計)", value: "retail_price", colSpan: 8 },
    { label: "店舗名", value: "shop_name", colSpan: 8 },
    { label: "色CD", value: "color_cd", colSpan: 8 },
    { label: "設定率", value: "setting_rate", colSpan: 8 },
    { label: "流入元", value: "inflow_source", colSpan: 8 },
    { label: "色名", value: "color_name", colSpan: 8 },
    { label: "報酬金額", value: "incentive_price", colSpan: 8 },
    { label: "種別", value: "type", colSpan: 8 },
    { label: "サイズCD", value: "size_cd", colSpan: 8 },
    { label: "投稿元ブランドCD", value: "origin_brand_cd", colSpan: 8 },
    { label: "注文番号", value: "ec_order_id", colSpan: 8 },
    { label: "サイズ名", value: "size_name", colSpan: 8 },
    { label: "投稿元ブランド名", value: "origin_brand_name", colSpan: 8 },

  ];
  return (
    <div className="content-body">
      <Header />
      <CsvDownloadModal
        modalVisible={paymentsCsvDownloadModal}
        options={options}
        favoriteItem={favoriteItem}
        onFavoriteItem={(checkedList) => {
          let param = {
            screen_type: 0,
            favorite: {},
          };
          options.forEach((item) => {
            param.favorite[item.value] = "0";
          });
          checkedList.forEach((item) => {
            param.favorite[item] = "1";
          });
          dispatch(updateCsvPaymentsFavoriteItem(param));
        }}
        onClose={() => dispatch(actions.closePaymentsCsvDownloadModal())}
        onDownload={(checkedList) => {
          dispatch(
            csvDownloadPayments({
              screen_type: 0,
              user_code: searchParams.user_code,
              user_name: searchParams.user_name,
              shop_code: searchParams.shop_code,
              brand_code: searchParams.brand_code,
              inflow: searchParams.inflow,
              start_date: searchParams.from_date,
              end_date: searchParams.to_date,
              columns: checkedList,
            })
          );
        }}
        colspan={8}
      />
      <InfomationModal
        modalVisible={infomationModal}
        item={{ information_title: "出力データが存在しません" }}
        setModalVisible={() => {
          dispatch(actions.closeInfomationMondal());
        }}
      />
      <div className="payments-container nf-page">
        <h2>支払報酬確認</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center">売上日</label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={{
                  backgroundColor: "white",
                  fontSize: 16,
                  height: 32,
                  width: 120,
                }}
                value={salesDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    salesDate,
                    setSalesDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={{
                  backgroundColor: "white",
                  fontSize: 16,
                  height: 32,
                  width: 120,
                }}
                value={salesDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    salesDate,
                    setSalesDate
                  );
                }}
              />
            </div>
            <GenernalInput
              label="社員CD"
              placeholder="社員CDを入力してください"
              formik={formik}
              name={"user_code"}
            />
            <GenernalInput
              label="社員名"
              placeholder="社員名を入力してください"
              formik={formik}
              name={"user_name"}
            />
            <GenernalInput
              label="店舗"
              mode="multiple"
              name="shop_code"
              placeholder={"店舗を選択してください"}
              formik={formik}
              options={shops}
            />
            <GenernalInput
              label="ブランド"
              mode="multiple"
              name="brand_code"
              placeholder={"ブランドを選択してください"}
              formik={formik}
              options={brands}
            />
            <div className="nf-input-wrapper nf-input-wrapper-middle serchItem-checkBoxes">
              <label class="nf-input-label text-align-center text-required">流入元</label>
              <GeneralCheckbox
                defaultChecked={true}
                label="コーディネート"
                className="nf-checkbox-margin"
                checked={inflowCheckBoxes.coordinate}
                onChange={(val) => {
                  createOnInflow("coordinate", val.target.checked);
                }}
              />
              <GeneralCheckbox
                defaultChecked={true}
                label="ブログ"
                className="nf-checkbox-margin"
                checked={inflowCheckBoxes.blog}
                onChange={(val) => {
                  createOnInflow("blog", val.target.checked);
                }}
              />
              <GeneralCheckbox
                label="オンラインライブ"
                className="nf-checkbox-margin"
                checked={inflowCheckBoxes.live}
                onChange={(val) => {
                  createOnInflow("live", val.target.checked);
                }}
              />
              <GeneralCheckbox
                label="アーカイブ"
                className="nf-checkbox-margin"
                checked={inflowCheckBoxes.archive}
                onChange={(val) => {
                  createOnInflow("archive", val.target.checked);
                }}
              />
              <GeneralCheckbox
                label="動画"
                className="nf-checkbox-margin"
                checked={inflowCheckBoxes.movie}
                onChange={(val) => {
                  createOnInflow("movie", val.target.checked);
                }}
              />
            </div>
            <div className="nf-input-wrapper nf-input-wrapper-middle serchItem-checkBoxes">
              <label class="nf-input-label text-align-center"></label>
              <GeneralCheckbox
                label="Instagram"
                className="nf-checkbox-margin"
                checked={inflowCheckBoxes.instagram}
                onChange={(val) => {
                  createOnInflow("instagram", val.target.checked);
                }}
              />
              <GeneralCheckbox
                label="Twitter"
                className="nf-checkbox-margin"
                checked={inflowCheckBoxes.twitter}
                onChange={(val) => {
                  createOnInflow("twitter", val.target.checked);
                }}
              />
            </div>
          </div>
          <div className="search-item-deleted">
            <Button
            text="検索"
            type="submit"
            disabled={
              !inflowCheckBoxes.coordinate
              && !inflowCheckBoxes.blog
              && !inflowCheckBoxes.live
              && !inflowCheckBoxes.archive
              && !inflowCheckBoxes.movie
              && !inflowCheckBoxes.instagram
              && !inflowCheckBoxes.twitter
            }
            />
          </div>
        </form>
        <label className="annotation">
          ※報酬レートは夜間更新の為、前日分までが参照できます
        </label>
        <div className="table-header">
          <h3>検索結果一覧</h3>
          <Button
            text="CSV出力"
            width="wider"
            onClick={() => dispatch(actions.openPaymentsCsvDownloadModal())}
          />
        </div>
        <Table
          columns={columns}
          dataSource={paymentsList}
          onChange={(pagination) => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{offsetHeader: 60}}
        />
      </div>
      <Footer />
    </div>
  );
};
export default Payments;

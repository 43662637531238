import Header from "../../components/header";
import Button from "../../components/button";
import Footer from "../../components/footer";
import "./style.scss";
import { useState } from "react";
import { useFormik } from "formik";
import GeneralInput from "../../components/generalInput";
import axios from "../../utils/axiosUtil";
import Yup from "../../utils/yupUtil";

const Reserves = () => {
  const [shareUrl, setShareUrl] = useState({ instagram: "", twitter: "" });
  const copyButtonStyle = { margin: 0, marginLeft: 10 };
  const formik = useFormik({
    initialValues: { share_url:"" },
    validationSchema: Yup.object({
      share_url:Yup.string().url(),
    }),
    onSubmit: async values => {
      let res = await axios.post("/analyze/url/create", {
        create_type: 1,
        url: values.share_url,
      });
      setShareUrl(res.item);
    },
  });
  const { share_url } = formik.values;

  return (
    <div className="content-body">
      <Header />
      <div className="shareUrl-container nf-page">
        <h2>SNS分析URL作成</h2>
        <div className="input-area">
          <div className="input-item">
            <GeneralInput
              label="SNSにシェアしたいURL"
              className="sharUrl-input"
              placeholder={"SNSにシェアしたいURLを入力してください"}
              name="share_url"
              formik={formik}
            />
          </div>
          <form className="search-area" onSubmit={formik.handleSubmit}>
            <Button 
              text="作成"
              type="submit"
              theme={ ((share_url === "" || share_url === undefined) || !(formik.isValid)) ? "light-black" : "black" }
              disabled={ ((share_url === "" || share_url === undefined) || !(formik.isValid)) ? true : false }
            />
          </form>
        </div>
        <div className="shareUrl-result">
          <h3>作成結果</h3>
          <div className="shareUrl-item">
            <labe className="shareUrl-label">Instagram</labe>
            <span className="shareUrl-url">{shareUrl.instagram}</span>
            <Button
              style={copyButtonStyle}
              disabled={shareUrl.instagram === ""}
              text="コピー"
              onClick={async () =>
                await navigator.clipboard.writeText(shareUrl.instagram)
              }
            />
          </div>
          <div className="shareUrl-item">
            <labe className="shareUrl-label">Twitter</labe>
            <span className="shareUrl-url">{shareUrl.twitter}</span>
            <Button
              style={copyButtonStyle}
              text="コピー"
              disabled={shareUrl.twitter === ""}
              onClick={async () =>
                await navigator.clipboard.writeText(shareUrl.twitter)
              }
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reserves;

import { Modal, Input } from "antd";
import Button from "../../button";
import LoginInfoModal from "../loginInfoModal";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Yup from "../../../utils/yupUtil";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import { updatePassword } from "../../../slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../slices/accountSlice";
import { actions as messageActions } from "../../../slices/messageSlice";
import crypto from "crypto";
import "./style.scss";

/* パスワード変更モーダル */

const ChangePasswordModal = ({
  modalVisible,
  setModalVisible,
  maskClosable = true,
  login_type,
  provisionalLoginAccount,
  currentPassword,
}) => {
  const dispatch = useDispatch();
  const loginAccount = useSelector((state) => state.account);
  const [infoModal, setInfoModal] = useState({ visible: false });
  useEffect(() => {}, []);
  const validationSchema = {
    new_password: Yup.string().required().password(),
    confirm_password: Yup.string()
      .required()
      .password()
      .test(
        "passwordConfirm",
        "パスワードが一致しません",
        (value, context) => value === context.parent.new_password
      ),
  };
  if (login_type === "1")
    validationSchema.current_password = Yup.string().required().password();
  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_password: "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object(validationSchema),
    onSubmit: async (inputVals) => {
      let body = {};
      if (login_type === "1") {
        body = changePasswordBody(
          loginAccount.user_code,
          loginAccount.corporation_code,
          inputVals.new_password
        );
        body.current_password = crypto
          .createHash("sha256")
          .update(inputVals.current_password, "utf8")
          .digest("hex");
        body.token = loginAccount.token;
        dispatch(updatePassword(body)).then((res) => {
          if (res.type === "updatePassword/fulfilled") {
            dispatch(
              messageActions.setMessage({
                messages: [
                  "変更が完了しました",
                ],
              })
            );
          }else{
            dispatch(
              messageActions.setMessage({
                messages: [
                  "入力情報が異なります",
                ],
              })
            );             
          }
        });
      } else {
        body = changePasswordBody(
          provisionalLoginAccount.user_code,
          provisionalLoginAccount.corporation_code,
          inputVals.new_password
        );
        body.current_password = currentPassword;
        body.token = provisionalLoginAccount.token;
        dispatch(updatePassword(body)).then((res) => {
          if (res.type === "updatePassword/fulfilled") {
            setModalVisible(false);
            setInfoModal({
              title: "変更が完了しました",
              visible: true,
            });
          }
        });
      }
    },
  });
  const changePasswordBody = (urrCd, corpCd, newPassword) => {
    return {
      request_from: "2",
      operator_code:
        login_type === "0"
          ? provisionalLoginAccount.user_code
          : loginAccount.user_code,
      corporation_code:
        login_type === "0"
          ? provisionalLoginAccount.corporation_code
          : loginAccount.corporation_code,
      login_type: login_type,
      new_password: crypto
        .createHash("sha256")
        .update(newPassword, "utf8")
        .digest("hex"),
    };
  };

  return (
    <>
      <LoginInfoModal
        modalVisible={infoModal.visible}
        setModalVisible={(val) => {
          setInfoModal({ ...infoModal, visible: val });
          dispatch(actions.setAccount(provisionalLoginAccount));
        }}
        title={infoModal.title}
      />
      <Modal
        className={"nf-modal-changePassword"}
        visible={modalVisible}
        centered={true}
        footer={null}
        width={500}
        closable={false}
        maskClosable={maskClosable}
        onCancel={() => {
          setModalVisible(false);
        }}
        afterClose={() => {
          formik.resetForm();
        }}
      >
        <div className="nf-modal-changePassword-content">
          <div className="nf-modal-changePassword-title">パスワード変更</div>
          <div className="nf-modal-changePassword-form">
            {login_type === "1" && (
              <div className="changePassword-input-wrapper">
                <div className="changePassword-label-area">
                  <span className="changePassword-label">現在のパスワード</span>
                  <span className="changePassword-requiredMark">
                    {formik?.touched?.current_password &&
                      formik?.errors?.current_password}
                  </span>
                </div>
                <Input.Password
                  className="input-changePassword"
                  name="current_password"
                  placeholder={"現在のパスワードを入力してください"}
                  onBlur={formik.handleBlur}
                  visibilityToggle={false}
                  onChange={(e) => {
                    handlerFormikFieldChange(
                      formik,
                      "current_password",
                      e.target.value
                    );
                  }}
                />
              </div>
            )}
            <div className="changePassword-input-wrapper">
              <div className="changePassword-label-area">
                <span className="changePassword-label">新しいパスワード</span>
                <span className="changePassword-requiredMark">
                  {formik?.touched?.new_password &&
                    formik?.errors?.new_password}
                </span>
              </div>
              <Input.Password
                className="input-changePassword"
                name="new_password"
                placeholder={"パスワードを入力してください"}
                visibilityToggle={false}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  handlerFormikFieldChange(
                    formik,
                    "new_password",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="changePassword-input-wrapper">
              <div className="changePassword-label-area">
                <span className="changePassword-label">
                  新しいパスワード(再入力)
                </span>
                <span className="changePassword-requiredMark">
                  {formik?.touched?.confirm_password &&
                    formik?.errors?.confirm_password}
                </span>
              </div>
              <Input.Password
                className="input-changePassword"
                name="confirm_password"
                placeholder={"パスワードを入力してください"}
                onBlur={formik.handleBlur}
                visibilityToggle={false}
                onChange={(e) => {
                  handlerFormikFieldChange(
                    formik,
                    "confirm_password",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
          <Button
            contentEditable={false}
            text="変更"
            onClick={formik.handleSubmit}
            disabled={!formik.isValid}
          />
        </div>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { downloadFile } from "../utils/fnUtil";
import { actions as messageActions } from "../slices/messageSlice";
import moment from "moment";

export const fetchAnalyzeShop = createAsyncThunk(
  "fetchAnalyzeShop",
  async (params) => axios.get("analyze/shop/list", { params })
);

export const fetchShopFavoriteItem = createAsyncThunk(
  "fetchShopFavoriteItem",
  async (params) => axios.get("csv/favorite_item", { params })
);

export const updateCsvShopFavoriteItem = createAsyncThunk(
  "updateCsvShopFavoriteItem",
  async (params, {dispatch}) => {
    const updateFavoriteRes = await axios.post("csv/favorite_item/update", params)
    if (updateFavoriteRes?.code === "0")
      dispatch(
        messageActions.setMessage({
          messages:["お気に入り登録が完了しました"],
        })
      );
    return params.favorite;
  }
);

export const csvDownloadShop = createAsyncThunk(
  "csvDownloadShop",
  async (params) => await axios.get("csv/download", { params })
);

const initialSearchParams = {
  start_date: "",
  end_date: "",
  contents_type: [],
  shop_code: [],
  offset: 0,
  count: 10,
  sort: "shop_name_desc",
};

const initialState = {
  analyzeList: [],
  filterList: {
    contents_type: [],
    shop_info: [],
  },
  showFields: [],
  favorite_item: {},
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  filterContentsVisible: false,
  filterShopVisible: false,
  infomationModal: false,
  infomationTitle: "",
  searchParams: initialSearchParams,
  total: 0,
  flag:false,
};

export const analyzeShopSlice = createSlice({
  name: "analyzeShopSlice",
  initialState: initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      filterContentsVisible: false,
      filterShopVisible: false,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    changeFlag: (state) => ({
      ...state,
      flag: true,
    }),
    openFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: true,
    }),
    openFilterShopModal: (state) => ({
      ...state,
      filterShopVisible: true,
    }),
    openSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: true,
    }),
    openCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    openInfomationModal: (state, { payload }) => ({
      ...state,
      infomationModal: true,
      infomationTitle: payload,
    }),
    closeFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: false,
    }),
    closeFilterShopModal: (state) => ({
      ...state,
      filterShopVisible: false,
    }),
    closeSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: false,
    }),
    closeCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    closeInfomationModal: (state) => ({
      ...state,
      infomationModal: false,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyzeShop.fulfilled, (state, { payload }) => ({
      ...state,
      analyzeList: payload?.item ?? [],
      filterList: payload?.filter_list
        ? {
          contents_type: payload?.filter_list?.contents_type ?? [],
          shop_info: state.flag ? payload?.filter_list?.shop_info ?? [] : state.filterList.shop_info,
          }
        : { contents_type: [], shop_info: [] },
      total: payload?.total || 0,
      flag:false,
    }));
    builder.addCase(fetchShopFavoriteItem.fulfilled, (state, { payload }) => ({
      ...state,
      favoriteItem: payload?.item?.favorite ?? {},
    }));
    builder.addCase(
      updateCsvShopFavoriteItem.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          favoriteItem: payload,
        };
      }
    );
    builder.addCase(csvDownloadShop.fulfilled, (state, { payload }) => {
      if (typeof payload === "string") {
        downloadFile(
          payload,
          `店舗別分析_${moment().format("YYYYMMDDHHmmss")}.csv`
        );
      }
      return {
        ...state,
        infomationModal: payload?.code === "-1",
        infomationTitle: "出力データが存在しません",
        csvDownloadModalVisible: false,
      };
    });
  },
});

export const actions = analyzeShopSlice.actions;

export default analyzeShopSlice.reducer;

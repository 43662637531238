import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";

export const fetchRanking = createAsyncThunk("fetchRanking", async (params) =>
  axios.get("/analyze/user/ranking", { params })
);

const initialSearchParams = {
  aggregate_type: [],
  output: "",
  aggregate_unit: "",
  start_date: "",
  end_date: "",
  sort: "sales",
  offset: 0,
  count: 10,
  image_height: 130,
};

const initialState = {
  topRankList: [],
  buttomRankList: [],
  searchParams: initialSearchParams,
  total: 0,
  paging_total: 0,
};

export const rankingSlice = createSlice({
  name: "ranking",
  initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
      },
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRanking.fulfilled, (state, { payload }) => {
      let item = payload?.item ?? [];
      let top = item.slice(0, 5);
      let bottom = item.slice(5, 10);
      if (top.length > 0 && top.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (top.length < 5) top.push({ dummy: true });
        }
      }
      if (bottom.length > 0 && bottom.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (bottom.length < 5) bottom.push({ dummy: true });
        }
      }
      return {
        ...state,
        topRankList: top,
        buttomRankList: bottom,
        total: payload?.total || 0,
        paging_total: payload?.paging_total || 0,
      };
    });
  },
});

export const actions = rankingSlice.actions;

export default rankingSlice.reducer;

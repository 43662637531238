import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Table, DatePicker, Image } from "antd";
import Icon from "@ant-design/icons";
import { useUpdateEffect } from "../../utils/hooksUtil";
import SelectColumsModal from "../../components/modal/selectCloumsModal";
import CsvDownloadModal from "../../components/modal/csvDownloadModal";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  fetchBrandFavoriteItem,
  fetchAnalyzeBrand,
  updateCsvBrandFavoriteItem,
  csvDownloadBrand,
} from "../../slices/analyzeBrandSlice";
import { validDatePickChecker, toMoneyString } from "../../utils/fnUtil";
import InfomationModal from "../../components/modal/infomationModal";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import "./style.scss";
const AnalyzeBrand = () => {
  const dispatch = useDispatch();
  const {
    analyzeList,
    searchParams,
    total,
    selectColumsVisible,
    showFields,
    csvDownloadModalVisible,
    filterContentsVisible,
    filterBrandVisible,
    filterList,
    favoriteItem,
    infomationModal,
    infomationTitle,
    flag,
  } = useSelector((state) => state.analyzeBrand);
  const pageTitle = "ブランド別分析 - コーディネート・ブログ・ライブ配信・動画";
  const columns = [
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span onClick={createOnClikSort("brand_name")}>
            {getSortIcon(formik.values.sort === "brand_name_asc")}
          </span>
          &nbsp;&nbsp;
          <Icon
            component={
              filterList.brand_info.length === 0
                ? ""
                : searchParams.brand_code.length === 0
                ? Icons.FilterIcon
                : Icons.FilterUsedIcon
            }
            onClick={() => {
              dispatch(actions.openFilterBrandModal());
            }}
          />
        </>
      ),
      dataIndex: "brand_name",
      key: "brand_name",
      label: "ブランド",
      align: "center",
      width: TableColumnWidth.brandName,
      render: (val) => val || "-",
    },
    {
      title: () => (
        <>
          <span>コンテンツ種別 </span>
          <span onClick={createOnClikSort("contents_type")}>
            {getSortIcon(formik.values.sort === "contents_type_asc")}
          </span>
          &nbsp;&nbsp;
          <Icon
            component={
              filterList.contents_type.length === 0
                ? ""
                : searchParams.contents_type.length === 0
                ? Icons.FilterIcon
                : Icons.FilterUsedIcon
            }
            onClick={() => {
              dispatch(actions.openFilterContentsModal());
            }}
          />
        </>
      ),
      dataIndex: "contents_type",
      key: "contents_type",
      label: "コンテンツ種別",
      align: "center",
      width: TableColumnWidth.contentType,
      render: (val) => val || "-",
    },
    {
      title: () => (
        <>
          <span>売上 </span>
          <span onClick={createOnClikSort("sales")}>
            {getSortIcon(formik.values.sort === "sales_asc")}
          </span>
        </>
      ),
      key: "earnings",
      dataIndex: "earnings",
      label: "売上",
      align: "center",
      width: TableColumnWidth.earnings,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>件数 </span>
          <span onClick={createOnClikSort("order_count")}>
            {getSortIcon(formik.values.sort === "order_count_asc")}
          </span>
        </>
      ),
      key: "order_count",
      dataIndex: "order_count",
      label: "件数",
      align: "center",
      width: TableColumnWidth.orderCount,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>商品点数 </span>
          <span onClick={createOnClikSort("qty")}>
            {getSortIcon(formik.values.sort === "qty_asc")}
          </span>
        </>
      ),
      key: "qty_count",
      dataIndex: "qty_count",
      label: "商品点数",
      align: "center",
      width: TableColumnWidth.qty,
      render: (val) => toMoneyString(val) || "-",
    },

    {
      title: () => (
        <>
          <span>セット率 </span>
          <span onClick={createOnClikSort("set_rate")}>
            {getSortIcon(formik.values.sort === "set_rate_asc")}
          </span>
        </>
      ),
      key: "set_rate",
      dataIndex: "set_rate",
      label: "セット率",
      align: "center",
      width: TableColumnWidth.setRate,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>PV数/視聴者数/再生回数 </span>
          <span onClick={createOnClikSort("pv")}>
            {getSortIcon(formik.values.sort === "pv_asc")}
          </span>
        </>
      ),
      key: "pv_count",
      dataIndex: "pv_count",
      label: "PV数/視聴者数/再生回数",
      align: "center",
      width: TableColumnWidth.pv,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>CV率 </span>
          <span onClick={createOnClikSort("cv")}>
            {getSortIcon(formik.values.sort === "cv_asc")}
          </span>
        </>
      ),
      key: "cv_rate",
      dataIndex: "cv_rate",
      label: "CV数",
      align: "center",
      width: TableColumnWidth.cvRate,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>客単価 </span>
          <span onClick={createOnClikSort("unit_price")}>
            {getSortIcon(formik.values.sort === "unit_price_asc")}
          </span>
        </>
      ),
      key: "customer_unit_price",
      dataIndex: "customer_unit_price",
      label: "客単価",
      align: "center",
      width: TableColumnWidth.unitPrice,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>いいね数 </span>
          <span onClick={createOnClikSort("like")}>
            {getSortIcon(formik.values.sort === "like_asc")}
          </span>
        </>
      ),
      key: "like_count",
      dataIndex: "like_count",
      label: "いいね数",
      align: "center",
      width: TableColumnWidth.like,
      render: (val) => toMoneyString(val) || "-",
    },
  ];
  const favoriteItemOptions = [
    { label: "ブランドCD", value: "brand_cd" },
    { label: "PV数/視聴者数/再生回数", value: "pv_total" },
    { label: "ブランド名", value: "brand_name" },
    { label: "CV率", value: "cv_rate" },
    { label: "コンテンツ種別", value: "content_flag" },
    { label: "客単価", value: "unit_price" },
    { label: "売上", value: "sales" },
    { label: "いいね数", value: "like_total" },
    { label: "件数", value: "count" },
    null,
    { label: "商品点数", value: "order_qty" },
    null,
    { label: "セット率", value: "set_rate" },
  ];
  const displayColumnOptions = [
    { label: "ブランド", value: "brand_name" },
    { label: "CV率", value: "cv_rate" },
    { label: "コンテンツ種別", value: "contents_type" },
    { label: "客単価", value: "customer_unit_price" },
    { label: "売上", value: "earnings" },
    { label: "いいね数", value: "like_count" },
    { label: "件数", value: "order_count" },
    null,
    { label: "商品点数", value: "qty_count" },
    null,
    { label: "セット率", value: "set_rate" },
    null,
    { label: "PV数/視聴者数/再生回数", value: "pv_count" },
  ];
  const screenType = 4;

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) =>
      dispatch(
        actions.saveSearchParam({
          brand_code: flag? []:searchParams.brand_code,
          start_date: analyzeDate.from?.format("YYYY-MM-DD"),
          end_date: analyzeDate.to?.format("YYYY-MM-DD"),
        })
      ),
  });
  const [analyzeDate, setAnalyzeDate] = useState({ from: null, to: null });

  useEffect(() => {
    return () => dispatch(actions.clear());
  }, []);

  useEffect(() => {
    dispatch(fetchBrandFavoriteItem({ screen_type: screenType }));
    dispatch(actions.saveShowFields(columns));
  }, []);
  useUpdateEffect(() => {
    if (isValidFormVal(searchParams)) {
      dispatch(fetchAnalyzeBrand(searchParams));
    } else {
      dispatch(actions.openInfomationModal("集計期間は必須です"));
    }
  }, [searchParams, dispatch]);
  useEffect(() => {
    dispatch(actions.saveShowFields(columns));
  }, [analyzeList]);
  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      formik.values.sort === desc
        ? asc
        : formik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const datePickerStyle = {
    backgroundColor: "white",
    fontSize: 16,
    height: 32,
    width: 120,
  };
  const getSortIcon = (sort) => {
    return sort ? (
      <>
        &nbsp;
        <Icon component={Icons.SortAscendingIcon} style={{ fontSize: 10 }} />
      </>
    ) : (
      <>
        &nbsp;
        <Icon component={Icons.SortDescendingIcon} style={{ fontSize: 10 }} />
      </>
    );
  };
  const isValidFormVal = (_searchParams) => {
    return _searchParams.start_date && _searchParams.end_date;
  };
  return (
    <div className="content-body">
      <SelectColumsModal
        title="表示する項目を選択してください"
        modalVisible={selectColumsVisible}
        closeModal={() => dispatch(actions.closeSelectColumsModal())}
        options={displayColumnOptions}
        onDisplay={(checkedList) => {
          dispatch(
            actions.saveShowFields(
              columns.filter((col) =>
                checkedList.some((checkedCol) => checkedCol === col.key)
              )
            )
          );
        }}
        chekedValues={displayColumnOptions.map((opt) => opt?.value)}
        colSpan={12}
        initialize={false}
      />
      <SelectColumsModal
        title="絞り込むブランドを選択してください"
        modalVisible={filterBrandVisible}
        closeModal={() => dispatch(actions.closeFilterBrandModal())}
        options={filterList.brand_info
          .map((brand) => {
            if (brand?.brand_code) {
              return {
                label: `${brand.brand_code} ${brand?.brand_name || ""}`,
                value: brand.brand_code,
              };
            }
          })
          .filter((e) => e)}
        onDisplay={(checkedList) => {
          dispatch(actions.saveSearchParam({ brand_code: checkedList }));
        }}
        chekedValues={searchParams.brand_code}
        colSpan={24}
      />
      <SelectColumsModal
        title="絞り込むコンテンツを選択してください"
        modalVisible={filterContentsVisible}
        closeModal={() => dispatch(actions.closeFilterContentsModal())}
        options={
          filterList.contents_type.map((contents) => {
            const contentsValue = (() => {
              if (contents === 'コーディネート') return 'coordinate';
              if (contents === 'ブログ') return 'blog';
              if (contents === 'オンラインライブ') return 'live';
              if (contents === 'アーカイブ') return 'archive';
              if (contents === '動画') return 'movie';
            })();
            if (contentsValue) {
              return { label: contents, value: contentsValue };
            }
          })
          .filter((e) => e)
        }
        onDisplay={(checkedList) => {
          dispatch(actions.saveSearchParam({ contents_type: checkedList }));
        }}
        chekedValues={searchParams.contents_type}
        colSpan={24}
      />
      <CsvDownloadModal
        modalVisible={csvDownloadModalVisible}
        options={favoriteItemOptions.map((opt) => {
          return { ...opt, colSpan: 12 };
        })}
        favoriteItem={favoriteItem}
        onFavoriteItem={(checkedList) => {
          let param = {
            screen_type: screenType,
            favorite: {},
          };
          favoriteItemOptions.forEach(
            (item) => (param.favorite[item?.value] = "0")
          );
          checkedList.forEach((item) => (param.favorite[item] = "1"));
          dispatch(updateCsvBrandFavoriteItem(param));
        }}
        onClose={() => dispatch(actions.closeCsvDownloadModal())}
        onDownload={(checkedList) => {
          if (isValidFormVal(searchParams)) {
            dispatch(
              csvDownloadBrand({
                screen_type: screenType,
                start_date: searchParams.start_date,
                end_date: searchParams.end_date,
                user_code: searchParams.user_code,
                shop_code: searchParams.shop_code,
                brand_code: searchParams.brand_code,
                columns: checkedList,
              })
            );
          } else {
            dispatch(actions.openInfomationModal("集計期間は必須です"));
          }
        }}
      />
      <InfomationModal
        modalVisible={infomationModal}
        item={{ information_title: infomationTitle }}
        setModalVisible={() => {
          dispatch(actions.closeInfomationModal());
        }}
      />
      <Header />
      <div className="analyzeBrand-container nf-page">
        <h2>{pageTitle}</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center nf-analyze-required">
                集計期間
              </label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={analyzeDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    analyzeDate,
                    setAnalyzeDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={analyzeDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    analyzeDate,
                    setAnalyzeDate
                  );
                }}
              />
            </div>
          </div>
          <Button
            text="検索"
            type="submit"
            disabled={!analyzeDate.from || !analyzeDate.to}
            onClick={() => {
              if (searchParams?.contents_type?.length !== 0 || searchParams?.brand_code?.length !== 0) {
                dispatch(
                  actions.saveSearchParam({
                    contents_type: [],
                    brand_code: [],
                  })
                );
              }
              dispatch(actions.changeFlag());
            }}
          />
        </form>
        <div className="table-header">
          <div className="top-header">
            <Button
              text="CSV出力"
              width="wider"
              style={{ display: "block", marginBottom: 5, marginLeft: "auto" }}
              onClick={() => dispatch(actions.openCsvDownloadModal())}
            />
          </div>
          <div className="bottom-header">
            <h3>検索結果一覧</h3>
            <div style={{ display: "flex" }}>
              <Button
                text="フィルター解除"
                theme="white"
                width="wider"
                disabled={
                  analyzeList.length === 0
                  && searchParams?.contents_type?.length === 0
                  && searchParams?.brand_code?.length === 0
                }
                onClick={() => {
                  dispatch(
                    actions.saveSearchParam({
                      contents_type: [],
                      brand_code: [],
                    })
                  );
                }}
              />
              <Button
                text="表示切替"
                width="wider"
                style={{
                  backgroundColor: "#ffffff",
                  border: "none",
                  color: "black",
                  marginLeft: 15,
                }}
                onClick={() => {
                  dispatch(actions.openSelectColumsModal());
                }}
              />
            </div>
          </div>
        </div>
        <Table
          columns={showFields}
          dataSource={analyzeList}
          onChange={(pagination) => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{offsetHeader: 60}}
        />
      </div>
      <Footer />
    </div>
  );
};

export default AnalyzeBrand;

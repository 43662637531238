import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Table, Checkbox } from "antd";
import {
  actions,
  tagsInit,
  fetchTags,
  updateTag,
  allUpdateTag,
} from "../../slices/tagsSlice";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import Icon from "@ant-design/icons";
import "./style.scss";
import GenernalInput from "../../components/generalInput";
import GeneralCheckbox from "../../components/cusCheckbox/genernalCheckbox";
import StatusBox from "../../components/statusBox";
import DeleteModal from "../../components/modal/deleteModal";
import Button from "../../components/button";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SelectUpdateModal from "../../components/modal/selectUpdateModal";
import IconImage from "../../constants/Icons";
import TagEditModal from "../../components/modal/tagEditModal";
import TableColumnWidth from '../../constants/tableColumnWidth';

const Tag = () => {
  const location = useLocation();
  // 定義
  const dispatch = useDispatch();
  const {
    brands, categories, total, pageSize, tagsList, statusCountMap,
    deleteModalVisible, searchParams, activeTag, updateParams, updateModalVisible
  } = useSelector((state) => state.tags);
  // 内部変数
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) => dispatch(actions.saveSearchParam(values)),
  });
  const updateFormik = useFormik({
    initialValues: updateParams
  });
  const { selectedRowKeys, allUpdateButtonStatus, updateList } = updateFormik.values;
  const approvalStatus = [
    { status: 0, nstatus: "unapproved", name: "承認待ち" },
    { status: 1, nstatus: "unpublised", name: "承認済み(未公開)" },
    { status: 2, nstatus: "published", name: "公開中" },
    { status: 3, nstatus: "end_published", name: "公開終了" },
  ];
  const createOnClikSort = (field) => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };

  // 初期データ取得
  useEffect(() => {
    dispatch(tagsInit({
      ...searchParams, 
      status : location?.state?.status_code ? parseInt(location?.state.status_code) : 999 }))
    return () => dispatch(actions.clear());
  }, []);
  useUpdateEffect(() => {
    handlerFormikFieldChange(updateFormik, "selectedRowKeys", []);
    handlerFormikFieldChange(updateFormik, "allUpdateButtonStatus", false);
    handlerFormikFieldChange(updateFormik, "updateList", []);
    dispatch(fetchTags(searchParams));
  },[searchParams, dispatch]);

  //　テーブル定義
  const columns = [
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {formik.values.sort === "brand_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 12 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 12 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      width:TableColumnWidth.brandName,
      render: (brand_name) => (
        <div className="table-colum-title-cell brand-name">
          {brand_name || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>タグCD </span>
          <span className="sort-span" onClick={createOnClikSort("tag_code")}>
            {formik.values.sort === "tag_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "tag_code",
      key: "tag_code",
      align: "center",
      width:TableColumnWidth.tag.tagCd,
      render: (tag_code) => (
        <div className="table-colum-title-cell tag-code">{tag_code || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>タグ名 </span>
          <span className="sort-span" onClick={createOnClikSort("tag_name")}>
            {formik.values.sort === "tag_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "tag_name",
      key: "tag_name",
      align: "center",
      width:TableColumnWidth.tag.tagName,
      render: (tag_name) => (
        <div className="table-colum-title-cell tag-title">
          {tag_name || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>カテゴリ </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("tag_category_name")}
          >
            {formik.values.sort === "tag_category_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "tag_category_name",
      key: "tag_category_name",
      align: "center",
      width:TableColumnWidth.categoryName,
      render: (tag_category_name) => (
        <div className="table-colum-title-cell category-title">
          {tag_category_name || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>申請者 </span>
          <span className="sort-span" onClick={createOnClikSort("applicant")}>
            {formik.values.sort === "applicant_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "applicant_name",
      key: "applicant_name",
      align: "center",
      width:TableColumnWidth.tag.applicantName,
      render: (applicant_name, record) => (
        <>
          {applicant_name !== "" ? (
            <div className="table-colum-title-cell applicant_name">
              <span>{applicant_name}</span>
              <br />
              {record.user_name !== "" &&
              (<>
                <span>({record.user_name})</span>
              </>
              )}
            </div>
          ) : (
            <span>-</span>
          )}
        </>
      ),
    },
    {
      title: () => (
        <>
          <span>承認ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("status")}>
            {formik.values.sort === "status_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "status",
      key: "status",
      align: "center",
      width:TableColumnWidth.tag.status,
      render: (status) => (
        <>
          {
            <div className="table-colum-title-cell status">
              {parseInt(status) < 4
                ? approvalStatus[parseInt(status)].name
                : "削除済み"}
            </div>
          }
        </>
      ),
    },
    {
      title: () => (
        <>
          <span>公開開始日 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("publicstartdate")}
          >
            {formik.values.sort === "publicstartdate_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      width:TableColumnWidth.date,
      render: (start_date) => (
        <div className="table-colum-title-cell start-date">
          {start_date || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>公開終了日 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("publicenddate")}
          >
            {formik.values.sort === "publicenddate_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      width:TableColumnWidth.date,
      render: (end_date) => (
        <div className="table-colum-title-cell end-date">{end_date || "-"}</div>
      ),
    },
    {
      title: "編集",
      align: "center",
      dataIndex: "delete_flg",
      key: "delete_flg",
      width:TableColumnWidth.icon,
      render: (delete_flg, record) => (
        <>
          { delete_flg === 0 ? (
            <Icon
              // className="table-colum-title-cell icon"
              component={IconImage.EditIcon}
              style={{ fontSize: 25 }}
              onClick={() => dispatch(actions.updateEditModal(record))}
            />
          ) : (
            <Icon
              component={IconImage.DisableEditIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
    {
      title: "削除",
      align: "center",
      dataIndex: "delete_flg",
      key: "delete_flg",
      width:TableColumnWidth.icon,
      render: (delete_flg, record) => (
        <>
          {delete_flg === 0 ? (
            <Icon
              // className="table-colum-title-cell icon"
              component={IconImage.RemoveIcon}
              style={{ fontSize: 25 }}
              onClick={() => dispatch(actions.openDeleteModal(record))}
            />
          ) : (
            <Icon
              component={IconImage.DisableRemoveIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
  ];

  //　一括承認ワーク
  const onSelectChange = (selectedRowKeys) => {
    const allUpdateButtonStatus =
      selectedRowKeys.filter(
        (x) => tagsList[x].delete_flg === 0 && tagsList[x].status === "0"
      ).length > 0;
    const updateList = selectedRowKeys
      .filter((x) => tagsList[x].delete_flg === 0 && tagsList[x].status === "0")
      .map((x) => {
        return tagsList[x].tag_code;
      });
      handlerFormikFieldChange(updateFormik, "selectedRowKeys", selectedRowKeys);
      handlerFormikFieldChange(updateFormik, "allUpdateButtonStatus",allUpdateButtonStatus);
      handlerFormikFieldChange(updateFormik, "updateList", updateList);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (recode) => ({
      disabled: recode.delete_flg === 1 || recode.status !== "0",
    }),
  };
  // 一括更新
  const bulkUpdate = () => {
    dispatch(allUpdateTag({ tag_code: updateList }));
  };

  return (
    <>
      <div className="content-body">
        <Header />
        <div>
          <div className="tag-container nf-page">
            <h2>タグ一覧</h2>
            <div className="total-data">
              登録タグ数 <span>{total}</span>
            </div>
            <div className="status-box-area">
              <StatusBox
                status={approvalStatus[0].name}
                count={statusCountMap.unapproved ?? "0"}
                warning={true}
                onClick={() =>
                  dispatch(actions.refineSearch(approvalStatus[0].status))
                }
              />
              <StatusBox
                status={approvalStatus[1].name}
                count={statusCountMap.unpublised ?? "0"}
                warning={true}
                onClick={() =>
                  dispatch(actions.refineSearch(approvalStatus[1].status))
                }
              />
              <StatusBox
                status={approvalStatus[2].name}
                count={statusCountMap.published ?? "0"}
                onClick={() =>
                  dispatch(actions.refineSearch(approvalStatus[2].status))
                }
              />
              <StatusBox
                status={approvalStatus[3].name}
                count={statusCountMap.end_published ?? "0"}
                onClick={() =>
                  dispatch(actions.refineSearch(approvalStatus[3].status))
                }
              />
            </div>
            <form className="search-area" onSubmit={formik.handleSubmit}>
              <div className="search-item">
                <GenernalInput
                  label="ブランド"
                  labelWidth="wider"
                  mode="multiple"
                  placeholder={"ブランドを選択してください"}
                  value={formik.values.brand_code}
                  onChange={formik.setFieldValue}
                  options={brands}
                  formik={formik}
                  name={"brand_code"}
                />
                <GenernalInput
                  label="タグCD"
                  labelWidth="wider"
                  placeholder="タグCDを入力してください"
                  name="tag_code"
                  formik={formik}
                />
                <GenernalInput
                  label="タグ名"
                  labelWidth="wider"
                  placeholder="タグ名を入力してください"
                  name="tag_name"
                  formik={formik}
                  {...formik.getFieldProps("tag_name")}
                />
                <GenernalInput
                  label="カテゴリ名"
                  labelWidth="wider"
                  mode="multiple"
                  placeholder={"タグカテゴリ名を選択してください"}
                  value={formik.values.tag_category_code}
                  onChange={formik.setFieldValue}
                  options={categories}
                  formik={formik}
                  name={"tag_category_code"}
                />
              </div>
              <div className="search-item-deleted">
                <Button text="検索" type="submit" />
                <GeneralCheckbox
                  label="削除済み"
                  className="search-item-checkbox"
                  checked={formik.values.deleted_flg === 1}
                  onChange={() =>
                    handlerFormikFieldChange(
                      formik,
                      "deleted_flg",
                      formik.values.deleted_flg === 1 ? 0 : 1
                    )
                  }
                />
              </div>
            </form>
            <div className="table-header">
              <h3>検索結果一覧</h3>
              <text>
                ※即時公開せず承認する場合は、編集画面から更新してください
              </text>
              <div className="button">
                <Button
                  text="承認/公開"
                  width="wider"
                  disabled={allUpdateButtonStatus ? false : true}
                  theme={allUpdateButtonStatus ? "black" : "light-black" }
                  onClick={() => dispatch(actions.openUpdateModal())}
                />
              </div>
              <div className="button">
                <Button
                  text="新規作成"
                  width="wider"
                  theme="white"
                  onClick={() => dispatch(actions.newEditModal())}
                />
              </div>
            </div>
            <div className="nf-table-tags">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={tagsList.map((data, index) => {
                  return { ...data, key: index };
                })}
                onChange={(pagination) => {
                  const offset =
                    pagination.current === 1
                      ? 0
                      : pagination.pageSize * (pagination.current - 1);
                  dispatch(actions.saveOffset(offset));
                  formik.setFieldValue("offset", offset);
                }}
                pagination={{
                  total: pageSize,
                  pageSize: 10,
                  current: (searchParams?.offset || 0) / 10 + 1,
                  position: ["bottomCenter"],
                  showQuickJumper: false,
                  size: "small",
                }}
                sticky={{offsetHeader: 60}}
              />
            </div>
          </div>
          <Footer />
        </div>
        <TagEditModal />
        <DeleteModal
          modalVisible={deleteModalVisible}
          onCancel={() => dispatch(actions.closeDeleteModal())}
          item={"タグ"}
          itemValue={activeTag?.tag_name}
          onOk={() => {
            dispatch(
              updateTag({
                division: "delete",
                brand_code: activeTag.brand_code,
                tag_category_code: activeTag.tag_category_code,
                tag_code: activeTag.tag_code,
              })
            );
          }}
        />
        <SelectUpdateModal
          modalVisible={updateModalVisible}
          item="タグ"
          onCancel={() => dispatch(actions.closeUpdateModal())}
          onOk={() => bulkUpdate()}
        />
      </div>
    </>
  );
};

export default Tag;

import { Modal } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../slices/reservesSlice";
import "./style.scss";

const ReserveDetailModal = () => {
  const dispatch = useDispatch();
  const { reserveDetailModalVisible, activeReserve } = useSelector(
    (state) => state.reserves
  );
  const [shopData, setShopData] = useState([]);

  useEffect(() => {
    createDisplayShopInfo();
  }, [reserveDetailModalVisible, activeReserve]);

  const createDisplayShopInfo = () => {
    const shopList = activeReserve?.shop_list;
    if (!shopList) return;
    const brandMap = {};
    const brandCdList = shopList?.map(e => e.brand_list)?.map(e => e?.map(e => {
      brandMap[e.brand_code] = e.brand_name;
      console.log(e.brand_name);
      return e.brand_code;
    })).flat().filter((e, i, s) => s.indexOf(e) === i).sort();
    const list = [];
    for (const brandCd of brandCdList) {
      const brandName = brandMap[brandCd];
      const element = {
        brandCd,
        brandName,
        shopNames: [],
      };
      list.push(element);
    }
    for (const shop of shopList) {
      for (const data of list) {
        const target = shop.brand_list.filter(e => e.brand_code === data.brandCd);
        if (target.length > 0) {
          data.shopNames.push(shop.shop_name);
        }
      }
    }
    setShopData(list);
  };

  const getMomentTagertWeekOfMonthNum = (date) => {
    const targetMoment = moment(date);
    const startOfMonthOfWeekNum = moment(
      targetMoment.clone().startOf("month")
    ).format("w");
    const targetOfYearOfWeekNum = targetMoment.clone().format("w");
    return targetOfYearOfWeekNum - startOfMonthOfWeekNum + 1;
  };

  return (
    <>
      <Modal
        visible={reserveDetailModalVisible}
        width={600}
        footer={null}
        closable={false}
        maskClosable={true}
        onCancel={() => dispatch(actions.closeReserveDetailModal())}
        destroyOnClose={true}
      >
        <div className="reserveDetailModal-area">
          <div className="title-area">
            <span className="text-title">配信内容</span>
          </div>
          <label>配信区分</label>
          <span>
            {activeReserve?.send_type === "1" ? "予約配信" : "即時配信"}
          </span>
          <label>{activeReserve?.repeat_type === "1" ? "開始日" : "配信日"}</label>
          <span>
          {activeReserve?.repeat_type === "1" ?
            moment(
                  `${activeReserve?.repeat_start_date} ${activeReserve?.repeat_send_time}`,
                  "YYYY-MM-DD HH:mm"
                ).format("YYYY.MM.DD HH:mm")
            :
            moment(
              `${activeReserve?.send_plan_date} ${activeReserve?.send_reserve_time}`,
              "YYYY-MM-DD HH:mm"
            ).format("YYYY.MM.DD HH:mm")
          }
          </span>
          {activeReserve?.repeat_type === "1" && (
            <>
              <label>繰り返し間隔</label>
              <span>
                {activeReserve?.repeat_period === "day" && (
                  `${activeReserve?.timing?.day}日毎`
                )}
                {activeReserve?.repeat_period === "week" && (
                  `毎週${moment(activeReserve?.repeat_start_date).format("dddd")}毎`
                )}
                {activeReserve?.repeat_period === "month" && (
                  activeReserve?.timing?.month?.month_day ?
                    `毎月${activeReserve?.timing?.month?.month_day}日` :
                    `毎月第${getMomentTagertWeekOfMonthNum(activeReserve?.repeat_start_date)}週${moment(activeReserve?.repeat_start_date).format("dddd")}`
                )}
              </span>
            </>
          )}
          {activeReserve?.repeat_type === "1" && (
            <>
              <label>終了日</label>
              <span>
                {moment(
                  `${activeReserve?.repeat_end_date} ${activeReserve?.repeat_send_time}`,
                  "YYYY-MM-DD HH:mm"
                ).format("YYYY.MM.DD HH:mm")}
              </span>
            </>
          )}
          <label>開封結果</label>
          <span>
            {`全体数:${activeReserve?.document_count}
              開封数:${activeReserve?.document_read_count}
              未開封数:${activeReserve?.document_unread_count}`}
          </span>
          <label>絞り込み条件</label>
          <span>
            {activeReserve?.cond_flag === "0"
              ? "絞り込み条件設定無"
              : "絞り込み条件設定有"}
          </span>
          {activeReserve?.cond_flag === "1" ? (
            <>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
                <label style={{ alignSelf: 'flex-start', marginTop: 0 }}>所属</label>
                <div>
                  {shopData.length > 0 ? shopData.map(e => {
                    return (
                      <>
                        <div>{e.brandName}</div>
                        {e.shopNames.map(shopName => <div style={{ marginLeft: '20px' }}>{shopName}</div>)}
                      </>
                    );
                  }) : <>-</>}
                </div>
              </div>
              <label>IDリスト</label>
              <span>{activeReserve?.upload_file_name ?? '-'}</span>
            </>
          ) : (
            ""
          )}
        </div>
      </Modal>
    </>
  );
};

export default ReserveDetailModal;

import { Image } from "antd";
import { useDrag, useDrop } from "react-dnd";
import { caculateTaxIncludePrice } from "../../../utils/fnUtil";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import BaseUpload from "../../baseUpload";
import ProductInfoCard from "../../productInfoCard";

export const ItemTypes = {
  IMAGE_SELECTOR: "image_selector",
};

const DraggableItem = ({
  image,
  index,
  onClick,
  onExchange,
  onCancle,
  contentDisable,
  editable,
  isProduct,
}) => {
  const [, drag] = useDrag(
    () => ({
      type: ItemTypes.IMAGE_SELECTOR,
      item: { dragIndex: index },
      canDrag: !!editable,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        dropResult && onExchange(item.dragIndex, dropResult.dropIndex);
      },
    }),
    [index, onClick, onExchange, onCancle]
  );

  const [, drop] = useDrop({
    accept: ItemTypes.IMAGE_SELECTOR,
    drop: () => ({ dropIndex: index }),
  });

  return (
    <div className="live-image-container" ref={node => drag(drop(node))}>
      <div className="image-select">
        {image && !contentDisable && (
          <Icon
            component={Icons.CloseIcon}
            className="close-icon"
            onClick={e => onCancle(e, index)}
          />
        )}
        {isProduct ? (
          image?.image_url ? (
            <ProductInfoCard
              productInfo={{
                ...image,
                tax_included_price: caculateTaxIncludePrice(
                  image.selling_price,
                  image.tax_rate
                ),
              }}
            />
          ) : (
            editable && (
              <Button
                text="商品を追加"
                disabled={contentDisable}
                theme="white"
                onClick={() => onClick(index)}
              />
            )
          )
        ) : image ? (
          <Image src={image} preview={false} className="live-img" />
        ) : (
          editable && (
            <BaseUpload
              onChange={info => onClick(info, index)}
              disabled={contentDisable}
            >
              <Button
                text="画像を追加"
                disabled={contentDisable}
                theme="white"
              />
            </BaseUpload>
          )
        )}
      </div>
      <span className="image-number">
        {isProduct ? "商品" : "画像"}
        {index + 1}
      </span>
    </div>
  );
};

export default DraggableItem;

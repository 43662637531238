import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import { Select, DatePicker, Pagination } from "antd";
import {
  actions,
  staffResultInit,
  fetchUserContents,
  fetchUserSns,
  fetchAnalyzeCoordinate,
  fetchAnalyzeBlog,
  fetchAnalyzeLive,
} from "../../slices/staffResultSlice";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import { toMoneyString } from "../../utils/fnUtil";
import PersonalIcon from "../../components/personalIcon";
import StaffResultHeader from "../../components/staffResultHeader";
import StaffResultSnsHeader from "../../components/staffResultSnsHeader";
import StaffResultSummary from "../../components/staffResultSummary";
import "./style.scss";
import { useUpdateEffect } from "../../utils/hooksUtil";
const StaffResult = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    staff,
    brands,
    userParam,
    userContaints,
    userSns,
    contents,
    currentTab,
    containtsParam,
    contentsTotal,
    currentContaintsTab,
  } = useSelector((state) => state.staffResult);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    if (location?.state?.staff_code) {
        dispatch(staffResultInit(location.state.staff_code));
    }
    return () => dispatch(actions.clear());
  }, []);
  useUpdateEffect(() => {
    if (!userParam.user_code) {
      history.goBack();
      return;
    }
    switch (currentTab) {
      case "fetchUserContents":
        dispatch(fetchUserContents(userParam));
        break;
      case "fetchUserSns":
        dispatch(fetchUserSns(userParam));
        break;
      case "fetchContents":
        if (currentContaintsTab === "coordinate")
          dispatch(
            fetchAnalyzeCoordinate({
              ...containtsParam,
            })
          );
        if (currentContaintsTab === "blog")
          dispatch(
            fetchAnalyzeBlog({
              ...containtsParam,
            })
          );
        if (currentContaintsTab === "live")
          dispatch(
            fetchAnalyzeLive({
              ...containtsParam,
              live_flag: 0,
            })
          );
        if (currentContaintsTab === "archive")
          dispatch(
            fetchAnalyzeLive({
              ...containtsParam,
              live_flag: 1,
            })
          );
        if (currentContaintsTab === "movie")
          dispatch(
            fetchAnalyzeLive({
              ...containtsParam,
              live_flag: 2,
            })
          );
        break;
      default:
        break;
    }
  }, [currentTab, userParam, containtsParam, currentContaintsTab, dispatch]);
  const today = moment();
  const buttonStyle = {
    color: "#bdbdbd",
    height: 50,
    backgroundColor: "white",
    fontWeight: "bold",
    borderWidth: "0px 0px 1px 0px",
    width: 150,
    fontSize: "1rem",
    borderRadius: 0,
  };
  const selectedButtonStyle = {
    height: 50,
    backgroundColor: "white",
    fontWeight: "bold",
    borderWidth: "0px 0px 1px 0px",
    width: 150,
    fontSize: "1rem",
    borderRadius: 0,
  };
  const getRankCard = (data, i) => {
    return data.dummy ? (
      <div
        className="containts-rankCard"
        style={{ visibility: "hidden" }}
      ></div>
    ) : (
      <div className="containts-rankCard">
        <span className="ranking-no">{data?.rank}</span>
        <div style={{marginTop: currentContaintsTab !== "coordinate" && 15}} />
        <PersonalIcon 
          imgSrc={data?.image_url ?? data.title_image_url}
          width={140} 
          height={currentContaintsTab !== "coordinate" ? 140 : 185} 
          borderRadius={0} 
          cardIconFlag={true} 
          cursor={'default'} 
        />
        <div style={{marginBottom: currentContaintsTab !== "coordinate" && 15}} />
        {currentContaintsTab !== "coordinate" ? (
          <p className="title">{data?.title}</p>
        ) : (
          ""
        )}
        <p className="card-column">
          <span>売上</span>
          <span>￥ {toMoneyString(data?.earnings) || "-"}</span>
        </p>
        <p className="card-column">
          <span>{getPvName()}</span>
          {"　"}
          <span>{toMoneyString(data?.pv_count) || "-"}</span>
        </p>
        <p className="card-column">
          <span>いいね</span>
          {"　"}
          <span>{toMoneyString(data?.like_count) || "-"}</span>
        </p>
        <p className="rank-publishedAt">
          {getpublishedAtName()}：
          {data?.published_at
            ? moment(data.published_at).format("YYYY.MM.DD")
            : ""}
        </p>
      </div>
    );
  };

  const getPvName = () => {
    if (currentContaintsTab === "coordinate") return 'PV';
    if (currentContaintsTab === "blog") return 'PV';
    if (currentContaintsTab === "live") return '視聴者数';
    if (currentContaintsTab === "archive") return '再生回数';
    if (currentContaintsTab === "movie") return '再生回数';
    return "";
  };

  const getpublishedAtName = () => {
    if (currentContaintsTab === "coordinate") return '公開日';
    if (currentContaintsTab === "blog") return '公開日';
    if (currentContaintsTab === "live") return '配信日';
    if (currentContaintsTab === "archive") return '公開日';
    if (currentContaintsTab === "movie") return '公開日';
    return "";
  };

  const displayDate = () => {
    const startDate = moment(userParam.year_month).format("YYYY.MM") + ".01";
    const endDate = (userParam.year_month === today.format("YYYYMM")
        ? today.add(-1, 'day').format("YYYY.MM.DD")
        : moment(userParam.year_month).endOf('month').format("YYYY.MM.DD"));
    return `${startDate}~${endDate}`;
  };

  return (
    <div className="content-body">
      <Header />
      <div className="staffResult-container nf-page">
        <h2>社員実績</h2>
        <div className="staffDetail-area">
          <PersonalIcon imgSrc={staff?.img} width={130} height={130} borderRadius="50%" cursor={"default"} />
          <div>
            <div className="staffDetail-label">所属</div>
            <div className="staffDetail-column">
              <span>店舗：{staff?.shop_name}</span>
              <span>
                ブランド：
                {(staff?.brand ?? []).map((b) => b.brand_name).join(",")}
              </span>
            </div>
            <div className="staffDetail-label">社員情報</div>
            <div className="staffDetail-column">
              <span
                style={{ borderBottom: "solid 1px", borderColor: "#f3f3f3" }}
              >
                社員CD：{staff?.user_code}
              </span>
              <span
                style={{ borderBottom: "solid 1px", borderColor: "#f3f3f3" }}
              >
                社員：{staff?.name}{" "}
                {staff?.user_nickname ? "(" + staff?.user_nickname + ")" : ""}
              </span>
            </div>
            <div className="staffDetail-column">
              <span>
                性別：{staff?.gender === "1" ? "男性" : ""}
                {staff?.gender === "2" ? "女性" : ""}
              </span>
              <span>身長：{staff?.height}</span>
            </div>
            <div className="staffDetail-label">SNS</div>
            <div className="staffDetail-column">
              <span
                style={{ borderBottom: "solid 1px", borderColor: "#f3f3f3" }}
              >
                Instagram ID：{staff?.sns?.instagram?.insta_id}
              </span>
              <span
                style={{ borderBottom: "solid 1px", borderColor: "#f3f3f3" }}
              >
                フォロワー数：{staff?.sns?.instagram?.follower}
              </span>
            </div>
            <div className="staffDetail-column">
              <span>Twitter ID：{staff?.sns?.twitter?.twitter_id}</span>
              <span>フォロワー数：{staff?.sns?.twitter?.follower}</span>
            </div>
          </div>
        </div>
        <div className="table-header">
          <div className="top-header">
            <p>集計項目</p>
            <label>ブランド</label>
            <Select
              value={userParam.brand_code}
              onChange={(bcode) => dispatch(actions.saveBrandCode(bcode))}
            >
              {brands.map((b) => (
                <Select.Option value={b.value}>{b.label}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="bottom-header">
            <div>
              <span style={{ fontWeight: "bold", fontSize: 17 }}>MONTHLY</span>
              <span style={{ fontSize: 13, marginLeft: 5 }}>
                前月同期間対比
              </span>
              <div style={{ fontSize: 12, color: "#7B7B7B" }}>
                ※当月選択時の集計期間 ：{displayDate()}
              </div>
            </div>
            <div>
              <label>期間</label>
              <DatePicker
                style={{ width: 200 }}
                className="ranking-datepicker"
                picker="month"
                placeholder="年月を指定してください"
                value={moment(userParam.year_month + "")}
                onChange={(date, dateString) =>
                  dispatch(
                    actions.saveTagDate(
                      today.isAfter(date)
                        ? date.format("YYYYMM")
                        : today.startOf("Month").format("YYYYMM")
                    )
                  )
                }
              />
            </div>
          </div>
        </div>
        <div className="contents-parent">
        <div className="tab-block">
          <div className="tab-column">
            <div
              className={
                currentTab === "fetchUserContents" ? "bottom-shadow-none" : "disable-tab left"
              }
              style={
                currentTab === "fetchUserContents"
                  ? {
                      backgroundColor: "white",
                    }
                  : { color: "#808080" }
              }
              onClick={() => {
                setCurrentPage(1);
                dispatch(actions.saveCurrentTab("fetchUserContents"));
              }}
            >
              社内比較-コンテンツ
            </div>
            <div
              className={
                currentTab === "fetchUserSns" ? "bottom-shadow-none" : "disable-tab center"
              }
              style={
                currentTab === "fetchUserSns"
                  ? { backgroundColor: "white" }
                  : { color: "#808080" }
              }
              onClick={() => {
                setCurrentPage(1);
                dispatch(actions.saveCurrentTab("fetchUserSns"));
              }}
            >
              社内比較-SNS
            </div>
            <div
              className={
                currentTab === "fetchContents" ? "bottom-shadow-none" : "disable-tab right"
              }
              style={
                currentTab === "fetchContents"
                  ? { backgroundColor: "white" }
                  : { color: "#808080" }
              }
              onClick={() => {
                setCurrentPage(1);
                dispatch(actions.saveCurrentTab("fetchContents"));
              }}
            >
              コンテンツ分析
            </div>
          </div>
        </div>
        <div className="contents-area">
          {currentTab === "fetchUserContents" && (
            <>
              <StaffResultHeader
                columns={{
                  left: {
                    label: "TOTAL",
                    value: userContaints?.contents_sales_amount,
                    lastMonth: userContaints?.last_month_contents_sales_amount,
                  },
                  rightValues: [
                    {
                      label: "コーディネート",
                      value: userContaints?.coordinate_sales_amount,
                      lastMonth: userContaints?.last_month_coordinate_sales_amount,
                    },
                    {
                      label: "ブログ",
                      value: userContaints?.blog_sales_amount,
                      lastMonth: userContaints?.last_month_blog_sales_amount,
                    },
                    {
                      label: "オンラインライブ",
                      value: userContaints?.live_sales_amount,
                      lastMonth: userContaints?.last_month_live_sales_amount,
                    },
                    {
                      label: "アーカイブ",
                      value: userContaints?.archive_sales_amount,
                      lastMonth: userContaints?.last_month_archive_sales_amount,
                    },
                    {
                      label: "動画",
                      value: userContaints?.movie_sales_amount,
                      lastMonth: userContaints?.last_month_movie_sales_amount,
                    },
                  ],
                }}
              />
              <StaffResultSummary
                rankType="sales"
                datas={[
                  {
                    title: "コーディネート",
                    corp: userContaints?.coordinate_sales_corporation,
                    brand: userContaints?.coordinate_sales_brand,
                    shop: userContaints?.coordinate_sales_shop,
                    amount: userContaints?.coordinate_sales_amount,
                  },
                  {
                    title: "ブログ",
                    corp: userContaints?.blog_sales_corporation,
                    brand: userContaints?.blog_sales_brand,
                    shop: userContaints?.blog_sales_shop,
                    amount: userContaints?.blog_sales_amount,
                  },
                  {
                    title: "オンラインライブ",
                    corp: userContaints?.live_sales_corporation,
                    brand: userContaints?.live_sales_brand,
                    shop: userContaints?.live_sales_shop,
                    amount: userContaints?.live_sales_amount,
                  },
                  {
                    title: "アーカイブ",
                    corp: userContaints?.archive_sales_corporation,
                    brand: userContaints?.archive_sales_brand,
                    shop: userContaints?.archive_sales_shop,
                    amount: userContaints?.archive_sales_amount,
                  },
                  {
                    title: "動画",
                    corp: userContaints?.movie_sales_corporation,
                    brand: userContaints?.movie_sales_brand,
                    shop: userContaints?.movie_sales_shop,
                    amount: userContaints?.movie_sales_amount,
                  },
                ]}
                userAmount={{
                  corp: userContaints?.corporation_user_amount,
                  brand: userContaints?.brand_user_amount,
                  shop: userContaints?.shop_user_amount,
                }}
              />
              <StaffResultSummary
                rankType="pv"
                datas={[
                  {
                    title: "コーディネート",
                    label: "実績(PV数)",
                    corp: userContaints?.coordinate_pv_corporation,
                    brand: userContaints?.coordinate_pv_brand,
                    shop: userContaints?.coordinate_pv_shop,
                    amount: userContaints?.coordinate_pv_amount,
                  },
                  {
                    title: "ブログ",
                    label: "実績(PV数)",
                    corp: userContaints?.blog_pv_corporation,
                    brand: userContaints?.blog_pv_brand,
                    shop: userContaints?.blog_pv_shop,
                    amount: userContaints?.blog_pv_amount,
                  },
                  {
                    title: "オンラインライブ",
                    label: "実績(視聴者数)",
                    corp: userContaints?.live_viewer_corporation,
                    brand: userContaints?.live_viewer_brand,
                    shop: userContaints?.live_viewer_shop,
                    amount: userContaints?.live_viewer_amount,
                  },
                  {
                    title: "アーカイブ",
                    label: "実績(再生回数)",
                    corp: userContaints?.archive_play_corporation,
                    brand: userContaints?.archive_play_brand,
                    shop: userContaints?.archive_play_shop,
                    amount: userContaints?.archive_play_amount,
                  },
                  {
                    title: "動画",
                    label: "実績(再生回数)",
                    corp: userContaints?.movie_play_corporation,
                    brand: userContaints?.movie_play_brand,
                    shop: userContaints?.movie_play_shop,
                    amount: userContaints?.movie_play_amount,
                  },
                ]}
                userAmount={{
                  corp: userContaints?.corporation_user_amount,
                  brand: userContaints?.brand_user_amount,
                  shop: userContaints?.shop_user_amount,
                }}
              />
              <StaffResultSummary
                rankType="like"
                datas={[
                  {
                    title: "コーディネート",
                    corp: userContaints?.coordinate_like_corporation,
                    brand: userContaints?.coordinate_like_brand,
                    shop: userContaints?.coordinate_like_shop,
                    amount: userContaints?.coordinate_like_amount,
                  },
                  {
                    title: "ブログ",
                    corp: userContaints?.blog_like_corporation,
                    brand: userContaints?.blog_like_brand,
                    shop: userContaints?.blog_like_shop,
                    amount: userContaints?.blog_like_amount,
                  },
                  {
                    title: "オンラインライブ",
                    corp: userContaints?.live_like_corporation,
                    brand: userContaints?.live_like_brand,
                    shop: userContaints?.live_like_shop,
                    amount: userContaints?.live_like_amount,
                  },
                  {
                    title: "アーカイブ",
                    corp: userContaints?.archive_like_corporation,
                    brand: userContaints?.archive_like_brand,
                    shop: userContaints?.archive_like_shop,
                    amount: userContaints?.archive_like_amount,
                  },
                  {
                    title: "動画",
                    corp: userContaints?.movie_like_corporation,
                    brand: userContaints?.movie_like_brand,
                    shop: userContaints?.movie_like_shop,
                    amount: userContaints?.movie_like_amount,
                  },
                ]}
                userAmount={{
                  corp: userContaints?.corporation_user_amount,
                  brand: userContaints?.brand_user_amount,
                  shop: userContaints?.shop_user_amount,
                }}
              />
            </>
          )}
          {currentTab === "fetchUserSns" && (
            <>
              <StaffResultSnsHeader
                columns={{
                  left: {
                    label: "TOTAL",
                    value: userSns?.sns_sales_amount,
                    lastMonth: userSns?.last_month_sns_sales_amount,
                  },
                  rightValues: [
                    {
                      label: "Instagram",
                      value: userSns?.instagram_sales_amount,
                      lastMonth: userSns?.last_month_instagram_sales_amount,
                    },
                    {
                      label: "Twitter",
                      value: userSns?.twitter_sales_amount,
                      lastMonth: userSns?.last_month_twitter_sales_amount,
                    },
                  ],
                }}
              />
              <StaffResultSummary
                rankType="sales"
                datas={[
                  {
                    label: "Instagram",
                    corp: userSns?.instagram_sales_corporation,
                    brand: userSns?.instagram_sales_brand,
                    shop: userSns?.instagram_sales_shop,
                    amount: userSns?.instagram_sales_amount,
                  },
                  {
                    label: "Twitter",
                    corp: userSns?.twitter_sales_corporation,
                    brand: userSns?.twitter_sales_brand,
                    shop: userSns?.twitter_sales_shop,
                    amount: userSns?.twitter_sales_amount,
                  },
                ]}
                userAmount={{
                  corp: userSns?.corporation_user_amount,
                  brand: userSns?.brand_user_amount,
                  shop: userSns?.shop_user_amount,
                }}
              />
            </>
          )}
          {currentTab === "fetchContents" && (
            <>
              <div className="contents-tabArea">
                <Button
                  theme="white"
                  style={
                    currentContaintsTab === "coordinate"
                      ? selectedButtonStyle
                      : buttonStyle
                  }
                  text="コーディネート"
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(actions.saveCurrentContaintsTab("coordinate"));
                  }}
                />
                <Button
                  theme="white"
                  style={
                    currentContaintsTab === "blog"
                      ? selectedButtonStyle
                      : buttonStyle
                  }
                  text="ブログ"
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(actions.saveCurrentContaintsTab("blog"));
                  }}
                />
                <Button
                  theme="white"
                  style={
                    currentContaintsTab === "live"
                      ? selectedButtonStyle
                      : buttonStyle
                  }
                  text="オンラインライブ"
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(actions.saveCurrentContaintsTab("live"));
                  }}
                />
                <Button
                  theme="white"
                  style={
                    currentContaintsTab === "archive"
                      ? selectedButtonStyle
                      : buttonStyle
                  }
                  text="アーカイブ"
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(actions.saveCurrentContaintsTab("archive"));
                  }}
                />
                <Button
                  theme="white"
                  style={
                    currentContaintsTab === "movie"
                      ? selectedButtonStyle
                      : buttonStyle
                  }
                  text="動画"
                  onClick={() => {
                    setCurrentPage(1);
                    dispatch(actions.saveCurrentContaintsTab("movie"));
                  }}
                />
              </div>
              <div
                className="containts-rankingArea"
                style={
                  contents.topRankList.length > 0
                    ? { height: "auto", display: "block" }
                    : {}
                }
              >
                {contents.topRankList.length > 0 ? (
                  <>
                    <div className="cards-area-row">
                      {contents.topRankList.map((data, i) => {
                        return getRankCard(data);
                      })}
                    </div>
                    {contents.buttomRankList.length > 0 ? (
                      <div className="cards-area-row">
                        {contents.buttomRankList.map((data, i) => {
                          return getRankCard(data);
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div class="ant-empty ant-empty-normal">
                    <div class="ant-empty-image">
                      <svg
                        class="ant-empty-img-simple"
                        width="64"
                        height="41"
                        viewBox="0 0 64 41"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          transform="translate(0 1)"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <ellipse
                            class="ant-empty-img-simple-ellipse"
                            cx="32"
                            cy="33"
                            rx="32"
                            ry="7"
                          ></ellipse>
                          <g class="ant-empty-img-simple-g" fill-rule="nonzero">
                            <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                            <path
                              d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                              class="ant-empty-img-simple-path"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <div class="ant-empty-description">データがありません</div>
                  </div>
                )}
              </div>
              <div style={{ margin: 20 }}>
                {contentsTotal != 0 &&
                <Pagination
                  size="small"
                  total={contentsTotal}
                  pageSize={10}
                  current={currentPage}
                  style={{ textAlign: "center" }}
                  onChange={(newPage) => {
                    const dif = newPage - currentPage;
                    const maxPage = Math.floor(contentsTotal / 10) + ((contentsTotal % 10 == 0) ? 0 : 1);
                    let setPage = 0;
                    if (dif < 5 && dif > -5) {
                      setPage = newPage;
                    } else if (dif === 5) {
                      setPage = currentPage + 10;
                    } else if (dif === -5) {
                      setPage = currentPage - 10;
                    } else if (dif > 5) {
                      setPage = maxPage;
                    } else if (dif < -5) {
                      setPage = 1;
                    }
                    if (setPage > maxPage) {
                      setPage = maxPage;
                    }
                    if (setPage < 1) {
                      setPage = 1;
                    }
                    setCurrentPage(setPage);
                    dispatch(
                      actions.saveOffset(setPage === 1 ? 0 : (setPage - 1) * 10)
                    );
                  }}
                />
                }
              </div>
            </>
          )}
        </div>
      </div>
      </div>
      <Footer />
    </div>
  );
};
export default StaffResult;

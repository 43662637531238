import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import { actions as messageActions } from "./messageSlice";
import axios from "../utils/axiosUtil";
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { searchChats } from '../graphql/queries';
import { createChat } from '../graphql/mutations'

const initialState = {
  activeLive: null,
  chats: [],
  viewCount: 0,
  likeCount: 0,
  telop: '',
  live_cd: '',
  useCamera: undefined,
  useMic: undefined,
  deviceId: '',
  liveCloseModalVisible: false,
  transitionLiveList: false,
};

const getChatsItems = async (params) => {
  try {
    const chatData = await API.graphql(graphqlOperation(searchChats, params));
    return chatData?.data?.searchChats?.items;
  } catch (err) { console.log('error getChatsItems', err); }
};

export const fetchChats = createAsyncThunk(
  "fetchChats",
  async (params) => await getChatsItems(params) ?? []
);

export const fetchViewCount = createAsyncThunk(
  "fetchViewCount",
  async (params) => await getChatsItems(params) ?? []
);

export const fetchLikeCount = createAsyncThunk(
  "fetchLikeCount",
  async (params) => await getChatsItems(params) ?? []
);

export const fetchTelop = createAsyncThunk(
  "fetchTelop",
  async (params) => await getChatsItems(params) ?? []
);

export const insertChat = createAsyncThunk(
  "insertChat",
  async (params, { dispatch }) => {
    await API.graphql(graphqlOperation(createChat, params));
    if (params?.input?.signaling_flag == "telop") {
      dispatch(
        messageActions.setMessage({
          btnText: "閉じる",
          messages: ["テロップを更新しました"],
        })
      );
    }
  }
);

export const liveFinish = createAsyncThunk(
  "liveFinish",
  async params => await axios.post("live/status/update", params)
);

export const liveDeliverySlice = createSlice({
  name: "liveDelivery",
  initialState,
  reducers: {
    clear: () => initialState,
    setLiveDetail: (state, { payload }) => {
      state.activeLive = payload;
    },
    setTelop: (state, { payload }) => {
      state.telop = payload;
    },
    openLiveClose: state => ({
      ...state,
      liveCloseModalVisible: true,
    }),
    closeLiveClose: state => ({
      ...state,
      liveCloseModalVisible: false,
    }),
    setParam: (state, { payload }) => ({
      ...state,
      live_cd: payload?.live_cd,
      useCamera: payload?.useCamera ?? false,
      useMic: payload?.useMic ?? false,
      deviceId: payload?.deviceId
    }),
    setCamera: (state, { payload }) => ({
      ...state,
      useCamera: payload,
    }),
    setMic: (state, { payload }) => ({
      ...state,
      useMic: payload,
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchChats.fulfilled, (state, { payload }) => ({
      ...state,
      chats: payload ?? [],
    }));

    builder.addCase(fetchViewCount.fulfilled, (state, { payload }) => ({
      ...state,
      viewCount: payload?.length ?? 0,
    }));

    builder.addCase(fetchLikeCount.fulfilled, (state, { payload }) => ({
      ...state,
      likeCount: payload?.reduce((s, e) => s + Number(e.signaling_text) ?? 1, 0) ?? 0,
    }));

    builder.addCase(fetchTelop.fulfilled, (state, { payload }) => ({
      ...state,
      telop: payload?.[0]?.signaling_text ?? '',
    }));

    builder.addCase(liveFinish.fulfilled, (state, { payload }) => ({
      ...state,
      transitionLiveList: true,
    }));
  },
});

export const actions = liveDeliverySlice.actions;

export default liveDeliverySlice.reducer;

import { useState } from "react";
import { Modal, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  csvCheck,
  csvUpload,
  csvDownload,
  uploadActions,
} from "../../../slices/fileUploadSlice";
import Button from "../../button";
import CompleteModal from "../completeModal";
import "./style.scss";

const FileUploadModal = () => {
  const dispatch = useDispatch();
  const {
    checkResult,
    registerEnable,
    screenType,
    successCount,
    errorCount,
    fileUploadModalVisible,
  } = useSelector(state => state.fileUpload);

  const [fileInfo, setFileInfo] = useState(null);

  const { user_code, token, corporation_code } = useSelector(state => state.account ?? {});

  const beforeUpload = file => {

    var format = new RegExp("([^s]+(\\.(csv|txt))$)", "i");
    const checkFormat = format.test(file.name);

    if (!checkFormat) {
      message.error(`${file.name} はcsvまたはtxtファイルではありません`);
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!isLt5M) {
      message.error("画像は5M以下でアップロードしてください");
      return false;
    }

    onChange(file);

    return false;
  };

  const onChange = fileInfo => {
    setFileInfo(fileInfo);
    dispatch(csvCheck({ fileInfo, screenType, userCode: user_code, token }));
  };

  const onFileDownload = () => {
    dispatch(csvDownload({ corporation_code, screenType }));
  };


  return (
    <Modal
      visible={fileUploadModalVisible}
      closable={false}
      width={1000}
      style={{ minWidth: 1000 }}
      footer={null}
      destroyOnClose={true}
      onCancel={() => dispatch(uploadActions.formatParam())}
    >
      <h2 className="text-bold upload-title">アップロード</h2>
      <div className="nf-upload">
        <Upload
          className="upload-btn"
          beforeUpload={beforeUpload}
          showUploadList={false}
          maxCount={1}
          listType="text"
        >
          <Button text="ファイル選択" theme="white" />
          <div className="info-count">
            <span className="nf_count_text">更新可能件数 : {successCount}</span>
            <br></br>
            <span className="nf_count_text">エラー件数 : {errorCount}</span>
          </div>
        </Upload>
        <div className="info-label">
          <span className="nf_info_text">サンプルファイルは</span>
          <span className="nf_info_link" onClick={()=>onFileDownload()}>こちら</span>
          <br />
          <span className="nf_info_text">
            検証結果が正常なデータのみ更新されます
          </span>
          <br />
          <span className="nf_info_text">ファイル形式:csvまたはtxt</span>
        </div>
      </div>
      <div className="nf-file-view">
        <div className="csv-layout">
          <table border="1" cellSpacing="0">
            {checkResult.map((result, i) =>
              i === 0 ? (
                <thead key={i}>
                  <tr>
                    {(result ?? []).map((column, j) => (
                      <th key={j}>{column}</th>
                    ))}
                  </tr>
                </thead>
              ) : (
                <tbody key={i}>
                  <tr>
                    {(result ?? []).map((column, j) => (
                      <td key={j}>{column}</td>
                    ))}
                  </tr>
                </tbody>
              )
            )}
          </table>
        </div>
      </div>
      <div className="btn-area">
        <Button
          text="戻る"
          theme="white"
          onClick={() => dispatch(uploadActions.formatParam())}
        />
        <form className="upload-area">
          <Button
            text="登録"
            theme={!registerEnable ? "light-black" : "black"}
            disabled={!registerEnable}
            onClick={() =>
              dispatch(
                csvUpload({
                  fileInfo,
                  screenType,
                  userCode: user_code,
                  token,
                })
              )
            }
          />
        </form>
      </div>
      <CompleteModal />
    </Modal>
  );
};

export default FileUploadModal;

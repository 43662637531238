import { Modal, Select } from "antd";
import Icon from "@ant-design/icons";
import { DownOutlined, CheckOutlined } from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import InfomationModal from "../../modal/infomationModal";
import { useEffect, useState } from "react";
import { Checkbox, Row, Col } from "antd";
import "./style.scss";

/* CSV出力モダール */

const StaffCsvDownloadModal = ({
  formik,
  modalVisible,
  options,
  favoriteItem,
  onClose,
  onDownload,
  onFavoriteItem,
  displayShopList,
  displayBrandList,
  displayUserAuthList,
}) => {
  const allValue = "ALL";
  const [checkedList, setCheckedList] = useState([]);
  const [checkedAuthList, setCheckedAuthList] = useState([]);
  const [selectedBrandList, setSelectedBrandList] = useState([]);
  const [selectedShopList, setSelectedShopList] = useState([]);
  const [checkAllFlg, setCheckAllFlg] = useState(false);
  const [infomationModalVisible, setInfomationModalVosible] = useState({
    visible: false,
    title: "",
  });
  useEffect(() => {
    let checkList = [];
    for (const option in favoriteItem) {
      if (favoriteItem[option] === "1") checkList.push(option);
    }
    setCheckedList(checkList);
    setCheckAllFlg(options.filter(e => e.value).length === checkList.length);
    setCheckedAuthList([]);
    setSelectedBrandList([]);
    setSelectedShopList([]);
  }, [modalVisible]);
  const onCheckAllChange = (e) => {
    const checkVals = options.map((option) => option.value);
    setCheckedList(e.target.checked ? checkVals : []);
    setCheckAllFlg(!checkAllFlg);
  };
  const checkBoxesOnChange = (checkedValues) => {
    setCheckedList(checkedValues);
    setCheckAllFlg(options.filter(e => e.value).length === checkedValues.length);
  };
  const checkAuthBoxesOnChange = (checkedValues) => {
    setCheckedAuthList(checkedValues);
  };
  const selectBrandOnChange = (selectedValues) => {
    if (selectedValues && selectedValues.indexOf(allValue) > -1) {
      setSelectedBrandList([allValue]);
      return;
    }
    setSelectedBrandList(selectedValues);
  };
  const selectShopOnChange = (selectedValues) => {
    if (selectedValues && selectedValues.indexOf(allValue) > -1) {
      setSelectedShopList([allValue]);
      return;
    }
    setSelectedShopList(selectedValues);
  };

  return (
    <>
      <InfomationModal
        modalVisible={infomationModalVisible.visible}
        setModalVisible={(val) => {
          setInfomationModalVosible({ visible: val, title: "" });
        }}
        item={{ information_title: infomationModalVisible.title }}
      />
      <Modal
        visible={modalVisible}
        centered
        footer={null}
        closable={false}
        onCancel={onClose}
        width={1000}
      >
      <form onSubmit={formik.handleSubmit}>
        <div className="staffCsvDownloadModal-content">
          <div className="title-area">
            <span className="text-title">CSV出力項目</span>
          </div>
          <div className="contents-area">
            <div>
              <div className="shop">
                <label className="label-required">ブランド</label><br/>
                <Select
                  value={selectedBrandList}
                  onChange={selectBrandOnChange}
                  name="brand_code"
                  mode="multiple"
                  className="nf-select select-block-grey"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  maxTagCount="responsive"
                  placeholder={"ブランドを選択してください"}
                  maxTagPlaceholder={"..."}
                  defaultActiveFirstOption={false}
                  size={"middle"}
                  suffixIcon={<DownOutlined className="ant-select-suffix" />}
                  allowClear={true}
                  showArrow={true}
                  menuItemSelectedIcon={
                    <CheckOutlined style={{ color: "black" }} />
                  }
                >
                  <Select.Option value={allValue}>すべて</Select.Option>
                  {displayBrandList.map(o => (
                    <Select.Option value={o.value} key={o.value}>
                      {o.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <label className="label-required">店舗</label><br/>
                <Select
                  value={selectedShopList}
                  onChange={selectShopOnChange}
                  name="shop_code"
                  mode="multiple"
                  className="nf-select select-block-grey"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  maxTagCount="responsive"
                  placeholder={"店舗を選択してください"}
                  maxTagPlaceholder={"..."}
                  defaultActiveFirstOption={false}
                  size={"middle"}
                  suffixIcon={<DownOutlined className="ant-select-suffix" />}
                  allowClear={true}
                  showArrow={true}
                  menuItemSelectedIcon={
                    <CheckOutlined style={{ color: "black" }} />
                  }
                >
                  <Select.Option value={allValue}>すべて</Select.Option>
                  {displayShopList.map(o => (
                    <Select.Option value={o.value} key={o.value}>
                      {o.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <span className="remarks">
                  ※ブランド/店舗で「すべて」を選択された場合、ダウンロードに時間がかかる場合があります。
                </span>
              </div>
            </div>
            <div className="userAuth">
              <label className="label-required">権限</label>
              <div className="checkbox-group">
                <Checkbox.Group onChange={checkAuthBoxesOnChange} value={checkedAuthList}>
                  <Row>
                    {displayUserAuthList.map((col) => {
                      return (
                        <Col style={{ marginBottom: 10, marginRight: 30 }}>
                          {col && col.label && <Checkbox value={col.value}>{col.label}</Checkbox>}
                        </Col>
                      );
                    })}
                  </Row>
                </Checkbox.Group>
              </div>
            </div>
            <div className="outputItem">
              出力する項目を選択してください
              <Button
                text={
                  <>
                    <Icon
                      component={Icons.favoriteItemIcon}
                      style={{ marginRight: 3 }}
                    ></Icon>
                    お気に入り項目設定
                  </>
                }
                onClick={() => {
                  if (checkedList.length < 1) {
                    setInfomationModalVosible({
                      visible: true,
                      title: "項目を1つ以上選択してください",
                    });
                    return;
                  }
                  onFavoriteItem(checkedList);
                }}
                style={{
                  width: 200,
                  height: 30,
                  fontSize: 14,
                  margin: 0,
                }}
                theme={"white"}
                displayRight={true}
              />
            </div>
            <Checkbox
              onChange={onCheckAllChange}
              checked={checkAllFlg}
              style={{ margin: "10px 20px 13px 0" }}
            >
              全選択
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox.Group onChange={checkBoxesOnChange} value={checkedList}>
                <Row>
                  {options.map((col) => {
                    return (
                      <Col span={col.colSpan} style={{ marginBottom: 10 }}>
                        {col && col.label && <Checkbox value={col.value}>{col.label}</Checkbox>}
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </div>
          </div>
          <div className="button-area">
            <Button
              text="閉じる"
              onClick={onClose}
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
              theme={"white"}
            />
            <Button
              text="ダウンロード"
              onClick={() => {
                onDownload(checkedList, checkedAuthList, selectedBrandList, selectedShopList);
              }}
              disabled={
                checkedList?.length == 0 ||
                checkedAuthList?.length == 0 ||
                selectedBrandList.length == 0 ||
                selectedShopList.length == 0
              }
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
            />
          </div>
        </div>
        </form>
      </Modal>
    </>
  );
};

export default StaffCsvDownloadModal;

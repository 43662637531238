import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { downloadFile } from "../utils/fnUtil";
import { actions as messageActions } from "./messageSlice";
import moment from "moment";
export const paymentsInit = createAsyncThunk("paymentsInit", async (params) => {
  const [paymentsRes, shopsRes, brandsRes, favotiteItemRes] = await Promise.all(
    [
      axios.get("reward/payment/list", { params: initialSearchParams }),
      axios.get("shops/list",{params: { count: 0, sort : "shop_code_asc" }}),
      axios.get("brands/list",{params: { mall_flag: 0, count: 0, sort : "brand_name_asc" }}),
      axios.get("csv/favorite_item", { params: { screen_type: 0 } }),
    ]
  );
  return {
    paymentsList: paymentsRes?.item,
    brands: (brandsRes?.item ?? []).map((b) => ({
      ...b,
      label: b.brand_name,
      value: b.brand_code,
    })),
    shops: (shopsRes?.item ?? []).map((s) => ({
      ...s,
      label: s.shop_code + ":" + s.shop_name,
      value: s.shop_code,
    })),
    favoriteItem: favotiteItemRes?.item?.favorite,
    total: paymentsRes?.total ?? 0,
  };
});

export const fetchPayments = createAsyncThunk(
  "fetchPayments",
  async (params) => await axios.get("reward/payment/list", { params })
);

export const updateCsvPaymentsFavoriteItem = createAsyncThunk(
  "updateCsvPaymentsFavoriteItem",
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post("csv/favorite_item/update", params);
    if (updateFavoriteRes?.code === "0")
      dispatch(
        messageActions.setMessage({
          messages:["お気に入り登録が完了しました"],
        })
      );
    return params.favorite;
  }
);

export const csvDownloadPayments = createAsyncThunk(
  "csvDownloadPayments",
  async (params) => await axios.get("csv/download", { params })
);

const initialSearchParams = {
  from_date: "",
  to_date: "",
  user_code: "",
  user_name: "",
  brand_code: [],
  shop_code: [],
  inflow: ["1", "2"],
  offset: 0,
  count: 10,
  sort: "sales_date_desc",
};

const initialState = {
  brands: [],
  shops: [],
  paymentsList: [],
  favoriteItem: null,
  inflowCheckBoxes: {
    coordinate: true,
    blog: true,
    live: false,
    archive: false,
    instagram: false,
    twitter: false,
  },
  searchParams: initialSearchParams,
  paymentsCsvDownloadModal: false,
  infomationModal: false,
  total: 0,
};

export const paymentsSlice = createSlice({
  name: "payments",
  initialState: initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveCheckBoxes: (state, { payload }) => ({
      ...state,
      inflowCheckBoxes: payload.inflowCheckBoxes,
    }),
    openPaymentsCsvDownloadModal: (state) => ({
      ...state,
      paymentsCsvDownloadModal: true,
    }),
    closePaymentsCsvDownloadModal: (state) => ({
      ...state,
      paymentsCsvDownloadModal: false,
    }),
    closeInfomationMondal: (state) => ({
      ...state,
      infomationModal: false,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(paymentsInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchPayments.fulfilled, (state, { payload }) => ({
      ...state,
      paymentsList: payload?.item ?? [],
      total: payload?.total ?? 0,
    }));
    builder.addCase(
      updateCsvPaymentsFavoriteItem.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          favoriteItem: payload,
        };
      }
    );
    builder.addCase(csvDownloadPayments.fulfilled, (state, { payload }) => {
      if (typeof payload === "string") {
        downloadFile(
          payload,
          `支払報酬確認_${moment().format("YYYYMMDDHHmmss")}.csv`
        );
      }
      return {
        ...state,
        infomationModal: payload?.code === "-1",
      };
    });
  },
});

export const actions = paymentsSlice.actions;

export default paymentsSlice.reducer;

import React, { useEffect } from "react";
import { Table, DatePicker } from "antd";
import { useFormik } from "formik";
import Icon from "@ant-design/icons";
import moment from "moment";
import "./style.scss";
import { useUpdateEffect } from "../../utils/hooksUtil";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Loading from "../../components/loading";
import DeleteModal from "../../components/modal/deleteModal";
import GenernalInput from "../../components/generalInput";
import { useDispatch, useSelector } from "react-redux";
import {
  shopActions,
  shopsInit,
  fetchShops,
  fetchShopDetail,
  updateShop,
} from "../../slices/shopsSlice";
import { uploadActions } from "../../slices/fileUploadSlice";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import Button from "../../components/button";
import ShopEditModal from "../../components/modal/shopEditModal";
import GeneralCheckbox from "../../components/cusCheckbox/genernalCheckbox";
import prefecturesList from "../../constants/prefectures.json";
import IconImage from "../../constants/Icons";
import TableColumnWidth from '../../constants/tableColumnWidth';

const Shop = () => {
  const dispatch = useDispatch();
  const {
    shopsList,
    searchParams,
    total_count,
    active_count,
    pageSize,
    brands,
    deleteModalVisible,
    activeShop,
  } = useSelector(state => state.shops);
  const { user_auth } = useSelector(state => state.account);
  const statusMap = ["使用不可", "使用中", "削除済"];
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => 
      dispatch(shopActions.saveSearchParam({
        ...values,
        start_date: values?.start_date ? values?.start_date.format('YYYY-MM-DD') : null,
        end_date: values.end_date ? values.end_date.format('YYYY-MM-DD') : null,
      })),
  });
  const {
    start_date,
    end_date
  } = formik.values;
  useEffect(() => {
    dispatch(shopsInit(searchParams));
    return () => dispatch(shopActions.clear());
  }, []);
  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(shopActions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  useUpdateEffect(
    () => dispatch(fetchShops(searchParams)),
    [searchParams, dispatch]
  );
  //　テーブル定義
  const columns = [
    {
      title: () => (
        <>
          <span>店舗CD </span>
          <span className="sort-span" onClick={createOnClikSort("shop_code")}>
            {formik.values.sort === "shop_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "shop_code",
      key: "shop_code",
      align: "center",
      width:TableColumnWidth.shop.shopCd
    },
    {
      title: () => (
        <>
          <span>店舗名 </span>
          <span className="sort-span" onClick={createOnClikSort("shop_name")}>
            {formik.values.sort === "shop_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "shop_name",
      key: "shop_name",
      align: "center",
      width:TableColumnWidth.shop.shopName
    },
    {
      title: () => (
        <>
          <span>ブランド名 </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {formik.values.sort === "brand_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "brand",
      key: "brand",
      align: "center",
      width:TableColumnWidth.brandName,
      render: brand => <span>{brand[0].brand_name}</span>,
    },
    {
      title: () => (
        <>
          <span>都道府県 </span>
          <span className="sort-span" onClick={createOnClikSort("prefectures")}>
            {formik.values.sort === "prefectures_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "prefectures",
      key: "prefectures",
      align: "center",
      width:TableColumnWidth.shop.prefectures,
      render: prefectures => (
        <span>
          {prefectures
            ? prefecturesList.prefectures.map(x => {
                if (x.prefecturesCode === prefectures) return x.value;
              })
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>電話番号 </span>
          <span className="sort-span" onClick={createOnClikSort("tel")}>
            {formik.values.sort === "tel_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "tel",
      key: "tel",
      align: "center",
      width:TableColumnWidth.shop.tel,
      render: tel => (
        <span>
          {tel.number1 !== "" ? (
            <>
              {tel.number1}-{tel.number2}-{tel.number3}
            </>
          ) : (
            "-"
          )}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("status")}>
            {formik.values.sort === "status_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "status",
      key: "status",
      align: "center",
      width:TableColumnWidth.shop.status,
      render: status => <span>{statusMap[parseInt(status)]}</span>,
    },
    {
      title: () => (
        <>
          <span>登録日 </span>
          <span className="sort-span" onClick={createOnClikSort("create_date")}>
            {formik.values.sort === "create_date_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "date",
      key: "date",
      align: "center",
      width:TableColumnWidth.date,
      render: date => <span>{moment(date).format("YYYY.MM.DD")}</span>,
    },
    {
      title: "編集",
      align: "center",
      dataIndex: "status",
      key: "status",
      width:TableColumnWidth.icon,
      render: (status, recode) => (
        <>
          {status !== "2" && user_auth < "3" ? (
            <Icon
              component={IconImage.EditIcon}
              style={{ fontSize: 25 }}
              onClick={() =>
                dispatch(fetchShopDetail({ shop_code: recode.shop_code }))
              }
            />
          ) : (
            <Icon
              component={IconImage.DisableEditIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
    {
      title: "削除",
      align: "center",
      dataIndex: "status",
      key: "status",
      width:TableColumnWidth.icon,
      render: (status, recode) => (
        <>
          {status !== "2" && user_auth < "3" ? (
            <Icon
              component={IconImage.RemoveIcon}
              style={{ fontSize: 25 }}
              onClick={() => dispatch(shopActions.openDeleteModal(recode))}
            />
          ) : (
            <Icon
              component={IconImage.DisableRemoveIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="content-body">
        <Loading />
        <Header />
        <div className="shop-container nf-page">
          <h2>店舗マスタ</h2>
          <div className="total-data">
            全店舗数 <span>{total_count}</span>
          </div>
          <div className="total-data">
            利用中店舗数 <span>{active_count}</span>
          </div>

          <form className="search-area" onSubmit={formik.handleSubmit}>
            <div className="search-item">
              <GenernalInput
                label="店舗CD"
                name="shop_code"
                placeholder="店舗CDを入力してください"
                formik={formik}
              />
              <GenernalInput
                label="店舗名"
                name="shop_name"
                placeholder="店舗名を入力してください"
                formik={formik}
              />
              <GenernalInput
                label="ブランド"
                mode="multiple"
                name="brand_code"
                placeholder={"ブランドを選択してください"}
                formik={formik}
                options={brands}
              />
              <GenernalInput
                label="都道府県"
                mode="multiple"
                name="prefectures"
                placeholder={"都道府県を選択してください"}
                formik={formik}
                options={prefecturesList.prefectures}
              />
              <GenernalInput
                label="電話番号"
                name="tel_number"
                placeholder="電話番号を入力してください"
                formik={formik}
              />
              <div className="nf-input-wrapper nf-input-wrapper-middle">
                <label class="nf-input-label text-align-center">登録日</label>
                <div className="nf-input-date-area">
                  <DatePicker
                    size="small"
                    className="nf-input-date"
                    value={start_date}
                    format={"YYYY.MM.DD"}
                    placeholder=""
                    suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onChange={( _ , dateString ) =>{ 
                      dateString && end_date
                        ? dateString > end_date.format('YYYY.MM.DD')
                          ? handlerFormikFieldChange(formik, "start_date", end_date)
                          : handlerFormikFieldChange(formik, "start_date", moment(dateString))
                        : handlerFormikFieldChange(formik, "start_date", dateString ? moment(dateString) : "");
                    }}
                  />
                  <span className="nf-label">〜</span>
                  <DatePicker
                    size="small"
                    className="nf-input-date"
                    value={end_date}
                    format={"YYYY.MM.DD"}
                    placeholder=""
                    suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onChange={( _ , dateString ) =>{
                      dateString && start_date
                        ? dateString < start_date.format('YYYY.MM.DD')
                          ? handlerFormikFieldChange(formik, "end_date", start_date)
                          : handlerFormikFieldChange(formik, "end_date", moment(dateString))
                        : handlerFormikFieldChange(formik, "end_date", dateString ? moment(dateString) : "");
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="search-item-deleted">
              <Button text="検索" type="submit" />
              <GeneralCheckbox
                label="削除済み"
                className="search-item-checkbox"
                checked={formik.values.deleted_flg === 1}
                onChange={() =>
                  handlerFormikFieldChange(
                    formik,
                    "deleted_flg",
                    formik.values.deleted_flg === 1 ? 0 : 1
                  )
                }
              />
            </div>
          </form>
          <div className="table-header">
            <h3>検索結果一覧</h3>
            <div className={user_auth > "1" ? "button hidden" : "button"}>
              <Button
                text="CSV取込"
                width="wider"
                theme={"black"}
                onClick={() => dispatch(uploadActions.openFileUploadModal(0))}
              />
            </div>
            <div className="button">
              <Button
                text="新規作成"
                theme="white"
                width="wider"
                onClick={() => dispatch(shopActions.newEditModal())}
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={shopsList}
            onChange={pagination => {
              const offset =
                pagination.current === 1
                  ? 0
                  : pagination.pageSize * (pagination.current - 1);
              dispatch(shopActions.saveOffset(offset));
              formik.setFieldValue("offset", offset);
            }}
            pagination={{
              total: pageSize,
              pageSize: 10,
              current: (searchParams?.offset || 0) / 10 + 1,
              position: ["bottomCenter"],
              showQuickJumper: false,
              size: "small",
            }}
            sticky={{offsetHeader: 60}}
          />
        </div>
        <Footer />
        <ShopEditModal />
        <DeleteModal
          modalVisible={deleteModalVisible}
          onCancel={() => dispatch(shopActions.closeDeleteModal())}
          item={"店舗CD"}
          itemValue={activeShop.shop_code}
          onOk={() =>
            dispatch(
              updateShop({
                division: "delete",
                shop_code: activeShop.shop_code,
              })
            )
          }
        />
      </div>
    </>
  );
};

export default Shop;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import moment from "moment";
import { Table } from "antd";
import { useFormik } from "formik";
import "./style.scss";
import { useUpdateEffect } from "../../utils/hooksUtil";
import Header from "../../components/header";
import Footer from "../../components/footer";
import GenernalInput from "../../components/generalInput";
import Button from "../../components/button";
import BrandEdit from "../../components/modal/brandEditModal";
import IconImage from "../../constants/Icons";
import TableColumnWidth from '../../constants/tableColumnWidth';
import {
  actions,
  brandInit,
  fetchBrand,
  fetchBrandDetail,
  fetchBrandNoMall,
  fetchMallBrand
} from "../../slices/brandSlice";

const Brand = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { brandList, searchParams, total_count, active_count, pageSizeCount, brands } = useSelector((state) => state.brand);
  const { user_auth }  = useSelector(state => state.account);
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) => dispatch(actions.saveSearchParam(values)),
  });
  useEffect(() => {
    dispatch(brandInit(searchParams));
    return () => dispatch(actions.clear());
  }, [dispatch]);
  useUpdateEffect(
    () => dispatch(fetchBrand(searchParams)),
    [searchParams, dispatch]
  );
  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  //　テーブル定義
  const columns = [
    {
      title: () => (
        <>
          <span>ブランドCD </span>
          <span className="sort-span" onClick={createOnClikSort("brand_code")}>
            {formik.values.sort === "brand_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "brand_code",
      key: "brand_code",
      align: "center",
      width:TableColumnWidth.brand.brandCd,
      render: brand_code => <div className="table-colum-title-cell brand-code">{brand_code || "-"}</div>,
    },
    {
      title: () => (
        <>
          <span>ブランド名 </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {formik.values.sort === "brand_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      width:TableColumnWidth.brand.brandName,
      render: brand_name => <div className="table-colum-title-cell brand-name">{brand_name || "-"}</div>,
    },
    {
      title: () => (
        <>
          <span>ブランド名(カナ) </span>
          <span className="sort-span" onClick={createOnClikSort("brand_namekana")}>
            {formik.values.sort === "brand_namekana_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "brand_name_kana",
      key: "brand_name_kana",
      align: "center",
      width:TableColumnWidth.brand.brandNameKana,
      render: brand_namekana_asc => <div className="table-colum-title-cell brand-namekana">{brand_namekana_asc || "-"}</div>,
    },
    {
      title: () => (
        <>
          <span>EC URL </span>
          <span className="sort-span" onClick={createOnClikSort("ec_url")}>
            {formik.values.sort === "ec_url_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "ec_url",
      key: "ec_url",
      align: "center",
      width:TableColumnWidth.brand.url,
      render: ec_url => <div className="table-colum-title-cell ec-url">{ec_url || "-"}</div>,
    },
    {
      title: () => (
        <>
          <span>報酬レート </span>
          <span className="sort-span" onClick={createOnClikSort("incentive_rate")}>
            {formik.values.sort === "incentive_rate_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "reward_rate",
      key: "reward_rate",
      width:TableColumnWidth.brand.incentiveRate,
      align: "center",
      render: reward_rate => <span>{(reward_rate ?? 0).toFixed(1)}</span>,
    },
    {
      title: () => (
        <>
          <span>登録日 </span>
          <span className="sort-span" onClick={createOnClikSort("create_date")}>
            {formik.values.sort === "create_date_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "create_date",
      key: "create_date",
      align: "center",
      width:TableColumnWidth.date,
      render: create_date => <span>{moment(create_date).format("YYYY.MM.DD")}</span>,

    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("status")}>
            {formik.values.sort === "status_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "status",
      key: "status",
      align: "center",
      width:TableColumnWidth.brand.status,
      render: (status) => <span>{status === "1" ? "使用中" : "使用不可" }</span>,
    },
    {
      title: "編集",
      align: "center",
      dataIndex: "status",
      key: "status",
      width:TableColumnWidth.icon,
      render: (status,recode) => (
        <>
          {status !== "2" && user_auth < "3" ? (
            <Icon
              component={IconImage.EditIcon}
              style={{ fontSize: 25 }}
              onClick={() =>{
                dispatch(fetchBrandNoMall({
                  mall_flag: 2,
                  offset: 0,
                  status: 0,
                  sort: "brand_code_asc"
                }));
                dispatch(fetchMallBrand(
                    {
                      brand_code: recode.brand_code,
                      mall_flag: 1,
                      offset: 0,
                      status: 0,
                      sort: "brand_code_asc"
                    }
                ));
                dispatch(fetchBrandDetail({
                  brand_code: recode.brand_code,
                  mall_flag: 0
                }));
              }
            }
            />
          ) : (
            <Icon
              component={IconImage.DisableEditIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    }
  ];

  return (
    <>
      <div className="content-body">
      <Header />
        <div className="brand-container nf-page">
          <h2>ブランドマスタ</h2>
          <div className="total-data">
            全ブランド数 <span>{total_count}</span>
          </div>
          <div className="total-data">
            利用中ブランド数 <span>{active_count}</span>
          </div>
          <form className="search-area" onSubmit={formik.handleSubmit}>
            <div className="search-item">
              <GenernalInput
                label="ブランドCD"
                name="brand_code"
                placeholder="ブランドCDを入力してください"
                formik={formik}
              />
              <GenernalInput
                label="ブランド名"
                mode="multiple"
                name="brand_name"
                placeholder={"ブランドを選択してください"}
                formik={formik}
                options={brands}
              />
            </div>
            <Button text="検索" type="submit" />
          </form>
          <div className="table-header">
          <h3>検索結果一覧</h3>
            {
              user_auth === "0"
              ?
              <>
                <div className="button">
                  <Button
                    text="新規追加"
                    theme="white"
                    width="wider"
                    onClick={() =>{
                      dispatch(fetchBrandNoMall({
                        mall_flag: 2,
                        offset: 0,
                        status: 0,
                        sort: "brand_code_asc"
                      }));
                      dispatch(fetchMallBrand(
                          {
                            mall_flag: 1,
                            offset: 0,
                            status: 0,
                            sort: "brand_code_asc"
                          }
                      ));
                      dispatch(actions.newEditModal());
                    }}
                  />
                </div>
              </>
              : <text>※新規追加・削除に関してはシステム管理者にお問い合わせください</text>


            }
          </div>
          <Table
            columns={columns}
            dataSource={brandList}
            onChange={(pagination) => {
              const offset =
                pagination.current === 1
                  ? 0
                  : pagination.pageSize * (pagination.current - 1);
              dispatch(actions.saveOffset(offset));
              formik.setFieldValue("offset", offset);
            }}
            pagination={{
              total: pageSizeCount,
              pageSize: 10,
              current: (searchParams?.offset || 0) / 10 + 1,
              position: ["bottomCenter"],
              showQuickJumper: false,
              size: "small",
            }}
            sticky={{offsetHeader: 60}}
          />
        </div>
        <Footer />
        <BrandEdit />
      </div>
    </>
  );
};

export default Brand;

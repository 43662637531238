import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import { actions as messageActions } from "../slices/messageSlice";
import axios from "../utils/axiosUtil";
import moment from "moment";

export const staffResultInit = createAsyncThunk(
  "staffResultInit",
  async (staffCode, {dispatch}) => {
    dispatch(loadingActions.multiLoadingOn());
    const [staffRes, brandsRes] = await Promise.all([
      axios.get("staff/detail", { params: { user_code: staffCode } }),
      axios.get("brands/list", { params: { mall_flag: 0, count: 0, sort : "brand_name_asc"}}),
    ]);
    dispatch(loadingActions.multiLoadingOff());
    staffRes?.item === null &&
      dispatch(messageActions.setMessage({messages: [`参照権限がない社員です`]}));
    return {
      staff: staffRes?.item ?? null,
      brands: (brandsRes.item ?? []).map((b) => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
    };
  }
);

export const fetchUserContents = createAsyncThunk(
  "fetchUserContents",
  async (params) => axios.get("analyze/user/contents", { params })
);

export const fetchUserSns = createAsyncThunk("fetchUserSns", async (params) =>
  axios.get("analyze/user/sns", { params })
);

export const fetchAnalyzeCoordinate = createAsyncThunk(
  "fetchAnalyzeCoordinate",
  async (params) => axios.get("analyze/coordinate/list", { params })
  //ブログの時はブログタイトル追加
  //公開日時追加
  //売上、PV、いいね、追加
);

export const fetchAnalyzeBlog = createAsyncThunk(
  "fetchAnalyzeBlog",
  async (params) => axios.get("analyze/articles/list", { params })
  //ブログの時はブログタイトル追加
  //公開日時追加
  //売上、PV、いいね、追加
);

export const fetchAnalyzeLive = createAsyncThunk(
  "fetchAnalyzeLive",
  async (params) => axios.get("analyze/live/list", { params })
);

const initialAnalyzeUserParam = {
  user_code: "",
  brand_code: "",
  year_month: moment().format("YYYYMM"),
};

const initialAnalyzeContaintsParam = {
  user_code: "",
  brand_code: "",
  analyze_unit: "1",
  start_date: moment().startOf("month").format("YYYY-MM-DD"),
  end_date: moment().endOf("month").format("YYYY-MM-DD"),
  count: 10,
  sort: "sales_desc",
  offset: 0,
  // image_height: 0,
};
const initialState = {
  staff: null,
  brands: [],
  userParam: initialAnalyzeUserParam,
  userContaints: {},
  userSns: {},
  containtsParam: initialAnalyzeContaintsParam,
  contents: { topRankList: [], buttomRankList: [] },
  contentsTotal: 0,
  currentTab: "fetchUserContents",
  currentContaintsTab: "coordinate",
};

export const staffResultSlice = createSlice({
  name: "staffResultSlice",
  initialState,
  reducers: {
    clear: () => initialState,
    saveStaffCode: (state, { payload }) => {
      state.staff = payload;
    },
    saveBrandCode: (state, { payload }) => ({
      ...state,
      userParam: {
        ...state.userParam,
        brand_code: payload,
        offset: 0,
      },
      containtsParam: {
        ...state.containtsParam,
        brand_code: payload,
        offset: 0,
      },
    }),
    saveTagDate: (state, { payload }) => ({
      ...state,
      userParam: {
        ...state.userParam,
        year_month: payload,
        offset: 0,
      },
      containtsParam: {
        ...state.containtsParam,
        start_date: moment(payload).format("YYYY-MM-DD"),
        end_date: moment(payload).endOf("month").format("YYYY-MM-DD"),
        offset: 0,
      },
    }),
    saveCurrentTab: (state, { payload }) => ({
      ...state,
      currentTab: payload,
      containtsParam: {
        ...state.containtsParam,
        offset: 0,
      },
    }),
    saveCurrentContaintsTab: (state, { payload }) => ({
      ...state,
      currentContaintsTab: payload,
      containtsParam: {
        ...state.containtsParam,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => ({
      ...state,
      containtsParam: {
        ...state.containtsParam,
        offset: payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(staffResultInit.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
        userParam: {
          ...state.userParam,
          user_code: payload?.staff?.user_code,
          brand_code: payload?.staff?.brand?.length > 0 ? payload?.staff?.brand[0].brand_code : "",
        },
        containtsParam: {
          ...state.containtsParam,
          user_code: payload?.staff?.user_code,
          brand_code: payload?.staff?.brand?.length > 0 ? payload?.staff?.brand[0].brand_code : "",
        },
      };
    });
    builder.addCase(fetchUserContents.fulfilled, (state, { payload }) => ({
      ...state,
      userContaints: payload?.item ?? {},
    }));
    builder.addCase(fetchUserSns.fulfilled, (state, { payload }) => ({
      ...state,
      userSns: payload?.item ?? {},
    }));
    builder.addCase(fetchAnalyzeCoordinate.fulfilled, (state, { payload }) => {
      let item = (payload?.item ?? []).map((a, index )=> ({
        ...a,
        rank: state.containtsParam.offset + index + 1,
      }));
      let top = item.slice(0, 5);
      let bottom = item.slice(5, 10);
      if (top.length > 0 && top.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (top.length < 5) top.push({ dummy: true });
        }
      }
      if (bottom.length > 0 && bottom.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (bottom.length < 5) bottom.push({ dummy: true });
        }
      }
      return {
        ...state,
        contents: { topRankList: top, buttomRankList: bottom },
        contentsTotal: (payload?.params?.offset == 0 ? payload?.total : state?.contentsTotal) ?? 0,
      };
    });
    builder.addCase(fetchAnalyzeBlog.fulfilled, (state, { payload }) => {
      let item = (payload?.item ?? []).map((a, index )=> ({
        ...a,
        rank: state.containtsParam.offset + index + 1,
      }));
      let top = item.slice(0, 5);
      let bottom = item.slice(5, 10);
      if (top.length > 0 && top.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (top.length < 5) top.push({ dummy: true });
        }
      }
      if (bottom.length > 0 && bottom.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (bottom.length < 5) bottom.push({ dummy: true });
        }
      }
      return {
        ...state,
        contents: { topRankList: top, buttomRankList: bottom },
        contentsTotal: (payload?.params?.offset == 0 ? payload?.total : state?.contentsTotal) ?? 0,
      };
    });
    builder.addCase(fetchAnalyzeLive.fulfilled, (state, { payload }) => {
      let item = (payload?.item ?? []).map((a, index )=> ({
        ...a,
        rank: state.containtsParam.offset + index + 1,
      }));
      let top = item.slice(0, 5);
      let bottom = item.slice(5, 10);
      if (top.length > 0 && top.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (top.length < 5) top.push({ dummy: true });
        }
      }
      if (bottom.length > 0 && bottom.length < 5) {
        for (let i = 0; i < 5; i++) {
          if (bottom.length < 5) bottom.push({ dummy: true });
        }
      }
      return {
        ...state,
        contents: { topRankList: top, buttomRankList: bottom },
        contentsTotal: (payload?.params?.offset == 0 ? payload?.total : state?.contentsTotal) ?? 0,
      };
    });
  },
});

export const actions = staffResultSlice.actions;

export default staffResultSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as messageActions } from "../slices/messageSlice";
import { actions as loadingActions } from "./loadingSlice";
import axios from "../utils/axiosUtil";

export const categoryInit = createAsyncThunk("categoryInit", async (params, {dispatch}) => {
  dispatch(loadingActions.multiLoadingOn());
  const [categoriesRes, brandsRes, shopsRes] = await Promise.all([
    axios.get("tags/category/list",{ params :{sort : "brand_name_desc", count : 10 }}),
    axios.get("brands/list",{params: { count: 0, sort : "brand_name_asc" }}),
    axios.get("shops/list",{params: { count: 0, sort : "shop_code_asc" }}),
  ]);
  dispatch(loadingActions.multiLoadingOff());
  return {
    categoriesList: categoriesRes?.item ?? [],
    activeCategory: {},
    total: categoriesRes?.total,
    pageSize: categoriesRes?.search_count,
    brands: (brandsRes?.item ?? []).map(b => ({
      ...b,
      label: b.brand_name,
      value: b.brand_code,
    })),
    shops: (shopsRes?.item ?? []).map(s => ({
      ...s,
      label: s.shop_code + ":" + s.shop_name,
      value: s.shop_code,
    })),
  };
});

export const fetchCategory = createAsyncThunk(
  "fetchCategory",
  async params => await axios.get("/tags/category/list", { params })
);

export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (params, {dispatch}) => {
    const categoryUpdatRes = await axios.post("/tags/category/update", params )
    categoryUpdatRes?.code === "0" &&
      dispatch(
        messageActions.setMessage({
          messages: [
            `${params.division === "insert" ? "登録" : "更新"}が完了しました`,
          ],
        })
      );
  }
);

const initialSearchParams = {
  brand_code: [],
  tag_category_code:"",
  tag_category_name:"",
  deleted_flg: 0,
  offset: 0,
  count:10,
  sort: "brand_name_desc",
  operator_code: null,
};

const initialEditParams = {
  brand_code: null,
  brand_name: null,
  tag_category_code: null,
  tag_category_name: null,
  delete_flg: 0,
};
const initialState ={
  categoriesList: [],
  activeCategory: null,
  categoryDate: initialEditParams,
  brands: [],
  deleteCategory: {},
  statusCountMap: {},
  editMode:"insert",
  editModalVisible: false,
  deleteModalVisible: false,
  searchParams: initialSearchParams,
  total: 0,
}


export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    clear: () => initialState,
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveDelete: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        deleted_flg: state.searchParams.deleted_flg === 1 ? 0 : 1,
      }
    }),
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          brand_code: [],
          status: payload,
          sort: "brand_name_desc"
        },
      };
    },
    newEditModal: (state, { payload }) => ({
      ...state,
      editMode: "insert",
      editModalVisible: true,
      activeCategory: payload,
    }),
    updateEditModal: (state, { payload }) => {
      return{
        ...state,
        activeCategory: payload,
        editModalVisible: true,
        editMode: "update",
        categoryDate:payload,
      }
    },
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeCategory: payload,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      categoryDate: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      categoryDate:initialEditParams,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    editTag: state => ({
      ...state,
      editModalVisible: false,
    }),
    deleteTag: state => ({
      ...state,
      deleteModalVisible: false,
      activeCategory: null,
    }),
  },
  extraReducers: builder => {
    builder.addCase(categoryInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchCategory.fulfilled, (state, { payload }) => ({
      ...state,
      categoriesList: payload?.item ?? [],
      total: payload?.total || 0,
      pageSize: payload?.search_count  || 0,
    }));
    builder.addCase(updateCategory.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
      editModalVisible: false,
      deleteModalVisible: false,
    }));
  },
});

export const actions = categorySlice.actions;

export default categorySlice.reducer;

import "./style.scss";
import className from "classnames";

const StatusBox = ({
  status,
  subStatus = null,
  count = 0,
  warning = false,
  onClick,
  topStyle = false,
}) => {
  return (
    <button
      className={topStyle ? "top-box-wrapper" : "box-wrapper"}
      onClick={onClick}
    >
      <div className="inner-wrapper">
        <div className="inner-wrapper-left">
          <div className="inner-wrapper-left-top">
            {!subStatus ?
              <div>{status}</div>
            :
              <div className="sub-status-area">
                <div className="status-label">{status}</div>
                <div className="sub-status-label">{subStatus}</div>
              </div>
            }
            <div>
              <span
                className={className("text-count", {
                  warning,
                  "text-count-sm": count > 10000,
                })}
              >
                {count}&nbsp;
              </span>
              <span>件</span>
            </div>
          </div>
          <div className="inner-wrapper-left-bottom">絞り込み検索</div>
        </div>
        <span>&gt;</span>
      </div>
    </button>
  );
};

export default StatusBox;

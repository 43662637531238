import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import { actions as messageActions } from "./messageSlice";
import axios from "../utils/axiosUtil";

export const liveListInit = createAsyncThunk(
  "liveListInit",
  async (params, { dispatch }) => {
    dispatch(loadingActions.multiLoadingOn());
    const [liveRes, shopsRes, brandsRes, staffRes] = await Promise.all([
      axios.get("live/list", {
        params: {
          status_flag: params?.status_code ? params.status_code : 999,
          live_flag: [0, 1],
          sort: "live_code_desc",
          count: 10,
        },
      }),
      axios.get("shops/list", { params: { count: 0, sort: "shop_code_asc" } }),
      axios.get("brands/list", { params: { count: 0, sort: "brand_name_asc" } }),
      axios.get("staff/list", { params: { count: 0, sort: "user_name_asc" } }),
    ]);
    dispatch(loadingActions.multiLoadingOff());

    return {
      statusAggregate: liveRes.status_aggregate ?? {},
      total: liveRes.total,
      liveList: liveRes?.item ?? [],
      shops: (shopsRes?.item ?? []).map(s => ({
        ...s,
        label: s.shop_code + ":" + s.shop_name,
        value: s.shop_code,
      })),
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      staffs: (staffRes?.item ?? []).map(s => ({
        ...s,
        label: s.user_name,
        value: s.user_code,
      })),
    };
  }
);

export const fetchLiveList = createAsyncThunk(
  "fetchLiveList",
  async params => await axios.get("live/list", { params })
);

export const fetchLiveDetail = createAsyncThunk(
  "fetchLiveDetail",
  async params => await axios.get("live/detail", { params })
);

export const fetchLivePreparation = createAsyncThunk(
  "fetchLivePreparation",
  async params => await axios.get("live/detail", { params })
);

export const fetchLiveArchive = createAsyncThunk(
  "fetchLiveArchive",
  async params => await axios.get("live/detail", { params })
);

export const fetchTags = createAsyncThunk(
  "fetchTags",
  async params => await axios.get("tags/list", { params })
);

export const fetchTagCategory = createAsyncThunk(
  "fetchTagCategory",
  async params => await axios.get("tags/category/list", { params })
);

export const updateLivePreparation = createAsyncThunk(
  "updateLivePreparation",
  async params => await axios.post("live/update", params)
);

export const registLive = createAsyncThunk(
  "registLive",
  async (params, { dispatch }) => {
    await axios.post("live/update", params);

    dispatch(
      messageActions.setMessage({
        btnText: "閉じる",
        messages: ["設定が完了しました"],
        onCloseAction: actions.closeSignUpModal(),
      })
    );
  }
);

export const updateLive = createAsyncThunk(
  "updateLive",
  async (params, { dispatch }) => {
    await axios.post("live/update", params);

    dispatch(
      messageActions.setMessage({
        btnText: "閉じる",
        messages: ["更新が完了しました"],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);

export const deleteLive = createAsyncThunk(
  "deleteLive",
  async params => await axios.post("live/update", params),
);

const initialSearchParams = {
  user_name: "",
  brand_code: [],
  shop_code: [],
  status_flag: 999,
  offset: 0,
  count: 10,
  sort: "live_code_desc",
  live_flag: [0, 1],
};

const initialState = {
  liveList: [],
  activeLive: {},
  deleteLive: {},
  brands: [],
  shops: [],
  staffs: [],
  categories: [],
  tags: [],
  statusAggregate: {},
  currentLiveCode: "",
  editModalVisible: false,
  deleteModalVisible: false,
  signUpModalVisible: false,
  preparationModalVisible: false,
  archiveModalVisible: false,
  confirmModalVisible: false,
  transitionLiveDelivery: false,
  searchParams: initialSearchParams,
  total: 0,
};

export const liveSlice = createSlice({
  name: "live",
  initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
        status_flag: 999,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          sort: state.searchParams.sort,
          ...payload,
        },
      };
    },
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeLive: payload,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteLive: payload,
    }),
    openEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: true,
    }),
    openSignUpModal: state => ({
      ...state,
      signUpModalVisible: true,
    }),
    openPreparationModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeLive: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      activeLive: {},
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    closeEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: false,
    }),
    closeSignUpModal: state => ({
      ...state,
      signUpModalVisible: false,
      currentLiveCode: "",
    }),
    closePreparationModal: state => ({
      ...state,
      preparationModalVisible: false,
      activeLive: {},
    }),
    closeArchiveModal: state => ({
      ...state,
      archiveModalVisible: false,
      activeLive: {},
    }),
    clearCurrentLiveCode: state => ({
      ...state,
      currentLiveCode: "",
    }),
    openConfirmModal: state => ({
      ...state,
      confirmModalVisible: true,
    }),
    closeConfirmModal: state => ({
      ...state,
      confirmModalVisible: false,
    }),
    clearActiveLive: state => ({
      ...state,
      activeLive: {},
    }),
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(liveListInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchLiveList.fulfilled, (state, { payload }) => ({
      ...state,
      liveList: payload?.item ?? [],
      total: payload?.total || 0,
      statusAggregate: payload?.status_aggregate ?? {},
    }));
    builder.addCase(fetchLiveDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeLive: payload?.item ?? {},
      editModalVisible: true,
    }));
    builder.addCase(fetchLivePreparation.fulfilled, (state, { payload }) => ({
      ...state,
      activeLive: payload?.item ?? {},
      preparationModalVisible: true,
    }));
    builder.addCase(fetchLiveArchive.fulfilled, (state, { payload }) => ({
      ...state,
      activeLive: payload?.item ?? {},
      archiveModalVisible: true,
    }));
    builder.addCase(updateLivePreparation.fulfilled, state => ({
      ...state,
      preparationModalVisible: false,
      transitionLiveDelivery: true,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(registLive.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(updateLive.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(deleteLive.fulfilled, state => ({
      ...state,
      editModalVisible: false,
      deleteModalVisible: false,
      signUpModalVisible: false,
      preparationModalVisible: false,
      archiveModalVisible: false,
      editDeleteModalVisible: false,
      confirmModalVisible: false,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => ({
      ...state,
      tags: (payload?.item ?? []).map(t => ({
        ...t,
        label: t.tag_name,
        value: t.tag_code,
      })),
    }));
    builder.addCase(fetchTagCategory.fulfilled, (state, { payload }) => ({
      ...state,
      categories: (payload?.item ?? [])
        .filter(t => t.active_tag_count > 0 && t.category_status === "使用中")
        .map(t => ({
          ...t,
          label: t.tag_category_name,
          value: t.tag_category_code,
        })),
    }));
  },
});

export const actions = liveSlice.actions;

export default liveSlice.reducer;

import { remove } from "lodash";
import classNames from "classnames";
import "./style.scss";

const CusCheckbox = ({
  onChange,
  value = [],
  name,
  options = [],
  radio = false,
  className,
  disabled = false,
  max = 99999,
}) => {
  return (
    <div className={classNames("nf-checkbox-wrapper", className)}>
      {options.map((o, i) => {
        const checkboxDisabled =
          disabled || (value.length >= max && !value.includes(o.value));
        return (
          <div key={i}>
            <label
              className={classNames("nf-checkbox-label", {
                "nf-checkbox-active": value.includes(o.value),
                "nf-cuscheck-disabled": checkboxDisabled,
              })}
            >
              {o.label}
              <input
                className="nf-checkbox"
                type="checkbox"
                value={o.value}
                name={name}
                disabled={checkboxDisabled}
                checked={value.includes(o.value)}
                onChange={e => {
                  let next = [...value];
                  value.includes(e.target.value)
                    ? remove(next, f => f === e.target.value)
                    : radio
                    ? (next = [e.target.value])
                    : next.push(e.target.value);
                  onChange?.(next);
                }}
              />
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default CusCheckbox;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import { actions as messageActions } from "./messageSlice";
import axios from "../utils/axiosUtil";

export const movieListInit = createAsyncThunk(
  "movieListInit",
  async (params, { dispatch }) => {
    dispatch(loadingActions.multiLoadingOn());
    const [liveRes, shopsRes, brandsRes, staffRes] = await Promise.all([
      axios.get("live/list", {
        params: {
          status_flag: params?.status_code ? params.status_code : undefined,
          sort: "live_code_desc",
          count: 10,
          live_flag: [2],
        },
      }),
      axios.get("shops/list", { params: { count: 0, sort: "shop_code_asc" } }),
      axios.get("brands/list", { params: { count: 0, sort: "brand_name_asc" } }),
      axios.get("staff/list", { params: { count: 0, sort: "user_name_asc" } }),
    ]);
    dispatch(loadingActions.multiLoadingOff());

    return {
      statusAggregate: liveRes.status_aggregate ?? {},
      total: liveRes.total,
      liveList: liveRes?.item ?? [],
      shops: (shopsRes?.item ?? []).map(s => ({
        ...s,
        label: s.shop_code + ":" + s.shop_name,
        value: s.shop_code,
      })),
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      staffs: (staffRes?.item ?? []).map(s => ({
        ...s,
        label: s.user_name,
        value: s.user_code,
      })),
    };
  }
);

export const fetchMovieList = createAsyncThunk(
  "fetchMovieList",
  async params => await axios.get("live/list", { params })
);

export const fetchMovieDetail = createAsyncThunk(
  "fetchMovieDetail",
  async params => await axios.get("live/detail", { params })
);

export const fetchTags = createAsyncThunk(
  "fetchTags",
    async params => {
      params.custom_sort_flg= true;
      return await axios.get("tags/list", { params });
    }
);

export const fetchTagCategory = createAsyncThunk(
  "fetchTagCategory",
  async params => await axios.get("tags/category/list", { params })
);

export const deleteLive = createAsyncThunk(
  "deleteLive",
  async params => await axios.post("live/update", params)
);

export const registMovie = createAsyncThunk(
  "registMovie",
  async (params, { dispatch }) => {
    await uploadMovieData(params, dispatch);

    dispatch(
      messageActions.setMessage({
        btnText: "閉じる",
        messages: ["設定が完了しました"],
        onCloseAction: actions.closeSignUpModal(),
      })
    );
  }
);

export const updateMovie = createAsyncThunk(
  "updateMovie",
  async (params, { dispatch }) => {
    await uploadMovieData(params, dispatch);

    dispatch(
      messageActions.setMessage({
        btnText: "閉じる",
        messages: ["更新が完了しました"],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);

const uploadMovieData = async (params, dispatch, message) => {
  dispatch(loadingActions.multiLoadingOn({
    subText: ["動画のアップロードは最大数分かかる場合があります", "画面を閉じずにお待ちください"]
  }));
  const config = { headers: { "content-type": "multipart/form-data" }, isFileUpload: true };
  await axios.post("live/update/movie", params, config);
  dispatch(loadingActions.multiLoadingOff());
};


const initialSearchParams = {
  user_name: "",
  brand_code: [],
  shop_code: [],
  status_flag: undefined,
  offset: 0,
  count: 10,
  sort: "live_code_desc",
  live_flag: [2],
};

const initialState = {
  liveList: [],
  activeLive: {},
  deleteLive: {},
  brands: [],
  shops: [],
  staffs: [],
  categories: [],
  tags: [],
  statusAggregate: {},
  currentLiveCode: "",
  editModalVisible: false,
  deleteModalVisible: false,
  signUpModalVisible: false,
  archiveModalVisible: false,
  confirmModalVisible: false,
  transitionLiveDelivery: false,
  searchParams: initialSearchParams,
  total: 0,
};

export const liveSlice = createSlice({
  name: "live",
  initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
        status_flag: undefined,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          sort: state.searchParams.sort,
          status_flag: payload,
        },
      };
    },
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeLive: payload,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteLive: payload,
    }),
    openEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: true,
    }),
    openSignUpModal: state => ({
      ...state,
      signUpModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      activeLive: {},
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    closeEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: false,
    }),
    closeSignUpModal: state => ({
      ...state,
      signUpModalVisible: false,
      currentLiveCode: "",
    }),
    closeArchiveModal: state => ({
      ...state,
      archiveModalVisible: false,
      activeLive: {},
    }),
    clearCurrentLiveCode: state => ({
      ...state,
      currentLiveCode: "",
    }),
    openConfirmModal: state => ({
      ...state,
      confirmModalVisible: true,
    }),
    closeConfirmModal: state => ({
      ...state,
      confirmModalVisible: false,
    }),
    clearActiveLive: state => ({
      ...state,
      activeLive: {},
    }),
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(movieListInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchMovieList.fulfilled, (state, { payload }) => ({
      ...state,
      liveList: payload?.item ?? [],
      total: payload?.total || 0,
      statusAggregate: payload?.status_aggregate ?? {},
    }));
    builder.addCase(fetchMovieDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeLive: payload?.item ?? {},
      editModalVisible: true,
    }));
    builder.addCase(deleteLive.fulfilled, state => ({
      ...state,
      editModalVisible: false,
      deleteModalVisible: false,
      signUpModalVisible: false,
      archiveModalVisible: false,
      editDeleteModalVisible: false,
      confirmModalVisible: false,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(registMovie.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(updateMovie.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => ({
      ...state,
      tags: (payload?.item ?? []).map(t => ({
        ...t,
        label: t.tag_name,
        value: t.tag_code,
      })),
    }));
    builder.addCase(fetchTagCategory.fulfilled, (state, { payload }) => ({
      ...state,
      categories: (payload?.item ?? [])
        .filter(t => t.active_tag_count > 0 && t.category_status === "使用中")
        .map(t => ({
          ...t,
          label: t.tag_category_name,
          value: t.tag_category_code,
        })),
    }));
  },
});

export const actions = liveSlice.actions;

export default liveSlice.reducer;

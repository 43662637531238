/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onChatByLiveCd = /* GraphQL */ `
  subscription OnChatByLiveCd($live_cd: String!, $corporation_cd: String!) {
    onChatByLiveCd(live_cd: $live_cd, corporation_cd: $corporation_cd) {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;
export const onChatBySignalingFlag = /* GraphQL */ `
  subscription OnChatBySignalingFlag(
    $live_cd: String!
    $corporation_cd: String!
    $signaling_flag: String!
  ) {
    onChatBySignalingFlag(
      live_cd: $live_cd
      corporation_cd: $corporation_cd
      signaling_flag: $signaling_flag
    ) {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChat = /* GraphQL */ `
  subscription OnCreateChat {
    onCreateChat {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChat = /* GraphQL */ `
  subscription OnUpdateChat {
    onUpdateChat {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChat = /* GraphQL */ `
  subscription OnDeleteChat {
    onDeleteChat {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;

import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import GenernalInput from "../../components/generalInput";
import { Checkbox, DatePicker, Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useHistory } from "react-router-dom";
import { actions, fetchRanking } from "../../slices/rankingSlice";
import PersonalIcon from "../../components/personalIcon";
import {
  handlerFormikFieldChange,
  toMoneyString,
  validDatePickChecker,
} from "../../utils/fnUtil";
import "./style.scss";

const Ranking = () => {
  const dispatch = useDispatch();
  const { topRankList, buttomRankList, total, paging_total, searchParams } =
    useSelector((state) => state.ranking ?? {});
  const account = useSelector((state) => state.account ?? {});
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) => {
      setCurrentPage(1);
      dispatch(
        actions.saveSearchParam({
          ...searchParams,
          offset: 0,
          aggregate_type: values.aggregate_type,
          output: values.output,
          aggregate_unit: values.aggregate_unit,
          start_date:
            values.aggregate_unit === "monthly"
              ? monthlyDate.from.startOf("month").format("YYYY-MM-DD")
              : dailyDate.from.format("YYYY-MM-DD"),
          end_date:
            values.aggregate_unit === "monthly"
              ? monthlyDate.to.endOf("month").format("YYYY-MM-DD")
              : dailyDate.to.format("YYYY-MM-DD"),
          sort:
            values.aggregate_type === "sns" ||
            values.aggregate_type === "instagram" ||
            values.aggregate_type === "twitter"
              ? "sales"
              : searchParams.sort,
        })
      );
      setDisabled(false);
    },
  });
  const formValidation = (searchParams) => {
    return !(
      formik.getFieldProps("aggregate_type").value &&
      (outputTypeCheckboxes.brand ||
        outputTypeCheckboxes.shop ||
        outputTypeCheckboxes.user) &&
      (aggregateUnitTypeCheckboxes.monthly ||
        aggregateUnitTypeCheckboxes.daily) &&
      ((aggregateUnitTypeCheckboxes.monthly &&
        monthlyDate.from &&
        monthlyDate.to) ||
        (aggregateUnitTypeCheckboxes.daily && dailyDate.from && dailyDate.to))
    );
  };
  useEffect(() => {
    handlerFormikFieldChange(formik, "aggregate_type", []);
    dispatch(actions.clear());
    return () => dispatch(actions.clear());
  }, [dispatch]);
  useUpdateEffect(
    () => dispatch(fetchRanking(searchParams)),
    [searchParams, dispatch]
  );

  const [outputTypeCheckboxes, setOutputTypeCheckboxes] = useState({
    brand: false,
    shop: false,
    user: false,
  });
  const outputTypeCheckboxesHandler = (name) => {
    setOutputTypeCheckboxes({
      brand: false,
      shop: false,
      user: false,
      [name]: true,
    });
    formik.setFieldValue("output", name);
  };
  const [aggregateUnitTypeCheckboxes, setAggregateUnitTypeCheckboxes] =
    useState({
      monthly: false,
      daily: false,
    });
  const aggregateUnitTypeCheckboxesHanler = (name) => {
    setAggregateUnitTypeCheckboxes({
      monthly: false,
      daily: false,
      [name]: true,
    });
    formik.setFieldValue("aggregate_unit", name);
  };
  const getRankCard = (data) => {
    return data.dummy ? (
      <div className="ranking-card" style={{ visibility: "hidden" }}></div>
    ) : (
      <div className="ranking-card">
        <span className="ranking-no">{data?.rank}</span>
        {searchParams.output === "user" ? (
          <PersonalIcon
            onClick={() => {
              history.push({
                pathname: "/staffResult",
                state: { staff_code: data?.user_code },
              });
            }}
            imgSrc={data?.image_url}
            mask={true}
            maskText="社員実績"
            width={130}
            height={130}
          />
        ) : (
          <PersonalIcon
            imgSrc={data?.image_url}
            mask={false}
            width={130}
            height={130}
            borderRadius={0}
            cursor={'default'}
          />
        )}
        <div className="output-name">
          {searchParams.output === "brand" ? data?.brand_name : data?.shop_name}
        </div>
        {searchParams.output === "user" ? (
          <div className="result-label">{data?.user_name} <br/>{data?.user_nickname ? "("+ data?.user_nickname + ")": "" }<br/> </div>
        ) : (
          ""
        )}
        <div className="result-value">
          <span>
            {searchParams.sort === "sales" ? "売上" : ""}
            {searchParams.sort === "pv_count" ? getPvName() : ""}
            {searchParams.sort === "like_count" ? "いいね" : ""}
          </span>
          <span>
            {searchParams.sort === "sales" ? "￥" : ""}{" "}
            {toMoneyString(data?.result_value) || "-"}
          </span>
        </div>
        {data?.rank === "1" ? (
          ""
        ) : (
          <div className="result-gap">
            次の順位まで&nbsp;
            {searchParams.sort === "sales" ? "￥" : ""}&nbsp;
            {toMoneyString(data?.result_gap) || "-"}
          </div>
        )}
      </div>
    );
  };

  const getPvName = () => {
    if (searchParams.aggregate_type === "contents") return "PV";
    if (searchParams.aggregate_type === "coordinate") return "PV";
    if (searchParams.aggregate_type === "blog") return "PV";
    if (searchParams.aggregate_type === "live") return "視聴者数";
    if (searchParams.aggregate_type === "archive") return "再生回数";
    if (searchParams.aggregate_type === "movie") return "再生回数";
    return "";
  };

  const [disabled, setDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [monthlyDate, setMonthlyDate] = useState({ from: null, to: null });
  const [dailyDate, setDailyDate] = useState({ from: null, to: null });
  const history = useHistory();
  return (
    <div className="content-body">
      <Header />
      <div className="ranking-container nf-page">
        <h2>ランキング</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <div className="form-label">集計条件</div>
            <GenernalInput
              label="ランキング種別"
              mode={null}
              name="aggregate_type"
              placeholder="選択してください"
              labelWidth="wider"
              labelTextAlign="left"
              formik={formik}
              options={[
                { label: "コンテンツ合計", value: "contents" },
                { label: "コーディネート", value: "coordinate" },
                { label: "ブログ", value: "blog" },
                { label: "オンラインライブ", value: "live" },
                { label: "アーカイブ", value: "archive" },
                { label: "動画", value: "movie" },
                { label: "SNS合計", value: "sns" },
                { label: "Instagram", value: "instagram" },
                { label: "Twitter", value: "twitter" },
              ]}
            />
            <div>
              <Checkbox
                className="ranking-checkbox"
                name="brand"
                onChange={(val) => {
                  outputTypeCheckboxesHandler(val.target.name);
                }}
                disabled={
                  account.user_auth === "3" || account.user_auth === "2"
                }
                checked={outputTypeCheckboxes.brand}
              >
                ブランド単位
              </Checkbox>
              <Checkbox
                className="ranking-checkbox"
                name="shop"
                onChange={(val) => {
                  outputTypeCheckboxesHandler(val.target.name);
                }}
                disabled={account.user_auth === "3"}
                checked={outputTypeCheckboxes.shop}
              >
                店舗単位
              </Checkbox>
              <Checkbox
                className="ranking-checkbox"
                name="user"
                onChange={(val) => {
                  outputTypeCheckboxesHandler(val.target.name);
                }}
                checked={outputTypeCheckboxes.user}
              >
                社員単位
              </Checkbox>
            </div>
            <div className="form-label">集計期間</div>
            <div>
              <Checkbox
                className="ranking-checkbox"
                name="monthly"
                onChange={(val) => {
                  aggregateUnitTypeCheckboxesHanler(val.target.name);
                }}
                checked={aggregateUnitTypeCheckboxes.monthly}
              >
                月別
              </Checkbox>
              <DatePicker
                className="ranking-datepicker"
                picker="month"
                value={monthlyDate.from}
                onChange={(date, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    monthlyDate,
                    setMonthlyDate
                  );
                }}
              />
              &nbsp; ~ &nbsp;
              <DatePicker
                className="ranking-datepicker"
                picker="month"
                value={monthlyDate.to}
                onChange={(date, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    monthlyDate,
                    setMonthlyDate
                  );
                }}
              />
            </div>
            <div>
              <Checkbox
                className="ranking-checkbox"
                name="daily"
                onChange={(val) => {
                  aggregateUnitTypeCheckboxesHanler(val.target.name);
                }}
                checked={aggregateUnitTypeCheckboxes.daily}
              >
                日別
              </Checkbox>
              <DatePicker
                className="ranking-datepicker"
                value={dailyDate.from}
                onChange={(date, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    dailyDate,
                    setDailyDate
                  );
                }}
              />
              &nbsp; ~ &nbsp;
              <DatePicker
                className="ranking-datepicker"
                value={dailyDate.to}
                onChange={(date, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    dailyDate,
                    setDailyDate
                  );
                }}
              />
            </div>
          </div>
          <Button
            text="検索"
            type="submit"
            disabled={formValidation()}
            style={{ margin: "auto", display: "block", marginTop: 20 }}
          />
        </form>
        <div className="ranking-cards-total">
          {searchParams.output === "user"
            ? `TOTAL ${total}人`
            : searchParams.output === "brand"
            ? `TOTAL ${total}ブランド`
            : searchParams.output === "shop"
            ? `TOTAL ${total}店舗`
            : ""}
        </div>
        <div className="ranking-cards-sort">
          並び替え{" "}
          <Select
            value={searchParams.sort}
            size="small"
            disabled={disabled}
            onChange={(val) => {
              dispatch(actions.saveSearchParam({ sort: val }));
            }}
          >
            <Select.Option value="sales">売上金額</Select.Option>
            {searchParams.aggregate_type === "sns" ||
            searchParams.aggregate_type === "instagram" ||
            searchParams.aggregate_type === "twitter" ? (
              ""
            ) : (
              <>
                <Select.Option value="pv_count">PV/視聴者数/再生回数</Select.Option>
                <Select.Option value="like_count">いいね</Select.Option>
              </>
            )}
          </Select>
        </div>
        <div
          className="ranking-cards-area"
          style={
            topRankList.length > 0 ? { height: "auto", display: "block" } : {}
          }
        >
          {topRankList.length > 0 ? (
            <>
              <div className="cards-area-row">
                {topRankList.map((data, i) => {
                  return getRankCard(data);
                })}
              </div>
              {buttomRankList.length > 0 ? (
                <div className="cards-area-row">
                  {buttomRankList.map((data, i) => {
                    return getRankCard(data);
                  })}
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <div class="ant-empty ant-empty-normal">
              <div class="ant-empty-image">
                <svg
                  class="ant-empty-img-simple"
                  width="64"
                  height="41"
                  viewBox="0 0 64 41"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                    <ellipse
                      class="ant-empty-img-simple-ellipse"
                      cx="32"
                      cy="33"
                      rx="32"
                      ry="7"
                    ></ellipse>
                    <g class="ant-empty-img-simple-g" fill-rule="nonzero">
                      <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                      <path
                        d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                        class="ant-empty-img-simple-path"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="ant-empty-description">データがありません</div>
            </div>
          )}
        </div>
        <Pagination
          size="small"
          total={paging_total}
          pageSize={10}
          disabled={disabled}
          current={currentPage}
          style={{ textAlign: "center" }}
          onChange={(newPage) => {
            const dif = newPage - currentPage;
            const maxPage = Math.floor(paging_total / 10) + ((paging_total % 10 == 0) ? 0 : 1);
            let setPage = 0;
            if (dif < 5 && dif > -5) {
              setPage = newPage;
            } else if (dif === 5) {
              setPage = currentPage + 10;
            } else if (dif === -5) {
              setPage = currentPage - 10;
            } else if (dif > 5) {
              setPage = maxPage;
            } else if (dif < -5) {
              setPage = 1;
            }
            if (setPage > maxPage) {
              setPage = maxPage;
            }
            if (setPage < 1) {
              setPage = 1;
            }
            setCurrentPage(setPage);
            dispatch(
              actions.saveSearchParam({
                offset: setPage === 1 ? 0 : (setPage - 1) * 10,
              })
            );
          }}
        />
      </div>
      <Footer />
    </div>
  );
};
export default Ranking;

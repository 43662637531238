import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { downloadFile } from "../utils/fnUtil";
import { actions as messageActions } from "./messageSlice";
import moment from "moment";
import { actions as loadingActions } from "./loadingSlice";

export const fetchAnalyzeContents = createAsyncThunk(
  "fetchAnalyzeContents",
  async (params, { dispatch }) => {
    dispatch(loadingActions.multiLoadingOn());
    const [res, resFilter] = await Promise.all([
      axios.get("analyze/contents/list", { params }),
      axios.get("analyze/contents/list/filter", { params }),
    ]);
    dispatch(loadingActions.multiLoadingOff());
    return {
      res: res,
      resFilter: resFilter
    };
  }
);
/* export const fetchAnalyzeContentsFilter = createAsyncThunk(
  "fetchAnalyzeContentsFilter",
  async (params) => {
    const res = await axios.get("analyze/contents/list/filter", { params });
    return res;
  }
); */

export const fetchContentsFavoriteItem = createAsyncThunk(
  "fetchContentsFavoriteItem",
  async (params) => axios.get("csv/favorite_item", { params })
);

export const updateCsvContentsFavoriteItem = createAsyncThunk(
  "updateCsvContentsFavoriteItem",
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post("csv/favorite_item/update", params);
    if (updateFavoriteRes?.code === "0")
      dispatch(
        messageActions.setMessage({
          messages:["お気に入り登録が完了しました"],
        })
      );
    return params.favorite;
  }
);

export const csvDownloadContents = createAsyncThunk(
  "csvDownloadContents",
  async (params) => await axios.get("csv/download", { params })
);

const initialSearchParams = {
  start_date: "",
  end_date: "",
  user_code: [],
  brand_code: [],
  shop_code: [],
  contents_type: [],
  offset: 0,
  count: 10,
  sort: "sales_desc",
};

const initialState = {
  analyzeList: [],
  filterList: {
    contents_type: [],
    user_info: [],
    brand_info: [],
    shop_info: [],
  },
  checkedColumnOption:[],
  showFields: [],
  favorite_item: {},
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  filterUserVisible: false,
  filterBrandVisible: false,
  filterShopVisible: false,
  filterContentsVisible: false,
  infomationModal: false,
  infomationTitle: "",
  searchParams: initialSearchParams,
  total: 0,
  flag:false,
};
export const analyzeContentsSlice = createSlice({
  name: "analyzeContentsSlice",
  initialState: initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      filterUserVisible: false,
      filterBrandVisible: false,
      filterShopVisible: false,
      filterContentsVisible: false,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    saveShowCustomFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      checkedColumnOption: payload,
    }),
    changeFlag: (state) => ({
      ...state,
      flag: true,
    }),
    openFilterUserModal: (state) => ({
      ...state,
      filterUserVisible: true,
    }),
    openFilterBrandModal: (state) => ({
      ...state,
      filterBrandVisible: true,
    }),
    openFilterShopModal: (state) => ({
      ...state,
      filterShopVisible: true,
    }),
    openFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: true,
    }),
    openSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: true,
    }),
    openCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    openInfomationModal: (state, { payload }) => ({
      ...state,
      infomationModal: true,
      infomationTitle: payload,
    }),
    closeFilterUserModal: (state) => ({
      ...state,
      filterUserVisible: false,
    }),
    closeFilterBrandModal: (state) => ({
      ...state,
      filterBrandVisible: false,
    }),
    closeFilterShopModal: (state) => ({
      ...state,
      filterShopVisible: false,
    }),
    closeFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: false,
    }),
    closeSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: false,
    }),
    closeCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    closeInfomationModal: (state) => ({
      ...state,
      infomationModal: false,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyzeContents.fulfilled, (state, { payload }) => ({
      ...state,
      analyzeList: payload.res?.item ?? [],
      total: (payload.res?.params?.offset == 0 ? payload.res?.total : state?.total) ?? 0,
      flag:false,
      filterList:{
            user_info: state.flag? payload.resFilter?.filter_list?.user_info ?? [] : state.filterList.user_info,
            brand_info: state.flag? payload.resFilter?.filter_list?.brand_info ?? [] : state.filterList.brand_info,
            shop_info: state.flag? payload.resFilter?.filter_list?.shop_info ?? [] : state.filterList.shop_info,
            contents_type: payload.resFilter?.filter_list?.contents_type ?? [],
          },
    })); 
    builder.addCase(
      fetchContentsFavoriteItem.fulfilled,
      (state, { payload }) => ({
        ...state,
        favoriteItem: payload?.item?.favorite ?? {},
      })
    );
    builder.addCase(
      updateCsvContentsFavoriteItem.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          favoriteItem: payload,
        };
      }
    );
    builder.addCase(csvDownloadContents.fulfilled, (state, { payload }) => {
      if (typeof payload === "string") {
        downloadFile(
          payload,
          `コンテンツ分析(社員コンテンツ別分析)_${moment().format(
            "YYYYMMDDHHmmss"
          )}.csv`
        );
      }
      return {
        ...state,
        infomationModal: payload?.code === "-1",
        infomationTitle: "出力データが存在しません",
        csvDownloadModalVisible: false,
      };
    });
  },
});

export const actions = analyzeContentsSlice.actions;

export default analyzeContentsSlice.reducer;

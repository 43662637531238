import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as messageActions } from "../slices/messageSlice";
import { actions as loadingActions } from "../slices/loadingSlice";
import axios from "../utils/axiosUtil";

export const shopsInit = createAsyncThunk("shopsInit", async (params, {dispatch}) => {
  dispatch(loadingActions.multiLoadingOn());
  const [shopsRes, brandsRes] = await Promise.all([
    axios.get("/shops/list", { params } ),  
    axios.get("/brands/list",{ params: { count: 0, sort : "brand_name_asc" }}),
  ]);
  dispatch(loadingActions.multiLoadingOff());
  return {
    shopsList: shopsRes?.item ?? [],
    total_count: shopsRes?.total_count,
    active_count: shopsRes?.active_count,
    pageSize: shopsRes?.search_count,
    brands: (brandsRes?.item ?? []).map(b => ({
      ...b,
      label: b.brand_name,
      value: b.brand_code,
    })),
  };
});

export const fetchShops = createAsyncThunk(
  "fetchShops",
  async params => await axios.get("/shops/list", { params } )
);

export const fetchShopDetail = createAsyncThunk(
  "fetchShopDetail",
  async params => await axios.get("/shops/detail", { params } )
);

export const updateShop = createAsyncThunk(
  "updateShop",
  async (params, {dispatch}) => {

    const shopUpdatRes = await axios.post("/shops/update?img", params)

    params.division !== "delete" &&
      shopUpdatRes?.code === "0" &&
      dispatch(
        messageActions.setMessage({
          messages: [
            `${params.division === "insert" ? "登録" : "更新"}が完了しました`,
          ],
        })
      );
  }
);


const initialSearchParams = {
  brand_code: [],
  count:10,
  offset: 0,
  shop_code: "",
  shop_name: "",
  prefectures: [],
  tel_number: "",
  start_date: "",
  end_date: "",
  deleted_flg: 0,
  shop_image_height: 0,
  sort: "shop_code_desc",
};

const initialEditParams = {
  shop_code: "",
  shop_name: "",
  shop_namekana:"",
  ec_display_flag:1,
  brand:[],
  brand_code: [],
  zip:{ number1: "", number2: "" },
  prefectures_code:"",
  municipality:"",
  house_number:"",
  house_info:"",
  tel:{ number1: "", number2: "", number3: "" },
  fax:{ number1: "", number2: "", number3: "" },
  business_hours:{ start: "", end: "" },
  shop_holidays:{
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
    public_holiday: 0
  },
  icon_image_url: "",
  status:"",
  date:"",
  description:"",
  business_hours1:{ start: "", end: "", description:"" },
  business_hours2:{ start: "", end: "", description:"" },
  irregular_holidays:"",
  link:"",
  start_date:"",
  end_date:"",
  location:{ latitude: null, longitude: null },
};
const initialState = {
  shopsList: [],
  activeShop: initialEditParams,
  brands: [],
  editMode:"",
  editModalVisible: false,
  shopFileUploadModalVisible:false,
  deleteModalVisible: false,
  searchParams: initialSearchParams,
  total_count: 0,
  active_count: 0,
  pageSize: 0
}


export const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    clear: () => initialState,
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveDelete: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        deleted_flg: state.searchParams.deleted_flg === 1 ? 0 : 1,
      }
    }),
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          brand_code: state.user_brand_code,
          status_flg: payload,
        },
      };
    },
    newEditModal: (state, { payload }) => ({
      ...state,
      editMode: "insert",
      editModalVisible: true,
      activeShop : initialEditParams
    }),
    fileUploadModal: (state, { payload }) => ({
      ...state,
      shopFileUploadModalVisible: true,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      activeShop: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      editMode:"",
      activeShop:initialEditParams,
    }),
    closeFileUploadModal: (state, { payload }) => ({
      ...state,
      shopFileUploadModalVisible: false,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    editArticle: state => ({
      ...state,
      editModalVisible: false,
    }),
    deleteArticle: state => ({
      ...state,
      deleteModalVisible: false,
      activeArticle: null,
    }),
  },
  extraReducers: builder => {
    builder.addCase(shopsInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchShops.fulfilled, (state, { payload }) => ({
      ...state,
      shopsList: payload?.item ?? [],
      total_count: payload?.total_count ?? 0,
      active_count: payload?.active_count ?? 0,
      pageSize: payload?.search_count ?? 0,
    }));
    builder.addCase(fetchShopDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeShop: payload?.item ?? initialEditParams,
      editMode: "update",
      editModalVisible: true,
    }));
    builder.addCase(updateShop.fulfilled, state => ({
      ...state,
      editMode: "",
      editModalVisible: false,
      deleteModalVisible: false,
      searchParams: { ...state.searchParams },
    }));
  },
});

export const shopActions = shopsSlice.actions;

export default shopsSlice.reducer;

import { useEffect } from "react";
import { useFormik } from "formik";
import { Table, Image } from "antd";
import { useHistory } from "react-router-dom";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { userAuthOptions } from "../../constants/options";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  staffInit,
  fetchStaff,
  fetchStaffDetail,
  updateStaff,
  csvDownloadStaff,
  updateCsvFavoriteItem,
} from "../../slices/staffSlice";
import { uploadActions } from "../../slices/fileUploadSlice";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import GenernalInput from "../../components/generalInput";
import GeneralCheckbox from "../../components/cusCheckbox/genernalCheckbox";
import DeleteModal from "../../components/modal/deleteModal";
import StaffSignUpModal from "../../components/modal/staffSignUpModal";
import StaffEditModal from "../../components/modal/staffEditModal";
import StaffCsvDownloadModal from "../../components/modal/staffCsvDownloadModal";
import InfomationModal from "../../components/modal/infomationModal";
import noimage from "../../images/noimage.png";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import "./style.scss";

const StaffList = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const {
    shops,
    brands,
    staffList,
    totalCount,
    activeCount,
    pageSizeCount,
    deleteModalVisible,
    deleteStaff = {},
    searchParams,
    staffCsvDownloadModal,
    favoriteItem,
    infomationModal,
    infomationTitle,
  } = useSelector(state => state.staff);

  const { user_auth } = useSelector(state => state.account);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => dispatch(actions.saveSearchParam(values)),
  });

  useEffect(() => {
    dispatch(staffInit());
    return () => dispatch(actions.clear());
  }, [dispatch]);

  useUpdateEffect(
    () => dispatch(fetchStaff(searchParams)),
    [searchParams, dispatch]
  );

  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const columns = [
    {
      title: "アイコン",
      key: "img",
      width: TableColumnWidth.image,
      render: staff => (
        <Image
          src={staff.img || noimage}
          width={100}
          height={100}
          className="avater"
          onClick={() =>
            history.push({
              pathname: "/staffResult",
              state: { staff_code: staff.user_code },
            })
          }
          preview={{ mask: "社員実績", visible: false }}
        />
      ),
      align: "center",
    },
    {
      title: () => (
        <>
          <span>社員CD </span>
          <span className="sort-span" onClick={createOnClikSort("user_code")}>
            {formik.values.sort === "user_code_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "user_code",
      dataIndex: "user_code",
      align: "center",
      width: TableColumnWidth.staff.userCd,
      render: userCode => userCode || "-",
    },
    {
      title: () => (
        <>
          <span>社員 </span>
          <span className="sort-span" onClick={createOnClikSort("user_name")}>
            {formik.values.sort === "user_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "user_name",
      align: "center",
      width: TableColumnWidth.userName,
      render: staff => (
        <span className="display-linebreak">
          {staff?.user_name
            ? `${staff?.user_name}\n${
                staff?.user_nickname ? `(${staff?.user_nickname})` : ""
              }`
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>権限 </span>
          <span className="sort-span" onClick={createOnClikSort("user_auth")}>
            {formik.values.sort === "user_auth_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "user_auth",
      dataIndex: "user_auth",
      align: "center",
      width: TableColumnWidth.staff.userAuth,
      render: userAuth =>
        userAuthOptions.find(auth => auth.value === userAuth)?.label || "-",
    },
    {
      title: () => (
        <>
          <span>店舗 </span>
          <span className="sort-span" onClick={createOnClikSort("shop_name")}>
            {formik.values.sort === "shop_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "shop_name",
      dataIndex: "shop_name",
      align: "center",
      width: TableColumnWidth.shopName,
      render: shopName => shopName || "-",
    },
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {formik.values.sort === "brand_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "brand_name",
      dataIndex: "brand_name",
      align: "center",
      width: TableColumnWidth.brandName,
      render: brandName => brandName || "-",
    },
    {
      title: () => (
        <>
          <span>承認スキップ </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("approval_skip")}
          >
            {formik.values.sort === "approval_skip_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "approval_skip",
      dataIndex: "approval_skip",
      align: "center",
      width: TableColumnWidth.staff.approvalSkip,
      render: approvalSkip => (
        <span className="display-linebreak">
          {approvalSkip === 3
            ? "コーディネート\nブログ"
            : approvalSkip === 2
            ? "コーディネート"
            : approvalSkip === 1
            ? "ブログ"
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("status")}>
            {formik.values.sort === "status_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "status",
      dataIndex: "status",
      align: "center",
      width: TableColumnWidth.staff.status,
      render: status =>
        status === 0
          ? "使用不可"
          : status === 1
          ? "使用中"
          : status === 2
          ? "削除済み"
          : "-",
    },
    {
      title: "編集",
      align: "center",
      width: TableColumnWidth.icon,
      render: current => (
        <>
          {[0, 1, 2].includes(current.status) &&
          user_auth <= current.user_auth ? (
            <Icon
              component={Icons.EditIcon}
              style={{ fontSize: 25 }}
              onClick={() =>
                dispatch(
                  fetchStaffDetail({
                    user_code: current.user_code,
                    deleted_flg: 1,
                  })
                )
              }
            />
          ) : (
            <Icon
              component={Icons.DisableEditIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
    {
      title: "削除",
      align: "center",
      width: TableColumnWidth.icon,
      render: current => (
        <>
          {current.status !== 2 &&
          user_auth < 3 &&
          user_auth <= current.user_auth ? (
            <Icon
              component={Icons.RemoveIcon}
              style={{ fontSize: 25 }}
              onClick={() => dispatch(actions.openDeleteModal(current))}
            />
          ) : (
            <Icon
              component={Icons.DisableRemoveIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
  ];
  const options = [
    { label: "店舗CD", value: "shop_cd", colSpan: 4 },
    { label: "店舗名", value: "shop_name", colSpan: 5 },
    { label: "ブランドCD", value: "brand_cd", colSpan:  5},
    { label: "ブランド名", value: "brand_name", colSpan:  5},
    { label: "社員CD", value: "user_cd", colSpan: 5 },
    { label: "社員名", value: "user_name", colSpan: 4 },
    { label: "社員名(カナ)", value: "user_name_kana", colSpan: 5 },
    { label: "サイト表示名", value: "user_nick_name", colSpan: 5 },
    { label: "メールアドレス", value: "mail_address", colSpan: 5 },
    { label: "パスワード", value: "password", colSpan: 5 },
    { label: "電話番号", value: "tel", colSpan: 4 },
    { label: "性別", value: "gender", colSpan: 5 },
    { label: "身長", value: "height", colSpan: 5 },
    { label: "コメント", value: "comment", colSpan: 5 },
    { label: "利用開始日", value: "use_from_date", colSpan: 5 },
    { label: "利用終了日", value: "use_to_date", colSpan: 4 },
    { label: "Instagram ID", value: "insta_id", colSpan: 5 },
    { label: "Instagram フォロワー数", value: "insta_follower", colSpan: 5 },
    { label: "Twitter ID", value: "twitter_id", colSpan: 5 },
    { label: "Twitter フォロワー数", value: "twitter_follower", colSpan: 5 },
    { label: "参照元社員", value: "referrer_user", colSpan: 4 },
    { label: "権限", value: "user_auth", colSpan: 5 },
    { label: "承認スキップ(コーディネート)", value: "coordinate_approval_flag", colSpan: 5 },
    { label: "承認スキップ(ブログ)", value: "blog_approval_flag", colSpan: 5 },
    { label: "同時投稿", value: "multi_post", colSpan: 5 },
  ];
  return (
    <div className="content-body">
      <Header />
      <InfomationModal
        modalVisible={infomationModal}
        item={{ information_title: infomationTitle }}
        setModalVisible={() => {
          dispatch(actions.closeInfomationModal());
        }}
      />
      <StaffCsvDownloadModal
        modalVisible={staffCsvDownloadModal}
        options={options}
        formik={formik}
        displayShopList={shops}
        displayBrandList={brands}
        displayUserAuthList={userAuthOptions}
        favoriteItem={favoriteItem}
        onFavoriteItem={(checkedList) => {
          let param = {
            screen_type: 9,
            favorite: {},
          };
          options.forEach((item) => {
            param.favorite[item.value] = "0";
          });
          checkedList.forEach((item) => {
            param.favorite[item] = "1";
          });
          dispatch(updateCsvFavoriteItem(param));
        }}
        onClose={() => dispatch(actions.closeStaffCsvDownloadModal())}
        onDownload={(checkedList, checkedAuthList, selectedBrandList, selectedShopList) => {

          // すべての場合、条件なしとして設定
          const allValue = "ALL";
          if (selectedBrandList && selectedBrandList.indexOf(allValue) > -1) {
            selectedBrandList = null;
          }
          if (selectedShopList && selectedShopList.indexOf(allValue) > -1) {
            selectedShopList = null;
          }

          dispatch(
            csvDownloadStaff({
              screen_type: 9,
              user_auth: checkedAuthList,
              brand_code: selectedBrandList,
              shop_code: selectedShopList,
              columns: checkedList,
            })
          );
        }}
        colspan={8}
      />
      <div className="staff-container nf-page">
        <h2>社員マスタ</h2>
        <div className="total-data">
          全社員数 <span>{totalCount}</span>
        </div>
        <div className="total-data">
          利用中全社員数 <span>{activeCount}</span>
        </div>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <GenernalInput
              label="社員CD"
              placeholder="社員CDを入力してください"
              formik={formik}
              name={"user_code"}
            />
            <GenernalInput
              label="社員"
              placeholder="社員名またはサイト表示名を入力してください"
              formik={formik}
              name={"user_name"}
            />
            <GenernalInput
              label="権限"
              placeholder="権限を選択してください"
              formik={formik}
              name={"user_auth"}
              options={userAuthOptions.filter(auth => auth.value >= user_auth)}
            />
            <GenernalInput
              label="店舗"
              mode="multiple"
              name="shop_code"
              placeholder={"店舗を選択してください"}
              formik={formik}
              options={shops}
            />
            <GenernalInput
              label="ブランド"
              mode="multiple"
              name="brand_code"
              placeholder={"ブランドを選択してください"}
              formik={formik}
              options={brands}
            />
          </div>
          <Button text="検索" type="submit" />
          <GeneralCheckbox
            label="削除済み"
            checked={formik.values.deleted_flg === 1}
            onChange={() =>
              handlerFormikFieldChange(
                formik,
                "deleted_flg",
                formik.values.deleted_flg === 1 ? 0 : 1
              )
            }
          />
        </form>
        <div className="table-header">
          <h3>検索結果一覧</h3>
          <div className={user_auth > "1" ? "hidden button" : "button"}>
            <Button
              text="CSV取込"
              width="wider"
              onClick={() => dispatch(uploadActions.openFileUploadModal(1))}
            />
          </div>
          <div className={user_auth > "1" ? "hidden button" : "button"}>
            <Button
              text="CSV出力"
              width="wider"
              onClick={() => dispatch(actions.openStaffCsvDownloadModal(1))}
            />
          </div>
          <div className={user_auth > "2" ? "hidden button" : "button"}>
            <Button
              text="新規追加"
              theme="white"
              width="wider"
              onClick={() => dispatch(actions.openSignUpModal())}
            />
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={staffList}
          onChange={pagination => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total: pageSizeCount,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{ offsetHeader: 60 }}
        />
      </div>
      <StaffSignUpModal />
      <StaffEditModal />
      <DeleteModal
        modalVisible={deleteModalVisible}
        onCancel={() => dispatch(actions.closeDeleteModal())}
        item={deleteStaff.user_code}
        itemValue={deleteStaff.user_name}
        onOk={() =>
          dispatch(
            updateStaff({
              division: "delete",
              user_code: deleteStaff.user_code,
              shop_code: deleteStaff.shop_code,
            })
          )
        }
      />
      <Footer />
    </div>
  );
};

export default StaffList;

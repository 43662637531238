import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { downloadFile } from "../utils/fnUtil";
import { actions as messageActions } from "./messageSlice";
import moment from "moment";

export const fetchAnalyzeBrand = createAsyncThunk(
  "fetchAnalyzeBrand",
  async (params) => {
    const res = await axios.get("analyze/brand/list", { params });
    return res;
  }
);

export const fetchBrandFavoriteItem = createAsyncThunk(
  "fetchBrandFavoriteItem",
  async (params) => axios.get("csv/favorite_item", { params })
);

export const updateCsvBrandFavoriteItem = createAsyncThunk(
  "updateCsvBrandFavoriteItem",
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post("csv/favorite_item/update", params);
    if (updateFavoriteRes?.code === "0")
      dispatch(
        messageActions.setMessage({
          messages:["お気に入り登録が完了しました"],
        })
      );
    return params.favorite;
  }
);

export const csvDownloadBrand = createAsyncThunk(
  "csvDownloadBrand",
  async (params) => await axios.get("csv/download", { params })
);

const initialSearchParams = {
  start_date: "",
  end_date: "",
  contents_type: [],
  brand_code: [],
  offset: 0,
  count: 10,
  sort: "brand_name_desc",
};

const initialState = {
  analyzeList: [],
  filterList: {
    contents_type: [],
    brand_info: [],
  },
  showFields: [],
  favorite_item: {},
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  filterBrandVisible: false,
  infomationModal: false,
  infomationTitle: "",
  searchParams: initialSearchParams,
  total: 0,
  flag:false,
};

export const analyzeBrandSlice = createSlice({
  name: "analyzeBrand",
  initialState: initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      filterBrandVisible: false,
      filterContentsVisible: false,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    changeFlag: (state) => ({
      ...state,
      flag: true,
    }),
    openFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: true,
    }),
    openFilterBrandModal: (state) => ({
      ...state,
      filterBrandVisible: true,
    }),
    openSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: true,
    }),
    openCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    openInfomationModal: (state, { payload }) => ({
      ...state,
      infomationModal: true,
      infomationTitle: payload,
    }),
    closeFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: false,
    }),
    closeFilterBrandModal: (state) => ({
      ...state,
      filterBrandVisible: false,
    }),
    closeSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: false,
    }),
    closeCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    closeInfomationModal: (state) => ({
      ...state,
      infomationModal: false,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyzeBrand.fulfilled, (state, { payload }) => ({
      ...state,
      analyzeList: payload?.item ?? [],
      filterList: payload?.filter_list
        ? {
            contents_type: payload?.filter_list?.contents_type ?? [],
            brand_info: state.flag ? payload?.filter_list?.brand_info ?? [] : state.filterList.brand_info,
          }
        : { contents_type: [], brand_info: [] },
      total: (payload?.params?.offset == 0 ? payload?.total : state?.total) ?? 0,
      flag:false,
    }));
    builder.addCase(fetchBrandFavoriteItem.fulfilled, (state, { payload }) => ({
      ...state,
      favoriteItem: payload?.item?.favorite ?? {},
    }));
    builder.addCase(
      updateCsvBrandFavoriteItem.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          favoriteItem: payload,
        };
      }
    );
    builder.addCase(csvDownloadBrand.fulfilled, (state, { payload }) => {
      if (typeof payload === "string") {
        downloadFile(
          payload,
          `ブランド別分析_${moment().format("YYYYMMDDHHmmss")}.csv`
        );
      }
      return {
        ...state,
        infomationModal: payload?.code === "-1",
        infomationTitle: "出力データが存在しません",
        csvDownloadModalVisible: false,
      };
    });
  },
});

export const actions = analyzeBrandSlice.actions;

export default analyzeBrandSlice.reducer;

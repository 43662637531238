import {useEffect, useState} from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Image, DatePicker } from "antd";
import moment from "moment";
import GeneralInput from "../../generalInput";
import Button from "../../button";
import BaseUpload from "../../baseUpload";
import noimage from "../../../images/noimage.png";
import {actions, updateBrand, fetchBrand, brandInit, fetchMallBrand} from "../../../slices/brandSlice";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import Yup from "../../../utils/yupUtil";

import "./style.scss";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";


const BrandEditModal = () => {
  const dispatch = useDispatch();
  const [multiPostUsedFlag, setMultiPostUsedFlag] = useState(false);
  const { editMode, editModalVisible, activeBrand, searchParams, noMallBrandList, mallBrandCount } = useSelector(state => state.brand);
  const { live_flag, multi_post_flag }  = useSelector(state => state.account);
  const formik = useFormik({
    initialValues: {
      brand_code: activeBrand?.brand_code ?? "",
      brand_image_url: activeBrand?.brand_image_url ?? "",
      brand_name: activeBrand?.brand_name ?? "",
      brand_name_kana: activeBrand?.brand_name_kana ?? "",
      ec_url: activeBrand?.ec_url ?? "",
      ec_url_coordinate: activeBrand?.ec_url_coordinate ?? "",
      ec_url_blog: activeBrand?.ec_url_blog ?? "",
      ec_url_live_archive: activeBrand?.ec_url_live_archive ?? "",
      ec_url_movie: activeBrand?.ec_url_movie ?? "",
      reward_rate: activeBrand?.reward_rate ?? "",
      mall_flag: activeBrand?.mall_flag ?? 0,
      multi_post_brand: activeBrand?.multi_post_brand ?? [],
      use_from_date: activeBrand?.use_from_date ? moment(activeBrand.use_from_date).format("YYYY-MM-DD") : moment(),
      use_to_date: activeBrand?.use_to_date  ? moment(activeBrand.use_from_date).format("YYYY-MM-DD") : moment("2999-12-31"),
      registration_button : false
    },
    validationSchema: Yup.object({
      brand_code: Yup.string().matches(/^[0-9a-zA-Z]+$/,'半角英数字で入力してください').required(),
      brand_name: Yup.string().required(),
      brand_name_kana: Yup.string().kana(),
      ec_url: Yup.string(),
      ec_url_coordinate: Yup.string().required(),
      ec_url_blog: Yup.string().required(),
      ec_url_live_archive: live_flag === 1 ? Yup.string().required() : null,
      ec_url_movie: live_flag === 1 ? Yup.string().required() : null,
      reward_rate: Yup.string().required().nullable().selected("必須項目です"),
      use_from_date: Yup.string().nullable().date_required(),
      use_to_date: Yup.string().nullable().date_required(),
      mall_flag:Yup.number().required(),
      multi_post_brand: Yup.array().mallBrand(),
    }),
    onSubmit: values => {
      dispatch(
        updateBrand({
          division : editMode,
          brand_code : values?.brand_code,
          brand_name : values?.brand_name,
          brand_name_kana : values?.brand_name_kana,
          ec_url : values?.ec_url,
          ec_url_coordinate : values?.ec_url_coordinate,
          ec_url_blog : values?.ec_url_blog,
          ec_url_live_archive : values?.ec_url_live_archive,
          ec_url_movie : values?.ec_url_movie,
          reward_rate : parseFloat(values?.reward_rate ?? 0).toFixed(1),
          use_from_date : moment(values.use_from_date).format("YYYY-MM-DD"),
          use_to_date : moment(values.use_to_date).format("YYYY-MM-DD"),
          brand_image_url : values?.brand_image_url,
          mall_flag : values?.mall_flag,
          multi_post_brand : values?.multi_post_brand,
        })
      )
    },
  });
  const {
    brand_code,
    brand_name,
    brand_image_url,
    use_from_date,
    use_to_date,
    ec_url_coordinate,
    ec_url_blog,
    ec_url_live_archive,
    ec_url_movie,
    registration_button,
    reward_rate,
    mall_flag,
    multi_post_brand
  } = formik.values;

  const handleFileChange = url => {
    handlerFormikFieldChange(formik, "brand_image_url", url);
  };

  const onBack = () => {
    dispatch(actions.closeEditModal())
    dispatch(fetchBrand(searchParams))
  };

  useEffect(() => {

    if(editMode === "update") {
      activeBrand?.brand_code && handlerFormikFieldChange(formik, "brand_code", activeBrand?.brand_code);
      activeBrand?.brand_image_url && handlerFormikFieldChange(formik, "brand_image_url", activeBrand?.brand_image_url);
      activeBrand?.brand_name && handlerFormikFieldChange(formik, "brand_name", activeBrand?.brand_name);
      activeBrand?.brand_name_kana && handlerFormikFieldChange(formik, "brand_name_kana", activeBrand?.brand_name_kana);
      activeBrand?.ec_url && handlerFormikFieldChange(formik, "ec_url", activeBrand?.ec_url);
      activeBrand?.ec_url_coordinate && handlerFormikFieldChange(formik, "ec_url_coordinate", activeBrand?.ec_url_coordinate);
      activeBrand?.ec_url_blog && handlerFormikFieldChange(formik, "ec_url_blog", activeBrand?.ec_url_blog);
      activeBrand?.ec_url_live_archive && handlerFormikFieldChange(formik, "ec_url_live_archive", activeBrand?.ec_url_live_archive);
      activeBrand?.ec_url_movie && handlerFormikFieldChange(formik, "ec_url_movie", activeBrand?.ec_url_movie);
      activeBrand?.reward_rate && handlerFormikFieldChange(formik, "reward_rate", activeBrand?.reward_rate);
      activeBrand?.use_from_date && handlerFormikFieldChange(formik, "use_from_date", moment(activeBrand.use_from_date));
      activeBrand?.use_to_date && handlerFormikFieldChange(formik, "use_to_date", moment(activeBrand.use_to_date));
      activeBrand?.mall_flag && handlerFormikFieldChange(formik, "mall_flag", activeBrand.mall_flag);
      activeBrand?.multi_post_brand && handlerFormikFieldChange(formik, "multi_post_brand", activeBrand.multi_post_brand.map(t => t.brand_cd));
    }
    handlerFormikFieldChange(
      formik,
      "registration_button",
      (
        brand_code != "" &&
        brand_name != "" &&
        brand_image_url != "" &&
        use_from_date != "" &&
        use_to_date != "" &&
        ec_url_coordinate != "" &&
        ec_url_blog != ""  &&
        ec_url_live_archive != ""  &&
        ec_url_movie != "" &&
        !formik.errors.multi_post_brand)
    );
  }, [editMode]);

  useEffect(() => {
    setMultiPostUsedFlag(
        multi_post_flag === 1
            ? editMode === "update" && mallBrandCount === 0
                ? true
                : multi_post_flag === 1 && editMode ==="insert"
                    ? true
                    : false
            : false
    );
  }, [mallBrandCount, editMode]);
  return (
    <Modal visible={editModalVisible} closable={false} width={950} footer={null} onCancel={onBack} afterClose={() => formik.resetForm()}>
      <>
        <h2 className="text-bold brand-title">
          {editMode === "insert" ? "ブランド登録" : "ブランド編集"}
          <span className="nf-text-required-style">　*は必須項目です</span>
        </h2>
        <div className="nf-brand-modal-area" >
          <div className="nf-brand-edit-area">
            <div className="file-upload-area">
              <div className="image-icon">
                <BaseUpload onChange={handleFileChange}>
                  <div className="avator">
                    <Image src={brand_image_url || noimage} preview={{ mask: "画像を選択", visible: false }}/>
                  </div>
                </BaseUpload>
              </div>
              <div className="image-text margin">png,gif,jpg,jpeg形式のみアップロード可</div>
              <div className="image-text">画像は5M以下でアップロードしてください</div>
              <div className="image-text">推奨画像サイズ：正方形</div>
            </div>
            <div className="nf-brand-edit-input-text">
              <GeneralInput
                label ="ブランドCD"
                labelTextAlign ="left"
                labelWidth="wider"
                name="brand_code"
                uneditable={editMode==="update"}
                requiredItem={editMode==="insert"}
                placeholder="ブランドCDを入力してください"
                selectSize="wide"
                styleType="block-grey"
                formik={formik}
              />
              <GeneralInput
                label ="ブランド名"
                labelTextAlign ="left"
                labelWidth="wider"
                requiredItem={true}
                placeholder="ブランド名を入力してください"
                selectSize="wide"
                styleType="block-grey"
                name="brand_name"
                formik={formik}
              />
              <GeneralInput
                label ="ブランド名(カナ)"
                labelTextAlign ="left"
                labelWidth="wider"
                placeholder="ブランド名(カナ)を入力してください"
                selectSize="wide"
                styleType="block-grey"
                name="brand_name_kana"
                formik={formik}
              />
              <GeneralInput
                label="EC URL"
                labelTextAlign ="left"
                labelWidth="wider"
                selectSize="wide"
                styleType="block-grey"
                placeholder={"https://"}
                name="ec_url"
                formik={formik}
              />
              <GeneralInput
                label ="EC URL コーディネート"
                labelTextAlign ="left"
                requiredItem={true}
                labelWidth="wider"
                selectSize="wide"
                styleType="block-grey"
                placeholder={"https://"}
                name="ec_url_coordinate"
                formik={formik}
              />
              <GeneralInput
                label ="EC URL ブログ"
                labelTextAlign ="left"
                requiredItem={true}
                labelWidth="wider"
                selectSize="wide"
                styleType="block-grey"
                placeholder={"https://"}
                name="ec_url_blog"
                formik={formik}
              />
              <GeneralInput
                label ="EC URL ライブ配信"
                labelTextAlign ="left"
                requiredItem={live_flag === 1}
                labelWidth="wider"
                selectSize="wide"
                styleType="block-grey"
                placeholder={"https://"}
                name="ec_url_live_archive"
                formik={formik}
              />
              <GeneralInput
                label ="EC URL 動画"
                labelTextAlign ="left"
                requiredItem={live_flag === 1}
                labelWidth="wider"
                selectSize="wide"
                styleType="block-grey"
                placeholder={"https://"}
                name="ec_url_movie"
                formik={formik}
              />
              <GeneralInput
                label="報酬レート"
                labelTextAlign ="left"
                labelWidth="wider"
                selectSize="half"
                requiredItem={true}
                name="reward_rate"
                styleType="block-grey"
                placeholder={"0.0"}
                options={"step"}
                min={0}
                max={100}
                step={0.1}
                customOnChange={value =>
                  value !== null && handlerFormikFieldChange(formik, "reward_rate", value.toFixed(1))
                }
                formik={formik}
                textAfter="%"
              />
              <div className="nf-input-wrapper nf-input-wrapper-herf">
                {multiPostUsedFlag ? (
                  <>
                    <label className="nf-input-label text-align-left label-width-wider label-required">モール利用</label>
                    <GeneralCheckbox
                        label="利用しない"
                        checked={mall_flag === 0}
                        onChange={(value) => {
                          handlerFormikFieldChange(formik, "mall_flag", value.target.checked ? 0 : 1)
                          handlerFormikFieldChange(formik, "multi_post_brand", [])
                        }}
                    />
                    <GeneralCheckbox
                        label="利用する"
                        checked={mall_flag === 1}
                        className={"nf-checkbox-wide"}
                        onChange={(value) => handlerFormikFieldChange(formik, "mall_flag", value.target.checked ? 1 : 0)}
                    />
                  </>
                ):(
                  <>
                    <label className="nf-input-label text-align-left label-width-wider">同時投稿</label>
                    <label className="nf-input-label">利用しない</label>

                  </>
                )}
              </div>
              {(multi_post_flag === 1 && mall_flag === 1) && (
                  <>
                    <GeneralInput
                        disabled={mall_flag === 0}
                        options={noMallBrandList.filter(x=> x.brand_code !== brand_code)}
                        label="同時投稿ブランド"
                        labelTextAlign ="left"
                        labelWidth="wider"
                        selectSize="half"
                        placeholder={"ブランドを選択してください"}
                        name={"multi_post_brand"}
                        styleType="block-grey"
                        formik={formik}
                    />
                  </>
              )}
              <div className="input-date-area  input-multi-area-wide">
                <div className="nf-input-wrapper nf-input-wrapper-title-short">
                  <label className="nf-input-label text-align-left label-width-wide text-required iregular-label">利用開始日</label>
                  <DatePicker
                    format={"YYYY.MM.DD"}
                    size="small"
                    placeholder={null}
                    value={use_from_date}
                    suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onChange={value =>
                      value !== null && use_to_date !== null
                        ? value > use_to_date
                          ? handlerFormikFieldChange(formik, "use_from_date", use_to_date)
                          : handlerFormikFieldChange(formik, "use_from_date", value)
                        : handlerFormikFieldChange(formik, "use_from_date", value)
                    }
                  />
                  {formik?.errors.use_from_date && formik?.touched.use_from_date && (
                    <span className="nf-input-error">{formik.errors.use_from_date}</span>
                  )}
                </div>
                <div className="nf-input-wrapper nf-input-wrapper-short">
                  <label className="text-required end-date">利用終了日</label>
                  <DatePicker
                    format={"YYYY.MM.DD"}
                    value={use_to_date}
                    placeholder={null}
                    suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onChange={value =>
                      value !== null && use_from_date !== null
                        ? value < use_from_date
                          ? handlerFormikFieldChange(formik, "use_to_date", use_from_date)
                          : handlerFormikFieldChange(formik, "use_to_date", value)
                        : handlerFormikFieldChange(formik, "use_to_date", value)
                    }
                  />
                  {formik?.errors.use_to_date && formik?.touched.use_to_date && (
                    <span className="nf-input-error">{formik.errors.use_to_date}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="nf-brand-btn-area">
            <Button text="戻る" theme="white" onClick={() => onBack()} />
            <Button
              text={editMode === "insert" ? "登録" : "更新"}
              theme={!registration_button ? !formik.isValid ? "light-black" : "black" : "black"}
              disabled={!registration_button ? !formik.isValid ? true : false : false}
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </>
    </Modal>
  )
};

export default BrandEditModal;

import { useEffect, useState, createRef, useLayoutEffect } from "react";
import PersonalIcon from "../personalIcon";
import moment from "moment";
import "./style.scss";

const LiveChats = ({
  streamers,
  chats = [],
  dateList = [],
  viewCount = 0,
  likeCount = 0,
  style,
  sycophancy = false,
  infoVisible = true,
}) => {
  const scrollBottomRef = createRef();

  const [position, setPosition] = useState(null);
  const [data, setData] = useState([]);

  useLayoutEffect(() => {
    // 追従しない場合、全データを表示
    if (!sycophancy) {
      setData(chats);
    }
  }, [chats]);

  useLayoutEffect(() => {
    // 追従しない場合、チャット欄を一番下までスクロール
    if (!sycophancy) {
      scrollBottomRef?.current?.scrollIntoView();
      return;
    }

    // 追従する場合
    if (position === null) return;
    const element = document.querySelector("#row" + position);
    if (!element) return;
    const elementTop = element.offsetTop;
    document.querySelector("#chat-area").scrollTo(0, elementTop - 125);
  }, [data]);

  useLayoutEffect(() => {
    // 追従しない場合、処理終了
    if (!sycophancy) return;

    // 追従する場合
    setData(position === null ? [] : chats.slice(0, position + 1));
  }, [position]);

  useEffect(() => {
    if (!chats || chats.length === 0) return;
    const interval = setInterval(() => {
      if (!sycophancy) return;
      if (!dateList) return;
      const video = document.getElementById("local-video");
      const plytime = video.currentTime;
      let newPosition = null;
      const nowTime = moment(dateList?.[0]).add(plytime, "second").format("YYYYMMDDHHmmss");
      for (let i = 0; i < (chats?.length ?? 0); i++) {
        const create = moment(chats?.[i]?.createdAt).format("YYYYMMDDHHmmss");
        if (Number(nowTime) > Number(create)) {
          newPosition = i;
        } else {
          break;
        }
      }
      setPosition(newPosition);
    }, 1000);
    return () => interval && clearInterval(interval);
  }, [chats]);

  return (
    <div className="live-chat-area" style={style}>
      <div className="info-area">
      {infoVisible ?
          <>
            <span>視聴者数 {viewCount}</span>
            <span className="like">いいね {likeCount}</span>
          </>
        :
          <span>チャット</span>
      }
      </div>
      <div id="chat-area" className="chat-area">
        {data?.map((e, index) => {
          return !streamers?.map(user => user?.user_code).includes(e?.user_cd) ?
          (
            <div className="chat left" key={e.id} id={"row" + index}>
              <span className="name">{e?.name_nickname || e?.user_name}</span>
              <span>{e?.signaling_text}</span>
            </div>
          ) : (
            <div className="chat right" key={e.id} id={"row" + index}>
              <div className="streamer-info">
                <PersonalIcon
                  imgSrc={streamers?.filter(user => user?.user_code === e?.user_cd)?.[0]?.image_url}
                  mask={false}
                  width={20}
                  height={20}
                  cursor={'default'}
                />
                <span className="user_name">{e?.name_nickname || e?.user_name}</span>
              </div>
              <span>{e?.signaling_text}</span>
            </div>
          );
        })}
        <div ref={scrollBottomRef}/>
      </div>
    </div>
  );
};
export default LiveChats;

import "./style.scss";
import React from "react";
import PersonalIcon from "../personalIcon";
import { useHistory } from "react-router-dom";
import { toMoneyString } from "../../utils/fnUtil";
const LiveRanking = ({ rankingList, type, accountAuth }) => {
  const history = useHistory();

  const onClickCard = (card) => {
    if (card?.user_code) {
      history.push({
        pathname: "/staffResult",
        state: { staff_code: card?.user_code },
      });
    }
  };

  const createCard = (card, i) => {
    return card ? (
      <div className="ranking-card left" onClick={() => onClickCard(card)}>
        <span className="ranking-no">{i + 1}</span>
        <PersonalIcon
          imgSrc={card?.image_url}
          width={100}
          height={100}
        />
        <p className="brand-name" style={{ marginTop: 7 }}>{card?.brand_name || "-"}</p>
        <p className="shop-name">{card?.shop_name || "-"}</p>
        <p className="user-name" style={{fontSize: 12}}>
          {card?.user_name}（{card?.user_nickname}）
        </p>
        <p className="ranking-result" style={{ color: "#03C04A" }}>
          ￥
          {card?.earnings || card?.earnings === 0 ? toMoneyString(card?.earnings) : "-"}
        </p>
        <div className="content-detail">
          <div className="left">視聴者数</div>
          <div>{toMoneyString(card?.pv_count) || "-"}</div>
        </div>
        <div className="content-detail" style={{ marginBottom: "10px" }}>
          <div className="left">いいね</div>
          <div>{toMoneyString(card?.like_count) || "-"}</div>
        </div>
      </div>
    ) : <div className="ranking-card-empty"></div>;
  };

  const createRightCard = (card, i) => {
    return card ? (
      <div className="ranking-card right" onClick={() => onClickCard(card)}>
        <div style={{display: "flex", flexDirection: "row"}}>
          <span className="ranking-no">{i + 1}</span>
          <PersonalIcon
            imgSrc={card?.image_url}
            width={50}
            height={50}
          />
          <div style={{ width: '50%', marginLeft: '10px', marginRight: '10px' }}>
            <p className="brand-name" style={{ marginTop: 7 }}>{card?.brand_name || "-"}</p>
            <p className="shop-name">{card?.shop_name || "-"}</p>
            <p className="user-name">
              {card?.user_name}（{card?.user_nickname}）
            </p>
          </div>
        </div>
        <p className="ranking-result">
          ￥
          {card?.earnings || card?.earnings === 0 ? toMoneyString(card?.earnings) : "-"}
        </p>
        <div className="content-detail-area">
          <div className="content-detail">
            <div className="left">{type === 'live' ? '視聴者数' : '再生回数'}</div>
            <div>{toMoneyString(card?.pv_count) || "-"}</div>
          </div>
          <div className="content-detail">
            <div className="left">いいね</div>
            <div>{toMoneyString(card?.like_count) || "-"}</div>
          </div>
        </div>
      </div>
    ) : <div className="ranking-card-empty"></div>;
  };

  return (
    <>
      {rankingList.length > 0 ? (
        <div className="live-ranking-cards">
          {createCard(rankingList[0], 0)}
          <div style={{ display: "flex", flexDirection: "column" }}>
            {createRightCard(rankingList[1], 1)}
            {createRightCard(rankingList[2], 2)}
          </div>
        </div>
      ) : (
        <div className="live-ranking-none">実績はありません</div>
      )}
    </>
  );
};

export default LiveRanking;

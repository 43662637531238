import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { downloadFile } from "../utils/fnUtil";
import { actions as messageActions } from "./messageSlice";
import moment from "moment";

export const fetchAnalyzeLive = createAsyncThunk(
  "fetchAnalyzeLive",
  async (params) => await axios.get("analyze/live/list", { params })
);

export const fetchLiveFavoriteItem = createAsyncThunk(
  "fetchLiveFavoriteItem",
  async (params) => axios.get("csv/favorite_item", { params })
);

export const updateCsvLiveFavoriteItem = createAsyncThunk(
  "updateCsvLiveFavoriteItem",
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post("csv/favorite_item/update", params);
    if (updateFavoriteRes?.code === "0")
      dispatch(
        messageActions.setMessage({
          messages:["お気に入り登録が完了しました"],
        })
      );
    return params.favorite;
  }
);

export const csvDownloadLive = createAsyncThunk(
  "csvDownloadLive",
  async (params) => await axios.get("csv/download", { params })
);

const initialSearchParams = {
  start_date: "",
  end_date: "",
  live_flag: [0, 1],
  user_code: [],
  brand_code: [],
  shop_code: [],
  offset: 0,
  count: 10,
  sort: "live_code_desc",
};

const initialState = {
  analyzeList: [],
  filterList: {
    contents_type: [],
    user_info: [],
    brand_info: [],
    shop_info: [],
  },
  showFields: [],
  favorite_item: {},
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  filterContentsVisible: false,
  filterUserVisible: false,
  filterBrandVisible: false,
  filterShopVisible: false,
  infomationModal: false,
  infomationTitle: "",
  searchParams: initialSearchParams,
  total: 0,
  flag:false,
};

export const analyzeLiveSlice = createSlice({
  name: "analyzeLive",
  initialState: initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      filterContentsVisible: false,
      filterUserVisible: false,
      filterBrandVisible: false,
      filterShopVisible: false,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    changeFlag: (state) => ({
      ...state,
      flag: true,
    }),
    openFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: true,
    }),
    openFilterUserModal: (state) => ({
      ...state,
      filterUserVisible: true,
    }),
    openFilterBrandModal: (state) => ({
      ...state,
      filterBrandVisible: true,
    }),
    openFilterShopModal: (state) => ({
      ...state,
      filterShopVisible: true,
    }),
    openSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: true,
    }),
    openCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    openInfomationModal: (state, { payload }) => ({
      ...state,
      infomationModal: true,
      infomationTitle: payload,
    }),
    closeFilterContentsModal: (state) => ({
      ...state,
      filterContentsVisible: false,
    }),
    closeFilterUserModal: (state) => ({
      ...state,
      filterUserVisible: false,
    }),
    closeFilterBrandModal: (state) => ({
      ...state,
      filterBrandVisible: false,
    }),
    closeFilterShopModal: (state) => ({
      ...state,
      filterShopVisible: false,
    }),
    closeSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: false,
    }),
    closeCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    closeInfomationModal: (state) => ({
      ...state,
      infomationModal: false,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyzeLive.fulfilled, (state, { payload }) => ({
      ...state,
      analyzeList: payload?.item ?? [],
      filterList: {
        contents_type: payload?.filter_list?.contents_type ?? [],
        user_info: state.flag ? payload?.filter_list?.user_info ?? [] : state.filterList.user_info,
        brand_info: state.flag ? payload?.filter_list?.brand_info ?? [] : state.filterList.brand_info,
        shop_info: state.flag ? payload?.filter_list?.shop_info ?? [] : state.filterList.shop_info,
      },
      total: (payload?.params?.offset == 0 ? payload?.total : state?.total) ?? 0,
      flag:false
    }));
    builder.addCase(
      fetchLiveFavoriteItem.fulfilled,
      (state, { payload }) => ({
        ...state,
        favoriteItem: payload?.item?.favorite ?? {},
      })
    );
    builder.addCase(
      updateCsvLiveFavoriteItem.fulfilled,
      (state, { payload }) => ({
        ...state,
        favoriteItem: payload,
      })
    );
    builder.addCase(csvDownloadLive.fulfilled, (state, { payload }) => {
      if (typeof payload === "string") {
        downloadFile(
          payload,
          `コンテンツ分析(ライブ)_${moment().format(
            "YYYYMMDDHHmmss"
          )}.csv`
        );
      }
      return {
        ...state,
        infomationModal: payload?.code === "-1",
        infomationTitle: "出力データが存在しません",
        csvDownloadModalVisible: false,
      };
    });
  },
});

export const actions = analyzeLiveSlice.actions;

export default analyzeLiveSlice.reducer;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Table, Checkbox, DatePicker } from "antd";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  notificationsInit,
  fetchNotifications,
  fetchNotificationDetail,
  updateNotification,
  fetchNotificationReserveDetail,
} from "../../slices/notificationsSlice";
import {
  handlerFormikFieldChange,
  validDatePickChecker,
} from "../../utils/fnUtil";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import GenernalInput from "../../components/generalInput";
import GeneralCheckbox from "../../components/cusCheckbox/genernalCheckbox";
import DeleteModal from "../../components/modal/deleteModal";
import NotificationEditModal from "../../components/modal/notificationEditModal";
import ReserveEditModal from "../../components/modal/reserveEditModal";
import InfomationModal from "../../components/modal/infomationModal";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import moment from "moment";
import "./style.scss";

const Notifications = () => {
  const dispatch = useDispatch();

  const {
    total,
    brands,
    shops,
    notificationsList,
    activeNotification,
    activeReserve,
    notificationDeleteModalVisible,
    reserveEditModalVisible,
    infomationModalVisible,
    infomationModalTitle,
    searchParams,
  } = useSelector((state) => state.notifications);
  const [createDate, setCreateDate] = useState({ from: null, to: null });
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) =>
      dispatch(
        actions.saveSearchParam({
          ...values,
          from_create_date: createDate.from?.format("YYYY-MM-DD"),
          to_create_date: createDate.to?.format("YYYY-MM-DD"),
        })
      ),
  });
  useEffect(() => {
    dispatch(notificationsInit({}));
    return () => dispatch(actions.clear());
  }, []);
  useUpdateEffect(
    () => dispatch(fetchNotifications(searchParams)),
    [searchParams, dispatch, reserveEditModalVisible]
  );

  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      formik.values.sort === desc
        ? asc
        : formik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const getSortIcon = (sort) => {
    return sort ? (
      <>
        &nbsp;
        <Icon component={Icons.SortAscendingIcon} style={{ fontSize: 10 }} />
      </>
    ) : (
      <>
        &nbsp;
        <Icon component={Icons.SortDescendingIcon} style={{ fontSize: 10 }} />
      </>
    );
  };
  const columns = [
    {
      title: () => (
        <>
          <span>CD </span>
          <span onClick={createOnClikSort("document_id")}>
            {getSortIcon(formik.values.sort === "document_id_asc")}
          </span>
        </>
      ),
      dataIndex: "document_id",
      key: "document_id",
      align: "center",
      width: TableColumnWidth.cd,
      render: (val) => val || "-",
    },
    {
      title: () => (
        <>
          <span>タイトル </span>
          <span onClick={createOnClikSort("title")}>
            {getSortIcon(formik.values.sort === "title_asc")}
          </span>
        </>
      ),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: TableColumnWidth.notice.title,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>メッセージ </span>
          <span onClick={createOnClikSort("message")}>
            {getSortIcon(formik.values.sort === "message_asc")}
          </span>
        </>
      ),
      key: "messge",
      dataIndex: "message",
      align: "center",
      width: TableColumnWidth.notice.message,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>登録日 </span>
          <span onClick={createOnClikSort("create_date")}>
            {getSortIcon(formik.values.sort === "create_date_asc")}
          </span>
        </>
      ),
      key: "create_date",
      dataIndex: "create_date",
      align: "center",
      width: TableColumnWidth.date,
      render: (date) => <span>{moment(date).format("YYYY.MM.DD")}</span>,
    },
    {
      title: "編集",
      align: "center",
      width: TableColumnWidth.icon,
      render: (current) => (
        <>
          {current?.delete_flag === 0 ? (
            <Icon
              component={Icons.EditIcon}
              style={{ fontSize: 25 }}
              onClick={() => {
                dispatch(
                  fetchNotificationDetail({
                    document_id: current.document_id,
                  })
                );
              }}
            />
          ) : (
            <Icon
              component={Icons.DisableEditIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
    {
      title: "設定",
      align: "center",
      width: TableColumnWidth.icon,
      render: (current) => {
        const deleteFlag = current?.delete_flag;
        const sendStatus = current?.send_status;
        return deleteFlag == 1 || (sendStatus && sendStatus != '0' && sendStatus != '1' && sendStatus != '2') ? (
          <Icon
            component={Icons.DisableSettingIcon}
            style={{ fontSize: 25 }}
            disabled={true}
          />
        ) : (
          <Icon
            component={Icons.SettingIcon}
            style={{ fontSize: 25 }}
            onClick={() => {
              dispatch(
                fetchNotificationReserveDetail({
                  document_id: current.document_id,
                })
              );
            }}
          />
        );
      },
    },
    {
      title: "削除",
      align: "center",
      width: TableColumnWidth.icon,
      render: (current) => (
        <>
          {current?.delete_flag === 0 ? (
            <Icon
              component={Icons.RemoveIcon}
              style={{ fontSize: 25 }}
              onClick={() => {
                dispatch(actions.openDeleteModal(current));
              }}
            />
          ) : (
            <Icon
              component={Icons.DisableRemoveIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <div className="content-body">
      <Header />
      <div className="notifications-container nf-page">
        <h2>お知らせ登録・配信設定</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <GenernalInput
              label="タイトル"
              placeholder="タイトルを入力してください"
              formik={formik}
              name={"title"}
            />
            <GenernalInput
              label="メッセージ"
              placeholder="メッセージを入力してください"
              formik={formik}
              name={"message"}
            />
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center">登録日</label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={{
                  backgroundColor: "white",
                  fontSize: 16,
                  height: 32,
                  width: 120,
                }}
                value={createDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    createDate,
                    setCreateDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={{
                  backgroundColor: "white",
                  fontSize: 16,
                  height: 32,
                  width: 120,
                }}
                value={createDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    createDate,
                    setCreateDate
                  );
                }}
              />
            </div>
          </div>
          <div className="search-item-deleted">
            <Button text="検索" type="submit" />
            <GeneralCheckbox
              label="削除済み"
              className="search-item-checkbox"
              checked={formik.values.show_delete_flag === 1}
              onChange={(value) =>
                handlerFormikFieldChange(
                  formik,
                  "show_delete_flag",
                  value.target.checked ? 1 : 0
                )
              }
            />
          </div>
        </form>
        <div className="table-header">
          <h3>検索結果一覧</h3>
          <Button
            text="新規作成"
            theme="white"
            width="wider"
            onClick={() => dispatch(actions.openEditModal_insert())}
          />
        </div>
        <Table
          columns={columns}
          dataSource={notificationsList}
          onChange={(pagination) => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{offsetHeader: 60}}
        />
      </div>
      <InfomationModal
        modalVisible={infomationModalVisible}
        item={{ information_title: infomationModalTitle }}
        setModalVisible={() => {
          dispatch(actions.closeInfomationModal());
        }}
      />
      <NotificationEditModal />
      <ReserveEditModal
        brands={brands}
        shops={shops}
        reserveEditModalVisible={reserveEditModalVisible}
        closeReserveEditModal={() => dispatch(actions.closeReserveEditModal())}
        reserve={activeReserve}
      />
      <DeleteModal
        modalVisible={notificationDeleteModalVisible}
        onCancel={() => dispatch(actions.closeDeleteModal())}
        item={"お知らせ"}
        itemValue={activeNotification.document_id}
        onOk={() => {
          dispatch(
            updateNotification({
              division: "delete",
              document_id: activeNotification.document_id,
            })
          );
        }}
      />
      <Footer />
    </div>
  );
};

export default Notifications;

import { useState } from "react";
import { Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Button from "../../button";
import classNames from "classnames";
import "./style.scss";

export const layoutMap = [
  { count: 1, maxCol: 1, boxtype: "1" },
  { count: 2, maxCol: 2, boxtype: "2" },
  { count: 3, maxCol: 3, boxtype: "3" },
  { count: 4, maxCol: 2, boxtype: "4" },
  { count: 6, maxCol: 3, boxtype: "5" },
  { count: 2, maxCol: 1, boxtype: "6" },
  { count: 3, maxCol: 1, boxtype: "7" },
  { count: 6, maxCol: 2, boxtype: "8" },
  { count: 3, maxCol: 2, boxtype: "9" },
];

const LayoutModal = ({ visible, onCancel, onOk, availableCount }) => {
  const [currentLayout, setCurrentLayout] = useState("-1");

  const limitedLayout = layoutMap.filter(l => l.count <= availableCount);

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      width={980}
      style={{ minWidth: 980 }}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <div className="layout-modal">
        <h2>レイアウト選択</h2>
        <div className="layout-container">
          {limitedLayout.map(layout => (
            <div
              key={layout.boxtype}
              className={classNames("layout-box", {
                "layout-box-active": layout.boxtype === currentLayout,
              })}
              onClick={() =>
                setCurrentLayout(
                  currentLayout === layout.boxtype ? "-1" : layout.boxtype
                )
              }
            >
              <div className="layout-title">
                <h3>レイアウト{layout.boxtype}</h3>
              </div>
              <div
                className={classNames("layout-plus-container", {
                  [`col-${layout.maxCol}`]: layout.maxCol,
                })}
              >
                {Array.from({ length: layout.count }, (_, subIndex) => (
                  <div
                    key={subIndex}
                    className={classNames("layout-plus", {
                      plus: layout.boxtype === "9" && subIndex === 0,
                    })}
                  >
                    <PlusOutlined />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div>
          <div className="action-container">
            <Button text="戻る" theme="white" onClick={onCancel} />
            <Button
              disabled={!limitedLayout.some(l => l.boxtype === currentLayout)}
              text="挿入"
              onClick={() =>
                onOk(limitedLayout.find(l => l.boxtype === currentLayout))
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LayoutModal;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as messageActions } from "../slices/messageSlice";
import { actions as loadingActions } from "./loadingSlice";
import { downloadFile } from "../utils/fnUtil";
import axios from "../utils/axiosUtil";
import moment from "moment";

export const staffInit = createAsyncThunk(
  "staffInit",
  async (_, { dispatch }) => {
    dispatch(loadingActions.multiLoadingOn());
    const [staffRes, shopsRes, brandsRes, favotiteItemRes] = await Promise.all([
      axios.get("staff/list", { params: { sort: "user_code_desc", count: 10 } }),
      axios.get("shops/list", { params: { count: 0, sort : "shop_code_asc"  } }),
      axios.get("brands/list", { params: { count: 0, sort : "brand_name_asc" } }),
      axios.get("csv/favorite_item", { params: { screen_type: 9 } }),
    ]);
    dispatch(loadingActions.multiLoadingOff());

    return {
      totalCount: staffRes.total_count,
      activeCount: staffRes.active_count,
      pageSizeCount: staffRes.search_count,
      staffList: (staffRes.item ?? []).map(s => ({
        ...s,
        brand_name: (s.brand ?? [])[0]?.brand_name,
      })),
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      shops: (shopsRes?.item ?? []).map(s => ({
        ...s,
        label: s.shop_code + ":" + s.shop_name,
        value: s.shop_code,
      })),
      favoriteItem: favotiteItemRes?.item?.favorite,
    };
  }
);
export const fetchShops = createAsyncThunk(
  "fetchShops",
  async params => await axios.get("shops/list", { params: { count: 0, sort : "shop_code_asc"  } }),
);


export const fetchfavotiteItem = createAsyncThunk(
  "fetchfavotiteItem",
  async (params) => axios.get("csv/favorite_item", { params })
);


export const fetchStaff = createAsyncThunk(
  "fetchStaff",
  async params => await axios.get("staff/list", { params })
);

export const fetchStaffDetail = createAsyncThunk(
  "fetchStaffDetail",
  async params => {
    const staffDetailRes = await axios.get("staff/detail", { params });
    return {
      ...staffDetailRes,
      authMenuInvisible: params.authMenuInvisible,
    };
  }
);

export const fetchReferrerStaffDetail = createAsyncThunk(
  "fetchReferrerStaffDetail",
  async params => await axios.get("staff/detail", { params })
);

export const checkStaffExist = createAsyncThunk(
  "checkStaffExist",
  async params => await axios.get("staff/detail", { params })
);

export const updateStaff = createAsyncThunk(
  "updateStaff",
  async (params, { dispatch }) => {
    dispatch(loadingActions.multiLoadingOn());
    
    await Promise.all([
      axios.post("staff/sns/follower/update", {
        user_code: params.user_code,
        sns_type: 1,
        sns_account: params.insta_id,
      }),
      axios.post("staff/sns/follower/update", {
        user_code: params.user_code,
        sns_type: 2,
        sns_account: params.twitter_id,
      }),
    ]);

    const staffUpdateRes = await axios.post("staff/update?img", params);

    dispatch(loadingActions.multiLoadingOff());

    staffUpdateRes?.code === "0" && params.division !== "delete" &&
      dispatch(
        messageActions.setMessage({
          messages: [
            `${params.division === "insert" ? "登録" : "更新"}が完了しました`,
          ],
        })
      );
  }
);

export const csvDownloadStaff = createAsyncThunk(
  "csvDownloadStaff",
  async (params) => await axios.get("csv/download", { params })
);

export const updateCsvFavoriteItem = createAsyncThunk(
  "updateCsvFavoriteItem",
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post("csv/favorite_item/update", params);
    if (updateFavoriteRes?.code === "0")
      dispatch(
        messageActions.setMessage({
          messages:["お気に入り登録が完了しました"],
        })
      );
    return params.favorite;
  }
);

const initialSearchParams = {
  user_code: "",
  user_name: "",
  user_auth: [],
  brand_code: [],
  shop_code: [],
  deleted_flg: 0,
  offset: 0,
  count: 10,
  sort: "user_code_desc",
};

const initialCsvDownloadParams = {
  brand_code: [],
  shop_code: [],
  user_auth: [],
};

const initialState = {
  staffList: [],
  activeStaff: {},
  deleteStaff: {},
  totalCount: "-",
  active: "-",
  pageSizeCount: 1,
  brands: [],
  shops: [],
  referrerStaff: null,
  favoriteItem: null,
  userExist: false,
  referrerStaffValid: true,
  editModalVisible: false,
  deleteModalVisible: false,
  signUpModalVisible: false,
  staffCsvDownloadModal: false,
  infomationModal: false,
  infomationTitle: "",
  csvDownloadParams: initialCsvDownloadParams,
  searchParams: initialSearchParams,
};

export const staffSlice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeStaff: payload,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteStaff: payload,
    }),
    openEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: true,
    }),
    openSignUpModal: state => ({
      ...state,
      signUpModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      referrerStaff: null,
      editModalVisible: false,
      userExist: false,
      referrerStaffValid: true,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    closeEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: false,
    }),
    closeSignUpModal: state => ({
      ...state,
      signUpModalVisible: false,
      referrerStaff: null,
      userExist: false,
      referrerStaffValid: true,
    }),
    openInfomationModal: (state, { payload }) => ({
      ...state,
      infomationModal: true,
      infomationTitle: payload,
    }),
    closeInfomationModal: (state) => ({
      ...state,
      infomationModal: false,
    }),
    openStaffCsvDownloadModal: (state) => ({
      ...state,
      staffCsvDownloadModal: true,
      csvDownloadParams: initialCsvDownloadParams,
    }),
    closeStaffCsvDownloadModal: (state) => ({
      ...state,
      staffCsvDownloadModal: false,
    }),
    clearReferrerStaff: state => ({
      ...state,
      referrerStaff: null,
      referrerStaffValid: true,
    }),
    clearExtraInfo: state => ({
      ...state,
      referrerStaff: null,
      userExist: false,
      referrerStaffValid: true,
      activeStaff: {},
    }),
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(staffInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchStaff.fulfilled, (state, { payload }) => ({
      ...state,
      staffList: (payload?.item ?? []).map(staff => {
        const brand = (staff.brand ?? []).find(b =>
          state.searchParams.brand_code.includes(b.brand_code)
        );
        return {
          ...staff,
          brand_name: brand ? brand.brand_name : staff.brand?.[0]?.brand_name,
        };
      }),
      totalCount: payload?.total_count || 0,
      activeCount: payload?.active_count || 0,
      pageSizeCount: payload?.search_count || 0,
    }));
    builder.addCase(fetchStaffDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeStaff: payload?.item ?? {},
      editModalVisible: true,
      authMenuInvisible: payload.authMenuInvisible,
    }));
    builder.addCase(
      fetchReferrerStaffDetail.fulfilled,
      (state, { payload }) => ({
        ...state,
        referrerStaff: payload?.item,
        referrerStaffValid: !!payload?.item,
      })
    );
    builder.addCase(fetchShops.fulfilled, (state, { payload }) => ({
      ...state,
      shops: (payload?.item ?? []).map(s => ({
        ...s,
        label: s.shop_code + ":" + s.shop_name,
        value: s.shop_code,
      })),
    }));
    builder.addCase(checkStaffExist.fulfilled, (state, { payload }) => ({
      ...state,
      userExist: !!payload.item,
    }));
    builder.addCase(updateStaff.fulfilled, state => ({
      ...state,
      referrerStaff: null,
      userExist: false,
      referrerStaffValid: true,
      editModalVisible: false,
      deleteModalVisible: false,
      signUpModalVisible: false,
      editDeleteModalVisible: false,
      activeStaff: {},
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(
      updateCsvFavoriteItem.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          favoriteItem: payload,
        };
      }
    );
    builder.addCase(csvDownloadStaff.fulfilled, (state, { payload }) => {
      if (typeof payload === "string") {
        downloadFile(
          payload,
          `社員マスタ_${moment().format("YYYYMMDDHHmmss")}.csv`
        );
      }
      return {
        ...state,
        infomationModal: payload?.code === "-1",
        infomationTitle: "出力データが存在しません",
        staffCsvDownloadModal: false,
      };
    });
  },
});

export const actions = staffSlice.actions;

export default staffSlice.reducer;

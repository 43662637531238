import { menuOptions } from "../../constants/options";
import GeneralCheckbox from "../cusCheckbox/genernalCheckbox";
import classNames from "classnames";
import "./style.scss";

const MenuCheckbox = ({ formik, disabled }) => {
  return (
    <div className="nf-menu-area">
      {menuOptions
      .map((m, i) => (
        <div key={i}>
          <GeneralCheckbox
            label={m.label}
            disabled={
              disabled ||
              (m.name === "top_flag" && formik.values["top_flag"] === 1)
            }
            checked={formik.values[m.name] === 1}
            onChange={() => {
              const checked = formik.values[m.name] === 1;
              formik.setFieldValue(m.name, checked ? 0 : 1);
              (m.subMenu ?? []).forEach(sub =>
                formik.setFieldValue(sub.name, checked ? 0 : 1)
              );
            }}
          />
          <div className="sub-menu">
            {(m.subMenu ?? [])
            .map(sub => (
              <GeneralCheckbox
                disabled={disabled}
                className={classNames({
                  "wrap-option": m.name === "analysis_flag",
                })}
                label={sub.label}
                checked={formik.values[sub.name] === 1}
                onChange={() => {
                  const subMenuChecked = formik.values[sub.name] === 1;
                  formik.setFieldValue(sub.name, subMenuChecked ? 0 : 1);
                  !subMenuChecked && formik.setFieldValue(m.name, 1);
                }}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuCheckbox;

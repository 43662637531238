import "./style.scss";
import Icon from "@ant-design/icons";
import IconImage from "../../../constants/Icons";
import dayjs from "dayjs";
import { toMoneyString } from "../../../utils/fnUtil";

/* 日別アーカイブ売上実績のカード */

const SalesArchiveDailyResultCard = ({ data, title }) => {
  const getJsxIcon = (after, before) => {
    let icon = "";
    if ((!after && after !== 0) || (!before && before !== 0)) {
      icon = IconImage.ImageIcon2;
    } else {
      before = Number(before);
      after = Number(after);
      if (before < after) icon = IconImage.ImageIcon1;
      if (before === after) icon = IconImage.ImageIcon2;
      if (before > after) icon = IconImage.ImageIcon3;
    }
    return (
      <Icon component={icon} style={{ fontSize: 16, cursor: "initial" }} className="display-icon" />
    );
  };
  const getGap = (after, before, preffix = '') => {
    if ((!after && after !== 0) || (!before && before !== 0)) return "";
    before = Number(before);
    after = Number(after);
    if (before === after) return '';
    if(after - before > 0) return `${preffix}${toMoneyString(after - before)}`;
    else return `- ${preffix}${toMoneyString(Math.abs(after - before))}`;
  };
  return (
    <div className="salesArchiveDailyResultCard-card">
      <table>
        <tr className="salesArchiveDailyResultCard-main-table">
          <td className="salesArchiveDailyResultCard-term">
            <span style={{ fontWeight: "bold", fontSize: 14 }}>
              DAILY
            </span>
            <span style={{ fontSize: 10, marginLeft: 5 }}>
              前々日対比
            </span>
            <div style={{ fontSize: 10, color: "#7B7B7B" }}>
              {data?.latest_date ? dayjs(data?.latest_date).format("YYYY.MM.DD") : "-"}
            </div>
          </td>
          <td className="salesArchiveDailyResultCard-result">
            <div className="salesArchiveDailyResultCard-result-title">
              {title}
            </div>
            <div className="salesArchiveDailyResultCard-result-money" style={{ color: "#03C04A" }}>
              ¥ {data?.sales_amount ? toMoneyString(data?.sales_amount) : "0"}
            </div>
          </td>
          <td className="salesArchiveDailyResultCard-image">
            <div>
                {getJsxIcon(data?.sales_amount, data?.daybefore_sales_amount)}
            </div>
          </td>
          <td className="salesArchiveDailyResultCard-diff">
            <div>
              <span>{getGap(data?.sales_amount, data?.daybefore_sales_amount, '¥')}</span>
            </div>
          </td>
        </tr>
      </table>
      <table className="salesArchiveDailyResultCard-analyze-table">
        <tr>
          <td className="salesArchiveDailyResultCard-colum">
            <span>再生回数　　&nbsp;</span>
          </td>
          <td className="salesArchiveDailyResultCard-value">
            <span>{data?.play_count ? toMoneyString(data?.play_count) : "0"}</span>
          </td>
          <td className="salesArchiveDailyResultCard-image">
            {getJsxIcon(data?.play_count, data?.daybefore_play_count)}
          </td>
          <td className="salesArchiveDailyResultCard-diff">
            <span>
              {getGap(data?.play_count, data?.daybefore_play_count)}
            </span>
          </td>
        </tr>
        <tr>
          <td className="salesArchiveDailyResultCard-colum">
            <span>いいね　　　&nbsp;</span>
          </td>
          <td className="salesArchiveDailyResultCard-value">
            <span>
              {data?.like_count ? toMoneyString(data?.like_count) : "0"}
            </span>
          </td>
          <td className="salesArchiveDailyResultCard-image">
            {getJsxIcon(data?.like_count, data?.daybefore_like_count)}
          </td>
          <td className="salesArchiveDailyResultCard-diff">
            <span>
              {getGap(data?.like_count, data?.daybefore_like_count)}
            </span>
          </td>
        </tr>
        <tr>
          <td className="salesArchiveDailyResultCard-colum">
            <span>投稿数　　　&nbsp;</span>
          </td>
          <td className="salesArchiveDailyResultCard-value">
            <span>
              {data?.post_count ? toMoneyString(data?.post_count) : "0"}
            </span>
          </td>
          <td className="salesArchiveDailyResultCard-image">
            {getJsxIcon(data?.post_count, data?.daybefore_post_count)}
          </td>
          <td className="salesArchiveDailyResultCard-diff">
            <span>
              {getGap(data?.post_count, data?.daybefore_post_count)}
            </span>
          </td>
        </tr>
        <tr>
          <td className="salesArchiveDailyResultCard-colum">
            <span>1投稿最高売上</span>
          </td>
          <td className="salesArchiveDailyResultCard-value">
            <span>
              ¥ {data?.max_sales ? toMoneyString(data?.max_sales) : "0"}
            </span>
          </td>
          <td className="salesArchiveDailyResultCard-image">
            {getJsxIcon(data?.max_sales, data?.daybefore_max_sales)}
          </td>
          <td className="salesArchiveDailyResultCard-diff">
            <span>
              {getGap(data?.max_sales, data?.daybefore_max_sales, '¥')}
            </span>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default SalesArchiveDailyResultCard;

import React, { useState } from "react";
import { Image } from "antd";
import { focusToEnd } from "../utils/other";
import { spliceArray } from "../../../utils/fnUtil";
import { useDispatch } from "react-redux";
import { uploadImage } from "../../../slices/articlesSlice";
import { useFocused, useSelected, useSlate, useReadOnly } from "slate-react";
import { Editor, Transforms, Element as SlateElement } from "slate";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import noimage from "../../../images/noimage_square2.png";
import BaseUpload from "../../baseUpload";
import ProductInfoCard from "../../productInfoCard";
import ProductSearchModal from "../../modal/productSearchModal";
import CoordinateSearchModal from "../../modal/coordinateSearchModal";
import classNames from "classnames";
import "../style.scss";

const ImageBlock = ({ element, children, attributes }) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();
  const readOnly = useReadOnly();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);
  const [coordinateSearchModalVisible, setCoordinateSearchModalVisible] =
    useState(false);

  const { images, maxCol, boxtype, deviceMode, articleCode, userCode } = element;

  const isPlus = i => boxtype === "9" && i === 0;

  const changeImage = (imageObj, index) => {
    Transforms.setNodes(
      editor,
      {
        images: spliceArray(images, index, imageObj),
      },
      {
        match: n =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "image",
      }
    );
  };

  return (
    <div {...attributes} className={classNames("image-block-container")}>
      <div
        className={classNames("image-block-inner", {
          "image-block-active": selected && focused,
          "image-block-disable": readOnly,
        })}
        contentEditable={false}
        style={{
          maxWidth: maxCol * (deviceMode ? 0.57 * 250 : 250),
          width: maxCol * (deviceMode ? 0.57 * 205 : 205),
        }}
        onMouseDown={e => {
          e.preventDefault();
          focusToEnd(editor);
        }}
      >
        {images.map((i, index) => (
          <div
            key={index}
            className={classNames("block-action-container", {
              "block-action-container-plus": isPlus(index),
              "block-action-container-device": deviceMode,
            })}
          >
            {i && !readOnly && (
              <Icon
                component={Icons.CloseIcon}
                className="close-icon"
                onClick={() => setTimeout(() => changeImage("", index))}
              />
            )}
            {i ? (
              i.product_code ? (
                <ProductInfoCard
                  productInfo={i}
                  size={isPlus(index) ? "plus" : "normal"}
                  deviceMode={deviceMode}
                  displayNoImage={i.disabled}
                />
              ) : (
                <Image
                  preview={false}
                  className="block-img"
                  src={
                    i.coordinate_code && i.disabled
                      ? noimage
                      : i.image_url || noimage
                  }
                />
              )
            ) : (
              <>
                <BaseUpload
                  disabled={readOnly}
                  onChange={image_url => {
                    dispatch(
                      uploadImage({
                        image: image_url,
                        content_flag: 1,
                        content_code: articleCode,
                        upload_operator_code: userCode
                      })
                    ).then(
                      ({ payload }) =>
                        payload?.code === "0" &&
                        changeImage({ image_url: payload.image_url }, index)
                    );
                  }}
                >
                  <Button
                    text="画像を追加"
                    theme="white"
                    fontSize="small"
                    disabled={readOnly}
                  />
                </BaseUpload>
                <Button
                  text="商品を追加"
                  theme="white"
                  disabled={readOnly}
                  fontSize="small"
                  onClick={() => {
                    setProductSearchModalVisible(true);
                    setActiveIndex(index);
                  }}
                />
                <Button
                  text="コーディネートを追加"
                  fontSize="small"
                  theme="white"
                  disabled={readOnly}
                  onClick={() => {
                    setCoordinateSearchModalVisible(true);
                    setActiveIndex(index);
                  }}
                />
              </>
            )}
          </div>
        ))}
      </div>
      <CoordinateSearchModal
        visible={coordinateSearchModalVisible}
        onOk={seletedCoordinate => {
          focusToEnd(editor);
          changeImage(seletedCoordinate, activeIndex);
          setCoordinateSearchModalVisible(false);
        }}
        onCancel={() => {
          setCoordinateSearchModalVisible(false);
          focusToEnd(editor);
        }}
      />
      <ProductSearchModal
        visible={productSearchModalVisible}
        onCancel={() => {
          setProductSearchModalVisible(false);
          focusToEnd(editor);
        }}
        onOk={selectedProduct => {
          focusToEnd(editor);
          changeImage(selectedProduct, activeIndex);
          setProductSearchModalVisible(false);
        }}
      />
      {children}
    </div>
  );
};

export default ImageBlock;

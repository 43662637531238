import { useState } from "react";
import { Popover, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { SketchPicker } from "react-color";
import { useSlate } from "slate-react";
import { focusToEnd } from "./utils/other";
import Icons from "../../constants/Icons";
import Button from "../button";
import GeneralInput from "../generalInput";
import LayoutModal from "../modal/layoutModal";
import Icon from "@ant-design/icons";
import classNames from "classnames";
import {
  Editor,
  Transforms,
  Element as SlateElement,
  Range,
  Path,
} from "slate";
import "./style.scss";

const fontSizeMap = [
  { label: "特大", value: "32" },
  { label: "大", value: "24" },
  { label: "中", value: "16" },
  { label: "小", value: "10" },
];

const EditorToolbar = ({ deviceMode, availableCount }) => {
  const editor = useSlate();
  const [fontSizePropverVisible, setFontSizePropverVisible] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [layoutModalVisible, setLayoutModalVisible] = useState(false);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [linkURL, setLinkURL] = useState("");
  const [linkText, setLinkText] = useState("");

  const toggleMark = (editor, format, value = true) =>
    isMarkActive(editor, format)
      ? Editor.removeMark(editor, format)
      : Editor.addMark(editor, format, value);

  const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? !!marks[format] : false;
  };

  const LIST_TYPES = ["numbered-list", "bulleted-list"];

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: n =>
        LIST_TYPES.includes(
          !Editor.isEditor(n) && SlateElement.isElement(n) && n.type
        ),
      split: true,
    });
    const newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const isBlockActive = (editor, format) => {
    const [match] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
  };

  const insertLink = (editor, url, text) => {
    if (!url) return;

    wrapLink(editor, url, text);
  };

  const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  };

  const replaceLink = editor => {
    Transforms.removeNodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  };

  const wrapLink = (editor, url, text) => {
    if (isLinkActive(editor)) {
      replaceLink(editor);
    }

    const { selection } = editor;
    const isCollapsed = selection && Range.isCollapsed(selection);
    const link = {
      type: "link",
      url,
      children: [{ text }],
    };

    if (!selection || isCollapsed) {
      Transforms.insertNodes(editor, link);
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
      Transforms.collapse(editor, { edge: "end" });
    }
  };

  const insertImage = ({ count, maxCol, boxtype }) => {
    const image = {
      type: "image",
      maxCol,
      count,
      boxtype,
      deviceMode,
      images: Array.from({ length: count }, () => ""),
      children: [{ text: "" }],
    };

    Transforms.insertNodes(editor, image, {
      mode: "highest",
    });
  };

  const moveToEndAfterInsertImage = () => {
    const [image] = Editor.nodes(editor, {
      match: n =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "image",
    });
    if (image && image[1]) {
      if (Editor.after(editor, image[1])) {
        Transforms.move(editor);
      } else {
        Transforms.insertNodes(
          editor,
          {
            type: "content",
            children: [{ text: "" }],
          },
          {
            at: Path.next(image[1]),
            select: true,
          }
        );
      }
    }
  };

  return (
    <>
      <div
        className={"rich-text-editor-header"}
        onMouseDown={e => {
          e.preventDefault();
          focusToEnd(editor);
        }}
      >
        <div id="font-size-select-area">
          <Popover
            getPopupContainer={triggerNode => triggerNode.parentNode}
            trigger="click"
            placement="bottom"
            onVisibleChange={setFontSizePropverVisible}
            visible={fontSizePropverVisible}
            onMouseDown={e => {
              e.preventDefault();
            }}
            content={() =>
              fontSizeMap.map((f, index) => (
                <div
                  className={"font-size-option"}
                  value={f.value}
                  key={index}
                  style={{ fontSize: `${f.value}px` }}
                  onMouseDown={e => {
                    e.preventDefault();
                    focusToEnd(editor);
                    Editor.removeMark(editor, "fontSize");
                    toggleMark(editor, "fontSize", f.value);
                    setFontSizePropverVisible(false);
                  }}
                >
                  {f.label}
                </div>
              ))
            }
          >
            <div className="font-size-placeholder">
              {fontSizeMap.find(m => {
                const marks = Editor.marks(editor);
                if (marks) {
                  return m.value === marks["fontSize"];
                } else {
                  return false;
                }
              })?.label || "中"}
              <DownOutlined />
            </div>
          </Popover>
        </div>

        <div className="rich-text-icons">
          <Icon
            component={Icons.BoldIcon}
            onMouseDown={e => {
              e.preventDefault();
              focusToEnd(editor);
              toggleMark(editor, "bold");
            }}
            className={classNames({
              "rich-text-icon-active": isMarkActive(editor, "bold"),
            })}
          />
          <Icon
            component={Icons.ImageIcon12}
            onMouseDown={e => {
              e.preventDefault();
              focusToEnd(editor);
              toggleMark(editor, "italic");
            }}
            className={classNames({
              "rich-text-icon-active": isMarkActive(editor, "italic"),
            })}
          />
          <div>
            <Icon
              component={Icons.ImageIcon13}
              style={{ fontSize: 22, position: "relative", top: 1 }}
              onMouseDown={e => {
                e.preventDefault();
                setShowColorPicker(true);
              }}
            />
            {showColorPicker && (
              <div
                style={{ position: "absolute", zIndex: "2" }}
                onMouseDown={e => e.preventDefault()}
              >
                <div
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                  }}
                  onClick={() => {
                    setShowColorPicker(false);
                  }}
                />
                <SketchPicker
                  color={Editor.marks(editor)?.color}
                  onChangeComplete={color => {
                    const newTextColor = color.hex;

                    Editor.removeMark(editor, "color");

                    toggleMark(editor, "color", newTextColor);

                    setShowColorPicker(false);
                  }}
                />
              </div>
            )}
          </div>
          <Icon
            component={Icons.ImageIcon11}
            onMouseDown={e => {
              e.preventDefault();
              focusToEnd(editor);
              toggleMark(editor, "underline");
            }}
            className={classNames({
              "rich-text-icon-active": isMarkActive(editor, "underline"),
            })}
          />
          <Icon
            component={Icons.ImageIcon14}
            onMouseDown={e => {
              e.preventDefault();
              focusToEnd(editor);
              toggleMark(editor, "strikethrough");
            }}
            className={classNames({
              "rich-text-icon-active": isMarkActive(editor, "strikethrough"),
            })}
          />
          <Icon
            component={Icons.ImageIcon15}
            onMouseDown={e => {
              e.preventDefault();
              setLinkModalVisible(true);

              if (editor.selection && !Range.isCollapsed(editor.selection)) {
                const selectedText = Editor.string(editor, editor.selection);
                setLinkText(selectedText);
                const [link] = Editor.nodes(editor, {
                  at: editor.selection,
                  match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n.type === "link",
                });
                if (link && link.length > 0) {
                  setLinkURL(link[0].url);
                }
              }
            }}
          />
          <Icon
            component={Icons.ImageIcon7}
            onMouseDown={e => {
              e.preventDefault();
              toggleBlock(editor, "align-left");
            }}
            className={classNames({
              "rich-text-icon-active": isBlockActive(editor, "align-left"),
            })}
          />
          <Icon
            component={Icons.ImageIcon8}
            onMouseDown={e => {
              e.preventDefault();
              toggleBlock(editor, "align-center");
            }}
            className={classNames({
              "rich-text-icon-active": isBlockActive(editor, "align-center"),
            })}
          />
          <Icon
            component={Icons.ImageIcon9}
            onMouseDown={e => {
              e.preventDefault();
              toggleBlock(editor, "align-right");
            }}
            className={classNames({
              "rich-text-icon-active": isBlockActive(editor, "align-right"),
            })}
          />
          <Icon
            component={Icons.ImageIcon10}
            onMouseDown={e => {
              e.preventDefault();
              toggleBlock(editor, "bulleted-list");
            }}
            className={classNames({
              "rich-text-icon-active": isBlockActive(editor, "bulleted-list"),
            })}
          />
          <div
            className="revert-icon"
            onMouseDown={e => {
              e.preventDefault();
              Editor.removeMark(editor, "fontSize");
              Editor.removeMark(editor, "bold");
              Editor.removeMark(editor, "italic");
              Editor.removeMark(editor, "color");
              Editor.removeMark(editor, "underline");
              Editor.removeMark(editor, "strikethrough");
            }}
          >
            <Icon component={Icons.ImageIcon16_2} style={{ fontSize: 18 }} />
            <span className="icon-text">もどす</span>
          </div>
        </div>
        <Button
          text="レイアウト"
          theme="white"
          disabled={availableCount <= 0}
          onClick={() => setLayoutModalVisible(true)}
        />
      </div>

      <LayoutModal
        visible={layoutModalVisible}
        availableCount={availableCount}
        onCancel={() => setLayoutModalVisible(false)}
        onOk={layout => {
          setLayoutModalVisible(false);
          focusToEnd(editor);
          insertImage(layout);
          moveToEndAfterInsertImage();
        }}
      />
      <Modal
        visible={linkModalVisible}
        footer={null}
        closable={false}
        onCancel={() => {
          setLinkModalVisible(false);
          focusToEnd(editor);
        }}
      >
        <div className="link-modal-wrapper">
          <div className="link-modal-header">
            <span className="text-title text-bold">リンク追加 </span>
            <span className="nf-text-required-style">*は必須項目です</span>
          </div>
          <GeneralInput
            label="URL"
            requiredItem={true}
            labelTextAlign="left"
            labelWidth="wider"
            styleType="block-grey"
            placeholder="https://"
            value={linkURL}
            onChange={e => {
              setLinkURL(e.target.value);
            }}
          />
          <GeneralInput
            label="テキストで表示"
            labelWidth="wider"
            styleType="block-grey"
            labelTextAlign="left"
            onChange={e => {
              setLinkText(e.target.value);
            }}
            value={linkText}
            placeholder="テキストを入力してください"
          />
          <div className="text-remind">
            ※リンクのテキストに文字装飾はできません
          </div>
          <div className="action-container">
            <Button
              text="戻る"
              theme="white"
              onClick={() => {
                setLinkModalVisible(false);
                focusToEnd(editor);
              }}
            />
            <Button
              text="挿入"
              disabled={!linkURL}
              onClick={() => {
                setLinkModalVisible(false);
                focusToEnd(editor);
                insertLink(editor, linkURL, linkText || linkURL);
                setLinkText("");
                setLinkURL("");
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditorToolbar;

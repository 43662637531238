import { useEffect, useState } from "react";
import { Formik, useFormik, FieldArray } from "formik";
import {
  createTimeOptions,
  handlerFormikFieldChange,
  spliceArray,
  swapArray,
} from "../../../utils/fnUtil";
import { useDispatch, useSelector } from "react-redux";
import { Modal, DatePicker, Image } from "antd";
import {
  actions,
  fetchTagCategory,
  fetchTags,
  registLive,
} from "../../../slices/liveSlice";
import { actions as productActions } from "../../../slices/productSlice";
import { actions as messageActions } from "../../../slices/messageSlice";
import Button from "../../button";
import CusCheckbox from "../../cusCheckbox";
import GeneralInput from "../../generalInput";
import MemoInput from "../../generalInput/memoInput";
import MemoTextArea from "../../generalInput/memoTextArea";
import BaseUpload from "../../baseUpload";
import SelectModal from "../selectModal";
import classNames from "classnames";
import ProductSearchModal from "../productSearchModal";
import DraggableContainer from "./DraggableContainer";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Yup from "../../../utils/yupUtil";
import moment from "moment";
import "./style.scss";

const LiveSignUpModal = () => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);

  const [activeIndex, setActiveIndex] = useState(0);

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const {
    signUpModalVisible,
    brands,
    shops,
    tags,
    categories,
    staffs,
  } = useSelector(state => state.live);

  const formik = useFormik({
    initialValues: {
      streamer_info: [
        {
          brand_code: (account.brand_code ?? [])?.[0],
          shop_code: account.shop_code,
          user_code: account.user_code,
          distribution_rate: '0',
          display_order: '1',
        },
      ],
      distribution_method_flag: 0,
      archive_comment_flag: 0,
      telop_flag: 0,
      list_img_url: null,
      detail_img_url: null,
      products: Array(30).fill(null),
      tag_code: [],
      title: "",
      description: "",
      tag_category_code: null,
      start_date: moment(),
      start_hour: moment().format("HH"),
      start_minute: moment().format("mm"),
      end_date: moment(),
      end_hour: moment().format("HH"),
      end_minute: moment().format("mm"),
      opening_date: moment(),
      opening_hour: moment().format("HH"),
      opening_minute: moment().format("mm"),
      uneditable: true,
    },
    validationSchema: Yup.object({
      streamer_info: Yup.array()
        .of(
          Yup.object().shape({
            brand_code: Yup.string().nullable().selected(),
            shop_code: Yup.string().nullable().selected(),
            user_code: Yup.string()
              .nullable()
              .selected(),
            distribution_rate: Yup.string().nullable().selected(),
            display_order: Yup.string().nullable().selected(),
          })
        ),
      title: Yup.string().required().max(30),
      description: Yup.string().required(),
      list_img_url: Yup.string().nullable().required(),
      detail_img_url: Yup.string().nullable().required(),
      start_date: Yup.string()
        .nullable()
        .test(
          "start_date",
          "必須項目です",
          (value, testContext) =>
            value && testContext.parent.start_hour && testContext.parent.start_minute
        ),
      end_date: Yup.string()
        .nullable()
        .test(
          "end_date",
          "必須項目です",
          (value, testContext) =>
            value && testContext.parent.end_hour && testContext.parent.end_minute
        ),
      opening_date: Yup.string()
        .nullable()
        .test(
          "opening_date",
          "必須項目です",
          (value, testContext) =>
            value && testContext.parent.opening_hour && testContext.parent.opening_minute
        ),
    }),
    onSubmit: values => {
      // バリデーションチェック
      const {
        start_date,
        start_hour,
        start_minute,
        end_date,
        end_hour,
        end_minute,
        opening_date,
        opening_hour,
        opening_minute,
        tag_code,
        user_code,
        products,
        streamer_info,
        ...rest
      } = values;

      const start_datetime = moment(start_date).set('h', Number(start_hour)).set('m', Number(start_minute)).set('s', 0);
      const end_datetime = moment(end_date).set('h', Number(end_hour)).set('m', Number(end_minute)).set('s', 0);
      const opening_datetime = moment(opening_date).set('h', Number(opening_hour)).set('m', Number(opening_minute)).set('s', 0);

      // 配信開始/終了日時範囲チェック
      if (start_datetime.diff(end_datetime, 'm') < -120) {
        dispatch(
          messageActions.setMessage({
            btnText: "閉じる",
            messages: ["配信時間は120分以内で入力してください"],
          })
        );
        return;
      }

      // 公開日時/配信開始日時間隔チェック
      if (-30 < opening_datetime.diff(start_datetime, 'm')) {
        dispatch(
          messageActions.setMessage({
            btnText: "閉じる",
            messages: ["配信開始日時は公開日時から30分以上間隔を空けて入力してください"],
          })
        );
        return;
      }

      const param_streamer_info = [];
      for (let i = 0; i < streamer_info.length; i++) {
        const data = streamer_info[i];
        const brand = brands.filter(e => e.brand_code === data.brand_code)[0];
        const shop = shops.filter(e => e.shop_code === data.shop_code)[0];
        const staff = staffs.filter(e => e.user_code === data.user_code)[0];
        param_streamer_info.push({
          brand_code: data.brand_code,
          brand_name: brand.brand_name,
          shop_code: data.shop_code,
          shop_name: shop.shop_name,
          shop_name_kana: shop.shop_namekana,
          user_code: data.user_code,
          user_name: staff.user_name,
          user_name_kana: staff.user_name_kana,
          name_nickname: staff.user_nickname,
          height: staff.height,
          distribution_rate: data.distribution_rate,
          display_order: data.display_order,
          image_url: staff?.img,
        });
      }

      dispatch(
        registLive({
          ...rest,
          division: "insert",
          streamer_info: param_streamer_info,
          live_flag: 0,
          status_flag: "00",
          tags: tag_code.map(t => t.tag_code),
          products: products.filter(p => p),
          start_datetime: `${start_date.format("YYYY-MM-DD")} ${start_hour}:${start_minute}:00`,
          end_datetime: `${end_date.format("YYYY-MM-DD")} ${end_hour}:${end_minute}:00`,
          opening_time: `${opening_date.format("YYYY-MM-DD")} ${opening_hour}:${opening_minute}:00`,
        })
      );
    },
  });

  const onBack = () => {
    dispatch(actions.closeSignUpModal());
    formik.resetForm();
  };

  const {
    values: {
      tag_category_code,
      tag_code,
      start_date,
      start_hour,
      start_minute,
      end_date,
      end_hour,
      end_minute,
      opening_date,
      opening_hour,
      opening_minute,
      list_img_url,
      detail_img_url,
      products,
      description,
      uneditable,
      streamer_info,
      distribution_method_flag,
      archive_comment_flag,
      telop_flag,
    },
    setFieldValue,
  } = formik;

  const handleFileChange = (url, name) => handlerFormikFieldChange(formik, name, url);

  useEffect(() => {
    tag_category_code &&
      streamer_info?.[0]?.brand_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code: streamer_info?.[0]?.brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, streamer_info, dispatch]);

  useEffect(() => {
    if (signUpModalVisible) {
      const start = moment(moment().format("YYYY-MM-DD HH:mm:00")).add(30, 'm');
      const end = moment(moment().format("YYYY-MM-DD HH:mm:00")).add(60, 'm');
      const opening = moment(moment().format("YYYY-MM-DD HH:mm:00"));
      setFieldValue("start_date", start);
      setFieldValue("start_hour", start.format("HH"));
      setFieldValue("start_minute", start.format("mm"));
      setFieldValue("end_date", end);
      setFieldValue("end_hour", end.format("HH"));
      setFieldValue("end_minute", end.format("mm"));
      setFieldValue("opening_date", opening);
      setFieldValue("opening_hour", opening.format("HH"));
      setFieldValue("opening_minute", opening.format("mm"));
    }
  }, [signUpModalVisible, setFieldValue]);

  useEffect(() => {
    categories.length === 1 &&
      setFieldValue("tag_category_code", categories[0]?.tag_category_code);
  }, [categories, setFieldValue]);

  const numberSelect = (start, end) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push({ label: `${i}`, value: `${i}` });
    }
    return list;
  };

  const brandOptions = brands.filter(b => b.status === "1");

  const shopOptions = i => shops.filter(s => (s.brand ?? []).some(b => b.brand_code === streamer_info?.[i]?.brand_code) && s.status === "1");

  const staffOptions = i => staffs.filter(s => s.status === 1).filter(s => s?.shop_code === streamer_info?.[i]?.shop_code);

  const onSaveBaseInfo = () => {
    // 重複チェック
    for (const streamer of streamer_info) {
      // 配信者
      if (streamer_info.filter(e => e.shop_code === streamer.shop_code && e.user_code === streamer.user_code).length >= 2) {
        dispatch(messageActions.setMessage({ btnText: "閉じる", messages: ["配信者が重複しています。"] }));
        return;
      }
      // 表示優先順
      if (streamer_info.filter(e => e.display_order === streamer.display_order).length >= 2) {
        dispatch(messageActions.setMessage({ btnText: "閉じる", messages: ["表示優先順が重複しています。"] }));
        return;
      }
    }

    // 分配率の合計が0 or 100以外の場合、エラー
    const distributionRateSum = streamer_info.reduce((s, e) => s + Number(e.distribution_rate), 0);
    if (distributionRateSum !== 0 && distributionRateSum !== 100) {
      dispatch(
        messageActions.setMessage({
          btnText: "閉じる",
          messages: ["売上分配率は合計で0%または100%になるように設定してください"],
        })
      );
      return;
    }

    dispatch(
      fetchTagCategory({
        brand_code: streamer_info?.[0]?.brand_code,
        count: 0,
        sort: "tag_category_code_asc",
      })
    );

    dispatch(productActions.saveBrand(streamer_info?.[0]?.brand_code));
    setFieldValue("uneditable", false);
    document.querySelector(".register-modal .ant-modal-body").scrollTo(0, 255 + (130 * streamer_info.length));
  };

  // 公開日時（日付）入力時の補正処理
  const validOpeningDate = input => {
    if (!input || (!start_date && !end_date)) return input;
    validOpeningDateTime(input, opening_hour, opening_minute);
    return input;
  };

  // 公開日時（時）入力時の補正処理
  const validOpeningHour = input => {
    if (!input || !opening_date || (!start_date && !end_date)) return input;
    validOpeningDateTime(opening_date, input, opening_minute);
    return input;
  };

  // 公開日時（分）入力時の補正処理
  const validOpeningMinute = input => {
    if (!input || !opening_date || (!start_date && !end_date)) return input;
    validOpeningDateTime(opening_date, opening_hour, input);
    return input;
  };

  const validOpeningDateTime = (opening_date, opening_hour, opening_minute) => {
    const start_datetime = createMomentDate(start_date, start_hour, start_minute);
    const end_datetime = createMomentDate(end_date, end_hour, end_minute);
    const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
    if (moment(start_datetime).isSameOrBefore(moment(opening_datetime))) {
      opening_datetime.add(30, 'm');
      setFieldValue("start_date", opening_datetime);
      setFieldValue("start_hour", opening_datetime.format("HH"));
      setFieldValue("start_minute", opening_datetime.format("mm"));
      if (moment(end_datetime).isSameOrBefore(moment(opening_datetime))) {
        opening_datetime.add(30, 'm');
        setFieldValue("end_date", opening_datetime);
        setFieldValue("end_hour", opening_datetime.format("HH"));
        setFieldValue("end_minute", opening_datetime.format("mm"));
      }
    } else if (moment(end_datetime).isSameOrBefore(moment(opening_datetime))) {
      opening_datetime.add(60, 'm');
      setFieldValue("end_date", opening_datetime);
      setFieldValue("end_hour", opening_datetime.format("HH"));
      setFieldValue("end_minute", opening_datetime.format("mm"));
    }
  };

  // 開始日時（日付）入力時の補正処理
  const validStartDate = input => {
    if (!input || (!opening_date && !end_date)) return input;
    if (validStartDateTime(input, start_hour, start_minute)) {
      const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
      opening_datetime.add(30, 'm');
      setFieldValue("start_hour", opening_datetime.format("HH"));
      setFieldValue("start_minute", opening_datetime.format("mm"));
      return opening_datetime;
    }
    return input;
  };

  // 開始日時（時）入力時の補正処理
  const validStartHour = input => {
    if (!input || !start_date || (!opening_date && !end_date)) return input;
    if (validStartDateTime(start_date, input, start_minute)) {
      const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
      opening_datetime.add(30, 'm');
      setFieldValue("start_date", opening_datetime);
      setFieldValue("start_minute", opening_datetime.format("mm"));
      return opening_datetime.format("HH");
    }
    return input;
  };

  // 開始日時（分）入力時の補正処理
  const validStartMinute = input => {
    if (!input || !start_date || (!opening_date && !end_date)) return input;
    if (validStartDateTime(start_date, start_hour, input)) {
      const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
      opening_datetime.add(30, 'm');
      setFieldValue("start_date", opening_datetime);
      setFieldValue("start_hour", opening_datetime.format("HH"));
      return opening_datetime.format("mm");
    }
    return input;
  };

  const validStartDateTime = (start_date, start_hour, start_minute) => {
    const start_datetime = createMomentDate(start_date, start_hour, start_minute);
    const end_datetime = createMomentDate(end_date, end_hour, end_minute);
    const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
    if (moment(end_datetime).isSameOrBefore(start_datetime)) {
      start_datetime.add(30, 'm');
      setFieldValue("end_date", start_datetime);
      setFieldValue("end_hour", start_datetime.format("HH"));
      setFieldValue("end_minute", start_datetime.format("mm"));
    }
    return moment(start_datetime).isSameOrBefore(moment(opening_datetime));
  };

  // 終了日時（日付）入力時の補正処理
  const validEndDate = input => {
    if (!input || !start_date) return input;
    if (validEndDateTime(input, end_hour, end_minute)) {
      return input;
    } else {
      const start_datetime = createMomentDate(start_date, start_hour, start_minute);
      start_datetime.add(30, 'm');
      setFieldValue("end_hour", start_datetime.format("HH"));
      setFieldValue("end_minute", start_datetime.format("mm"));
      return start_datetime;
    }
  };

  // 終了日時（時）入力時の補正処理
  const validEndHour = input => {
    if (!input || !start_date || !end_date) return input;
    if (validEndDateTime(end_date, input, end_minute)) {
      return input;
    } else {
      const start_datetime = createMomentDate(start_date, start_hour, start_minute);
      start_datetime.add(30, 'm');
      setFieldValue("end_date", start_datetime);
      setFieldValue("end_minute", start_datetime.format("mm"));
      return start_datetime.format("HH");
    }
  };

  // 終了日時（分）入力時の補正処理
  const validEndMinute = input => {
    if (!input || !start_date || !end_date) return input;
    if (validEndDateTime(end_date, end_hour, input)) {
      return input;
    } else {
      const start_datetime = createMomentDate(start_date, start_hour, start_minute);
      start_datetime.add(30, 'm');
      setFieldValue("end_date", start_datetime);
      setFieldValue("end_hour", start_datetime.format("HH"));
      return start_datetime.format("mm");
    }
  };

  const validEndDateTime = (end_date, end_hour, end_minute) => {
    const start_datetime = createMomentDate(start_date, start_hour, start_minute);
    const end_datetime = createMomentDate(end_date, end_hour, end_minute);
    return moment(start_datetime).isSameOrBefore(moment(end_datetime));
  };

  const createMomentDate = (date, hour, minute) => moment(date).set('h', Number(hour || 0)).set('m', Number(minute || 0)).set('s', 0);

  const onTagOk = value => {
    handlerFormikFieldChange(
      formik,
      "tag_code",
      [
        ...tag_code,
        ...tags.filter(
          t =>
            value.includes(t.value) &&
            !tag_code.some(tag => t.value === tag.tag_code)
        ),
      ].filter(
        tag =>
          !tags.map(t => t.value).includes(tag.tag_code) ||
          value.includes(tag.tag_code)
      )
    );
    setSelectTagModalVisible(false);
  };

  const afterClose = () => {
    formik.resetForm();
  };

  const addStreamerInfo = () => {
    // 新規追加する表示優先順は最大値 + 1
    const display_order = Math.max(...(streamer_info.filter(e => e).map(e => Number(e.display_order)))) + 1;

    const newData = JSON.parse(JSON.stringify(streamer_info));
    newData.push({
      brand_code: null,
      shop_code: null,
      user_code: null,
      distribution_rate: '0',
      display_order: display_order > 100 ? null : `${display_order}`,
    });
    handlerFormikFieldChange(formik, 'streamer_info', newData);
  };

  const removeStreamerInfo = (index) => {
    const newData = JSON.parse(JSON.stringify(streamer_info));
    newData.splice(index, 1);
    handlerFormikFieldChange(formik, 'streamer_info', newData);
  };

  const isStreamerInfoError = () => {
    if (!formik?.errors?.streamer_info) return false;
    for (let i = 0; i < formik?.errors?.streamer_info?.length; i++) {
      if (!formik?.errors?.streamer_info?.[i]) continue;
      const { brand_code, shop_code, user_code, distribution_rate, display_order } = formik?.errors?.streamer_info?.[i];
      if (brand_code || shop_code || user_code || distribution_rate || display_order) {
        return true;
      }
    }
    return false;
  };

  const delivererArea = (data, index) => {
    const {
      brand_code,
      shop_code,
    } = data;
    return (
      <>
        <div className="deliverer-title-area">
          <div className="text-bold normal-label">
            配信者{index + 1}{index === 0 ? "（主催者）" : ""}
          </div>
          {index !== 0 &&
            <Icon
              component={Icons.CloseIcon}
              className="deliverer-close-icon"
              onClick={e => {
                removeStreamerInfo(index);
              }}
            />
          }
        </div>
        <div className="select-container">
          <GeneralInput
            disabled={!uneditable}
            label="ブランド"
            options={
              brandOptions.length > 0
                ? brandOptions
                : [
                    {
                      label: account.brand_name?.[0],
                      value: account.brand_code?.[0],
                    },
                  ]
            }
            placeholder="ブランドを選択して下さい"
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'brand_code'}
            formik={formik}
            extraOnChange={(key, value) => {
              if (index == 0) {
                handlerFormikFieldChange(formik, `streamer_info[${index}].shop_code`, null);
                handlerFormikFieldChange(formik, `streamer_info[${index}].user_code`, null);
              } else {
                setFieldValue(`streamer_info[${index}].shop_code`, null);
                setFieldValue(`streamer_info[${index}].user_code`, null);
              }
            }}
          />
          <GeneralInput
            disabled={!brand_code || !uneditable}
            options={
              shopOptions(index)?.length > 0
                ? shopOptions(index)
                : [{ label: account.shop_name, value: account.shop_code }]
            }
            label="店舗"
            placeholder="店舗を選択して下さい"
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'shop_code'}
            formik={formik}
            extraOnChange={() => {
              if (index == 0) {
                handlerFormikFieldChange(formik, `streamer_info[${index}].user_code`, null);
              } else {
                setFieldValue(`streamer_info[${index}].user_code`, null);
              }
            }}
          />
          <GeneralInput
            label="社員"
            options={
              staffOptions(index)?.length > 0
                ? staffOptions(index)
                : [{ label: account.user_name, value: account.user_code }]
            }
            disabled={!brand_code || !shop_code || !uneditable}
            placeholder="社員を選択して下さい"
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'user_code'}
            formik={formik}
          />
        </div>
        <div className="select-container-bottom">
          <GeneralInput
            disabled={!uneditable}
            label="売上分配率"
            options={numberSelect(0, 100)}
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'distribution_rate'}
            extraOnChange={(_, v) => {
            }}
            formik={formik}
            textAfter="%"
          />
          <GeneralInput
            disabled={!uneditable}
            label="表示優先順"
            options={numberSelect(1, 100)}
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'display_order'}
            extraOnChange={(_, v) => {
            }}
            formik={formik}
          />
        </div>
      </>
    );
  };

  return (
    <Modal
      visible={signUpModalVisible}
      closable={false}
      width={1000}
      style={{ minWidth: 1000 }}
      footer={null}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
      className={"register-modal"}
    >
      <h2 className="signup-title">
        <span className="text-bold">ライブ配信ルーム新規登録</span>
        <span className="nf-text-required-style">　*は必須項目です</span>
      </h2>
      <div className="live-signup">
        <form onSubmit={formik.handleSubmit}>
          <div
            className={classNames("live-edit-area", {
              "disabled-area": !uneditable,
            })}
          >
            <div className="text-bold text-required">配信者情報</div>
            <Formik
              render={formikProps => (
                <FieldArray
                  name="streamer_info"
                  component={() => (<>
                    {streamer_info.map(delivererArea)}
                    </>)}
                />
              )}
            />
            <div style={{ textAlign: 'right' }}>
              <Button
                text={<div>
                  <Icon
                    component={Icons.IconPlus}
                    style={{ position: "absolute" }}
                  ></Icon>
                  <span>　 配信者を追加</span></div>}
                className="addButton"
                theme="black"
                onClick={() => {
                  addStreamerInfo();
                }}
              />
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="保存"
                disabled={isStreamerInfoError()}
                onClick={onSaveBaseInfo}
              />
            </div>
          </div>
          <div
            className={classNames("live-edit-area", {
              "disabled-area": uneditable,
            })}
          >
            <div className="title-area">
              <div className="text-bold text-required">タイトル</div>
              <MemoInput
                placeholder="タイトルを入力してください"
                disabled={uneditable}
                styleType="block-grey-normal"
                name="title"
                formik={formik}
                scrollQuery=".ant-modal-body"
                error={v => !v ? "必須項目です" : v.length > 30 ? "30文字以内で入力してください" : ""}
              />
            </div>

            <div className="description-area">
              <span className="text-bold text-required">説明</span>
              <MemoTextArea
                placeholder="説明を入力してください"
                disabled={uneditable}
                name="description"
                formik={formik}
                scrollQuery=".ant-modal-body"
                error={v => !v ? "必須項目です" : ""}
              />
            </div>

            <div className="image-area">
              <span className="text-bold text-required">サムネイル画像</span>
              <span className="text-tip">
                ※画像を選択してください
              </span>
              <div className="image-select-area">
                <div>
                  <span className="validation-error" style={{ position: "absolute", left: "130px" }}>
                    {formik.touched.list_img_url && formik.errors.list_img_url}
                  </span>
                  <div className="image-select">
                    {list_img_url && !uneditable && (
                      <Icon
                        component={Icons.CloseIcon}
                        className="close-icon"
                        onClick={e => {
                          handlerFormikFieldChange(formik, "list_img_url", "");
                          e.stopPropagation();
                        }}
                      />
                    )}
                    {list_img_url ? (
                      <Image src={list_img_url} width={150} height={200} preview={false} />
                    ) : (
                      <BaseUpload onChange={url => handleFileChange(url, "list_img_url")}>
                        <Button text="画像を追加" theme="white" disabled={uneditable} />
                      </BaseUpload>
                    )}
                  </div>
                  <div className="image-select-label">一覧</div>
                </div>
                <div>
                  <span className="validation-error" style={{ position: "absolute", left: "320px" }}>
                    {formik.touched.detail_img_url && formik.errors.detail_img_url}
                  </span>
                  <div className="image-select">
                    {detail_img_url && !uneditable && (
                      <Icon
                        component={Icons.CloseIcon}
                        className="close-icon"
                        onClick={e => {
                          handlerFormikFieldChange(formik, "detail_img_url", "");
                          e.stopPropagation();
                        }}
                      />
                    )}
                    {detail_img_url ? (
                      <Image src={detail_img_url} width={150} height={200} preview={false} />
                    ) : (
                      <BaseUpload onChange={url => handleFileChange(url, "detail_img_url")}>
                        <Button text="画像を追加" theme="white" disabled={uneditable} />
                      </BaseUpload>
                    )}
                  </div>
                  <div className="image-select-label">詳細</div>
                </div>
              </div>
            </div>

            <span className="text-bold text-required">公開設定</span>
            <div className="public-date-select">
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "315px" }}>
                {(formik.touched.start_date ||
                  formik.touched.start_hour ||
                  formik.touched.start_minute) &&
                  formik.errors.start_date}
              </span>
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "690px" }}>
                {(formik.touched.end_date ||
                  formik.touched.end_hour ||
                  formik.touched.end_minute) &&
                  formik.errors.end_date}
              </span>
              <span className="text-required">配信日時</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={start_date}
                disabled={uneditable}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value => handlerFormikFieldChange(formik, "start_date", validStartDate(value))}
              />
              <GeneralInput
                mode={null}
                disabled={uneditable}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"start_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
                customOnChange={value => handlerFormikFieldChange(formik, "start_hour", validStartHour(value))}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={uneditable}
                options={createTimeOptions(60)}
                name={"start_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
                customOnChange={value => handlerFormikFieldChange(formik, "start_minute", validStartMinute(value))}
              />
              <span>　～</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={end_date}
                disabled={uneditable}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value => handlerFormikFieldChange(formik, "end_date", validEndDate(value))}
              />
              <GeneralInput
                mode={null}
                disabled={uneditable}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"end_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
                customOnChange={value => handlerFormikFieldChange(formik, "end_hour", validEndHour(value))}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={uneditable}
                options={createTimeOptions(60)}
                name={"end_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
                customOnChange={value => handlerFormikFieldChange(formik, "end_minute", validEndMinute(value))}
              />
            </div>
            <div className="public-date-select">
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "315px" }}>
                {(formik.touched.opening_date ||
                  formik.touched.opening_hour ||
                  formik.touched.opening_minute) &&
                  formik.errors.opening_date}
              </span>
              <span className="text-required">公開日時</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={opening_date}
                disabled={uneditable}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value => handlerFormikFieldChange(formik, "opening_date", validOpeningDate(value))}
              />
              <GeneralInput
                mode={null}
                disabled={uneditable}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"opening_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
                customOnChange={value => handlerFormikFieldChange(formik, "opening_hour", validOpeningHour(value))}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={uneditable}
                options={createTimeOptions(60)}
                name={"opening_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
                customOnChange={value => handlerFormikFieldChange(formik, "opening_minute", validOpeningMinute(value))}
              />
            </div>

            <div className="check-area">
              <span className="text-bold text-required">配信方法</span>
              <CusCheckbox
                options={[
                  { label: "管理画面", value: 0},
                  { label: "アプリ", value: 1},
                ]}
                value={[distribution_method_flag]}
                name={"distribution_method_flag"}
                onChange={(val) => {
                  handlerFormikFieldChange(formik, "distribution_method_flag", Number(val?.[1]));
                }}
                max={2}
              />
            </div>
            <div className="check-area">
              <span className="text-bold">配信詳細設定</span>
              <div className="nf-input-wrapper">
                <CusCheckbox
                  options={[
                    { label: "アーカイブにコメントを表示", value: 1},
                  ]}
                  value={[archive_comment_flag]}
                  name={"archive_comment_flag"}
                  onChange={(val) => {
                    handlerFormikFieldChange(formik, "archive_comment_flag", archive_comment_flag == 0 ? 1 : 0);
                  }}
                  max={2}
                />
              </div>
              <div className="nf-input-wrapper">
                <CusCheckbox
                  options={[
                    { label: "ライブ画面にテロップを流す", value: 1},
                  ]}
                  value={[telop_flag]}
                  name={"telop_flag"}
                  onChange={(val) => {
                    handlerFormikFieldChange(formik, "telop_flag", telop_flag == 0 ? 1 : 0);
                  }}
                  max={2}
                />
              </div>
            </div>
            <div>
              <span className="text-bold">商品情報</span>
              <span className="text-tip">
                <span className="text-subtip">
                  30枚まで登録可、ドラッグで入れ替え可。
                </span>
              </span>
              <span className="validation-error error-images">
                {formik.touched.products &&
                  formik.errors.products}
              </span>
            </div>
            <DraggableContainer
              items={products}
              isProduct={true}
              onClick={index => {
                setProductSearchModalVisible(true);
                setActiveIndex(index);
              }}
              uneditable={uneditable}
              onCancle={(e, index) => {
                handlerFormikFieldChange(
                  formik,
                  "products",
                  spliceArray(products, index, null)
                );
                e.stopPropagation();
              }}
              onExchange={(dragIndex, dropIndex) => {
                handlerFormikFieldChange(
                  formik,
                  "products",
                  swapArray(products, dragIndex, dropIndex)
                );
              }}
            />
            <div className="tag-area">
              <div className="tag-title">
                <span className="text-bold">タグ情報</span>
                <span className="text-tip">
                  <span className="text-subtip">
                    20個まで登録可。
                  </span>
                </span>
              </div>
              <GeneralInput
                label="カテゴリ"
                uneditable={categories.length === 1}
                placeholder="選択してください"
                styleType="block-grey-normal"
                labelTextAlign="left"
                options={categories}
                disabled={uneditable}
                mode={null}
                name={"tag_category_code"}
                formik={formik}
              />
              <div>
                <span className="text-tag">タグ</span>
                <Button
                  text="タグを選択"
                  disabled={!tag_category_code || uneditable}
                  theme="white"
                  onClick={() => setSelectTagModalVisible(true)}
                />
                <span className="validation-error">
                  {formik.touched.tag_code && formik.errors.tag_code}
                </span>
              </div>
              <div className="tags-container">
                {tag_code.map((t, i) => (
                  <div className="tag" key={i}>
                    {t.tag_name}
                    {!uneditable && (
                      <Icon
                        className="close-icon"
                        component={Icons.CloseIcon}
                        onClick={() => {
                          handlerFormikFieldChange(
                            formik,
                            "tag_code",
                            tag_code.filter(i => i.tag_code !== t.tag_code)
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="登録"
                type="submit"
                theme={"black"}
                disabled={uneditable || !formik.isValid}
              />
            </div>
          </div>
          <SelectModal
            checkBoxName="sign-up-tags"
            modalVisible={selectTagModalVisible}
            onCancel={() => setSelectTagModalVisible(false)}
            onOk={onTagOk}
            title={"タグを選択してください"}
            value={tag_code.map(t => t.tag_code)}
            options={tags}
            max={20}
          />
          <ProductSearchModal
            group={true}
            visible={productSearchModalVisible}
            onCancel={() => setProductSearchModalVisible(false)}
            onOk={selectedProduct => {
              handlerFormikFieldChange(
                formik,
                "products",
                spliceArray(products, activeIndex, selectedProduct)
              );
              setProductSearchModalVisible(false);
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default LiveSignUpModal;

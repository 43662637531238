import React, { useState, useMemo, useCallback } from "react";
import { Leaf } from "./elements/leaf";
import { createEditor } from "slate";
import { Element } from "./elements/element";
import { withHistory } from "slate-history";
import { withLink } from "./plugins/withLink";
import { withImage } from "./plugins/withImage";
import { withHtml } from "./plugins/withHtml";
import { getAvailableCount } from "./utils/getData";
import { Slate, Editable, withReact } from "slate-react";
import CusCheckbox from "../cusCheckbox";
import EditorToolbar from "./editorToolBar";
import pipe from "lodash/fp/pipe";
import "./style.scss";

const createEditorWithPlugins = pipe(
  withHtml,
  withReact,
  withHistory,
  withImage,
  withLink
);

const RichTextEditor = ({
  editorState = [
    {
      type: "content",
      children: [{ text: "" }],
    },
  ],
  onChange,
  error,
  articleCode,
  readOnly = false,
  setTouched,
  userCode
}) => {
  const editor = useMemo(() => createEditorWithPlugins(createEditor()), []);

  const [deviceOption, setDeviceOption] = useState([]);
  const renderElement = useCallback(
    props => (
      <Element
        {...{
          ...props,
          element: {
            ...props.element,
            deviceMode: deviceOption.includes("1"),
            articleCode,
            userCode
          },
        }}
      />
    ),
    [deviceOption, articleCode]
  );
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <>
      <div className={"rich-text-editor-title"}>
        <span>
          <span className="text-bold text-required">本文</span>
          <span className="validation-error">{error}</span>
        </span>
        <CusCheckbox
          value={deviceOption}
          options={[{ label: "スマホ幅で表示", value: "1" }]}
          onChange={setDeviceOption}
        />
      </div>
      <div className="rich-text-editor-area">
        <Slate editor={editor} value={editorState} onChange={onChange}>
          {!readOnly && (
            <EditorToolbar
              deviceMode={deviceOption.includes("1")}
              availableCount={getAvailableCount(editorState)}
            />
          )}
          <Editable
            onBlur={() => {
              setTouched();
              editor.blurSelection = editor.selection;
            }}
            spellCheck={false}
            readOnly={readOnly}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            style={{
              backgroundColor: "white",
              height: 450,
              minHeight: 450,
              padding: 20,
              width: deviceOption.includes("1") ? "416px" : "100%",
              maxWidth: "100%",
              overflowY: "auto",
            }}
          />
        </Slate>
      </div>
    </>
  );
};

export default RichTextEditor;

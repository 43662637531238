import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as messageActions } from "../slices/messageSlice";
import { actions as loadingActions } from "./loadingSlice";
import axios from "../utils/axiosUtil";

export const brandInit = createAsyncThunk(
  "brandInit",
  async (params, {dispatch}) => {
    dispatch(loadingActions.multiLoadingOn());
    const brandsRes = await axios.get("/brands/list", { params })
    const brandAllRes = await axios.get("brands/list", {
      params: { mall_flag:0, count: 0, sort: "brand_name_asc" },
    });

    dispatch(loadingActions.multiLoadingOff());

    return {
      brandList: brandsRes?.item ?? [],
      brands: (brandAllRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      total_count: brandsRes?.total_count ?? 0,
      active_count: brandsRes?.active_count ?? 0,
      pageSizeCount: brandsRes?.search_count ?? 0,
    }
  }

);

export const fetchBrand = createAsyncThunk(
  "fetchBrand",
  async params => await axios.get("/brands/list", { params } )
);

export const fetchMallBrand = createAsyncThunk(
    "fetchMallBrand",
    async params => await axios.get("/brands/list",{ params })
);

export const fetchBrandNoMall = createAsyncThunk(
    "fetchBrandNoMall",
    async params => await axios.get("/brands/list", { params })
);

export const fetchBrandDetail = createAsyncThunk(
  "fetchBrandDetail",
  async params => await axios.get("/brands/detail", { params } )
);

export const updateBrand = createAsyncThunk(
  "updateBrand",
  async (params, {dispatch}) => {
    const brandUpdateRes = await axios.post("/brands/update?img", params)
    brandUpdateRes?.code === "0" &&
      dispatch(
        messageActions.setMessage({
          messages: [
            `${params.division === "insert" ? "登録" : "更新"}が完了しました`,
          ],
        })
      );
  }
);

const initialSearchParams = {
  brand_code: "",
  brand_name: [],
  count:10,
  mall_flag:0,
  offset: 0,
  status: 0,
  sort: "brand_code_desc"
};

const initialEditParams = {
  division: "",
  brand_code: "",
  brand_name:"",
  brand_name_kana:"",
  ec_url:"",
  ec_url_coordinate:"",
  ec_url_blog:"",
  ec_url_live:"",
  ec_url_archive:"",
  reward_rate:0,
  use_from_date:"",
  use_to_date:"",
  brand_image_url:"",
  mall_flag:0,
  multi_post_brand:[]
};
const initialState ={
  brandList: [],
  mallBrandCount: 0,
  noMallBrandList: [],
  activeBrand: initialEditParams,
  brands: [],
  rete: Array.from(new Array(101)).map((v,i) => {return {label:i.toString(),value:i.toString()} }),
  editMode:"",
  editModalVisible: false,
  deleteModalVisible: false,
  searchParams: initialSearchParams,
  total_count: 0,
  active_count: 0,
  pageSizeCount: 0,
}

export const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    clear: () => initialState,
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveDelete: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        status: state.searchParams.status === 1 ? 0 : 1,
      }
    }),
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          brand_code: state.user_brand_code,
          status_flg: payload,
        },
      };
    },
    newEditModal: (state, { payload }) => ({
      ...state,
      editMode: "insert",
      editModalVisible: true,
      activeBrand : initialEditParams
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      activeBrand: payload,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      editMode:"",
      activeBrand:initialEditParams,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    editArticle: state => ({
      ...state,
      editModalVisible: false,
    }),
    deleteArticle: state => ({
      ...state,
      deleteModalVisible: false,
      activeBrand: null,
    }),
  },
  extraReducers: builder => {
    builder.addCase(brandInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchBrand.fulfilled, (state, { payload }) => ({
      ...state,
      brandList: payload?.item ?? [],
      total_count: payload?.total_count ?? 0,
      active_count: payload?.active_count ?? 0,
      pageSizeCount: payload?.search_count ?? 0,

    }));
    builder.addCase(fetchMallBrand.fulfilled, (state, { payload }) => ({
      ...state,
      mallBrandCount: (payload?.item ?? []).length,
    }));
    builder.addCase(fetchBrandNoMall.fulfilled, (state, { payload }) => ({
      ...state,
      noMallBrandList: (payload?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
    }));
    builder.addCase(fetchBrandDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeBrand: payload?.item ?? initialEditParams,
      editMode: "update",
      editModalVisible: true,
    }));
    builder.addCase(updateBrand.fulfilled, state => ({
      ...state,
      editMode: "",
      editModalVisible: false,
      deleteModalVisible: false,
      searchParams: { ...state.searchParams },
    }));
  },
});

export const actions = brandSlice.actions;

export default brandSlice.reducer;

import Icon1 from "../images/icon1.svg";
import Icon2 from "../images/icon2.svg";
import Icon3 from "../images/icon3.svg";
import Icon4 from "../images/icon4.svg";
import Icon5 from "../images/icon5.svg";
import Icon6 from "../images/icon6.svg";
import Icon7 from "../images/icon7.svg";
import Icon8 from "../images/icon8.svg";
import Icon9 from "../images/icon9.svg";
import Icon10 from "../images/icon10.svg";
import Icon11 from "../images/icon11.svg";
import Icon12 from "../images/icon12.svg";
import Icon13 from "../images/icon13.svg";
import Icon14 from "../images/icon14.svg";
import Icon15 from "../images/icon15.svg";
import Icon16 from "../images/icon16.svg";
import Icon16_2 from "../images/icon16_2.svg";
import Icon17 from "../images/icon17.svg";
import Icon18 from "../images/icon18.svg";
import Icon19 from "../images/icon19.svg";
import Icon20 from "../images/icon20.svg";
import Icon22 from "../images/icon22.svg";
import Icon23 from "../images/icon23.svg";
import Icon24 from "../images/icon24.svg";
import Icon25 from "../images/icon25.svg";
import Icon26 from "../images/icon26.svg";
import Icon27 from "../images/icon27.svg";
import Icon28 from "../images/icon28.svg";
import Icon29 from "../images/icon29.svg";
import Icon30 from "../images/icon30.svg";
import NoImgaeIcon from "../images/noimage.svg";
import NoImgaeSquare1 from "../images/noimage_square1.svg";
import NoImgaeSquare2 from "../images/noimage_square2.svg";
import LogoOnlineservice from "../images/logo_onlineservice.svg";
import LogoOnlineservicew2 from "../images/logo_onlineservicew2.svg";
import LogoOnlineservicew3 from "../images/logo_onlineservicew3.png";
import Icon_PC from "../images/icon_pc.svg";
import Icon_SP from "../images/icon_sp.svg";
import Camera_OFF from "../images/camera_off.svg";
import Camera_ON from "../images/camera_on.svg";
import Mike_OFF from "../images/mike_off.svg";
import Mike_ON from "../images/mike_on.svg";
import Menu_b from "../images/menu_b.svg";
import Icon_Paper_Airplane_W from "../images/icon_paper airplane_w.svg";
import Icon_Plus from "../images/icon_plus.svg";

const Icons = {
  ImageIcon1: Icon1,
  ImageIcon2: Icon2,
  ImageIcon3: Icon3,
  EditIcon: Icon4,
  RemoveIcon: Icon5,
  BoldIcon: Icon6,
  ImageIcon7: Icon7,
  ImageIcon8: Icon8,
  ImageIcon9: Icon9,
  ImageIcon10: Icon10,
  ImageIcon11: Icon11,
  ImageIcon12: Icon12,
  ImageIcon13: Icon13,
  ImageIcon14: Icon14,
  ImageIcon15: Icon15,
  ImageIcon16: Icon16,
  ImageIcon16_2: Icon16_2,
  CloseIcon: Icon17,
  ImageAddIcon: Icon18,
  FilterIcon: Icon19,
  SortAscendingIcon: Icon22,
  SortDescendingIcon: Icon20,
  FilterUsedIcon: Icon23,
  SettingIcon: Icon24,
  DisableSearchIcon: Icon25,
  SearchIcon: Icon26,
  DisableEditIcon: Icon27,
  favoriteItemIcon: Icon28,
  DisableSettingIcon: Icon29,
  DisableRemoveIcon: Icon30,
  NoImgaeIcon: NoImgaeIcon,
  NoImgaeSquare1: NoImgaeSquare1,
  NoImgaeSquare2: NoImgaeSquare2,
  LogoOnlineservice: LogoOnlineservice,
  LogoOnlineservicew2: LogoOnlineservicew2,
  LogoOnlineservicew3: LogoOnlineservicew3,
  PCIcon: Icon_PC,
  SPIcon: Icon_SP,
  CameraOFF: Camera_OFF,
  CameraON: Camera_ON,
  MikeOFF: Mike_OFF,
  MikeON: Mike_ON,
  MenuB: Menu_b,
  PaperAirplaneW: Icon_Paper_Airplane_W,
  IconPlus: Icon_Plus,
};

export default Icons;

import "./style.scss";
import Icon from "@ant-design/icons";
import IconImage from "../../../constants/Icons";
import dayjs from "dayjs";
import { toMoneyString } from "../../../utils/fnUtil";

/* コンテンツ売上実績のカード */

const SalesContentsResultCard = ({ data, type }) => {
  const getJsxIcon = (after, before) => {
    before = Number(before);
    after = Number(after);
    let icon = "";
    if (before < after) icon = IconImage.ImageIcon1;
    if (before === after) icon = IconImage.ImageIcon2;
    if (before > after) icon = IconImage.ImageIcon3;
    return (
      <Icon
        component={icon}
        style={{
          fontSize: 16,
          display: "inline-block",
          width: 20,
          textAlign: "right",
          margin: "auto 5px",
          cursor: "initial",
        }}
        className="display-icon"
      />
    );
  };
  const getGap = (after, before, preffix = '') => {
    before = Number(before);
    after = Number(after);
    if (before === after) return '';
    if(after - before > 0) return `${preffix}${toMoneyString(after - before)}`;
    else return `- ${preffix}${toMoneyString(Math.abs(after - before))}`;
  };
  return (
    <div className="salesSnsResultCard">
      <div className="salesSnsResultCard-term">
        <span style={{ fontWeight: "bold", fontSize: 14 }}>
          {type === "daily" ? "DAILY" : type === "monthly" ? "MONTHLY" : ""}
        </span>
        <span style={{ fontSize: 10, marginLeft: 5 }}>
          {type === "daily"
            ? "前々日対比"
            : type === "monthly"
            ? "前月同期間対比"
            : ""}
        </span>
        <span style={{ fontSize: 8, color: "#7B7B7B", display: "block" }}>
          {type === "daily" && data?.latest_date
            ? dayjs(data?.latest_date).format("YYYY.MM.DD")
            : type === "monthly" && data?.start_date && data?.end_date
            ? `${dayjs(data?.start_date).format("YYYY.MM.DD")}-${dayjs(
                data?.end_date
              ).format("YYYY.MM.DD")}`
            : "-"}
        </span>
      </div>
      <div className="salesSnsResultCard-result">
        <span className="salesSnsResultCard-result-value">
          ¥ {data?.sales_amount ? toMoneyString(data?.sales_amount) : "-"}
        </span>{" "}
        {type === "daily" && data?.sales_amount && data?.daybefore_sales_amount
          ? getJsxIcon(data?.sales_amount, data?.daybefore_sales_amount)
          : type === "monthly" &&
            data?.sales_amount &&
            data?.lastmonth_sales_amount
          ? getJsxIcon(data?.sales_amount, data?.lastmonth_sales_amount)
          : "-"}
        <span className="salesSnsResultCard-diff">
          {" "}
          {type === "daily" &&
          data?.sales_amount &&
          data?.daybefore_sales_amount
            ? getGap(data?.sales_amount, data?.daybefore_sales_amount, '¥')
            : type === "monthly" &&
              data?.sales_amount &&
              data?.lastmonth_sales_amount
            ? getGap(data?.sales_amount, data?.lastmonth_sales_amount, '¥')
            : "-"}
        </span>
      </div>
      <div className="salesSnsResultCard-analyze">
        <span className="salesSnsResultCard-analyze-colum">投稿数</span>
        <span className="salesSnsResultCard-analyze-value">
          {data?.count ? toMoneyString(data?.count) : "-"}
        </span>{" "}
        {type === "daily" && data?.count && data?.daybefore_count
          ? getJsxIcon(data?.count, data?.daybefore_count)
          : type === "monthly" && data?.count && data?.lastmonth_count
          ? getJsxIcon(data?.count, data?.lastmonth_count)
          : "-"}
        <span className="salesSnsResultCard-diff">
          {" "}
          {type === "daily" && data?.count && data?.daybefore_count
            ? getGap(data?.count, data?.daybefore_count)
            : type === "monthly" && data?.count && data?.lastmonth_count
            ? getGap(data?.count, data?.lastmonth_count)
            : "-"}
        </span>
      </div>
    </div>
  );
};

export default SalesContentsResultCard;

export const menuOptions = [
  {
    label: "トップ",
    name: "top_flag",
  },
  {
    label: "コーディネート",
    name: "coordinate_flag",
  },
  {
    label: "ブログ",
    name: "blog_flag",
  },
  {
    label: "ライブ配信",
    name: "live_list_flag",
  },
  {
    label: "動画",
    name: "movie_list_flag",
  },
  {
    label: "タグ",
    name: "tag_flag",
    subMenu: [
      {
        label: "カテゴリ一覧",
        name: "tag_category_flag",
      },
      {
        label: "タグ一覧",
        name: "tag_list_flag",
      },
    ],
  },
  {
    label: "SNS分析URL",
    name: "sns_analysis_flag",
    subMenu: [
      {
        label: "SNS分析URL作成",
        name: "sns_analysis_create_flag",
      },
      {
        label: "SNS分析URL一覧",
        name: "sns_analysis_list_flag",
      },
    ],
  },
  {
    label: "分析",
    name: "analysis_flag",
    subMenu: [
      {
        label: "コーディネート分析",
        name: "coordinate_sales_flag",
      },
      {
        label: "ブログ分析",
        name: "blog_sales_flag",
      },
      {
        label: "ライブ配信分析",
        name: "live_sales_flag",
      },
      {
        label: "動画分析",
        name: "movie_sales_flag",
      },
      {
        label: "店舗別分析",
        name: "shop_analysis_flag",
      },
      {
        label: "ブランド別分析",
        name: "brand_analysis_flag",
      },
      {
        label: "社員コンテンツ別分析",
        name: "user_analysis_flag",
      },
      {
        label: "社員SNS別分析",
        name: "user_sns_analysis_flag",
      },
      {
        label: "ランキング",
        name: "ranking_flag",
      },
      {
        label: "ベスト表",
        name: "best_list_flag",
      },
    ],
  },
  {
    label: "お知らせ",
    name: "notice_flag",
    subMenu: [
      {
        label: "お知らせ登録・配信設定",
        name: "notified_flag",
      },
      {
        label: "予約・配信済み一覧",
        name: "notice_list_flag",
      },
    ],
  },
  {
    label: "支払報酬設定/確認",
    name: "payment_reward_flag",
    subMenu: [
      {
        label: "報酬レート設定",
        name: "reward_rate_setting_flag",
      },
      {
        label: "支払報酬確認",
        name: "payment_reward_confirmation_flag",
      },
    ],
  },
  {
    label: "社員マスタ",
    name: "user_master_flag",
  },
  {
    label: "店舗マスタ",
    name: "shop_master_flag",
  },
  {
    label: "ブランドマスタ",
    name: "brand_master_flag",
  },
];

export const defaultMenu = {
  top_flag: 1,
  coordinate_flag: 1,
  blog_flag: 1,
  live_list_flag: 1,
  movie_list_flag: 1,
  tag_flag: 1,
  tag_category_flag: 1,
  tag_list_flag: 1,
  sns_analysis_flag: 1,
  sns_analysis_create_flag: 1,
  sns_analysis_list_flag: 1,
  analysis_flag: 1,
  coordinate_sales_flag: 1,
  blog_sales_flag: 1,
  live_sales_flag: 1,
  movie_sales_flag: 1,
  shop_analysis_flag: 1,
  brand_analysis_flag: 1,
  user_analysis_flag: 1,
  user_sns_analysis_flag: 1,
  ranking_flag: 1,
  best_list_flag: 1,
  notice_flag: 1,
  notified_flag: 1,
  notice_list_flag: 1,
  payment_reward_flag: 1,
  reward_rate_setting_flag: 1,
  payment_reward_confirmation_flag: 1,
  user_master_flag: 1,
  shop_master_flag: 1,
  brand_master_flag: 1,
};

export const userAuthOptions = [
  {
    label: "システム管理者",
    value: "0",
  },
  {
    label: "管理責任者",
    value: "1",
  },
  {
    label: "ブランド責任者",
    value: "2",
  },
  {
    label: "店舗責任者",
    value: "3",
  },
  {
    label: "スタッフ",
    value: "4",
  },
];

export const genderOptions = [
  { label: "未選択", value: "0" },
  { label: "男性", value: "1" },
  { label: "女性", value: "2" },
];

export const genderOptionsWithKid = [
  { label: "未選択", value: "0" },
  { label: "男性", value: "1" },
  { label: "女性", value: "2" },
  { label: "キッズ", value: "3" },
];

export const pdfOutputTypeOptions = [
  { label: "コーディネート分析", value: "0" },
  { label: "社員コンテンツ分析", value: "1" },
];

import { useEffect, useState, memo } from "react";
import moment from "moment";
import "./style.scss";

const RemainingTime = (({
  limit,
  onPassTime,
  onPassSession,
  displayDiff,
}) => {
  const [time, setTime] = useState("");
  const [isCount, setIsCount] = useState(true);

  useEffect(() => {
    let sessionLimit = null;
    const interval = setInterval(() => {
      if (!limit) return;
      if (sessionLimit) {
        const sessionDiff = moment(sessionLimit).diff(moment());
        if (sessionDiff <= 0) {
          sessionLimit = null;
          onPassSession && onPassSession();
        }
      } else {
        // 25分ごとにセッションを確認する
        sessionLimit = moment().add(25, 'minute');
      }
      setIsCount(true);
      const diff = moment(limit).diff(moment());
      if (diff <= 0) {
        setTime("");
        onPassTime && onPassTime();
        setIsCount(false);
        return;
      }
      if (displayDiff && displayDiff < diff) {
        setTime("");
        return;
      }
      const duration = moment.duration(diff);
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      setTime((minutes > 0 ? `${minutes}分` : '') + `${seconds}秒`);
    }, 1000);
    if (!isCount) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [limit, isCount]);

  return (
    <>
      {time &&
        <div className="top-info-area">
          <span>配信終了まで残り</span>
          <span>{time}</span>
        </div>
      }
    </>
  );
});

export default RemainingTime;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Table, DatePicker, Image } from "antd";
import Icon from "@ant-design/icons";
import { useUpdateEffect } from "../../utils/hooksUtil";
import SelectColumsModal from "../../components/modal/selectCloumsModal";
import CsvDownloadModal from "../../components/modal/csvDownloadModal";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  fetchCoordinateFavoriteItem,
  fetchAnalyzeCoordinate,
  updateCsvCoordinateFavoriteItem,
  csvDownloadCoordiate,
} from "../../slices/analyzeCoordinateSlice";
import { validDatePickChecker, toMoneyString } from "../../utils/fnUtil";
import InfomationModal from "../../components/modal/infomationModal";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import moment from "moment";
import "./style.scss";
const AnalyzeCoordinate = () => {
  const dispatch = useDispatch();
  const {
    analyzeList,
    searchParams,
    total,
    selectColumsVisible,
    showFields,
    csvDownloadModalVisible,
    filterUserVisible,
    filterBrandVisible,
    filterShopVisible,
    filterList,
    favoriteItem,
    infomationModal,
    infomationTitle,
    flag,
  } = useSelector((state) => state.analyzeCoordinate);

  const pageTitle = "コンテンツ分析 - コーディネート";
  const columns = [
    {
      title: () => (
        <>
          <span>コーディネートCD </span>
          <span onClick={createOnClikSort("coordinate_code")}>
            {getSortIcon(formik.values.sort === "coordinate_code_asc")}
          </span>
        </>
      ),
      dataIndex: "coordinate_code",
      key: "coordinate_code",
      label: "コーディネートCD",
      align: "center",
      width: TableColumnWidth.analyzeCoordinate.cd,
      render: (val) => val || "-",
    },
    {
      title: () => (
        <>
          <span>コーディネート画像 </span>
        </>
      ),
      dataIndex: "image_url",
      key: "image_url",
      label: "コーディネート画像",
      width: TableColumnWidth.image,
      render: (image) =>
        image ? (
          <Image src={image} width={100} height={100} />
        ) : (
          <Icon component={Icons.NoImgaeIcon} style={{ fontSize: 50 }} />
        ),
      align: "center",
    },
    {
      title: () => (
        <>
          <span>社員 </span>
          <span onClick={createOnClikSort("user_name")}>
            {getSortIcon(formik.values.sort === "user_name_asc")}
          </span>
          &nbsp;&nbsp;
          <Icon
            component={
              filterList.user_info.length === 0
                ? ""
                : searchParams.user_code.length === 0
                ? Icons.FilterIcon
                : Icons.FilterUsedIcon
            }
            onClick={() => {
              dispatch(actions.openFilterUserModal());
            }}
          />
        </>
      ),
      key: "user_name",
      dataIndex: "user_name",
      label: "社員",
      align: "center",
      width: TableColumnWidth.userName,
      render: (userName, data) => (
        <span className="display-linebreak">
          {userName
            ? `${userName}\n${
                data?.user_nickname ? `(${data?.user_nickname})` : ""
              }`
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>店舗 </span>
          <span onClick={createOnClikSort("shop_name")}>
            {getSortIcon(formik.values.sort === "shop_name_asc")}
          </span>
          &nbsp;&nbsp;
          <Icon
            component={
              filterList.shop_info.length === 0
                ? ""
                : searchParams.shop_code.length === 0
                ? Icons.FilterIcon
                : Icons.FilterUsedIcon
            }
            onClick={() => {
              dispatch(actions.openFilterShopModal());
            }}
          />
        </>
      ),
      key: "shop_name",
      dataIndex: "shop_name",
      label: "店舗",
      align: "center",
      width: TableColumnWidth.shopName,
      render: (val) => val || "-",
    },
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span onClick={createOnClikSort("brand_name")}>
            {getSortIcon(formik.values.sort === "brand_name_asc")}
          </span>
          &nbsp;&nbsp;
          <Icon
            component={
              filterList.brand_info.length === 0
                ? ""
                : searchParams.brand_code.length === 0
                ? Icons.FilterIcon
                : Icons.FilterUsedIcon
            }
            onClick={() => {
              dispatch(actions.openFilterBrandModal());
            }}
          />
        </>
      ),
      key: "brand_name",
      dataIndex: "brand_name",
      label: "ブランド",
      align: "center",
      width: TableColumnWidth.brandName,
      render: (val) => val || "-",
    },
    {
      title: () => (
        <>
          <span>公開日時 </span>
          <span onClick={createOnClikSort("datetime")}>
            {getSortIcon(formik.values.sort === "datetime_asc")}
          </span>
        </>
      ),
      key: "published_at",
      dataIndex: "published_at",
      label: "公開日時",
      align: "center",
      width: TableColumnWidth.date,
      render: (date) => (
        <span>{date ? moment(date).format("YYYY.MM.DD") : "-"}</span>
      ),
    },
    {
      title: () => (
        <>
          <span>売上 </span>
          <span onClick={createOnClikSort("sales")}>
            {getSortIcon(formik.values.sort === "sales_asc")}
          </span>
        </>
      ),
      key: "earnings",
      dataIndex: "earnings",
      label: "売上",
      align: "center",
      width: TableColumnWidth.earnings,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>件数 </span>
          <span onClick={createOnClikSort("order_count")}>
            {getSortIcon(formik.values.sort === "order_count_asc")}
          </span>
        </>
      ),
      key: "order_count",
      dataIndex: "order_count",
      label: "件数",
      align: "center",
      width: TableColumnWidth.orderCount,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>商品点数 </span>
          <span onClick={createOnClikSort("qty")}>
            {getSortIcon(formik.values.sort === "qty_asc")}
          </span>
        </>
      ),
      key: "qty",
      dataIndex: "qty",
      label: "商品点数",
      align: "center",
      width: TableColumnWidth.qty,
      render: (val) => toMoneyString(val) || "-",
    },

    {
      title: () => (
        <>
          <span>PV数 </span>
          <span onClick={createOnClikSort("pv")}>
            {getSortIcon(formik.values.sort === "pv_asc")}
          </span>
        </>
      ),
      key: "pv_count",
      dataIndex: "pv_count",
      label: "PV数",
      align: "center",
      width: TableColumnWidth.pv,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>いいね数 </span>
          <span onClick={createOnClikSort("like")}>
            {getSortIcon(formik.values.sort === "like_asc")}
          </span>
        </>
      ),
      key: "like_count",
      dataIndex: "like_count",
      label: "いいね数",
      align: "center",
      width: TableColumnWidth.like,
      render: (val) => toMoneyString(val) || "-",
    },
  ];
  const favoriteItemOptions = [
    { label: "コーディネートCD", value: "coordinate_cd" },
    { label: "ブランド名", value: "brand_name" },
    { label: "社員CD", value: "user_cd" },
    { label: "公開日時", value: "release_date" },
    { label: "社員名", value: "user_name" },
    { label: "売上", value: "sales" },
    { label: "サイト表示名", value: "user_nickname" },
    { label: "件数", value: "count" },
    { label: "店舗CD", value: "shop_cd" },
    { label: "商品点数", value: "order_qty" },
    { label: "店舗名", value: "shop_name" },
    { label: "PV数", value: "pv_total" },
    { label: "ブランドCD", value: "brand_cd" },
    { label: "いいね数", value: "like_total" },
  ];
  const displayColumnOptions = [
    { label: "コーディネートCD", value: "coordinate_code" },
    { label: "コーディネート画像", value: "image_url" },
    { label: "社員", value: "user_name" },
    { label: "店舗", value: "shop_name" },
    { label: "ブランド", value: "brand_name" },
    { label: "公開日時", value: "published_at" },
    { label: "売上", value: "earnings" },
    { label: "件数", value: "order_count" },
    { label: "商品点数", value: "qty" },
    { label: "PV数", value: "pv_count" },
    { label: "いいね数", value: "like_count" },
  ];
  const screenType = 1;

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) =>
      dispatch(
        actions.saveSearchParam({
          user_code: flag ? [] : searchParams.user_code,
          brand_code : flag ? [] : searchParams.brand_code,
          shop_code : flag ? [] : searchParams.shop_code,
          start_date: analyzeDate.from?.format("YYYY-MM-DD"),
          end_date: analyzeDate.to?.format("YYYY-MM-DD"),
        })
      ),
  });
  const [analyzeDate, setAnalyzeDate] = useState({ from: null, to: null });

  useEffect(() => {
    return () => dispatch(actions.clear());
  }, []);
  useEffect(() => {
    dispatch(fetchCoordinateFavoriteItem({ screen_type: screenType }));
    dispatch(actions.saveShowFields(columns));
  }, []);
  useUpdateEffect(() => {
    if (isValidFormVal(searchParams)) {
      dispatch(fetchAnalyzeCoordinate(searchParams));
    } else {
      dispatch(actions.openInfomationModal("集計期間は必須です"));
    }
  }, [searchParams, dispatch]);
  useEffect(() => {
    dispatch(actions.saveShowFields(columns));
  }, [analyzeList]);
  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      formik.values.sort === desc
        ? asc
        : formik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const datePickerStyle = {
    backgroundColor: "white",
    fontSize: 16,
    height: 32,
    width: 120,
  };
  const getSortIcon = (sort) => {
    return sort ? (
      <>
        &nbsp;
        <Icon component={Icons.SortAscendingIcon} style={{ fontSize: 10 }} />
      </>
    ) : (
      <>
        &nbsp;
        <Icon component={Icons.SortDescendingIcon} style={{ fontSize: 10 }} />
      </>
    );
  };
  const isValidFormVal = (_searchParams) => {
    return _searchParams.start_date && _searchParams.end_date;
  };
  return (
    <div className="content-body">
      <SelectColumsModal
        title="表示する項目を選択してください"
        modalVisible={selectColumsVisible}
        closeModal={() => dispatch(actions.closeSelectColumsModal())}
        options={displayColumnOptions}
        onDisplay={(checkedList) => {
          dispatch(
            actions.saveShowFields(
              columns.filter((col) =>
                checkedList.some((checkedCol) => checkedCol === col.key)
              )
            )
          );
        }}
        chekedValues={displayColumnOptions.map((opt) => opt.value)}
        colSpan={12}
        initialize={false}
      />
      <SelectColumsModal
        title="絞り込む社員を選択してください"
        modalVisible={filterUserVisible}
        closeModal={() => dispatch(actions.closeFilterUserModal())}
        options={filterList.user_info
          .map((user) => {
            if (user?.user_code) {
              return {
                label: `${user?.user_code} ${user?.user_name || ""}${
                  user?.user_nickname ? `(${user?.user_nickname})` : ""
                }`,
                value: user?.user_code || "-",
              };
            }
          })
          .filter((e) => e)}
        onDisplay={(checkedList) => {
          dispatch(
            actions.saveSearchParam({
              user_code: checkedList,
            })
          );
        }}
        chekedValues={searchParams.user_code}
        colSpan={24}
      />
      <SelectColumsModal
        title="絞り込む店舗を選択してください"
        modalVisible={filterShopVisible}
        closeModal={() => dispatch(actions.closeFilterShopModal())}
        options={filterList.shop_info
          .map((shop) => {
            if (shop?.shop_code) {
              return {
                label: `${shop.shop_code} ${shop.shop_name || ""}`,
                value: shop.shop_code,
              };
            }
          })
          .filter((e) => e)}
        onDisplay={(checkedList) => {
          dispatch(actions.saveSearchParam({ shop_code: checkedList }));
        }}
        chekedValues={searchParams.shop_code}
        colSpan={24}
      />
      <SelectColumsModal
        title="絞り込むブランドを選択してください"
        modalVisible={filterBrandVisible}
        closeModal={() => dispatch(actions.closeFilterBrandModal())}
        options={filterList.brand_info
          .map((brand) => {
            if (brand?.brand_code) {
              return {
                label: `${brand.brand_code} ${brand?.brand_name || ""}`,
                value: brand.brand_code,
              };
            }
          })
          .filter((e) => e)}
        onDisplay={(checkedList) => {
          dispatch(actions.saveSearchParam({ brand_code: checkedList }));
        }}
        chekedValues={searchParams.brand_code}
        colSpan={24}
      />
      <CsvDownloadModal
        modalVisible={csvDownloadModalVisible}
        options={favoriteItemOptions.map((opt) => {
          return { ...opt, colSpan: 12 };
        })}
        favoriteItem={favoriteItem}
        onFavoriteItem={(checkedList) => {
          let param = {
            screen_type: screenType,
            favorite: {},
          };
          favoriteItemOptions.forEach(
            (item) => (param.favorite[item.value] = "0")
          );
          checkedList.forEach((item) => (param.favorite[item] = "1"));
          dispatch(updateCsvCoordinateFavoriteItem(param));
        }}
        onClose={() => dispatch(actions.closeCsvDownloadModal())}
        onDownload={(checkedList) => {
          if (isValidFormVal(searchParams)) {
            dispatch(
              csvDownloadCoordiate({
                screen_type: screenType,
                start_date: searchParams.start_date,
                end_date: searchParams.end_date,
                user_code: searchParams.user_code,
                shop_code: searchParams.shop_code,
                brand_code: searchParams.brand_code,
                columns: checkedList,
              })
            );
          } else {
            dispatch(actions.openInfomationModal("集計期間は必須です"));
          }
        }}
      />
      <InfomationModal
        modalVisible={infomationModal}
        item={{ information_title: infomationTitle }}
        setModalVisible={() => {
          dispatch(actions.closeInfomationModal());
        }}
      />
      <Header />
      <div className="analyzeCordinate-container nf-page">
        <h2>{pageTitle}</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center nf-analyze-required">
                集計期間
              </label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={analyzeDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    analyzeDate,
                    setAnalyzeDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={analyzeDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    analyzeDate,
                    setAnalyzeDate
                  );
                }}
              />
            </div>
          </div>
          <Button
            text="検索"
            type="submit"
            disabled={!analyzeDate.from || !analyzeDate.to}
            onClick={() => {
              if (searchParams?.user_code?.length !== 0 || searchParams?.shop_code?.length !== 0 || searchParams?.brand_code?.length !== 0) {
                dispatch(
                  actions.saveSearchParam({
                    user_code: [],
                    shop_code: [],
                    brand_code: [],
                  })
                );
              }
              dispatch(actions.changeFlag());
            }}
          />
        </form>
        <div className="table-header">
          <div className="top-header">
            <Button
              text="CSV出力"
              width="wider"
              style={{ display: "block", marginBottom: 5, marginLeft: "auto" }}
              onClick={() => dispatch(actions.openCsvDownloadModal())}
            />
          </div>
          <div className="bottom-header">
            <h3>検索結果一覧</h3>
            <div style={{ display: "flex" }}>
              <Button
                text="フィルター解除"
                theme="white"
                width="wider"
                disabled={
                  analyzeList.length === 0
                  && searchParams?.user_code?.length === 0
                  && searchParams?.shop_code?.length === 0
                  && searchParams?.brand_code?.length === 0
                }
                onClick={() => {
                  dispatch(
                    actions.saveSearchParam({
                      user_code: [],
                      shop_code: [],
                      brand_code: [],
                    })
                  );
                }}
              />
              <Button
                text="表示切替"
                width="wider"
                style={{
                  backgroundColor: "#ffffff",
                  border: "none",
                  color: "black",
                  marginLeft: 15,
                }}
                onClick={() => {
                  dispatch(actions.openSelectColumsModal());
                }}
              />
            </div>
          </div>
        </div>
        <Table
          columns={showFields}
          dataSource={analyzeList}
          onChange={(pagination) => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{offsetHeader: 60}}
        />
      </div>
      <Footer />
    </div>
  );
};

export default AnalyzeCoordinate;

import { Modal, DatePicker, Checkbox } from "antd";
import "./style.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Yup from "../../../utils/yupUtil";
import Button from "../../button";
import GeneralInput from "../../generalInput";
import CusCheckbox from "../../cusCheckbox";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";
import { actions, fetchTags, updateTag } from "../../../slices/tagsSlice";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import moment from "moment";

const TagEditModal = () => {
  const dispatch = useDispatch();
  const { editModalVisible, categories, editMode, tagDate } = useSelector( state => state.tags );
  const formik = useFormik({
    initialValues: {
      ...tagDate,
      registration_button: false
    },
    validationSchema: Yup.object({
      tag_category_code: Yup.string().nullable().selected("カテゴリ"),
      tag_name: Yup.string().required(),
      start_date: Yup.string().nullable().date_required(),
      end_date: Yup.string().nullable().date_required(),
    }),
    onSubmit: values => {
      const { tag_category_code, ...rest } = values;
      dispatch(
        updateTag({
          division: editMode,
          tag_category_code: values.tag_category_code,
          tag_code: values.tag_code,
          tag_name: values.tag_name,
          start_date: moment(values.start_date).format("YYYY-MM-DD"),
          end_date: moment(values.end_date).format("YYYY-MM-DD"),
          approval_flg: values.approval_flg          
        })
      );
    },
  });
  const onBack = () => {
    dispatch(actions.closeEditModal())
  };
  const { 
    start_date, 
    end_date, 
    approval, 
    tag_category_code, 
    tag_name,
    approval_flg,
    registration_button
  } = formik.values;

  useEffect(() => {
    handlerFormikFieldChange(formik, "registration_button", 
      tag_category_code != null && tag_name != null && 
      start_date != null && end_date != null && approval_flg != null
    );
    if(editMode === "update"){
      tagDate?.tag_category_code && handlerFormikFieldChange(formik, "tag_category_code", tagDate?.tag_category_code);
      tagDate?.tag_category_name && handlerFormikFieldChange(formik, "tag_category_name", tagDate?.tag_category_name);
      tagDate?.brand_code && handlerFormikFieldChange(formik, "brand_code", tagDate?.brand_code);
      tagDate?.brand_name && handlerFormikFieldChange(formik, "brand_name", tagDate?.brand_name);
      tagDate?.tag_code && handlerFormikFieldChange(formik, "tag_code", tagDate?.tag_code);
      tagDate?.tag_name && handlerFormikFieldChange(formik, "tag_name", tagDate?.tag_name);
      handlerFormikFieldChange(formik, "approval", tagDate?.approval_flg === 1 ? "approval" : "anApproval");
      handlerFormikFieldChange(formik, "approval_flg", tagDate?.approval_flg);
    }
    handlerFormikFieldChange(formik, "start_date", tagDate?.start_date ? moment(tagDate?.start_date) : moment());
    handlerFormikFieldChange(formik, "end_date", tagDate?.end_date ? moment(tagDate?.end_date) : moment("2999-12-31"));
}, [editModalVisible]);

  return (
    <Modal
      visible={editModalVisible}
      closable={false}
      width={1000}
      footer={null}
      onCancel={onBack}
      afterClose={() => formik.resetForm()}
    >
      <div className ="nf-tag-modal-area">
        <div className="title-area">
          <span className="text-title text-bold">{editMode === "insert" ? "タグ登録": "タグ編集"} </span>
          <span className="nf-text-required-style">*は必須項目です</span>
        </div>
        <div className="tag-edit-area input-section">
          {
            editMode === "insert" ?
            <>
              <GeneralInput
                label="カテゴリ"
                labelTextAlign ="left"
                labelWidth="wider"
                placeholder={"カテゴリを選択してください"}
                requiredItem
                options={categories}
                styleType="block-grey"
                mode={null}
                name={"tag_category_code"}
                formik={formik}
              />
            </>
            :
            <>
              <GeneralInput
                label="タグCD"
                labelWidth="wider"
                labelTextAlign="left"
                uneditable={true}
                styleType="block-grey-normal"
                formik={formik}
                name={"tag_code"}
              />
              <GeneralInput
                label="カテゴリ"
                labelWidth="wider"
                labelTextAlign="left"
                uneditable={true}
                styleType="block-grey-normal"
                formik={formik}
                name={"tag_category_name"}
              />
              <GeneralInput
                label="ブランド"
                labelWidth="wider"
                labelTextAlign="left"
                uneditable={true}
                styleType="block-grey-normal"
                formik={formik}
                name={"brand_name"}
              />
            </>
          }
          <GeneralInput
            label ="タグ名"
            labelTextAlign ="left"
            labelWidth="wider"
            requiredItem={true}
            placeholder="タグ名を入力してください"
            selectSize="half"
            styleType="block-grey"
            name={"tag_name"}
            formik={formik}
          />
          {
            editMode === "update" ?
            <>
              <div class="nf-input-wrapper nf-input-wrapper-herf nf-checkbox-checkbox">
                <label className="nf-input-label text-align-left label-width-wider">承認ステータス</label>
                <GeneralCheckbox
                  label="承認"
                  className="nf-checkbox-margin"
                  checked={approval === "approval"}
                  onChange={(e) => {
                    if(e.target.checked){
                      handlerFormikFieldChange(formik, "approval_flg", 1)
                      handlerFormikFieldChange(formik, "approval", "approval")
                    }
                  }}
                />
                <GeneralCheckbox
                  label="未承認"
                  checked={approval === "anApproval"}
                  onChange={(e) => {
                    if(e.target.checked){
                      handlerFormikFieldChange(formik, "approval_flg", 0)
                      handlerFormikFieldChange(formik, "approval", "anApproval")
                    }
                  }}
                />
              </div>
            </>
            : <></>
          }
          <div className="input-date input-date-area">
            <div className="nf-input-wrapper">
              <div className="text-required iregular-label">公開開始日</div>
              <DatePicker
                format={"YYYY.MM.DD"}
                size="small"
                placeholder={null}
                value={start_date}
                // value={moment(start_date)}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value =>
                  value !== null && end_date !== null
                    ? value > end_date 
                      ? handlerFormikFieldChange(formik, "start_date", end_date)
                      : handlerFormikFieldChange(formik, "start_date", value)
                    : handlerFormikFieldChange(formik, "start_date", value)
                }
              />
              {formik?.errors.start_date && formik?.touched.start_date && (
                <span className="nf-input-error">{formik.errors.start_date}</span>
              )}
            </div>
            <div className="nf-input-wrapper">
              <span className="text-required end-date">公開終了日</span>
                <DatePicker
                  format={"YYYY.MM.DD"}
                  value={end_date}
                  // value={moment(end_date)}
                  placeholder={null}
                  suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  onChange={value =>
                    value !== null  && start_date !== null
                      ? value < start_date 
                        ? handlerFormikFieldChange(formik, "end_date", start_date)
                        : handlerFormikFieldChange(formik, "end_date", value)
                      : handlerFormikFieldChange(formik, "end_date", value)
                  }
                />
                {formik?.errors.end_date && formik?.touched.end_date && (
                  <span className="nf-input-error">{formik.errors.end_date}</span>
                )}
            </div>
            <span className="validation-error validation-date">
              {formik.errors.date}
            </span>
          </div>
        </div>
        <div className= "btn-area">
          <Button text="戻る" theme="white" onClick={() => onBack()} />
          <form className="search-area" onSubmit={formik.handleSubmit}>
            <Button 
              text={editMode === "insert" ? "登録": "更新"} 
              type="submit"
              theme={!registration_button ? !formik.isValid ? "light-black" : "black" : "black"}
              disabled={!registration_button ? !formik.isValid ? true : false : false}
            />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default TagEditModal;

import "./style.scss";
import React, { useState } from "react";
import Footer from "../../components/footer";
import { Input } from "antd";
import { useFormik } from "formik";
import Yup from "../../utils/yupUtil";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import { Link } from "react-router-dom";
import Button from "../../components/button";
import LoginInfoModal from "../../components/modal/loginInfoModal";
import { useDispatch, useSelector } from "react-redux";
import { generateProvisonalPassword, actions } from "../../slices/accountSlice";

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const { passwordRecoveryInfoModal } = useSelector((state) => state.account);
  const formik = useFormik({
    initialValues: { corporation_code: "", mail_address: "" },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      corporation_code: Yup.string().required(),
      mail: Yup.string().required().mail(),
    }),
    onSubmit: async (inputVals) => {
      dispatch(
        generateProvisonalPassword({
          request_from: 3,
          corporation_code: inputVals.corporation_code,
          mail_address: inputVals.mail,
        })
      );
    },
  });
  return (
    <>
      <LoginInfoModal
        modalVisible={passwordRecoveryInfoModal}
        setModalVisible={(val) => {
          dispatch(actions.closePasswordRecoveryInfoModal());
        }}
        title={""}
        msg={"メールを送信しました。"}
      />
      <div className="passwordRecovery-page">
        <div className="passwordRecovery-content">
          <div className="passwordRecovery-title">
            <b>パスワードをお忘れの方</b>
          </div>
          <div className="passwordRecovery-form">
            <div className="passwordRecovery-input-wrapper">
              <div className="passwordRecovery-label-area">
                <span className="passwordRecovery-label">法人CD</span>
                <span className="passwordRecovery-requiredMark">
                  {formik?.touched?.corporation_code &&
                    formik?.errors?.corporation_code}
                </span>
              </div>
              <Input
                bordered={true}
                className="input-passwordRecovery"
                name="corporation_code"
                placeholder={"法人CDを入力してください"}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  handlerFormikFieldChange(
                    formik,
                    "corporation_code",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="passwordRecovery-input-wrapper">
              <div className="passwordRecovery-label-area">
                <span className="passwordRecovery-label">メールアドレス</span>
                <span className="passwordRecovery-requiredMark">
                  {formik?.touched?.mail && formik?.errors?.mail}
                </span>
              </div>
              <Input
                bordered={true}
                className="input-passwordRecovery"
                name="mail"
                placeholder={"メールアドレスを入力してください"}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  handlerFormikFieldChange(formik, "mail", e.target.value);
                }}
              />
            </div>
            <div className="btn-area">
              <Link to="/login">
                <Button
                  theme="white"
                  type="link"
                  contentEditable={false}
                  text="ログイン画面へ戻る"
                  style={{
                    width: 140,
                  }}
                />
              </Link>
              <Button
                contentEditable={false}
                text="メールを送信"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}
                style={{
                  width: 140,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PasswordRecovery;

import "./style.scss";
import React from "react";
import PersonalIcon from "../personalIcon";
import { useHistory } from "react-router-dom";
import { toMoneyString } from "../../utils/fnUtil";
const Ranking = ({ rankingList, type, accountAuth }) => {
  const history = useHistory();

  const onClickCard = (card) => {
    if (card?.user_code) {
      history.push({
        pathname: "/staffResult",
        state: { staff_code: card?.user_code },
      });
    }
  };

  const createCard = (card, i) => {
    return card ? (
      <div className="ranking-card" onClick={() => onClickCard(card)}>
        <span className="ranking-no">{i + 1}</span>
        <PersonalIcon
          imgSrc={card?.image_url}
          width={100}
          height={100}
        />
        <p className="brand-name" style={{ marginTop: 7 }}>{type === "contents" ? card?.brand_name || "-" : "　"}</p>
        <p className="shop-name">{card?.shop_name || "-"}</p>
        <p className="user-name" style={{fontSize: 12}}>
          {card?.user_name}（{card?.user_nickname}）
        </p>
        <p className="ranking-result">
          ￥
          {type === "contents" || type === "live"
            ? card?.earnings || card?.earnings === 0
              ? toMoneyString(card?.earnings)
              : "-"
            : type === "sns"
            ? card?.sales_amount || card?.sales_amount === 0
              ? toMoneyString(card?.sales_amount)
              : "-"
            : "-"}
        </p>
        {type === "contents" ? (
          <>
            <div className="content-detail">
              <div className="left">PV</div>
              <div>{toMoneyString(card?.pv_count) || "-"}</div>
            </div>
            <div className="content-detail" style={{ marginBottom: "10px" }}>
              <div className="left">いいね</div>
              <div>{toMoneyString(card?.like_count) || "-"}</div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    ) : <div className="ranking-card-empty"></div>;
  };

  return (
    <>
      {rankingList.length > 0 ? (
        <div className="ranking-cards">
          {createCard(rankingList[0], 0)}
          {createCard(rankingList[1], 1)}
          {createCard(rankingList[2], 2)}
        </div>
      ) : (
        <div className="ranking-none">実績はありません</div>
      )}
    </>
  );
};

export default Ranking;

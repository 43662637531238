import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import axios from "../utils/axiosUtil";
import { actions as messageActions } from "../slices/messageSlice";

export const tagsInit = createAsyncThunk("tagsInit", async (params, {dispatch}) => {
  dispatch(loadingActions.multiLoadingOn());
  const [tagsRes, categoriesRes, brandsRes] = await Promise.all([
    axios.get("tags/list", { params }),
    axios.get("tags/category/list", { params : { count: 0, sort : "tag_category_name_asc" } }),
    axios.get("brands/list", { params: { count: 0, sort : "brand_name_asc"}}),
  ]);
  dispatch(loadingActions.multiLoadingOff());
  return {
    user_brand_code: params.brand_code,
    operator_code: params.operator_code,
    tagsList: tagsRes?.item ?? [],
    activeTag: {},
    total: tagsRes?.total,
    pageSize: tagsRes?.search_count,
    statusCountMap: tagsRes?.status_count ?? {},
    categories: (categoriesRes?.item ?? []).map(c => ({
      ...c,
      label: c.tag_category_name,
      value: c.tag_category_code,
    })),
    brands: (brandsRes?.item ?? []).map(b => ({
      ...b,
      label: b.brand_name,
      value: b.brand_code,
    })),
  };
});

export const fetchTags = createAsyncThunk(
  "fetchTags",
  async params => await axios.get("/tags/list", { params })
);

export const updateTag = createAsyncThunk(
  "updateTag",
  async (params, {dispatch}) => {
    const tagUpdatRes = await axios.post("/tags/update", params )
    tagUpdatRes?.code === "0" &&
      dispatch(
        messageActions.setMessage({
          messages: [
            `${params.division === "insert" ? "登録" : "更新"}が完了しました`,
          ],
        })
      );
  }
);
export const allUpdateTag = createAsyncThunk(
  "allUpdateTag",
  async params => {
    await axios.post("/tags/approval", params)
  }
);

const initialSearchParams = {
  brand_code: [],
  tag_code: "",
  tag_name: "",
  tag_category_code: [],
  deleted_flg: 0,
  offset: 0,
  count:10,
  status:999,
  sort: "brand_name_desc",
  operator_code: null,
};

const initialEditParams = {
  tag_category_code: null,
  tag_category_name: null,
  brand_code: null,
  brand_name: null,
  tag_code: null,
  tag_name: "",
  start_date: null,
  end_date: null,
  approval:"default",
  approval_flg: 1,
};

const updateParams = {
  selectedRowKeys:[],
  allUpdateButtonStatus:false,
  updateList :[]
}

const initialState ={
  tagsList: [],
  activeTag: null,
  tagDate: initialEditParams,
  brands: [],
  categories: [],
  statusCountMap: {},
  editMode:"insert",
  editModalVisible: false,
  deleteModalVisible: false,
  updateModalVisible:false,
  refetchTags: true,
  searchParams: initialSearchParams,
  updateParams: updateParams,
  total: 0,
  pageSize: 0
}


export const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    clear: () => initialState,
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveDelete: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        deleted_flg: state.searchParams.deleted_flg === 1 ? 0 : 1,
      }
    }),
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          brand_code: [],
          status: payload,
        },
      };
    },
    newEditModal: (state, { payload }) => ({
      ...state,
      editMode: "insert",
      editModalVisible: true,
      activeTag: payload,
      tagDate: initialEditParams
    }),
    updateEditModal: (state, { payload }) => {
      return{
        ...state,
        activeTag: payload,
        editModalVisible: true,
        editMode: "update",
        tagDate: {
          ...payload,
          approval: payload?.approval_flg ?? payload?.approval_flg === 1 ? "approval" : "anApproval"
        }
      }
    },
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeTag: payload,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      activeTag: payload,
    }),
    openUpdateModal: (state, { payload }) => ({
      ...state,
      updateModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      tagDate:initialEditParams,
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
      refetchTags: true,
    }),
    closeUpdateModal: (state, { payload }) => ({
      ...state,
      updateModalVisible: false,
    }),
  },
  extraReducers: builder => {
    builder.addCase(tagsInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => ({
      ...state,
      tagsList: payload?.item ?? [],
      total: payload?.total || 0,
      pageSize: payload?.search_count  || 0,
      statusCountMap: payload?.status_count ?? {},
    }));
    builder.addCase(updateTag.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
      editModalVisible: false,
      deleteModalVisible: false,
    }));
    builder.addCase(allUpdateTag.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
      updateModalVisible:false,
    }));
  },
});

export const actions = tagsSlice.actions;

export default tagsSlice.reducer;

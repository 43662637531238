import React, { useEffect } from "react";
import { useFormik } from "formik";
import { DatePicker, Modal } from "antd";
import { pdfOutputTypeOptions } from "../../../constants/options";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import { useSelector, useDispatch } from "react-redux";
import InfomationModal from "../../../components/modal/infomationModal";
import {
  actions,
  fetchBrands,
  pdfDownload,
} from "../../../slices/pdfOutputSlice";
import GenernalInput from "../../generalInput";
import Button from "../../button";
import Yup from "../../../utils/yupUtil";
import "./style.scss";

const PdfOutputModal = () => {
  const { outputModalVisible, brands, errorModalVisible } = useSelector(state => state.pdfOutput);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      report_type: null,
      start_date: null,
      end_date: null,
      brand_code: [],
      end_rank: 10,
    },
    validationSchema: Yup.object({
      report_type: Yup.string().nullable().selected("出力種別"),
      brand_code: Yup.array().test(
        "brand_code",
        "ブランドを選択してください",
        value => value?.length > 0
      ),
      date: Yup.string()
        .nullable()
        .test(
          "date_required",
          "集計期間を選択してください",
          (_, context) => context.parent.start_date && context.parent.end_date
        )
        .test(
          "date_valid",
          "終了日には開始日以降の日付を指定して下さい",
          (_, context) =>
            context.parent.start_date &&
            context.parent.end_date &&
            context.parent.start_date.isBefore(context.parent.end_date)
        ),
      end_ranking: Yup.string()
        .nullable()
        .test(
          "ranking",
          "1以上の数値を入力してください",
          (_, context) => context.parent.end_rank > 1
        ),
    }),
    onSubmit: values => {
      const { start_date, end_date, ...rest } = values;
      dispatch(
        pdfDownload({
          ...rest,
          start_date: start_date.format("YYYY-MM-DD"),
          end_date: end_date.format("YYYY-MM-DD"),
        })
      );
    },
  });

  const onBack = () => {
    dispatch(actions.closeOutputModal());
    formik.resetForm();
  };

  useEffect(() => {
    outputModalVisible && dispatch(fetchBrands());
  }, [dispatch, outputModalVisible]);

  const { start_date, end_date } = formik.values;

  return (
    <Modal
      visible={outputModalVisible}
      closable={false}
      width={500}
      style={{ minWidth: 500 }}
      footer={null}
      onCancel={onBack}
    >
      <h2 className="pdf-title">PDF出力</h2>
      <div className="pdf-output-area">
        <form onSubmit={formik.handleSubmit}>
          <div className="pdf-input">
            <span className="input-label nf-analyze-required">出力種別</span>
            <GenernalInput
              options={pdfOutputTypeOptions}
              placeholder="出力種別を選択して下さい"
              styleType="inline-grey"
              mode={null}
              formik={formik}
              name={"report_type"}
              className={"input-report-type"}
            />
          </div>
          <div className="input-period">
            <span className="input-label nf-analyze-required">集計期間</span>
            <DatePicker
              format={"YYYY.MM.DD"}
              value={start_date}
              placeholder={null}
              suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              onChange={value =>
                handlerFormikFieldChange(formik, "start_date", value)
              }
            />
            <span className="period-range">~</span>
            <DatePicker
              format={"YYYY.MM.DD"}
              value={end_date}
              placeholder={null}
              suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
              getPopupContainer={triggerNode => triggerNode.parentNode}
              onChange={value =>
                handlerFormikFieldChange(formik, "end_date", value)
              }
            />
            <span className="validation-error validation-date">
              {formik.touched.start_date &&
                formik.touched.end_date &&
                formik.errors.date}
            </span>
          </div>
          <div className="pdf-input">
            <span className="input-label nf-analyze-required">ブランド</span>
            <GenernalInput
              options={brands}
              mode="multiple"
              placeholder="ブランドを選択して下さい"
              styleType="inline-grey"
              formik={formik}
              name={"brand_code"}
              className={"input-brand"}
            />
          </div>
          <div className="pdf-input input-ranking">
            <span className="input-label nf-analyze-required">出力順位</span>
            <span>1位</span>
            <span className="ranking-range">~</span>
            <GenernalInput
              styleType="block-grey"
              formik={formik}
              name={"end_rank"}
              textAfter={"位"}
            />
            <span className="validation-error error-ranking">
              {formik.touched.end_rank && formik.errors.end_ranking}
            </span>
          </div>
          <div className="output-tip">売上金額が高い順に出力されます</div>
          <div className="action-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <Button
              text="ダウンロード"
              type="submit"
              disabled={!formik.isValid}
            />
          </div>
        </form>
      </div>
      <InfomationModal
        modalVisible={errorModalVisible}
        item={{ information_title: "対象データがありません" }}
        setModalVisible={() => {
          dispatch(actions.closeErrorModal());
        }}
      />
    </Modal>
  );
};

export default PdfOutputModal;

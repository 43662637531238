/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchChats = /* GraphQL */ `
  query SearchChats(
    $filter: SearchableChatFilterInput
    $sort: [SearchableChatSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableChatAggregationInput]
  ) {
    searchChats(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        live_cd
        corporation_cd
        user_cd
        user_name
        like_name
        like_image_url
        signaling_text
        sender_flag
        signaling_flag
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        live_cd
        corporation_cd
        user_cd
        user_name
        like_name
        like_image_url
        signaling_text
        sender_flag
        signaling_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

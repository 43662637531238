import "./style.scss";
import Icon from "@ant-design/icons";
import IconImage from "../../../constants/Icons";
import dayjs from "dayjs";
import { toMoneyString } from "../../../utils/fnUtil";

/* ライブ売上実績のカード（2つ分） */

const SalesLiveResultDoubleCard = ({ leftdata, rightdata, lefttitle, righttitle }) => {
  const getJsxIcon = (after, before) => {
    let icon = "";
    if ((!after && after !== 0) || (!before && before !== 0)) {
      icon = IconImage.ImageIcon2;
    } else {
      before = Number(before);
      after = Number(after);
      if (before < after) icon = IconImage.ImageIcon1;
      if (before === after) icon = IconImage.ImageIcon2;
      if (before > after) icon = IconImage.ImageIcon3;
    }
    return (
      <Icon component={icon} style={{ fontSize: 16, cursor: "initial" }} className="display-icon" />
    );
  };
  const getGap = (after, before, preffix = '') => {
    if ((!after && after !== 0) || (!before && before !== 0)) return "";
    before = Number(before);
    after = Number(after);
    if (before === after) return '';
    if(after - before > 0) return `${preffix}${toMoneyString(after - before)}`;
    else return `- ${preffix}${toMoneyString(Math.abs(after - before))}`;
  };

  const createTableHeader = (term, title, money, image, gap) => {
    return (
      <>
        <td className="salesLiveResultDoubleCard-term">{term}</td>
        <td className="salesLiveResultDoubleCard-result">
          <div className="salesLiveResultDoubleCard-result-title">
            {title}
          </div>
          <div className="salesLiveResultDoubleCard-result-money" style={{ color: "#03C04A" }}>
            ¥ {money}
          </div>
        </td>
        <td className="salesLiveResultDoubleCard-image">
          <div>
            {image}
          </div>
        </td>
        <td className="salesLiveResultDoubleCard-diff">
          <div>
            <span>{gap}</span>
          </div>
        </td>
      </>
    );
  };

  const createTerms = (data) => {
    return (
      <>
        <span style={{ fontWeight: "bold", fontSize: 14 }}>
          MONTHLY
        </span>
        <span style={{ fontSize: 10, marginLeft: 5 }}>
          前月同期間対比
        </span>
        <div style={{ fontSize: 10, color: "#7B7B7B" }}>
          {data?.start_date && data?.end_date
            ? `${dayjs(data?.start_date).format("YYYY.MM.DD")}-${dayjs(data?.end_date).format("YYYY.MM.DD")}`
            : "-"}
        </div>
      </>
    );
  };

  const createTableRow = (title, value, image, gap) => {
    return (
      <>
        <td className="salesLiveResultDoubleCard-colum">
          <span>{title}</span>
        </td>
        <td className="salesLiveResultDoubleCard-value">
          <span>{value}</span>
        </td>
        <td className="salesLiveResultDoubleCard-image">
          {image}
        </td>
        <td className="salesLiveResultDoubleCard-diff">
          <span>{gap}</span>
        </td>
      </>
    );
  };

  return (
    <div className="salesLiveResultDoubleCard-card">
      <table>
        <tr className="salesLiveResultDoubleCard-main-table">
          {createTableHeader(
            createTerms(leftdata),
            lefttitle,
            toMoneyString(leftdata?.sales_amount) || "0",
            getJsxIcon(leftdata?.sales_amount, leftdata?.lastmonth_sales_amount),
            getGap(leftdata?.sales_amount, leftdata?.lastmonth_sales_amount, '¥'),
          )}
          {createTableHeader(
            '',
            righttitle,
            toMoneyString(rightdata?.sales_amount) || "0",
            getJsxIcon(rightdata?.sales_amount, rightdata?.lastmonth_sales_amount),
            getGap(rightdata?.sales_amount, rightdata?.lastmonth_sales_amount, '¥'),
          )}
        </tr>
      </table>
      <table className="salesLiveResultDoubleCard-analyze-table">
        <tr>
          {createTableRow(
            "再生回数 　　",
            toMoneyString(leftdata?.play_count) || "0",
            getJsxIcon(leftdata?.play_count, leftdata?.lastmonth_play_count),
            getGap(leftdata?.play_count, leftdata?.lastmonth_play_count),
          )}
          {createTableRow(
            "視聴者数 　　",
            toMoneyString(rightdata?.viewer_number) || "0",
            getJsxIcon(rightdata?.viewer_number, rightdata?.lastmonth_viewer_number),
            getGap(rightdata?.viewer_number, rightdata?.lastmonth_viewer_number),
          )}
        </tr>
        <tr>
          {createTableRow(
            "いいね 　　　",
            toMoneyString(leftdata?.like_count) || "0",
            getJsxIcon(leftdata?.like_count, leftdata?.lastmonth_like_count),
            getGap(leftdata?.like_count, leftdata?.lastmonth_like_count),
          )}
          {createTableRow(
            "いいね 　　　",
            toMoneyString(rightdata?.like_count) || "0",
            getJsxIcon(rightdata?.like_count, rightdata?.lastmonth_like_count),
            getGap(rightdata?.like_count, rightdata?.lastmonth_like_count),
          )}
        </tr>
        <tr>
          {createTableRow(
            "投稿数 　　　",
            toMoneyString(leftdata?.post_count) || "0",
            getJsxIcon(leftdata?.post_count, leftdata?.lastmonth_post_count),
            getGap(leftdata?.post_count, leftdata?.lastmonth_post_count),
          )}
          {createTableRow(
            "配信数 　　　",
            toMoneyString(rightdata?.post_count) || "0",
            getJsxIcon(rightdata?.post_count, rightdata?.lastmonth_post_count),
            getGap(rightdata?.post_count, rightdata?.lastmonth_post_count),
          )}
        </tr>
        <tr>
          {createTableRow(
            "1投稿最高売上",
            `¥ ${toMoneyString(leftdata?.max_sales) || "0"}`,
            getJsxIcon(leftdata?.max_sales, leftdata?.lastmonth_max_sales),
            getGap(leftdata?.max_sales, leftdata?.lastmonth_max_sales, '¥'),
          )}
          {createTableRow(
            "1配信最高売上",
            `¥ ${toMoneyString(rightdata?.max_sales) || "0"}`,
            getJsxIcon(rightdata?.max_sales, rightdata?.lastmonth_max_sales),
            getGap(rightdata?.max_sales, rightdata?.lastmonth_max_sales, '¥'),
          )}
        </tr>
      </table>
    </div>
  );
};

export default SalesLiveResultDoubleCard;

import { useEffect } from "react";
import { useFormik } from "formik";
import { Table, Image } from "antd";
import { useLocation } from "react-router";
import { statusMap } from "../../components/progressBar";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  cooridinateListInit,
  fetchCoordinateDetail,
  fetchCoordinateList,
  updateCoordinate,
} from "../../slices/coordinateSlice";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import StatusBox from "../../components/statusBox";
import GenernalInput from "../../components/generalInput";
import DeleteModal from "../../components/modal/deleteModal";
import CoordinateSignUpModal from "../../components/modal/coordinateSignUp";
import CoordinateEditModal from "../../components/modal/coordinateEditModal";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import moment from "moment";
import "./style.scss";

const CoordinateList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    shops,
    brands,
    coordinateList,
    total,
    statusAggregate,
    deleteModalVisible,
    deleteCoordinate = {},
    searchParams,
  } = useSelector(state => state.coordinate);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => dispatch(actions.saveSearchParam(values)),
  });

  useEffect(() => {
    dispatch(cooridinateListInit(location.state));
    return () => dispatch(actions.clear());
  }, [dispatch]);

  useUpdateEffect(
    () => dispatch(fetchCoordinateList(searchParams)),
    [searchParams, dispatch]
  );

  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const columns = [
    {
      title: () => (
        <>
          <span>コーディネートCD </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("coordinate_code")}
          >
            {formik.values.sort === "coordinate_code_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "coordinate_code",
      key: "coordinate_code",
      align: "center",
      width: TableColumnWidth.coordinate.cd,
    },
    {
      title: "コーディネート画像",
      dataIndex: "image_url",
      key: "image_url",
      render: image =>
        image ? (
          <Image src={image} width={100} height={100} />
        ) : (
          <Icon component={Icons.NoImgaeSquare1} style={{ fontSize: 100 }} />
        ),
      align: "center",
      width: TableColumnWidth.image,
    },
    {
      title: () => (
        <>
          <span>社員 </span>
          <span className="sort-span" onClick={createOnClikSort("user_name")}>
            {formik.values.sort === "user_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      align: "center",
      key: "user_name",
      width: TableColumnWidth.userName,
      render: coordinate => (
        <span className="display-linebreak">
          {coordinate.user_name
            ? `${coordinate.user_name}\n${
                coordinate.user_nickname ? `(${coordinate.user_nickname})` : ""
              }`
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>店舗 </span>
          <span className="sort-span" onClick={createOnClikSort("shop_name")}>
            {formik.values.sort === "shop_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "shop_name",
      dataIndex: "shop_name",
      align: "center",
      width: TableColumnWidth.shopName,
      render: shopName => shopName || "-",
    },
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {formik.values.sort === "brand_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "brand_name",
      dataIndex: "brand_name",
      align: "center",
      width: TableColumnWidth.brandName,
      render: brandName => brandName || "-",
    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("status")}>
            {formik.values.sort === "status_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "status_flg",
      dataIndex: "status_flg",
      align: "center",
      width: TableColumnWidth.status,
      render: status => (
        <span className="display-linebreak">
          {statusMap.find(s => s.value === status)?.status || "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>公開日時 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("publish_date")}
          >
            {formik.values.sort === "publish_date_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "accept_at",
      dataIndex: "accept_at",
      align: "center",
      width: TableColumnWidth.dateTime,
      render: accept_at => (
        <span className="display-linebreak">
          {accept_at
            ? `${moment(accept_at).format("YYYY.MM.DD")}\n${moment(
                accept_at
              ).format("HH:mm")}`
            : "-"}
        </span>
      ),
    },
    {
      title: "編集",
      align: "center",
      width: TableColumnWidth.icon,
      render: current => (
        <Icon
          component={Icons.EditIcon}
          style={{ fontSize: 25 }}
          onClick={() =>
            dispatch(
              fetchCoordinateDetail({
                coordinate_code: current.coordinate_code,
                brand_code: current.brand_code,
                origin_status_flg: current.status_flg,
              })
            )
          }
        />
      ),
    },
    {
      title: "削除",
      align: "center",
      width: TableColumnWidth.icon,
      render: current => (
        <Icon
          component={Icons.RemoveIcon}
          style={{ fontSize: 25 }}
          onClick={() => dispatch(actions.openDeleteModal(current))}
        />
      ),
    },
  ];
  return (
    <div className="content-body">
      <Header />
      <div className="coordinate-container nf-page">
        <h2>コーディネート一覧</h2>
        <div className="status-box-area">
          {statusMap.map((s, i) => (
            <StatusBox
              status={s.status}
              count={statusAggregate?.[s.value]}
              key={i}
              warning={i < 2}
              onClick={() => dispatch(actions.refineSearch(s.value))}
            />
          ))}
        </div>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <GenernalInput
              label="コーディネートCD"
              labelWidth="wider"
              placeholder="コーディネートCDを入力してください"
              formik={formik}
              name={"coordinate_code"}
            />
            <GenernalInput
              label="社員"
              placeholder="社員名またはサイト表示名で入力してください"
              formik={formik}
              name={"user_name"}
            />
            <GenernalInput
              label="店舗"
              labelWidth="wider"
              mode="multiple"
              name="shop_code"
              placeholder={"店舗を選択してください"}
              formik={formik}
              options={shops}
            />
            <GenernalInput
              label="ブランド"
              mode="multiple"
              name="brand_code"
              placeholder={"ブランドを選択してください"}
              formik={formik}
              options={brands}
            />
          </div>
          <Button text="検索" type="submit" />
        </form>
        <div className="table-header">
          <h3>検索結果一覧</h3>
          <Button
            text="新規作成"
            theme="white"
            width="wider"
            onClick={() => dispatch(actions.openSignUpModal())}
          />
        </div>

        <Table
          columns={columns}
          dataSource={coordinateList}
          onChange={pagination => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{ offsetHeader: 60 }}
        />
      </div>
      <CoordinateEditModal />
      <CoordinateSignUpModal />
      <DeleteModal
        modalVisible={deleteModalVisible}
        onCancel={() => dispatch(actions.closeDeleteModal())}
        item={"コーディネートCD"}
        itemValue={deleteCoordinate.coordinate_code}
        onOk={() => {
          dispatch(
            updateCoordinate({
              division: "delete",
              coordinate_code: deleteCoordinate.coordinate_code,
              brand_code: deleteCoordinate.brand_code,
              origin_status_flg:
                deleteCoordinate.status_flg === "4"
                  ? "3"
                  : deleteCoordinate.status_flg,
            })
          );
        }}
      />
      <Footer />
    </div>
  );
};

export default CoordinateList;

import "./style.scss";
import React, { useEffect, useState } from "react";
import { actions } from "../../slices/corporationSlice";
import { actions as pdfOutputActions } from "../../slices/pdfOutputSlice";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import CorporationEditModal from "../modal/corporationEditModal";
import PdfOutputModal from "../modal/pdfOutputModal";
import axios from "../../utils/axiosUtil";
import { updateAccountAuth } from "../../utils/fnUtil";
import store from "../../store";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";

const Humberger = ({ user }) => {
  const dispatch = useDispatch();

  const menuOptions = [
    {
      label: "コーディネート",
      name: "coordinate_flag",
      link: "/coordinate",
      status: "coordinate",
    },
    {
      label: "ブログ",
      name: "blog_flag",
      link: "/articles",
      status: "blog",
    },
    {
      label: "ライブ配信",
      name: "live_list_flag",
      link: "/live",
    },
    {
      label: "動画",
      name: "movie_list_flag",
      link: "/movie",
    },
    {
      label: "タグ",
      status: "tag",
      position: "top",
      subMenu: [
        {
          label: "カテゴリ一覧",
          name: "tag_category_flag",
          link: "/category",
        },
        {
          label: "タグ一覧",
          name: "tag_list_flag",
          link: "/tag",
        },
      ],
    },
    {
      label: "分析",
      position: "top",
      subMenu: [
        {
          label: "分析",
          position: "center",
          subMenu: [
            {
              label: "コーディネート分析",
              name: "coordinate_sales_flag",
              link: "/analyzeCoordinate",
            },
            {
              label: "ブログ分析",
              name: "blog_sales_flag",
              link: "/analyzeArticles",
            },
            {
              label: "ライブ配信分析",
              name: "live_sales_flag",
              link: "/analyzeLive",
            },
            {
              label: "動画分析",
              name: "movie_sales_flag",
              link: "/analyzeMovie",
            },
            {
              label: "店舗別分析",
              name: "shop_analysis_flag",
              link: "/analyzeShop",
            },
            {
              label: "ブランド別分析",
              name: "brand_analysis_flag",
              link: "/analyzeBrand",
            },
            {
              label: "社員コンテンツ別分析",
              name: "user_analysis_flag",
              link: "/analyzeContents",
            },
            {
              label: "社員SNS別分析",
              name: "user_sns_analysis_flag",
              link: "/analyzeSnsList",
            },
            {
              label: "ランキング",
              name: "ranking_flag",
              link: "/ranking",
            },
            {
              label: "ベスト表",
              name: "best_list_flag",
              onClick: () => dispatch(pdfOutputActions.openOutputModal()),
            },
          ],
        },
        {
          label: "SNS分析URL",
          position: "top",
          subMenu: [
            {
              label: "SNS分析URL作成",
              name: "sns_analysis_create_flag",
              link: "/shareUrl",
            },
            {
              label: "SNS分析URL一覧",
              name: "sns_analysis_list_flag",
              link: "/shareUrls",
            },
          ],
        },
      ],
    },
    {
      label: "お知らせ",
      position: "top",
      subMenu: [
        {
          label: "お知らせ登録・配信設定",
          name: "notified_flag",
          link: "/notification",
        },
        {
          label: "予約・配信済み一覧",
          name: "notice_list_flag",
          link: "/reserves",
        },
      ],
    },
    {
      label: "支払報酬設定/確認",
      position: "top",
      subMenu: [
        {
          label: "報酬レート設定",
          name: "reward_rate_setting_flag",
          link: "/reward",
        },
        {
          label: "支払報酬確認",
          name: "payment_reward_confirmation_flag",
          link: "/payments",
        },
      ],
    },
    {
      label: "マスタ",
      position: "top",
      subMenu: [
        {
          label: "社員マスタ",
          name: "user_mst_flag",
          link: "/staff",
        },
        {
          label: "店舗マスタ",
          name: "shop_mst_flag",
          link: "/Shops",
        },
        {
          label: "ブランドマスタ",
          name: "brand_mst_flag",
          link: "/brand",
        },
        {
          label: "商品マスタ",
          name: "product_master_flag",
          link: "/product",
        },
        {
          label: "法人マスタ",
          name: "corporation_master_flag",
          onClick: () => dispatch(actions.openEditModal()),
        },
      ],
    },
  ];

  const loginaccount = useSelector((state) => state.account);
  let account = updateAccountAuth(loginaccount);
  const [openMenu, setOpenMenu] = useState(false);
  const [manualManagementUrl, setManagementManualUrl] = useState(null);
  const [manualAppUrl, setAppManualUrl] = useState(null);
  const menuFunction = () => {
    setOpenMenu(!openMenu);
  };
  const handleClickAway = () => {
    setOpenMenu(false);
  };
  const [unapprovedStatus, setUnapprovedStatus] = useState({
    coordinate: false,
    blog: false,
    tag: false,
  });
  const openInNewTab = async (url) => {
    const response = await axios.get("tags/list", { params: { display_item : 0 } });
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  useEffect(() => {
    const { user_code: operator_code, corporation_code, token } = store.getState().account ?? {};
    setManagementManualUrl(`${process.env.REACT_APP_API_BASE_URL}/pdf/download/manual?operator_code=${operator_code}&request_from=2&token=${token}&corporation_code=${corporation_code}&content_type=2`);
    setAppManualUrl(`${process.env.REACT_APP_API_BASE_URL}/pdf/download/manual?operator_code=${operator_code}&request_from=2&token=${token}&corporation_code=${corporation_code}&content_type=1`);
    (async () => {
      const [coordinateRes, blogRes, tagRes] = await Promise.all([
        account?.user_auth === "0" ||
        account?.user_auth === "1" ||
        account?.user_auth === "2" ||
        account?.user_auth === "3"
          ? axios.get("coordinate/list", { params: { display_item : 0 }})
          : {},
        account?.user_auth === "0" ||
        account?.user_auth === "1" ||
        account?.user_auth === "2" ||
        account?.user_auth === "3"
          ? axios.get("articles/list", { params: { display_item : 0 } })
          : {},
        account?.user_auth === "0" ||
        account?.user_auth === "1" ||
        account?.user_auth === "2"
          ? axios.get("tags/list", { params: { display_item : 0 } })
          : {},
      ]);
      setUnapprovedStatus({
        coordinate: Number(coordinateRes?.status_aggregate?.["1"]) >= 1 ? true : false,
        blog: Number(blogRes.status_aggregate?.["1"]) >= 1 ? true : false,
        tag: Number(tagRes?.status_count?.unapproved) >= 1 ? true : false,
      });
    })();
  }, []);

  const createTopMenu = () => {
    const menu = [];
    for (const option of menuOptions) {
      if (option?.name) {
        menu.push(createChildMenu(account?.[option?.name], option?.label, option?.link, option?.onClick, option?.status));
      } else {
        menu.push(createParentMenu(option?.label, option?.subMenu, option?.status, option?.position));
      }
    }
    return menu;
  };

  const createParentMenu = (label, subMenu, status, position) => {
    const flagList = subMenu?.filter(e => e.subMenu ? e.subMenu?.filter(s => account?.[s.name] == 1).length > 0 : account?.[e.name] == 1);
    const isOnlySubMenu = flagList?.length == 1 && flagList?.[0]?.subMenu;
    const subMenuFlagList = isOnlySubMenu ? flagList?.[0]?.subMenu?.filter(e => account?.[e.name] == 1) : [];

    const top = (() => {
      const count = isOnlySubMenu ? subMenuFlagList?.length : flagList.length;
      const p = isOnlySubMenu ? flagList?.[0]?.position : position;
      if (p === "top") {
        return -40;
      } else if (p === "center") {
        return (count * 20 + 20) * -1;
      } else if (p === "bottom") {
        return (count * 40) * -1;
      }
    })();

    return flagList?.length > 0 ? (
        <li className={unapprovedStatus?.[status] ? "hmenu-li-style attentionFlg" : "hmenu-li-style"}>
          <span>{label}</span>
          <ul className="hmenu-ul-style" style={{ top }}>
            {isOnlySubMenu ?
              subMenuFlagList.map(s => createChildMenu(account?.[s?.name], s?.label, s?.link, s?.onClick, s?.status))
            : subMenu.map(e => {
              if (e.subMenu) {
                return createParentMenu(e?.label, e?.subMenu, e?.status, e?.position);
              } else {
                return createChildMenu(account?.[e?.name], e?.label, e?.link, e?.onClick, e?.status);
              }
            })}
          </ul>
        </li>
      ) : (
        <></>
      );
  };

  const createChildMenu = (flag, label, link, onClick, status) => {
    return flag ? (
        <li className={unapprovedStatus?.[status] ? "hmenu-li-style attentionFlg" : "hmenu-li-style"}>
          {onClick ? <button onClick={onClick} >{label}</button> : <Link to={link}>{label}</Link>}
        </li>
      ) : (
        <></>
      );
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="hmenu-wrapper">
          {openMenu
            ? <div class="hmenu-logo active" onClick={menuFunction}><span></span><span></span><span></span></div>
            : <div class="hmenu-logo" onClick={menuFunction}><span></span><span></span><span></span></div>
          }
          {openMenu ? (
            <div id="hmenu" className="openmenu">
              <ul className="hmenu-ul-style">
                <div className="top-menu">
                  {createTopMenu()}
                </div>
                <div className="bottom-menu">
                  <li className="terms">
                    <Link to="/termsOfService" className="link-text">利用規約</Link>
                  </li>
                  <li className="terms">
                    <Link to="/privacy">プライバシーポリシー</Link>
                  </li>
                  <div className="terms_icon">
                    <Tooltip overlayClassName="nf-manual" placement="topLeft" title={<div className="nf-manual-text">アプリ操作マニュアル</div>}>
                      <button className="link-text" onClick={()=>{openInNewTab(manualAppUrl)}}>
                        <Icon component={Icons.SPIcon} style={{ fontSize: 22.5, cursor: "pointer" }} disabled={true} />
                      </button>
                    </Tooltip>
                    <Tooltip overlayClassName="nf-manual" placement="topLeft" title={<div className="nf-manual-text">管理画面操作マニュアル</div>}>
                      <button className="link-text" onClick={()=>{openInNewTab(manualManagementUrl)}}>
                        <Icon component={Icons.PCIcon} style={{ fontSize: 22.5, cursor: "pointer" }} disabled={true} />
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </ul>
            </div>
          ) : (
            <div id="hmenu">
              <ul className="hmenu-ul-style">
                <div className="top-menu"></div>
                <div className="bottom-menu">
                  <li className="terms"></li>
                </div>
              </ul>
            </div>
          )}
          <CorporationEditModal />
          <PdfOutputModal />
        </div>
      </ClickAwayListener>
    </>
  );
};

export default Humberger;

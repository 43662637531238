import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";

export const fetchShareUrls = createAsyncThunk(
  "fetchShareUrls",
  async (params) => await axios.get("analyze/url/list", { params })
);

const initialSearchParams = {
  type: [],
  user_name: "",
  start_date: "",
  end_date: "",
  offset: 0,
  count: 10,
  sort: "create_date_desc",
};
const initialState = {
  shareUrlList: [],
  searchParams: initialSearchParams,
  total: 0,
};
export const shareUrlsSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShareUrls.fulfilled, (state, { payload }) => ({
      ...state,
      shareUrlList: payload?.item ?? [],
      total: payload?.total || 0,
    }));
  },
});

export const actions = shareUrlsSlice.actions;

export default shareUrlsSlice.reducer;

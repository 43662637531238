import { useEffect, useState } from "react";
import { Modal } from "antd";
import { actions } from "../../../slices/messageSlice";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../button";
import classNames from "classnames";
import "./style.scss";

const MessageModal = () => {
  const dispatch = useDispatch();

  const [ramdomKey, setRamdomKey] = useState(Math.random());

  const {
    messages = [],
    type,
    btnText,
    onCloseAction,
  } = useSelector(state => state.message ?? {});

  const onClose = () => {
    onCloseAction && dispatch(onCloseAction);
    dispatch(actions.reset());
  };

  useEffect(() => {
    if (messages.length > 0) setRamdomKey(Math.random());
  }, [messages]);

  return (
    <Modal
      visible={messages.length > 0}
      centered
      footer={null}
      closable={false}
      width={500}
      onCancel={onClose}
      zIndex={10000}
    >
      <div className="message-modal">
        <div className="message-type">{type}</div>
        <div className={classNames("messages", { "text-bold": !type })}>
          {messages.map((mes, i) => (
            <span key={i} className="message">
              {mes}
            </span>
          ))}
        </div>
        <Button text={btnText} onClick={onClose} autoFocus={true} key={ramdomKey} />
      </div>
    </Modal>
  );
};

export default MessageModal;

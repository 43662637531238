import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal, DatePicker, Image } from "antd";
import { actions as productActions } from "../../../slices/productSlice";
import { actions as messageActions } from "../../../slices/messageSlice";
import {
  swapArray,
  spliceArray,
  replaceArray,
  createTimeOptions,
  handlerFormikFieldChange,
} from "../../../utils/fnUtil";
import {
  actions,
  fetchTags,
  fetchTagCategory,
  updateLive,
  deleteLive,
} from "../../../slices/liveSlice";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import LiveProgressBar from "../../progressBar/live";
import BaseUpload from "../../baseUpload";
import GeneralInput from "../../generalInput";
import MemoInput from "../../generalInput/memoInput";
import MemoTextArea from "../../generalInput/memoTextArea";
import CusCheckbox from "../../cusCheckbox";
import SelectModal from "../selectModal";
import DeleteModal from "../deleteModal";
import ProductSearchModal from "../productSearchModal";
import DraggableContainer from "../liveSignUpModal/DraggableContainer";
import Yup from "../../../utils/yupUtil";
import moment from "moment";
import "./style.scss";

const LiveEditModal = () => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);

  const [activeIndex, setActiveIndex] = useState(0);

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const {
    editModalVisible,
    editDeleteModalVisible,
    tags = [],
    categories = [],
    activeLive,
  } = useSelector(state => state.live ?? {});

  const createUpdateParams = values => {
    const {
      start_date,
      start_hour,
      start_minute,
      end_date,
      end_hour,
      end_minute,
      opening_date,
      opening_hour,
      opening_minute,
      tag_code,
      product,
      ...rest
    } = values;

    return {
      ...rest,
      division: "update",
      tags: tag_code.map(t => t.tag_code),
      products: product.filter(p => p),
      start_datetime: `${start_date.format("YYYY-MM-DD")} ${start_hour}:${start_minute}:00`,
      end_datetime: `${end_date.format("YYYY-MM-DD")} ${end_hour}:${end_minute}:00`,
      opening_time: `${opening_date.format("YYYY-MM-DD")} ${opening_hour}:${opening_minute}:00`,
    };
  };

  const formik = useFormik({
    initialValues: {
      live_code: activeLive.live_code,
      streamer_info: activeLive.streamer_info,
      title: activeLive.title,
      description: activeLive.description,
      list_img_url: activeLive.list_img_url,
      detail_img_url: activeLive.detailed_img_url,
      live_flag: activeLive.live_flag,
      start_date: activeLive.start_datetime && moment(activeLive.start_datetime),
      start_hour: activeLive.start_datetime && moment(activeLive.start_datetime).format("HH"),
      start_minute: activeLive.start_datetime && moment(activeLive.start_datetime).format("mm"),
      end_date: activeLive.end_datetime && moment(activeLive.end_datetime),
      end_hour: activeLive.end_datetime && moment(activeLive.end_datetime).format("HH"),
      end_minute: activeLive.end_datetime && moment(activeLive.end_datetime).format("mm"),
      opening_date: activeLive.opening_time && moment(activeLive.opening_time),
      opening_hour: activeLive.opening_time && moment(activeLive.opening_time).format("HH"),
      opening_minute: activeLive.opening_time && moment(activeLive.opening_time).format("mm"),
      received_time: activeLive.received_time && moment(activeLive.received_time).format("YYYY-MM-DD HH:mm:00"),
      distribution_method_flag: activeLive.distribution_method_flag,
      archive_comment_flag: activeLive.archive_comment_flag,
      telop_flag: activeLive.telop_flag,
      product: replaceArray(Array(30).fill(""), 0, activeLive?.product?.map(p => ({ ...p, color_name: null, size_name: null  })) ?? []),
      tag_code: activeLive.tags ?? [],
      status_flag: activeLive.status_flag,
      tag_category_code: null,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      streamer_info: Yup.array()
        .of(
          Yup.object().shape({
            display_order: Yup.string().nullable().selected(),
          })
        ),
      title: Yup.string().required().max(30),
      description: Yup.string().required(),
      list_img_url: Yup.string().nullable().required(),
      detail_img_url: Yup.string().nullable().required(),
      start_date: Yup.string()
        .nullable()
        .test(
          "start_date",
          "日時を選択してください",
          (value, testContext) =>
            value && testContext.parent.start_hour && testContext.parent.start_minute
        ),
      end_date: Yup.string()
        .nullable()
        .test(
          "end_date",
          "日時を選択してください",
          (value, testContext) =>
            value && testContext.parent.end_hour && testContext.parent.end_minute
        ),
      opening_date: Yup.string()
        .nullable()
        .test(
          "opening_date",
          "日時を選択してください",
          (value, testContext) =>
            value && testContext.parent.opening_hour && testContext.parent.opening_minute
        ),
    }),
    onSubmit: values => {
      // バリデーションチェック
      const {
        start_date,
        start_hour,
        start_minute,
        end_date,
        end_hour,
        end_minute,
        opening_date,
        opening_hour,
        opening_minute,
      } = values;

      const start_datetime = moment(start_date).set('h', Number(start_hour)).set('m', Number(start_minute)).set('s', 0);
      const end_datetime = moment(end_date).set('h', Number(end_hour)).set('m', Number(end_minute)).set('s', 0);
      const opening_datetime = moment(opening_date).set('h', Number(opening_hour)).set('m', Number(opening_minute)).set('s', 0);

      // 配信開始/終了日時範囲チェック
      if (start_datetime.diff(end_datetime, 'm') < -120) {
        dispatch(
          messageActions.setMessage({
            btnText: "閉じる",
            messages: ["配信時間は120分以内で入力してください"],
          })
        );
        return;
      }

      // 公開日時/配信開始日時間隔チェック
      if (beforeLive) {
        if (-30 < opening_datetime.diff(start_datetime, 'm')) {
          dispatch(
            messageActions.setMessage({
              btnText: "閉じる",
              messages: ["配信開始日時は公開日時から30分以上間隔を空けて入力してください"],
            })
          );
          return;
        }
      }

      dispatch(updateLive(createUpdateParams(values)));
    },
  });

  const {
    values: {
      tag_category_code,
      tag_code,
      status_flag,
      list_img_url,
      detail_img_url,
      live_flag,
      start_date,
      start_hour,
      start_minute,
      end_date,
      end_hour,
      end_minute,
      opening_date,
      opening_hour,
      opening_minute,
      distribution_method_flag,
      archive_comment_flag,
      telop_flag,
      product,
      description,
      streamer_info,
    },
    setFieldValue,
  } = formik;

  const isAuth = account?.user_auth != 4;
  const beforeLive = live_flag == 0 && ["00", "01", "02"].includes(status_flag);

  const handleFileChange = (url, name) => handlerFormikFieldChange(formik, name, url);

  useEffect(() => {
    tag_category_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code: activeLive?.streamer_info?.[0].brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, activeLive, dispatch]);

  useEffect(() => {
    editModalVisible && dispatch(productActions.saveBrand(activeLive?.streamer_info?.[0].brand_code));
    editModalVisible &&
      dispatch(
        fetchTagCategory({
          brand_code: activeLive?.streamer_info?.[0].brand_code,
          sort: "tag_category_code_asc",
          count: 0,
        })
      );
  }, [activeLive, editModalVisible, dispatch]);

  const onBack = () => {
    dispatch(actions.closeEditModal());
    formik.resetForm();
  };

  const afterClose = () => {
    dispatch(actions.clearActiveLive());
    formik.resetForm();
  };

  const numberSelect = (start, end) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push({ label: `${i}`, value: `${i}` });
    }
    return list;
  };

  // 公開日時（日付）入力時の補正処理
  const validOpeningDate = input => {
    if (!beforeLive) return input;
    if (!input || (!start_date && !end_date)) return input;
    validOpeningDateTime(input, opening_hour, opening_minute);
    return input;
  };

  // 公開日時（時）入力時の補正処理
  const validOpeningHour = input => {
    if (!beforeLive) return input;
    if (!input || !opening_date || (!start_date && !end_date)) return input;
    validOpeningDateTime(opening_date, input, opening_minute);
    return input;
  };

  // 公開日時（分）入力時の補正処理
  const validOpeningMinute = input => {
    if (!beforeLive) return input;
    if (!input || !opening_date || (!start_date && !end_date)) return input;
    validOpeningDateTime(opening_date, opening_hour, input);
    return input;
  };

  const validOpeningDateTime = (opening_date, opening_hour, opening_minute) => {
    const start_datetime = createMomentDate(start_date, start_hour, start_minute);
    const end_datetime = createMomentDate(end_date, end_hour, end_minute);
    const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
    if (moment(start_datetime).isSameOrBefore(moment(opening_datetime))) {
      opening_datetime.add(30, 'm');
      setFieldValue("start_date", opening_datetime);
      setFieldValue("start_hour", opening_datetime.format("HH"));
      setFieldValue("start_minute", opening_datetime.format("mm"));
      if (moment(end_datetime).isSameOrBefore(moment(opening_datetime))) {
        opening_datetime.add(30, 'm');
        setFieldValue("end_date", opening_datetime);
        setFieldValue("end_hour", opening_datetime.format("HH"));
        setFieldValue("end_minute", opening_datetime.format("mm"));
      }
    } else if (moment(end_datetime).isSameOrBefore(moment(opening_datetime))) {
      opening_datetime.add(60, 'm');
      setFieldValue("end_date", opening_datetime);
      setFieldValue("end_hour", opening_datetime.format("HH"));
      setFieldValue("end_minute", opening_datetime.format("mm"));
    }
  };

  // 開始日時（日付）入力時の補正処理
  const validStartDate = input => {
    if (!input || (!opening_date && !end_date)) return input;
    if (validStartDateTime(input, start_hour, start_minute)) {
      const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
      opening_datetime.add(30, 'm');
      setFieldValue("start_hour", opening_datetime.format("HH"));
      setFieldValue("start_minute", opening_datetime.format("mm"));
      return opening_datetime;
    }
    return input;
  };

  // 開始日時（時）入力時の補正処理
  const validStartHour = input => {
    if (!input || !start_date || (!opening_date && !end_date)) return input;
    if (validStartDateTime(start_date, input, start_minute)) {
      const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
      opening_datetime.add(30, 'm');
      setFieldValue("start_date", opening_datetime);
      setFieldValue("start_minute", opening_datetime.format("mm"));
      return opening_datetime.format("HH");
    }
    return input;
  };

  // 開始日時（分）入力時の補正処理
  const validStartMinute = input => {
    if (!input || !start_date || (!opening_date && !end_date)) return input;
    if (validStartDateTime(start_date, start_hour, input)) {
      const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
      opening_datetime.add(30, 'm');
      setFieldValue("start_date", opening_datetime);
      setFieldValue("start_hour", opening_datetime.format("HH"));
      return opening_datetime.format("mm");
    }
    return input;
  };

  const validStartDateTime = (start_date, start_hour, start_minute) => {
    const start_datetime = createMomentDate(start_date, start_hour, start_minute);
    const end_datetime = createMomentDate(end_date, end_hour, end_minute);
    const opening_datetime = createMomentDate(opening_date, opening_hour, opening_minute);
    if (moment(end_datetime).isSameOrBefore(start_datetime)) {
      start_datetime.add(30, 'm');
      setFieldValue("end_date", start_datetime);
      setFieldValue("end_hour", start_datetime.format("HH"));
      setFieldValue("end_minute", start_datetime.format("mm"));
    }
    return moment(start_datetime).isSameOrBefore(moment(opening_datetime));
  };

  // 終了日時（日付）入力時の補正処理
  const validEndDate = input => {
    if (!input || !start_date) return input;
    if (validEndDateTime(input, end_hour, end_minute)) {
      return input;
    } else {
      const start_datetime = createMomentDate(start_date, start_hour, start_minute);
      start_datetime.add(30, 'm');
      setFieldValue("end_hour", start_datetime.format("HH"));
      setFieldValue("end_minute", start_datetime.format("mm"));
      return start_datetime;
    }
  };

  // 終了日時（時）入力時の補正処理
  const validEndHour = input => {
    if (!input || !start_date || !end_date) return input;
    if (validEndDateTime(end_date, input, end_minute)) {
      return input;
    } else {
      const start_datetime = createMomentDate(start_date, start_hour, start_minute);
      start_datetime.add(30, 'm');
      setFieldValue("end_date", start_datetime);
      setFieldValue("end_minute", start_datetime.format("mm"));
      return start_datetime.format("HH");
    }
  };

  // 終了日時（分）入力時の補正処理
  const validEndMinute = input => {
    if (!input || !start_date || !end_date) return input;
    if (validEndDateTime(end_date, end_hour, input)) {
      return input;
    } else {
      const start_datetime = createMomentDate(start_date, start_hour, start_minute);
      start_datetime.add(30, 'm');
      setFieldValue("end_date", start_datetime);
      setFieldValue("end_hour", start_datetime.format("HH"));
      return start_datetime.format("mm");
    }
  };

  const validEndDateTime = (end_date, end_hour, end_minute) => {
    const start_datetime = createMomentDate(start_date, start_hour, start_minute);
    const end_datetime = createMomentDate(end_date, end_hour, end_minute);
    return moment(start_datetime).isSameOrBefore(moment(end_datetime));
  };

  const createMomentDate = (date, hour, minute) => moment(date).set('h', Number(hour || 0)).set('m', Number(minute || 0)).set('s', 0);

  const createInfoArea = (data, index) => {
    return (
      <div key={index + 1}>
        <div className="text-bold normal-label">
          配信者{index + 1}{index === 0 ? "（主催者）" : ""}
        </div>
        <div className="streamer-detail">
          <span>ブランド：{data.brand_name}</span>
          <span>店舗：{data.shop_name}</span>
          <span>配信者：{data.user_name}</span>
          <span>売上分配率：{data.distribution_rate}%</span>
          <GeneralInput
            label="表示優先順"
            disabled={!isAuth}
            options={numberSelect(1, 100)}
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'display_order'}
            extraOnChange={(_, v) => {
            }}
            formik={formik}
          />
        </div>
      </div>
    );
  };

  const progressStatus = {
    "00": "0",
    "01": "1",
    "02": "2",
    "03": "3",
    "04": "4",
    "10": "4",
    "11": "4",
    "12": "4",
  }[activeLive?.status_flag];

  return (
    <Modal
      visible={editModalVisible}
      footer={null}
      closable={false}
      width={1000}
      style={{ minWidth: 1000 }}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
    >
      <LiveProgressBar activeProgress={progressStatus} />
      <div className="live-edit">
        <div className="live-edit-area">
          <span className="text-title text-bold">ライブ配信ルーム編集</span>
          <span className="nf-text-required-style">　*は必須項目です</span>
          <div className="text-bold" style={{ marginTop: '10px', marginBottom: '10px' }}>
            配信CD {activeLive.live_code}
          </div>
          <div className="text-bold text-required">投稿者情報</div>
          <div className="info-area">
            {streamer_info?.map(createInfoArea)}
          </div>

          <div className="title-area">
            <div className="text-bold text-required">タイトル</div>
            <MemoInput
              placeholder="タイトルを入力してください"
              disabled={!isAuth}
              styleType="block-grey-normal"
              name="title"
              formik={formik}
              scrollQuery=".ant-modal-body"
              error={v => !v ? "必須項目です" : v.length > 30 ? "30文字以内で入力してください" : ""}
            />
          </div>

          <div className="description-area">
            <span className="text-bold text-required">説明</span>
            <MemoTextArea
              placeholder="説明を入力してください"
              disabled={!isAuth}
              name="description"
              formik={formik}
              scrollQuery=".ant-modal-body"
              error={v => !v ? "必須項目です" : ""}
            />
          </div>

          <div className="image-area">
            <span className="text-bold text-required">サムネイル画像</span>
            <span className="text-tip">
              ※画像を選択してください
            </span>
            <div className="image-select-area">
              <div>
                <span className="validation-error" style={{ position: "absolute", left: "130px" }}>
                  {formik.touched.list_img_url && formik.errors.list_img_url}
                </span>
                <div className="image-select">
                  {list_img_url && isAuth && (
                    <Icon
                      component={Icons.CloseIcon}
                      className="close-icon"
                      onClick={e => {
                        handlerFormikFieldChange(formik, "list_img_url", "");
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {list_img_url ? (
                    <Image src={list_img_url} width={150} height={200} preview={false} />
                  ) : (
                    <>
                      <BaseUpload onChange={url => handleFileChange(url, "list_img_url")}>
                        <Button text="画像を追加" theme="white" />
                      </BaseUpload>
                    </>
                  )}
                </div>
                <div className="image-select-label">一覧</div>
              </div>
              <div>
                <span className="validation-error" style={{ position: "absolute", left: "320px" }}>
                  {formik.touched.detail_img_url && formik.errors.detail_img_url}
                </span>
                <div className="image-select">
                  {detail_img_url && isAuth && (
                    <Icon
                      component={Icons.CloseIcon}
                      className="close-icon"
                      onClick={e => {
                        handlerFormikFieldChange(formik, "detail_img_url", "");
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {detail_img_url ? (
                    <Image src={detail_img_url} width={150} height={200} preview={false} />
                  ) : (
                    <>
                      <BaseUpload onChange={url => handleFileChange(url, "detail_img_url")}>
                        <Button text="画像を追加" theme="white" />
                      </BaseUpload>
                    </>
                  )}
                </div>
                <div className="image-select-label">詳細</div>
              </div>
            </div>
          </div>

          <div className="delivery-public-area">
            <span className="text-bold text-required">公開設定</span>
            <div className="public-date-select">
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "315px" }}>
                {(formik.touched.start_date ||
                  formik.touched.start_hour ||
                  formik.touched.start_minute) &&
                  formik.errors.start_date}
              </span>
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "690px" }}>
                {(formik.touched.end_date ||
                  formik.touched.end_hour ||
                  formik.touched.end_minute) &&
                  formik.errors.end_date}
              </span>
              <span className="text-required">配信日時</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={start_date}
                disabled={!isAuth || !beforeLive}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value => handlerFormikFieldChange(formik, "start_date", validStartDate(value))}
              />
              <GeneralInput
                mode={null}
                disabled={!isAuth || !beforeLive}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"start_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
                customOnChange={value => handlerFormikFieldChange(formik, "start_hour", validStartHour(value))}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={!isAuth || !beforeLive}
                options={createTimeOptions(60)}
                name={"start_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
                customOnChange={value => handlerFormikFieldChange(formik, "start_minute", validStartMinute(value))}
              />
              <span>　～</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={end_date}
                disabled={!isAuth || !beforeLive}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value => handlerFormikFieldChange(formik, "end_date", validEndDate(value))}
              />
              <GeneralInput
                mode={null}
                disabled={!isAuth || !beforeLive}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"end_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
                customOnChange={value => handlerFormikFieldChange(formik, "end_hour", validEndHour(value))}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={!isAuth || !beforeLive}
                options={createTimeOptions(60)}
                name={"end_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
                customOnChange={value => handlerFormikFieldChange(formik, "end_minute", validEndMinute(value))}
              />
            </div>
            <div className="public-date-select">
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "315px" }}>
                {(formik.touched.opening_date ||
                  formik.touched.opening_hour ||
                  formik.touched.opening_minute) &&
                  formik.errors.opening_date}
              </span>
              <span className="text-required">公開日時</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={opening_date}
                disabled={!isAuth || (!beforeLive && live_flag != 1)}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value => handlerFormikFieldChange(formik, "opening_date", validOpeningDate(value))}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={!isAuth || (!beforeLive && live_flag != 1)}
                options={createTimeOptions(24)}
                name={"opening_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
                customOnChange={value => handlerFormikFieldChange(formik, "opening_hour", validOpeningHour(value))}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={!isAuth || (!beforeLive && live_flag != 1)}
                options={createTimeOptions(60)}
                name={"opening_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
                customOnChange={value => handlerFormikFieldChange(formik, "opening_minute", validOpeningMinute(value))}
              />
            </div>
          </div>
          <div className="check-area">
            <span className="text-bold text-required">配信方法</span>
            <CusCheckbox
              options={[
                { label: "管理画面", value: 0},
                { label: "アプリ", value: 1},
              ]}
              disabled={!isAuth || !beforeLive}
              value={[distribution_method_flag]}
              name={"distribution_method_flag"}
              onChange={(val) => {
                handlerFormikFieldChange(formik, "distribution_method_flag", Number(val?.[1]));
              }}
              max={2}
            />
          </div>
          <div className="check-area">
            <span className="text-bold">配信詳細設定</span>
            <div className="nf-input-wrapper">
              <CusCheckbox
                options={[
                  { label: "アーカイブにコメントを表示", value: 1},
                ]}
                disabled={!isAuth}
                value={[archive_comment_flag]}
                name={"archive_comment_flag"}
                onChange={(val) => {
                  handlerFormikFieldChange(formik, "archive_comment_flag", archive_comment_flag == 0 ? 1 : 0);
                }}
                max={2}
              />
            </div>
            <div className="nf-input-wrapper">
              <CusCheckbox
                options={[
                  { label: "ライブ画面にテロップを流す", value: 1},
                ]}
                value={[telop_flag]}
                disabled={!isAuth || !beforeLive}
                name={"telop_flag"}
                onChange={(val) => {
                  handlerFormikFieldChange(formik, "telop_flag", telop_flag == 0 ? 1 : 0);
                }}
                max={2}
              />
              <span className="valid-txt">{formik?.errors?.cond || ""}</span>
            </div>
          </div>

          <div>
            <span className="text-bold">商品情報</span>
            <span className="text-tip">
              <span className="text-subtip">
                30枚まで登録可、ドラッグで入れ替え可。
              </span>
            </span>
            <span className="validation-error error-images">
              {formik.touched.product && formik.errors.product}
            </span>
          </div>
          <DraggableContainer
            items={product}
            isProduct={true}
            onClick={index => {
              setProductSearchModalVisible(true);
              setActiveIndex(index);
            }}
            onCancle={(e, index) => {
              handlerFormikFieldChange(formik, "product", spliceArray(product, index, null));
              e.stopPropagation();
            }}
            onExchange={(dragIndex, dropIndex) => {
              handlerFormikFieldChange(formik, "product", swapArray(product, dragIndex, dropIndex));
            }}
            contentDisable={!isAuth || !beforeLive}
            editable={isAuth && beforeLive}
          />

          <div class="tag-area">
            <div className="tag-title">
              <span className="text-bold">タグ情報</span>
              <span className="text-tip">
                <span className="text-subtip">
                  20個まで登録可。
                </span>
              </span>
            </div>
            <>
              <GeneralInput
                label="カテゴリ"
                placeholder="選択してください"
                styleType="block-grey-normal"
                labelTextAlign="left"
                options={categories}
                mode={null}
                name={"tag_category_code"}
                formik={formik}
                disabled={!isAuth}
              />
              <div>
                <span className="text-tag">タグ</span>
                <Button
                  disabled={!isAuth || !tag_category_code}
                  text="タグを選択"
                  theme="white"
                  onClick={() => setSelectTagModalVisible(true)}
                />
                <span className="validation-error">
                  {formik.touched.tag_code && formik.errors.tag_code}
                </span>
              </div>
            </>
            <div className="tags-container">
              {tag_code.map((t, i) => (
                <div className="tag" key={i}>
                  {t.tag_name}
                  {isAuth && <Icon
                    className="close-icon"
                    component={Icons.CloseIcon}
                    onClick={() => {
                      handlerFormikFieldChange(
                        formik,
                        "tag_code",
                        tag_code.filter(i => i.tag_code !== t.tag_code)
                      );
                    }}
                  />}
                </div>
              ))}
            </div>
          </div>

          <div className="action-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            {isAuth && <Button text="更新" onClick={formik.handleSubmit} disabled={!formik.isValid} />}
            {isAuth && <Button text="配信ルームを削除する" theme="link" onClick={() => dispatch(actions.openEditDeleteModal())} />}
          </div>
        </div>

        <SelectModal
          checkBoxName={`${activeLive.live_code}-tags}`}
          modalVisible={selectTagModalVisible}
          onCancel={() => setSelectTagModalVisible(false)}
          onOk={value => {
            handlerFormikFieldChange(
              formik,
              "tag_code",
              [
                ...tag_code,
                ...tags.filter(
                  t =>
                    value.includes(t.value) &&
                    !tag_code.some(tag => t.value === tag.tag_code)
                ),
              ].filter(
                tag =>
                  !tags.map(t => t.value).includes(tag.tag_code) ||
                  value.includes(tag.tag_code)
              )
            );
            setSelectTagModalVisible(false);
          }}
          title={"タグを選択してください"}
          value={tag_code.map(t => t.tag_code)}
          options={tags}
          max={20}
        />
      </div>
      <ProductSearchModal
        group={true}
        visible={productSearchModalVisible}
        onCancel={() => setProductSearchModalVisible(false)}
        onOk={selectedProduct => {
          handlerFormikFieldChange(
            formik,
            "product",
            spliceArray(product, activeIndex, selectedProduct)
          );
          setProductSearchModalVisible(false);
        }}
      />
      <DeleteModal
        modalVisible={editDeleteModalVisible}
        onCancel={() => dispatch(actions.closeEditDeleteModal())}
        item={"配信CD"}
        itemValue={activeLive.live_code}
        onOk={() => {
          dispatch(
            deleteLive({
              division: "delete",
              live_code: activeLive.live_code,
              live_flag: activeLive.live_flag,
            })
          );
        }}
      />
    </Modal>
  );
};

export default LiveEditModal;

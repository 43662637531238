import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import { actions as messageActions } from "./messageSlice";
import axios from "../utils/axiosUtil";

export const cooridinateListInit = createAsyncThunk(
  "cooridinateListInit",
  async (params, { dispatch }) => {
    dispatch(loadingActions.multiLoadingOn());
    const [coordinateRes, shopsRes, brandsRes] = await Promise.all([
      axios.get("coordinate/list", {
        params: {
          status_flg: params?.status_code ? params.status_code : 999,
          sort: "coordinate_code_desc",
          count: 10,
        },
      }),
      axios.get("shops/list", { params: { count: 0, sort: "shop_code_asc" } }),
      axios.get("brands/list", {
        params: { count: 0, sort: "brand_name_asc" },
      }),
    ]);
    dispatch(loadingActions.multiLoadingOff());

    return {
      statusAggregate: coordinateRes.status_aggregate ?? {},
      total: coordinateRes.total,
      coordinateList: coordinateRes?.item ?? [],
      shops: (shopsRes?.item ?? []).map(s => ({
        ...s,
        label: s.shop_code + ":" + s.shop_name,
        value: s.shop_code,
      })),
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
    };
  }
);

export const fetchCoordinateList = createAsyncThunk(
  "fetchCoordinateList",
  async params => await axios.get("coordinate/list", { params })
);

export const fetchCoordinateDetail = createAsyncThunk(
  "fetchCoordinateDetail",
  async params => await axios.get("coordinate/detail", { params })
);

export const fetchTags = createAsyncThunk(
  "fetchTags",
  async params => {
    params.custom_sort_flg= true;
    return await axios.get("tags/list", { params });
  }
);

export const fetchTagCategory = createAsyncThunk(
  "fetchTagCategory",
  async params => await axios.get("tags/category/list", { params })
);

export const fetchStaffs = createAsyncThunk(
  "fetchStaffs",
  async params => await axios.get("staff/list", { params })
);

export const fetchMallBrand = createAsyncThunk(
    "fetchMallBrand",
    async params => await axios.get("/brands/list",{ params })
);

export const updateCoordinate = createAsyncThunk(
  "updateCoordinate",
  async params => await axios.post("coordinate/update", params)
);
export const registCoordinate = createAsyncThunk(
  "registCoordinate",
  async (params, { dispatch }) => {
    await axios.post("coordinate/update", params);

    dispatch(
      messageActions.setMessage({
        btnText: "閉じる",
        messages: ["設定が完了しました"],
        onCloseAction: actions.closeSignUpModal(),
      })
    );
  }
);
export const registDraftCoordinate = createAsyncThunk(
  "registDraftCoordinate",
  async (params, { dispatch }) => {
    await axios.post("coordinate/update", params);

    dispatch(
      messageActions.setMessage({
        btnText: "閉じる",
        messages: ["保存しました"],
        onCloseAction: actions.closeSignUpModal(),
      })
    );
  }
);
export const saveDraftCoordinate = createAsyncThunk(
  "saveDraftCoordinate",
  async (params, { dispatch }) => {
    await axios.post("coordinate/update", params);

    dispatch(
      messageActions.setMessage({
        btnText: "閉じる",
        messages: ["保存しました"],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);
export const approvalCoordinate = createAsyncThunk(
  "approvalCoordinate",
  async (params, { dispatch }) => {
    await axios.post("coordinate/approval", params);

    dispatch(
      messageActions.setMessage({
        btnText: "戻る",
        messages: ["更新が完了しました"],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);

export const updateApprovalCoordinate = createAsyncThunk(
  "updateApprovalCoordinate",
  async ({ updateParams, approvalParams }, { dispatch }) => {
    await axios.post("coordinate/update", updateParams);

    await axios.post("coordinate/approval", approvalParams);

    dispatch(
      messageActions.setMessage({
        btnText: "戻る",
        messages: ["更新が完了しました"],
        onCloseAction: actions.closeEditModal(),
      })
    );
  }
);

export const uploadImage = createAsyncThunk(
  "uploadImage",
  async params => await axios.post("image/upload?img", params)
);

export const saveBaseInfo = createAsyncThunk(
  "saveBaseInfo",
  async (params, { dispatch, getState }) => {
    dispatch(loadingActions.multiLoadingOn());

    dispatch(
      fetchTagCategory({
        brand_code: params.brand_code,
        count: 0,
        sort: "tag_category_code_asc",
      })
    );

    const updateRes = await axios.post("coordinate/update", params);

    dispatch(fetchCoordinateList(getState().coordinate.searchParams)).then(() =>
      dispatch(loadingActions.multiLoadingOff())
    );

    return updateRes;
  }
);

const initialSearchParams = {
  user_name: "",
  brand_code: [],
  shop_code: [],
  status_flg: 999,
  offset: 0,
  count: 10,
  sort: "coordinate_code_desc",
};

const initialState = {
  coordinateList: [],
  activeCoordinate: {},
  deleteCoordinate: {},
  brands: [],
  shops: [],
  staffs: [],
  staffsReady: false,
  coordinates: [],
  categories: [],
  tags: [],
  mallBrandList:[],
  statusAggregate: {},
  currentCoordinateCode: "",
  editModalVisible: false,
  deleteModalVisible: false,
  signUpModalVisible: false,
  coordinateSearchModalVisible: false,
  confirmModalVisible: false,
  searchParams: initialSearchParams,
  total: 0,
};

export const coordinateSlice = createSlice({
  name: "coordinate",
  initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
        status_flg: 999,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    refineSearch: (state, { payload }) => {
      return {
        ...state,
        searchParams: {
          ...initialSearchParams,
          sort: state.searchParams.sort,
          status_flg: payload,
        },
      };
    },
    openEditModal: (state, { payload }) => ({
      ...state,
      editModalVisible: true,
      activeCoordinate: payload,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      deleteModalVisible: true,
      deleteCoordinate: payload,
    }),
    openEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: true,
    }),
    openSignUpModal: state => ({
      ...state,
      signUpModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
      activeCoordinate: {},
      mallBrandList: [],
    }),
    closeDeleteModal: state => ({
      ...state,
      deleteModalVisible: false,
    }),
    closeEditDeleteModal: state => ({
      ...state,
      editDeleteModalVisible: false,
    }),
    closeSignUpModal: state => ({
      ...state,
      signUpModalVisible: false,
      currentCoordinateCode: "",
      staffsReady: false,
      mallBrandList: [],
    }),
    clearCurrentCoordinateCode: state => ({
      ...state,
      currentCoordinateCode: "",
      staffsReady: false,
    }),
    openConfirmModal: state => ({
      ...state,
      confirmModalVisible: true,
    }),
    closeConfirmModal: state => ({
      ...state,
      confirmModalVisible: false,
    }),
    clearActiveCoordinate: state => ({
      ...state,
      activeCoordinate: {},
      staffsReady: false,
    }),
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(cooridinateListInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchCoordinateList.fulfilled, (state, { payload }) => ({
      ...state,
      coordinateList: payload?.item ?? [],
      total: payload?.total || 0,
      statusAggregate: payload?.status_aggregate ?? {},
    }));
    builder.addCase(fetchCoordinateDetail.fulfilled, (state, { payload }) => ({
      ...state,
      activeCoordinate: payload?.item ?? {},
      editModalVisible: true,
    }));
    builder.addCase(updateCoordinate.fulfilled, state => ({
      ...state,
      editModalVisible: false,
      deleteModalVisible: false,
      signUpModalVisible: false,
      editDeleteModalVisible: false,
      confirmModalVisible: false,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(saveBaseInfo.fulfilled, (state, { payload }) => ({
      ...state,
      currentCoordinateCode: payload.coordinate_code,
    }));
    builder.addCase(saveDraftCoordinate.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(registCoordinate.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(registDraftCoordinate.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(approvalCoordinate.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(updateApprovalCoordinate.fulfilled, state => ({
      ...state,
      searchParams: { ...state.searchParams },
    }));
    builder.addCase(fetchStaffs.fulfilled, (state, { payload }) => ({
      ...state,
      staffs: (payload?.item ?? []).map(t => ({
        ...t,
        label: t.user_name,
        value: t.user_code,
      })),
      staffsReady: true,
    }));
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => ({
      ...state,
      tags: (payload?.item ?? []).map(t => ({
        ...t,
        label: t.tag_name,
        value: t.tag_code,
      })),
    }));
    builder.addCase(fetchTagCategory.fulfilled, (state, { payload }) => ({
      ...state,
      categories: (payload?.item ?? [])
        .filter(t => t.active_tag_count > 0 && t.category_status === "使用中")
        .map(t => ({
          ...t,
          label: t.tag_category_name,
          value: t.tag_category_code,
        })),
    }));
    builder.addCase(fetchMallBrand.fulfilled, (state, { payload }) => ({
      ...state,
      mallBrandList: (payload?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
    }));
  },
});

export const actions = coordinateSlice.actions;

export default coordinateSlice.reducer;

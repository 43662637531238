import DraggableItem from "./DraggableItem";
import { Image } from "antd";
const DraggableContainer = ({
  items,
  onClick,
  onExchange,
  onCancle,
  contentDisable,
  editable = true,
  isProduct,
  previewStatus = false
}) => {
  return (
    <div className="images-container">
      {previewStatus ? (
        <Image.PreviewGroup>
        {items.map((image, index) => (
          <DraggableItem
            key={index}
            image={image}
            index={index}
            onClick={onClick}
            onExchange={onExchange}
            onCancle={onCancle}
            contentDisable={contentDisable}
            isProduct={isProduct}
            editable={editable}
            previewStatus ={previewStatus}
          />
        ))}
        </Image.PreviewGroup>
      ) : (
        <>
          {items.map((image, index) => (
            <DraggableItem
              key={index}
              image={image}
              index={index}
              onClick={onClick}
              onExchange={onExchange}
              onCancle={onCancle}
              contentDisable={contentDisable}
              isProduct={isProduct}
              editable={editable}
              previewStatus ={previewStatus}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default DraggableContainer;

import { Provider } from "react-redux";
import store from "./store";
import IndexRouter from "./routers";
import MessageModal from "./components/modal/messageModal";
import FileUploadModal from "./components/modal/fileUploadModal";

function App() {
  return (
    <Provider store={store}>
      <IndexRouter />
      <MessageModal />
      <FileUploadModal />
    </Provider>
  );
}

export default App;

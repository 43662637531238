import "./style.scss";
import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        Copyright © W2 Co.,Ltd.　W2 Staff Sync powered by Benent
      </footer>
    </>
  );
};

export default Footer;

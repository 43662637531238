import { Modal } from "antd";
import "./style.scss";
import { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Yup from "../../../utils/yupUtil";
import Button from "../../button";
import { actions, updateCategory } from "../../../slices/categorySlice";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import GeneralInput from "../../generalInput";

const CategoryEditModal = () => {
  // 初期化
  const { brands, editModalVisible, editMode, categoryDate } = useSelector( state => state.category );
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      tag_category_code: categoryDate?.tag_category_code ?? categoryDate?.tag_category_code,
      tag_category_name: categoryDate?.tag_category_name ?? categoryDate?.tag_category_name,
      brand_code: categoryDate?.brand_code ?? categoryDate?.brand_code,
      brand_name: categoryDate?.brand_name ?? categoryDate?.brand_name,
      registration_button : false
    },
    validationSchema: Yup.object({
      brand_code: Yup.string().nullable().required(),
      tag_category_name: Yup.string().nullable().required(),
    }),
    onSubmit: values => {
      const { tag_category_code, ...rest } = values;
      dispatch(
        updateCategory({
          division: editMode,
          brand_code : values.brand_code ,
          tag_category_code : editMode === "insert" ? "" : values.tag_category_code,
          tag_category_name : values.tag_category_name,
        })
      );
    }
  });

  const {
    brand_code,
    brand_name,
    tag_category_code,
    tag_category_name,
    registration_button
  } = formik.values;

  useEffect(() => {
    if(editMode === "update"){
      categoryDate?.tag_category_code && handlerFormikFieldChange(formik, "tag_category_code", categoryDate?.tag_category_code);
      categoryDate?.brand_code && handlerFormikFieldChange(formik, "brand_code", categoryDate?.brand_code);
      categoryDate?.brand_name && handlerFormikFieldChange(formik, "brand_name", categoryDate?.brand_name);
      categoryDate?.tag_category_name && handlerFormikFieldChange(formik, "tag_category_name", categoryDate?.tag_category_name);
    }
    handlerFormikFieldChange(formik, "registration_button", 
      brand_code != null && brand_name != null && 
      tag_category_code != null && tag_category_name != null
    );
  }, [editModalVisible]);

  const onBack = () => {
    dispatch(actions.closeEditModal())
    formik.resetForm();
  };

  return (
    <Modal
      visible={editModalVisible}
      closable={false}
      width={1000}
      footer={null}
      onCancel={onBack}
      afterClose={() => formik.resetForm()}
    >
      <div className ="category-modal-area">
        <div className="title-area">
          <span className="text-title text-bold">{editMode === "insert" ? "カテゴリ登録": "カテゴリ編集"} </span>
          <span className="nf-text-required-style">*は必須項目です</span>
        </div>
        <div className="category-edit-area">
          <div className={"input-section"}>
            {editMode === "update" 
              ?
              <>
                <div className="input-information">
                  <GeneralInput
                    label ="カテゴリCD"
                    labelTextAlign ="left"
                    labelWidth="wider"
                    styleType="block-grey-normal"
                    uneditable={true}
                    name="tag_category_code"
                    formik={formik}
                  />
                </div>
                <div className="input-information">
                  <GeneralInput
                    label ="ブランド"
                    labelTextAlign ="left"
                    labelWidth="wider"
                    placeholder="カテゴリ名を入力してください"
                    styleType="block-grey-normal"
                    uneditable={true}
                    name="brand_name"
                    formik={formik}
                  />
                </div>
              </>
              :
              <>
                <GeneralInput
                  label="ブランド"
                  labelTextAlign ="left"
                  labelWidth="wider"
                  options={brands}
                  requiredItem={true}
                  mode={null}
                  placeholder="ブランドを選択して下さい"
                  name={"brand_code"}
                  styleType="block-grey"
                  formik={formik}
                />
              </>
            }
            <GeneralInput
              label ="カテゴリ名"
              labelTextAlign ="left"
              labelWidth="wider"
              requiredItem={true}
              placeholder="カテゴリ名を入力してください"
              styleType="block-grey-normal"
              name="tag_category_name"
              formik={formik}
            />
          </div>
        </div>
        <div className= "btn-area">
          <Button text="戻る" theme="white" onClick={() => onBack()} />
          <form className="search-area" onSubmit={formik.handleSubmit}>
            <Button 
              text={editMode === "insert" ? "登録": "更新"} 
              type="submit"
              theme={!registration_button ? !formik.isValid ? "light-black" : "black" : "black"}
              disabled={!registration_button ? !formik.isValid ? true : false : false}
            />
          </form>
        </div>
      </div>


    </Modal>
  );
};

export default CategoryEditModal;

import { useEffect } from "react";
import "video.js/dist/video-js.min.css";
import Hls from "hls.js";
import "./style.scss";

const Player = ({ url }) => {
  useEffect(() => {
    const player = document.getElementById('local-video');
    const hls = new Hls();
    hls.loadSource(url);
    hls.attachMedia(player);
    player.play();
  }, []);

  return (
    <video
      id="local-video"
      className="video-js"
      controls
      playsInline
      autoPlay
    >
      <source type="application/x-mpegURL" src={url} />
    </video>
  );
};

export default Player;

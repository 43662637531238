const TableColumnWidth = {
  small: "50px",
  short: "105px",
  normal: "150px",
  wide: "255px",
  long: "355px",

  icon: "5%",
  date: "10%",
  dateTime: "15%",
  userName: "10%",
  shopName: "10%",
  brandName: "12%",
  image: "15%",
  cd: "15%",
  contentType: "15%",
  earnings: "10%",
  orderCount: "10%",
  qty:"10%",
  pv: "10%",
  like: "10%",
  setRate: "10%",
  cvRate: "10%",
  unitPrice: "10%",
  insta: "10%",
  twitter: "10%",
  title : "15%",
  status: "10%",
  blogTitle:"15%",
  productName: "15%",
  categoryName:"15%",

  // SNS分析用URL一覧
  shareUrls:{
    param : "20%",
    url : "40%",
    none : "10%",  
  },
  // コーディネート一覧
  coordinate: {
    cd: "12%",
    status: "10%",
  },
  // ライブ配信一覧
  live: {
    cd: "10%",
    image: "15%",
    title: "10%",
    userName: "10%",
    shopName: "10%",
    brandName: "10%",
    status: "10%",
    dateTime: "13%",
    button : "10%",
    icon: "5%",
  },
  // ブログ一覧
  article:{
    cd: "10%",
    title:"13%",
    status: "7%",
  },
  // カテゴリ一覧
  category:{
    cd:"15%",
    categoryName:"20%",
    qty:"10%",
    status:"10%"
  },
  // タグ一覧
  tag:{
    tagCd:"7.5%",
    tagName:"12.5%",
    categoryName:"7.5%",
    applicantName:"7.5%",
    status:"12%"
  },
  // 報酬レート設定
  reward:{
    productCd:"10%",
    colorCd:"7.5%",
    sizeCd:"7.5%",
    skuCd:"10%",
    janCd:"10%",
    price:"10%",
    incentiveRate:"10%",
    incentivePrice:"10%"
  },
  // 社員一覧
  staff:{
    userCd:"10%",
    userAuth:"10%",
    approvalSkip:"10%",
    status:"10%"
  },
  // 店舗一覧
  shop:{
    shopCd:"15%",
    shopName:"15%",
    prefectures:"15%",
    tel:"15%",
    status:"10%"
  },
  // ブランド一覧
  brand:{
    brandCd:"15%",
    brandName:"15%",
    brandNameKana:"15%",
    url:"15%",
    incentiveRate:"10%",
    status:"10%"
  },
  // お知らせ一覧
  notice: {
    title: "30%",
    message: "35%",
  },
  // 予約配信
  reserve: {
    cd: "5%",
    title: "20%",
    message: "20%",
    status: "10%",
    repeat: "10%",
    result: "10%",
  },
  // コーディネート別分析
  analyzeCoordinate: {
    cd: "15%",
  },
  // ブログ別分析
  analyzeArticle: {
    title: "15%",
    orderCount: "5%",
  },
  // ライブ配信分析
  analyzeLive: {
    cd: "12%",
    contentType: "12%",
    userName: "12%",
    shopName: "12%",
    brandName: "12%",
  },
  // 動画分析
  analyzeMovie: {
    cd: "12%",
    contentType: "12%",
    userName: "12%",
    shopName: "12%",
    brandName: "12%",
  },
  // 店舗別分析 - コーディネート・ブログ
  analyzeShop: {
  },
  analyzeBrand: {
  },
  analyzeContents: {
  },
  // 社員SNS別分析
  analyzeSns: {
    snsType: "15%",
    insta: "12.5%",
    twitter: "12.5%",
  },
  // 支払報酬確認
  payments: {
    cd: "7.5%",
    inflow: "10%",
    type: "7.5%",
    retailPrice: "7.5%",
    reward: "7.5%",
  },
};

export default TableColumnWidth;

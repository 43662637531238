import { useState, useEffect } from "react";
import { Modal } from "antd";
import Button from "../../button";
import CusCheckbox from "../../cusCheckbox";
import classNames from "classnames";
import "./style.scss";

/* 複数選択モダール */

const SelectModal = ({
  options = [],
  value = [],
  titleTextAlign = "center",
  checkBoxCenter = true,
  checkBoxName,
  modalVisible,
  onCancel,
  onOk,
  title,
  width = 400,
  max = 20,
}) => {
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    setTempValue(value);
  }, [value]);
  return (
    <Modal
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      width={width}
      onCancel={onCancel}
    >
      <div className={"nf-modal-select"}>
        <h4
          className={classNames("nf-modal-select-title", {
            [`text-align-${titleTextAlign}`]: titleTextAlign,
          })}
        >
          {title}
        </h4>
        <div
          className={classNames("nf-modal-select-modal", {
            "box-center": checkBoxCenter,
          })}
        >
          <CusCheckbox
            options={options}
            value={tempValue}
            name={checkBoxName}
            onChange={setTempValue}
            max={max}
          />
        </div>
        <div>
          <Button
            text="戻る"
            theme="white"
            onClick={() => {
              onCancel?.();
            }}
          />
          <Button text="選択" onClick={() => onOk(tempValue)} />
        </div>
      </div>
    </Modal>
  );
};

export default SelectModal;

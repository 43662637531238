import "./style.scss";
import noimage from "../../images/noimage.png";

/* 社員アイコン */

const PersonalIcon = ({
  onClick,
  imgSrc,
  mask = false,
  maskText,
  width,
  height,
  borderRadius = "50%",
  cardIconFlag=false,
  cursor="pointer",
  maskTextSize = "0.75rem",
}) => {
  const imgStyle = { width: width, height: height, borderRadius: borderRadius, cursor:cursor, cardIconFlag };

  return (
    <div className={cardIconFlag ? "personal-icon card-icon" : "personal-icon"} onClick={onClick} style={{cursor:cursor}}>
      <img
        src={imgSrc || noimage}
        alt={"personal-icon"}
        style={imgStyle}
        onError={(e) => (e.target.src = noimage)}
      />
      {mask ? (
        <div class="icon-mask" style={{ ...imgStyle, top: 0, right: 0, bottom: 0, left: 0, margin: 'auto' }}>
          <div class="icon-caption" style={{ lineHeight: `${width}px`, fontSize: maskTextSize }}>
            {maskText}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PersonalIcon;

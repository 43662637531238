import "./style.scss";
import noimage from "../../images/noimage.png";
import Icon from "@ant-design/icons";
import IconImage from "../../constants/Icons";
import { toMoneyString, MoneyFormatString } from "../../utils/fnUtil";

/* 社員実績　社内比較コンテンツ　ヘッダ */

const StaffResultHeader = ({ columns }) => {
  const getJsxIcon = (before, after) => {
    let icon = "";
    if (before < after) icon = IconImage.ImageIcon1;
    if (before === after) icon = IconImage.ImageIcon2;
    if (before > after) icon = IconImage.ImageIcon3;
    return (
      <p>
        <Icon
          component={icon}
          style={{ fontSize: 16, marginLeft: 5, cursor: "initial" }}
          className="display-icon"
        />
        {before !== after && ` ${MoneyFormatString(toMoneyString(after - before))}`}
      </p>
    );
  };

  const createRightColumn = (data) => {
    return (
      <div className="staffResult-row">
        <span className="staffResult-header-rightlabel">
          {data?.label}
        </span>
        <span className="staffResult-header-rightvalue">
          {data?.value || data?.value === 0 ? "￥" : ""}
          {data?.value || data?.value === 0 ? toMoneyString(data?.value) : "-" || ""}
        </span>
        <span className="staffResult-header-rightgap">
          {data?.lastMonth || data?.lastMonth === 0 ? getJsxIcon(data?.lastMonth, data?.value) : ""}
        </span>
      </div>
    );
  };

  return (
    <div className="staffResultSns-header">
      <div className="staffResult-columns staffResult-left">
        <span className="staffResult-header-leftlabel">
          {columns?.left?.label}
        </span>
        <span className="staffResult-header-leftvalue">
          {columns?.left?.value || columns?.left?.value === 0 ? "￥" : ""}
          {columns?.left?.value || columns?.left?.value === 0 ? toMoneyString(columns?.left?.value) : "-" || ""}
        </span>
        <span className="staffResult-header-leftgap">
          {columns?.left?.lastMonth || columns?.left?.lastMonth === 0
            ? getJsxIcon(columns?.left?.lastMonth, columns?.left?.value)
            : ""}
        </span>
      </div>
      <div className="staffResult-columns">
        {columns?.rightValues?.map(e => createRightColumn(e))}
      </div>
    </div>
  );
};

export default StaffResultHeader;

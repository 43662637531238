import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal, DatePicker } from "antd";
import { genderOptionsWithKid } from "../../../constants/options";
import { actions as productActions } from "../../../slices/productSlice";
import {
  swapArray,
  spliceArray,
  replaceArray,
  createTimeOptions,
  createNumberOptions,
  handlerFormikFieldChange,
} from "../../../utils/fnUtil";
import {
  actions,
  fetchTags,
  fetchTagCategory,
  updateCoordinate,
  approvalCoordinate,
  updateApprovalCoordinate,
  saveDraftCoordinate,
  uploadImage, fetchMallBrand,
} from "../../../slices/coordinateSlice";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import ProgressBar from "../../progressBar";
import GeneralInput from "../../generalInput";
import CusCheckbox from "../../cusCheckbox";
import SelectModal from "../selectModal";
import DeleteModal from "../deleteModal";
import CommonModal from "../commonModal";
import ProductSearchModal from "../productSearchModal";
import DraggableContainer from "../coordinateSignUp/DraggableContainer";
import Yup from "../../../utils/yupUtil";
import classNames from "classnames";
import moment from "moment";
import "./style.scss";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";

const CoordinateEditModal = () => {
  const dispatch = useDispatch();

  const [activeIndex, setActiveIndex] = useState(0);

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const [selectMallBrand, setSelectMallBrand] = useState([]);

  const { multi_post_flag = 0 } = useSelector(state => state.account);

  const {
    editModalVisible,
    editDeleteModalVisible,
    confirmModalVisible,
    tags = [],
    categories = [],
    activeCoordinate,
    mallBrandList,
  } = useSelector(state => state.coordinate ?? {});

  const createUpdateParams = values => {
    const {
      tag_category_code,
      tag_code,
      coordinate_images,
      coordinate_products,
      status_flg,
      ...rest
    } = values;

    return {
      ...rest,
      tag_code: tag_code.map(t => t.tag_code),
      coordinate_images: coordinate_images.filter(c => c),
      coordinate_products: coordinate_products.filter(p => p),
      origin_status_flg: status_flg === "4" ? "3" : status_flg,
      status_flg: "0",
    };
  };

  const formik = useFormik({
    initialValues: {
      division: "update",
      coordinate_code: activeCoordinate.coordinate_code,
      brand_code: activeCoordinate.brand_code,
      shop_code: activeCoordinate.shop_code,
      user_code: activeCoordinate.user_code,
      accept_at: activeCoordinate.accept_at,
      coordinate_images: replaceArray(Array(10).fill(""), 0, [
        activeCoordinate.image_url ?? "",
        ...(activeCoordinate.sub_image ?? []),
      ]),
      coordinate_products: replaceArray(
        Array(10).fill(""),
        0,
        activeCoordinate.products ?? []
      ),
      tag_code: activeCoordinate.tags ?? [],
      gender: activeCoordinate.coordinate_genre,
      height: activeCoordinate.coordinate_height,
      comment: activeCoordinate.comment,
      mall_flg: activeCoordinate.mall_flg ?? 0,
      mall_brand_list: activeCoordinate.mall_brand_list ?? [],
      status_flg: activeCoordinate.status_flg,
      tag_category_code: null,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      coordinate_code: Yup.string().required(),
      brand_code: Yup.string().nullable().selected(),
      shop_code: Yup.string().nullable().selected(),
      user_code: Yup.string().nullable().selected(),
      coordinate_images: Yup.array().test(
        "coordinate_images",
        "2枚以上必須です",
        value => value.filter(v => v).length > 1
      ),
      coordinate_products: Yup.array().test(
        "coordinate_products",
        "1枚以上必須です",
        value => value.filter(v => v).length >= 1
      ),
      tag_code: Yup.array().min(2).max(20),
      gender: Yup.string().nullable().selected("性別"),
      height: Yup.string().nullable().selected("身長"),
      mall_brand_list: Yup.array().mallBrands(),
    }),
    onSubmit: values => dispatch(updateCoordinate(createUpdateParams(values))),
  });

  const approvalFormik = useFormik({
    initialValues: {
      date: null,
      hour: null,
      minute: null,
      approval_flg: ["3", "4"].includes(activeCoordinate.status_flg)
        ? ["1"]
        : ["2"].includes(activeCoordinate.status_flg)
        ? ["0"]
        : [],
      remand_reason: activeCoordinate.remand_reason ?? "",
      status_flg: activeCoordinate.status_flg,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      approval_flg: Yup.array().test(
        "approval_flg",
        "必須項目です",
        value => value.length > 0
      ),
      remand_reason: Yup.string().when("approval_flg", {
        is: v => v.includes("0"),
        then: Yup.string().required(),
      }),
      date: Yup.string()
        .nullable()
        .test("date", "日時を選択してください", (value, testContext) =>
          testContext.parent.approval_flg.includes("1")
            ? value && testContext.parent.hour && testContext.parent.minute
            : true
        ),
    }),
    onSubmit: async values => {
      const accept_at = values.approval_flg.includes("1")
        ? `${values.date.format("YYYY-MM-DD")} ${values.hour}:${
            values.minute
          }:00`
        : null;

      const approvalParams = {
        accept_at,
        remand_reason: values.remand_reason,
        coordinate_code: formik.values.coordinate_code,
        approval_flg: values.approval_flg[0],
        origin_status_flg: values.status_flg === "4" ? "3" : values.status_flg,
      };
      if (values.status_flg === "0") {
        dispatch(
          updateApprovalCoordinate({
            updateParams: createUpdateParams(formik.values),
            approvalParams,
          })
        );
      } else {
        dispatch(approvalCoordinate(approvalParams));
      }
    },
  });

  const {
    brand_code,
    tag_category_code,
    tag_code,
    status_flg,
    coordinate_code,
    coordinate_images,
    coordinate_products,
    comment,
    user_code,
    mall_flg,
    mall_brand_list
  } = formik.values;

  const {
    values: { approval_flg, date, remand_reason },
  } = approvalFormik;

  const contentEditable = status_flg === "0";

  const handleFileChange = (url, index) =>
    dispatch(
      uploadImage({
        image: url,
        content_flag: 0,
        content_code: coordinate_code,
        upload_operator_code: user_code
      })
    ).then(
      ({ payload }) =>
        payload?.code === "0" &&
        handlerFormikFieldChange(
          formik,
          "coordinate_images",
          spliceArray(coordinate_images, index, payload.image_url)
        )
    );

  useEffect(() => {
    if (editModalVisible) {
      dispatch(fetchMallBrand(
          {
            brand_code: activeCoordinate.brand_code,
            mall_flag:1,
            offset: 0,
            status_flag: 1,
            sort: "brand_code_asc"
          }
      ));
      const current = activeCoordinate.accept_at
        ? moment(activeCoordinate.accept_at)
        : moment();
      handlerFormikFieldChange(approvalFormik, "date", current);
      handlerFormikFieldChange(approvalFormik, "hour", current.format("HH"));
      handlerFormikFieldChange(approvalFormik, "minute", current.format("mm"));
    }
  }, [activeCoordinate, editModalVisible, handlerFormikFieldChange]);

  useEffect(() => {
    tag_category_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code: activeCoordinate.brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, activeCoordinate, dispatch]);

  useEffect(() => {
    editModalVisible &&
      dispatch(productActions.saveBrand(activeCoordinate.brand_code));
    editModalVisible &&
      activeCoordinate.status_flg === "0" &&
      dispatch(
        fetchTagCategory({
          brand_code: activeCoordinate.brand_code,
          sort: "tag_category_code_asc",
          count: 0,
        })
      );
  }, [activeCoordinate, editModalVisible, dispatch]);

  useEffect(() => {
     setSelectMallBrand(mallBrandList.filter(m=> mall_brand_list.includes(m.brand_code)));
  },[mallBrandList, mall_brand_list]);

  const onBack = () => {
    dispatch(actions.closeEditModal());
    formik.resetForm();
    approvalFormik.resetForm();
  };

  const afterClose = () => {
    dispatch(actions.clearActiveCoordinate());
    formik.resetForm();
    approvalFormik.resetForm();
  };

  return (
    <Modal
      visible={editModalVisible}
      footer={null}
      closable={false}
      width={850}
      style={{ minWidth: 850 }}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
    >
      <ProgressBar activeProgress={activeCoordinate.status_flg} />
      <div className="coordinate-edit">
        <div className="coordinate-edit-area">
          <span className="text-title text-bold">コーディネート編集 </span>
          <span className="nf-text-required-style">*は必須項目です</span>
          <br />
          <div className="text-bold text-coordinate-code text-blog-code">
            コーディネートCD {activeCoordinate.coordinate_code}
          </div>
          <div className="text-bold text-required">投稿者情報</div>
          <div className="info-area">
            <span>ブランド：{activeCoordinate.brand_name}</span>
            <span>店舗：{activeCoordinate.shop_name}</span>
            <span>社員：{activeCoordinate.user_name}</span>
            <br />
            <span>
              投稿者権限：
              {activeCoordinate.coordinate_approval_flag === 1
                ? "承認スキップユーザー"
                : activeCoordinate.coordinate_approval_flag === 0
                ? "承認申請ユーザー"
                : "-"}
            </span>
          </div>

          <div>
            <span className="text-bold text-required">コーディネート画像</span>
            <span className="text-tip">
              2枚以上登録必須
              <span className="text-subtip">
                ファイル形式 png,gif,jpg,jpeg、ドラッグで入れ替え可。
              </span>
            </span>
            <span className="validation-error error-images">
              {formik.touched.coordinate_images &&
                formik.errors.coordinate_images}
            </span>
          </div>
          <DraggableContainer
            items={coordinate_images}
            onClick={handleFileChange}
            contentDisable={!contentEditable}
            editable={contentEditable}
            onCancle={(e, index) => {
              handlerFormikFieldChange(
                formik,
                "coordinate_images",
                spliceArray(coordinate_images, index, "")
              );
              e.stopPropagation();
            }}
            onExchange={(dragIndex, dropIndex) => {
              handlerFormikFieldChange(
                formik,
                "coordinate_images",
                swapArray(coordinate_images, dragIndex, dropIndex)
              );
            }}
            previewStatus={activeCoordinate.status_flg !== "0"}
          />

          <div>
            <span className="text-bold text-required">商品情報</span>
            <span className="text-tip">
              <span className="text-subtip">
                10枚まで登録可、ドラッグで入れ替え可。
              </span>
            </span>
            <span className="validation-error error-images">
              {formik.touched.coordinate_products &&
                formik.errors.coordinate_products}
            </span>
          </div>
          <DraggableContainer
            items={coordinate_products}
            isProduct={true}
            editable={contentEditable}
            onClick={index => {
              setProductSearchModalVisible(true);
              setActiveIndex(index);
            }}
            contentDisable={!contentEditable}
            onCancle={(e, index) => {
              handlerFormikFieldChange(
                formik,
                "coordinate_products",
                spliceArray(coordinate_products, index, null)
              );
              e.stopPropagation();
            }}
            onExchange={(dragIndex, dropIndex) => {
              handlerFormikFieldChange(
                formik,
                "coordinate_products",
                swapArray(coordinate_products, dragIndex, dropIndex)
              );
            }}
          />
          <div class="tag-area">
            <div className="tag-title">
              <span className="text-bold text-required">タグ情報</span>　
              <span className="text-tip">
                2個以上登録必須
                <span className="text-subtip"> 20個まで登録可。</span>
              </span>
            </div>
            {contentEditable && (
              <>
                <GeneralInput
                  label="カテゴリ"
                  placeholder="選択してください"
                  styleType="block-grey-normal"
                  requiredItem
                  labelTextAlign="left"
                  options={categories}
                  mode={null}
                  name={"tag_category_code"}
                  formik={formik}
                />
                <div>
                  <span className="text-required text-tag">タグ</span>
                  <Button
                    disabled={!tag_category_code}
                    text="タグを選択"
                    theme="white"
                    onClick={() => setSelectTagModalVisible(true)}
                  />
                  <span className="validation-error">
                    {formik.touched.tag_code && formik.errors.tag_code}
                  </span>
                </div>
              </>
            )}
            <div className="tags-container">
              {tag_code.map((t, i) => (
                <div className="tag" key={i}>
                  {t.tag_name}
                  {contentEditable && (
                    <Icon
                      className="close-icon"
                      component={Icons.CloseIcon}
                      onClick={() => {
                        handlerFormikFieldChange(
                          formik,
                          "tag_code",
                          tag_code.filter(i => i.tag_code !== t.tag_code)
                        );
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="user-info-area">
            <span className="text-bold text-required">属性</span>　
            <GeneralInput
              label="性別"
              placeholder="選択してください"
              uneditable={!contentEditable}
              styleType="block-grey-normal"
              requiredItem
              labelTextAlign="left"
              options={genderOptionsWithKid}
              mode={null}
              name={"gender"}
              formik={formik}
            />
            <GeneralInput
              label="身長"
              uneditable={!contentEditable}
              placeholder="選択してください"
              styleType="block-grey-normal"
              requiredItem
              labelTextAlign="left"
              options={createNumberOptions()}
              mode={null}
              name={"height"}
              formik={formik}
              textAfter="cm"
            />
          </div>

          <div className="comment-area">
            <span className="text-bold">コメント</span>　
            {contentEditable ? (
              <textarea
                placeholder="コメントを入力してください"
                spellCheck={false}
                autoComplete={"false"}
                value={comment}
                onChange={e =>
                  handlerFormikFieldChange(formik, "comment", e.target.value)
                }
              />
            ) : (
              <p className="display-linebreak">{comment}</p>
            )}
          </div>
          <div className="mall-brand-area">
            <div className="mall-brand-title">
              <span className="text-bold text-required">同時投稿</span>
            </div>
            {mallBrandList.length > 0 && multi_post_flag === 1 && contentEditable && (
                <>
                  <div className="nf-input-wrapper">
                    <label className="nf-input-label text-align-left label-width-wide label-required">同時投稿</label>
                    <GeneralCheckbox
                        label="同時投稿する"
                        checked={mall_flg === 1}
                        className={"nf-checkbox-wide"}
                        onChange={(value) => handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 1 : 0)}
                    />
                    <GeneralCheckbox
                        label="同時投稿しない"
                        checked={mall_flg === 0}
                        onChange={(value) => {
                          handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 0 : 1)
                          handlerFormikFieldChange(formik, "mall_brand_list", [])
                        }}
                    />
                  </div>
                  {mall_flg === 1 && (<>
                    <GeneralInput
                        label="同時投稿先"
                        placeholder="選択してください"
                        styleType="block-grey-normal"
                        labelTextAlign="left"
                        requiredItem={mall_flg === 1}
                        options={mallBrandList}
                        disabled={mall_flg === 0}
                        name={"mall_brand_list"}
                        formik={formik}
                    />
                  </>)}
                </>
            )}

            <div className="mall-brand-container">
              {multi_post_flag === 1 && selectMallBrand.map((b, i) => (
                  <div className="mall-brand" key={i}>
                    {b.brand_name}
                    {(contentEditable && multi_post_flag === 1) && (
                        <Icon
                            className="close-icon"
                            component={Icons.CloseIcon}
                            onClick={() => {
                              handlerFormikFieldChange(
                                  formik,
                                  "mall_brand_list",
                                  mall_brand_list.filter(i => i !== b.brand_code)
                              );
                            }}
                        />
                    )}
                  </div>
              ))}
              {(contentEditable &&
                  (mallBrandList.length === 0 || multi_post_flag !== 1) ||
               (!contentEditable &&
                  (multi_post_flag !== 1 || selectMallBrand.length === 0))) && (
                  <>
                    <div className="nf-input-wrapper nf-wrapper-uneditable">
                      <span className="input-value">{"同時投稿しない"}</span>
                    </div>
                  </>
              )}
            </div>
          </div>
          <div className="action-container">
            <Button
              text={
                ["1", "2", "3", "4"].includes(status_flg)
                  ? "下書きに戻す"
                  : "保存"
              }
              disabled={status_flg !== "0" && !formik.isValid}
              onClick={() =>
                dispatch(
                  ["1", "2", "3", "4"].includes(status_flg)
                    ? actions.openConfirmModal()
                    : saveDraftCoordinate(createUpdateParams(formik.values))
                )
              }
            />
            <Button
              text="コーディネートを削除する"
              theme="link"
              onClick={() => dispatch(actions.openEditDeleteModal())}
            />
          </div>
        </div>
        <div className={classNames("coordinate-edit-area")}>
          <span className="text-title text-bold">承認設定</span>
          <span className="validation-error">
            {approvalFormik.touched.approval_flg &&
              approvalFormik.errors.approval_flg}
          </span>
          <div className="coordinate-approval-area">
            <CusCheckbox
              radio={true}
              value={approval_flg}
              onChange={value =>
                handlerFormikFieldChange(approvalFormik, "approval_flg", value)
              }
              options={[
                {
                  label: "承認",
                  value: "1",
                },
              ]}
            />
            <div className="public-date-select">
              <span className="text-required text-checkbox-label">
                公開日時
              </span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={date}
                disabled={!approval_flg.includes("1")}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value =>
                  handlerFormikFieldChange(approvalFormik, "date", value)
                }
              />
              <GeneralInput
                mode={null}
                disabled={!approval_flg.includes("1")}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"hour"}
                placeholder="時"
                textAfter="時"
                formik={approvalFormik}
              />
              <GeneralInput
                mode={null}
                disabled={!approval_flg.includes("1")}
                styleType="block-grey-normal"
                options={createTimeOptions(60)}
                name={"minute"}
                placeholder="分"
                textAfter="分"
                formik={approvalFormik}
              />
              <span className="validation-error">
                {approvalFormik.touched.date &&
                  approvalFormik.touched.hour &&
                  approvalFormik.touched.minute &&
                  approvalFormik.errors.date}
              </span>
            </div>
            <div
              class={classNames("remand-area", {
                "disabled-area": ["0"].includes(status_flg),
              })}
            >
              <CusCheckbox
                radio={true}
                value={approval_flg}
                disabled={["0"].includes(status_flg)}
                options={[
                  {
                    label: "差戻し",
                    value: "0",
                  },
                ]}
                onChange={value =>
                  handlerFormikFieldChange(
                    approvalFormik,
                    "approval_flg",
                    value
                  )
                }
              />
              <span class="text-remind">
                ※公開中の記事を編集中に戻す場合はステータスを差戻しに変更してください
              </span>
              <span className="validation-error error-remand-reason">
                {approvalFormik.touched.remand_reason &&
                  approvalFormik.errors.remand_reason}
              </span>
            </div>
            <div
              className={classNames("public-date-select", "select-flex-top", {
                "disabled-area": ["0"].includes(status_flg),
              })}
            >
              <span className="text-required text-checkbox-label">
                差戻し理由
              </span>
              <textarea
                placeholder="テキストを入力してください"
                spellCheck={false}
                autoComplete={"false"}
                disabled={["0"].includes(status_flg)}
                value={remand_reason}
                onChange={e =>
                  handlerFormikFieldChange(
                    approvalFormik,
                    "remand_reason",
                    e.target.value
                  )
                }
              />
            </div>

            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="更新"
                onClick={approvalFormik.handleSubmit}
                disabled={!formik.isValid || !approvalFormik.isValid}
              />
            </div>
          </div>
        </div>
        <SelectModal
          checkBoxName={`${activeCoordinate.coordinate_code}-tags}`}
          modalVisible={selectTagModalVisible}
          onCancel={() => setSelectTagModalVisible(false)}
          onOk={value => {
            handlerFormikFieldChange(
              formik,
              "tag_code",
              [
                ...tag_code,
                ...tags.filter(
                  t =>
                    value.includes(t.value) &&
                    !tag_code.some(tag => t.value === tag.tag_code)
                ),
              ].filter(
                tag =>
                  !tags.map(t => t.value).includes(tag.tag_code) ||
                  value.includes(tag.tag_code)
              )
            );
            setSelectTagModalVisible(false);
          }}
          title={"タグを選択してください"}
          value={tag_code.map(t => t.tag_code)}
          options={tags}
        />
      </div>
      <ProductSearchModal
        visible={productSearchModalVisible}
        onCancel={() => setProductSearchModalVisible(false)}
        onOk={selectedProduct => {
          handlerFormikFieldChange(
            formik,
            "coordinate_products",
            spliceArray(coordinate_products, activeIndex, selectedProduct)
          );
          setProductSearchModalVisible(false);
        }}
      />
      <DeleteModal
        modalVisible={editDeleteModalVisible}
        onCancel={() => dispatch(actions.closeEditDeleteModal())}
        item={"コーディネートCD"}
        itemValue={activeCoordinate.coordinate_code}
        onOk={() => {
          dispatch(
            updateCoordinate({
              division: "delete",
              coordinate_code: activeCoordinate.coordinate_code,
              brand_code: activeCoordinate.brand_code,
              origin_status_flg:
                activeCoordinate.status_flg === "4"
                  ? "3"
                  : activeCoordinate.status_flg,
            })
          );
        }}
      />
      <CommonModal
        modalVisible={confirmModalVisible}
        text={`コーディネートCD:${activeCoordinate.coordinate_code}を下書きに戻します。\nよろしいですか？`}
        tip={"*公開日時、差戻理由は更新されません。"}
        okText="下書きに戻す"
        onClose={() => dispatch(actions.closeConfirmModal())}
        onOk={() =>
          dispatch(updateCoordinate(createUpdateParams(formik.values)))
        }
      />
    </Modal>
  );
};

export default CoordinateEditModal;

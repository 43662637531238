import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Table, Select, DatePicker, Input, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { actions, ProductInit, fetchProducts, updateProduct, UpdateMstProduct } from "../../slices/productSlice";
import classNames from "classnames";
import { uploadActions } from "../../slices/fileUploadSlice";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import Icon from "@ant-design/icons";
import moment from "moment";
import "./style.scss";
import GenernalInput from "../../components/generalInput";
import Button from "../../components/button";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SelectUpdateModal from "../../components/modal/selectUpdateModal";
import DeleteModal from "../../components/modal/deleteModal";
import GeneralCheckbox from "../../components/cusCheckbox/genernalCheckbox";
import IconImage from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";


const Product = () => {
  // 定義
  const dispatch = useDispatch();
  const { Text } = Typography;
  const { brands, productList, categoryList, subCategoryList, searchParams, updateParams, 
          total, errorIndex, selectRate, productDeleteModalVisible, productUpdateModalVisible } = useSelector(state => state.product);
  const { user_auth }  = useSelector(state => state.account);
  const searchFormik = useFormik({
    initialValues: searchParams,
    onSubmit: values => {
      dispatch(actions.saveSearchParam({
        ...values,
        start_date: values?.start_date ? values?.start_date.format('YYYY-MM-DD') : null,
        end_date: values.end_date ? values.end_date.format('YYYY-MM-DD') : null,
      }))
    }
  });
  const updateFormik = useFormik({
    initialValues: updateParams,
    // onSubmit: values => dispatch(actions.saveSearchParam(values)),
  });
  const { 
    start_date,
    end_date
  } = searchFormik.values;

  const { 
    selectUpdateButtonStatus,
    changeUpdateButtonStatus,
    selectedRowKeys, 
    updateProductList, 
    workProductList } = updateFormik.values;
  // 初期データ取得
  useEffect(() => {
    dispatch(ProductInit(searchParams));
    return () => dispatch(actions.clear());
  }, []);
  useUpdateEffect(() => {
    handlerFormikFieldChange(updateFormik, "selectedRowKeys", []);
    handlerFormikFieldChange(updateFormik, "selectUpdateButtonStatus", false);
    handlerFormikFieldChange(updateFormik, "changeUpdateButtonStatus", false);
    handlerFormikFieldChange(updateFormik, "updateProductList", []);
    dispatch(fetchProducts(searchParams));
  }, [dispatch, searchParams]);

  useUpdateEffect(() => {
    handlerFormikFieldChange(updateFormik, "workProductList", productList.map((data, index) => { return { ...data, key: index } }));
  }, [productList]);

  useUpdateEffect(() => {
    if(errorIndex > 0){
      dispatch(actions.saveProductList(workProductList.map((data, index) => { 
        if(errorIndex > index){
          return data; 
        }else{
          return {...productList[index], key: index}
        }
      })));
      onSelectChange(selectedRowKeys.filter(x => errorIndex <= x).sort().map(data => {return data}),"",true);
    }else if(errorIndex === -1){
      handlerFormikFieldChange(updateFormik, "selectedRowKeys", []);
      handlerFormikFieldChange(updateFormik, "selectUpdateButtonStatus", false);
      handlerFormikFieldChange(updateFormik, "changeUpdateButtonStatus", false);
      handlerFormikFieldChange(updateFormik, "updateProductList", []);
      dispatch(fetchProducts(searchParams));  
    }
  }, [errorIndex]);

  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      searchFormik.values.sort === asc
        ? desc
        : searchFormik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    searchFormik.setFieldValue("sort", targetSort);
  };
  //　一括更新ワーク
  const onSelectChange = (selectedRowKeys,selectedRow,changeFlg) => {

    const updateList = selectedRowKeys
      .filter(x => productList[x]?.delete_flag === 0)
      .map(x => {
        workProductList[x].origin_sku_code =  productList[x].sku_code;
        return workProductList[x] 
      });
    handlerFormikFieldChange(updateFormik, "selectedRowKeys", selectedRowKeys);
    handlerFormikFieldChange(updateFormik, "updateProductList", updateList);
    // チェック入れた時に値を戻す処理
    // if(!changeFlg){
    //   handlerFormikFieldChange(updateFormik, "workProductList", 
    //     workProductList.map((data, index) => {
    //       console.log(selectedRowKeys.some(v => v === index))
    //       return selectedRowKeys.some(v => v === index) ? data : { ...productList[index], key: index };
    //     })  
    //   );
    // }
    const status = updateList && updateList.length > 0 ? updateList : false;
    handlerFormikFieldChange(updateFormik, "selectUpdateButtonStatus", status);
  };
  //　選択更新ワーク
  const selectUpdateChange = (values, recode ,param) => {
    eval("recode." + param + "= values.toString()" );
    if(param === "brand_code"){
      recode.brand_name = brands.filter(x => x.brand_code === recode?.brand_code )[0].brand_name;
      recode.category_code = null;
      recode.category_name = null;
      recode.sub_category_code = null;
      recode.sub_category_name = null;
    }else if(param === "category_code"){
      // API出来次第修正
      recode.category_name = categoryList.filter(x => x.brand_code === recode?.brand_code && x.category_code === recode?.category_code )[0].category_name;
      recode.sub_category_code = null;
      recode.sub_category_name = null;
    }else if(param === "sub_category_code"){
      // API出来次第修正
      recode.sub_category_name = subCategoryList.filter(x => x.sub_category_code === recode?.sub_category_code )[0].sub_category_name;
    }else if(param === "price"){
      recode.price = recode?.price?.replace(/[１-９][０-９]/g,s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g,'').replace(/^0+/, '');
    }else if(param === "selling_price"){
      recode.selling_price = recode?.selling_price?.replace(/[０-９]/g,s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g,'').replace(/^0+/, '');
    }else if(param === "tax_rate"){
      recode.tax_rate = recode?.tax_rate?.replace(/[０-９]/g,s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g,'');
    }
    handlerFormikFieldChange(updateFormik, "workProductList", 
      workProductList.map((data, index) => {
        return recode?.key === index ? recode : data;
      })
    );
    handlerFormikFieldChange(updateFormik, "changeUpdateButtonStatus", true);
    var addRowKeys = [recode?.key];
    var flg  = false;
    eval("flg = productList[recode?.key]?." + param + " !== recode?." + param + " && !selectedRowKeys.some(e => e === recode?.key)");
    flg && (onSelectChange(
      selectedRowKeys.concat(addRowKeys).sort()
      .map(data => {return data}),"",true));
  };

  const inputClassName = (color, width, date= "normal") => classNames(
    {[`nf-table-input-${ color && "red"}`]: color },
    {[`nf-table-input-size-${width}`]: width},
    {[`nf-table-input-${date}`]: date}
  );
  const tableClassName = (width) => classNames(
    {[`nf-table-column-size-${width}`]: width}
  );

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: recode =>({
      disabled : recode?.delete_flag === 1,
      className : recode?.delete_flag === 1 && "nf-checkbox-hidden",
    }),
  };

  // 設定率一括更新
  const bulkUpdate = async (updateMode) => {
    updateMode === "delete" &&
      handlerFormikFieldChange(updateFormik, "updateProductList", 
        updateProductList.map(x => {return {...productList[x.key] ,key: x.key}})
      );
    dispatch(UpdateMstProduct({updateProductList, updateMode, searchParams}));
  };
  //　テーブル定義
  const columns = [
    // ステータス ボタン or ラベル
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("release_flag")}>
            {searchFormik.values.sort === "release_flag_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "delete_flag",
      key: "delete_flag",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index,recode) => (
        <>
          {recode.delete_flag === 0 ? (
            <label>{"公開"}</label>
          ) : (
            <Button
              text="復帰"
              theme="withe"
              width="table"
              onClick={ () => {dispatch(updateProduct({
                division: "revive",
                product_code : recode?.product_code,
                color_code: recode?.color_code,
                size_code: recode?.size_code,
                brand_code:recode?.brand_code,
                sku_code:recode?.sku_code,
              }))}}
            />
          )} 
        </>
      ),

    },
    // 商品CD ラベル
    {
      title: () => (
        <>
          <span>商品CD </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("product_code")}
          >
            {searchFormik.values.sort === "product_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "product_code",
      key: "product_code",
      align: "center",
      width:TableColumnWidth.normal,
    },
    // 色CD ラベル
    {
      title: () => (
        <>
          <span>色CD </span>
          <span className="sort-span" onClick={createOnClikSort("color_code")}>
            {searchFormik.values.sort === "color_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "color_code",
      key: "color_code",
      align: "center",
      width:TableColumnWidth.normal,
    },
    // 色名　テキスト or ラベル
    {
      title: () => (
        <>
          <span>色名 </span>
          <span className="sort-span" onClick={createOnClikSort("color_name")}>
            {searchFormik.values.sort === "color_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "color_name",
      key: "color_name",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index ,recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="color_name"
                value={recode.color_name}
                onChange={v => selectUpdateChange(v.target.value, recode, "color_name")}
                className={inputClassName(productList[recode.key]?.color_name !== recode?.color_name, "normal")}
              />
            ) : (
              <label>{recode.color_name}</label>
            )} 
          </div>
        </>
      ),
    },
    // サイズCD　テキスト or ラベル
    {
      title: () => (
        <>
          <span>サイズCD </span>
          <span className="sort-span" onClick={createOnClikSort("size_code")}>
            {searchFormik.values.sort === "size_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "size_code",
      key: "size_code",
      align: "center",
      width:TableColumnWidth.short,
      // render: (index,recode) => (
      //   <>
      //     <div className="nf-table-input-product">
      //       {recode.delete_flag === 0 ? (
      //         <Input
      //           name="size_code"
      //           value={recode.size_code}
      //           onChange={v => selectUpdateChange(v.target.value, recode, "size_code")}
      //           className={inputClassName(productList[recode.key]?.size_code !== recode?.size_code, "short")}
      //         />
      //       ) : (
      //         <label>{recode.size_code}</label>
      //       )} 
      //     </div>
      //   </>
      // ),
    },
    // サイズ名　テキスト or ラベル
    {
      title: () => (
        <>
          <span>サイズ名 </span>
          <span className="sort-span" onClick={createOnClikSort("size_name")}>
            {searchFormik.values.sort === "size_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "size_name",
      key: "size_name",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="size_name"
                value={recode.size_name}
                onChange={v => selectUpdateChange(v.target.value, recode, "size_name")}
                className={inputClassName(productList[recode.key]?.size_name !== recode?.size_name, "normal")}
              />
            ) : (
              <label>{recode.size_name}</label>
            )}
          </div>
        </>
      ),
    },
    // SKUコード　テキスト or ラベル
    {
      title: () => (
        <>
          <span>SKUコード </span>
          <span className="sort-span" onClick={createOnClikSort("sku_code")}>
            {searchFormik.values.sort === "sku_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "sku_code",
      key: "sku_code",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="sku_code"
                value={recode.sku_code}
                onChange={v => selectUpdateChange(v.target.value, recode, "sku_code")}
                className={inputClassName(productList[recode.key]?.sku_code !== recode?.sku_code, "normal")}
              />
            ) : (
              <label>{recode.sku_code}</label>
            )}
          </div>
        </>
      ),
    },
    // JANコード　テキスト or ラベル
    {
      title: () => (
        <>
          <span>JANコード </span>
          <span className="sort-span" onClick={createOnClikSort("jan_code")}>
            {searchFormik.values.sort === "jan_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "jan_code",
      key: "jan_code",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="jan_code"
                value={recode.jan_code}
                onChange={v => selectUpdateChange(v.target.value, recode, "jan_code")}
                className={inputClassName(productList[recode.key]?.jan_code !== recode?.jan_code, "normal")}
              />
            ) : (
              <label>{recode.jan_code}</label>
            )}
          </div>
        </>
      ),
    },
    // バーコード　テキスト or ラベル
    {
      title: () => (
        <>
          <span>バーコード </span>
          <span className="sort-span" onClick={createOnClikSort("barcode")}>
            {searchFormik.values.sort === "barcode_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "barcode",
      key: "barcode",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="barcode"
                value={recode.barcode}
                onChange={v => selectUpdateChange(v.target.value, recode, "barcode")}
                className={inputClassName(productList[recode.key]?.barcode !== recode?.barcode, "normal")}
              />
            ) : (
              <label>{recode.barcode}</label>
            )}
          </div>
        </>
      ),
    },
    // ブランドコード　テキスト or ラベル
    {
      title: () => (
        <>
          <span>ブランドCD </span>
          <span className="sort-span" onClick={createOnClikSort("brand_code")}>
            {searchFormik.values.sort === "brand_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "brand_code",
      key: "brand_code",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode, brand_code) => (
        <>
          <div className="nf-table-input-product">
            <label>{recode.brand_code}</label>
          </div>
        </>
      ),
    },
    // ブランド名　テキスト or ラベル
    {
      title: () => (
        <>
          <span>ブランド名 </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {searchFormik.values.sort === "brand_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            <label>{recode.brand_name}</label>
          </div>
        </>
      ),
    },
    // 商品名　テキスト or ラベル
    {
      title: () => (
        <>
          <span>商品名 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("product_name")}
          >
            {searchFormik.values.sort === "product_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "product_name",
      key: "product_name",
      align: "center",
      width:TableColumnWidth.wide,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="product_name"
                value={recode.product_name}
                onChange={v => selectUpdateChange(v.target.value, recode, "product_name")}
                className={inputClassName(productList[recode.key]?.product_name !== recode?.product_name, "wide")}
              />
            ) : (
              <label>{recode.product_name}</label>
            )}
          </div>
        </>
      ),
    },
    // 商品説明 テキスト or ラベル
    {
      title: () => (
        <>
          <span>商品説明 </span>
          <span className="sort-span" onClick={createOnClikSort("product_description")}>
            {searchFormik.values.sort === "product_description_desc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "product_description",
      key: "product_description",
      align: "center",
      width:TableColumnWidth.long,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="product_ascription"
                value={recode.product_description}
                onChange={v => selectUpdateChange(v.target.value, recode, "product_description")}
                className={inputClassName(productList[recode.key]?.product_description !== recode?.product_description, "long")}
              />
            ) : (
              <label>{recode.product_description}</label>
            )}
          </div>
        </>
      ),
    },
    // カテゴリCD テキスト or ラベル
    {
      title: () => (
        <>
          <span>カテゴリCD </span>
          <span className="sort-span" onClick={createOnClikSort("category_code")}>
            {searchFormik.values.sort === "category_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "category_code",
      key: "category_code",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode, category_code, ) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                value={recode.category_code}
                className={inputClassName(productList[recode.key]?.category_code !== recode?.category_code, "normal")}
                onChange={v => selectUpdateChange(v, recode, "category_code")}
                showArrow={true}
                menuItemSelectedIcon={<CheckOutlined style={{ color: "black" }} />}
              >
                {categoryList
                  .filter(x => x.brand_code === recode?.brand_code)
                  .map(o => (
                      <Select.Option value={o.value} key={o.value}>
                        {o.value}
                      </Select.Option>
                    )
                  )}
              </Select>
            ) : (
              <label>{recode.category_code}</label>
            )}
          </div>
        </>
      ),
    },
    // カテゴリ名 ラベル
    {
      title: () => (
        <>
          <span>カテゴリ名 </span>
          <span className="sort-span" onClick={createOnClikSort("category_name")}>
            {searchFormik.values.sort === "category_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "category_name",
      key: "category_name",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode, category_name) => (
        <>
          <div className="nf-table-input-product">
            <Text
              className={inputClassName(productList[recode.key]?.category_name !== recode?.category_name)}
            >{recode.category_name}</Text>
          </div>
        </>
      ),
    },
    // サブカテゴリCD テキスト or ラベル
    {
      title: () => (
        <>
          <span>サブカテゴリCD </span>
          <span className="sort-span" onClick={createOnClikSort("sub_category_code")}>
            {searchFormik.values.sort === "sub_category_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "sub_category_code",
      key: "sub_category_code",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode, sub_category_code) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                value={recode.sub_category_code}
                className={inputClassName(productList[recode.key]?.sub_category_code !== recode?.sub_category_code, "normal")}
                onChange={v => selectUpdateChange(v, recode, "sub_category_code")}
                showArrow={true}
                menuItemSelectedIcon={
                  <CheckOutlined style={{ color: "black" }} />
                }
              >
                {subCategoryList
                .filter(x => x.brand_code === recode.brand_code && x.category_code === recode.category_code )
                .map(o => (
                  <Select.Option value={o.value} key={o.value}>
                    {o.value}
                  </Select.Option>
                ))}
              </Select>
            ) : (
              <label>{recode.sub_category_code}</label>
            )}
          </div>
        </>
      ),

    },
    // サブカテゴリ名 ラベル
    {
      title: () => (
        <>
          <span>サブカテゴリ名 </span>
          <span className="sort-span" onClick={createOnClikSort("sub_category_name")}>
            {searchFormik.values.sort === "sub_category_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "sub_category_name",
      key: "sub_category_name",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            <Text
              className={inputClassName(productList[recode.key]?.sub_category_name !== recode?.sub_category_name)}
            >
              {recode.sub_category_name}
            </Text>
          </div>
        </>
      ),
    },
    // 定価 テキスト or ラベル
    {
      title: () => (
        <>
          <span>定価 </span>
          <span className="sort-span" onClick={createOnClikSort("price")}>
            {searchFormik.values.sort === "price_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "price",
      key: "price",
      align: "center",
      width:TableColumnWidth.short,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="price"
                value={recode.price}
                onChange={v => selectUpdateChange(v.target.value, recode, "price")}
                className={inputClassName(productList[recode.key]?.price !== recode?.price, "short")}
              />
            ) : (
              <label>{recode.price}</label>
            )}
          </div>
        </>
      ),
    },
    // 販売価格 テキスト or ラベル
    {
      title: () => (
        <>
          <span>販売価格 </span>
          <span className="sort-span" onClick={createOnClikSort("selling_price")}>
            {searchFormik.values.sort === "selling_price_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "selling_price",
      key: "selling_price",
      align: "center",
      width:TableColumnWidth.short,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="selling_price"
                value={recode.selling_price}
                onChange={v => selectUpdateChange(v.target.value, recode, "selling_price")}
                className={inputClassName(productList[recode.key]?.selling_price !== recode?.selling_price, "short")}
              />
            ) : (
              <label>{recode.selling_price}</label>
            )}
          </div>
        </>
      ),
    },
    // 税率 テキスト or ラベル
    {
      title: () => (
        <>
          <span>税率 </span>
          <span className="sort-span" onClick={createOnClikSort("tax_rate")}>
            {searchFormik.values.sort === "tax_rate_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "tax_rate",
      key: "tax_rate",
      align: "center",
      width:TableColumnWidth.short,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Select
                getPopupContainer={triggerNode => triggerNode.parentNode}
                value={recode.tax_rate}
                className={inputClassName(productList[recode.key]?.tax_rate !== recode?.tax_rate, "short","line-height")}
                onChange={v => selectUpdateChange(v, recode, "tax_rate")}
                showArrow={true}
                menuItemSelectedIcon={<CheckOutlined style={{ color: "black" }} />}
              >
              {selectRate.map(o => (
                    <Select.Option value={o.value} key={o.value}>
                      {o.value}
                    </Select.Option>
                  )
                )}
            </Select>
              // <Input
              //   name="tax_rate"
              //   value={recode.tax_rate}
              //   onChange={v => selectUpdateChange(v.target.value, recode, "tax_rate")}
              //   className={inputClassName(productList[recode.key]?.tax_rate !== recode?.tax_rate, "small")}
              // />
            ) : (
              <label>{recode.tax_rate}</label>
            )}
          </div>
        </>
      ),
    },
    // 商品詳細URL テキスト or ラベル
    {
      title: () => (
        <>
          <span>商品詳細URL </span>
          <span className="sort-span" onClick={createOnClikSort("product_url")}>
            {searchFormik.values.sort === "product_url_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "product_url",
      key: "product_url",
      align: "center",
      width:TableColumnWidth.long,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="product_url"
                value={recode.product_url}
                onChange={v => selectUpdateChange(v.target.value, recode, "product_url")}
                className={inputClassName(productList[recode.key]?.product_url !== recode?.product_url, "long")}
              />
            ) : (
              <label>{recode.product_url}</label>
            )}
          </div>
        </>
      ),
    },
    // 商品画像URL テキスト or ラベル
    {
      title: () => (
        <>
          <span>商品画像URL </span>
          <span className="sort-span" onClick={createOnClikSort("image_url")}>
            {searchFormik.values.sort === "image_url_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "image_url",
      key: "image_url",
      align: "center",
      width:TableColumnWidth.long,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="image_url"
                value={recode.image_url}
                onChange={v => selectUpdateChange(v.target.value, recode, "image_url")}
                className={inputClassName(productList[recode.key]?.image_url !== recode?.image_url, "long")}
              />
            ) : (
              <label>{recode.image_url}</label>
            )}
          </div>
        </>
      ),
    },
    // カート追加用URL テキスト or ラベル
    {
      title: () => (
        <>
          <span>カート追加用URL </span>
          <span className="sort-span" onClick={createOnClikSort("cart_url")}>
            {searchFormik.values.sort === "cart_url_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "cart_url",
      key: "cart_url",
      align: "center",
      width:TableColumnWidth.long,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <Input
                name="cart_url"
                value={recode.cart_url}
                onChange={v => selectUpdateChange(v.target.value, recode, "cart_url")}
                className={inputClassName(productList[recode.key]?.cart_url !== recode?.cart_url, "long")}
              />
            ) : (
              <label>{recode.cart_url}</label>
            )}
          </div>
        </>
      ),
    },
    // 公開日 ラベル
    {
      title: () => (
        <>
          <span>公開日 </span>
          <span className="sort-span" onClick={createOnClikSort("release_date")}>
            {searchFormik.values.sort === "release_date_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "release_date",
      key: "release_date",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            {recode.delete_flag === 0 ? (
              <DatePicker
                size="small"
                className={
                  inputClassName(
                    productList[recode.key]?.release_date !== recode?.release_date,
                    "normal",
                    "date"
                  )
                }
                value={recode.release_date && !(recode.release_date === "")
                  ? moment(recode.release_date) :null}
                format={"YYYY.MM.DD"}
                placeholder="YYYY.MM.DD"
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={( _ , dateString ) =>{ 
                  dateString
                    ? selectUpdateChange(moment(dateString).format("YYYY-MM-DD"), recode, "release_date")
                    : selectUpdateChange("", recode, "release_date")
                }}
              />
            ) : (
              <label>{moment(recode.release_date).format("YYYY.MM.DD")}</label>
            )}
          </div>
        </>
      ),
    },

    // 更新日 ラベル
    {
      title: () => (
        <>
          <span>更新日 </span>
          <span className="sort-span" onClick={createOnClikSort("update_datetime")}>
            {searchFormik.values.sort === "update_datetime_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "update_datetime",
      key: "update_datetime",
      align: "center",
      width:TableColumnWidth.normal,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-product">
            <label>{moment(recode.update_datetime).format("YYYY.MM.DD")}</label>
          </div>
        </>
      ),

    },
  ];

  return (
    <>
      <div className="content-body">
      <Header />
        <div className="product-container nf-page">
            <h2>商品マスタ登録修正</h2>
            <div className="nf-search-area">
              <form
                className="search-area-input"
                onSubmit={searchFormik.handleSubmit}
              >
                <div className="search-item">
                  <GenernalInput
                    label="商品CD"
                    placeholder="商品CDを入力してください"
                    name="product_code"
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="SKUコード"
                    placeholder="SKUコードを入力してください"
                    name="sku_code"
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="JANコード"
                    placeholder="JANコードを入力してください"
                    name="jan_code"
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="ブランド"
                    mode="multiple"
                    name="brand_code"
                    placeholder={"ブランドを選択してください"}
                    options={brands}
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="商品名"
                    placeholder="商品名を入力してください"
                    name="product_name"
                    formik={searchFormik}
                  />
                  <div className="nf-input-wrapper nf-input-wrapper-middle">
                    <label class="nf-input-label text-align-center">更新日</label>
                    <div className="nf-input-date-area">
                      <DatePicker
                        size="small"
                        className="nf-input-date"
                        value={start_date}
                        format={"YYYY.MM.DD"}
                        placeholder=""
                        suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        onChange={( _ , dateString ) =>{ 
                          dateString && end_date
                            ? dateString > end_date.format('YYYY.MM.DD')
                              ? handlerFormikFieldChange(searchFormik, "start_date", end_date)
                              : handlerFormikFieldChange(searchFormik, "start_date", moment(dateString))
                            : handlerFormikFieldChange(searchFormik, "start_date", dateString ? moment(dateString) : "");
                        }}
                      />
                      <label className="nf-label">
                        〜
                      </label>
                      <DatePicker
                        size="small"
                        className="nf-input-date"
                        value={end_date}
                        format={"YYYY.MM.DD"}
                        placeholder=""
                        suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                        getPopupContainer={triggerNode => triggerNode.parentNode}
                        onChange={( _ , dateString ) =>{
                          dateString && start_date
                            ? dateString < start_date.format('YYYY.MM.DD')
                              ? handlerFormikFieldChange(searchFormik, "end_date", start_date)
                              : handlerFormikFieldChange(searchFormik, "end_date", moment(dateString))
                            : handlerFormikFieldChange(searchFormik, "end_date", dateString ? moment(dateString) : "");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="search-item-deleted">
                  <Button text="検索" type="submit" />
                  <GeneralCheckbox
                    label="削除済み"
                    className="search-item-checkbox"
                    checked={searchFormik.values.deleted_flg === 1}
                    onChange={() =>
                      handlerFormikFieldChange(
                        searchFormik,
                        "deleted_flg",
                        searchFormik.values.deleted_flg === 1 ? 0 : 1
                      )
                    }
                  />
                </div>
              </form>
            </div>
            <div className="nf-table-header">
              <div className="top-header">
                <div className={user_auth > "1" ? "button hidden" : "button"}>
                  <Button
                    text="CSV取込"
                    width="wider"
                    onClick={() => dispatch(uploadActions.openFileUploadModal(2))}
                  />
                </div>
              </div>
              <div className="bottom-header">
                <h3>検索結果一覧</h3>
                <div className="button">
                  <Button
                    text="データ更新"
                    width="wider"
                    disabled={ changeUpdateButtonStatus || selectUpdateButtonStatus ? false : true }
                    theme={changeUpdateButtonStatus || selectUpdateButtonStatus ? "black" : "light-black" }
                    onClick={() => dispatch(actions.openUpdateModal())}
                  />
                </div>
                <div className="button">
                  <Button
                    text="マスタ削除"
                    width="wider"
                    disabled={ selectUpdateButtonStatus ? false : true }
                    theme={selectUpdateButtonStatus ? "red" : "light-red" }
                    onClick={() => dispatch(actions.openDeleteModal())}
                  />
                </div>
              </div>
            </div>
            <div className="nf-table-product">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                scroll={{ x: 5000 }}
                dataSource={workProductList}
                onChange={pagination => {
                  const offset =
                    pagination.current === 1
                      ? 0
                      : pagination.pageSize * (pagination.current - 1);
                  dispatch(actions.saveOffset(offset));
                  searchFormik.setFieldValue("offset", offset);
                }}
                pagination={{
                  total: total,
                  pageSize: 10,
                  current: (searchParams?.offset || 0) / 10 + 1,
                  position: ["bottomCenter"],
                  showQuickJumper: false,
                  size: "small",
                }}
                sticky={{offsetHeader: 60}}
              />
            </div>
          </div>
        <DeleteModal
          modalVisible={productDeleteModalVisible}
          itemValue="商品"
          onCancel={() => dispatch(actions.closeDeleteModal())}
          onOk={() => bulkUpdate("delete")}
        />
        <SelectUpdateModal
          modalVisible={productUpdateModalVisible}
          item="商品"
          onCancel={() => dispatch(actions.closeUpdateModal())}
          onOk={() => bulkUpdate("update")}
        />
        <Footer />
      </div>
    </>
  );
};

export default Product;

import axios from "axios";
import qs from "qs";
import store from "../store";
import { actions } from "../slices/messageSlice";
import { actions as accountActions } from "../slices/accountSlice";
import { actions as loadingActions } from "../slices/loadingSlice";
import { actions as corporationActions } from "../slices/corporationSlice";
import { uploadActions } from "../slices/fileUploadSlice";
import React from "react";


const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
  paramsSerializer: (params) => qs.stringify(
    { ...params
      , benentKanriTime: new Date().getTime()
    }
    , { arrayFormat: "repeat" }),
});

const dispatchErrorMessage = (response) => {
  store.dispatch(
    actions.setMessage({
      type: response?.data?.code ?? "".indexOf("30003")  ? null : "エラー",
      messages: [
        response?.data?.display_message?.split('\n').map((str, index) => ( <React.Fragment key={index}>{str}<br /></React.Fragment>)) || "システムエラーが発生しました",
      ],
    })
  );
};

instance.interceptors.request.use(
  (config) => {
    const {
      user_code: operator_code,
      corporation_code,
      token,
    } = store.getState().account ?? {};
    const { multiLoadingFlg } = store.getState().loading ?? {}; 
    !multiLoadingFlg && store.dispatch(loadingActions.loadingOn())

    return config.method === "get"
      ? {
          ...config,
          params: {
            ...config.params,
            request_from: "2",
            ...(operator_code && { operator_code }),
            token,
          },
          headers: {
            ...config.headers,
            ...(corporation_code && { corporation_code }),
          },
        }
      : {
          ...config,
          ...(config.isFileUpload
            ? { data: config.data }
            : {
                data: {
                  ...config.data,
                  request_from: config.data.request_from == "3" ? config.data.request_from : "2",
                  ...(operator_code && { operator_code }),
                  token,
                },
              }),
          headers: {
            ...config.headers,
            ...(corporation_code && { corporation_code }),
          },
        };
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response,config) => {
    const { multiLoadingFlg } = store.getState().loading ?? {};
    !multiLoadingFlg && store.dispatch(loadingActions.loadingOff())
    return response.data;
  },
  async (error) => {
    if (error.config.responseType === 'arraybuffer') {
      // PDFダウンロードエラーの場合、エラー内容の変換を行う
      const blob = new Blob([ error.response.data ], { "type" : "text/plain" });
      const objStr = await blob.text();
      error.response.data = JSON.parse(objStr);
    }
    setTimeout(() => {
      !error?.config?.ignoreError && dispatchErrorMessage(error?.response);
    }, 0);
    store.dispatch(loadingActions.loadingOff());

    //ログアウト
    if(/30003/.test(error?.response?.data?.code) ||
      /30004/.test(error?.response?.data?.code) ||
      /40000/.test(error?.response?.data?.code) ||
      /40001/.test(error?.response?.data?.code)){
        store.dispatch(accountActions.clearAccount())
        store.dispatch(uploadActions.closeFileUploadModal())
      }
    //top画面
    if(/30005/.test(error?.response?.data?.code)){
      store.dispatch(accountActions.refleshAccount());
      store.dispatch(uploadActions.closeFileUploadModal());
      store.dispatch(corporationActions.closeEditModal());
    }

    return Promise.reject(error);
  }
);

export default instance;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Popover } from "antd";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../utils/axiosUtil";
import {
  actions,
  fetchChats,
  fetchViewCount,
  fetchLikeCount,
  fetchTelop,
  insertChat,
  liveFinish,
} from "../../slices/liveDeliverySlice";
import { handlerFormikFieldChange, unicodeUnescape } from "../../utils/fnUtil";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import GeneralInput from "../../components/generalInput";
import LiveCloseModal from "../../components/modal/liveCloseModal";
import LiveChats from "../../components/liveChats";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import moment from "moment";
import "./style.scss";
import RemainingTime from "./remainingTime";
import { useHistory } from "react-router";
import Sora from "sora-js-sdk";
import Amplify, { API } from 'aws-amplify'
import { onChatBySignalingFlag } from '../../graphql/subscriptions';
import { actions as messageActions } from "../../slices/messageSlice";
import baseAxios from "axios";

import awsExports from "../../aws-exports";
Amplify.configure(awsExports);

// GraphQLのデータ取得に遅延させる時間（サーバースペックによって変える）
const DELAY = 1500;
const TELOP_DELAY = 3000;

const LiveDelivery = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // ライブ配信の接続情報
  const [sendonly, setSendonly] = useState(null);

  // NGワード一覧
  const [ngWords, setNgWords] = useState([]);

  const {
    activeLive,
    chats,
    viewCount,
    likeCount,
    telop,
    liveCloseModalVisible,
    transitionLiveList,
    live_cd,
    useCamera,
    useMic,
    deviceId,
  } = useSelector(state => state.liveDelivery);

  const { corporation_code } = useSelector(state => state.account);

  // formik

  const formik = useFormik({
    initialValues: {
      chat_streamer: null,
      chat_message: "",
      telop_flag: false,
      popoverVisible: false,
    },
  });

  // GraphQL

  const dispatchChats = () => dispatch(fetchChats(fetchParams("chat", { field: "createdAt", direction: "asc" })));
  const dispatchView = () => dispatch(fetchViewCount(fetchParams("view")));
  const dispatchLike = () => dispatch(fetchLikeCount(fetchParams("like")));
  const dispatchTelop = () => dispatch(fetchTelop(fetchParams("telop", { field: "createdAt", direction: "desc" })));

  const fetchParams = (signaling_flag, sort) => {
    return {
      filter: {
        live_cd: { eq: location?.state?.live_cd },
        corporation_cd: { eq: corporation_code },
        signaling_flag: { eq: signaling_flag },
      },
      sort,
    };
  };

  const subscription = async (signaling_flag, callback) => {
    return API.graphql({
      query: onChatBySignalingFlag,
      variables: {
        live_cd: location?.state?.live_cd,
        corporation_cd: corporation_code,
        signaling_flag,
      }
    }).subscribe({
      next: () => {
        setTimeout(() => {
          callback();
        }, DELAY);
      }
    });
  };

  const addChat = async () => {
    if (!chat_streamer || !chat_message) return;
    // メールアドレスが含まれていたら除外
    if (/[a-zA-Z.0-9-._/#?!%&~+]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+/.test(chat_message)) {
      showMessage(["メールアドレスが含まれているため送信できません。"]);
      return;
    }

    // NGワードをはじく
    for (const ngWord of ngWords) {
      if (~chat_message.indexOf(ngWord)) {
        // 部分一致
        showMessage(["NGワードが含まれているため送信できません。", `NGワード : ${ngWord}`]);
        return;
      }
    }
    const user = activeLive?.streamer_info?.filter(e => e.user_code === chat_streamer)?.[0];
    dispatch(insertChat(insertParams(user, "chat", chat_message)));
    handlerFormikFieldChange(formik, "chat_message", "");
  };

  const addTelop = async () => {
    if (!telop) return;
    const user = activeLive?.streamer_info?.[0];
    dispatch(insertChat(insertParams(user, "telop", telop)));
  };

  const insertParams = (user, signaling_flag, signaling_text) => {
    return {
      input: {
        live_cd: location?.state?.live_cd,
        corporation_cd: corporation_code,
        user_cd: chat_streamer,
        user_name: user?.name_nickname || user?.user_name,
        signaling_text,
        sender_flag: "backoffice",
        signaling_flag,
      },
    };
  };

  // 配信系

  const initConnect = async () => {
    try {
      if (!location?.state?.live_cd || !location?.state?.deviceId) {
        showMessage(["配信情報の取得に失敗しました"]);
        transitionLive();
        return;
      }
      dispatch(actions.setParam({
        live_cd: location?.state?.live_cd,
        useCamera: location?.state?.camera ?? false,
        useMic: location?.state?.mic ?? false,
        deviceId: location?.state?.deviceId,
      }));

      const liveDetail = await axios.get("live/detail", { params: {
        live_code: location?.state?.live_cd,
        live_flag: 0,
      }, ignoreError: true });
      dispatch(actions.setLiveDetail(liveDetail.item));

      handlerFormikFieldChange(formik, "telop_flag", !!liveDetail.item.telop_flag);
      handlerFormikFieldChange(formik, "chat_streamer", liveDetail.item.streamer_info[0].user_code);

      const channel_id = liveDetail.item.channel_id;
      const sora_url = liveDetail.item.sora_url;

      const debug = false;
      const sora = Sora.connection(sora_url, debug);

      const metadata = {};
      const options = {
        audio: true,
        audioCodecType: 'OPUS',
        audioBitRate: 96,
        video: true,
        videoCodecType: 'H264',
        videoBitRate: 2000,
        multistream: false
      };
      const sendonly = sora.sendonly(channel_id, metadata, options);
      return sendonly;
    } catch (e) {
      console.error("error発生");
      console.error(e);
      throw e;
    }
  };

  const initTracks = async (video, constraints) => {
    const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
    const src = await sendonly.connect(mediaStream);
    video.srcObject = src;
  };

  const addTracks = async (constraints) => {
    const mediaStream = await sendonly?.stream;
    const replacedMediaStream = await navigator.mediaDevices.getUserMedia(constraints);
    if (constraints?.video) {
      await sendonly?.replaceVideoTrack(mediaStream, replacedMediaStream.getVideoTracks()[0]);
    } else {
      await sendonly?.replaceAudioTrack(mediaStream, replacedMediaStream.getAudioTracks()[0]);
    }
  };

  const stopTracks = async (tracks) => {
    if (tracks) {
      tracks.forEach(track => {
        track.enabled = false;
        setTimeout(async () => {
          track.stop();
        }, 1000);
      });
    }
  };

  // function

  const convertDisplayDate = date => moment(date).format("YYYY.MM.DD HH:mm");

  const transitionLive = () => history.push({ pathname: "/live" });

  const showMessage = messages => dispatch(messageActions.setMessage({ btnText: "閉じる", messages }));

  // action

  const onClickCamera = async () => {
    const video = document.querySelector('#local-video');
    const tracks = video?.srcObject?.getVideoTracks();
    await stopTracks(tracks);
    if (!useCamera) {
      if (!video?.srcObject) {
        await initTracks(video, { audio: useMic, video: deviceId ? { deviceId } : false });
      } else {
        await addTracks({ video: deviceId ? { deviceId } : false });
      }
    }
    history.replace(location.pathname, { ...location.state, camera: !useCamera });
    dispatch(actions.setCamera(!useCamera));
  };

  const onClickMic = async () => {
    const video = document.querySelector('#local-video');
    const tracks = video?.srcObject?.getAudioTracks();
    await stopTracks(tracks);
    if (!useMic) {
      if (!video?.srcObject) {
        await initTracks(video, { audio: true, video: useCamera ? (deviceId ? { deviceId } : false) : false });
      } else {
        await addTracks({ audio: true });
      }
    }
    history.replace(location.pathname, { ...location.state, mic: !useMic });
    dispatch(actions.setMic(!useMic));
  };

  const onPassTime = () => {
    if (!activeLive) return;
    // 残り時間マイナスの場合に呼ばれる、配信終了処理
    sendonly?.disconnect();
    dispatch(liveFinish({ live_code: activeLive.live_code }));
    showMessage(["終了時間になったため、", "ライブ配信が終了しました"]);
  };

  const onPassSession = async () => {
    const liveDetail = await axios.get("live/detail", { params: {
      live_code: live_cd,
      live_flag: 0,
    }, ignoreError: true });
    dispatch(actions.setLiveDetail(liveDetail.item));
  };

  // ライフサイクル

  useEffect(() => {
    // 動画の設定
    const video = document.querySelector('#local-video');
    let holdSendonly = null;
    initConnect().then(async sendonly => {
      if (!sendonly) return;
      setSendonly(sendonly);
      holdSendonly = sendonly;
      const camera = useCamera !== undefined ? useCamera : location?.state?.camera ?? false;
      const mic = useMic !== undefined ? useMic : location?.state?.mic ?? false;
      const paramDeviceId = deviceId ? deviceId : location?.state?.deviceId;
      if (!mic && !camera) return null;
      const mediaStream = await navigator.mediaDevices.getUserMedia({ audio: mic, video: camera ? (paramDeviceId ? { deviceId: paramDeviceId } : true) : false });
      return await sendonly?.connect(mediaStream);
    }).then(stream => video.srcObject = stream).catch(e => {
      showMessage(["配信情報の取得に失敗しました"]);
      transitionLive();
    });

    // 初期値取得
    dispatchChats();
    dispatchView();
    dispatchLike();
    setTimeout(() => {
      dispatchTelop();
    }, TELOP_DELAY);

    // fetchを開始
    const subscriptionChats = subscription('chat', dispatchChats);
    const subscriptionViewCount = subscription('view', dispatchView);
    const subscriptionLikeCount = subscription('like', dispatchLike);
    const subscriptionTelop = subscription('telop', dispatchTelop);

    // NGワード取得
    baseAxios.get(process.env.REACT_APP_NG_WORD_URL, {})
      .then(e => {
        const result = e?.data;
        if (!result) return;
        setNgWords(result.split('\n').filter(e => e).map(e => unicodeUnescape(e)));
      })
      .catch(e => {
        console.log("エラー", e);
      });

    return () => {
      // 配信画面閉じる
      holdSendonly?.disconnect();
      if (video) {
        const tracks = [
          ...(video?.srcObject?.getVideoTracks() || []),
          ...(video?.srcObject?.getAudioTracks() || []),
        ];
        tracks.forEach(track => track.stop());
      }

      // fetchをやめる
      subscriptionChats && subscriptionChats?.then(e => e.unsubscribe());
      subscriptionViewCount && subscriptionViewCount?.then(e => e.unsubscribe());
      subscriptionLikeCount && subscriptionLikeCount?.then(e => e.unsubscribe());
      subscriptionTelop && subscriptionTelop?.then(e => e.unsubscribe());

      // 状態をクリア
      dispatch(actions.clear());
    };
  }, []);

  useEffect(() => {
    if (transitionLiveList) {
      transitionLive();
    }
    return () => dispatch(actions.clear());
  }, [transitionLiveList]);

  const {
    chat_streamer,
    chat_message,
    popoverVisible,
    telop_flag,
  } = formik.values;

  return (
    <div className="content-body">
      <Header />
      <div className="live-delivery-container">
        <div className="flex-row">
          <div className="delivery-area">
            <RemainingTime
              limit={activeLive?.end_datetime}
              displayDiff={300000}
              onPassTime={onPassTime}
              onPassSession={onPassSession}
            />
          	<video id="local-video" autoPlay muted></video>
          </div>
          <div className="chat-parent">
            <LiveChats chats={chats} streamers={activeLive?.streamer_info} viewCount={viewCount} likeCount={likeCount} />
          </div>
        </div>
        <div className="flex-row">
          <div className="detail-area">
            <Popover
              content={
                <>
                  <div style={{ cursor: "pointer" }} className="media-control" onClick={onClickCamera}>
                    <span>カメラを{!useCamera ? "オン" : "オフ"}にする</span>
                    <Icon
                      component={useCamera ? Icons.CameraON : Icons.CameraOFF}
                      className="control-icon"
                    />
                  </div>
                  <div style={{ cursor: "pointer" }} className="media-control" onClick={onClickMic}>
                    <span>マイクを{!useMic ? "オン" : "オフ"}にする</span>
                    <Icon
                      component={useMic ? Icons.MikeON : Icons.MikeOFF}
                      className="control-icon"
                    />
                  </div>
                </>
              }
              trigger="click"
              visible={popoverVisible}
              placement="topLeft"
              overlayClassName="detail-area-popover"
              onVisibleChange={(e) => handlerFormikFieldChange(formik, "popoverVisible", e)}
            >
              <Icon
                component={Icons.MenuB}
                className="media-control-open-icon"
                onClick={() => handlerFormikFieldChange(formik, "popoverVisible", !popoverVisible)}
              />
            </Popover>
            <Button
              text="配信終了"
              theme="safetyyellow"
              style={{ flex: 2 }}
              onClick={() => dispatch(actions.openLiveClose())}
            />
            <div className="title-area">
              <table>
                <tbody>
                  <tr>
                    <td className="title">{activeLive?.title}</td>
                  </tr>
                  <tr>
                    <td className="date">{convertDisplayDate(activeLive?.start_datetime)} ~ {convertDisplayDate(activeLive?.end_datetime)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="chat-input-area">
            <div className="chat-input-area-inner">
              <div className="streamer-select-wrapper">
                <GeneralInput
                  placeholder="配信者"
                  options={activeLive?.streamer_info?.map(e => ({ label: e.name_nickname || e.user_name, value: e.user_code }))}
                  mode={null}
                  name={'chat_streamer'}
                  formik={formik}
                />
              </div>
              <div className="chat-input-wrapper">
                <GeneralInput
                  placeholder="コメントする"
                  styleType="inline-grey"
                  mode={null}
                  name={'chat_message'}
                  formik={formik}
                />
              </div>
              <Button
                text={<Icon
                  component={Icons.PaperAirplaneW}
                  style={{ fontSize: '22px' }}
                />}
                theme="black"
                disabled={!chat_streamer || !chat_message}
                onClick={addChat}
                style={{ height: '30px', width: '33px' }}
              />
            </div>
          </div>
        </div>
        <div className="flex-row">
          {telop_flag &&
            <div className="telop-area">
              <div className="telop-area-inner">
                <GeneralInput
                  label="テロップ"
                  placeholder="テロップ表示したい文言を入力してください"
                  styleType="inline-grey"
                  mode={null}
                  requiredItem={true}
                  onChange={e => dispatch(actions.setTelop(e.target.value))}
                  value={telop}
                />
              </div>
              <Button
                text="更新"
                theme="black"
                style={{ flex: 1 }}
                disabled={!telop}
                onClick={addTelop}
              />
            </div>
          }
          <div className="empty-area" />
        </div>
      </div>
      <LiveCloseModal
        modalVisible={liveCloseModalVisible}
        onCancel={() => dispatch(actions.closeLiveClose())}
        onOk={() => dispatch(liveFinish({ live_code: activeLive.live_code }))}
      />
      <Footer />
    </div>
  );
};

export default LiveDelivery;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { downloadFile } from "../utils/fnUtil";
import { actions as messageActions } from "./messageSlice";
import moment from "moment";

export const fetchAnalyzeSns = createAsyncThunk(
  "fetchAnalyzeSns",
  async (params) => {
    return await axios.get("analyze/sns/list", { params });
  }
);

export const fetchSnsFavoriteItem = createAsyncThunk(
  "fetchSnsFavoriteItem",
  async (params) => axios.get("csv/favorite_item", { params })
);

export const updateCsvSnsFavoriteItem = createAsyncThunk(
  "updateCsvSnsFavoriteItem",
  async (params, { dispatch }) => {
    const updateFavoriteRes = await axios.post("csv/favorite_item/update", params);
    if (updateFavoriteRes?.code === "0")
      dispatch(
        messageActions.setMessage({
          messages:["お気に入り登録が完了しました"],
        })
      );
    return params.favorite;
  }
);

export const csvDownloadSns = createAsyncThunk(
  "csvDownloadSns",
  async (params) => await axios.get("csv/download", { params })
);

const initialSearchParams = {
  start_date: "",
  end_date: "",
  user_code: [],
  sns_type: [],
  offset: 0,
  count: 10,
  sort: "sales_amount_desc",
};

const initialState = {
  analyzeList: [],
  filterList: {
    user_info: [],
    sns_type: [],
  },
  showFields: [],
  favoriteItem: {},
  csvDownloadModalVisible: false,
  selectColumsVisible: false,
  filterUserVisible: false,
  filterSnsVisible: false,
  infomationModal: false,
  infomationTitle: "",
  searchParams: initialSearchParams,
  total: 0,
};

export const analyzeSnsListSlice = createSlice({
  name: "analyzeSnsList",
  initialState: initialState,
  reducers: {
    saveSearchParam: (state, { payload }) => ({
      ...state,
      filterUserVisible: false,
      filterSnsVisible: false,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveShowFields: (state, { payload }) => ({
      ...state,
      selectColumsVisible: false,
      showFields: payload,
    }),
    openFilterUserModal: (state) => ({
      ...state,
      filterUserVisible: true,
    }),
    openFilterSnsModal: (state) => ({
      ...state,
      filterSnsVisible: true,
    }),
    openSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: true,
    }),
    openCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: true,
    }),
    openInfomationModal: (state, { payload }) => ({
      ...state,
      infomationModal: true,
      infomationTitle: payload,
    }),
    closeFilterUserModal: (state) => ({
      ...state,
      filterUserVisible: false,
    }),
    closeFilterSnsModal: (state) => ({
      ...state,
      filterSnsVisible: false,
    }),
    closeSelectColumsModal: (state) => ({
      ...state,
      selectColumsVisible: false,
    }),
    closeCsvDownloadModal: (state) => ({
      ...state,
      csvDownloadModalVisible: false,
    }),
    closeInfomationModal: (state) => ({
      ...state,
      infomationModal: false,
    }),
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyzeSns.fulfilled, (state, { payload }) => ({
      ...state,
      analyzeList: payload?.item ?? [],
      filterList: payload?.filter_list
        ? {
            user_info: payload.filter_list?.user_info ?? [],
            sns_type: payload.filter_list?.sns_type ?? [],
          }
        : { user_info: [], sns_type: [] },
      total: (payload?.params?.offset == 0 ? payload?.total : state?.total) ?? 0,
    }));
    builder.addCase(fetchSnsFavoriteItem.fulfilled, (state, { payload }) => ({
      ...state,
      favoriteItem: payload?.item?.favorite ?? {},
    }));
    builder.addCase(
      updateCsvSnsFavoriteItem.fulfilled,
      (state, { payload }) => {
        return {
          ...state,
          favoriteItem: payload,
        };
      }
    );
    builder.addCase(csvDownloadSns.fulfilled, (state, { payload }) => {
      if (typeof payload === "string") {
        downloadFile(
          payload,
          `社員SNS別分析_${moment().format("YYYYMMDDHHmmss")}.csv`
        );
      }
      return {
        ...state,
        infomationModal: payload?.code === "-1",
        infomationTitle: "出力データが存在しません",
        csvDownloadModalVisible: false,
      };
    });
  },
});

export const actions = analyzeSnsListSlice.actions;

export default analyzeSnsListSlice.reducer;

import { Modal } from "antd";
import Button from "../../button";
import { useEffect, useState } from "react";
import { Checkbox, Row, Col } from "antd";
import "./style.scss";

/*項目選択モダール */

const SelectColumsModal = ({
  title,
  modalVisible,
  closeModal,
  onDisplay,
  options,
  chekedValues,
  colSpan,
  initialize = true,
}) => {
  const [checkedList, setCheckedList] = useState(chekedValues);
  const [checkAllFlg, setCheckAllFlg] = useState();
  const onCheckAllChange = (e) => {
    const checkVals = options.map((option) => option?.value);
    setCheckedList(e.target.checked ? checkVals : []);
    setCheckAllFlg(!checkAllFlg);
  };
  const checkBoxesOnChange = (checkedValues) => {
    setCheckedList(checkedValues);
    setCheckAllFlg(options.length === checkedValues.length);
  };
  useEffect(() => {
    if (initialize) {
      setCheckedList(chekedValues);
      setCheckAllFlg(options.length === chekedValues.length);
    } else {
      setCheckAllFlg(options.length === checkedList.length);
    }
  }, [modalVisible]);

  return (
    <>
      <Modal
        visible={modalVisible}
        centered
        footer={null}
        closable={false}
        width={450}
        onCancel={closeModal}
      >
        <div className="selectColumsModal-content">
          <div className="contents-area">
            <div>{title}</div>
            <Checkbox
              onChange={onCheckAllChange}
              checked={checkAllFlg}
              style={{ margin: "20px auto" }}
            >
              全選択
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox.Group onChange={checkBoxesOnChange} value={checkedList}>
                <Row>
                  {options.map((col) => {
                    return (
                      <Col span={colSpan} style={{ marginBottom: 10 }}>
                        {col && <Checkbox value={col.value}>{col.label}</Checkbox>}
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </div>
          </div>
          <div className="button-area">
            <Button
              text="閉じる"
              onClick={() => closeModal()}
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
              theme={"white"}
            />
            <Button
              text="表示"
              onClick={() => {
                onDisplay(checkedList);
              }}
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectColumsModal;

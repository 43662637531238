import { Upload, message } from "antd";
import { getBase64 } from "../../utils/fnUtil";

const BaseUpload = ({ children, onChange, ...rest }) => {
  const beforeUpload = file => {
    const isFormateValid = [
      "image/png",
      "image/gif",
      "image/jpg",
      "image/jpeg",
    ].includes(file.type);
    if (!isFormateValid) {
      message.error("png,gif,jpg,jpeg形式でアップロードしてください");
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 <= 5 ;

    if (!isLt5M) {
      message.error("画像は5M以下でアップロードしてください");
      return false;
    }

    getBase64(file, url => onChange(url));

    return false;
  };

  return (
    <Upload
      accept={".png,.gif,.jpg,.jpeg"}
      beforeUpload={beforeUpload}
      showUploadList={false}
      {...rest}
    >
      {children}
    </Upload>
  );
};

export default BaseUpload;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions } from "./messageSlice";
import axios from "../utils/axiosUtil";
import { downloadFile } from "../utils/fnUtil";


const createFormData = params => {
  const formData = new FormData();

  const jsonData = {
    token: params.token,
    screen_type: params.screenType,
    request_from: "2",
    operator_code: params.userCode,
    user_code: params.userCode,
  };

  formData.append("csv_file", params.fileInfo);
  formData.append(
    "jsonValue",
    new Blob([JSON.stringify(jsonData)], { type: "application/json" })
  );

  return formData;
};

export const csvCheck = createAsyncThunk(
  "csvCheck",
  async params =>
    await axios.post("/csv/check", createFormData(params), {
      headers: { "content-type": "multipart/form-data" },
      isFileUpload: true,
    })
);

export const csvDownload = createAsyncThunk(
  "csvDownload",
  async params =>{
    const filetype = ["店舗マスタサンプル","社員マスタサンプル","商品マスタサンプル","報酬レートサンプル"];
    const fileRes = await axios.get("/csv/download/uploadFile",{params});
    if (typeof fileRes === "string") {
      downloadFile(fileRes,`${filetype[params?.screenType]}.csv`);
    }
  }
);


export const csvUpload = createAsyncThunk(
  "csvUpload",
  async (params, { dispatch }) => {
    const uploadRes = await axios.post("/csv/upload", createFormData(params), {
      headers: { "content-type": "multipart/form-data" },
      isFileUpload: true,
    });

    uploadRes?.code === "0" &&
      dispatch(actions.setMessage({ messages: ["登録が完了しました"] }));

    dispatch(uploadActions.closeFileUploadModal())
  }
);

const initialState = {
  fileUploadModalVisible: false,
  screenType: null,
  registerEnable: false,
  errorCount: 0,
  successCount: 0,
  checkResult: [],
};

export const fileUploadSlice = createSlice({
  name: "csvUpload",
  initialState,
  reducers: {
    openFileUploadModal: (state, { payload }) => ({
      ...state,
      fileUploadModalVisible: true,
      screenType: payload,
    }),
    closeFileUploadModal: (state, { payload }) => ({
      ...state,
      checkResult: [],
      registerEnable: false,
      successCount: 0,
      errorCount: 0,
      fileUploadModalVisible: false,
    }),
    formatParam: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(csvCheck.fulfilled, (state, { payload }) => {
      return {
        ...state,
        checkResult: payload?.item ?? [],
        registerEnable: payload?.success_count > 0,
        successCount: payload?.success_count ?? 0,
        errorCount: payload?.error_count ?? 0,
      };
    });
  },
});

export const uploadActions = fileUploadSlice.actions;

export default fileUploadSlice.reducer;

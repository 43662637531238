import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { tokenLogin } from "../slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";

const GuestRoute = (props) => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.account);
  useEffect(() => {
    if (account.code === null) return;
    dispatch(
      tokenLogin({
        request_from: 2,
        corporation_code: account?.corporation_code,
        token: account?.token,
      })
    );
  }, [props]);
  return account.code === "A00000" || account.code === "A00001" ? (
    <Redirect to="/" />
  ) : account.code === null ||
      account.code === "A20000" ||
      account.code === "A20001" ||
      account.code === "A20002" ? (
      <Route {...props} />
  ) : (
    <></>
  );
};

export default GuestRoute;

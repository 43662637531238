import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import Header from "../../components/header";
import GenernalInput from "../../components/generalInput";
import Button from "../../components/button";
import { useFormik } from "formik";
import "./style.scss";

import {
  actions,
  testAnalysisInit,
  fetchCoordinate,
  fetchArticles,
  fetchLive,
  fetchCoordinateDetail,
  fetchArticlesDetail
} from "../../slices/testAnalysis";

const BenentPostAnalysisConfig = {
  API_URL: "http://localhost:8080/nf/analysis/",
};

const BenentPostAnalysis = {
  GLOVAL_COOKIE_NAME: "BenentPostAnalysis",
  PV_COOKIE_NAME: "BenentTestContent",
  SNS_COOKIE_NAME: "BenentTestSns",
  PURCHASE_COOKIE_NAME: "BenentTestPurchase",
  CART_COOKIE_NAME: "BenentTestCart",
  MOVIE_COOKIE_NAME: "BenentTestMovie",
  COOKIE_MAX_AGE: 604800, //cookieの残存期間（秒）
  isNull: (val) => {
    return val === null || val === "" || val === undefined;
  },
  isPostPvData: (current, cookie) => {
    return (
      current.content_type !== cookie?.content_type ||
      current.content_code !== cookie?.content_code ||
      current.user_id !== cookie?.user_id ||
      current.uuid !== cookie?.uuid
    );
  },
  isPostSnsData: (current, cookie) => {
    return (
      current.share_parameter !== cookie?.share_parameter ||
      current.content_type !== cookie?.content_type ||
      current.content_code !== cookie?.content_code ||
      current.user_id !== cookie?.user_id ||
      current.uuid !== cookie?.uuid
    );
  },
  isPostPurchaseData: (current, cookie) => {
    return current.order_id !== cookie?.order_id;
  },
  isPostAddCartData: (current, cookie) => {
    return (
      current.product_code !== cookie?.product_code ||
      current.live_code !== cookie?.live_code ||
      current.live_flag !== cookie?.live_flag ||
      current.user_id !== cookie?.user_id ||
      current.uuid !== cookie?.uuid
    );
  },
  isPostPlayCountData: (current, cookie) => {
    return (
      current.live_code !== cookie?.live_code ||
      current.live_flag !== cookie?.live_flag ||
      current.user_id !== cookie?.user_id ||
      current.uuid !== cookie?.uuid
    );
  },
  getCookieParam: (cookieName) => {
    let result = null;
    let allcookies = document.cookie;
    if (!BenentPostAnalysis.isNull(allcookies)) {
      allcookies = allcookies.split(";");
      allcookies.forEach((cookie) => {
        const key = cookie.split(/(?=^[^=]+?)=/)[0];
        if (key === `${BenentPostAnalysis.GLOVAL_COOKIE_NAME}_${cookieName}`) {
          result = JSON.parse(cookie.split(/(?=^[^=]+?)=/)[1]);
        }
      });
    }
    return result;
  },
  setCookieParam: (data, cookieName) => {
    document.cookie = `${
      BenentPostAnalysis.GLOVAL_COOKIE_NAME
    }_${cookieName}=${JSON.stringify(data)};max-age=${
      BenentPostAnalysis.COOKIE_MAX_AGE
    }`;
  },
  postContentsPv: async (tagData) => {
    BenentPostAnalysis.main(
      BenentPostAnalysis.PV_COOKIE_NAME,
      tagData,
      BenentPostAnalysis.isPostPvData,
      "pv"
    );
  },
  postSnsPv: async (tagData) => {
    BenentPostAnalysis.main(
      BenentPostAnalysis.SNS_COOKIE_NAME,
      tagData,
      BenentPostAnalysis.isPostSnsData,
      "sns"
    );
  },
  postPurchase: async (tagData) => {
    BenentPostAnalysis.main(
      BenentPostAnalysis.PURCHASE_COOKIE_NAME,
      tagData,
      BenentPostAnalysis.isPostPurchaseData,
      "purchase"
    );
  },
  postAddCart: async (tagData) => {
    BenentPostAnalysis.main(
      BenentPostAnalysis.CART_COOKIE_NAME,
      tagData,
      BenentPostAnalysis.isPostAddCartData,
      "addcart"
    );
  },
  postPlayCount: async (tagData) => {
    BenentPostAnalysis.main(
      BenentPostAnalysis.MOVIE_COOKIE_NAME,
      tagData,
      BenentPostAnalysis.isPostPlayCountData,
      "playcount"
    );
  },
  main: async (cookieName, tagData, isPostFunc, apiUrl) => {
    try {
      if (!isPostFunc(tagData, BenentPostAnalysis.getCookieParam(cookieName)))
        return;
      await fetch(BenentPostAnalysisConfig.API_URL + apiUrl, {
        method: "POST",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json",
          corporation_code: tagData?.corporation_code,
        },
        body: JSON.stringify({
          request_from: "0",
          ...tagData,
        }),
      });
      BenentPostAnalysis.setCookieParam(tagData, cookieName);
    } catch (_error) {
      console.error(_error);
    }
  },
};

const TestAnalysis = () => {
  const { contentList, liveList, brands, contentOptions, liveOptions, productList, edit, analyzeList, contentSearchParams, liveSearchParams } = useSelector((state) => state.testAnalysis);
  const dispatch = useDispatch();
  // 初期データ取得
  const formik = useFormik({
    initialValues: edit,
    // onSubmit: (values) => {
    //   BenentPostAnalysis.postContentsPv(values)},
  });
  const {
    brand_code,
    content_type,
    content_code,
    select_product,
    product_info,
    product_code,
    live_code,
    live_flag
  } = formik.values;
  useEffect(() => {
    dispatch(testAnalysisInit());
    return () => dispatch(actions.clear());
  }, []);
  useUpdateEffect(() => {
    // dispatch(actions.saveSearchParam({
    //   brand_code: brand_code ?? "", 
    //   sort: content_type ?? "" ? "coordinate_code_asc" : "article_code_asc"
    // }));
    if(brand_code && content_type){
      content_type === "1" 
        ? dispatch(fetchCoordinate({
          ...contentSearchParams,
          brand_code: brand_code,
          sort: "coordinate_code_asc",
          count: 0,
          display_item:1
        })) : dispatch(fetchArticles({
          ...contentSearchParams,
          brand_code: brand_code,
          sort: "article_code_asc",
          count: 0,
          display_item:1
        }))
    }else if(brand_code && live_flag){
      dispatch(fetchLive({
        ...liveSearchParams,
        brand_code: brand_code,
        sort: "live_code_asc",
        live_flag: live_flag
      }))
    }
  }, [brand_code, content_type, live_flag, dispatch]);
  useUpdateEffect(() => {
    dispatch(actions.saveProductsList(
      contentList.filter(x => x?.coordinate_code === content_code || x?.article_code === content_code)[0]?.products?.map(g => ({
        ...g,
        label: g.product_code,
        value: g.sku_code,
      }))
    ));
  }, [content_code, dispatch]);
  useUpdateEffect(() => {
    dispatch(actions.saveProductsList(
      liveList.filter(x => x?.live_code === live_code)[0]?.products?.map(g => ({
        ...g,
        label: g.product_code+" "+g.product_name,
        value: g.product_code,
      }))
    ));
  }, [live_code, dispatch]);
  useUpdateEffect(() => {
    console.log(productList)
    console.log(productList)
    if(select_product){
      if(productList?.filter(x => x?.sku_code === select_product)[0]?.selling_price){
        const work = product_info 
        ? product_info+","+select_product+":"+productList.filter(x => x?.sku_code === select_product)[0]?.selling_price+":1"
        : select_product+":"+productList?.filter(x => x?.sku_code === select_product)[0]?.selling_price+":1"
       handlerFormikFieldChange(formik, "product_info", work);
      } else {
        const work = product_info 
        ? product_info+","+select_product+":"+"6300:1" 
        : select_product+":"+"6300:1"
       handlerFormikFieldChange(formik, "product_info", work);  
      }
    }
  }, [select_product, dispatch]);

  const snsPvformik = useFormik({
    onSubmit: (values) => BenentPostAnalysis.postSnsPv(values),
  });
  const cvformik = useFormik({
    onSubmit: (values) => BenentPostAnalysis.postPurchase(values),
  });
  return (
    <div className="content-body">
      <Header />
      <div className="nf-page">
        <div className="testAnalysis-container">
          <form className="search-area" onSubmit={formik.handleSubmit}>
            コンテンツPV計測
            <div className="search-item">
              <GenernalInput
                label="法人CD"
                formik={formik}
                name={"corporation_code"}
              />
              <GenernalInput
                label="ブランドCD"
                formik={formik}
                name={"brand_code"}
                options={brands}
                mode={null}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "content_code", null);
                }}
              />
              <GenernalInput
                label="コンテンツ種別"
                mode={null}
                name={"content_type"}
                formik={formik}
                options={contentOptions}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "content_code", null);
                }}
              />
              <GenernalInput
                label="コンテンツCD"
                formik={formik}
                options={contentList}
                mode={null}
                name={"content_code"}
              />
              <GenernalInput
                label="ユーザーID(EC顧客ID)"
                formik={formik}
                name={"user_id"}
              />
              <GenernalInput
                label="UUID"
                formik={formik}
                name={"uuid"}
              />
            </div>
            <Button text="POST!!!" onClick={()=>{BenentPostAnalysis.postContentsPv(formik.values)}} />
          </form>
          {/* <form className="search-area" onSubmit={formik.handleSubmit}> */}
          <form className="search-area">
            SNS PV計測
            <div className="search-item">
              <GenernalInput
                label="法人CD"
                formik={formik}
                name={"corporation_code"}
              />
              <GenernalInput
                label="ブランドCD"
                formik={formik}
                name={"brand_code"}
                options={brands}
                mode={null}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "content_code", null);
                }}
              />
              <GenernalInput
                label="シェアURLパラメータ"
                formik={formik}
                name={"share_parameter"}
                options={analyzeList}
                mode={null}
              />
              <GenernalInput
                label="着地SNS種別"
                mode={null}
                name={"content_type"}
                formik={formik}
                options={contentOptions}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "content_code", null);
                }}
              />
              <GenernalInput
                label="コンテンツCD"
                formik={formik}
                options={contentList}
                mode={null}
                name={"content_code"}
              />
              <GenernalInput
                label="ユーザーID(EC顧客ID)"
                formik={formik}
                name={"user_id"}
              />
              <GenernalInput
                label="UUID"
                formik={formik}
                name={"uuid"}
              />
            </div>
            <Button text="POST!!!" onClick={()=>{BenentPostAnalysis.postSnsPv(formik.values)}} />
          </form>
          {/* <form className="search-area" onSubmit={formik.handleSubmit}> */}
          <form className="search-area">
            CV計測
            <div className="search-item">
              <GenernalInput
                label="法人CD"
                formik={formik}
                name={"corporation_code"}
              />
              <GenernalInput
                label="ブランドCD"
                formik={formik}
                name={"brand_code"}
                options={brands}
                mode={null}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "content_code", null);
                }}
              />
              <GenernalInput
                label="ECサイト側の購入ID"
                formik={formik}
                name={"order_id"}
              />
              <GenernalInput
                label="商品情報"
                formik={formik}
                name={"select_product"}
                options={productList ?? [].map(g => ({
                  ...g,
                  label: g.product_name,
                  value: g.product_code,
                }))}
                mode={null}
              />
              <GenernalInput
                label="ユーザーID(EC顧客ID)"
                formik={formik}
                name={"user_id"}
              />
              <GenernalInput
                label="UUID"
                formik={formik}
                name={"uuid"}
              />
              <Button text="消す" onClick={()=>{handlerFormikFieldChange(formik, "product_info", null);}} />

              <span>{product_info}</span>
            </div>
            <Button text="POST!!!" onClick={()=>{BenentPostAnalysis.postPurchase(formik.values)}} />
          </form>
          <form className="search-area">
            カート追加計測
            <div className="search-item">
              <GenernalInput
                label="法人CD"
                formik={formik}
                name={"corporation_code"}
              />
              <GenernalInput
                label="ブランドCD"
                formik={formik}
                name={"brand_code"}
                options={brands}
                mode={null}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "content_code", null);
                }}
              />
              <GenernalInput
                label="商品CD"
                formik={formik}
                name={"product_code"}
                options={productList ?? [].map(g => ({
                  ...g,
                  label: g.product_code +" "+ g.product_name,
                  value: g.product_code,
                }))}
                mode={null}
              />
              <GenernalInput
                label="ライブCD"
                mode={null}
                name={"live_code"}
                options={liveList}
                formik={formik}
              />
              <GenernalInput
                label="動画種別"
                formik={formik}
                options={liveOptions}
                mode={null}
                name={"live_flag"}
              />
              <GenernalInput
                label="ユーザーID(EC顧客ID)"
                formik={formik}
                name={"user_id"}
              />
              <GenernalInput
                label="UUID"
                formik={formik}
                name={"uuid"}
              />
              <GenernalInput
                label="数量"
                formik={formik}
                name={"qty"}
              />
            </div>
            <Button text="POST!!!" onClick={()=>{BenentPostAnalysis.postAddCart(formik.values)}} />
          </form>
          <form className="search-area">
            動画再生計測
            <div className="search-item">
              <GenernalInput
                label="法人CD"
                formik={formik}
                name={"corporation_code"}
              />
              <GenernalInput
                label="ブランドCD"
                formik={formik}
                name={"brand_code"}
                options={brands}
                mode={null}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "content_code", null);
                }}
              />
              <GenernalInput
                label="ライブCD"
                mode={null}
                name={"live_code"}
                formik={formik}
                options={liveList}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "live_code", null);
                }}
              />
              <GenernalInput
                label="動画種別"
                formik={formik}
                options={liveOptions}
                mode={null}
                name={"live_flag"}
              />
              <GenernalInput
                label="ユーザーID(EC顧客ID)"
                formik={formik}
                name={"user_id"}
              />
              <GenernalInput
                label="UUID"
                formik={formik}
                name={"uuid"}
              />
            </div>
            <Button text="POST!!!" onClick={()=>{BenentPostAnalysis.postPlayCount(formik.values)}} />
          </form>
        </div>
      </div>
    </div>
  );
};

export default TestAnalysis;

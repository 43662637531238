import { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Upload, Image, DatePicker, Input } from "antd";
import moment from "moment";
import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import Icon from "@ant-design/icons";
import GeneralInput from "../../generalInput";
import BaseUpload from "../../baseUpload";
import noimage from "../../../images/noimage.png";
import Button from "../../button";
import { shopActions, updateShop, fetchShops } from "../../../slices/shopsSlice";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";
import { createTimeOptions, getBase64, handlerFormikFieldChange } from "../../../utils/fnUtil";
import Yup from "../../../utils/yupUtil";
import IconImage from "../../../constants/Icons";
import prefecturesList from "../../../constants/prefectures.json"
import baseUrl from "../../../constants/NetfreamAPIURL"

import "./style.scss";

const ShopEditModal = () => {
  const dispatch = useDispatch();
  const { editMode, editModalVisible, activeShop, brands, searchParams } = useSelector(state => state.shops);

  const formik = useFormik({
    initialValues: {
      shop_code: activeShop?.shop_code ?? "",
      cooperation_shop_code: activeShop?.cooperation_shop_code ?? "",
      icon_image_url: activeShop?.icon_image_url ?? "",
      brand_code: activeShop?.brand_code ?? [],
      shop_name: activeShop?.shop_name ?? "",
      shop_name_kana: activeShop?.shop_namekana ?? "",
      ec_display_flag: activeShop?.ec_display_flag ?? 0,
      zip_code1: activeShop?.zip?.number1 ?? "",
      zip_code2: activeShop?.zip?.number2 ?? "",
      prefectures: prefecturesList.prefectures.filter((data)=>{if(activeShop?.prefectures === data.prefecturesCode) return data}),
      address1: activeShop?.municipality ?? "",
      address2: activeShop?.house_number ?? "",
      address3: activeShop?.house_info ?? "",
      tel1: activeShop?.tel?.number1 ?? "",
      tel2: activeShop?.tel?.number2 ?? "",
      tel3: activeShop?.tel?.number3 ?? "",
      fax1: activeShop?.fax?.number1 ?? "",
      fax2: activeShop?.fax?.number2 ?? "",
      fax3: activeShop?.fax?.number3 ?? "",
      opening_time1: activeShop?.business_hours1?.start ?? "",
      closing_time1: activeShop?.business_hours1?.end ?? "",
      sales_time_remark1: activeShop?.business_hours1?.description ?? "",
      opening_time2: activeShop?.business_hours2?.start ?? "",
      closing_time2: activeShop?.business_hours2?.end ?? "",
      sales_time_remark2: activeShop?.business_hours2?.description ?? "",
      sunday: activeShop?.shop_holidays?.sunday ?? 0,
      monday: activeShop?.shop_holidays?.monday ?? 0,
      tuesday: activeShop?.shop_holidays?.tuesday ?? 0,
      wednesday: activeShop?.shop_holidays?.wednesday ?? 0,
      thursday: activeShop?.shop_holidays?.thursday ?? 0,
      friday: activeShop?.shop_holidays?.friday ?? 0,
      saturday: activeShop?.shop_holidays?.saturday ?? 0,
      irregular_holiday: activeShop?.irregular_holidays ?? "",
      link: activeShop?.link ?? "",
      appeal: activeShop?.description ?? "",
      opening_date: activeShop?.start_date ? moment(activeShop?.start_date).format("YYYY.MM.DD") : moment(),
      closed_date: activeShop?.start_date ? moment(activeShop?.end_date).format("YYYY.MM.DD") : moment("2999-12-31"),
      location1: null,
      location2: null,
      registration_button: false
    },
    validationSchema: Yup.object({
      shop_code: Yup.string().matches(/^[0-9a-zA-Z]+$/,'半角英数字で入力してください').required(),
      brand_code: Yup.array().min(1,`必須項目です`),
      shop_name: Yup.string().required(),
      shop_name_kana: Yup.string().kana().required(),
      zip: Yup.string().nullable().zip(),
      zip_code1: Yup.string().nullable().matches(/^(\s*|[0-9]{3})$/, "3桁の数値で入力してください"),
      zip_code2: Yup.string().nullable().matches(/^(\s*|[0-9]{4})$/, "4桁の数値で入力してください"),
      tel: Yup.string().nullable().tel(),
      tel1 : Yup.string().matches(/^[0-9]+$/,'数値で入力してください'),
      tel2 : Yup.string().matches(/^[0-9]+$/,'数値で入力してください'),
      tel3 : Yup.string().matches(/^[0-9]+$/,'数値で入力してください'),
      fax: Yup.string().nullable().fax(),
      fax1 : Yup.string().matches(/^[0-9]+$/,'数値で入力してください'),
      fax2 : Yup.string().matches(/^[0-9]+$/,'数値で入力してください'),
      fax3 : Yup.string().matches(/^[0-9]+$/,'数値で入力してください'),
      opening_time1 : Yup.string().matches(/^[0-9:]+$/,'時間の形式で入力してください').min(5,'時間の形式で入力してください').max(5,'時間の形式で入力してください').time(),
      closing_time1 : Yup.string().matches(/^[0-9:]+$/,'時間の形式で入力してください').min(5,'時間の形式で入力してください').max(5,'時間の形式で入力してください').time(),
      opening_time2 : Yup.string().matches(/^[0-9:]+$/,'時間の形式で入力してください').min(5,'時間の形式で入力してください').max(5,'時間の形式で入力してください').time(),
      closing_time2 : Yup.string().matches(/^[0-9:]+$/,'時間の形式で入力してください').min(5,'時間の形式で入力してください').max(5,'時間の形式で入力してください').time(),
      opening_date: Yup.string().nullable().date_required(),
      closed_date: Yup.string().nullable().date_required(),
      link : Yup.string().url(),
      location1 : Yup.string().nullable().matches(/^[0-9.]+$/,'数値で入力してください').longitude(),
      location2 : Yup.string().nullable().matches(/^[0-9.]+$/,'数値で入力してください').latitude(),
    }),
    
    onSubmit: values => {
      dispatch(
        updateShop({
          division : editMode,
          shop_code : values?.shop_code,
          cooperation_shop_code : values?.cooperation_shop_code,
          brand_code : values?.brand_code,
          shop_image_url : values?.icon_image_url,
          shop_name : values?.shop_name,
          shop_name_kana : values?.shop_name_kana,
          ec_display_flag : values?.ec_display_flag,
          zip_code : `${values?.zip_code1}${values?.zip_code2}`,
          prefectures_code : values?.prefectures ? prefecturesList.prefectures.filter(p => p.label === values?.prefectures)[0]?.prefecturesCode ?? "" : "", 
          address1 : values?.address1 ?? "",
          address2 : values?.address2 ?? "",
          address3 : values?.address3 ?? "",
          tel1 : values?.tel1 ?? "",
          tel2 : values?.tel2 ?? "",
          tel3 : values?.tel3 ?? "",
          fax1 : values?.fax1 ?? "",
          fax2 : values?.fax2 ?? "",
          fax3 : values?.fax3 ?? "",
          opening_time1 : values?.opening_time1 ?? "",
          closing_time1 : values?.closing_time1 ?? "",
          sales_time_remark1 : values?.sales_time_remark1 ?? "",
          opening_time2 : values?.opening_time2 ?? "",
          closing_time2 : values?.closing_time2 ?? "",
          sales_time_remark2 : values?.sales_time_remark2 ?? "",
          regular_holiday : {
            sunday : values?.sunday ?? 0,
            monday : values?.monday ?? 0,
            tuesday : values?.tuesday ?? 0,
            wednesday : values?.wednesday ?? 0,
            thursday : values?.thursday ?? 0,
            friday : values?.friday ?? 0,
            saturday : values?.saturday ?? 0,  
          },
          irregular_holiday : values?.irregular_holiday ?? "",
          link : values?.link ?? "",
          appeal : values?.appeal ?? "",
          opening_date: values?.opening_date.format("YYYY-MM-DD"),
          closed_date: values?.closed_date.format("YYYY-MM-DD"),
          location : [values?.location1 ? parseFloat(values?.location1 ?? 0) : null, values?.location2 ? parseFloat(values?.location2 ?? 0) : null ]
        })
      )
    },
  });
  const {
    shop_code,
    shop_name,
    shop_name_kana,
    brand_code,
    icon_image_url,
    sunday ,
    monday ,
    tuesday ,
    wednesday ,
    thursday ,
    friday ,
    saturday ,
    appeal,
    opening_date ,
    closed_date,
    registration_button,
    ec_display_flag
  } = formik.values;

  const handleFileChange = url => {
    handlerFormikFieldChange(formik, "icon_image_url", url);
  };

  const onSearchZip = async () => {
    const zipcode = `${formik.values.zip_code1}${formik.values.zip_code2}`;
    try {
      const addressRes =
        /^\d{7}$/.test(zipcode) &&
        (await axios.get(`${baseUrl.ZIP_SEARCH_URL}`, {
          adapter: axiosJsonpAdapter,
          params: {
            zipcode,
          },
        }));
      const address = addressRes.data?.results[0];
      if (address) {
        handlerFormikFieldChange(formik, "prefectures", 
          prefecturesList.prefectures.find(p => p.label === address.address1)?.label);
        formik.setFieldValue(
          "prefectures",
          prefecturesList.prefectures.find(p => p.label === address.address1)
            ?.label
          );

        formik.setFieldValue("address1", address.address2);
        formik.setFieldValue("address2", address.address3);
      }
    } catch (error) {}
  };

  const onBack = () => {
    dispatch(shopActions.closeEditModal())
  };

  useEffect(() => {
    if(editMode === "update"){
      // const pre = prefecturesList.prefectures.filter((data)=>{if(activeShop?.prefectures === data.value) return data});
      const workBrand =[];
      activeShop?.brand.map((data) =>  { workBrand.push(data.brand_code) });
      activeShop?.shop_code && handlerFormikFieldChange(formik, "shop_code", activeShop?.shop_code);
      activeShop?.cooperation_shop_code && handlerFormikFieldChange(formik, "cooperation_shop_code", activeShop?.cooperation_shop_code);
      activeShop?.icon_image_url && handlerFormikFieldChange(formik, "icon_image_url", activeShop?.icon_image_url);
      activeShop?.brand && handlerFormikFieldChange(formik, "brand_code", workBrand);
      activeShop?.shop_name && handlerFormikFieldChange(formik, "shop_name", activeShop?.shop_name);
      activeShop?.shop_namekana && handlerFormikFieldChange(formik, "shop_name_kana", activeShop?.shop_namekana);
      activeShop?.ec_display_flag !== null && handlerFormikFieldChange(formik, "ec_display_flag", activeShop?.ec_display_flag);
      activeShop?.zip?.number1 && handlerFormikFieldChange(formik, "zip_code1", activeShop?.zip?.number1);
      activeShop?.zip?.number2 && handlerFormikFieldChange(formik, "zip_code2", activeShop?.zip?.number2);
      activeShop?.prefectures && handlerFormikFieldChange(formik, "prefectures", activeShop?.prefectures);
      activeShop?.municipality && handlerFormikFieldChange(formik, "address1", activeShop?.municipality);
      activeShop?.house_number && handlerFormikFieldChange(formik, "address2", activeShop?.house_number);
      activeShop?.house_info && handlerFormikFieldChange(formik, "address3", activeShop?.house_info);
      activeShop?.tel?.number1 && handlerFormikFieldChange(formik, "tel1", activeShop?.tel?.number1);
      activeShop?.tel?.number2 && handlerFormikFieldChange(formik, "tel2", activeShop?.tel?.number2);
      activeShop?.tel?.number3 && handlerFormikFieldChange(formik, "tel3", activeShop?.tel?.number3);
      activeShop?.fax?.number1 && handlerFormikFieldChange(formik, "fax1", activeShop?.fax?.number1);
      activeShop?.fax?.number2 && handlerFormikFieldChange(formik, "fax2", activeShop?.fax?.number2);
      activeShop?.fax?.number3 && handlerFormikFieldChange(formik, "fax3", activeShop?.fax?.number3);
      activeShop?.business_hours1?.start && handlerFormikFieldChange(formik, "opening_time1", activeShop?.business_hours1?.start);
      activeShop?.business_hours1?.end && handlerFormikFieldChange(formik, "closing_time1", activeShop?.business_hours1?.end);
      activeShop?.business_hours1?.description &&  handlerFormikFieldChange(formik, "sales_time_remark1", activeShop?.business_hours1?.description);
      activeShop?.business_hours2?.start && handlerFormikFieldChange(formik, "opening_time2", activeShop?.business_hours2?.start);
      activeShop?.business_hours2?.end && handlerFormikFieldChange(formik, "closing_time2", activeShop?.business_hours2?.end);
      activeShop?.business_hours2?.description && handlerFormikFieldChange(formik, "sales_time_remark2", activeShop?.business_hours2?.description);
      activeShop?.shop_holidays?.sunday && handlerFormikFieldChange(formik, "sunday", activeShop?.shop_holidays?.sunday);
      activeShop?.shop_holidays?.monday && handlerFormikFieldChange(formik, "monday", activeShop?.shop_holidays?.monday);
      activeShop?.shop_holidays?.tuesday && handlerFormikFieldChange(formik, "tuesday", activeShop?.shop_holidays?.tuesday);
      activeShop?.shop_holidays?.wednesday && handlerFormikFieldChange(formik, "wednesday", activeShop?.shop_holidays?.wednesday);
      activeShop?.shop_holidays?.thursday && handlerFormikFieldChange(formik, "thursday", activeShop?.shop_holidays?.thursday);
      activeShop?.shop_holidays?.friday && handlerFormikFieldChange(formik, "friday", activeShop?.shop_holidays?.friday);
      activeShop?.shop_holidays?.saturday && handlerFormikFieldChange(formik, "saturday", activeShop?.shop_holidays?.saturday);
      activeShop?.irregular_holidays && handlerFormikFieldChange(formik, "irregular_holiday", activeShop?.irregular_holidays);
      activeShop?.link && handlerFormikFieldChange(formik, "link", activeShop?.link);
      activeShop?.description && handlerFormikFieldChange(formik, "appeal", activeShop?.description);
      activeShop?.start_date && handlerFormikFieldChange(formik, "opening_date", moment(activeShop?.start_date));
      activeShop?.end_date && handlerFormikFieldChange(formik, "closed_date", moment(activeShop?.end_date));
      activeShop?.location?.longitude && handlerFormikFieldChange(formik, "location2", 
          activeShop?.location?.longitude ? parseFloat(activeShop?.location?.longitude ?? 0): null);
      activeShop?.location?.latitude && handlerFormikFieldChange(formik, "location1", 
          activeShop?.location?.latitude ? parseFloat(activeShop?.location?.latitude ?? 0) : null);
    }
    handlerFormikFieldChange(formik, "registration_button", 
      shop_code != "" && shop_name != "" && 
      shop_name_kana != "" && brand_code.length > 0 && 
      opening_date != "" && closed_date != "" 
    );
  }, [editMode]);


  return (
    <Modal 
      visible={editModalVisible} 
      closable={false} width={1000} 
      footer={null}
      onCancel={onBack}
      afterClose={() => formik.resetForm()}
    >
      <>
        <h2 className="text-bold shop-title">
          {editMode === "insert" ? "店舗登録" : "店舗編集"}
          <span className="nf-text-required-style">　*は必須項目です</span>
        </h2>
        <div className="nf-shop-edit-area">
          <div className="file-upload-area">
            <div className="image-icon">
              <BaseUpload onChange={handleFileChange}>
                <div className="avator">
                  <Image src={icon_image_url || noimage} preview={{ mask: "画像を選択", visible: false }}/>
                </div>
              </BaseUpload>
            </div>
            <div className="image-text margin">png,gif,jpg,jpeg形式のみアップロード可</div>
            <div className="image-text">画像は5M以下でアップロードしてください</div>
            <div className="image-text">推奨画像サイズ：正方形</div>
          </div>
          {editMode === "insert"
            ?
            <GeneralInput
              label ="店舗CD"
              labelTextAlign ="left"
              labelWidth="wider"   
              name={"shop_code"}
              inputLabel={editMode==="update"}
              requiredItem={true}
              placeholder="店舗CDを入力してください"
              selectSize="half"
              styleType="block-grey-normal"
              formik={formik}
            />
            :
            <div class="nf-input-wrapper nf-input-wrapper-half">
              <label class="nf-input-label text-align-left label-width-wider label-required">店舗CD</label>
              <label class="ant-input nf-input">{shop_code}</label>
            </div>

          }
          <GeneralInput
            label ="連携用店舗CD"
            labelTextAlign ="left"
            labelWidth="wider"
            placeholder="連携用店舗CDを入力してください"
            selectSize="half"
            styleType="block-grey-normal"
            name="cooperation_shop_code"
            formik={formik}
          />
          <GeneralInput
            label ="店舗名"
            labelTextAlign ="left"
            labelWidth="wider"
            requiredItem={true}
            placeholder="店舗名を入力してください"
            selectSize="half"
            styleType="block-grey-normal"
            name="shop_name"
            formik={formik}
          />
          <GeneralInput
            label ="店舗名(カナ)"
            labelTextAlign ="left"
            labelWidth="wider"
            requiredItem={true}
            placeholder="店舗名(カナ)を入力してください"
            selectSize="half"
            styleType="block-grey-normal"
            name="shop_name_kana"
            formik={formik}
          />
          <GeneralInput
            options={brands}
            label="ブランド"
            labelTextAlign ="left"
            labelWidth="wider"
            requiredItem
            selectSize="half"
            placeholder={"ブランドを選択してください"}
            name={"brand_code"}
            styleType="block-grey"
            formik={formik}
          />
          <div class="nf-input-wrapper nf-input-wrapper-herf">
            <label className="nf-input-label text-align-left label-width-wider label-required">EC表示</label>
            <GeneralCheckbox
              label="表示する"
              checked ={ec_display_flag===1}
              onChange={(value) => handlerFormikFieldChange(formik, "ec_display_flag", value.target.checked ? 1 : 0)}
            />
            <GeneralCheckbox
              label="表示しない"
              checked ={ec_display_flag===0}
              onChange={(value) => handlerFormikFieldChange(formik, "ec_display_flag", value.target.checked ? 0 : 1)}
            />
          </div>
          <div className="input-multi-area">
            <GeneralInput
              label ="郵便番号"
              labelTextAlign ="left"
              labelWidth="wider"
              placeholder="123"
              selectSize="title-short"
              inputWidth="small"
              styleType="block-grey-short"
              name="zip_code1"
              onKeyUp={onSearchZip}
              formik={formik}
            />
            <GeneralInput
              label ="-"
              labelWidth="short"
              placeholder="4567"
              selectSize="short"
              inputWidth="small"
              styleType="block-grey-short"
              name="zip_code2"
              onKeyUp={onSearchZip}
              formik={formik}
            />
            <span className="validation-error validation-multi">
              {formik.touched.zip_code1 &&
                formik.touched.zip_code2 &&
                formik.errors.zip}
            </span>
          </div>
          <GeneralInput
            label="都道府県"
            labelTextAlign ="left"
            labelWidth="wider"
            styleType="block-grey-normal"
            placeholder={"都道府県を選択してください"}
            options={prefecturesList.prefectures.map(p => ({
              label: p.label,
              value: p.value,
            }))}
            mode={null}
            formik={formik}
            name={"prefectures"}

          />
          <GeneralInput
            label="市区町村"
            labelWidth="wider"
            labelTextAlign="left"
            selectSize="wide"
            className="input-long-text"
            placeholder="市区町村を入力してください"
            styleType="block-grey"
            formik={formik}
            name={"address1"}
          />
          <GeneralInput
            label="丁目番地"
            labelWidth="wider"
            className="input-long-text"
            labelTextAlign="left"
            selectSize="wide"
            placeholder="丁目番地を入力してください"
            styleType="block-grey"
            formik={formik}
            name={"address2"}
          />
          <GeneralInput
            label="館情報"
            labelWidth="wider"
            className="input-long-text"
            labelTextAlign="left"
            selectSize="wide"
            placeholder="館情報を入力してください"
            styleType="block-grey"
            formik={formik}
            name={"address3"}
          />
          <div className="input-multi-area">
            <GeneralInput
              label="電話番号"
              labelTextAlign="left"
              labelWidth="wider"
              placeholder="03"
              styleType="block-grey-normal"
              name={"tel1"}
              formik={formik}
            />
            <GeneralInput
              label="-"
              className="input-tel"
              placeholder="0000"
              styleType="block-grey-normal"
              name={"tel2"}
              formik={formik}
            />
            <GeneralInput
              label="-"
              className="input-tel"
              placeholder="0000"
              styleType="block-grey-normal"
              name={"tel3"}
              formik={formik}
            />
            <span className="validation-error validation-multi">
              {formik.touched.tel1 &&
                formik.touched.tel2 &&
                formik.touched.tel3 &&
                formik.errors.tel}
            </span>
          </div>
          <div className="input-multi-area">
            <GeneralInput
              label="FAX"
              labelTextAlign="left"
              labelWidth="wider"
              placeholder="03"
              styleType="block-grey-normal"
              name={"fax1"}
              formik={formik}
            />
            <GeneralInput
              label="-"
              className="input-tel"
              placeholder="0000"
              styleType="block-grey-normal"
              name={"fax2"}
              formik={formik}
            />
            <GeneralInput
              label="-"
              className="input-tel"
              placeholder="0000"
              styleType="block-grey-normal"
              name={"fax3"}
              formik={formik}
            />
            <span className="validation-error validation-multi">
              {formik.touched.fax1 &&
                formik.touched.fax2 &&
                formik.touched.fax3 &&
                formik.errors.fax}
            </span>
          </div>
          <div className="input-multi-area">
            <GeneralInput
              label ="営業時間1"
              labelTextAlign ="left"
              labelWidth="wider"
              placeholder="10:00"
              selectSize="title-short"
              styleType="block-grey-normal"
              name="opening_time1"
              formik={formik}
            />
            <GeneralInput
              label ="〜"
              labelWidth="short"
              placeholder="19:00"
              selectSize="short"
              inputWidth="small"
              styleType="block-grey-normal"
              name="closing_time1"
              formik={formik}
            />
            <GeneralInput
              placeholder="備考"
              selectSize="half"
              styleType="block-grey"
              name="sales_time_remark1"
              formik={formik}
            />
          </div>
          <div className="input-multi-area">
            <GeneralInput
              label ="営業時間2"
              labelTextAlign ="left"
              labelWidth="wider"
              placeholder="10:00"
              selectSize="title-short"
              styleType="block-grey-normal"
              name="opening_time2"
              formik={formik}
            />
            <GeneralInput
              label ="〜"
              labelWidth="short"
              placeholder="19:00"
              selectSize="short"
              inputWidth="small"
              styleType="block-grey-normal"
              name="closing_time2"
              formik={formik}
            />
            <GeneralInput
              placeholder="備考"
              selectSize="half"
              styleType="block-grey"
              name="sales_time_remark2"
              formik={formik}
            />
          </div>
          <div class="nf-input-wrapper nf-input-wrapper-herf">
            <label className="nf-input-label text-align-left label-width-wider">定休日</label>
            <GeneralCheckbox
              label="日"
              checked ={sunday===1}
              onChange={(value) => handlerFormikFieldChange(formik, "sunday", value.target.checked ? 1 : 0)}
            />
            <GeneralCheckbox
              label="月"
              checked ={monday===1}
              onChange={(value) => handlerFormikFieldChange(formik, "monday", value.target.checked ? 1 : 0)}
            />
            <GeneralCheckbox
              label="火"
              checked ={tuesday===1}
              onChange={(value) => handlerFormikFieldChange(formik, "tuesday", value.target.checked ? 1 : 0)}
            />
            <GeneralCheckbox
              label="水"
              checked ={wednesday===1}
              onChange={(value) => handlerFormikFieldChange(formik, "wednesday", value.target.checked ? 1 : 0)}
            />
            <GeneralCheckbox
              label="木"
              checked ={thursday===1}
              onChange={(value) => handlerFormikFieldChange(formik, "thursday", value.target.checked ? 1 : 0)}
            />
            <GeneralCheckbox
              label="金"
              checked ={friday===1}
              onChange={(value) => handlerFormikFieldChange(formik, "friday", value.target.checked ? 1 : 0)}
            />
            <GeneralCheckbox
              label="土"
              checked ={saturday===1}
              onChange={(value) => handlerFormikFieldChange(formik, "saturday", value.target.checked ? 1 : 0)}
            />
          </div>
          <GeneralInput
            label ="不定休日"
            labelTextAlign ="left"
            labelWidth="wider"
            className="input-long-text"
            placeholder="不定休日を入力してください"
            selectSize="wide"
            styleType="block-grey"
            name="irregular_holiday"
            formik={formik}
          />
          <GeneralInput
            label ="外部リンク"
            labelTextAlign ="left"
            labelWidth="wider"
            className="input-long-text"
            placeholder="外部リンクを入力してください"
            selectSize="wide"
            styleType="block-grey"
            name="link"
            formik={formik}
          />
          <div className="input-comment">
            <span>店舗紹介文</span>　
            <textarea
              placeholder="店舗紹介文を入力してください"
              spellCheck={false}
              autoComplete={"false"}
              value={appeal}
              onChange={e =>
                handlerFormikFieldChange(formik, "appeal", e.target.value)
              }
            />
          </div>
          <div className="input-date-area input-multi-area-wide">
            <div className="nf-input-wrapper nf-input-wrapper-title-short">
              <label className="nf-input-label text-align-left label-width-wide text-required iregular-label">開店日</label>
              <DatePicker
                format={"YYYY.MM.DD"} 
                className="nf-input-date"
                size="small"
                placeholder={null}
                value={opening_date}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value =>
                  value !== null && closed_date !== null
                    ? value > closed_date 
                      ? handlerFormikFieldChange(formik, "opening_date", closed_date)
                      : handlerFormikFieldChange(formik, "opening_date", value)
                    : handlerFormikFieldChange(formik, "opening_date", value)
                }
              />
              {formik?.errors.opening_date && formik?.touched.opening_date && (
                <span className="nf-input-error">{formik.errors.opening_date}</span>
              )}
            </div>
            <div className="nf-input-wrapper nf-input-wrapper-short">
              <label className="text-required end-date">閉店日</label>
                <DatePicker
                  format={"YYYY.MM.DD"}
                  className="nf-input-date"
                  value={closed_date}
                  placeholder={null}
                  suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  onChange={value =>
                    value !== null  && opening_date !== null
                      ? value < opening_date 
                        ? handlerFormikFieldChange(formik, "closed_date", opening_date)
                        : handlerFormikFieldChange(formik, "closed_date", value)
                      : handlerFormikFieldChange(formik, "closed_date", value)
                  }
                />
              {formik?.errors.closed_date && formik?.touched.closed_date && (
                <span className="nf-input-error">{formik.errors.closed_date}</span>
              )}

            </div>
              <span className="validation-error validation-date">
                {
                  // formik.touched.opening_date &&
                  // formik.touched.closed_date &&
                  formik.errors.date}
              </span>
            </div>
          <div className="input-multi-area-wide">
            <div class="nf-input-wrapper nf-input-wrapper-herf">
              <label className="nf-input-label text-align-left label-width-wider">位置情報</label>
            </div>
            <GeneralInput
              label ="緯度"
              labelTextAlign ="center"
              selectSize="wider"
              styleType="block-grey"
              name="location1"
              formik={formik}
            />
            <GeneralInput
              className="margin"
              label="経度"
              labelTextAlign ="center"
              selectSize="wider"
              styleType="block-grey"
              name="location2"
              formik={formik}
            />
          </div>
        </div>
        <div className="nf-shop-btn-area">
          <Button text="戻る" theme="white" onClick={() => onBack()} />
          <form className="search-area" onSubmit={formik.handleSubmit}>
            <Button 
              text={editMode === "insert" ? "登録": "更新"} 
              type="submit"
              theme={!registration_button ? !formik.isValid ? "light-black" : "black" : "black"}
              disabled={!registration_button ? !formik.isValid ? true : false : false}
            />
          </form>
        </div>　
      </>
    </Modal>
  )
};

export default ShopEditModal;
import { Image } from "antd";
import { toMoneyString } from "../../utils/fnUtil";
import noimage from "../../images/noimage_square2.png";
import classNames from "classnames";
import "./style.scss";

const ProductInfoCard = ({
  productInfo,
  displayNoImage,
  size = "none",
  deviceMode = false,
}) => {
  return (
    <div
      className={classNames("product-info", {
        "product-info-device": deviceMode,
      })}
    >
      <Image
        className={classNames("product-img", {
          [`img-size-${size}`]: true,
        })}
        src={displayNoImage ? noimage : productInfo.image_url || noimage}
        preview={false}
      />
      <div className="product-detail">
        <span>{productInfo.product_code}</span>
        <span className="product-name">{productInfo.product_name}</span>
        <span>{productInfo.color_name}</span>
        <span>{productInfo.size_name}</span>
        <span>¥{toMoneyString(productInfo.tax_included_price)}(税込)</span>
      </div>
    </div>
  );
};
export default ProductInfoCard;

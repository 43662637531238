import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Table, Select, InputNumber } from "antd";
import { DownOutlined, CheckOutlined } from "@ant-design/icons";
import {
  actions,
  ProductRewardInit,
  fetchRewardProducts,
  updateProduct,
} from "../../slices/productSlice";
import { uploadActions } from "../../slices/fileUploadSlice";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import Icon from "@ant-design/icons";
import "./style.scss";
import GenernalInput from "../../components/generalInput";
import Button from "../../components/button";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SelectUpdateModal from "../../components/modal/selectUpdateModal";
import IconImage from "../../constants/Icons";
import TableColumnWidth from '../../constants/tableColumnWidth';

const Reward = () => {
  // 定義
  const dispatch = useDispatch();
  const {
    brands,
    productRewardList,
    searchRewardParams,
    updateParams,
    total,
    selectRate,
    productUpdateModalVisible
  } = useSelector((state) => state.product);
  const rateState = useRef({fromRate:true,toRate:true});

  const { user_auth } = useSelector(state => state.account ?? {});

  const searchFormik = useFormik({
    initialValues: searchRewardParams,
    onSubmit: (values) => dispatch(actions.saveSearchRewardParam(values)),
  });
  const updateFormik = useFormik({
    initialValues: updateParams,
    onSubmit: (values) => dispatch(actions.saveSearchRewardParam(values)),
  });
  const {
    from_incentive_rate,
    to_incentive_rate,
  } = searchFormik.values;

  const {
    allUpdateButtonStatus,
    selectedRowKeys,
    updateList,
    update_incentive_rate,
    selectUpdateButtonStatus,
    workProductList
  } = updateFormik.values;

  // 初期データ取得
  useEffect(() => {
    dispatch(ProductRewardInit());
    return () => dispatch(actions.clear());
  }, []);

  useUpdateEffect(() => {
    handlerFormikFieldChange(updateFormik, "selectedRowKeys", []);
    handlerFormikFieldChange(updateFormik, "selectUpdateButtonStatus", false);
    handlerFormikFieldChange(updateFormik, "allUpdateButtonStatus", false);
    handlerFormikFieldChange(updateFormik, "updateList", []);
    dispatch(fetchRewardProducts(searchRewardParams));
  }, [dispatch, searchRewardParams]);

  useUpdateEffect(() => {
    handlerFormikFieldChange(updateFormik, "workProductList", productRewardList?.map((data, index) => { return { ...data, key: index } }));
  }, [productRewardList]);

  const fromUpdate = (value) =>{
    value == null
      ? handlerFormikFieldChange(searchFormik, "from_incentive_rate", null)
      : to_incentive_rate == null
        ? handlerFormikFieldChange(searchFormik, "from_incentive_rate", value.toFixed(1))
        : value > to_incentive_rate
          ? handlerFormikFieldChange(searchFormik, "from_incentive_rate", to_incentive_rate)
          : handlerFormikFieldChange(searchFormik, "from_incentive_rate", value.toFixed(1))
  };

  const toUpdate = (value) =>{
    value == null
      ? handlerFormikFieldChange(searchFormik, "to_incentive_rate", null)
      : from_incentive_rate == null
        ? handlerFormikFieldChange(searchFormik, "to_incentive_rate", value.toFixed(1))
        : value < from_incentive_rate
          ? handlerFormikFieldChange(searchFormik, "to_incentive_rate", from_incentive_rate)
          : handlerFormikFieldChange(searchFormik, "to_incentive_rate", value.toFixed(1))
  };

  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      searchFormik.values.sort === desc
        ? asc
        : searchFormik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchRewardParam({ sort: targetSort }));
    searchFormik.setFieldValue("sort", targetSort);
  };

  //　一括更新ワーク 選択更新
  const onSelectChange = (selectedRowKeys) => {
    const approvalButtonStatus =
      selectedRowKeys.filter((x) => productRewardList[x].delete_flag === 0).length > 0;
    const updateList = selectedRowKeys
      .filter((x) => productRewardList[x]?.delete_flag === 0)
      .map((x) => {
        return productRewardList[x];
      });
    handlerFormikFieldChange(updateFormik, "selectedRowKeys", selectedRowKeys);
    handlerFormikFieldChange(updateFormik, "allUpdateButtonStatus",approvalButtonStatus);
    handlerFormikFieldChange(updateFormik, "updateList", updateList);
  };

  //　選択更新ワーク　
  const selectUpdateChange = (values, recode) => {
    recode.incentive_rate = values.toString();
    recode.changeFlg =
      productRewardList[recode.key]?.incentive_rate === recode?.incentive_rate
        ? false
        : true;
    handlerFormikFieldChange(updateFormik, "selectUpdateButtonStatus", false);
    handlerFormikFieldChange(updateFormik, "workProductList",
      workProductList.map((data, index) => {
        if (data?.changeFlg){
          handlerFormikFieldChange(
            updateFormik,
            "selectUpdateButtonStatus",
            true
          );
        }
        if (recode.key === index) {
          return recode;
        } else {
          return data;
        }
      })
    );
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (recode) => ({
      disabled: recode.delete_flag === 1,
      className: recode.delete_flag === 1 && "nf-checkbox-hidden",
    }),
  };
  // 設定率一括更新
  const bulkUpdate = async (value) => {
    const updateData = value
      ? updateList
      : workProductList.filter((x) => x.changeFlg);
    updateData.map((data) => {
      dispatch(
        updateProduct({
          division: "update",
          brand_code: data?.brand_code,
          product_code: data?.product_code,
          color_code: data?.color_code,
          size_code: data?.size_code,
          origin_sku_code: data?.sku_code,
          incentive_rate: parseFloat(
            value ? update_incentive_rate : data?.incentive_rate
          ),
        })
      );
    });
    handlerFormikFieldChange(updateFormik, "selectUpdateButtonStatus", false);
  };
  //　テーブル定義
  const columns = [
    {
      title: () => (
        <>
          <span>ブランド</span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {searchFormik.values.sort === "brand_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      width:TableColumnWidth.brandName
    },
    {
      title: () => (
        <>
          <span>商品CD</span>
          <span
            className="sort-span"
            onClick={createOnClikSort("product_code")}
          >
            {searchFormik.values.sort === "product_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "product_code",
      key: "product_code",
      align: "center",
      width:TableColumnWidth.reward.productCd
    },
    {
      title: () => (
        <>
          <span>色CD</span>
          <span className="sort-span" onClick={createOnClikSort("color_code")}>
            {searchFormik.values.sort === "color_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "color_code",
      key: "color_code",
      align: "center",
      width:TableColumnWidth.reward.colorCd
    },
    {
      title: () => (
        <>
          <span>サイズCD</span>
          <span className="sort-span" onClick={createOnClikSort("size_code")}>
            {searchFormik.values.sort === "size_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "size_code",
      key: "size_code",
      align: "center",
      width:TableColumnWidth.reward.sizeCd
    },
    {
      title: () => (
        <>
          <span>SKUコード</span>
          <span className="sort-span" onClick={createOnClikSort("sku_code")}>
            {searchFormik.values.sort === "sku_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "sku_code",
      key: "sku_code",
      align: "center",
      width:TableColumnWidth.reward.skuCd
    },
    {
      title: () => (
        <>
          <span>JANコード</span>
          <span className="sort-span" onClick={createOnClikSort("jan_code")}>
            {searchFormik.values.sort === "jan_code_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "jan_code",
      key: "jan_code",
      align: "center",
      width:TableColumnWidth.reward.janCd
    },
    {
      title: () => (
        <>
          <span>商品名</span>
          <span
            className="sort-span"
            onClick={createOnClikSort("product_name")}
          >
            {searchFormik.values.sort === "product_name_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "product_name",
      key: "product_name",
      align: "center",
      width:TableColumnWidth.productName,
      render: (val) => (
        <div style={{ maxWidth: 200 }} className="table-colum-title-cell">
          {val || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>上代単価</span>
          <span className="sort-span" onClick={createOnClikSort("price")}>
            {searchFormik.values.sort === "price_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "price",
      key: "price",
      align: "center",
      width:TableColumnWidth.reward.price,
      render: (price) => <span>{Number(price).toLocaleString()}</span>,
    },
    {
      title: () => (
        <>
          <span>設定率</span>
          <span
            className="sort-span"
            onClick={createOnClikSort("incentive_rate")}
          >
            {searchFormik.values.sort === "incentive_rate_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "incentive_rate",
      key: "incentive_rate",
      align: "center",
      width:TableColumnWidth.reward.incentiveRate,
      render: (index, recode) => (
        <>
          <div className="nf-table-input-reward">
            {recode.delete_flag === 0 ? (
              <InputNumber
                autoComplete="off"
                min={0}
                max={100}
                step={0.1}
                placeholder={0.0}
                style={{
                  width: 70,
                  color: recode?.changeFlg ? "#990000" : "black",
                  align: "center"
                }}
                onChange={(v) => v !== null &&
                  selectUpdateChange(v.toFixed(1), recode, "incentive_rate")
                }
                value={recode.incentive_rate}
              />
            ) : (
              <label>{recode.incentive_rate}</label>
            )}
          </div>
        </>
      ),
    },
    {
      title: () => (
        <>
          <span>報酬単価</span>
          <span
            className="sort-span"
            onClick={createOnClikSort("incentive_price")}
          >
            {searchFormik.values.sort === "incentive_price_asc" ? (
              <Icon
                component={IconImage.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={IconImage.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "incentive_price",
      key: "incentive_price",
      align: "center",
      width:TableColumnWidth.reward.incentivePrice,
      render: (incentive_price) => (
        <span>{parseInt(incentive_price).toLocaleString()}</span>
      ),
    },
  ];

  return (
    <>
      <div className="content-body">
        <Header />
        <div>
          <div className="container nf-page">
            <h2>報酬レート設定</h2>
            <div className="nf-search-area">
              <form
                className="search-area-input"
                onSubmit={searchFormik.handleSubmit}
              >
                <div className="search-item">
                  <GenernalInput
                    label="ブランド"
                    mode="multiple"
                    name="brand_code"
                    placeholder={"ブランドを選択してください"}
                    options={brands}
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="商品CD"
                    placeholder="商品CDを入力してください"
                    name="product_code"
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="SKUコード"
                    placeholder="SKUコードを入力してください"
                    name="sku_code"
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="JANコード"
                    placeholder="JANコードを入力してください"
                    name="jan_code"
                    formik={searchFormik}
                  />
                  <GenernalInput
                    label="商品名"
                    placeholder="商品名を入力してください"
                    name="product_name"
                    formik={searchFormik}
                  />
                  <div className="multi-input">
                    <GenernalInput
                      label="設定率"
                      labelWidth="wide"
                      mode={null}
                      styleType="short"
                      selectSize="search-short"
                      options={"step"}
                      min={0}
                      max={100}
                      step={0.1}
                      customOnChange={value => fromUpdate(value)}
                      formik={searchFormik}
                      name="from_incentive_rate"
                    />
                  </div>
                  <GenernalInput
                    label=" 〜 "
                    labelWidth="short-center"
                      mode={null}
                      styleType="short"
                      selectSize="short"
                      options={"step"}
                      min={0}
                      max={100}
                      step={0.1}
                      customOnChange={value => toUpdate(value)}
                      formik={searchFormik}
                      name="to_incentive_rate"
                  />
                </div>
                <div className="search-item-deleted">
                  <Button text="検索" type="submit" />
                </div>
              </form>

              <text className="search-area-bottom">
                ※報酬レートは夜間更新の為、前日分までが参照できます
              </text>
            </div>
            <div className="nf-table-header">
              <div className="top-header">
                <div className={user_auth > "1" ? "hidden button" : "button"}>
                  <Button
                    text="CSV取込"
                    width="wider"
                    onClick={() =>
                      dispatch(uploadActions.openFileUploadModal(3))
                    }
                  />
                </div>
              </div>
              <div className="bottom-header">
                <h3>検索結果一覧</h3>
                <div className="nf-rate-area multi-input">
                  <GenernalInput
                    label={` ${selectedRowKeys.length} 商品選択中`}
                    mode={null}
                    labelWidth="wider"
                    styleType="short"
                    selectSize="search-short"
                    placeholder={"0.0"}
                    options={"step"}
                    min={0}
                    max={100}
                    step={0.1}
                    customOnChange={value =>
                      value !== null
                        ? handlerFormikFieldChange(updateFormik, "update_incentive_rate", value.toFixed(1))
                        : handlerFormikFieldChange(updateFormik, "update_incentive_rate", (0).toFixed(1))
                    }
                    value={update_incentive_rate}
                    // name="update_incentive_rate"
                  />
                  <span>%</span>
                  <div className="button">
                    <Button
                      text={"設定率一括更新"}
                      width="wider"
                      theme={ (allUpdateButtonStatus && update_incentive_rate) ? "light-black" : "" }
                      disabled={ (allUpdateButtonStatus && update_incentive_rate) ? false : true }
                      onClick={() => dispatch(actions.openUpdateModal())}
                    />
                  </div>
                </div>
                <div className="button">
                  <Button
                    text="設定率更新"
                    disabled={ selectUpdateButtonStatus ? false : true }
                    width="wider"
                    theme={selectUpdateButtonStatus ? "black" : "light-black" }
                    onClick={() => bulkUpdate(false)}
                  />
                </div>
              </div>
            </div>
            <div className="nf-table-product">
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={workProductList}
                onChange={(pagination) => {
                  const offset =
                    pagination.current === 1
                      ? 0
                      : pagination.pageSize * (pagination.current - 1);
                  dispatch(actions.saveRewardOffset(offset));
                  searchFormik.setFieldValue("offset", offset);
                }}
                pagination={{
                  total: total,
                  pageSize: 10,
                  current: (searchRewardParams?.offset || 0) / 10 + 1,
                  position: ["bottomCenter"],
                  showQuickJumper: false,
                  size: "small",
                }}
                sticky={{offsetHeader: 60}}
              />
            </div>
          </div>
          <SelectUpdateModal
            modalVisible={productUpdateModalVisible}
            item="商品"
            onCancel={() => dispatch(actions.closeUpdateModal())}
            onOk={() => bulkUpdate("true")}
          />

          <Footer />
        </div>
      </div>
    </>
  );
};

export default Reward;

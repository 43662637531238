import { Modal } from "antd";
import Button from "../../button";
import "./style.scss";

const LogoutModal = ({ modalVisible, onCancel, onOk }) => {
  return (
    <Modal
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      width={500}
      onCancel={onCancel}
    >
      <div className="logout-modal">
        <div className="logout-title">ログアウトしますか</div>
        <div className="action-container">
          <Button text="はい" theme="white" onClick={onOk} />
          <Button text="いいえ" onClick={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;

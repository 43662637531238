import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosUtil from "../utils/axiosUtil";

export const tokenLogin = createAsyncThunk("tokenLogin", async (params) => {
  return await axiosUtil.post(
    `${process.env.REACT_APP_API_BASE_URL}/account/login`,
    params,
    {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-type": "application/json",
      },
    }
  );
});

export const refleshTokenLogin = createAsyncThunk(
  "refleshTokenLogin",
  async (params) => {
    return await axiosUtil.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/login`,
      params,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json",
        },
      }
    );
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (params) => await axiosUtil.post(`account/logout`, params)
);

export const generateProvisonalPassword = createAsyncThunk(
  "generateProvisonalPassword",
  async (params) =>
    await axiosUtil.post(`account/provisionalpassword/generate`, params)
);

export const updatePassword = createAsyncThunk(
  "updatePassword",
  async (params) => {
    return await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/password/update`,
      params,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json",
        },
      }
    );
  }
  // async (params) => await axiosUtil.post(`account/password/update`, params)
);

const initialState = {
  code: null,
  reflesh: false,
  passwordRecoveryInfoModal: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action) => ({ ...state, ...action.payload }),
    clearAccount: () => initialState,
    refleshAccount: (state) => ({
      ...state,
      code: "",
      reflesh: true,
      corporation_code: state.corporation_code,
      token: state.token,
    }),
    closePasswordRecoveryInfoModal: (state, action) => ({
      ...state,
      passwordRecoveryInfoModal: false,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(tokenLogin.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(tokenLogin.rejected, (state, action) => ({
      ...state,
      code: null,
    }));
    builder.addCase(refleshTokenLogin.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      code: "reflesh",
      reflesh: false,
    }));
    builder.addCase(refleshTokenLogin.rejected, (state, action) => ({
      ...state,
      reflesh: false,
      code: null,
    }));
    builder.addCase(logout.fulfilled, (state, action) => initialState);
    builder.addCase(generateProvisonalPassword.fulfilled, (state, action) => ({
      ...state,
      passwordRecoveryInfoModal: true,
    }));
    builder.addCase(updatePassword.fulfilled, (state, action) => ({
      ...state,
    }));
  },
});

export const actions = accountSlice.actions;
export default accountSlice.reducer;

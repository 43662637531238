import BaseProgressBar from "./base";

const progress = [
  "配信予定(EC公開前)",
  "配信予定(EC公開中)",
  "配信準備中",
  "配信中",
  "アーカイブ",
];

const LiveProgressBar = ({ activeProgress }) => {
  return <BaseProgressBar progress={progress} activeProgress={activeProgress} />;
};

export default LiveProgressBar;

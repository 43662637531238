import "./style.scss";
import Icon from "@ant-design/icons";
import IconImage from "../../../constants/Icons";
import dayjs from "dayjs";
import { toMoneyString } from "../../../utils/fnUtil";

/* ライブ売上実績のカード */

const SalesLiveResultCard = ({ data, type, title }) => {
  const getJsxIcon = (after, before) => {
    let icon = "";
    if ((!after && after !== 0) || (!before && before !== 0)) {
      icon = IconImage.ImageIcon2;
    } else {
      before = Number(before);
      after = Number(after);
      if (before < after) icon = IconImage.ImageIcon1;
      if (before === after) icon = IconImage.ImageIcon2;
      if (before > after) icon = IconImage.ImageIcon3;
    }
    return (
      <Icon component={icon} style={{ fontSize: 16, cursor: "initial" }} className="display-icon" />
    );
  };
  const getGap = (after, before, preffix = '') => {
    before = Number(before);
    after = Number(after);
    if (before === after) return '';
    if(after - before > 0) return `${preffix}${toMoneyString(after - before)}`;
    else return `- ${preffix}${toMoneyString(Math.abs(after - before))}`;
  };
  return (
    <div className="salesLiveResultCard-card">
      <table>
        <tr className="salesLiveResultCard-main-table">
          <td className="salesLiveResultCard-term">
            <span style={{ fontWeight: "bold", fontSize: 14 }}>
              {type === "daily" ? "DAILY" : type === "monthly" ? "MONTHLY" : ""}
            </span>
            <span style={{ fontSize: 10, marginLeft: 5 }}>
              {type === "daily"
                ? "前々日対比"
                : type === "monthly"
                ? "前月同期間対比"
                : ""}
            </span>
            <div style={{ fontSize: 10, color: "#7B7B7B" }}>
              {type === "daily" && data?.latest_date
                ? dayjs(data?.latest_date).format("YYYY.MM.DD")
                : type === "monthly" && data?.start_date && data?.end_date
                ? `${dayjs(data?.start_date).format("YYYY.MM.DD")}-${dayjs(
                    data?.end_date
                  ).format("YYYY.MM.DD")}`
                : "-"}
            </div>
          </td>
          <td className="salesLiveResultCard-result">
            <div className="salesLiveResultCard-result-title">
              {title}
            </div>
            <div className="salesLiveResultCard-result-money">
              ¥ {data?.sales_amount ? toMoneyString(data?.sales_amount) : "0"}
            </div>
          </td>
          <td className="salesLiveResultCard-image">
            <div>
              {type === "daily"
                ? getJsxIcon(data?.sales_amount, data?.daybefore_sales_amount)
                : getJsxIcon(data?.sales_amount, data?.lastmonth_sales_amount)
              }
            </div>
          </td>
          <td className="salesLiveResultCard-diff">
            <div>
              {type === "daily" &&
              data?.sales_amount &&
              data?.daybefore_sales_amount
                ? getGap(data?.sales_amount, data?.daybefore_sales_amount, '¥')
                : type === "monthly" &&
                  data?.sales_amount &&
                  data?.lastmonth_sales_amount
                ? getGap(data?.sales_amount, data?.lastmonth_sales_amount, '¥')
                : ""}
            </div>
          </td>
        </tr>
      </table>
      <table className="salesLiveResultCard-analyze-table">
        <tr>
          <td className="salesLiveResultCard-colum">
            <span>再生回数</span>
          </td>
          <td className="salesLiveResultCard-value">
            <span>{data?.play_count ? toMoneyString(data?.play_count) : "0"}</span>
          </td>
          <td className="salesLiveResultCard-image">
            {type === "daily"
              ? getJsxIcon(data?.play_count, data?.daybefore_play_count)
              : getJsxIcon(data?.play_count, data?.lastmonth_play_count)
            }
          </td>
          <td className="salesLiveResultCard-diff">
            <span>
              {type === "daily" && data?.play_count && data?.daybefore_play_count
                ? getGap(data?.play_count, data?.daybefore_play_count)
                : type === "monthly" &&
                  data?.play_count &&
                  data?.lastmonth_play_count
                ? getGap(data?.play_count, data?.lastmonth_play_count)
                : ""}
            </span>
          </td>
        </tr>
        <tr>
          <td className="salesLiveResultCard-colum">
            <span>いいね</span>
          </td>
          <td className="salesLiveResultCard-value">
            <span>
              {data?.like_count ? toMoneyString(data?.like_count) : "0"}
            </span>
          </td>
          <td className="salesLiveResultCard-image">
            {type === "daily"
              ? getJsxIcon(data?.like_count, data?.daybefore_like_count)
              : getJsxIcon(data?.like_count, data?.lastmonth_like_count)
            }
          </td>
          <td className="salesLiveResultCard-diff">
            <span>
              {type === "daily" &&
              data?.like_count &&
              data?.daybefore_like_count
                ? getGap(data?.like_count, data?.daybefore_like_count)
                : type === "monthly" &&
                  data?.like_count &&
                  data?.lastmonth_like_count
                ? getGap(data?.like_count, data?.lastmonth_like_count)
                : ""}
            </span>
          </td>
        </tr>
        <tr>
          <td className="salesLiveResultCard-colum">
            <span>投稿数</span>
          </td>
          <td className="salesLiveResultCard-value">
            <span>
              {data?.post_count ? toMoneyString(data?.post_count) : "0"}
            </span>
          </td>
          <td className="salesLiveResultCard-image">
            {type === "daily"
              ? getJsxIcon(data?.post_count, data?.daybefore_post_count)
              : getJsxIcon(data?.post_count, data?.lastmonth_post_count)
            }
          </td>
          <td className="salesLiveResultCard-diff">
            <span>
              {type === "daily" &&
              data?.post_count &&
              data?.daybefore_post_count
                ? getGap(data?.post_count, data?.daybefore_post_count)
                : type === "monthly" &&
                  data?.post_count &&
                  data?.lastmonth_post_count
                ? getGap(data?.post_count, data?.lastmonth_post_count)
                : ""}
            </span>
          </td>
        </tr>
        <tr>
          <td className="salesLiveResultCard-colum">
            <span>1投稿最高売上</span>
          </td>
          <td className="salesLiveResultCard-value">
            <span>
              ¥ {data?.max_sales ? toMoneyString(data?.max_sales) : "0"}
            </span>
          </td>
          <td className="salesLiveResultCard-image">
            {type === "daily"
              ? getJsxIcon(data?.max_sales, data?.daybefore_max_sales)
              : getJsxIcon(data?.max_sales, data?.lastmonth_max_sales)
            }
          </td>
          <td className="salesLiveResultCard-diff">
            <span>
              {type === "daily" && data?.max_sales && data?.daybefore_max_sales
                ? getGap(data?.max_sales, data?.daybefore_max_sales, '¥')
                : type === "monthly" &&
                  data?.max_sales &&
                  data?.lastmonth_max_sales
                ? getGap(data?.max_sales, data?.lastmonth_max_sales, '¥')
                : ""}
            </span>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default SalesLiveResultCard;

import { Modal } from "antd";
import Button from "../../button";
import "./style.scss";

/* 削除確認モダール */

const SelectUpdateModal = ({ modalVisible, onCancel, onOk, item }) => {
  return (
    <Modal
      className={"nf-modal-update"}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      width={350}
      onCancel={onCancel}
    >
      <div className="modal-content">
        <div className="update-title">一括更新</div>
        <div className="update-info">
          <div>選択した{item}を更新します</div>
          <div>よろしいですか？</div>
        </div>
        <div>
          <Button text="戻る" theme="white" onClick={onCancel} />
          <Button text="更新" theme="black" onClick={onOk} />
        </div>
      </div>
    </Modal>
  );
};

export default SelectUpdateModal;

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      live_cd
      corporation_cd
      user_cd
      user_name
      like_name
      like_image_url
      signaling_text
      sender_flag
      signaling_flag
      createdAt
      updatedAt
    }
  }
`;

import { Modal } from "antd";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import InfomationModal from "../../modal/infomationModal";
import { useEffect, useState } from "react";
import { Checkbox, Row, Col } from "antd";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";
import "./style.scss";

/* CSV出力モダール */

const CsvDownloadModal = ({
  modalVisible,
  options,
  favoriteItem,
  onClose,
  onDownload,
  onFavoriteItem,
}) => {
  const [checkedList, setCheckedList] = useState([]);
  const [checkAllFlg, setCheckAllFlg] = useState(false);
  const [infomationModalVisible, setInfomationModalVosible] = useState({
    visible: false,
    title: "",
  });
  useEffect(() => {
    let checkList = [];
    for (const option in favoriteItem) {
      if (favoriteItem[option] === "1") checkList.push(option);
    }
    setCheckedList(checkList);
    setCheckAllFlg(options.filter(e => e.value).length === checkList.length);
  }, [modalVisible]);
  const onCheckAllChange = (e) => {
    const checkVals = options.map((option) => option.value);
    setCheckedList(e.target.checked ? checkVals : []);
    setCheckAllFlg(!checkAllFlg);
  };
  const checkBoxesOnChange = (checkedValues) => {
    setCheckedList(checkedValues);
    setCheckAllFlg(options.filter(e => e.value).length === checkedValues.length);
  };

  return (
    <>
      <InfomationModal
        modalVisible={infomationModalVisible.visible}
        setModalVisible={(val) => {
          setInfomationModalVosible({ visible: val, title: "" });
        }}
        item={{ information_title: infomationModalVisible.title }}
      />
      <Modal
        visible={modalVisible}
        centered
        footer={null}
        closable={false}
        onCancel={onClose}
        width={550}
      >
        <div className="csvDownloadModal-content">
          <div className="title-area">
            <span className="text-title">CSV出力項目</span>
            <Button
              text={
                <>
                  <Icon
                    component={Icons.favoriteItemIcon}
                    style={{ marginRight: 3 }}
                  ></Icon>
                  お気に入り項目設定
                </>
              }
              onClick={() => {
                if (checkedList.length < 1) {
                  setInfomationModalVosible({
                    visible: true,
                    title: "項目を1つ以上選択してください",
                  });
                  return;
                }
                onFavoriteItem(checkedList);
              }}
              style={{
                width: 160,
                height: 30,
                fontSize: 14,
              }}
              theme={"white"}
            />
          </div>
          <div className="contents-area">
            <div>出力する項目を選択してください</div>
            <Checkbox
              onChange={onCheckAllChange}
              checked={checkAllFlg}
              style={{ margin: "20px auto" }}
            >
              全選択
            </Checkbox>
            <div className="checkbox-group">
              <Checkbox.Group onChange={checkBoxesOnChange} value={checkedList}>
                <Row>
                  {options.map((col) => {
                    return (
                      <Col span={col.colSpan} style={{ marginBottom: 10 }}>
                        {col && col.label && <Checkbox value={col.value}>{col.label}</Checkbox>}
                        {/* <GeneralCheckbox
                          defaultChecked={true}
                          label={col.label}
                          // value=
                          className="nf-checkbox-margin"
                          checked={inflowCheckBoxes.cordinate}
                          onChange={(val) => {
                            createOnInflow("cordinate", val.target.checked);
                          }}
                        /> */}
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
              {/* {options.map((cbox) => {
                <GeneralCheckbox
                  defaultChecked={true}
                  label="コーディネート"
                  className="nf-checkbox-margin"
                  checked={inflowCheckBoxes.cordinate}
                  onChange={(val) => {
                    createOnInflow("cordinate", val.target.checked);
                  }}
                />;
              })} */}
            </div>
          </div>
          <div className="button-area">
            <Button
              text="閉じる"
              onClick={onClose}
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
              theme={"white"}
            />
            <Button
              text="ダウンロード"
              onClick={() => {
                onDownload(checkedList);
              }}
              disabled={checkedList?.length == 0}
              style={{
                width: 140,
                height: 30,
                fontSize: 14,
                marginTop: 40,
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CsvDownloadModal;

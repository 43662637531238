import "./style.scss";
import { useSelector } from "react-redux";

/* 削除確認モダール */

const Loading = () => {
  const { loadingState, subText } = useSelector(state => state.loading);
  return (
    <>
    {loadingState &&
      (
        <div className="nf-loading">
          <div>
            <div class="loading-container">
              <div class="loading"></div>
              <div id="loading-text">loading</div>
            </div>
            {subText && <div class="loading-sub-text">{subText.map(e => e + '\n')}</div>}
          </div>
        </div>
      )
    } 
  </>
  );
};

export default Loading;

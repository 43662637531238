import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import moment from "moment";

export const fetchBrands = createAsyncThunk(
  "fetchBrands",
  async params => await axios.get("brands/list",
      { params: { mall_flag: 0, count: 0, sort : "brand_name_asc"}})
);

export const pdfDownload = createAsyncThunk(
  "pdfDownload",
  async params => {
    const result = await axios.get("analyze/pdf", { params, responseType: 'arraybuffer' });
    const blob = new Blob([ result ], { "type" : "application/pdf" });

    if (blob.size == 0) {
      return null;
    }

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    const reportTypeName = params?.report_type === '0' ? 'コーディネート分析' : '社員コンテンツ分析';
    const fileName = `ベスト表_${reportTypeName}_${moment().format('YYYYMMDDHHmmss')}.pdf`;
    a.download = fileName;
    a.href = url;
    a.target = "_self";
    a.click();
    a.remove();
    setTimeout(() => {
        URL.revokeObjectURL(url);
    }, 1);
    return result;
  }
);
const initialState = {
  brands: [],
  outputModalVisible: false,
  errorModalVisible: false,
}


export const pdfOutputSlice = createSlice({
  name: "pdfOutput",
  initialState,
  reducers: {
    clear: () => initialState,
    openOutputModal: (state, { payload }) => ({
      ...state,
      outputModalVisible: true,
    }),
    closeOutputModal: state => ({
      ...state,
      outputModalVisible: false,
    }),
    openErrorModal: (state, { payload }) => ({
      ...state,
      errorModalVisible: true,
    }),
    closeErrorModal: state => ({
      ...state,
      errorModalVisible: false,
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchBrands.fulfilled, (state, { payload }) => ({
      ...state,
      brands: (payload?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
    }));
    builder.addCase(pdfDownload.fulfilled, (state, { payload }) => ({
      ...state,
      errorModalVisible: !payload ? true : false,
    }));
  },
});

export const actions = pdfOutputSlice.actions;

export default pdfOutputSlice.reducer;

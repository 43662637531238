import { useEffect, useState, useLayoutEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Switch } from "antd";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import { actions, updateLivePreparation } from "../../../slices/liveSlice";
import { actions as loadingActions } from "../../../slices/loadingSlice";
import Button from "../../button";
import GeneralInput from "../../generalInput";
import Yup from "../../../utils/yupUtil";
import moment from "moment";
import { useHistory } from "react-router";
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { searchChats } from '../../../graphql/queries';
import { createChat } from '../../../graphql/mutations';
import "./style.scss";

import awsExports from "../../../aws-exports";
Amplify.configure(awsExports);

const LivePreparationModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { corporation_code } = useSelector(state => state.account);

  const {
    preparationModalVisible,
    activeLive,
    transitionLiveDelivery,
  } = useSelector(state => state.live ?? {});

  const onBack = async () => {
    dispatch(loadingActions.multiLoadingOn());
    await disconnect();
    dispatch(loadingActions.multiLoadingOff());
    dispatch(actions.closePreparationModal());
    formik.resetForm();
  };

  const afterClose = async () => {
    dispatch(actions.closePreparationModal());
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      live_code: activeLive.live_code,
      title: activeLive.title,
      start_date: moment(activeLive.start_datetime).format('YYYY.MM.DD HH:mm'),
      end_date: moment(activeLive.end_datetime).format('YYYY.MM.DD HH:mm'),
      camera: false,
      mic: false,
      telop_flag: !!activeLive.telop_flag,
      telop: !!activeLive.telop_flag ? activeLive.telop : '',
      beforeDelivery: moment(new Date()).isAfter(moment(activeLive.start_datetime)),
      afterDelivery: moment(new Date()).isBefore(moment(activeLive.end_datetime)),
      option: "",
      options: [],
      isBrowserAuth: true,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      telop: Yup.string()
      .test(
        "telop",
        "必須項目です",
        (value, testContext) => testContext.parent.telop_flag ? value : true,
      ),
      option: Yup.string().nullable().required(),
    }),
  });

  const connect = async (camera, mic, deviceId) => {
    dispatch(loadingActions.multiLoadingOn());
    const video = document.querySelector('#local-video');
    if (!video || (!camera && !mic)) {
      dispatch(loadingActions.multiLoadingOff());
      return;
    }
    if (video?.srcObject) {
      await disconnect();
    }
    navigator.mediaDevices.getUserMedia({
      video: camera ? (deviceId || option ? { deviceId: deviceId || option } : true) : false,
      audio: mic,
    }).then(stream => {
      video.srcObject = stream;
      video?.play();
      dispatch(loadingActions.multiLoadingOff());
    }).catch(e => {
      console.log(e);
      dispatch(loadingActions.multiLoadingOff());
    });
  };

  const disconnect = async () => {
    const video = document.querySelector('#local-video');
    if (!video) return;
    await removeVideoTrack();
    await removeAudioTrack();
  };

  const removeVideoTrack = async () => {
    const video = document.querySelector('#local-video');
    if (!video) return;
    const tracks = video?.srcObject?.getVideoTracks();
    if (tracks) {
      tracks.forEach(track => {
        console.log("stopVideo");
        track.stop();
      });
    }
  };

  const removeAudioTrack = async () => {
    const video = document.querySelector('#local-video');
    if (!video) return;
    const tracks = video?.srcObject?.getAudioTracks();
    if (tracks) {
      tracks.forEach(track => {
        console.log("stopAudio");
        track.stop();
      });
    }
  };

  const getTelop = async () => {
    try {
      const chatData = await API.graphql(graphqlOperation(searchChats, {
        filter: {
          live_cd: { eq: activeLive.live_code },
          corporation_cd: { eq: corporation_code },
          signaling_flag: { eq: "telop" },
        },
        sort: { field: "createdAt", direction: "desc" },
      }));
      const res = chatData?.data?.searchChats?.items;
      handlerFormikFieldChange(formik, "telop", res?.[0]?.signaling_text ?? '');
    } catch (err) { console.log('error getChatsItems', err); }
  };

  useEffect(() => {
    if (preparationModalVisible) {
      getTelop();
      setTimeout(() => {
        settingOptions();
      }, 500);
    }
    return () => {
      disconnect();
    }
  }, [preparationModalVisible]);

  useEffect(() => {
    if (transitionLiveDelivery) {
      history.push({
        pathname: `/liveDelivery`,
        state: { camera, mic, deviceId: option, live_cd: live_code },
      });
    }
    return () => dispatch(actions.clear());
  }, [transitionLiveDelivery]);

  const settingOptions = async (check) => {
    dispatch(loadingActions.multiLoadingOn());
    // 一度既存のStreamを開放する
    await disconnect();
    navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    }).then((stream) => {
      console.log("許可");
      handlerFormikFieldChange(formik, "isBrowserAuth", true);
      navigator?.mediaDevices?.enumerateDevices().then((result) => {
        const enumerateDevices = result?.filter(e => "videoinput" === e?.kind);
        handlerFormikFieldChange(formik, "options", enumerateDevices?.map(e => ({ value: e.deviceId, label: e.label })) ?? []);
        handlerFormikFieldChange(formik, "option", enumerateDevices?.[0]?.deviceId);
        if (!check) {
          handlerFormikFieldChange(formik, "camera", true);
          handlerFormikFieldChange(formik, "mic", true);
          connect(true, true);
        } else if (check === "camera") {
          handlerFormikFieldChange(formik, "camera", true);
          connect(true, mic);
        } else if (check === "mic") {
          handlerFormikFieldChange(formik, "mic", true);
          connect(camera, true);
        }
      });
    }).catch((err) => {
      console.log("ブロック", err);
      handlerFormikFieldChange(formik, "isBrowserAuth", false);
      handlerFormikFieldChange(formik, "camera", false);
      handlerFormikFieldChange(formik, "mic", false);
      dispatch(loadingActions.multiLoadingOff());
    });
  };

  const {
    live_code,
    title,
    start_date,
    end_date,
    camera,
    mic,
    telop_flag,
    telop,
    beforeDelivery,
    afterDelivery,
    option,
    options,
    isBrowserAuth,
  } = formik.values;

  return (
    <Modal
      visible={preparationModalVisible}
      footer={null}
      closable={false}
      width={1200}
      style={{ minWidth: 1200 }}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
    >
      <div className="live-preparation">
        <div className="left-area">
          {/* 配信詳細画像？ */}
          <video id="local-video" muted></video>
        </div>
        <div className="right-area">
          <div className="title">{title}</div>
          <div>{start_date} ~ {end_date}</div>
          <span className="nf-text-required-style">*は必須項目です</span>
          <div className="sub-title">カメラ・マイク初期設定</div>
          {!isBrowserAuth && <>
            <div className="nf-text-required-style">※カメラとマイクの使用がブロックされています</div>
            <div className="nf-text-required-style">　ブラウザの設定を見直してください</div>
          </>}
          <div className="switch-area">
            <div className="label">カメラ</div>
            <Switch checked={camera} onChange={async e => {
              if (!isBrowserAuth) {
                settingOptions("camera");
                return;
              }
              handlerFormikFieldChange(formik, "camera", e);
              if (e) {
                connect(true, mic);
              } else {
                removeVideoTrack();
              }
            }} />
          </div>
          <div className="switch-area">
            <div className="label">マイク</div>
            <Switch checked={mic} onChange={async e => {
              if (!isBrowserAuth) {
                settingOptions("mic");
                return;
              }
              handlerFormikFieldChange(formik, "mic", e);
              if (e) {
                connect(camera, true);
              } else {
                removeAudioTrack();
              }
            }} />
          </div>
          <div className="input-area-wrapper">
            <GeneralInput
              requiredItem={true}
              label="オプション"
              options={options}
              styleType="inline-grey"
              mode={null}
              name={'option'}
              formik={formik}
              extraOnChange={(name, value) => {
                connect(camera, mic, value);
              }}
            />
          </div>
          <div className="comment-area">
            {telop_flag &&
              <>
                <label className="text-required">テロップ</label>
                <textarea
                  placeholder="テロップ表示したい文言を入力してください"
                  spellCheck={false}
                  autoComplete={"false"}
                  value={telop}
                  onChange={e =>
                    handlerFormikFieldChange(formik, "telop", e.target.value)
                  }
                />
              </>
            }
          </div>
        </div>
      </div>
      <div className="action-container">
        <div>
          <Button text="閉じる" theme="white" onClick={onBack} />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Button
            text="オンライン"
            theme="red"
            style={{ marginBottom: "0" }}
            disabled={!beforeDelivery || !afterDelivery || !formik.isValid}
            onClick={async () => {
              // テロップ更新
              if (telop_flag) {
                const user = activeLive?.streamer_info?.[0];
                await API.graphql(graphqlOperation(createChat, {
                  input: {
                    live_cd: activeLive.live_code,
                    corporation_cd: corporation_code,
                    user_cd: user?.user_code,
                    user_name: user?.name_nickname || user?.user_name,
                    signaling_text: telop,
                    sender_flag: "backoffice",
                    signaling_flag: "telop",
                  },
                }));
              }

              // カメラを切る
              disconnect();

              // ステータス更新
              dispatch(
                updateLivePreparation({
                  division: "update",
                  live_code: live_code,
                  live_flag: activeLive.live_flag,
                  status_flag: "03",
                })
              );
            }}
          />
          {!beforeDelivery && <div className="live-description-label">※配信時間になると開始できます</div>}
          {!afterDelivery && <div className="live-description-label">※配信時間を過ぎているため開始できません</div>}
        </div>
      </div>
    </Modal>
  );
};

export default LivePreparationModal;
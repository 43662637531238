import { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Image } from "antd";
import prefecturesList from "../../../constants/prefectures.json";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import {
  actions,
  fetchCorporationDetail,
  updateCorporation,
} from "../../../slices/corporationSlice";
import { tokenLogin } from "../../../slices/accountSlice";
import Button from "../../button";
import GeneralInput from "../../generalInput";
import BaseUpload from "../../baseUpload";
import noimage from "../../../images/noimage.png";
import Yup from "../../../utils/yupUtil";
import axios from "axios";
import baseUrl from "../../../constants/NetfreamAPIURL";
import axiosJsonpAdapter from "axios-jsonp";
import "./style.scss";

const CorporationEditModal = () => {
  const dispatch = useDispatch();

  const handleFileChange = url =>
    handlerFormikFieldChange(formik, "corporation_image_url", url);

  const { editModalVisible, corporationDetail, loginApi } = useSelector(
    state => state.corporation
  );

  const account = useSelector((state) => state.account);

  const formik = useFormik({
    initialValues: {
      division: "update",
      corporation_code: corporationDetail.corporation_code ?? "",
      corporation_name: corporationDetail.corporation_name ?? "",
      corporation_name_kana: corporationDetail.corporation_name_kana ?? "",
      auto_connection_flag: corporationDetail.auto_connection_flag,
      coordinate_retention_period:
        corporationDetail.coordinate_retention_period || 365,
      blog_retention_period: corporationDetail.blog_retention_period || 365,
      live_flag: corporationDetail.live_flag === "1" ? "利用する" : "利用しない",
      multi_post_flag: corporationDetail.multi_post_flag,
      archive_retention_period: corporationDetail.archive_retention_period || 180,
      movie_retention_period: corporationDetail.movie_retention_period || 180,
      zip1: corporationDetail.zip?.number1,
      zip2: corporationDetail.zip?.number2,
      prefectures_code: corporationDetail.prefectures,
      address1: corporationDetail.municipality,
      address2: corporationDetail.house_number,
      address3: corporationDetail.house_info,
      tel1: corporationDetail.tel?.number1,
      tel2: corporationDetail.tel?.number2,
      tel3: corporationDetail.tel?.number3,
      fax1: corporationDetail.fax?.number1,
      fax2: corporationDetail.fax?.number2,
      fax3: corporationDetail.fax?.number3,
      mail_address: corporationDetail.mail_address,
      corporation_image_url: corporationDetail.corporation_image_url,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      corporation_code: Yup.string().required(),
      corporation_name: Yup.string().required(),
      corporation_name_kana: Yup.string().kana(),
      mail_address: Yup.string().max(256).mail().mail_local(),
      tel: Yup.string().nullable().tel(),
      fax: Yup.string().nullable().fax(),
      zip: Yup.string().nullable().zip(),
      zip1: Yup.string()
        .nullable()
        .matches(/^(\s*|[0-9]{3})$/, "3桁の数値で入力してください"),
      zip2: Yup.string()
        .nullable()
        .matches(/^(\s*|[0-9]{4})$/, "4桁の数値で入力してください"),
    }),
    onSubmit: values => {
      const { zip1, zip2, ...rest } = values;
      dispatch(
        updateCorporation({
          ...rest,
          zip: zip1 && zip2 ? `${zip1}-${zip2}` : "",
        })
      );
    },
  });

  const onBack = () => {
    dispatch(actions.closeEditModal());
    formik.resetForm();
  };

  const onSearchZip = async () => {
    const zipcode = `${formik.values.zip1}${formik.values.zip2}`;
    try {
      const addressRes =
        /^\d{7}$/.test(zipcode) &&
        (await axios.get(`${baseUrl.ZIP_SEARCH_URL}`, {
          adapter: axiosJsonpAdapter,
          params: {
            zipcode,
          },
        }));
      const address = addressRes.data?.results[0];
      if (address) {
        formik.setFieldValue(
          "prefectures_code",
          prefecturesList.prefectures.find(p => p.label === address.address1)
            ?.prefecturesCode
        );
        formik.setFieldValue("address1", address.address2);
        formik.setFieldValue("address2", address.address3);
      }
    } catch (error) {}
  };

  const { corporation_image_url, auto_connection_flag, multi_post_flag } = formik.values;

  useEffect(() => {
    editModalVisible && dispatch(fetchCorporationDetail());
  }, [dispatch, editModalVisible]);

  useEffect(() => {
    loginApi &&
      dispatch(
        tokenLogin({
          request_from: 2,
          corporation_code: account?.corporation_code,
          token: account?.token,
        })
      );
      dispatch(actions.loginApiStatus());
  }, [dispatch, loginApi]);

  return (
    <Modal
      visible={editModalVisible}
      closable={false}
      width={900}
      style={{ minWidth: 900 }}
      footer={null}
      onCancel={onBack}
      destroyOnClose={true}
    >
      <h2 className="text-bold signup-title">
        法人編集
        <span className="nf-text-required-style">　*は必須項目です</span>
      </h2>
      <div className="corporation-edit">
        <form onSubmit={formik.handleSubmit}>
          <div className="corporation-edit-area">
            <div className="file-upload-area">
              <BaseUpload onChange={handleFileChange}>
                <div className="avator">
                  <Image
                    src={corporation_image_url || noimage}
                    preview={{ mask: "画像を選択", visible: false }}
                  />
                </div>
              </BaseUpload>
              <div className="upload-tip">
                <span>png,gif,jpg,jpeg形式のみアップロード可</span>
                <span>画像は5M以下でアップロードしてください</span>
                <span>推奨画像サイズ:正方形</span>
              </div>
            </div>
            <div className={"input-section"}>
              <GeneralInput
                label="法人CD"
                labelTextAlign="left"
                labelWidth="wider"
                requiredItem
                styleType="block-grey-normal"
                uneditable={true}
                name={"corporation_code"}
                formik={formik}
              />
              <GeneralInput
                label="法人名"
                labelWidth="wider"
                labelTextAlign="left"
                className="input-name"
                placeholder="法人名を入力してください"
                requiredItem
                styleType="block-grey-normal"
                formik={formik}
                name={"corporation_name"}
              />
              <GeneralInput
                label="法人名(カナ)"
                labelWidth="wider"
                className="input-name"
                labelTextAlign="left"
                placeholder="法人名(カナ)を入力してください"
                styleType="block-grey-normal"
                formik={formik}
                name={"corporation_name_kana"}
              />
              <GeneralInput
                label="自動連携"
                labelTextAlign="left"
                labelWidth="wider"
                requiredItem
                styleType="block-grey-normal"
                uneditable={true}
                name={"auto_connection_flag"}
                formik={formik}
                displayValue={
                  auto_connection_flag === 0 ? "連携する" : "連携しない"
                }
              />
              <GeneralInput
                label="コーディネート保持期間"
                labelTextAlign="left"
                className="input-period"
                labelWidth="wider"
                styleType="block-grey-normal"
                uneditable={true}
                name={"coordinate_retention_period"}
                formik={formik}
                textAfter="日"
              />
              <GeneralInput
                label="ブログ保持期間"
                labelTextAlign="left"
                labelWidth="wider"
                styleType="block-grey-normal"
                uneditable={true}
                name={"blog_retention_period"}
                formik={formik}
                textAfter="日"
              />
              <GeneralInput
                  label="同時投稿"
                  labelTextAlign="left"
                  labelWidth="wider"
                  styleType="block-grey-normal"
                  uneditable={true}
                  name={"multi_post_flag"}
                  formik={formik}
                  displayValue={multi_post_flag === 1 ? "利用する" : "利用しない"}
              />
              <GeneralInput
                label="ライブ配信・動画機能"
                labelTextAlign="left"
                labelWidth="wider"
                styleType="block-grey-normal"
                uneditable={true}
                name={"live_flag"}
                formik={formik}
              />
              {corporationDetail.live_flag === "1" &&
                <>
                  <GeneralInput
                    label="アーカイブ保存日数"
                    labelTextAlign="left"
                    labelWidth="wider"
                    styleType="block-grey-normal"
                    uneditable={true}
                    name={"archive_retention_period"}
                    formik={formik}
                    textAfter="日"
                  />
                  <GeneralInput
                    label="動画保持期間"
                    labelTextAlign="left"
                    labelWidth="wider"
                    styleType="block-grey-normal"
                    uneditable={true}
                    name={"movie_retention_period"}
                    formik={formik}
                    textAfter="日"
                  />
                </>
              }
            </div>
            <div className={"input-section"}>
              <div className="text-bold">請求情報</div>
              <div className="input-multi-area">
                <GeneralInput
                  label="郵便番号"
                  labelTextAlign="left"
                  labelWidth="wider"
                  placeholder="123"
                  styleType="block-grey-normal"
                  name={"zip1"}
                  onKeyUp={onSearchZip}
                  formik={formik}
                />
                <GeneralInput
                  label="-"
                  className="input-tel"
                  placeholder="4567"
                  styleType="block-grey-normal"
                  name={"zip2"}
                  onKeyUp={onSearchZip}
                  formik={formik}
                />
                <span className="validation-error validation-multi">
                  {formik.touched.zip1 &&
                    formik.touched.zip2 &&
                    formik.errors.zip}
                </span>
              </div>
              <GeneralInput
                label="都道府県"
                labelWidth="wider"
                labelTextAlign="left"
                options={prefecturesList.prefectures.map(p => ({
                  label: p.label,
                  value: p.prefecturesCode,
                }))}
                placeholder="都道府県を選択して下さい"
                styleType="block-grey-normal"
                mode={null}
                formik={formik}
                name={"prefectures_code"}
              />
              <GeneralInput
                label="市区町村"
                labelWidth="wider"
                labelTextAlign="left"
                className="input-long-text"
                placeholder="市区町村を入力してください"
                styleType="block-grey-normal"
                formik={formik}
                name={"address1"}
              />
              <GeneralInput
                label="丁目番地"
                labelWidth="wider"
                className="input-long-text"
                labelTextAlign="left"
                placeholder="丁目番地を入力してください"
                styleType="block-grey-normal"
                formik={formik}
                name={"address2"}
              />
              <GeneralInput
                label="館情報"
                labelWidth="wider"
                className="input-long-text"
                labelTextAlign="left"
                placeholder="館情報を入力してください"
                styleType="block-grey-normal"
                formik={formik}
                name={"address3"}
              />
              <div className="input-multi-area">
                <GeneralInput
                  label="電話番号"
                  labelTextAlign="left"
                  labelWidth="wider"
                  placeholder="03"
                  styleType="block-grey-normal"
                  name={"tel1"}
                  formik={formik}
                />
                <GeneralInput
                  label="-"
                  className="input-tel"
                  placeholder="0000"
                  styleType="block-grey-normal"
                  name={"tel2"}
                  formik={formik}
                />
                <GeneralInput
                  label="-"
                  className="input-tel"
                  placeholder="0000"
                  styleType="block-grey-normal"
                  name={"tel3"}
                  formik={formik}
                />
                <span className="validation-error validation-multi">
                  {formik.touched.tel1 &&
                    formik.touched.tel2 &&
                    formik.touched.tel3 &&
                    formik.errors.tel}
                </span>
              </div>
              <div className="input-multi-area">
                <GeneralInput
                  label="FAX"
                  labelTextAlign="left"
                  labelWidth="wider"
                  placeholder="03"
                  styleType="block-grey-normal"
                  name={"fax1"}
                  formik={formik}
                />
                <GeneralInput
                  label="-"
                  className="input-tel"
                  placeholder="0000"
                  styleType="block-grey-normal"
                  name={"fax2"}
                  formik={formik}
                />
                <GeneralInput
                  label="-"
                  className="input-tel"
                  placeholder="0000"
                  styleType="block-grey-normal"
                  name={"fax3"}
                  formik={formik}
                />
                <span className="validation-error validation-multi">
                  {formik.touched.fax1 &&
                    formik.touched.fax2 &&
                    formik.touched.fax3 &&
                    formik.errors.fax}
                </span>
              </div>
              <GeneralInput
                className={"input-mail"}
                label="メールアドレス"
                labelTextAlign="left"
                labelWidth="wider"
                placeholder="メールアドレスを入力して下さい"
                styleType="block-grey-normal"
                name={"mail_address"}
                formik={formik}
              />
            </div>
          </div>
          <div className="action-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <Button text="更新" type="submit" disabled={!formik.isValid} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CorporationEditModal;

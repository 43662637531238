import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { actions as loadingActions } from "./loadingSlice";
import axios from "../utils/axiosUtil";

export const testAnalysisInit = createAsyncThunk("testAnalysisInit", async (params, {dispatch}) => {
  const [brandsRes,analyzeRes] = await Promise.all([
    axios.get("brands/list", { params: { count: 0, sort : "brand_name_asc"}}),
    axios.get("analyze/url/list",{ params: { count: 30}}),
  ]);
  dispatch(loadingActions.multiLoadingOff());
  return {
    brands: (brandsRes?.item ?? []).map(b => ({
      ...b,
      label: b.brand_name,
      value: b.brand_code,
    })),
    analyzeList:(analyzeRes.item ?? []).map(s => ({
      ...s,
      label: s.param.replace("&Benent=",""),
      value: s.param.replace("&Benent=",""),
    }))
  };
});

export const fetchCoordinate = createAsyncThunk(
  "fetchCoordinate",
  async params => await axios.get("coordinate/list", { params }),
);
export const fetchArticlesDetail = createAsyncThunk(
  "fetchArticlesDetail",
  async params => await axios.get("articles/detail", { params }),
);
export const fetchCoordinateDetail = createAsyncThunk(
  "fetchCoordinateDetail",
  async params => await axios.get("coordinate/detail", { params }),
);
export const fetchArticles = createAsyncThunk(
  "fetchArticles",
  async params => await axios.get("articles/list", { params }),
);
export const fetchLive = createAsyncThunk(
  "fetchLive",
  async params => await axios.get("live/list", { params }),
);

// コーディネートからデータ取得
const contentSearchParams = {
  brand_code:"",
  status_flg: 4,
  sort: "article_code_asc",
  // sort: "coordinate_code_asc",
  count: 0,
  display_item: 1,
};
// 商品マスタからデータ取得
// シェア用URLからデータ取得
const liveSearchParams = {
  brand_code:"",
  sort:"",
  count: 30,
  live_flag: 0
}

const initialEditParams = {
  // どのAPIを叩くか　1:pv 2:snspv 3:cv(購入)
  type:0,
  corporation_code: null,
  brand_code: null,
  content_type: null,
  content_code: null,
  user_id: null,
  uuid: null,
  // pv専用
  share_parameter: null,
  // 購入専用
  order_id: null,
  product_info: "",
  product_code: "",
  select_product: "",
  // ライブ専用
  live_code : null,
  live_flag : null,
  product_code : null
};

const initialState ={
  brands:[],
  contentSearchParams: contentSearchParams,
  contentList:[],
  liveList:[],
  productList:[],
  analyzeList:[],
  contentOptions:[
    { label: "コーディネート", value: "1" },
    { label: "ブログ", value: "2" },
  ],
  liveOptions:[
    { label: "ライブ", value: "0" },
    { label: "アーカイブ", value: "1" },
    { label: "動画", value: "2" },
  ],
  edit: initialEditParams,
}


export const testAnalysisSlice = createSlice({
  name: "testAnalysis",
  initialState,
  reducers: {
    clear: () => initialState,
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveProductsList: (state, { payload }) => ({
      ...state,
      productList: payload
    }),
  },
  extraReducers: builder => {
    builder.addCase(testAnalysisInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
    builder.addCase(fetchCoordinate.fulfilled, (state, { payload }) => ({
      ...state,
      contentList: (payload?.item ?? []).map(c => ({
        ...c,
        label: c.coordinate_code,
        value: c.coordinate_code,
      })),
    }));
    builder.addCase(fetchArticles.fulfilled, (state, { payload }) => ({
      ...state,
      contentList: (payload?.item ?? []).map(a => ({
        ...a,
        label: a.article_code+" : "+a.title,
        value: a.article_code,
      })),
      productList: (payload?.item?.products ?? []).map(b => ({
        ...b,
        label: b.product_name,
        value: b.product_code,
      })),
    }));
    builder.addCase(fetchCoordinateDetail.fulfilled, (state, { payload }) => ({
      ...state,
      productList : (payload?.item?.products ?? []).map(c => ({
        ...c,
        label: c.product_name,
        value: c.product_code,
      })),
    }));
    builder.addCase(fetchLive.fulfilled, (state, { payload }) => ({
      ...state,
      liveList: (payload?.item ?? []).map(d => ({
        ...d,
        label: d.live_code,
        value: d.live_code,
      })),
      productList : (payload?.item?.products ?? []).map(e => ({
        ...e,
        label: e.product_code,
        value: e.product_code,
      })),
    }));
  },
});

export const actions = testAnalysisSlice.actions;

export default testAnalysisSlice.reducer;

import React from "react";
import { useFormik } from "formik";
import { useUpdateEffect } from "../../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "react-use";
import {
  defaultMenu,
  genderOptions,
  userAuthOptions,
} from "../../../constants/options";
import { Modal, Image, DatePicker } from "antd";
import {
  actions,
  fetchReferrerStaffDetail,
  updateStaff,
  checkStaffExist,
} from "../../../slices/staffSlice";
import {
  cryptoString,
  getMenuArray,
  generatePassword,
  createNumberOptions,
  handlerFormikFieldChange,
} from "../../../utils/fnUtil";
import { pickBy } from "lodash";
import Button from "../../button";
import GeneralInput from "../../generalInput";
import BaseUpload from "../../baseUpload";
import noimage from "../../../images/noimage.png";
import MenuCheckbox from "../../menuCheckbox";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";
import Yup from "../../../utils/yupUtil";
import classNames from "classnames";
import moment from "moment";
import "./style.scss";

const StaffSignUpModal = () => {
  const dispatch = useDispatch();

  const handleFileChange = url =>
    handlerFormikFieldChange(formik, "profile_image_url", url);

  const {
    signUpModalVisible,
    referrerStaff,
    referrerStaffValid,
    shops,
    userExist,
  } = useSelector(state => state.staff);

  const { user_auth, live_flag, multi_post_flag = 0 } = useSelector(state => state.account);

  const formik = useFormik({
    initialValues: {
      division: "insert",
      brand_name: null,
      shop_code: null,
      user_code: "",
      user_name: "",
      user_namekana: "",
      user_nickname: "",
      mail_address: "",
      password: "",
      password_confirm: "",
      tel1: "",
      tel2: "",
      tel3: "",
      gender: null,
      height: 155,
      comment: "",
      use_from_date: moment(),
      use_to_date: moment("2999-12-31"),
      insta_id: "",
      twitter_id: "",
      referrer_login_id: "",
      user_auth: null,
      profile_image_url: "",
      coordinate_approval_flag: 0,
      blog_approval_flag: 0,
      mall_flag: 0,
      ...defaultMenu,
    },
    validationSchema: Yup.object({
      shop_code: Yup.string().nullable().selected("店舗"),
      user_code: Yup.string()
        .required()
        .halfWidthAlphanumeric()
        .test(
          "user_code",
          "入力された社員コードは既に登録済みです",
          () => !userExist
        ),
      referrer_login_id: Yup.string()
        .nullable()
        .test(
          "referrer_login_id",
          `${
            userAuthOptions.find(u => u.value === user_auth)?.label ?? ""
          }以下の有効な社員CDを入力してください`,
          value => !value || referrerStaffValid
        ),
      user_name: Yup.string().required(),
      user_namekana: Yup.string().kana().required(),
      mail_address: Yup.string().required().max(256).mail().mail_local(),
      password: Yup.string().min(6).password(),
      password_confirm: Yup.string().nullable().passwordConfirm(),
      use_from_date: Yup.string().nullable().date_required(),
      use_to_date: Yup.string().nullable().date_required(),
      tel: Yup.string().nullable().tel(),
      user_auth: Yup.string().nullable().selected(),
    }),
    onSubmit: values => {
      const {
        brand_name,
        tel1,
        tel2,
        tel3,
        password,
        password_confirm,
        use_from_date,
        use_to_date,
        mall_flag,
        ...rest
      } = values;
      dispatch(
        updateStaff({
          ...rest,
          tel: {
            tel1,
            tel2,
            tel3,
          },
          password: password ? cryptoString(password) : generatePassword(),
          use_from_date: use_from_date.format("YYYY-MM-DD"),
          use_to_date: use_to_date.format("YYYY-MM-DD"),
          multi_post_flag:mall_flag,
        })
      );
    },
  });

  const onBack = () => {
    dispatch(actions.closeSignUpModal());
    formik.resetForm();
  };

  const {
    values: {
      profile_image_url,
      use_from_date,
      use_to_date,
      comment,
      user_code,
      coordinate_approval_flag,
      blog_approval_flag,
      referrer_login_id,
      mall_flag,
    },
    setFieldTouched,
  } = formik;

  useDebounce(
    () => {
      referrer_login_id
        ? dispatch(
            fetchReferrerStaffDetail({
              user_code: referrer_login_id,
            })
          ).then(() => setFieldTouched("referrer_login_id", true))
        : dispatch(actions.clearReferrerStaff());
    },
    500,
    [referrer_login_id, setFieldTouched, dispatch]
  );

  useDebounce(
    () => {
      user_code &&
        dispatch(
          checkStaffExist({
            user_code,
            deleted_flg: 1,
          })
        ).then(() => setFieldTouched("user_code", true));
    },
    500,
    [user_code, setFieldTouched, dispatch]
  );

  useUpdateEffect(() => {
    formik.setValues({
      ...formik.values,
      ...(referrerStaff
        ? {
            ...pickBy(referrerStaff, (_, key) => getMenuArray().includes(key)),
            user_master_flag: referrerStaff.user_mst_flag ?? 0,
            shop_master_flag: referrerStaff.shop_mst_flag ?? 0,
            brand_master_flag: referrerStaff.brand_mst_flag ?? 0,
          }
        : { ...defaultMenu }),
      user_auth: referrerStaff?.user_auth || null,
    });
  }, [referrerStaff]);

  useUpdateEffect(() => {
    signUpModalVisible && formik.validateForm();
  }, [signUpModalVisible]);

  const afterClose = () => {
    dispatch(actions.clearExtraInfo());
    formik.resetForm();
  };

  return (
    <Modal
      visible={signUpModalVisible}
      closable={false}
      width={900}
      style={{ minWidth: 900 }}
      footer={null}
      destroyOnClose={true}
      onCancel={onBack}
      afterClose={afterClose}
    >
      <h2 className="signup-title">
        <span className="text-bold"> 社員登録</span>
        <span className="nf-text-required-style">　*は必須項目です</span>
      </h2>
      <div className="staff-signup">
        <form onSubmit={formik.handleSubmit}>
          <div className="staff-edit-area">
            <div className="file-upload-area">
              <BaseUpload onChange={handleFileChange}>
                <div className="avator">
                  <Image
                    src={profile_image_url || noimage}
                    preview={{ mask: "画像を選択", visible: false }}
                  />
                </div>
              </BaseUpload>
              <div className="upload-tip">
                <span>png,gif,jpg,jpeg形式のみアップロード可</span>
                <span>画像は5M以下でアップロードしてください</span>
                <span>推奨画像サイズ:正方形</span>
              </div>
            </div>
            <div className={"input-section"}>
              <div className="text-bold">所属</div>
              <GeneralInput
                options={shops}
                label="店舗"
                labelTextAlign="left"
                labelWidth="wider"
                requiredItem
                placeholder="店舗を選択して下さい"
                styleType="block-grey-normal"
                mode={null}
                name={"shop_code"}
                formik={formik}
                extraOnChange={(_, value) =>
                  formik.setFieldValue(
                    "brand_name",
                    (shops.find(s => s.shop_code === value)?.brand ?? [])
                      .map(b => b.brand_name)
                      .join(",")
                  )
                }
              />
              <GeneralInput
                label="ブランド"
                labelWidth="wider"
                labelTextAlign="left"
                className="brand-text-area"
                requiredItem
                uneditable={true}
                styleType="block-grey-normal"
                formik={formik}
                name={"brand_name"}
              />
            </div>
            <div className={"input-section"}>
              <div className="text-bold">社員情報</div>
              <GeneralInput
                label="社員CD"
                labelTextAlign="left"
                requiredItem
                labelWidth="wider"
                placeholder="社員CDを入力して下さい"
                styleType="block-grey-normal"
                name={"user_code"}
                formik={formik}
              />
              <GeneralInput
                label="社員名"
                labelTextAlign="left"
                requiredItem
                labelWidth="wider"
                placeholder="社員名を入力して下さい"
                styleType="block-grey-normal"
                name={"user_name"}
                formik={formik}
              />
              <GeneralInput
                label="社員名(カナ)"
                labelTextAlign="left"
                requiredItem
                labelWidth="wider"
                placeholder="社員名(カナ)を入力して下さい"
                styleType="block-grey-normal"
                name={"user_namekana"}
                formik={formik}
              />
              <GeneralInput
                label="サイト表示名"
                labelTextAlign="left"
                labelWidth="wider"
                placeholder="サイト表示名を入力して下さい"
                styleType="block-grey-normal"
                name={"user_nickname"}
                formik={formik}
              />
              <GeneralInput
                className={"input-mail"}
                label="メールアドレス"
                labelTextAlign="left"
                requiredItem
                labelWidth="wider"
                placeholder="メールアドレスを入力して下さい"
                styleType="block-grey-normal"
                name={"mail_address"}
                formik={formik}
              />
              <GeneralInput
                label="パスワード"
                className="input-password"
                inputType="password"
                labelTextAlign="left"
                labelWidth="wider"
                styleType="block-grey-normal"
                name={"password"}
                formik={formik}
                textAfter="未入力の場合は自動でセットされます"
              />
              <GeneralInput
                label="パスワード(再入力)"
                inputType="password"
                labelTextAlign="left"
                labelWidth="wider"
                styleType="block-grey-normal"
                name={"password_confirm"}
                formik={formik}
              />
              <div className="input-tel-area">
                <GeneralInput
                  label="電話番号"
                  labelTextAlign="left"
                  labelWidth="wider"
                  placeholder="03"
                  styleType="block-grey-normal"
                  name={"tel1"}
                  formik={formik}
                />
                <GeneralInput
                  label="-"
                  className="input-tel"
                  placeholder="0000"
                  styleType="block-grey-normal"
                  name={"tel2"}
                  formik={formik}
                />
                <GeneralInput
                  label="-"
                  className="input-tel"
                  placeholder="0000"
                  styleType="block-grey-normal"
                  name={"tel3"}
                  formik={formik}
                />
                <span className="validation-error validation-tel">
                  {formik.touched.tel1 &&
                    formik.touched.tel2 &&
                    formik.touched.tel3 &&
                    formik.errors.tel}
                </span>
              </div>
              <GeneralInput
                label="性別"
                labelWidth="wider"
                labelTextAlign="left"
                options={genderOptions}
                placeholder="性別を選択して下さい"
                styleType="block-grey-normal"
                mode={null}
                formik={formik}
                name={"gender"}
              />
              <GeneralInput
                label="身長"
                className="input-height"
                labelWidth="wider"
                labelTextAlign="left"
                options={createNumberOptions()}
                placeholder="身長を選択して下さい"
                styleType="block-grey-normal"
                mode={null}
                formik={formik}
                name={"height"}
                textAfter={"cm"}
              />
              <div className="input-comment">
                <span>コメント</span>　
                <textarea
                  placeholder="コメントを入力してください"
                  spellCheck={false}
                  autoComplete={"false"}
                  value={comment}
                  onChange={e =>
                    handlerFormikFieldChange(formik, "comment", e.target.value)
                  }
                />
              </div>
              <div className="input-date">
                <div className="text-required iregular-label">利用開始日</div>
                <div className="picker-wrapper">
                  <DatePicker
                    format={"YYYY.MM.DD"}
                    size="small"
                    placeholder={null}
                    value={use_from_date}
                    suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onChange={value =>
                      handlerFormikFieldChange(
                        formik,
                        "use_from_date",
                        value?.isSameOrAfter(use_to_date) ? use_to_date : value
                      )
                    }
                  />
                  <span className="validation-error validation-date">
                    {formik.touched.use_from_date &&
                      formik.errors.use_from_date}
                  </span>
                </div>
                <div className="picker-wrapper">
                  <span className="text-required end-date">利用終了日</span>
                  <DatePicker
                    format={"YYYY.MM.DD"}
                    value={use_to_date}
                    placeholder={null}
                    suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                    onChange={value =>
                      handlerFormikFieldChange(
                        formik,
                        "use_to_date",
                        value?.isSameOrBefore(use_from_date)
                          ? use_from_date
                          : value
                      )
                    }
                  />
                  <span className="validation-error validation-date">
                    {formik.touched.use_to_date && formik.errors.use_to_date}
                  </span>
                </div>
              </div>
            </div>
            <div className={classNames("input-section input-social")}>
              <div className="text-bold">SNS</div>
              <span className="social-name">Instagram</span>
              <GeneralInput
                label="ID"
                labelTextAlign="left"
                labelWidth="wider"
                placeholder="IDを入力して下さい"
                styleType="block-grey-normal"
                name={"insta_id"}
                formik={formik}
              />
              <span className="social-name">Twitter</span>
              <GeneralInput
                label="ID"
                labelTextAlign="left"
                labelWidth="wider"
                placeholder="IDを入力して下さい"
                styleType="block-grey-normal"
                name={"twitter_id"}
                formik={formik}
              />
            </div>
            <div className={"input-section"}>
              <div className="auth-title">
                <div className="text-bold">権限</div>
                <div className="auth-tip">
                  <span>
                    プログラム内で権限に応じた制御をしているメニューの場合、プログラムでの設定が優先されます。
                  </span>
                  <span>詳細はユーザーマニュアルをご確認ください</span>
                </div>
              </div>
              <GeneralInput
                label="参照元社員"
                labelTextAlign="left"
                labelWidth="wider"
                placeholder="参照元社員を入力して下さい"
                styleType="block-grey-normal"
                name={"referrer_login_id"}
                formik={formik}
              />
              <GeneralInput
                label="権限"
                options={userAuthOptions.filter(
                  option => option.value >= user_auth
                )}
                disabled={!!referrerStaff}
                labelTextAlign="left"
                labelWidth="wider"
                requiredItem
                placeholder="権限を選択して下さい"
                styleType="block-grey-normal"
                mode={null}
                name={"user_auth"}
                formik={formik}
              />
              <div className="input-menu">
                <div className="text-label">利用メニュー</div>
                <MenuCheckbox formik={formik} disabled={!!referrerStaff} />
              </div>
              <div className="input-approval">
                <div className="text-label">承認スキップ</div>
                <GeneralCheckbox
                  label="コーディネート"
                  checked={coordinate_approval_flag === 1}
                  onChange={() =>
                    formik.setFieldValue(
                      "coordinate_approval_flag",
                      coordinate_approval_flag === 1 ? 0 : 1
                    )
                  }
                />
                <GeneralCheckbox
                  label="ブログ"
                  checked={blog_approval_flag === 1}
                  onChange={() =>
                    formik.setFieldValue(
                      "blog_approval_flag",
                      blog_approval_flag === 1 ? 0 : 1
                    )
                  }
                />
              </div>
              <div className="input-approval">
                <div className="text-label">同時投稿</div>
                {multi_post_flag === 1 ? (
                    <>
                      <GeneralCheckbox
                          label="利用する"
                          checked={mall_flag === 1}
                          onChange={(value) => handlerFormikFieldChange(formik, "mall_flag", value.target.checked ? 1 : 0)}
                      />
                      <GeneralCheckbox
                          label="利用しない"
                          checked={mall_flag === 0}
                          onChange={(value) => handlerFormikFieldChange(formik, "mall_flag", value.target.checked ? 0 : 1)}
                      />
                    </>
                  ):(
                    <span className="input-value">利用しない</span>
                  )}
              </div>
            </div>
          </div>
          <div className="action-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            <Button text="登録" type="submit" disabled={!formik.isValid} />
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default StaffSignUpModal;

import BaseProgressBar from "./base";

const progress = [
  "下書き",
  "承認待ち",
  "差戻し",
  "承認済み(未公開)",
  "公開中",
];

export const statusMap = [
  {
    status: "承認待ち",
    value: "1",
  },
  {
    status: "承認済み\n(未公開)",
    value: "3",
  },
  {
    status: "差戻し",
    value: "2",
  },
  {
    status: "下書き",
    value: "0",
  },
  {
    status: "公開中",
    value: "4",
  },
];

const ProgressBar = ({ activeProgress }) => {
  return <BaseProgressBar progress={progress} activeProgress={activeProgress} />;
};

export default ProgressBar;

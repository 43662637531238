import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GuestRoute from "./guestRoute";
import PrivateRoute from "./privateRoute";
import ScrollToTop from "./scrollToTop";
import Articles from "../pages/articles";
import CoordinateList from "../pages/coordinate";
import LiveList from "../pages/live";
import MovieList from "../pages/movie";
import LiveDelivery from "../pages/liveDelivery";
import StaffList from "../pages/staff";
import Category from "../pages/category";
import Tag from "../pages/tag";
import Shops from "../pages/shop";
import Brand from "../pages/brand";
import Product from "../pages/product";
import Reward from "../pages/reward";
import Login from "../pages/login";
import PasswordRecovery from "../pages/passwordRecovery";
import Top from "../pages/top";
import Notification from "../pages/notification";
import Reserves from "../pages/reserves";
import Payments from "../pages/payments";
import Ranking from "../pages/ranking";
import ShareUrl from "../pages/shareUrl";
import ShareUrls from "../pages/shareUrls";
import AnalyzeCoordinate from "../pages/analyzeCoordinate";
import AnalyzeArticles from "../pages/analyzeArticles";
import AnalyzeLive from "../pages/analyzeLive";
import AnalyzeMovie from "../pages/analyzeMovie";
import AnalyzeContents from "../pages/analyzeContents";
import StaffResult from "../pages/staffResult";
import AnalyzeSnsList from "../pages/analyzeSnsList";
import AnalyzeShop from "../pages/analyzeShop";
import AnalyzeBrand from "../pages/analyzeBrand";
import TermsOfService from "../pages/termsOfService";
import Privacy from "../pages/privacy";
import TestAnalysis from "../pages/testAnalysisPage";

const IndexRouter = () => {
  // localStorage.clear();
  // return;
  // console.log("root");
  // console.log(localStorage);
  return (
    <Router basename="/backoffice">
      <ScrollToTop>
        <Switch>
          <GuestRoute path="/login" children={<Login />} />
          <GuestRoute
            path="/passwordRecovery"
            children={<PasswordRecovery />}
          />
          <PrivateRoute path="/articles" children={<Articles />} />
          <PrivateRoute path="/coordinate" children={<CoordinateList />} />
          <PrivateRoute path="/live" children={<LiveList />} />
          <PrivateRoute path="/liveDelivery" children={<LiveDelivery />} />
          <PrivateRoute path="/movie" children={<MovieList />} />
          <PrivateRoute path="/staff" children={<StaffList />} />
          <PrivateRoute path="/staffResult" children={<StaffResult />} />
          <PrivateRoute path="/category" children={<Category />} />
          <PrivateRoute path="/tag" children={<Tag />} />
          <PrivateRoute path="/reward" children={<Reward />} />
          <PrivateRoute path="/shops" children={<Shops />} />
          <PrivateRoute path="/brand" children={<Brand />} />
          <PrivateRoute path="/product" children={<Product />} />
          <PrivateRoute path="/notification" children={<Notification />} />
          <PrivateRoute path="/reserves" children={<Reserves />} />
          <PrivateRoute path="/payments" children={<Payments />} />
          <PrivateRoute path="/ranking" children={<Ranking />} />
          <PrivateRoute path="/shareUrl" children={<ShareUrl />} />
          <PrivateRoute path="/shareUrls" children={<ShareUrls />} />
          <PrivateRoute path="/analyzeCoordinate" children={<AnalyzeCoordinate />}/>
          <PrivateRoute path="/analyzeArticles" children={<AnalyzeArticles />} />
          <PrivateRoute path="/analyzeLive" children={<AnalyzeLive />}/>
          <PrivateRoute path="/analyzeMovie" children={<AnalyzeMovie />}/>
          <PrivateRoute path="/analyzeShop" children={<AnalyzeShop />} />
          <PrivateRoute path="/analyzeBrand" children={<AnalyzeBrand />} />
          <PrivateRoute path="/analyzeSnsList" children={<AnalyzeSnsList />} />
          <PrivateRoute path="/analyzeContents" children={<AnalyzeContents />}/>
          <PrivateRoute path="/testAnalysis" children={<TestAnalysis />}/>
          <PrivateRoute path="/termsOfService" children={<TermsOfService />} />
          <PrivateRoute path="/privacy" children={<Privacy />} />
          <PrivateRoute path="/" children={<Top />} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default IndexRouter;

import { Modal } from "antd";
import Button from "../../button";
import "./style.scss";

/* 削除確認モダール */

const DeleteModal = ({ modalVisible, onCancel, onOk, item, itemValue }) => {
  return (
    <Modal
      className={"nf-modal-delete"}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      width={350}
      onCancel={onCancel}
    >
      <div className="modal-content">
        <div className="delete-title">削除確認</div>
        <div className="delete-info">
          {item ?
            <>
              <div>
                {item}:{itemValue}
              </div>
              <div>を削除します</div>
              <div>よろしいですか？</div>
              <div className="delete-tip">※削除した内容は復元できません</div>
            </>
            :
            <>
              <div>選択した{itemValue}を削除します</div>
              <div>よろしいですか？</div>
            </>
          }
        </div>
        <div>
          <Button text="削除する" theme="red" onClick={onOk} />
          <Button text="戻る" onClick={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { serialize } from "../../richTextEditor/utils/serializer";
import {
  createTimeOptions,
  handlerFormikFieldChange,
} from "../../../utils/fnUtil";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Image, DatePicker } from "antd";
import { getImageDetail } from "../../richTextEditor/utils/getData";
import {
  actions,
  fetchStaffs,
  fetchTags,
  registArticle,
  registDraftArticle,
  saveBaseInfo,
  fetchMallBrand
} from "../../../slices/articlesSlice";
import { actions as productActions } from "../../../slices/productSlice";
import Button from "../../button";
import GeneralInput from "../../generalInput";
import SelectModal from "../selectModal";
import BaseUpload from "../../baseUpload";
import RichTextEditor from "../../richTextEditor";
import ProductSearchModal from "../productSearchModal";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Yup from "../../../utils/yupUtil";
import classNames from "classnames";
import moment from "moment";
import "./style.scss";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";

const ArticleSignUpModal = () => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const [selectMallBrand, setSelectMallBrand] = useState([]);

  const handleFileChange = url =>
    handlerFormikFieldChange(formik, "title_image", url);

  const {
    signUpModalVisible,
    currentArticleCode,
    brands,
    shops,
    tags,
    categories,
    staffs,
    staffsReady,
    mallBrandList
  } = useSelector(state => state.articles);

  const formik = useFormik({
    initialValues: {
      division: "update",
      article_code: "",
      origin_status_flg: 0,
      brand_code: (account.brand_code ?? [])[0],
      shop_code: account.shop_code,
      user_code: account.user_code,
      tag_code: [],
      mall_flg: 0,
      mall_brand_list:[],
      title: "",
      title_image: "",
      tag_category_code: null,
      date: moment(),
      hour: moment().format("HH"),
      minute: moment().format("mm"),
      text: [
        {
          type: "content",
          children: [{ text: "" }],
        },
      ],
      uneditable: true,
    },
    validationSchema: Yup.object({
      brand_code: Yup.string().nullable().selected(),
      shop_code: Yup.string().nullable().selected(),
      user_code: Yup.string()
        .nullable()
        .selected()
        .test("nickNameExist", "サイト表示名を登録してください", value =>
          staffs.some(s => s.user_code === value && s.user_nickname)
        ),
      title: Yup.string().required().max(30),
      title_image: Yup.string().required(),
      text: Yup.array().editorTextRequired(),
      tag_code: Yup.array().min(2).max(20),
      date: Yup.string()
        .nullable()
        .test(
          "date",
          "日時を選択してください",
          (value, testContext) =>
            value && testContext.parent.hour && testContext.parent.minute
        ),
      mall_brand_list: Yup.array().mallBrands(),
    }),
    onSubmit: values => {
      const {
        date,
        minute,
        hour,
        tag_category_code,
        user_code,
        tag_code,
        text,
        ...rest
      } = values;

      dispatch(
        registArticle({
          ...rest,
          accept_at: `${date.format("YYYY-MM-DD")} ${hour}:${minute}:00`,
          tag_code: tag_code.map(t => t.tag_code),
          user_code,
          status_flg: 3,
          text: values.text
            .map(t => serialize(t))
            .map((dom, i) => ({
              sort_num: `${i + 1}`,
              dom,
            })),
          ...getImageDetail(text),
        })
      );
    },
  });

  const onBack = () => {
    dispatch(actions.closeSignUpModal());
    formik.resetForm();
  };

  const afterClose = () => {
    dispatch(actions.clearCurrentArticleCode());
    formik.resetForm();
  };

  const {
    values: {
      tag_category_code,
      title_image,
      tag_code,
      brand_code,
      shop_code,
      user_code,
      text,
      date,
      uneditable,
      article_code,
      mall_flg,
      mall_brand_list
    },
    setFieldValue,
    validateField,
  } = formik;

  useEffect(() => {
    signUpModalVisible &&
      brand_code &&
      shop_code &&
      dispatch(
        fetchStaffs({
          brand_code,
          shop_code,
          count: 0,
        })
      );
  }, [brand_code, shop_code, signUpModalVisible, dispatch]);

  useEffect(() => {
    tag_category_code &&
      brand_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, brand_code, dispatch]);

  useEffect(() => {
    if (signUpModalVisible) {
      dispatch(actions.saveBrand(brand_code));
      dispatch(productActions.saveBrand(brand_code));
    }
  }, [signUpModalVisible, brand_code, dispatch]);

  useEffect(() => {
    if (signUpModalVisible) {
      setFieldValue("date", moment());
      setFieldValue("hour", moment().format("HH"));
      setFieldValue("minute", moment().format("mm"));
    }
  }, [signUpModalVisible, setFieldValue]);

  useEffect(() => {
    categories.length === 1 &&
      setFieldValue("tag_category_code", categories[0]?.tag_category_code);
  }, [categories, setFieldValue]);

  useEffect(() => {
    validateField("user_code");
  }, [validateField, staffs]);

  useEffect(() => {
    if (currentArticleCode) {
      setFieldValue("article_code", currentArticleCode);
      setFieldValue("uneditable", false);
      document
        .querySelector(".register-modal .ant-modal-body")
        .scrollTo(0, 300);
    }
  }, [currentArticleCode, setFieldValue]);

  useEffect(() => {
    setSelectMallBrand(mallBrandList.filter(m=> mall_brand_list.includes(m.brand_code)));
  },[mall_brand_list]);

  useEffect(() => {
    if(account.multi_post_flag === 1 && mallBrandList.length > 0){
      handlerFormikFieldChange(formik, "mall_flg", 1);
      uneditable && handlerFormikFieldChange(formik, "mall_brand_list", mallBrandList.map(m=>{return m.value;}));
      uneditable && setSelectMallBrand(mallBrandList);
    }else{
      handlerFormikFieldChange(formik, "mall_flg", 0);
      handlerFormikFieldChange(formik, "mall_brand_list", [])
      setSelectMallBrand([])
    }
  },[mallBrandList]);

  const brandOptions = brands.filter(b => b.status === "1");

  const shopOptions = shops.filter(
    s =>
      (s.brand ?? []).some(b => b.brand_code === brand_code) && s.status === "1"
  );

  const staffOptions = staffs.filter(s => s.status === 1);

  const onSaveBaseInfo = () => {
    dispatch(
      saveBaseInfo({
        division: "insert",
        brand_code,
        shop_code,
        user_code,
        status_flg: 0,
        title: "",
        title_image: "",
        text: [],
        tag_code: [],
        products: [],
        coordinates: [],
      })
    );
    dispatch(fetchMallBrand(
        {
          brand_code: brand_code,
          mall_flag: 1,
          offset: 0,
          status_flag: 1,
          sort: "brand_code_asc"
        }
    ));
  };

  const onRegistDraft = () => {
    formik.setFieldValue("date", null);
    formik.setFieldValue("hour", null);
    formik.setFieldValue("minute", null);
    const {
      date,
      hour,
      minute,
      tag_category_code,
      uneditable,
      title,
      ...rest
    } = formik.values;
    dispatch(
      registDraftArticle({
        ...rest,
        status_flg: 0,
        title,
        tag_code: tag_code.map(t => t.tag_code),
        text: text
          .map(t => serialize(t))
          .map((dom, i) => ({
            sort_num: `${i + 1}`,
            dom,
          })),
        ...getImageDetail(text),
      })
    );
  };

  const onTagOk = value => {
    handlerFormikFieldChange(
      formik,
      "tag_code",
      [
        ...tag_code,
        ...tags.filter(
          t =>
            value.includes(t.value) &&
            !tag_code.some(tag => t.value === tag.tag_code)
        ),
      ].filter(
        tag =>
          !tags.map(t => t.value).includes(tag.tag_code) ||
          value.includes(tag.tag_code)
      )
    );
    setSelectTagModalVisible(false);
  };

  return (
    <Modal
      visible={signUpModalVisible}
      closable={false}
      destroyOnClose={true}
      width={840}
      style={{ minWidth: 840 }}
      footer={null}
      afterClose={afterClose}
      onCancel={onBack}
      className={"register-modal"}
    >
      <h2 className="signup-title">
        <span className="text-bold"> ブログ新規登録</span>
        <span className="nf-text-required-style">　*は必須項目です</span>
      </h2>
      <div className="blog-signup">
        <form onSubmit={formik.handleSubmit}>
          <div
            className={classNames("blog-edit-area", {
              "disabled-area": !uneditable,
            })}
          >
            <div className="text-bold text-required">投稿者情報</div>
            <div className="select-container">
              <GeneralInput
                label="ブランド"
                options={
                  brandOptions.length > 0
                    ? brandOptions
                    : [
                        {
                          label: account.brand_name?.[0],
                          value: account.brand_code?.[0],
                        },
                      ]
                }
                placeholder="ブランドを選択して下さい"
                styleType="inline-grey"
                mode={null}
                formik={formik}
                name={"brand_code"}
                extraOnChange={() => {
                  handlerFormikFieldChange(formik, "shop_code", null);
                  handlerFormikFieldChange(formik, "user_code", null);
                }}
              />
              <GeneralInput
                disabled={!brand_code}
                options={
                  shopOptions.length > 0
                    ? shopOptions
                    : [{ label: account.shop_name, value: account.shop_code }]
                }
                label="店舗"
                placeholder="店舗を選択して下さい"
                styleType="inline-grey"
                mode={null}
                name={"shop_code"}
                formik={formik}
                extraOnChange={() =>
                  handlerFormikFieldChange(formik, "user_code", null)
                }
              />
              <GeneralInput
                label="社員"
                options={
                  staffOptions.length > 0 || staffsReady
                    ? staffOptions
                    : [{ label: account.user_name, value: account.user_code }]
                }
                disabled={!brand_code || !shop_code}
                placeholder="社員を選択して下さい"
                styleType="inline-grey"
                mode={null}
                name={"user_code"}
                formik={formik}
              />
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="保存"
                disabled={!brand_code || !shop_code || formik.errors.user_code}
                onClick={onSaveBaseInfo}
              />
            </div>
          </div>
          <div
            className={classNames("blog-edit-area", {
              "disabled-area": uneditable,
            })}
          >
            <div className="text-bold text-required">タイトル</div>
            <GeneralInput
              placeholder="タイトルを入力してください"
              disabled={uneditable}
              styleType="block-grey-normal"
              name="title"
              formik={formik}
            />
            <div>
              <div className="text-bold text-required title-image">
                タイトル画像
              </div>
              <span className="validation-error error-title-image">
                {formik.touched.title_image && formik.errors.title_image}
              </span>
            </div>
            <div className="text-image-tip">
              ※画像もしくは商品を選択してください
            </div>
            <div className="image-select">
              {title_image && !uneditable && (
                <Icon
                  component={Icons.CloseIcon}
                  className="close-icon"
                  onClick={e => {
                    handlerFormikFieldChange(formik, "title_image", "");
                    e.stopPropagation();
                  }}
                />
              )}
              {title_image ? (
                <Image
                  src={title_image}
                  width={150}
                  height={200}
                  preview={false}
                />
              ) : (
                <>
                  <BaseUpload
                    onChange={handleFileChange}
                    onClick={() => formik.setFieldTouched("title_image", true)}
                  >
                    <Button
                      text="画像を追加"
                      theme="white"
                      disabled={uneditable}
                    />
                  </BaseUpload>
                  <Button
                    text="商品を追加"
                    theme="white"
                    disabled={uneditable}
                    onClick={() => {
                      setProductSearchModalVisible(true);
                      formik.setFieldTouched("title_image", true);
                    }}
                  />
                </>
              )}
            </div>
            <RichTextEditor
              articleCode={article_code}
              editorState={text}
              disabled={uneditable}
              error={formik.touched.text && formik.errors.text}
              onChange={value => formik.setFieldValue("text", value)}
              setTouched={() =>
                !formik.touched.text && formik.setFieldTouched("text", true)
              }
              userCode={user_code}
            />
            <div className="tag-area">
              <div className="tag-title">
                <span className="text-bold text-required">タグ情報</span>　
                <span className="text-tip">
                  2個以上登録必須
                  <span className="text-subtip"> 20個まで登録可</span>
                </span>
              </div>
              <GeneralInput
                label="カテゴリ"
                uneditable={categories.length === 1}
                placeholder="選択してください"
                styleType="block-grey-normal"
                requiredItem
                labelTextAlign="left"
                options={categories}
                disabled={uneditable}
                mode={null}
                name={"tag_category_code"}
                formik={formik}
              />
              <div>
                <span className="text-required text-tag">タグ</span>
                <Button
                  text="タグを選択"
                  disabled={!tag_category_code || uneditable}
                  theme="white"
                  onClick={() => setSelectTagModalVisible(true)}
                />
                <span className="validation-error">
                  {formik.touched.tag_code && formik.errors.tag_code}
                </span>
              </div>
              <div className="tags-container">
                {tag_code.map((t, i) => (
                  <div className="tag" key={i}>
                    {t.tag_name}
                    {!uneditable && (
                      <Icon
                        className="close-icon"
                        component={Icons.CloseIcon}
                        onClick={() => {
                          handlerFormikFieldChange(
                            formik,
                            "tag_code",
                            tag_code.filter(i => i.tag_code !== t.tag_code)
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="mall-brand-area">
              <div className="mall-brand-title">
                <span className="text-bold text-required">同時投稿</span>
              </div>
              <div className="nf-input-wrapper">
                {(mallBrandList.length > 0 && account.multi_post_flag === 1) || uneditable ? (
                    <>
                      <label className="nf-input-label text-align-left label-width-wide label-required">同時投稿</label>
                      <GeneralCheckbox
                          label="同時投稿する"
                          checked={mall_flg === 1}
                          className={"nf-checkbox-wide"}
                          onChange={(value) => handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 1 : 0)}
                      />
                      <GeneralCheckbox
                          label="同時投稿しない"
                          checked={mall_flg === 0}
                          onChange={(value) => {
                            handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 0 : 1)
                            handlerFormikFieldChange(formik, "mall_brand_list", [])
                          }}
                      />
                    </>
                ) : (
                    <>
                      <div className="nf-input-wrapper nf-wrapper-uneditable">
                        <span className="input-value">{mall_flg !== 1 && ("同時投稿しない")}</span>
                      </div>
                    </>
                )}
              </div>
              {mall_flg === 1 && (<>
                <GeneralInput
                    label="同時投稿先"
                    placeholder="選択してください"
                    styleType="block-grey-normal"
                    labelTextAlign="left"
                    requiredItem={mall_flg === 1}
                    options={mallBrandList}
                    disabled={uneditable || mall_flg === 0}
                    name={"mall_brand_list"}
                    formik={formik}
                />
              </>)}
              <div className="mall-brand-container">
                {selectMallBrand.map((b, i) => (
                    <div className="mall-brand" key={i}>
                      {b.brand_name}
                      {!uneditable && (
                          <Icon
                              className="close-icon"
                              component={Icons.CloseIcon}
                              onClick={() => {
                                handlerFormikFieldChange(
                                    formik,
                                    "mall_brand_list",
                                    mall_brand_list.filter(i => i !== b.brand_code)
                                );
                              }}
                          />
                      )}
                    </div>
                ))}
              </div>
            </div>
            <div className="approval-area">
              <span className="text-bold text-required">公開設定</span>　
              <div className="public-date-select">
                <span className="text-required">公開日時</span>
                <DatePicker
                  format={"YYYY.MM.DD"}
                  value={date}
                  disabled={uneditable}
                  suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  onChange={value =>
                    handlerFormikFieldChange(formik, "date", value)
                  }
                />
                <GeneralInput
                  mode={null}
                  styleType="block-grey-normal"
                  options={createTimeOptions(24)}
                  name={"hour"}
                  formik={formik}
                  disabled={uneditable}
                  placeholder="時"
                  textAfter="時"
                />
                <GeneralInput
                  mode={null}
                  styleType="block-grey-normal"
                  options={createTimeOptions(60)}
                  name={"minute"}
                  formik={formik}
                  disabled={uneditable}
                  placeholder="分"
                  textAfter="分"
                />
                <span className="validation-error">
                  {formik.touched.date &&
                    formik.touched.hour &&
                    formik.touched.minute &&
                    formik.errors.date}
                </span>
              </div>
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="公開設定"
                type="submit"
                theme={"black"}
                disabled={!formik.isValid}
              />
              <Button
                disabled={uneditable}
                theme="link"
                text="下書きとして保存する"
                onClick={onRegistDraft}
              />
            </div>
          </div>
          <SelectModal
            checkBoxName="sign-up-tags"
            modalVisible={selectTagModalVisible}
            onCancel={() => setSelectTagModalVisible(false)}
            onOk={onTagOk}
            title={"タグを選択してください"}
            value={tag_code.map(t => t.tag_code)}
            options={tags}
          />
          <ProductSearchModal
            visible={productSearchModalVisible}
            onCancel={() => setProductSearchModalVisible(false)}
            onOk={selectedProduct => {
              handlerFormikFieldChange(
                formik,
                "title_image",
                selectedProduct.image_url
              );
              setProductSearchModalVisible(false);
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default ArticleSignUpModal;

export default {
  //リンク先 アクセス元はここで設定してね
  BASE_URL: process.env.REACT_APP_API_BASE_URL,

  //外部郵便番号検索API
  ZIP_SEARCH_URL: "https://zipcloud.ibsnet.co.jp/api/search",

  CONTEXT_URL: {
    COORDINATE: "/coordinate",
    STAFF: "/staff",
    SHOP: "/shops",
    BRAND: "/brands",
    ARTICLES: "/articles",
    CATEGORY: "/tags/category",
    TAG: "/tags",
    RANKING: "/ranking",
    PRODUCT: "/product",
    ANALYZE: "/analyze",
    REWARD: "/reward",
    NOTICE: "/notice",
  },
};

import "./style.scss";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import Button from "../button";
import React, { useState, useRef, useEffect } from "react";
import GenernalInput from "../generalInput";

/*  */

const DualListBox = ({
  selectBoxId,
  brandList,
  shopList,
  selectShops,
  setSelectShops,
  availableShops,
  setAvailableShops,
}) => {
  const availableShopsRef = useRef();
  const selectShopsRef = useRef();
  const changeSelectBrandEvent = (value) => {
    setAvailableShops(
      shopList.filter((shop) => {
        return (
          shop.brand.find((b) => value.includes(b.brand_code)) &&
          selectShops.find((selectShop) => {
            return shop.shop_code === selectShop.shop_code;
          }) === undefined
        );
      })
    );
  };

  const move = (options, fromState, setFromData, setToData) => {
    let fromData = [];
    let toData = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        fromData.push({
          shop_code: options[i].value,
          shop_name: options[i].text,
        });
      } else {
        toData.push({
          shop_code: options[i].value,
          shop_name: options[i].text,
        });
      }
    }
    setFromData(fromState.concat(fromData));
    setToData(toData);
  };
  return (
    <>
      <div className="nf-dualListBox">
        <div>
          <select
            style={{ height: 175 }}
            multiple
            size="8"
            ref={selectShopsRef}
            id={selectBoxId}
          >
            {selectShops.map((shop, i) => {
              return (
                <option key={"selectShops" + i} value={shop.shop_code}>
                  {shop.shop_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="dlb-actions">
          <Button
            text={<LeftOutlined />}
            style={{ width: 100, marginTop: 40, marginBottom: 0 }}
            theme="white"
            onClick={() => {
              move(
                availableShopsRef.current.options,
                selectShops,
                setSelectShops,
                setAvailableShops
              );
            }}
          />
          <Button
            text={<RightOutlined />}
            style={{ width: 100, marginTop: 10 }}
            theme="white"
            onClick={() => {
              move(
                selectShopsRef.current.options,
                availableShops,
                setAvailableShops,
                setSelectShops
              );
            }}
          />
        </div>
        <div>
          <div>
            <GenernalInput
              options={brandList.map(brand => ({ value: brand.brand_code, label: brand.brand_name }))}
              customStyle={{ height: 30, width: "210px", border: "none", margin: 0 }}
              customOnChange={changeSelectBrandEvent}
              className="brand_select"
              placeholder="ブランドを選択してください"
            />
          </div>
          <div>
            <select
              style={{ height: 140, marginTop: 5 }}
              multiple
              size="7"
              id="dlb-available"
              ref={availableShopsRef}
            >
              {availableShops.map((shop, i) => {
                return (
                  <option key={"availableShops" + i} value={shop.shop_code}>
                    {shop.shop_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default DualListBox;

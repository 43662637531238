import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { tokenLogin, refleshTokenLogin } from "../slices/accountSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountAuth } from "../utils/fnUtil";

const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const loginAccount = useSelector((state) => state.account);
  const request = {
    request_from: 2,
    corporation_code: loginAccount?.corporation_code,
    token: loginAccount?.token,
  };

  useEffect(() => {
    if (loginAccount.code === null) return;
    dispatch(tokenLogin(request));
  }, [props]);
  useEffect(() => {
    if (loginAccount.code === null || !loginAccount.reflesh) return;
    dispatch(refleshTokenLogin(request));
  }, [loginAccount.reflesh]);

  const isUserAuth = (_loginAccount, tagPage) => {
    let account = updateAccountAuth(_loginAccount);
    const pages = {
      articles: "blog_flag",
      coordinate: "coordinate_flag",
      live: "live_list_flag",
      liveDelivery: "live_flag",
      movie: "movie_list_flag",
      staff: "user_mst_flag",
      staffResult: "", //権限フラグなし
      category: "tag_category_flag",
      tag: "tag_list_flag",
      reward: "reward_rate_setting_flag",
      shops: "shop_mst_flag",
      brand: "brand_mst_flag",
      product: "product_master_flag",
      notification: "notified_flag",
      reserves: "notice_list_flag",
      payments: "payment_reward_confirmation_flag",
      ranking: "ranking_flag",
      shareUrl: "sns_analysis_create_flag",
      shareUrls: "sns_analysis_list_flag",
      analyzeCoordinate: "coordinate_sales_flag",
      analyzeArticles: "blog_sales_flag",
      analyzeLive: "live_sales_flag",
      analyzeMovie: "movie_sales_flag",
      analyzeShop: "shop_analysis_flag",
      analyzeBrand: "brand_analysis_flag",
      analyzeSnsList: "user_sns_analysis_flag",
      analyzeContents: "user_analysis_flag",
      testAnalysis: "", //権限フラグなし(テスト用ページ)
      Top: "", //権限フラグなし
    };
    return (
      tagPage === "" ||
      tagPage === "testAnalysis" ||
      tagPage === "staffResult" ||
      tagPage === "termsOfService" ||
      tagPage === "privacy" ||
      account[pages[tagPage]] === 1
    );
  };

  //main処理
  if (loginAccount.code === "A00000") {
    return isUserAuth(loginAccount, props.path.slice(1)) ? (
      <Route {...props} />
    ) : (
      <Redirect to="/" />
    );
  }
  if (
    loginAccount.code === null ||
    loginAccount.code === "A20000" ||
    loginAccount.code === "A20001" ||
    loginAccount.code === "A20002"
  ) {
    return <Redirect to="/login" />;
  }
  if (loginAccount.code === "reflesh") {
    return <Redirect to="/" />;
  }
  return <></>;
};

export default PrivateRoute;

import { useState, useCallback } from "react";
import { Input } from "antd";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import classNames from "classnames";
import "./style.scss";

const MemoInput = ({
  styleType,
  formik,
  name,
  disabled = false,
  inputWidth = "middle",
  selectSize = "middle",
  placeholder,
  textAfter,
  uneditable,
  className,
  error,
  scrollQuery,
  ...restProps
}) => {

  const [value, setValue] = useState((formik?.values && formik?.values?.[name]) ?? "");
  const [touch, setTouch] = useState(false);

  const wrapperClassName = classNames("nf-input-wrapper", className, {
    [`nf-input-wrapper-${selectSize}`]: selectSize,
    "nf-wrapper-uneditable": uneditable,
  });
  const inputClassName = classNames("nf-input", {
    [`input-${styleType}`]: styleType,
    [`input-width-${inputWidth}`]: inputWidth,
  });

  const addScrollEvent = () => {
    scrollQuery && document?.querySelector(scrollQuery)?.addEventListener('scroll', isScrollToggle, { passive: true });
  };

  const removeScrollEvent = () => {
    scrollQuery && document?.querySelector(scrollQuery)?.removeEventListener('scroll', isScrollToggle, { passive: true });
  };

  const isScrollToggle = useCallback(() => {
    handlerFormikFieldChange(formik, name, value || "");
    removeScrollEvent();
  }, []);

  return (
    <div className={wrapperClassName}>
      <>
        <Input
          className={inputClassName}
          size={selectSize}
          autoComplete="off"
          placeholder={placeholder}
          disabled={disabled}
          name={name}
          value={value}
          onChange={(e) => {
            setTouch(true);
            setValue(e.target.value);
            addScrollEvent();
          }}
          onBlur={e => {
            setTouch(true);
            handlerFormikFieldChange(formik, name, e?.target?.value || "");
            removeScrollEvent();
          }}
          {...restProps}
        />
      </>
      {error && touch && (
        <span className="nf-input-error">{error(value)}</span>
      )}
    </div>
  );
};

export default MemoInput;

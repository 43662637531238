import { useEffect, useState } from "react";
import { Formik, useFormik, FieldArray } from "formik";
import {
  createTimeOptions,
  handlerFormikFieldChange,
  spliceArray,
  swapArray,
} from "../../../utils/fnUtil";
import { useDispatch, useSelector } from "react-redux";
import { Modal, DatePicker, Image } from "antd";
import {
  actions,
  fetchTags,
  fetchTagCategory,
  registMovie,
} from "../../../slices/movieSlice";
import { actions as productActions } from "../../../slices/productSlice";
import { actions as messageActions } from "../../../slices/messageSlice";
import Button from "../../button";
import GeneralInput from "../../generalInput";
import MemoInput from "../../generalInput/memoInput";
import MemoTextArea from "../../generalInput/memoTextArea";
import BaseUpload from "../../baseUpload";
import BaseMovieUpload from "../../baseUpload/movie";
import SelectModal from "../selectModal";
import classNames from "classnames";
import ProductSearchModal from "../productSearchModal";
import DraggableContainer from "./DraggableContainer";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Yup from "../../../utils/yupUtil";
import moment from "moment";
import "./style.scss";

const MovieSignUpModal = () => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);

  const [activeIndex, setActiveIndex] = useState(0);

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const {
    signUpModalVisible,
    brands,
    shops,
    tags,
    categories,
    staffs,
  } = useSelector(state => state.movie);

  const formik = useFormik({
    initialValues: {
      origin_status_flg: 0,
      streamer_info: [
        {
          brand_code: (account.brand_code ?? [])[0],
          shop_code: account.shop_code,
          user_code: account.user_code,
          distribution_rate: '100',
          display_order: '1',
        },
      ],
      list_img_url: null,
      detail_img_url: null,
      live_file_name: "",
      movie_data: "",
      products: Array(30).fill(null),
      tag_code: [],
      description: "",
      tag_category_code: null,
      opening_date: moment(),
      opening_hour: moment().format("HH"),
      opening_minute: moment().format("mm"),
      uneditable: true,
      preview: null,
    },
    validationSchema: Yup.object({
      streamer_info: Yup.array()
        .of(
          Yup.object().shape({
            brand_code: Yup.string().nullable().selected(),
            shop_code: Yup.string().nullable().selected(),
            user_code: Yup.string()
              .nullable()
              .selected(),
            distribution_rate: Yup.string().nullable().selected(),
            display_order: Yup.string().nullable().selected(),
          })
        ),
      title: Yup.string().required().max(30),
      list_img_url: Yup.string().nullable().required(),
      detail_img_url: Yup.string().nullable().required(),
      opening_date: Yup.string()
        .nullable()
        .test(
          "opening_date",
          "日時を選択してください",
          (value, testContext) =>
            value && testContext.parent.opening_hour && testContext.parent.opening_minute
        ),
      movie_data: Yup.string().nullable().required(),
    }),
    onSubmit: values => {
      const {
        opening_date,
        opening_hour,
        opening_minute,
        tag_code,
        products,
        streamer_info,
        movie_data,
        ...rest
      } = values;

      const param_streamer_info = [];
      for (let i = 0; i < streamer_info.length; i++) {
        const data = streamer_info[i];
        const brand = brands.filter(e => e.brand_code === data.brand_code)[0];
        const shop = shops.filter(e => e.shop_code === data.shop_code)[0];
        const staff = staffs.filter(e => e.user_code === data.user_code)[0];
        param_streamer_info.push({
          brand_code: data.brand_code,
          brand_name: brand.brand_name,
          shop_code: data.shop_code,
          shop_name: shop.shop_name,
          shop_name_kana: shop.shop_namekana,
          user_code: data.user_code,
          user_name: staff.user_name,
          user_name_kana: staff.user_name_kana,
          name_nickname: staff.user_nickname,
          height: staff.height,
          distribution_rate: data.distribution_rate,
          display_order: data.display_order,
          image_url: staff?.img,
        });
      }

      const params = {
        ...rest,
        division: "insert",
        streamer_info: param_streamer_info,
        live_flag: 2,
        status_flag: "20",
        tags: tag_code.map(t => t.tag_code),
        products: products.filter(p => p),
        opening_time: `${opening_date.format("YYYY-MM-DD")} ${opening_hour}:${opening_minute}:00`,
        token: account.token,
        request_from: "2",
        operator_code: account.user_code,
      };

      const formData = new FormData();
      formData.append("upload_file", movie_data);
      formData.append("jsonValue", new Blob([JSON.stringify(params)], { type: "application/json" }));

      dispatch(registMovie(formData));
    },
  });

  const onBack = () => {
    dispatch(actions.closeSignUpModal());
    formik.resetForm();
  };

  const {
    values: {
      tag_category_code,
      tag_code,
      opening_date,
      list_img_url,
      detail_img_url,
      live_file_name,
      products,
      uneditable,
      streamer_info,
      preview,
    },
    setFieldValue,
  } = formik;

  const handleFileChange = (url, name) => handlerFormikFieldChange(formik, name, url);

  useEffect(() => {
    tag_category_code &&
      streamer_info?.[0]?.brand_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code: streamer_info?.[0]?.brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, streamer_info, dispatch]);

  useEffect(() => {
    dispatch(productActions.saveBrand(streamer_info?.[0]?.brand_code));
  }, [streamer_info?.[0]?.brand_code, dispatch]);

  useEffect(() => {
    if (signUpModalVisible) {
      setFieldValue("opening_date", moment());
      setFieldValue("opening_hour", moment().format("HH"));
      setFieldValue("opening_minute", moment().format("mm"));
    }
  }, [signUpModalVisible, setFieldValue]);

  useEffect(() => {
    categories.length === 1 &&
      setFieldValue("tag_category_code", categories[0]?.tag_category_code);
  }, [categories, setFieldValue]);

  const numberSelect = (start, end) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push({ label: `${i}`, value: `${i}` });
    }
    return list;
  };

  const brandOptions = brands.filter(b => b.status === "1");

  const shopOptions = i => shops.filter(s => (s.brand ?? []).some(b => b.brand_code === streamer_info?.[i]?.brand_code) && s.status === "1");

  const staffOptions = i => staffs.filter(s => s.status === 1).filter(s => s?.shop_code === streamer_info?.[i]?.shop_code);

  const onSaveBaseInfo = () => {
    // 出演者の重複チェック
    for (const streamer of streamer_info) {
      if (streamer_info.filter(e => e.shop_code === streamer.shop_code && e.user_code === streamer.user_code).length >= 2) {
        dispatch(
          messageActions.setMessage({
            btnText: "閉じる",
            messages: ["出演者が重複しています。"],
          })
        );
        return;
      }
    }

    // 分配率の合計が0 or 100以外の場合、エラー
    const distributionRateSum = streamer_info.reduce((s, e) => s + Number(e.distribution_rate), 0);
    if (distributionRateSum !== 0 && distributionRateSum !== 100) {
      dispatch(
        messageActions.setMessage({
          btnText: "閉じる",
          messages: ["売上分配率は合計で0%または100%になるように設定してください"],
        })
      );
      return;
    }

    dispatch(
      fetchTagCategory({
        brand_code: streamer_info?.[0]?.brand_code,
        count: 0,
        sort: "tag_category_code_asc",
      })
    );

    dispatch(productActions.saveBrand(streamer_info?.[0]?.brand_code));
    setFieldValue("uneditable", false);
    document.querySelector(".register-modal .ant-modal-body").scrollTo(0, 255 + (130 * streamer_info.length));
  };

  const onTagOk = value => {
    handlerFormikFieldChange(
      formik,
      "tag_code",
      [
        ...tag_code,
        ...tags.filter(
          t =>
            value.includes(t.value) &&
            !tag_code.some(tag => t.value === tag.tag_code)
        ),
      ].filter(
        tag =>
          !tags.map(t => t.value).includes(tag.tag_code) ||
          value.includes(tag.tag_code)
      )
    );
    setSelectTagModalVisible(false);
  };

  const afterClose = () => {
    formik.resetForm();
  };

  const addStreamerInfo = () => {
    // 新規追加する表示優先順は最大値 + 1
    const display_order = Math.max(...(streamer_info.filter(e => e).map(e => Number(e.display_order)))) + 1;

    const newData = JSON.parse(JSON.stringify(streamer_info));
    newData.push({
      brand_code: null,
      shop_code: null,
      user_code: null,
      distribution_rate: '100',
      display_order: display_order > 100 ? null : `${display_order}`,
    });
    handlerFormikFieldChange(formik, 'streamer_info', newData);
  };

  const removeStreamerInfo = (index) => {
    const newData = JSON.parse(JSON.stringify(streamer_info));
    newData.splice(index, 1);
    handlerFormikFieldChange(formik, 'streamer_info', newData);
  };

  const isStreamerInfoError = () => {
    if (!formik?.errors?.streamer_info) return false;
    for (let i = 0; i < formik?.errors?.streamer_info?.length; i++) {
      if (!formik?.errors?.streamer_info?.[i]) continue;
      const { brand_code, shop_code, user_code, distribution_rate, display_order } = formik?.errors?.streamer_info?.[i];
      if (brand_code || shop_code || user_code || distribution_rate || display_order) {
        return true;
      }
    }
    return false;
  };

  const delivererArea = (data, index) => {
    const {
      brand_code,
      shop_code,
    } = data;
    return (
      <div>
        <div className="deliverer-title-area">
          <div className="text-bold normal-label">
            出演者{index + 1}{index === 0 ? "（主催者）" : ""}
          </div>
          {index !== 0 &&
            <Icon
              component={Icons.CloseIcon}
              className="deliverer-close-icon"
              onClick={e => {
                removeStreamerInfo(index);
              }}
            />
          }
        </div>
        <div className="select-container">
          <GeneralInput
            disabled={!uneditable}
            label="ブランド"
            options={
              brandOptions.length > 0
                ? brandOptions
                : [
                    {
                      label: account.brand_name?.[0],
                      value: account.brand_code?.[0],
                    },
                  ]
            }
            placeholder="ブランドを選択して下さい"
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'brand_code'}
            formik={formik}
            extraOnChange={(key, value) => {
              if (index == 0) {
                handlerFormikFieldChange(formik, `streamer_info[${index}].shop_code`, null);
                handlerFormikFieldChange(formik, `streamer_info[${index}].user_code`, null);
              } else {
                setFieldValue(`streamer_info[${index}].shop_code`, null);
                setFieldValue(`streamer_info[${index}].user_code`, null);
              }
            }}
          />
          <GeneralInput
            disabled={!brand_code || !uneditable}
            options={
              shopOptions(index)?.length > 0
                ? shopOptions(index)
                : [{ label: account.shop_name, value: account.shop_code }]
            }
            label="店舗"
            placeholder="店舗を選択して下さい"
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'shop_code'}
            formik={formik}
            extraOnChange={() => {
              if (index == 0) {
                handlerFormikFieldChange(formik, `streamer_info[${index}].user_code`, null);
              } else {
                setFieldValue(`streamer_info[${index}].user_code`, null);
              }
            }}
          />
          <GeneralInput
            label="社員"
            options={
              staffOptions(index)?.length > 0
                ? staffOptions(index)
                : [{ label: account.user_name, value: account.user_code }]
            }
            disabled={!brand_code || !shop_code || !uneditable}
            placeholder="社員を選択して下さい"
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'user_code'}
            formik={formik}
          />
        </div>
        <div className="select-container-bottom">
          <GeneralInput
            disabled={!uneditable}
            label="売上分配率"
            options={numberSelect(0, 100)}
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'distribution_rate'}
            extraOnChange={(_, v) => {
            }}
            formik={formik}
            textAfter="%"
          />
          <GeneralInput
            disabled={!uneditable}
            label="表示優先順"
            options={numberSelect(1, 100)}
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'display_order'}
            extraOnChange={(_, v) => {
            }}
            formik={formik}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      visible={signUpModalVisible}
      closable={false}
      width={1000}
      style={{ minWidth: 1000 }}
      footer={null}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
      className={"register-modal"}
    >
      <h2 className="signup-title">
        <span className="text-bold">動画新規登録</span>
        <span className="nf-text-required-style">　*は必須項目です</span>
      </h2>
      <div className="live-signup">
        <form onSubmit={formik.handleSubmit}>
          <div
            className={classNames("live-edit-area", {
              "disabled-area": !uneditable,
            })}
          >
            <div className="text-bold text-required">出演者情報</div>
            <Formik
              render={formikProps => (
                <FieldArray
                  name="streamer_info"
                  component={() => (<>
                    {streamer_info.map(delivererArea)}
                    </>)}
                />
              )}
            />
            <div style={{ textAlign: 'right' }}>
              <Button
                text={<div>
                  <Icon
                    component={Icons.IconPlus}
                    style={{ position: "absolute" }}
                  ></Icon>
                  <span>　 出演者を追加</span></div>}
                className="addButton"
                theme="black"
                onClick={() => {
                  addStreamerInfo();
                }}
              />
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="保存"
                disabled={isStreamerInfoError()}
                onClick={onSaveBaseInfo}
              />
            </div>
          </div>
          <div
            className={classNames("live-edit-area", {
              "disabled-area": uneditable,
            })}
          >
            <div className="title-area">
              <div className="text-bold text-required">タイトル</div>
              <MemoInput
                placeholder="タイトルを入力してください"
                disabled={uneditable}
                styleType="block-grey-normal"
                name="title"
                formik={formik}
                scrollQuery=".ant-modal-body"
                error={v => !v ? "必須項目です" : v.length > 30 ? "30文字以内で入力してください" : ""}
              />
            </div>

            <div className="description-area">
              <span className="text-bold">説明</span>
              <MemoTextArea
                placeholder="説明を入力してください"
                disabled={uneditable}
                name="description"
                formik={formik}
                scrollQuery=".ant-modal-body"
              />
            </div>

            <div className="image-area">
              <span className="text-bold text-required">サムネイル画像</span>
              <span className="text-tip">
                ※画像を選択してください
              </span>
              <div className="image-select-area">
                <div>
                  <span className="validation-error" style={{ position: "absolute", left: "130px" }}>
                    {formik.touched.list_img_url && formik.errors.list_img_url}
                  </span>
                  <div className="image-select">
                    {list_img_url && !uneditable && (
                      <Icon
                        component={Icons.CloseIcon}
                        className="close-icon"
                        onClick={e => {
                          handlerFormikFieldChange(formik, "list_img_url", "");
                          e.stopPropagation();
                        }}
                      />
                    )}
                    {list_img_url ? (
                      <Image src={list_img_url} width={150} height={200} preview={false} />
                    ) : (
                      <BaseUpload onChange={url => handleFileChange(url, "list_img_url")}>
                        <Button text="画像を追加" theme="white" disabled={uneditable} />
                      </BaseUpload>
                    )}
                  </div>
                  <div className="image-select-label">一覧</div>
                </div>
                <div>
                  <span className="validation-error" style={{ position: "absolute", left: "320px" }}>
                    {formik.touched.detail_img_url && formik.errors.detail_img_url}
                  </span>
                  <div className="image-select">
                    {detail_img_url && !uneditable && (
                      <Icon
                        component={Icons.CloseIcon}
                        className="close-icon"
                        onClick={e => {
                          handlerFormikFieldChange(formik, "detail_img_url", "");
                          e.stopPropagation();
                        }}
                      />
                    )}
                    {detail_img_url ? (
                      <Image src={detail_img_url} width={150} height={200} preview={false} />
                    ) : (
                      <BaseUpload onChange={url => handleFileChange(url, "detail_img_url")}>
                        <Button text="画像を追加" theme="white" disabled={uneditable} />
                      </BaseUpload>
                    )}
                  </div>
                  <div className="image-select-label">詳細</div>
                </div>
              </div>
            </div>

            <span className="text-bold text-required">公開設定</span>
            <div className="public-date-select">
              <span className="text-required">動画</span>
              <>
                <BaseMovieUpload
                  onChange={(file, name) => {
                    handleFileChange(null, "preview");
                    handleFileChange(file, "movie_data");
                    handlerFormikFieldChange(formik, "live_file_name", name);

                    const fileURL = URL.createObjectURL(file);
                    setTimeout(() => handlerFormikFieldChange(formik, "preview", fileURL));
                  }}
                  onClick={() => formik.setFieldTouched("movie_data", true)}
                >
                  <Button
                    text="動画アップロード"
                    theme="white"
                    style={{ marginLeft: "45px", marginTop: "8px", marginBottom: "8px" }}
                  />
                </BaseMovieUpload>
                {live_file_name}
                <span className="validation-error">
                  {formik.touched.movie_data && formik.errors.movie_data}
                </span>
              </>
            </div>
            {preview && <div className="public-date-select video-area">
              <video id="local-video" controls>
                <source src={preview} />
              </video>
            </div>}
            <div className="public-date-select">
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "315px" }}>
                {(formik.touched.opening_date ||
                  formik.touched.opening_hour ||
                  formik.touched.opening_minute) &&
                  formik.errors.opening_date}
              </span>
              <span className="text-required">公開日時</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={opening_date}
                disabled={uneditable}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value =>
                  handlerFormikFieldChange(formik, "opening_date", value)
                }
              />
              <GeneralInput
                mode={null}
                disabled={uneditable}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"opening_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                disabled={uneditable}
                options={createTimeOptions(60)}
                name={"opening_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
              />
            </div>

            <div>
              <span className="text-bold">商品情報</span>
              <span className="text-tip">
                <span className="text-subtip">
                  30枚まで登録可、ドラッグで入れ替え可。
                </span>
              </span>
              <span className="validation-error error-images">
                {formik.touched.products &&
                  formik.errors.products}
              </span>
            </div>
            <DraggableContainer
              items={products}
              isProduct={true}
              onClick={index => {
                setProductSearchModalVisible(true);
                setActiveIndex(index);
              }}
              uneditable={uneditable}
              onCancle={(e, index) => {
                handlerFormikFieldChange(
                  formik,
                  "products",
                  spliceArray(products, index, null)
                );
                e.stopPropagation();
              }}
              onExchange={(dragIndex, dropIndex) => {
                handlerFormikFieldChange(
                  formik,
                  "products",
                  swapArray(products, dragIndex, dropIndex)
                );
              }}
            />
            <div className="tag-area">
              <div className="tag-title">
                <span className="text-bold">タグ情報</span>
                <span className="text-tip">
                  <span className="text-subtip">
                    20個まで登録可。
                  </span>
                </span>
              </div>
              <GeneralInput
                label="カテゴリ"
                uneditable={categories.length === 1}
                placeholder="選択してください"
                styleType="block-grey-normal"
                labelTextAlign="left"
                options={categories}
                disabled={uneditable}
                mode={null}
                name={"tag_category_code"}
                formik={formik}
              />
              <div>
                <span className="text-tag">タグ</span>
                <Button
                  text="タグを選択"
                  disabled={!tag_category_code || uneditable}
                  theme="white"
                  onClick={() => setSelectTagModalVisible(true)}
                />
                <span className="validation-error">
                  {formik.touched.tag_code && formik.errors.tag_code}
                </span>
              </div>
              <div className="tags-container">
                {tag_code.map((t, i) => (
                  <div className="tag" key={i}>
                    {t.tag_name}
                    {!uneditable && (
                      <Icon
                        className="close-icon"
                        component={Icons.CloseIcon}
                        onClick={() => {
                          handlerFormikFieldChange(
                            formik,
                            "tag_code",
                            tag_code.filter(i => i.tag_code !== t.tag_code)
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="action-container">
              <Button text="戻る" theme="white" onClick={onBack} />
              <Button
                text="登録"
                type="submit"
                theme={"black"}
                disabled={uneditable || !formik.isValid}
              />
            </div>
          </div>
          <SelectModal
            checkBoxName="sign-up-tags"
            modalVisible={selectTagModalVisible}
            onCancel={() => setSelectTagModalVisible(false)}
            onOk={onTagOk}
            title={"タグを選択してください"}
            value={tag_code.map(t => t.tag_code)}
            options={tags}
            max={20}
          />
          <ProductSearchModal
            group={true}
            visible={productSearchModalVisible}
            onCancel={() => setProductSearchModalVisible(false)}
            onOk={selectedProduct => {
              handlerFormikFieldChange(
                formik,
                "products",
                spliceArray(products, activeIndex, selectedProduct)
              );
              setProductSearchModalVisible(false);
            }}
          />
        </form>
      </div>
    </Modal>
  );
};

export default MovieSignUpModal;

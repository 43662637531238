import BaseProgressBar from "./base";

const progress = [
  "EC公開前",
  "EC公開中",
];

const MovieProgressBar = ({ activeProgress }) => {
  return <BaseProgressBar progress={progress} activeProgress={activeProgress} />;
};

export default MovieProgressBar;

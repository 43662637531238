import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Table, DatePicker } from "antd";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import { actions, fetchShareUrls } from "../../slices/shareUrlsSlice";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import Icon from "@ant-design/icons";
import GenernalInput from "../../components/generalInput";
import Icons from "../../constants/Icons";
import { validDatePickChecker } from "../../utils/fnUtil";
import TableColumnWidth from "../../constants/tableColumnWidth";
import moment from "moment";
import "./style.scss";

const ShareUrls = () => {
  const dispatch = useDispatch();

  const { total, shareUrlList, searchParams } = useSelector(
    (state) => state.shareUrls
  );
  const [createDate, setCreateDate] = useState({ from: null, to: null });
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) =>
      dispatch(
        actions.saveSearchParam({
          ...values,
          start_date: createDate.from?.format("YYYY-MM-DD"),
          end_date: createDate.to?.format("YYYY-MM-DD"),
        })
      ),
  });

  useEffect(() => {
    dispatch(fetchShareUrls({}));
    return () => dispatch(actions.clear());
  }, []);

  useUpdateEffect(
    () => dispatch(fetchShareUrls(searchParams)),
    [searchParams, dispatch]
  );

  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      formik.values.sort === desc
        ? asc
        : formik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const getSortIcon = (sort) => {
    return sort ? (
      <>
        &nbsp;
        <Icon component={Icons.SortAscendingIcon} style={{ fontSize: 10 }} />
      </>
    ) : (
      <>
        &nbsp;
        <Icon component={Icons.SortDescendingIcon} style={{ fontSize: 10 }} />
      </>
    );
  };
  const columns = [
    {
      title: () => (
        <>
          <span>SNS種別 </span>
          <span onClick={createOnClikSort("sns_type")}>
            {getSortIcon(formik.values.sort === "sns_type_asc")}
          </span>
        </>
      ),
      dataIndex: "type",
      key: "type",
      align: "center",
      width: TableColumnWidth.contentType,
      render: (val) => (
        <div className="table-colum-title-cell">{val || "-"}</div>
      ),
    },
    {
      title: () => (
        <>
          <span>パラメーター </span>
          <span onClick={createOnClikSort("param")}>
            {getSortIcon(formik.values.sort === "param_asc")}
          </span>
        </>
      ),
      dataIndex: "param",
      key: "param",
      align: "center",
      width: TableColumnWidth.shareUrls.param,
      render: (val) => (
        <div style={{ width: "15vw" }} className="table-colum-title-cell">
          {val || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>URL </span>
          <span onClick={createOnClikSort("url")}>
            {getSortIcon(formik.values.sort === "url_asc")}
          </span>
        </>
      ),
      key: "url",
      dataIndex: "url",
      align: "center",
      width: TableColumnWidth.shareUrls.url,
      render: (url) => (
        <div style={{ display: "flex" }}>
          <div style={{ width: "30vw" }} className="table-colum-title-cell">
            {url || "-"}
          </div>
        </div>
      ),
    },
    {
      title: () => <></>,
      key: "url",
      dataIndex: "url",
      align: "center",
      width: TableColumnWidth.shareUrls.none,
      render: (url) => (
        <div style={{ display: "flex" }}>
          {url ? (
            <Button
              text="コピー"
              style={{ width: 100, margin: "auto", display: "block" }}
              theme="white"
              onClick={async () => await navigator.clipboard.writeText(url)}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>登録者 </span>
          <span onClick={createOnClikSort("user_name")}>
            {getSortIcon(formik.values.sort === "user_name_asc")}
          </span>
        </>
      ),
      key: "user_name",
      dataIndex: "user_name",
      align: "center",
      width: TableColumnWidth.userName,
      render: (name, record) => (
        <>
          {name}
          {record?.site_display_name ? (
            <>
              <br />
              {record?.site_display_name}
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: () => (
        <>
          <span>登録日 </span>
          <span onClick={createOnClikSort("create_date")}>
            {getSortIcon(formik.values.sort === "create_date_asc")}
          </span>
        </>
      ),
      key: "create_date",
      dataIndex: "create_date",
      align: "center",
      width: TableColumnWidth.date,
      render: (date) => <span>{moment(date).format("YYYY.MM.DD")}</span>,
    },
  ];

  return (
    <div className="content-body">
      <Header />
      <div className="shareUrls-container nf-page">
        <h2>SNS分析用URL一覧</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <GenernalInput
              label="種別"
              mode="multiple"
              placeholder="種別を選択してください"
              formik={formik}
              name={"type"}
              options={[
                { label: "Instagram", value: "1" },
                { label: "Twitter", value: "2" },
              ]}
            />
            <GenernalInput
              label="登録者"
              placeholder="社員名またはサイト表示名を入力してください"
              formik={formik}
              name={"user_name"}
            />
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center">登録日</label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={{
                  backgroundColor: "white",
                  fontSize: 16,
                  height: 32,
                  width: 120,
                }}
                value={createDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    createDate,
                    setCreateDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={{
                  backgroundColor: "white",
                  fontSize: 16,
                  height: 32,
                  width: 120,
                }}
                value={createDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    createDate,
                    setCreateDate
                  );
                }}
              />
            </div>
          </div>
          <Button text="検索" type="submit" />
        </form>
        <div className="table-header">
          <h3>検索結果一覧</h3>
        </div>
        <Table
          columns={columns}
          dataSource={shareUrlList}
          onChange={(pagination) => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{offsetHeader: 60}}
        />
      </div>
      <Footer />
    </div>
  );
};

export default ShareUrls;

import React, { useEffect } from "react";
import { useFormik } from "formik";
import { Table,  Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Icon from "@ant-design/icons";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import { actions, categoryInit, fetchCategory, updateCategory } from "../../slices/categorySlice";
import "./style.scss";
import GenernalInput from "../../components/generalInput";
import DeleteModal from "../../components/modal/deleteModal";
import Button from "../../components/button";
import Header from "../../components/header";
import Footer from "../../components/footer";
import GeneralCheckbox from "../../components/cusCheckbox/genernalCheckbox";
import CategoryEditModal from "../../components/modal/categoryEditModal";
import IconImage from "../../constants/Icons";
import TableColumnWidth from '../../constants/tableColumnWidth';


// sleep
export const sleep = time => new Promise(resolve => setTimeout(resolve, time));

const Category = () => {
  // 定義
  const { brands, total, pageSize, categoriesList, categoryDate, deleteModalVisible, searchParams } = useSelector(state => state.category);
  const dispatch = useDispatch();

  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };


 // 内部変数
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => dispatch(actions.saveSearchParam(values)),
  });
  
  useEffect(() => {
    dispatch(categoryInit())
    return () => dispatch(actions.clear());
  }, []);
  
  useUpdateEffect(
    () => dispatch(fetchCategory(searchParams)),
    [searchParams, dispatch]
  );


  const columns = [
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("brand_code")}
          >
            {formik.values.sort === "brand_code_asc"
              ? <Icon component={IconImage.SortAscendingIcon} style={{ fontSize: 10 }} /> 
              : <Icon component={IconImage.SortDescendingIcon} style={{ fontSize: 10 }} />
            }
          </span>
        </>
      ),
      dataIndex: "brand_name",
      key: "brand_name",
      align: "center",
      width:TableColumnWidth.brandName,
      render: brand_name => <div className="table-colum-title-cell brand-name">{brand_name || "-"}</div>,
    },
    {
      title: () => (
        <>
          <span>タグカテゴリCD </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("tag_category_code")}
          >
            {formik.values.sort === "tag_category_code_asc"
              ? <Icon component={IconImage.SortAscendingIcon} style={{ fontSize: 10 }} /> 
              : <Icon component={IconImage.SortDescendingIcon} style={{ fontSize: 10 }} />
            }
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "tag_category_code",
      key: "tag_category_code",
      align: "center",
      width:TableColumnWidth.cd,
    },
    {
      title: () => (
        <>
          <span>タグカテゴリ名 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("tag_category_name")}
          >
            {formik.values.sort === "tag_category_name_asc"
              ? <Icon component={IconImage.SortAscendingIcon} style={{ fontSize: 10 }} /> 
              : <Icon component={IconImage.SortDescendingIcon} style={{ fontSize: 10 }} />
            }
          </span>
        </>
      ),
      dataIndex: "tag_category_name",
      key: "tag_category_name",
      align: "center",
      width:TableColumnWidth.categoryName,
      render: tag_category_name => <div className="table-colum-title-cell category-title">{tag_category_name || "-"}</div>,


    },
    {
      title: () => (
        <>
          <span>タグ登録数 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("tag_qty")}
          >
            {formik.values.sort === "tag_qty_asc"
              ? <Icon component={IconImage.SortAscendingIcon} style={{ fontSize: 10 }} /> 
              : <Icon component={IconImage.SortDescendingIcon} style={{ fontSize: 10 }} />
            }
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "tag_count",
      key: "tag_count",
      align: "center",
      width:TableColumnWidth.qty,
    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("status")}
          >
            {formik.values.sort === "status_asc"
              ? <Icon component={IconImage.SortAscendingIcon} style={{ fontSize: 10 }} /> 
              : <Icon component={IconImage.SortDescendingIcon} style={{ fontSize: 10 }} />
            }
          </span>
        </>
      ),
      defaultSortOrder: "descend",
      dataIndex: "category_status",
      key: "category_status",
      align: "center",
      width:TableColumnWidth.status,
    },
    {
      title: "編集",
      align: "center",
      dataIndex: "delete_flg",
      key: "delete_flg",
      width:TableColumnWidth.icon,
      render: (delete_flg,record) => (
        <>
        {delete_flg === 0 ? (
            <Icon
              component={IconImage.EditIcon}
              style={{ fontSize: 25 }}
              onClick={() => dispatch(actions.updateEditModal(record)) }
            />
          ) : (
            <Icon
              component={IconImage.DisableEditIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
        )}
        </>
      ),
    },
    {
      title: "削除",
      align: "center",
      dataIndex: "delete_flg",
      key: "delete_flg",
      width:TableColumnWidth.icon,
      render: (delete_flg, record) => (
        <>
          {delete_flg === 0 ?(
            <Icon
              component={IconImage.RemoveIcon}
              style={{ fontSize: 25 }}
              onClick={() => dispatch(actions.openDeleteModal(record))}
            />
          ) : (
            <Icon
              component={IconImage.DisableRemoveIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}        
        </>
      ),
    },
  ];
  return (
    <>
      <div className="content-body">
        <Header />
        <div className="category-container nf-page">
          <h2>カテゴリ一覧</h2>
          <div className="total-data">
            登録カテゴリ数 <span>{total}</span>
          </div>
          <form className="search-area" onSubmit={formik.handleSubmit}>
            <div className="search-item">
              <GenernalInput
                label="ブランド"
                labelWidth="wider"
                mode="multiple"
                name="brand_code"
                placeholder={"ブランドを選択してください"}
                formik={formik}
                options={brands}
              />
              <GenernalInput
                label="タグカテゴリCD"
                labelWidth="wider"
                placeholder="タグカテゴリCDを入力してください"
                name="tag_category_code"
                formik={formik}
              />
              <GenernalInput
                label="タグカテゴリ名"
                labelWidth="wider"
                placeholder="タグカテゴリ名を入力してください"
                name="tag_category_name"
                formik={formik}
              />
            </div>
            <div className="search-item-deleted">
              <Button text="検索" type="submit" />
              <GeneralCheckbox
                label="削除済み"
                className="search-item-checkbox"
                checked={formik.values.deleted_flg === 1}
                onChange={() =>
                  handlerFormikFieldChange(
                    formik,
                    "deleted_flg",
                    formik.values.deleted_flg === 1 ? 0 : 1
                  )
                }
              />
            </div>
          </form>
          <div className="table-header">
            <h3>検索結果一覧</h3>
            <Button
              text="新規作成"
              width="wider"
              theme="white"
              onClick={() => dispatch(actions.newEditModal()) }
            />
          </div>
          <div className="table-body">
            <Table
              columns={columns}
              dataSource={categoriesList}
              onChange={pagination => {
                const offset =
                  pagination.current === 1
                    ? 0
                    : pagination.pageSize * (pagination.current - 1);
                dispatch(actions.saveOffset(offset));
                formik.setFieldValue("offset", offset);
              }}
              pagination={{
                total : pageSize,
                pageSize: 10,
                current: (searchParams?.offset || 0) / 10 + 1,
                position: ["bottomCenter"],
                showQuickJumper: false,
                size: "small",
              }}
              sticky={{offsetHeader: 60}}
            />
          </div>
        </div>
        <Footer />

        <CategoryEditModal />
        <DeleteModal
          modalVisible={deleteModalVisible}
          onCancel={() => dispatch(actions.closeDeleteModal())}
          item={"カテゴリ"}
          itemValue={categoryDate.tag_category_name}
          onOk={() =>
            dispatch(
              updateCategory({
                division: "delete",
                brand_code:categoryDate.brand_code,
                tag_category_code: categoryDate.tag_category_code,
              })
            )
          }
        />
      </div>


    </>
  );
};

export default Category;

import { Modal } from "antd";
import Button from "../../button";
import "./style.scss";

/* 配信終了確認モーダル */

const LiveCloseModal = ({ modalVisible, onCancel, onOk }) => {
  return (
    <Modal
      className={"nf-modal-live-close"}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      width={350}
      onCancel={onCancel}
    >
      <div className="modal-content">
        <div className="live-close-info">
          <div>配信時間内ですが、配信を終了しますか？</div>
          <div className="live-close-tip">※終了する場合、再度配信することはできません</div>
        </div>
        <div>
          <Button text="終了" theme="red" onClick={onOk} />
          <Button text="配信を続ける" onClick={onCancel} />
        </div>
      </div>
    </Modal>
  );
};

export default LiveCloseModal;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { serialize, toSlateState } from "../../richTextEditor/utils/serializer";
import { getImageDetail } from "../../richTextEditor/utils/getData";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Image, DatePicker } from "antd";
import {
  createTimeOptions,
  handlerFormikFieldChange,
} from "../../../utils/fnUtil";
import {
  actions,
  approvalArticle,
  fetchTags,
  fetchTagCategory,
  updateArticle,
  updateApprovalArticle,
  saveDraftArticle,
  fetchMallBrand
} from "../../../slices/articlesSlice";
import { actions as productActions } from "../../../slices/productSlice";
import Icon from "@ant-design/icons";
import Button from "../../button";
import ProgressBar from "../../progressBar";
import GeneralInput from "../../generalInput";
import BaseUpload from "../../baseUpload";
import CusCheckbox from "../../cusCheckbox";
import SelectModal from "../selectModal";
import CommonModal from "../commonModal";
import Icons from "../../../constants/Icons";
import RichTextEditor from "../../richTextEditor";
import DeleteModal from "../deleteModal";
import ProductSearchModal from "../productSearchModal";
import Yup from "../../../utils/yupUtil";
import classNames from "classnames";
import moment from "moment";
import "./style.scss";
import GeneralCheckbox from "../../cusCheckbox/genernalCheckbox";

const ArticleEditModal = () => {
  const dispatch = useDispatch();

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const [selectMallBrand, setSelectMallBrand] = useState([]);

  const handleFileChange = url =>
    handlerFormikFieldChange(formik, "title_image", url);

  const createUpdateParams = values => {
    const { tag_category_code, status_flg, ...rest } = values;

    return {
      ...rest,
      tag_code: values.tag_code.map(t => t.tag_code),
      origin_status_flg: status_flg === "4" ? "3" : status_flg,
      status_flg: "0",
      text: values.text
        .map(t => serialize(t))
        .map((dom, i) => ({
          sort_num: `${i + 1}`,
          dom,
        })),
      ...getImageDetail(values.text),
    };
  };

  const { multi_post_flag = 0 } = useSelector(state => state.account);

  const {
    editModalVisible,
    editDeleteModalVisible,
    confirmModalVisible,
    activeArticle = {},
    categories = [],
    tags = [],
    mallBrandList
  } = useSelector(state => state.articles ?? {});

  const formik = useFormik({
    initialValues: {
      division: "update",
      article_code: activeArticle.article_code,
      brand_code: activeArticle.brand?.brand_code,
      shop_code: activeArticle.shop?.shop_code,
      user_code: activeArticle.user?.user_code,
      tag_code: activeArticle.tags ?? [],
      title: activeArticle.title ?? "",
      title_image: activeArticle.title_image_url ?? "",
      accept_at: activeArticle.published_at,
      text: toSlateState(
        activeArticle.article_texts,
        activeArticle.products,
        activeArticle.coordinates
      ),
      mall_flg: activeArticle.mall_flg ?? 0,
      mall_brand_list: activeArticle.mall_brand_list ?? [],
      status_flg: activeArticle.status_flg,
      tag_category_code: null,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      article_code: Yup.string().required(),
      brand_code: Yup.string().nullable().selected("ブランドCD"),
      shop_code: Yup.string().nullable().selected("店舗CD"),
      user_code: Yup.string().nullable().selected("社員CD"),
      title: Yup.string().required().max(30),
      title_image: Yup.string().required(),
      text: Yup.array().editorTextRequired(),
      tag_code: Yup.array().min(2).max(20),
      mall_brand_list: Yup.array().mallBrands(),
    }),
    onSubmit: values => dispatch(updateArticle(createUpdateParams(values))),
  });

  const approvalFormik = useFormik({
    initialValues: {
      date: null,
      hour: null,
      minute: null,
      approval_flg: ["3", "4"].includes(activeArticle.status_flg)
        ? ["1"]
        : ["2"].includes(activeArticle.status_flg)
        ? ["0"]
        : [],
      remand_reason: activeArticle.remand_reason ?? "",
      status_flg: activeArticle.status_flg,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      approval_flg: Yup.array().test(
        "approval_flg",
        "必須項目です",
        value => value.length > 0
      ),
      remand_reason: Yup.string().when("approval_flg", {
        is: v => v.includes("0"),
        then: Yup.string().required(),
      }),
      date: Yup.string()
        .nullable()
        .test("date", "日時を選択してください", (value, testContext) =>
          testContext.parent.approval_flg.includes("1")
            ? value && testContext.parent.hour && testContext.parent.minute
            : true
        ),
    }),
    onSubmit: async values => {
      const accept_at = values.approval_flg.includes("1")
        ? `${values.date.format("YYYY-MM-DD")} ${values.hour}:${
            values.minute
          }:00`
        : null;

      const approvalParams = {
        accept_at,
        remand_reason: values.remand_reason,
        article_code: formik.values.article_code,
        approval_flg: values.approval_flg[0],
        origin_status_flg: values.status_flg === "4" ? "3" : values.status_flg,
      };
      if (values.status_flg === "0") {
        dispatch(
          updateApprovalArticle({
            updateParams: createUpdateParams(formik.values),
            approvalParams,
          })
        );
      } else {
        dispatch(approvalArticle(approvalParams));
      }
    },
  });

  const {
    tag_category_code,
    title_image,
    tag_code,
    status_flg,
    article_code,
    user_code,
    mall_flg,
    mall_brand_list
  } = formik.values;

  const {
    values: { approval_flg, date, remand_reason },
  } = approvalFormik;

  const contentEditable = status_flg === "0";

  useEffect(() => {
    if (editModalVisible) {
      dispatch(fetchMallBrand(
          {
            brand_code: activeArticle.brand.brand_code,
            mall_flag: 1,
            offset: 0,
            status_flag: 1,
            sort: "brand_code_asc"
          }
      ));
      const current = activeArticle.published_at
        ? moment(activeArticle.published_at)
        : moment();
      handlerFormikFieldChange(approvalFormik, "date", current);
      handlerFormikFieldChange(approvalFormik, "hour", current.format("HH"));
      handlerFormikFieldChange(approvalFormik, "minute", current.format("mm"));
    }
  }, [activeArticle, handlerFormikFieldChange, editModalVisible]);

  useEffect(() => {
    tag_category_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code: activeArticle.brand?.brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, activeArticle, dispatch]);

  useEffect(() => {
    editModalVisible &&
      dispatch(productActions.saveBrand(activeArticle.brand?.brand_code));
    editModalVisible &&
      activeArticle.status_flg === "0" &&
      dispatch(
        fetchTagCategory({
          brand_code: activeArticle.brand?.brand_code,
          sort: "tag_category_code_asc",
          count: 0,
        })
      );
  }, [activeArticle, editModalVisible, dispatch]);

  useEffect(() => {
    setSelectMallBrand(mallBrandList.filter(m=> mall_brand_list.includes(m.brand_code)));
  },[mallBrandList, mall_brand_list]);

  const onBack = () => {
    dispatch(actions.closeEditModal());
    formik.resetForm();
    approvalFormik.resetForm();
  };

  const afterClose = () => {
    dispatch(actions.clearActiveArticle());
    formik.resetForm();
    approvalFormik.resetForm();
  };

  return (
    editModalVisible && (
      <Modal
        visible={editModalVisible}
        footer={null}
        closable={false}
        destroyOnClose={true}
        afterClose={afterClose}
        onCancel={onBack}
        width={840}
        style={{ minWidth: 840 }}
      >
        <ProgressBar activeProgress={activeArticle.status_flg} />
        <div className="blog-edit">
          <div className="blog-edit-area">
            <span className="text-title text-bold">ブログ編集 </span>
            <span className="nf-text-required-style">*は必須項目です</span>
            <br />
            <div className="text-bold text-article-code text-blog-code">
              ブログCD {activeArticle.article_code}
            </div>
            <div className="text-bold text-required">投稿者情報</div>
            <div className="info-area">
              <span>ブランド：{activeArticle?.brand?.brand_name}</span>
              <span>店舗：{activeArticle?.shop?.shop_name}</span>
              <span>社員：{activeArticle?.user?.user_name}</span>
              <br />
              <span>
                投稿者権限：
                {activeArticle.user?.blog_approval_flg === 1
                  ? "承認スキップユーザー"
                  : activeArticle.user?.blog_approval_flg === 0
                  ? "承認申請ユーザー"
                  : "-"}
              </span>
            </div>
            <div className="text-bold text-required">タイトル</div>
            <GeneralInput
              uneditable={!contentEditable}
              placeholder="タイトルを入力してください"
              styleType="block-grey-normal"
              formik={formik}
              name="title"
            />
            <div>
              <div className="text-bold text-required title-image">
                タイトル画像
              </div>
              <span className="validation-error error-title-image">
                {formik.touched.title_image && formik.errors.title_image}
              </span>
            </div>
            <div className="text-image-tip">
              ※画像もしくは商品を選択してください
            </div>
            <div className="image-select">
              {title_image && contentEditable && (
                <Icon
                  component={Icons.CloseIcon}
                  className="close-icon"
                  onClick={e => {
                    handlerFormikFieldChange(formik, "title_image", "");
                    e.stopPropagation();
                  }}
                />
              )}
              {title_image ? (
                <Image
                  src={title_image}
                  width={150}
                  height={200}
                  preview={false}
                />
              ) : (
                <>
                  <BaseUpload
                    disabled={!contentEditable}
                    onChange={handleFileChange}
                    onClick={() => formik.setFieldTouched("title_image", true)}
                  >
                    <Button
                      text="画像を追加"
                      theme="white"
                      disabled={!contentEditable}
                    />
                  </BaseUpload>
                  <Button
                    text="商品を追加"
                    theme="white"
                    disabled={!contentEditable}
                    onClick={() => {
                      setProductSearchModalVisible(true);
                      formik.setFieldTouched("title_image", true);
                    }}
                  />
                </>
              )}
            </div>
            <RichTextEditor
              articleCode={article_code}
              editorState={formik.values.text}
              readOnly={!contentEditable}
              error={formik.touched.text && formik.errors.text}
              onChange={state => formik.setFieldValue("text", state)}
              setTouched={() =>
                !formik.touched.text && formik.setFieldTouched("text", true)
              }
              userCode={user_code}
            />
            <div className="tag-area">
              <div className="tag-title">
                <span className="text-bold text-required">タグ情報</span>　
                <span className="text-tip">
                  2個以上登録必須
                  <span className="text-subtip"> 20個まで登録可</span>
                </span>
              </div>
              {contentEditable && (
                <>
                  <GeneralInput
                    label="カテゴリ"
                    placeholder="選択してください"
                    styleType="block-grey-normal"
                    requiredItem
                    labelTextAlign="left"
                    options={categories}
                    mode={null}
                    name={"tag_category_code"}
                    formik={formik}
                  />
                  <div>
                    <span className="text-required text-tag">タグ</span>
                    <Button
                      disabled={!tag_category_code}
                      text="タグを選択"
                      theme="white"
                      onClick={() => setSelectTagModalVisible(true)}
                    />
                    <span className="validation-error">
                      {formik.touched.tag_code && formik.errors.tag_code}
                    </span>
                  </div>
                </>
              )}
              <div className="tags-container">
                {tag_code.map((t, i) => (
                  <div className="tag" key={i}>
                    {t.tag_name}
                    {contentEditable && (
                      <Icon
                        className="close-icon"
                        component={Icons.CloseIcon}
                        onClick={() => {
                          handlerFormikFieldChange(
                            formik,
                            "tag_code",
                            tag_code.filter(i => i.tag_code !== t.tag_code)
                          );
                        }}
                      />
                    )}
                  </div>
                ))}
              </div>
              <div className="mall-brand-area">
                <div className="mall-brand-title">
                  <span className="text-bold text-required">同時投稿</span>
                </div>
                {mallBrandList.length > 0 && multi_post_flag === 1 && contentEditable && (
                    <>
                      <div className="nf-input-wrapper nf-input-wrapper-wide">
                        <label className="nf-input-label text-align-left label-width-wide label-required">同時投稿</label>
                        <GeneralCheckbox
                            label="同時投稿する"
                            checked={mall_flg === 1}
                            className={"nf-checkbox-wide"}
                            onChange={(value) => handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 1 : 0)}
                        />
                        <GeneralCheckbox
                            label="同時投稿しない"
                            checked={mall_flg === 0}
                            onChange={(value) => {
                              handlerFormikFieldChange(formik, "mall_flg", value.target.checked ? 0 : 1)
                              handlerFormikFieldChange(formik, "mall_brand_list", [])
                            }}
                        />
                      </div>
                      {mall_flg === 1 && (<>
                        <GeneralInput
                            label="同時投稿先"
                            placeholder="選択してください"
                            styleType="block-grey-normal"
                            labelTextAlign="left"
                            requiredItem={mall_flg === 1}
                            options={mallBrandList}
                            disabled={mall_flg === 0}
                            name={"mall_brand_list"}
                            formik={formik}
                        />
                      </>)}
                    </>
                )}

                <div className="mall-brand-container">
                  {multi_post_flag === 1 && selectMallBrand.map((b, i) => (
                      <div className="mall-brand" key={i}>
                        {b.brand_name}
                        {(contentEditable && multi_post_flag === 1) && (
                            <Icon
                                className="close-icon"
                                component={Icons.CloseIcon}
                                onClick={() => {
                                  handlerFormikFieldChange(
                                      formik,
                                      "mall_brand_list",
                                      mall_brand_list.filter(i => i !== b.brand_code)
                                  );
                                }}
                            />
                        )}
                      </div>
                  ))}
                  {(contentEditable &&
                      (mallBrandList.length === 0 || multi_post_flag !== 1) ||
                      (!contentEditable &&
                          (multi_post_flag !== 1 || selectMallBrand.length === 0))) && (
                      <>
                        <div className="nf-input-wrapper nf-wrapper-uneditable">
                          <span className="input-value">{"同時投稿しない"}</span>
                        </div>
                      </>
                  )}
                </div>
              </div>

              <div className="action-container">
                <Button
                  text={
                    ["1", "2", "3", "4"].includes(status_flg)
                      ? "下書きに戻す"
                      : "保存"
                  }
                  disabled={status_flg !== "0" && !formik.isValid}
                  onClick={() =>
                    dispatch(
                      ["1", "2", "3", "4"].includes(status_flg)
                        ? actions.openConfirmModal()
                        : saveDraftArticle(createUpdateParams(formik.values))
                    )
                  }
                />
                <Button
                  text="ブログを削除する"
                  theme="link"
                  onClick={() => dispatch(actions.openEditDeleteModal())}
                />
              </div>
            </div>
          </div>
          <div className={classNames("blog-edit-area")}>
            <span className="text-title text-bold">承認設定</span>
            <span className="validation-error">
              {approvalFormik.touched.approval_flg &&
                approvalFormik.errors.approval_flg}
            </span>
            <div className="approval-area">
              <CusCheckbox
                radio={true}
                value={approval_flg}
                onChange={value =>
                  handlerFormikFieldChange(
                    approvalFormik,
                    "approval_flg",
                    value
                  )
                }
                options={[
                  {
                    label: "承認",
                    value: "1",
                  },
                ]}
              />
              <div className="public-date-select">
                <span className="text-required text-checkbox-label">
                  公開日時
                </span>
                <DatePicker
                  format={"YYYY.MM.DD"}
                  value={date}
                  disabled={!approval_flg.includes("1")}
                  suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  onChange={value =>
                    handlerFormikFieldChange(approvalFormik, "date", value)
                  }
                />
                <GeneralInput
                  mode={null}
                  disabled={!approval_flg.includes("1")}
                  styleType="block-grey-normal"
                  options={createTimeOptions(24)}
                  name={"hour"}
                  placeholder="時"
                  textAfter="時"
                  formik={approvalFormik}
                />
                <GeneralInput
                  mode={null}
                  disabled={!approval_flg.includes("1")}
                  styleType="block-grey-normal"
                  options={createTimeOptions(60)}
                  name={"minute"}
                  placeholder="分"
                  textAfter="分"
                  formik={approvalFormik}
                />
                <span className="validation-error">
                  {approvalFormik.touched.date &&
                    approvalFormik.touched.hour &&
                    approvalFormik.touched.minute &&
                    approvalFormik.errors.date}
                </span>
              </div>
              <div
                className={classNames("remand-area", {
                  "disabled-area": ["0"].includes(status_flg),
                })}
              >
                <CusCheckbox
                  radio={true}
                  value={approval_flg}
                  disabled={["0"].includes(status_flg)}
                  options={[
                    {
                      label: "差戻し",
                      value: "0",
                    },
                  ]}
                  onChange={value =>
                    handlerFormikFieldChange(
                      approvalFormik,
                      "approval_flg",
                      value
                    )
                  }
                />
                <span className="text-remind">
                  ※公開中の記事を編集中に戻す場合はステータスを差戻しに変更してください
                </span>
                <span className="validation-error error-remand-reason">
                  {approvalFormik.touched.remand_reason &&
                    approvalFormik.errors.remand_reason}
                </span>
              </div>
              <div
                className={classNames("public-date-select", "select-flex-top", {
                  "disabled-area": ["0"].includes(status_flg),
                })}
              >
                <span className="text-required text-checkbox-label">
                  差戻し理由
                </span>
                <textarea
                  placeholder="テキストを入力してください"
                  spellCheck={false}
                  autoComplete={"false"}
                  value={remand_reason}
                  disabled={["0"].includes(status_flg)}
                  onChange={e =>
                    handlerFormikFieldChange(
                      approvalFormik,
                      "remand_reason",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="action-container">
                <Button text="戻る" theme="white" onClick={onBack} />
                <Button
                  text="更新"
                  onClick={approvalFormik.handleSubmit}
                  disabled={!formik.isValid || !approvalFormik.isValid}
                />
              </div>
            </div>
          </div>
          <SelectModal
            checkBoxName={`${activeArticle.article_code}-tags}`}
            modalVisible={selectTagModalVisible}
            onCancel={() => setSelectTagModalVisible(false)}
            onOk={value => {
              handlerFormikFieldChange(
                formik,
                "tag_code",
                [
                  ...tag_code,
                  ...tags.filter(
                    t =>
                      value.includes(t.value) &&
                      !tag_code.some(tag => t.value === tag.tag_code)
                  ),
                ].filter(
                  tag =>
                    !tags.map(t => t.value).includes(tag.tag_code) ||
                    value.includes(tag.tag_code)
                )
              );
              setSelectTagModalVisible(false);
            }}
            title={"タグを選択してください"}
            value={tag_code.map(t => t.tag_code)}
            options={tags}
          />
        </div>
        <ProductSearchModal
          visible={productSearchModalVisible}
          onCancel={() => setProductSearchModalVisible(false)}
          onOk={selectedProduct => {
            handlerFormikFieldChange(
              formik,
              "title_image",
              selectedProduct.image_url
            );
            setProductSearchModalVisible(false);
          }}
        />
        <DeleteModal
          modalVisible={editDeleteModalVisible}
          onCancel={() => dispatch(actions.closeEditDeleteModal())}
          item={"ブログCD"}
          itemValue={activeArticle.article_code}
          onOk={() => {
            dispatch(
              updateArticle({
                division: "delete",
                article_code: activeArticle.article_code,
                brand_code: activeArticle.brand?.brand_code,
                origin_status_flg:
                  activeArticle.status_flg === "4"
                    ? "3"
                    : activeArticle.status_flg,
              })
            );
          }}
        />
        <CommonModal
          modalVisible={confirmModalVisible}
          text={`ブログCD:${activeArticle.article_code}を下書きに戻します。\nよろしいですか？`}
          tip={"*公開日時、差戻理由は更新されません。"}
          okText="下書きに戻す"
          onClose={() => dispatch(actions.closeConfirmModal())}
          onOk={() =>
            dispatch(updateArticle(createUpdateParams(formik.values)))
          }
        />
      </Modal>
    )
  );
};

export default ArticleEditModal;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Table, DatePicker, Checkbox } from "antd";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  reservesInit,
  fetchReserves,
  fetchReserveDetail,
  fetchReserveDetailEdit,
} from "../../slices/reservesSlice";
import {
  createTimeOptions,
  getBase64,
  handlerFormikFieldChange,
} from "../../utils/fnUtil";
import { validDatePickChecker } from "../../utils/fnUtil";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import ReserveEditModal from "../../components/modal/reserveEditModal";
import ReserveDetailModal from "../../components/modal/reserveDetailModal";
import GenernalInput from "../../components/generalInput";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import moment from "moment";
import "./style.scss";
import { current } from "immer";

const sendStausName = {
  '0': '下書き',
  '1': '予約済み',
  '2': '抽出済み',
  '3': '配信準備',
  '4': '配信中',
  '5': '配信完了',
  '6': '抽出エラー',
  '7': '配信エラー',
  '8': 'キャンセル',
};

const sendStausEdit = {
  '0': true,
  '1': true,
  '2': true,
  '3': false,
  '4': false,
  '5': false,
  '6': false,
  '7': false,
  '8': false,
};

const Reserves = () => {
  const dispatch = useDispatch();

  const {
    brands,
    shops,
    reserveEditModalVisible,
    reservesList,
    activeReserve,
    searchParams,
    total,
  } = useSelector((state) => state.reserves);
  const account = useSelector((state) => state.account);
  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) =>
      dispatch(
        actions.saveSearchParam({
          ...values,
          from_create_date: createDate.from?.format("YYYY-MM-DD"),
          to_create_date: createDate.to?.format("YYYY-MM-DD"),
          from_send_plan_date: sendDate.from?.format("YYYY-MM-DD"),
          to_send_plan_date: sendDate.to?.format("YYYY-MM-DD"),
        })
      ),
  });
  const [createDate, setCreateDate] = useState({ from: null, to: null });
  const [sendDate, setSendDate] = useState({ from: null, to: null });

  useEffect(() => {
    dispatch(reservesInit({}));
    return () => dispatch(actions.clear());
  }, []);

  useUpdateEffect(
    () => dispatch(fetchReserves(searchParams)),
    [searchParams, dispatch, reserveEditModalVisible]
  );

  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      formik.values.sort === desc
        ? asc
        : formik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const getSortIcon = (sort) => {
    return sort ? (
      <>
        &nbsp;
        <Icon component={Icons.SortAscendingIcon} style={{ fontSize: 10 }} />
      </>
    ) : (
      <>
        &nbsp;
        <Icon component={Icons.SortDescendingIcon} style={{ fontSize: 10 }} />
      </>
    );
  };
  const columns = [
    {
      title: () => (
        <>
          <span>CD </span>
          <span onClick={createOnClikSort("document_id")}>
            {getSortIcon(formik.values.sort === "document_id_asc")}
          </span>
        </>
      ),
      dataIndex: "document_id",
      key: "document_id",
      align: "center",
      width: TableColumnWidth.cd,
      render: (val) => <div>{val || "-"}</div>,
    },
    {
      title: () => (
        <>
          <span>タイトル </span>
          <span onClick={createOnClikSort("title")}>
            {getSortIcon(formik.values.sort === "title_asc")}
          </span>
        </>
      ),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: TableColumnWidth.title,
      render: (val) => (
        <div className="table-colum-title-cell">
          {val || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>メッセージ </span>
          <span onClick={createOnClikSort("message")}>
            {getSortIcon(formik.values.sort === "message_asc")}
          </span>
        </>
      ),
      key: "messge",
      dataIndex: "message",
      align: "center",
      width: TableColumnWidth.reserve.message,
      render: (val) => (
        <div style={{ width: "13vw" }} className="table-colum-title-cell">
          {val || "-"}
        </div>
      ),
    },
    {
      title: () => (
        <>
          <span>登録日 </span>
          <span onClick={createOnClikSort("create_date")}>
            {getSortIcon(formik.values.sort === "create_date_asc")}
          </span>
        </>
      ),
      key: "create_date",
      dataIndex: "create_date",
      align: "center",
      width: TableColumnWidth.date,
      render: (date) => (date ? moment(date).format("YYYY.MM.DD") : "-"),
    },
    {
      title: () => (
        <>
          <span>配信日 </span>
          <span onClick={createOnClikSort("send_date")}>
            {getSortIcon(formik.values.sort === "send_date_asc")}
          </span>
        </>
      ),
      key: "send_plan_date",
      dataIndex: "send_plan_date",
      align: "center",
      width: TableColumnWidth.date,
      render: (date, record) => {
        return (
          <span>
            {`${date ? moment(date).format("YYYY.MM.DD") : "-"} ${
              record.send_reserve_time ?? "-"
            }`}
          </span>
        );
      },
    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span onClick={createOnClikSort("send_status")}>
            {getSortIcon(formik.values.sort === "send_status_asc")}
          </span>
        </>
      ),
      key: "send_status",
      dataIndex: "send_status",
      align: "center",
      width: TableColumnWidth.reserve.status,
      render: (val) => {
        return (val ? sendStausName[val] : "-");
      },
    },
    {
      title: () => (
        <>
          <span>リピート </span>
        </>
      ),
      key: "repeat_type",
      dataIndex: "repeat_type",
      align: "center",
      width: TableColumnWidth.reserve.repeat,
      render: (val) =>
        val ? (val === "0" ? "繰り返さない" : "繰り返す") : "-",
    },
    {
      title: () => (
        <>
          <span>配信数/予定数 </span>
        </>
      ),
      key: "send_count",
      dataIndex: "send_count",
      align: "center",
      width: TableColumnWidth.reserve.result,
      render: (val, record) => {
        return `${record.send_result_count ?? "-"}/${
          record.send_plan_count ?? "-"
        }`;
      },
    },
    {
      title: "編集",
      align: "center",
      width: TableColumnWidth.icon,
      render: (val, record) => (
        <>
          {!sendStausEdit[record?.send_status] ? (
            <Icon
              component={Icons.DisableEditIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          ) : (
            <Icon
              component={Icons.EditIcon}
              style={{ fontSize: 25 }}
              onClick={() =>
                dispatch(
                  fetchReserveDetailEdit({
                    reserve_id: record?.reserve_id,
                    document_id: record?.document_id,
                  })
                )
              }
            />
          )}
        </>
      )
    },
    {
      title: "参照",
      align: "center",
      width: TableColumnWidth.icon,
      render: (val, record) => (
        <>
          {!sendStausEdit[record?.send_status] ? (
            <Icon
              component={Icons.SearchIcon}
              style={{ fontSize: 25 }}
              onClick={() =>
                dispatch(
                  fetchReserveDetail({
                    reserve_id: record?.reserve_id,
                    document_id: record?.document_id,
                  })
                )
              }
            />
          ) : (
            <Icon
              component={Icons.DisableSearchIcon}
              style={{ fontSize: 25 }}
              disabled={true}
            />
          )}
        </>
      )
    },
  ];
  const datePickerStyle = {
    backgroundColor: "white",
    fontSize: 16,
    height: 32,
    width: 120,
  };
  return (
    <div className="content-body">
      <Header />
      <div className="reserves-container nf-page">
        <h2>予約・配信済み一覧</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <GenernalInput
              label="タイトル"
              placeholder="タイトルを入力してください"
              formik={formik}
              name={"title"}
            />
            <GenernalInput
              label="メッセージ"
              placeholder="メッセージを入力してください"
              formik={formik}
              name={"message"}
            />
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center">登録日</label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={createDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    createDate,
                    setCreateDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={createDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    createDate,
                    setCreateDate
                  );
                }}
              />
            </div>
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center">配信日</label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={sendDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    sendDate,
                    setSendDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={sendDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(dateString, "to", sendDate, setSendDate);
                }}
              />
            </div>
            <GenernalInput
              label="ステータス"
              mode={null}
              name="send_status"
              placeholder={"ステータスを選択してください"}
              formik={formik}
              options={[
                // { label: "下書き", value: "0" },
                { label: "予約済み", value: "1" },
                { label: "抽出済み", value: "2" },
                { label: "配信準備", value: "3" },
                { label: "配信中", value: "4" },
                { label: "配信完了", value: "5" },
                { label: "抽出エラー", value: "6" },
                { label: "配信エラー", value: "7" },
                { label: "キャンセル", value: "8" },
              ]}
            />
          </div>
          <Button text="検索" type="submit" />
        </form>
        <div className="table-header">
          <h3>検索結果一覧</h3>
        </div>
        <Table
          columns={columns}
          dataSource={reservesList}
          onChange={(pagination) => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{offsetHeader: 60}}
        />
      </div>
      <Footer />
      <ReserveEditModal
        brands={brands}
        shops={shops}
        reserveEditModalVisible={reserveEditModalVisible}
        closeReserveEditModal={() => dispatch(actions.closeReserveEditModal())}
        reserve={activeReserve}
      />
      <ReserveDetailModal />
    </div>
  );
};

export default Reserves;

import { useState } from "react";
import { Image, Modal } from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoordinates, actions } from "../../../slices/articlesSlice";
import Button from "../../button";
import GenernalInput from "../../generalInput";
import classNames from "classnames";
import noimage from "../../../images/noimage_square2.png";
import "./style.scss";

const CoordinateSearchModal = ({ onOk, visible, onCancel }) => {
  const dispatch = useDispatch();

  const [selectedCoordinate, setSelectedCoordinate] = useState({});

  const { user_name, shop_code } = useSelector(state => state.account);

  const {
    shops = [],
    coordinates = [],
    selectedBrand,
  } = useSelector(state => state.articles);

  const formik = useFormik({
    initialValues: {
      coordinate_code: null,
      shop_code: [shop_code],
      user_name,
      tag_name: "",
      status_flg: "4",
    },
    onSubmit: values =>
      dispatch(
        fetchCoordinates({ ...values, brand_code: selectedBrand, count: 0 })
      ),
  });
  const afterClose = () => {
    formik.resetForm();
    dispatch(actions.clearCoordinates());
    setSelectedCoordinate({});
  };

  return (
    <Modal
      closable={false}
      visible={visible}
      footer={null}
      width={1000}
      style={{ minWidth: 1000 }}
      onCancel={onCancel}
      afterClose={afterClose}
    >
      <div className="coordinate-search-area">
        <span className="search-title">コーディネート検索</span>
        <div className="coordinate-search-content">
          <form onSubmit={formik.handleSubmit}>
            <div className="search-condition">
              <GenernalInput
                label={"コーディネートCD"}
                labelWidth="wider"
                styleType="block-grey-normal"
                placeholder={"コーディネートCDを入力してください"}
                name={"coordinate_code"}
                formik={formik}
              />
              <GenernalInput
                label={"店舗"}
                mode="multiple"
                labelWidth="wider"
                styleType="block-grey-normal"
                placeholder={"店舗を選択してください"}
                name={"shop_code"}
                formik={formik}
                options={shops}
              />
              <GenernalInput
                styleType="block-grey-normal"
                labelWidth="wider"
                label={"社員"}
                name={"user_name"}
                formik={formik}
                placeholder={"社員名またはサイト表示名を入力してください"}
              />
              <GenernalInput
                labelWidth="wider"
                label={"タグ"}
                styleType="block-grey-normal"
                placeholder={"タグを入力してください"}
                name={"tag_name"}
                formik={formik}
              />
              <div className="search-button-container">
                <Button text="検索" type="submit" />
              </div>
            </div>
          </form>
          <div className="search-result">
            <span className="result-title">検索結果</span>
            {coordinates.map((c, i) => (
              <div
                key={i}
                className={classNames("result-box", {
                  "result-box-selected":
                    selectedCoordinate.coordinate_code === c.coordinate_code,
                })}
                onClick={() => setSelectedCoordinate(c)}
              >
                <div>
                  <Image src={c.image_url || noimage} width={90} height={120} />
                </div>
                <div className="item-box">
                  <div>{c.coordinate_code}</div>
                  <div>店舗：{c.shop_name}</div>
                  <div>
                    社員：{c.user_name}({c.user_name_kana})
                  </div>
                  <p className="tag">
                    タグ： {(c.tags ?? []).map(t => t.tag_name).join(",")}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="action-container">
          <Button text="戻る" theme="white" onClick={onCancel} />
          <Button
            text="追加"
            onClick={() => onOk(selectedCoordinate)}
            disabled={!selectedCoordinate.coordinate_code}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CoordinateSearchModal;

import "./style.scss";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Image } from "antd";
import { Link } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import Yup from "../../utils/yupUtil";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import Footer from "../../components/footer";
import Button from "../../components/button";
import Loading from "../../components/loading";
import LoginInfoModal from "../../components/modal/loginInfoModal";
import ChangePasswordModal from "../../components/modal/changePasswordModal";
import { actions } from "../../slices/accountSlice";
import { actions as loadingActions } from "../../slices/loadingSlice";
import Icons from "../../constants/Icons";
import Icon from "@ant-design/icons";
import crypto from "crypto";

const Login = () => {
  const [infoModal, setInfoModal] = useState({ visible: false, msg: "" });
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const [provisionalLoginAccount, setProvisionalLoginAccount] = useState({});
  const [currentPassword, setCurrentPassword] = useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { corporation_code: "", mail_address: "", password: "" },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      corporation_code: Yup.string().required(),
      mail: Yup.string().required().max(256).mail().mail_local(),
      password: Yup.string().required(),
    }),
    onSubmit: async (inputVals) => {
      try {
        dispatch(loadingActions.loadingOn());
        const pass = crypto
          .createHash("sha256")
          .update(inputVals.password, "utf8")
          .digest("hex");
        const loginResult = await accountLoginRequest({
          request_from: 2,
          corporation_code: inputVals.corporation_code,
          mail_address: inputVals.mail,
          password: pass,
        });
        dispatch(loadingActions.loadingOff());
        switch (loginResult.data.code) {
          case "A00000":
            dispatch(actions.setAccount(loginResult.data));
            break;
          case "A00001":
            setCurrentPassword(pass);
            setProvisionalLoginAccount(loginResult.data);
            setChangePasswordModalVisible(true);
            break;
          default:
            setInfoModal({
              visible: true,
              msg:
                loginResult?.data?.display_message.split('\n').map((str, index) => ( <React.Fragment key={index}>{str}<br /></React.Fragment>)) ||
                "システムエラーが発生しました",
            });
        }
      } catch (error) {
        dispatch(loadingActions.loadingOff());
        setInfoModal({
          visible: true,
          msg:
            error?.response?.data?.display_message.split('\n').map((str, index) => ( <React.Fragment key={index}>{str}<br /></React.Fragment>)) ||
            "システムエラーが発生しました",
        });
      }
    },
  });
  const accountLoginRequest = async (body) => {
    return await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/account/login`,
      body,
      {
        headers: {
          // corporation_code: "primary",
          "X-Requested-With": "XMLHttpRequest",
          "Content-type": "application/json",
        },
      }
    );
  };
  // ロゴ判断
  const os = require('os');
  const isW2 = os.hostname() === process.env.REACT_APP_W2_DOMAIN ? true : false;
  return (
    <>
      <Loading />
      <ChangePasswordModal
        modalVisible={changePasswordModalVisible}
        setModalVisible={setChangePasswordModalVisible}
        maskClosable={false}
        login_type="0"
        provisionalLoginAccount={provisionalLoginAccount}
        currentPassword={currentPassword}
      />
      <LoginInfoModal
        modalVisible={infoModal.visible}
        setModalVisible={(val) => {
          setInfoModal({ ...infoModal, visible: val });
        }}
        title="エラー"
        msg={infoModal.msg}
      />
      <div className="login-page">
        <div className={isW2 ? "login-content isW2 login-content-w2" : "login-content isW2"}>
          <div className="login-logo">
            {isW2 ?
              (<Image src={Icons.LogoOnlineservicew3} width={350} height={150} style={{"padding-top" : "30px"}} preview={false} />)
            : (
              <Icon 
                component={Icons.LogoOnlineservice} 
                className={"logo-image display-icon"}
              />
            )}
          </div>
          <div className="login-form">
            <div className="login-input-wrapper">
              <div className="login-label-area">
                <span className="login-label">法人CD</span>
                <span className="login-requiredMark">
                  {formik?.touched?.corporation_code &&
                    formik?.errors?.corporation_code}
                </span>
              </div>
              <Input
                bordered={true}
                className="input-loginForm"
                name="corporation_code"
                placeholder={"法人CDを入力してください"}
                onKeyPress={(e) => e.key === 'Enter' && formik.handleSubmit()}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  handlerFormikFieldChange(
                    formik,
                    "corporation_code",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="login-input-wrapper">
              <div className="login-label-area">
                <span className="login-label">メールアドレス</span>
                <span className="login-requiredMark">
                  {formik?.touched?.mail && formik?.errors?.mail}
                </span>
              </div>
              <Input
                bordered={true}
                className="input-loginForm"
                name="mail_address"
                placeholder={"メールアドレスを入力してください"}
                onKeyPress={(e) => e.key === 'Enter' && formik.handleSubmit()}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  handlerFormikFieldChange(formik, "mail", e.target.value);
                }}
              />
            </div>
            <div className="login-input-wrapper">
              <div className="login-label-area">
                <span className="login-label">パスワード</span>
                <span className="login-requiredMark">
                  {formik?.touched?.password && formik?.errors?.password}
                </span>
              </div>
              <Input.Password
                className="input-loginForm"
                name="password"
                placeholder={"パスワードを入力してください"}
                onKeyPress={(e) => e.key === 'Enter' && formik.handleSubmit()}
                visibilityToggle={false}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  handlerFormikFieldChange(formik, "password", e.target.value);
                }}
              />
            </div>
            <div className="login-passwordRecoveryLink">
              <Link to="/passwordRecovery">パスワードをお忘れの方はこちら</Link>
            </div>
            <div className="btn-area">
              <Button
                contentEditable={false}
                text="ログイン"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;

import {
  Modal,
  DatePicker,
  Checkbox,
  Select,
  Upload,
  InputNumber,
  message,
} from "antd";
import "./style.scss";
import "../../generalInput/style.scss";
import Button from "../../button";
import axios from "../../../utils/axiosUtil";
import { handlerFormikFieldChange } from "../../../utils/fnUtil";
import { useEffect, useState } from "react";
import DualListBox from "../../dualListBox";
import moment from "moment";
import { actions as messageActions } from "../../../slices/messageSlice";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { useFormik } from "formik";
import Yup from "../../../utils/yupUtil";

const ReserveModal = ({
  brands,
  shops,
  reserveEditModalVisible,
  closeReserveEditModal,
  reserve,
}) => {
  const dispatch = useDispatch();
  const [cancel, setCancel] = useState(false);
  const division = cancel ? "cancel" : reserve?.reserve_id ? "update" : "insert";
  const [availableShops, setAvailableShops] = useState([]);
  const [selectShops, setSelectShops] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const account = useSelector((state) => state.account);

  const formik = useFormik({
    initialValues: {
      send_type: reserve?.send_type || "1",
      repeat_type: reserve?.repeat_type || "0",
      send_date: reserve?.send_plan_date
        ? moment(reserve?.send_plan_date)
        : null,
      repeat_start_date: reserve?.repeat_start_date
        ? moment(reserve?.repeat_start_date)
        : null,
      send_start_time_hh: reserve?.send_reserve_time
        ? reserve?.send_reserve_time.slice(0, 2)
        : "",
      repeat_send_time_hh: reserve?.repeat_send_time
        ? reserve?.repeat_send_time.slice(0, 2)
        : "",
      send_start_time_mm: reserve?.send_reserve_time
        ? reserve?.send_reserve_time.slice(3, 5)
        : "",
      repeat_send_time_mm: reserve?.repeat_send_time
        ? reserve?.repeat_send_time.slice(3, 5)
        : "",
      repeat_end_date: reserve?.repeat_end_date
        ? moment(reserve.repeat_end_date)
        : null,
      repeat_period: reserve?.repeat_period || "week",
      timing: {
        day: reserve?.repeat_period === "day" ? reserve?.timing.day : "",
        week:
          reserve?.repeat_period === "week"
            ? moment(reserve?.repeat_start_date)
            : null,
        month: reserve?.timing?.month?.month_week?.day_of_week
          ? "day_of_week"
          : "month_day",
      },
      cond_flag: account.user_auth == "2" ? "1" : (reserve?.cond_flag || "0"),
      file_type: reserve?.list_type || "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      send_date: Yup.string()
        .nullable()
        .test(
          "send_date_required",
          "必須項目です",
          (_, context) =>
            context.parent.send_type === "2" ||
            context.parent.repeat_type === "1" ||
            context.parent.send_date
        ),
      start_date: Yup.string()
        .nullable()
        .test(
          "start_date_required",
          "必須項目です",
          (_, context) =>
            context.parent.send_type === "2" ||
            context.parent.repeat_type === "0" ||
            context.parent.repeat_start_date
        ),
      send_time: Yup.string().test(
        "send_time_required",
        "必須項目です",
        (_, context) =>
          context.parent.send_type === "2" ||
          context.parent.repeat_type === "1" ||
          (context.parent.send_start_time_hh &&
            context.parent.send_start_time_mm)
      ),
      start_time: Yup.string().test(
        "start_time_required",
        "必須項目です",
        (_, context) =>
          context.parent.send_type === "2" ||
          context.parent.repeat_type === "0" ||
          (context.parent.repeat_send_time_hh &&
            context.parent.repeat_send_time_mm)
      ),
      timing_day: Yup.string().test(
        "timing_required",
        "必須項目です",
        (_, context) =>
          context.parent.send_type === "2" ||
          context.parent.repeat_type === "0" ||
          context.parent.repeat_period !== "day" ||
          context.parent.timing.day
      ),
      repeat_end_date: Yup.string()
        .nullable()
        .test(
          "repeat_end_required",
          "必須項目です",
          (_, context) =>
            context.parent.send_type === "2" ||
            context.parent.repeat_type === "0" ||
            context.parent.repeat_end_date
        ),
      repeat_end_valid_date: Yup.string()
        .nullable()
        .test(
          "repeat_end_valid",
          "終了日は開始日以降を選択してください",
          (_, context) => {
            if (
              context.parent.send_type === "2" ||
              context.parent.repeat_type === "0" ||
              !context.parent.repeat_end_date ||
              !context.parent.repeat_start_date
            ) {
              return true;
            }
            return moment(context.parent.repeat_start_date).isSameOrBefore(
              context.parent.repeat_end_date
            );
          }
        ),
    }),
    onSubmit: async (values) => {
      if (division === "cancel") {
        const params = {
          token: account?.token,
          request_from: "2",
          operator_code: account?.user_code,
          division: division,
          document_id: reserve?.document_id,
          send_type: "1",
          reserve_id: reserve?.reserve_id,
          repeat_type: "0",
          cond_flag: "0",
        };
        await axios.post(
          "/notice/reserve/update",
          createFormData(params),
          {
            headers: { "content-type": "multipart/form-data" },
            isFileUpload: true,
          }
        );
        closeReserveEditModal();
        dispatch(
          messageActions.setMessage({
            messages: [
              "配信設定をキャンセルしました",
            ],
          })
        );
      } else {
        if (values.cond_flag === "1" && selectShops.length === 0 && ((division === "insert" && !uploadFile) || (division === "update" && !file_type))) {
          setCancel(false);
          dispatch(
            messageActions.setMessage({
              messages: [
                "絞り込み条件を一つ以上選択してください",
              ],
            })
          );
          return;
        }
        const params = {
          token: account?.token,
          request_from: "2",
          operator_code: account?.user_code,
          division: division,
          document_id: reserve?.document_id,
          send_type: values.send_type,
          cond_flag: values.cond_flag,
        };
        if (division === "update") params.reserve_id = reserve?.reserve_id;
        if (values.send_type === "1") {
          params.repeat_type = values.repeat_type;
          if (values.repeat_type === "0") {
            params.send_date = values.send_date.format("YYYY-MM-DD");
            params.send_time =
              ("00" + values.send_start_time_hh).slice(-2) +
              ("00" + values.send_start_time_mm).slice(-2);
          }
          if (values.repeat_type === "1") {
            params.repeat_start_date =
              values.repeat_start_date.format("YYYY-MM-DD");
            params.repeat_send_time =
              ("00" + values.repeat_send_time_hh).slice(-2) +
              ("00" + values.repeat_send_time_mm).slice(-2);
            params.repeat_end_date = values.repeat_end_date.format("YYYY-MM-DD");
            params.repeat_period = values.repeat_period;
            if (values.repeat_period === "day")
              params.timing = { day: values.timing.day };
            if (values.repeat_period === "week")
              params.timing = { week: repeat_start_date.format("dd") };
            if (
              values.repeat_period === "month" &&
              timing.month === "month_day"
            ) {
              params.timing = {
                month: { month_day: values.repeat_start_date.date() },
              };
            }
            if (
              values.repeat_period === "month" &&
              timing.month === "day_of_week"
            ) {
              params.timing = {
                month: {
                  month_week: {
                    day_of_week:
                      values.repeat_period === "month" &&
                      timing.month === "day_of_week"
                        ? Math.ceil(values.repeat_start_date.date() / 7)
                        : null,
                    repeat_week:
                      values.repeat_period === "month" &&
                      timing.month === "day_of_week"
                        ? values.repeat_start_date.format("dddd")
                        : null,
                  },
                },
              };
            }
          }
        }
        if (values.cond_flag === "1") {
          params.shop_list = selectShops.map((s) => s.shop_code);
          if (uploadFile) params.upload_file = uploadFile.file;
          params.file_type = values.file_type;
        }
        await axios.post(
          "/notice/reserve/update",
          createFormData(params),
          {
            headers: { "content-type": "multipart/form-data" },
            isFileUpload: true,
          }
        );
        closeReserveEditModal();
        dispatch(
          messageActions.setMessage({
            messages: [
              "配信設定を更新しました",
            ],
          })
        );
      }
    },
  });
  const {
    send_type,
    repeat_type,
    send_date,
    repeat_start_date,
    repeat_end_date,
    repeat_period,
    timing,
    cond_flag,
    file_type,
  } = formik.values;

  useEffect(() => {
    formik.resetForm();
    setUploadFile(null);
    setSelectShops(reserve?.shop_list ?? []);
    setAvailableShops([]);
    setCancel(false);
  }, [reserveEditModalVisible]);
  useEffect(() => {
    let html = window.document.getElementsByClassName("ant-modal-wrap");
    if (html.length < 1) return;
    if (send_type === "2") {
      html[0].classList.add("nf-modal-scroll");
    }
    if (send_type === "1") {
      html[0].classList.remove("nf-modal-scroll");
    }
  }, [send_type]);
  const numberPicker = (from, to, style, formName) => {
    let picker = [];
    for (let i = from; i <= to; i++) {
      let num = ("00" + i).slice(-2);
      picker.push(<Select.Option value={num}>{num}</Select.Option>);
    }
    return (
      <Select
        name={formName}
        style={style}
        value={formik.values[formName]}
        onChange={(val) => {
          handlerFormikFieldChange(formik, formName, val);
        }}
      >
        {picker}
      </Select>
    );
  };
  const getMomentTagertWeekOfMonthNum = (targetMoment) => {
    const startOfMonthOfWeekNum = moment(
      targetMoment.clone().startOf("month")
    ).format("w");
    const targetOfYearOfWeekNum = targetMoment.clone().format("w");
    return targetOfYearOfWeekNum - startOfMonthOfWeekNum + 1;
  };
  const getFileTxt = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsText(file, "UTF-8");
  };
  const isCorrectExtension = (name) => {
    const format = new RegExp("([^s]+(\\.(csv|txt))$)", "i");
    return format.test(name);
  };
  const createFormData = (params) => {
    const formData = new FormData();
    formData.append("upload_file", params.upload_file);
    formData.append(
      "jsonValue",
      new Blob([JSON.stringify(params)], { type: "application/json" })
    );
    return formData;
  };
  const cancelButton = () => {
    return !!reserve?.reserve_id ? (
        <Button
          text="配信予約をキャンセルする"
          theme="link"
          style={{ color: "red" }}
          onClick={(e) => {
            setCancel(true);
            return formik.handleSubmit(e);
          }}
        />) : (<></>);
  };
  return (
    reserveEditModalVisible && (
      <>
        <Modal
          visible={reserveEditModalVisible}
          footer={null}
          closable={false}
          destroyOnClose={true}
          width={840}
          afterClose={() => {
            formik.resetForm();
          }}
          onCancel={()=>{
            closeReserveEditModal()
          }}
          className="test"
        >
          <div className="reserveEditModal-area">
            <h3 className="title-area">
              <span className="text-title">配信設定</span>
            </h3>
            <div className="form-area">
              <div className="nf-input-wrapper">
                <div className="nf-input-label">配信区分</div>
                <Checkbox
                  name="sendType0"
                  onChange={(val) => {
                    handlerFormikFieldChange(
                      formik,
                      "send_type",
                      val.target.checked ? "1" : "2"
                    );
                  }}
                  checked={send_type === "1"}
                >
                  予約配信
                </Checkbox>
                <Checkbox
                  name="sendType1"
                  onChange={(val) => {
                    handlerFormikFieldChange(
                      formik,
                      "send_type",
                      val.target.checked ? "2" : "1"
                    );
                  }}
                  checked={send_type === "2"}
                >
                  即時配信
                </Checkbox>
              </div>
              {send_type === "1" && (
                <>
                  <div className="nf-input-wrapper">
                    <div className="nf-input-label">リピート</div>
                    <Checkbox
                      name="repeatType0"
                      onChange={(val) => {
                        handlerFormikFieldChange(
                          formik,
                          "repeat_type",
                          val.target.checked ? "0" : "1"
                        );
                      }}
                      checked={repeat_type === "0"}
                    >
                      繰り返さない
                    </Checkbox>
                    <Checkbox
                      name="repeatType1"
                      onChange={(val) => {
                        handlerFormikFieldChange(
                          formik,
                          "repeat_type",
                          val.target.checked ? "1" : "0"
                        );
                      }}
                      checked={repeat_type === "1"}
                    >
                      繰り返す
                    </Checkbox>
                  </div>
                  {repeat_type === "0" && (
                    <div className="nf-input-wrapper">
                      <div className="text-required nf-input-label">配信日</div>
                      <DatePicker
                        format={"YYYY.MM.DD"}
                        placeholder={null}
                        value={send_date}
                        suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onBlur={() =>
                          handlerFormikFieldChange(formik, "send_date", send_date)
                        }
                        onChange={(value) =>
                          handlerFormikFieldChange(formik, "send_date", value)
                        }
                      />
                      <span className="valid-txt">
                        {formik?.errors?.send_date || ""}
                      </span>
                    </div>
                  )}
                  {repeat_type === "1" && (
                    <div className="nf-input-wrapper">
                      <div className="text-required nf-input-label">開始日</div>
                      <DatePicker
                        format={"YYYY.MM.DD"}
                        placeholder={null}
                        value={repeat_start_date}
                        suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentNode
                        }
                        onChange={(value) => {
                          handlerFormikFieldChange(
                            formik,
                            "repeat_start_date",
                            value
                          );
                        }}
                      />
                      <span className="valid-txt">
                        {formik?.errors?.start_date || ""}
                      </span>
                    </div>
                  )}
                  <div className="nf-input-wrapper">
                    <div className="text-required nf-input-label">配信時間</div>
                    {numberPicker(
                      0,
                      23,
                      { width: 80 },
                      repeat_type === "0"
                        ? "send_start_time_hh"
                        : "repeat_send_time_hh"
                    )}
                    時
                    {numberPicker(
                      0,
                      59,
                      { width: 80, marginLeft: 15 },
                      repeat_type === "0"
                        ? "send_start_time_mm"
                        : "repeat_send_time_mm"
                    )}
                    分
                    <span className="valid-txt">
                      {formik?.errors?.send_time || ""}
                    </span>
                    <span className="valid-txt">
                      {formik?.errors?.start_time || ""}
                    </span>
                  </div>
                  <div className="nf-input-wrapper">
                    <div
                      className={`text-required nf-input-label ${
                        repeat_type === "0" && "nf-input-label-disabled"
                      }`}
                    >
                      繰り返し間隔
                    </div>
                    <Select
                      style={{ width: 170 }}
                      disabled={repeat_type === "0"}
                      value={repeat_period}
                      onChange={(val) => {
                        handlerFormikFieldChange(formik, "repeat_period", val);
                      }}
                    >
                      <Select.Option value="day">日</Select.Option>
                      <Select.Option value="week">週</Select.Option>
                      <Select.Option value="month">月</Select.Option>
                    </Select>
                    {repeat_period === "day" && (
                      <>
                        <InputNumber
                          value={timing.day}
                          min={1}
                          style={{ width: 170, marginLeft: 15 }}
                          disabled={repeat_type === "0"}
                          onChange={(val) => {
                            handlerFormikFieldChange(formik, "timing", {
                              ...timing,
                              day: val,
                            });
                          }}
                        ></InputNumber>
                        毎
                        <span className="valid-txt">
                          {formik?.errors?.timing_day || ""}
                        </span>
                      </>
                    )}
                    {repeat_period === "week" && (
                      <>
                        <Select
                          style={{
                            width: 170,
                            marginLeft: 15,
                          }}
                          disabled={repeat_type === "0"}
                          value={
                            repeat_start_date
                              ? repeat_start_date.format("dd")
                              : null
                          }
                          onChange={(val) => {
                            handlerFormikFieldChange(formik, "timing", {
                              ...timing,
                              week: val,
                            });
                          }}
                        >
                          {repeat_start_date && (
                            <Select.Option
                              value={repeat_start_date.format("dd")}
                            >
                              毎週{repeat_start_date.format("dddd")}
                            </Select.Option>
                          )}
                        </Select>
                        毎
                      </>
                    )}
                    {repeat_period === "month" && (
                      <>
                        <Select
                          style={{
                            width: 170,
                            marginLeft: 15,
                          }}
                          value={repeat_start_date ? timing.month : ""}
                          disabled={repeat_type === "0"}
                          onChange={(val) => {
                            handlerFormikFieldChange(formik, "timing", {
                              ...timing,
                              month: val,
                            });
                          }}
                        >
                          {repeat_start_date && (
                            <>
                              <Select.Option value="month_day">
                                毎月{repeat_start_date.date()}日
                              </Select.Option>
                              <Select.Option value="day_of_week">
                                毎月第
                                {getMomentTagertWeekOfMonthNum(
                                  repeat_start_date
                                )}
                                週{repeat_start_date.format("dddd")}
                              </Select.Option>
                            </>
                          )}
                        </Select>
                      </>
                    )}
                  </div>
                  <div className="nf-input-wrapper">
                    <div
                      className={`text-required nf-input-label ${
                        repeat_type === "0" && "nf-input-label-disabled"
                      }`}
                    >
                      終了日
                    </div>
                    <DatePicker
                      format={"YYYY.MM.DD"}
                      // size="small"
                      placeholder={null}
                      disabled={repeat_type === "0"}
                      value={repeat_end_date}
                      suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                      onChange={(value) =>
                        handlerFormikFieldChange(
                          formik,
                          "repeat_end_date",
                          value
                        )
                      }
                    />
                    <span className="valid-txt">
                      {formik?.errors?.repeat_end_date || ""}
                    </span>
                    <span className="valid-txt">
                      {formik?.errors?.repeat_end_valid_date || ""}
                    </span>
                  </div>
                </>
              )}
              <div className="nf-input-wrapper">
                <div className="nf-input-label">絞り込み条件</div>
                <Checkbox
                  name="condFlag0"
                  onChange={(val) => {
                    handlerFormikFieldChange(
                      formik,
                      "cond_flag",
                      val.target.checked ? "0" : "1"
                    );
                  }}
                  checked={cond_flag === "0"}
                  disabled={account.user_auth == "2"}
                >
                  全件
                </Checkbox>
                <Checkbox
                  name="condFlag1"
                  onChange={(val) => {
                    if (account.user_auth == "2") return;
                    handlerFormikFieldChange(
                      formik,
                      "cond_flag",
                      val.target.checked ? "1" : "0"
                    );
                  }}
                  checked={cond_flag === "1"}
                >
                  絞り込み
                </Checkbox>
                <span className="valid-txt">{formik?.errors?.cond || ""}</span>
              </div>
              {cond_flag === "1" && (
                <>
                  <div className="nf-input-wrapper">
                    <div className="nf-input-label" style={{ height: 175 }}>
                      店舗
                    </div>
                    <DualListBox
                      brandList={brands}
                      shopList={shops}
                      selectShops={selectShops}
                      setSelectShops={setSelectShops}
                      availableShops={availableShops}
                      setAvailableShops={setAvailableShops}
                    />
                  </div>
                  <div className="nf-input-wrapper">
                    <div className="nf-input-label" style={{ height: 142 }}>
                      IDリスト
                    </div>
                    <div>
                      ファイル区分
                      <br />
                      {/* <Checkbox
                        name="fileType1"
                        style={{ marginTop: 10 }}
                        onChange={(val) => {
                          handlerFormikFieldChange(
                            formik,
                            "file_type",
                            val.target.checked ? "1" : ""
                          );
                        }}
                        checked={file_type === "1"}
                      >
                        端末ID
                      </Checkbox> */}
                      <Checkbox
                        name="fileType2"
                        style={{ marginTop: 10 }}
                        onChange={(val) => {
                          handlerFormikFieldChange(
                            formik,
                            "file_type",
                            val.target.checked ? "2" : ""
                          );
                        }}
                        checked={file_type === "2"}
                      >
                        社員CD
                      </Checkbox>
                      <br />
                      <Upload
                        accept={".csv,.txt,"}
                        showUploadList={false}
                        beforeUpload={(file) => {
                          if (!isCorrectExtension(file.name)) {
                            message.error(
                              "csv/txtのファイルのみアップロードできます"
                            );
                            return false;
                          }
                          getFileTxt(file, (fileString) => {
                            const idList = Papa.parse(fileString, {
                              delimiter: "",
                            });
                            idList.data = idList.data.filter(Boolean);
                            setUploadFile({
                              file: file,
                              dataCnt: idList.data.length,
                              errorCnt: idList.errors.length,
                            });
                          });
                          return false;
                        }}
                        maxCount={1}
                        listType="text"
                      >
                        <Button
                          text="ファイルを選択"
                          theme="black"
                          style={{
                            marginTop: 20,
                            marginLeft: 0,
                          }}
                        />
                        <div>
                          <label>
                            {uploadFile
                              ? `ファイル名：${uploadFile?.file?.name}`
                              : ""}
                          </label>
                          <label style={{ marginLeft: 15 }}>
                            {uploadFile
                              ? `取込件数：${uploadFile?.dataCnt}`
                              : ""}
                          </label>
                          {/* <label style={{ marginLeft: 15 }}>
                            {uploadFile
                              ? `エラー：${uploadFile?.errorCnt}`
                              : ""}
                          </label> */}
                          <label>
                            {uploadFile ? "" : reserve?.upload_file_name}
                          </label>
                        </div>
                      </Upload>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="btn-area">
              <Button
                text="戻る"
                theme="white"
                onClick={() => closeReserveEditModal()}
              />
              <Button
                text={reserve?.reserve_id ? "更新" : "登録"}
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}
              />
              {cancelButton()}
            </div>
          </div>
        </Modal>
      </>
    )
  );
};

export default ReserveModal;

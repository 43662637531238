import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Table, DatePicker, Image } from "antd";
import Icon from "@ant-design/icons";
import { useUpdateEffect } from "../../utils/hooksUtil";
import SelectColumsModal from "../../components/modal/selectCloumsModal";
import CsvDownloadModal from "../../components/modal/csvDownloadModal";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  fetchSnsFavoriteItem,
  fetchAnalyzeSns,
  updateCsvSnsFavoriteItem,
  csvDownloadSns,
} from "../../slices/analyzeSnsListSlice";
import { validDatePickChecker, toMoneyString } from "../../utils/fnUtil";
import InfomationModal from "../../components/modal/infomationModal";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import "./style.scss";

const AnalyzeSnsList = () => {
  const dispatch = useDispatch();
  const {
    analyzeList,
    searchParams,
    total,
    selectColumsVisible,
    showFields,
    csvDownloadModalVisible,
    filterUserVisible,
    filterSnsVisible,
    filterList,
    favoriteItem,
    infomationModal,
    infomationTitle,
  } = useSelector((state) => state.analyzeSnsList);
  const pageTitle = "社員SNS別分析";
  const columns = [
    {
      title: () => (
        <>
          <span>社員 </span>
          <span onClick={createOnClikSort("user_name")}>
            {getSortIcon(formik.values.sort === "user_name_asc")}
          </span>
          &nbsp;&nbsp;
          <Icon
            component={
              filterList.user_info.length === 0
                ? ""
                : searchParams.user_code.length === 0
                ? Icons.FilterIcon
                : Icons.FilterUsedIcon
            }
            onClick={() => {
              dispatch(actions.openFilterUserModal());
            }}
          />
        </>
      ),
      key: "user_name",
      dataIndex: "user_name",
      align: "center",
      width: TableColumnWidth.userName,
      render: (userName, data) => (
        <span className="display-linebreak">
          {userName
            ? `${userName}\n${
                data?.user_nickname ? `(${data?.user_nickname})` : ""
              }`
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>SNS種別 </span>
          <span onClick={createOnClikSort("sns_type")}>
            {getSortIcon(formik.values.sort === "sns_type_asc")}
          </span>
          &nbsp;&nbsp;
          <Icon
            component={
              filterList.sns_type.length === 0
                ? ""
                : searchParams.sns_type.length === 0
                ? Icons.FilterIcon
                : Icons.FilterUsedIcon
            }
            onClick={() => {
              dispatch(actions.openFilterSnsModal());
            }}
          />
        </>
      ),
      key: "type",
      dataIndex: "type",
      align: "center",
      width: TableColumnWidth.analyzeSns.snsType,
      render: (val) => val || "-",
    },
    {
      title: () => (
        <>
          <span>売上 </span>
          <span onClick={createOnClikSort("sales_amount")}>
            {getSortIcon(formik.values.sort === "sales_amount_asc")}
          </span>
        </>
      ),
      key: "sales_amount",
      dataIndex: "sales_amount",
      label: "売上",
      align: "center",
      width: TableColumnWidth.earnings,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>件数 </span>
          <span onClick={createOnClikSort("sales_count")}>
            {getSortIcon(formik.values.sort === "sales_count_asc")}
          </span>
        </>
      ),
      key: "sales_count",
      dataIndex: "sales_count",
      label: "件数",
      align: "center",
      width: TableColumnWidth.orderCount,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>商品点数 </span>
          <span onClick={createOnClikSort("order_qty")}>
            {getSortIcon(formik.values.sort === "order_qty_asc")}
          </span>
        </>
      ),
      key: "order_qty",
      dataIndex: "order_qty",
      label: "商品点数",
      align: "center",
      width: TableColumnWidth.qty,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>セット率 </span>
          <span onClick={createOnClikSort("set_rate")}>
            {getSortIcon(formik.values.sort === "set_rate_asc")}
          </span>
        </>
      ),
      key: "set_rate",
      dataIndex: "set_rate",
      label: "セット率",
      align: "center",
      width: TableColumnWidth.setRate,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>客単価 </span>
          <span onClick={createOnClikSort("unit_price")}>
            {getSortIcon(formik.values.sort === "unit_price_asc")}
          </span>
        </>
      ),
      key: "customer_unit_price",
      dataIndex: "customer_unit_price",
      label:"客単価",
      align: "center",
      width: TableColumnWidth.unitPrice,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>Instagramフォロワー数 </span>
          <span onClick={createOnClikSort("insta_follower")}>
            {getSortIcon(formik.values.sort === "insta_follower_asc")}
          </span>
        </>
      ),
      key: "instagram_follower",
      dataIndex: "instagram_follower",
      align: "center",
      width: TableColumnWidth.analyzeSns.insta,
      render: (val) => toMoneyString(val) || "-",
    },
    {
      title: () => (
        <>
          <span>Twitterフォロワー数 </span>
          <span onClick={createOnClikSort("twitter_follower")}>
            {getSortIcon(formik.values.sort === "twitter_follower_asc")}
          </span>
        </>
      ),
      key: "twitter_follower",
      dataIndex: "twitter_follower",
      align: "center",
      width: TableColumnWidth.analyzeSns.twitter,
      render: (val) => toMoneyString(val) || "-",
    },
  ];
  const favoriteItemOptions = [
    { label: "社員CD", value: "user_cd" },
    { label: "商品点数", value: "order_qty" },
    { label: "社員名", value: "user_name" },
    { label: "セット率", value: "set_rate" },
    { label: "サイト表示名", value: "user_nickname" },
    { label: "客単価", value: "unit_price" },
    { label: "SNS種別", value: "sns_flag" },
    { label: "Instagram フォロワー数", value: "insta_follower" },
    { label: "売上", value: "sales" },
    { label: "Twitter フォロワー数", value: "twitter_follower" },
    { label: "件数", value: "count" },
  ];
  const displayColumnOptions = [
    { label: "社員", value: "user_name" },
    { label: "SNS種別", value: "sns_type" },
    { label: "売上", value: "sales_amount" },
    { label: "件数", value: "sales_count" },
    { label: "商品点数", value: "order_qty" },
    { label: "セット率", value: "set_rate" },
    { label: "客単価", value: "customer_unit_price" },
    { label: "Instagram フォロワー数", value: "instagram_follower" },
    { label: "Twitter フォロワー数", value: "twitter_follower" },
  ];

  const screenType = 6;

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: (values) =>
      dispatch(
        actions.saveSearchParam({
          start_date: analyzeDate.from?.format("YYYY-MM-DD"),
          end_date: analyzeDate.to?.format("YYYY-MM-DD"),
          user_code: [],
          sns_type: [],
        })
      ),
  });
  const [analyzeDate, setAnalyzeDate] = useState({ from: null, to: null });
  useEffect(() => {
    return () => dispatch(actions.clear());
  }, []);
  useEffect(() => {
    dispatch(fetchSnsFavoriteItem({ screen_type: screenType }));
    dispatch(actions.saveShowFields(columns));
  }, []);
  useUpdateEffect(() => {
    if (isValidFormVal(searchParams)) {
      dispatch(fetchAnalyzeSns(searchParams));
    } else {
      dispatch(actions.openInfomationModal("集計期間は必須です"));
    }
  }, [searchParams, dispatch]);
  useEffect(() => {
    dispatch(actions.saveShowFields(columns));
  }, [analyzeList]);
  const createOnClikSort = (field, customDesc, customAsc) => () => {
    const desc = customDesc ?? `${field}_desc`;
    const asc = customAsc ?? `${field}_asc`;
    const targetSort =
      formik.values.sort === desc
        ? asc
        : formik.values.sort === asc
        ? desc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const datePickerStyle = {
    backgroundColor: "white",
    fontSize: 16,
    height: 32,
    width: 120,
  };
  const getSortIcon = (sort) => {
    return sort ? (
      <>
        &nbsp;
        <Icon component={Icons.SortAscendingIcon} style={{ fontSize: 10 }} />
      </>
    ) : (
      <>
        &nbsp;
        <Icon component={Icons.SortDescendingIcon} style={{ fontSize: 10 }} />
      </>
    );
  };
  const isValidFormVal = (_searchParams) => {
    return _searchParams.start_date && _searchParams.end_date;
  };
  return (
    <div className="content-body">
      <SelectColumsModal
        title="表示する項目を選択してください"
        modalVisible={selectColumsVisible}
        closeModal={() => dispatch(actions.closeSelectColumsModal())}
        options={displayColumnOptions}
        onDisplay={(checkedList) => {
          dispatch(
            actions.saveShowFields(
              columns.filter((col) =>
                checkedList.some((checkedCol) => checkedCol === col.key)
              )
            )
          );
        }}
        chekedValues={displayColumnOptions.map((opt) => opt.value)}
        colSpan={12}
        initialize={false}
      />
      <SelectColumsModal
        title="絞り込む社員を選択してください"
        modalVisible={filterUserVisible}
        closeModal={() => dispatch(actions.closeFilterUserModal())}
        options={filterList.user_info
          .map((user) => {
            if (user?.user_code) {
              return {
                label: `${user?.user_code} ${user?.user_name || ""}${
                  user?.user_nickname ? `(${user?.user_nickname})` : ""
                }`,
                value: user?.user_code || "-",
              };
            }
          })
          .filter((e) => e)}
        onDisplay={(checkedList) => {
          dispatch(
            actions.saveSearchParam({
              user_code: checkedList,
            })
          );
        }}
        chekedValues={searchParams.user_code}
        colSpan={24}
      />
      <SelectColumsModal
        title="絞り込むSNSを選択してください"
        modalVisible={filterSnsVisible}
        closeModal={() => dispatch(actions.closeFilterSnsModal())}
        options={
          filterList.sns_type.map((sns) => {
            const snsValue = sns === 'Instagram' ? '0' : '1';
            if (snsValue) {
              return { label: sns, value: snsValue };
            }
          })
          .filter((e) => e)
        }
        onDisplay={(checkedList) => {
          dispatch(actions.saveSearchParam({ sns_type: checkedList }));
        }}
        chekedValues={searchParams.sns_type}
        colSpan={24}
      />
      <CsvDownloadModal
        modalVisible={csvDownloadModalVisible}
        options={favoriteItemOptions.map((opt) => {
          return { ...opt, colSpan: 12 };
        })}
        favoriteItem={favoriteItem}
        onFavoriteItem={(checkedList) => {
          let param = {
            screen_type: screenType,
            favorite: {},
          };
          favoriteItemOptions.forEach(
            (item) => (param.favorite[item.value] = "0")
          );
          checkedList.forEach((item) => (param.favorite[item] = "1"));
          dispatch(updateCsvSnsFavoriteItem(param));
        }}
        onClose={() => dispatch(actions.closeCsvDownloadModal())}
        onDownload={(checkedList) => {
          if (isValidFormVal(searchParams)) {
            dispatch(
              csvDownloadSns({
                screen_type: screenType,
                start_date: searchParams.start_date,
                end_date: searchParams.end_date,
                user_code: searchParams.user_code,
                inflow: searchParams.sns_type,
                columns: checkedList,
              })
            );
          } else {
            dispatch(actions.openInfomationModal("集計期間は必須です"));
          }
        }}
      />
      <InfomationModal
        modalVisible={infomationModal}
        item={{ information_title: infomationTitle }}
        setModalVisible={() => {
          dispatch(actions.closeInfomationModal());
        }}
      />
      <Header />
      <div className="analyzeSnsList-container nf-page">
        <h2>{pageTitle}</h2>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <div className="nf-input-wrapper nf-input-wrapper-middle">
              <label class="nf-input-label text-align-center nf-analyze-required">
                集計期間
              </label>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={analyzeDate.from}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "from",
                    analyzeDate,
                    setAnalyzeDate
                  );
                }}
              />
              <span style={{ width: 40 }}>　〜　</span>
              <DatePicker
                size="small"
                format={"YYYY.MM.DD"}
                placeholder=""
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                style={datePickerStyle}
                value={analyzeDate.to}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                onChange={(val, dateString) => {
                  validDatePickChecker(
                    dateString,
                    "to",
                    analyzeDate,
                    setAnalyzeDate
                  );
                }}
              />
            </div>
          </div>
          <Button
            text="検索"
            type="submit"
            disabled={!analyzeDate.from || !analyzeDate.to}
          />
        </form>
        <div className="table-header">
          <div className="top-header">
            <Button
              text="CSV出力"
              width="wider"
              style={{ display: "block", marginBottom: 5, marginLeft: "auto" }}
              onClick={() => dispatch(actions.openCsvDownloadModal())}
            />
          </div>
          <div className="bottom-header">
            <h3>検索結果一覧</h3>
            <div style={{ display: "flex" }}>
              <Button
                text="フィルター解除"
                theme="white"
                width="wider"
                disabled={
                  analyzeList?.length === 0
                  && searchParams?.user_code?.length === 0
                  && searchParams?.sns_type?.length === 0
                }
                onClick={() => {
                  dispatch(
                    actions.saveSearchParam({
                      user_code: [],
                      sns_type: [],
                    })
                  );
                }}
              />
              <Button
                text="表示切替"
                width="wider"
                style={{
                  backgroundColor: "#ffffff",
                  border: "none",
                  color: "black",
                  marginLeft: 15,
                }}
                onClick={() => {
                  dispatch(actions.openSelectColumsModal());
                }}
              />
            </div>
          </div>
        </div>
        <Table
          columns={showFields}
          dataSource={analyzeList}
          onChange={(pagination) => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{offsetHeader: 60}}
        />
      </div>
      <Footer />
    </div>
  );
};

export default AnalyzeSnsList;

import { Modal } from "antd";
import Button from "../../button";
import classNames from "classnames";
import "./style.scss";

const CommonModal = ({
  modalVisible,
  text,
  noText = "閉じる",
  okText,
  tip,
  onClose,
  onOk,
}) => {
  return (
    <Modal
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      width={500}
      onCancel={onClose}
      zIndex={9999}
    >
      <div className="common-modal">
        <div className={classNames("message")}>
          <span className="display-linebreak">{text}</span>
          {tip && <span className="tip">{tip}</span>}
        </div>
        <div>
          <Button
            theme={okText ? "white" : "black"}
            text={noText}
            onClick={onClose}
          />
          {onOk && <Button text={okText} onClick={onOk} />}
        </div>
      </div>
    </Modal>
  );
};

export default CommonModal;

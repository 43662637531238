import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ja_JP from "antd/es/locale/ja_JP";
import moment from "moment";
import "moment/locale/ja";
moment.locale("ja");
ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={ja_JP}>
      <DndProvider backend={HTML5Backend}>
        <App />
      </DndProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { actions as messageActions } from "../slices/messageSlice";
import { actions as loadingActions } from "./loadingSlice";
import axios from "../utils/axiosUtil";

export const ProductInit = createAsyncThunk(
  "ProductInit", 
    async (params, {dispatch}) => {
    dispatch(loadingActions.multiLoadingOn());
    const [productsRes, brandsRes, categoryRes, subCategoryRes] = await Promise.all([
      axios.get("/product/list"),
      axios.get("brands/list",{params: { mall_flag: 0, count: 0, sort : "brand_name_asc" }}),
      axios.get("/product/category/list"),
      axios.get("/product/subcategory/list"),
    ]);
    dispatch(loadingActions.multiLoadingOff());
    return {
      productList: productsRes?.item ?? [],
      total: productsRes?.total ?? 0,
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
      categoryList: (categoryRes.item ?? []).map(c => ({
          ...c,
          label: c.category_name,
          value: c.category_code,
        })
      ), 
      subCategoryList: (subCategoryRes.item ?? []).map(d => ({
          ...d,
          label: d.sub_category_name,
          value: d.sub_category_code,
        })
      ),  
    }
  }
);

export const ProductRewardInit = createAsyncThunk(
  "ProductRewardInit",
  async () => {
    const [productsRewardRes, brandsRes] = await Promise.all([
      axios.get("/product/list",{params:{sort:"brand_code_desc",count:10}}),
      axios.get("/brands/list",{params: { mall_flag: 0, count: 0, sort : "brand_name_asc" }}),
    ]);
    return {
      productRewardList: productsRewardRes?.item ?? [],
      total: productsRewardRes?.total ?? 0,
      brands: (brandsRes?.item ?? []).map(b => ({
        ...b,
        label: b.brand_name,
        value: b.brand_code,
      })),
    }
  }
);
export const fetchProducts = createAsyncThunk(
  "fetchProducts",
  async params => await axios.get("/product/list", { params })
);
export const fetchRewardProducts = createAsyncThunk(
  "fetchRewardProducts",
  async params => await axios.get("/product/list", { params })
);
export const updateProduct = createAsyncThunk(
  "updateProduct",
  async params => await axios.post("/product/update", params)
);

export const UpdateMstProduct = createAsyncThunk(
  "UpdateMstProduct",
  async (params, {dispatch}) => {
    dispatch(loadingActions.multiLoadingOn());
    var skipFlg = false;
    var errorIndex = -1;
    for (const data of params.updateProductList) {
      if(!skipFlg){
        const shopUpdatRes = await axios.post("/product/update", {
          update_screen: "MstProduct",
          division: params.updateMode,
          product_code : data?.product_code,
          color_code: data?.color_code,
          color_name: data?.color_name,
          size_code: data?.size_code,
          size_name: data?.size_name,
          origin_sku_code: data?.origin_sku_code,
          sku_code: data?.sku_code,
          jan_code: data?.jan_code,
          barcode: data?.barcode,
          brand_code: data?.brand_code,
          product_name: data?.product_name,
          product_description: data?.product_description,
          category_code: data?.category_code,
          category_name: data?.category_name,
          sub_category_code: data?.sub_category_code,
          sub_category_name: data?.sub_category_name,
          price: data?.price,
          selling_price: data?.selling_price,
          tax_rate: data?.tax_rate,
          product_url: data?.product_url,
          image_url: data?.image_url,
          cart_url: data?.cart_url,
          release_date: data?.release_date,
        })
        if(shopUpdatRes?.code !== "0"){
          skipFlg = true;  
          errorIndex = data.key;
          dispatch(actions.saveError(errorIndex));
        }
      }
    }
    const productsRes = await axios.get("/product/list", params.searchParams)
    dispatch(loadingActions.multiLoadingOff());
    return {
      errorIndex: errorIndex,
      // productList: productsRes?.item ?? [],
      // total: productsRes?.total ?? 0,
    };
  }
);

const initialSearchParams = {
  brand_code: [],
  product_code:"",
  sku_code: "",
  jan_code: "",
  product_name:"",
  from_incentive_rate:0,
  to_incentive_rate:100,
  count:10,
  offset: 0,
  deleted_flg: 0,
  sort: "product_code_desc",
  start_date: null,
  end_date: null
};

const initialUpdateParams = {
  division : "update",
  brand_code : "",
  product_code : "",
  color_code : "",
  size_code : "",
  update_incentive_rate:0,
  allUpdateButtonStatus : false,
  selectUpdateButtonStatus : false,
  changeUpdateButtonStatus : false,
  selectedRowKeys:[],
  updateProductList:[],
  workProductList:[],
};
const initialState = {
  selectedBrand: null,
  searchParams: initialSearchParams,
  searchRewardParams: { ...initialSearchParams, sort: "brand_code_desc"},
  productList: [],
  categoryList: [],
  subCategoryList: [],
  productRewardList: [],
  productFileUploadModalVisible:false,
  productDeleteModalVisible:false,
  productUpdateModalVisible:false,
  updateList:[],
  updateUniformList:[],
  updateParams:initialUpdateParams,
  selectRate: Array.from(new Array(101)).map((v,i) => {return {label:i.toString(),value:i.toString()} }),
  total: 0,
  incentive_rate: 1,
  errorIndex : -10
}

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clear: () => initialState,
    saveBrand: (state, { payload }) => ({
      ...state,
      selectedBrand: payload,
    }),
    saveSearchParam: (state, { payload }) => ({
      ...state,
      searchParams: {
        ...state.searchParams,
        ...payload,
        offset: 0,
      },
    }),
    saveOffset: (state, { payload }) => {
      state.searchParams["offset"] = payload;
    },
    saveProductList: (state, { payload }) => ({
      ...state,
      productList : payload,
    }),
    saveError: (state, { payload }) => ({
      ...state,
      errorIndex : payload,
    }),
    fileUploadModal: (state, { payload }) => ({
      ...state,
      productFileUploadModalVisible: true,
    }),
    closeFileUploadModal: (state, { payload }) => ({
      ...state,
      productFileUploadModalVisible: false,
    }),
    openUpdateModal: (state, { payload }) => ({
      ...state,
      productUpdateModalVisible: true,
    }),
    closeUpdateModal: (state, { payload }) => ({
      ...state,
      productUpdateModalVisible: false,
    }),
    openDeleteModal: (state, { payload }) => ({
      ...state,
      productDeleteModalVisible: true,
    }),
    closeDeleteModal: (state, { payload }) => ({
      ...state,
      productDeleteModalVisible: false,
    }),
    saveSearchRewardParam: (state, { payload }) => ({
      ...state,
      searchRewardParams: {
        ...state.searchRewardParams,
        ...payload,
        offset: 0,
      },
    }),
    saveRewardOffset: (state, { payload }) => {
      state.searchRewardParams["offset"] = payload;
    },

  },
  extraReducers: builder => {
    builder.addCase(ProductInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
      // productList: payload?.item ?? [],
      // total: payload?.total,
      updateParams:initialUpdateParams,
    }));
    builder.addCase(ProductRewardInit.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
      updateParams:initialUpdateParams,
    }));
    builder.addCase(fetchRewardProducts.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
      productRewardList: payload?.item ?? [],
      total: payload?.total,
      updateParams:initialUpdateParams,
    }));
    
    builder.addCase(fetchProducts.fulfilled, (state, { payload }) => ({
      ...state,
      productList: payload?.item ?? [],
      total: payload?.total,
      updateParams:initialUpdateParams,
    }));
    builder.addCase(updateProduct.fulfilled, (state, { payload }) => ({
      ...state,
      ...payload,
      searchParams: { ...state.searchParams },
      searchRewardParams: { ...state.searchRewardParams },
      productUpdateModalVisible: false,
    }));
   
    builder.addCase(UpdateMstProduct.fulfilled, (state, { payload }) => ({
      ...state,
      searchParams: { ...state.searchParams },
      errorIndex: payload.errorIndex,
      productUpdateModalVisible: false,
      productDeleteModalVisible: false,
    }));
  },
});

export const actions = productSlice.actions;

export default productSlice.reducer;

import { useEffect } from "react";
import { useFormik } from "formik";
import { Table, Image } from "antd";
import { useLocation } from "react-router";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  movieListInit,
  fetchMovieDetail,
  fetchMovieList,
  deleteLive as deleteLiveRequest,
} from "../../slices/movieSlice";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import StatusBox from "../../components/statusBox";
import GenernalInput from "../../components/generalInput";
import DeleteModal from "../../components/modal/deleteModal";
import MovieSignUpModal from "../../components/modal/movieSignUpModal";
import MovieEditModal from "../../components/modal/movieEditModal";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import moment from "moment";
import "./style.scss";

const MOVIE_HEADER_STATUS = [
  {
    status: "EC公開前",
    value: "20",
    searchParam: "20",
  },
  {
    status: "EC公開中",
    value: "21",
    searchParam: "21",
  },
];

const MOVIE_STATUS = [
  {
    status: "EC公開前",
    value: "20",
  },
  {
    status: "EC公開中",
    value: "21",
  },
  {
    status: "公開終了",
    value: "22",
  },
];

const MovieList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const account = useSelector(state => state.account);

  const {
    shops,
    brands,
    liveList,
    total,
    statusAggregate,
    deleteModalVisible,
    deleteLive = {},
    searchParams,
  } = useSelector(state => state.movie);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => dispatch(actions.saveSearchParam(values)),
  });

  useEffect(() => {
    dispatch(movieListInit(location.state));
    return () => dispatch(actions.clear());
  }, [dispatch]);

  useUpdateEffect(
    () => dispatch(fetchMovieList(searchParams)),
    [searchParams, dispatch]
  );

  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const columns = [
    {
      title: () => (
        <>
          <span>配信CD </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("live_code")}
          >
            {formik.values.sort === "live_code_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "live_code",
      key: "live_code",
      align: "center",
      width: TableColumnWidth.live.cd,
    },
    {
      title: "サムネイル画像",
      dataIndex: "title_image",
      key: "title_image",
      render: image =>
        image ? (
          <Image src={image} width={100} height={100} />
        ) : (
          <Icon component={Icons.NoImgaeSquare1} style={{ fontSize: 100 }} />
        ),
      align: "center",
      width: TableColumnWidth.image,
    },
    {
      title: () => (
        <>
          <span>タイトル </span>
          <span className="sort-span" onClick={createOnClikSort("live_title")}>
            {formik.values.sort === "live_title_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: TableColumnWidth.live.title,
    },
    {
      title: () => (
        <>
          <span>出演者 </span>
          <span className="sort-span" onClick={createOnClikSort("user_name")}>
            {formik.values.sort === "user_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      align: "center",
      key: "user_name",
      width: TableColumnWidth.userName,
      render: live => (
        <span className="display-linebreak">
          {live?.streamer_info?.[0]?.user_name
            ? `${live?.streamer_info?.[0]?.user_name}\n${
                live?.streamer_info?.[0]?.name_nickname ? `(${live?.streamer_info?.[0]?.name_nickname})` : ""
              }`
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>店舗 </span>
          <span className="sort-span" onClick={createOnClikSort("shop_name")}>
            {formik.values.sort === "shop_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "shop_name",
      align: "center",
      width: TableColumnWidth.shopName,
      render: live => live?.streamer_info?.[0]?.shop_name || "-",
    },
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {formik.values.sort === "brand_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "brand_name",
      align: "center",
      width: TableColumnWidth.brandName,
      render: live => live?.streamer_info?.[0]?.brand_name || "-",
    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("status")}>
            {formik.values.sort === "status_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      align: "center",
      width: TableColumnWidth.status,
      render: ({ status_flag, delete_flag })=> (
        <span className="display-linebreak">
          {delete_flag == 1 ? "削除済み" : (MOVIE_STATUS.find(s => s.value === status_flag)?.status || "-")}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>公開日時 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("opening_datetime")}
          >
            {formik.values.sort === "opening_datetime_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "opening_time",
      dataIndex: "opening_time",
      align: "center",
      width: TableColumnWidth.dateTime,
      render: opening_time => (
        <span className="display-linebreak">
          {opening_time
            ? `${moment(opening_time).format("YYYY.MM.DD")}\n${moment(
                opening_time
              ).format("HH:mm")}`
            : "-"}
        </span>
      ),
    },
    {
      title: "編集",
      align: "center",
      width: TableColumnWidth.icon,
      render: current => (
        <Icon
         component={current.delete_flag == 1 ? Icons.DisableEditIcon : Icons.EditIcon}
          style={{ fontSize: 25 }}
          disabled={current.delete_flag == 1 }
          onClick={() => {
            if (current.delete_flag == 1 ) return;
            dispatch(
              fetchMovieDetail({
                live_code: current.live_code,
                brand_code: current.brand_code,
                live_flag: "2",
              })
            );
          }}
        />
      ),
    },
    {
      title: "削除",
      align: "center",
      width: TableColumnWidth.icon,
      render: current => (
        <Icon
          component={current.delete_flag == 1 ? Icons.DisableReymoveIcon : Icons.RemoveIcon}
          style={{ fontSize: 25 }}
          disabled={current.delete_flag == 1 }
          onClick={() => {
            if (current.delete_flag == 1 ) return;
            dispatch(actions.openDeleteModal(current));
          }}
        />
      ),
    },
  ];
  return (
    <div className="content-body">
      <Header />
      <div className="movie-container nf-page">
        <h2>動画一覧</h2>
        <div className="status-box-area">
          {MOVIE_HEADER_STATUS.map((s, i) => (
            <StatusBox
              status={s.status}
              count={statusAggregate?.[s.value]}
              key={i}
              onClick={() => dispatch(actions.refineSearch(s.searchParam))}
            />
          ))}
        </div>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <GenernalInput
              label="配信CD"
              placeholder="配信CDを入力してください"
              formik={formik}
              name={"live_code"}
            />
            <GenernalInput
              label="タイトル"
              placeholder="タイトルを入力してください"
              formik={formik}
              name={"title"}
            />
            <GenernalInput
              label="出演者"
              placeholder="社員名またはサイト表示名で入力してください"
              formik={formik}
              name={"user_name"}
            />
            <GenernalInput
              label="店舗"
              mode="multiple"
              name="shop_code"
              placeholder={"店舗を選択してください"}
              formik={formik}
              options={shops}
            />
            <GenernalInput
              label="ブランド"
              mode="multiple"
              name="brand_code"
              placeholder={"ブランドを選択してください"}
              formik={formik}
              options={brands}
            />
          </div>
          <Button text="検索" type="submit" />
        </form>
        <div className="table-header">
          <h3>検索結果一覧</h3>
          {account?.live_flag == 1 && <Button
            text="新規作成"
            theme="white"
            width="wider"
            onClick={() => dispatch(actions.openSignUpModal())}
          />}
        </div>

        <Table
          columns={columns}
          dataSource={liveList}
          onChange={pagination => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{ offsetHeader: 60 }}
        />
      </div>
      <MovieEditModal />
      <MovieSignUpModal />
      <DeleteModal
        modalVisible={deleteModalVisible}
        onCancel={() => dispatch(actions.closeDeleteModal())}
        item={"配信CD"}
        itemValue={deleteLive.live_code}
        onOk={() => {
          dispatch(
            deleteLiveRequest({
              division: "delete",
              live_code: deleteLive.live_code,
              live_flag: deleteLive.live_flag,
            })
          );
        }}
      />
      <Footer />
    </div>
  );
};

export default MovieList;

import classNames from "classnames";
import "./style.scss";

const GeneralCheckbox = ({
  label,
  onChange,
  checked,
  className,
  disabled = false,
}) => {
  return (
    <div
      className={classNames("nf-checkbox-wrapper", className, {
        "nf-checkbox-disabled": disabled,
      })}
    >
      <div>
        <label
          className={classNames("nf-checkbox-label", {
            "nf-checkbox-active": checked,
          })}
        >
          {label}
          <input
            className="nf-checkbox"
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
        </label>
      </div>
    </div>
  );
};

export default GeneralCheckbox;

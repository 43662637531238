import "./style.scss";
import noimage from "../../images/noimage.png";
import { toMoneyString, MoneyFormatString } from "../../utils/fnUtil";
import classNames from "classnames";

/* 社員実績　サマリー */

const StaffResultSummary = ({
  datas,
  rankType,
  userAmount,
}) => {
  const wrapperClassName = (length, sub=true, bold) => {
    var workLength = parseInt((length === 1 ? 1 : length === 2 ? 2 : (length/2)+1) + (sub ? 2 : 0))
    var fontSize = "xxxl"
    switch(workLength){
      case 1:
        fontSize = "xxxl"
        break;
      case 2:
        fontSize = "xl"
        break;
      case 3:
        fontSize = "lg"
        break;
      case 4:
        fontSize = "sm"
        break;
      case 5:
        fontSize = "ssm"
        break;
      case 6:
        fontSize = "sssm"
        break;
    }
    return classNames(bold && "font-weight-bold", {
    [`font-size-${fontSize}`]: fontSize,
  })};

  const getRankingCard = (analyzeType, item, user_amount) => {
    return (
      <div className="srs-rankingcard">
        <div className="srs-no">
          {item?.rank && user_amount && item?.rank <= user_amount ? (
            <>
              <span className={ wrapperClassName(String(item?.rank ?? 1).length, false, true)}>
                {item?.rank}
              </span>
              <span className={ wrapperClassName(String(user_amount ?? 1).length)}>/{user_amount}位</span>
            </>
          ) : (
            <span className="font-size-lg">圏外</span>
          )}
        </div>
        <p className="font-size-ssm margin-bottom-3">
          {analyzeType === "corp" ? "社内" : ""}
          {analyzeType === "brand" ? "ブランド内" : ""}
          {analyzeType === "shop" ? "店舗内" : ""}順位
        </p>
        <p>
          <span className="font-size-ssm">{item?.rank && item?.rank !== 1 && '次の順位まで'}</span>
          {"　"}
          <span
            className="font-weight-bold font-size-ssm"
            style={{ overflow: "hidden" }}
          >
            {item?.rank && item?.rank !== 1 && rankType === "sales" ? "￥" : ""}
            {item?.rank && item?.rank !== 1 && toMoneyString(item?.prev_rank_gap) || ""}
          </span>
        </p>
        <p className="srs-detail margin-bottom-3 border-bottom">
          <span className="font-weight-bold">
            {" "}
            {analyzeType === "corp" ? "社内" : ""}
            {analyzeType === "brand" ? "ブランド内" : ""}
            {analyzeType === "shop" ? "店舗内" : ""}平均
          </span>
          {"　"}
          <span className="font-weight-bold">平均との差</span>
        </p>
        <p className="srs-detail">
          <span>
            {item && rankType === "sales" ? "￥" : ""}
            {toMoneyString(item?.average) || "-"}
          </span>
          {"　"}
          <span
            className={Math.sign(item?.average_gap) >= 0 ? "" : "color-red"}
          >
            {item && rankType === "sales"
              ? MoneyFormatString(toMoneyString(item?.average_gap)) || "-"
              : toMoneyString(item?.average_gap) || "-"
            }
          </span>
        </p>
      </div>
    );
  };

  const createAchievements = (data, label) => {
    return (
      <>
        <span className="font-weight-bold">
          {data.title}
        </span>
        <span className="srs-label font-size-ssm">{label}</span>
        <span className="font-weight-bold">
          {(data.amount || data.amount == 0) && rankType === "sales" ? "￥" : ""}
          {toMoneyString(data.amount) || "-"}
        </span>
        <div className="srs-rankCardsArea" style={{ marginBottom: 30 }}>
          {getRankingCard("corp", data.corp, userAmount.corp)}
          {getRankingCard("brand", data.brand, userAmount.brand)}
          {getRankingCard("shop", data.shop, userAmount.shop)}
        </div>
      </>
    )
  };

  return (
    <div className="staffResultSummary-area">
      <span className="srs-summaryType">
        <div>
          {rankType === "sales" ? "売上" : ""}
          {rankType === "like" ? "いいね数" : ""}
          {rankType === "pv" ? "PV数/視聴者数/再生回数" : ""}
        </div>
      </span>
      <div className="srs-containts" style={{ marginTop: 30 }}>
        {datas?.map(e => createAchievements(e, e?.label || "実績"))}
      </div>
    </div>
  );
};

export default StaffResultSummary;

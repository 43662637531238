import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./style.scss";
import axios from "axios";

const Privacy = () => {
  const [html, setHTML] = useState("");
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_POLICY_URL}?timestamp=${new Date().getTime()}`)
      .then(e => setHTML(e.data))
      .catch(() => setHTML("読み込みに失敗しました"));
  }, []);

  return (
    <>
      <div className="content-body">
        <Header />
        <div className="privacy-container nf-page">
          <div class="inner">
            <div dangerouslySetInnerHTML={ { __html: html } } />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Privacy;

import { Modal } from "antd";
import Button from "../../button";
import "./style.scss";

/* お知らせモーダル */

const InfomationModal = ({
  modalVisible,
  setModalVisible,
  item,
  page,
  onClick = () => setModalVisible(false),
}) => {
  return (
    <Modal
      className={"nf-modal-infomation"}
      visible={modalVisible}
      centered
      footer={null}
      closable={false}
      maskClosable={true}
      width={400}
      onCancel={() => {
        setModalVisible(false);
      }}
    >
      <div className="nf-modal-infomation-content">
        <div className="nf-modal-infomation-title">
          {item.information_title}
        </div>
        <div className="nf-modal-infomation-date">{item.date}</div>
        <div
          className="nf-modal-infomation-contens"
          style={page === "top" ? { overflowX: "auto" } : {}}
          dangerouslySetInnerHTML={{__html:item.contents}}
        >
        </div>
        <Button
          text="閉じる"
          onClick={onClick}
          style={{
            width: 140,
            height: 30,
            fontSize: 14,
            marginTop: 40,
          }}
        />
      </div>
    </Modal>
  );
};

export default InfomationModal;

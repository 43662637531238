import "./style.scss";
import { Popover } from "antd";
import React, { useState } from "react";
import Humberger from "../humberger";
import Button from "../button";
import Loading from "../loading";
import ChangePasswordModal from "../modal/changePasswordModal";
import PersonalIcon from "../personalIcon";
import LogoutModal from "../modal/logoutModal";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../slices/accountSlice";
import { useHistory, useLocation } from "react-router-dom";
import StaffEditModal from "../modal/staffEditModal";
import { fetchStaffDetail, fetchShops } from "../../slices/staffSlice";
import Icons from "../../constants/Icons";
import Icon from "@ant-design/icons";

const Header = minWidth => {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [changePasswordModalVisible, setchangePasswordModalVisible] =
    useState(false);
  const dispatch = useDispatch();
  const account = useSelector(state => state.account);
  const history = useHistory();
  const location = useLocation();
  const authNames = [
    "システム管理責任者",
    "管理責任者",
    "ブランド責任者",
    "店舗責任者",
    "スタッフ",
  ];

  // ロゴ判断
  const os = require('os');
  const logoImage = os.hostname() === process.env.REACT_APP_W2_DOMAIN ? Icons.LogoOnlineservicew2 : Icons.LogoOnlineservice;

  return (
    <>
      <Loading />
      <ChangePasswordModal
        modalVisible={changePasswordModalVisible}
        setModalVisible={setchangePasswordModalVisible}
        login_type="1"
      />
      <LogoutModal
        modalVisible={logoutModalVisible}
        onCancel={() => setLogoutModalVisible(false)}
        onOk={() => {
          dispatch(
            logout({
              request_from: "2",
              operator_code: account?.user_code,
              token: account?.token,
            })
          ).then(() => {
            setLogoutModalVisible(false);
            history.push({
              pathname: "/",
            });
          });
        }}
      />
      {location.pathname !== "/staff" && <StaffEditModal />}
      <header className="header" style={minWidth}>
        <div className="header-left">
          <Humberger />
          <div className="header-logo">
            <Icon
              component={logoImage}
              className={"logo-image display-icon"}
            />
          </div>
          <div
            className="header-logo"
            style={{ cursor: 'pointer', position: 'absolute', left: '65px' }}
            onClick={() => history.push('/')} />
        </div>
        <div className="header-right">
          <PersonalIcon
            onClick={() => {
              dispatch(fetchShops());
              dispatch(fetchStaffDetail({
                  user_code: account?.user_code,
                  authMenuInvisible: true,
              }));
            }}
            imgSrc={account?.profile_image_url}
            mask={true}
            maskText="編集"
            width={50}
            height={50}
            maskTop="8px"
          />
          <Popover
            content={
              <div className="header-personal-popover">
                <table>
                  <tr>
                    <td>権限</td>
                    <td>{authNames[account?.user_auth]}</td>
                  </tr>
                  <tr>
                    <td>ブランド</td>
                    <td>
                      {account?.brand_name?.map(brand => (
                        <>
                          {brand}
                          <br />
                        </>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>店舗</td>
                    <td>{account.shop_name}</td>
                  </tr>
                </table>
                <Button
                  text="パスワード変更"
                  style={{
                    width: 200,
                    margin: "10px auto",
                    display: "block",
                  }}
                  onClick={() => {
                    setchangePasswordModalVisible(true);
                  }}
                />
                <div
                  className="header-logoutButton"
                  onClick={() => setLogoutModalVisible(true)}
                >
                  ログアウト
                </div>
              </div>
            }
            getPopupContainer={trigger => trigger.parentElement}
          >
            <div className={"header-personal-info"}>
              <span>{account?.corporation_name}</span>
              <br />
              <span>{account?.user_name}</span>
            </div>
          </Popover>
        </div>
      </header>
    </>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./style.scss";
import {actions, topInit, topScroll} from "../../slices/topSlice";
import StatusBox from "../../components/statusBox";
import SalesContentsResultCard from "../../components/salesResult/salesContentsResultCard";
import SalesSnsResultCard from "../../components/salesResult/salesSnsResultCard";
import SalesLiveResultCard from "../../components/salesResult/salesLiveResultCard";
import SalesArchiveDailyResultCard from "../../components/salesResult/salesArchiveDailyResultCard";
import SalesLiveResultDoubleCard from "../../components/salesResult/salesLiveResultDoubleCard";
import InfomationModal from "../../components/modal/infomationModal";
import RankingCards from "../../components/rankingCards";
import LiveRankingCards from "../../components/liveRankingCards";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const Top = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    infomationData,
    statusAggregate,
    contentsDaily,
    contentsMonthly,
    instaDaily,
    instaMonthly,
    twDaily,
    twMonthly,
    liveMonthly,
    archiveDaily,
    archiveMonthly,
    movieDaily,
    movieMonthly,
    coordinateRank,
    blogRank,
    instaRank,
    twRank,
    liveRank,
    archiveRank,
    movieRank,
  } = useSelector((state) => state.top);
  const account = useSelector((state) => state.account);
  const initParams = {
    userAuth: account?.user_auth,
    analyze: {
      start_date: moment().add('days',-1).startOf("month").format("YYYY-MM-DD"),
      end_date: moment().add('days',-1).format("YYYY-MM-DD"),
      count: 3,
      image_height: 80,
    },
  };
  const [addSerach, setAddSerach] = useState(false);
  const toggleVisibility = () => {
    window.scrollY > 500 && setAddSerach(true);
  }

  useEffect(() => {
    dispatch(topInit(initParams));
  }, []);
  useEffect(()=>{
    window.addEventListener('scroll',toggleVisibility)
    return () => window.removeEventListener('scroll',toggleVisibility)
  },[]);

  useEffect(()=>{
    if(addSerach){
      dispatch(topScroll(initParams));
    }
  },[addSerach]);

  //hook定義
  const [infomationModalVisible, setInfomationModalVisible] = useState(false);
  const [infomationModalItem, setInfomationModalMsg] = useState({});
  const getInfomationJsx = () => {
    let jsx = [];
    for (let i = 0; i < 3; i++) {
      jsx.push(
        <>
          <div
            onClick={() => {
              if (infomationData[i]) {
                let date = "";
                if (infomationData[i]?.date) {
                  date = moment(infomationData[i].date).format("YYYY.MM.DD");
                }

                setInfomationModalVisible(true);
                setInfomationModalMsg({ ...infomationData[i], date: date });
              }
            }}
          >
            <span className="imfomation-date">
              {infomationData[i]?.date
                ? moment(infomationData[i].date).format("YYYY.MM.DD")
                : "-"}
            </span>
            <span className="imfomation-title">
              {infomationData[i]?.information_title || "----"}
            </span>
          </div>
        </>
      );
    }
    infomationData.forEach((info, i) => {
      if (i >= 3 && i < 10) {
        jsx.push(
          <>
            <div
              onClick={() => {
                setInfomationModalVisible(true);
                setInfomationModalMsg(infomationData[i]);
              }}
            >
              <span className="imfomation-date">
                {infomationData[i]?.date
                  ? moment(infomationData[i].date).format("YYYY.MM.DD")
                  : "-"}
              </span>
              <span className="imfomation-title">
                {infomationData[i]?.information_title || "----"}
              </span>
            </div>
          </>
        );
      }
    });
    return jsx;
  };
  const rankingDate = () => {
    const startDate = moment().startOf("month").format("YYYY.MM.DD");
    const endDate = moment().add(moment().date() > 1 ? -1 : 0, 'd').format("YYYY.MM.DD");
    return `${startDate}-${endDate}`;
  };
  return (
    <div className="content-body">
      <Header />
      <div className="nf-page">
        <InfomationModal
          modalVisible={infomationModalVisible}
          setModalVisible={setInfomationModalVisible}
          item={infomationModalItem}
          page="top"
        />
        <div className="top-container">
          {/* お知らせ */}
          {infomationData?.length > 0 && (
            <div className="infomation">
              <h2>お知らせ</h2>
              <div className="infomation-box infomation-scroll">{getInfomationJsx()}</div>
            </div>
          )}
          {/* 承認ステータス */}
          <div className="status">
            {account?.user_auth === "0" ||
            account?.user_auth === "1" ||
            account?.user_auth === "2" ||
            account?.user_auth === "3" ? (
              <div className="status-item">
                <h2>コーディネート</h2>
                <div className="status-box-area">
                  <StatusBox
                    status={"承認待ち"}
                    topStyle={true}
                    count={statusAggregate?.coordinate?.["1"]}
                    warning={statusAggregate?.coordinate?.["1"] > 0}
                    onClick={() => {
                      history.push({
                        pathname: "/coordinate",
                        state: { status_code: 1 },
                      });
                    }}
                  />
                  <div style={{ width: 10 }}></div>
                  <StatusBox
                    status={"承認済み(未公開)"}
                    topStyle={true}
                    count={statusAggregate?.coordinate?.["3"]}
                    warning={statusAggregate?.coordinate?.["3"] > 0}
                    onClick={() => {
                      history.push({
                        pathname: "/coordinate",
                        state: { status_code: 3 },
                      });
                    }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ maxWidth: "380px", flex: 1 }}></div>
            )}
            {account?.user_auth === "0" ||
            account?.user_auth === "1" ||
            account?.user_auth === "2" ||
            account?.user_auth === "3" ? (
              <div className="status-item">
                <h2>ブログ</h2>
                <div className="status-box-area">
                  <StatusBox
                    status={"承認待ち"}
                    topStyle={true}
                    count={statusAggregate?.article?.["1"]}
                    warning={statusAggregate?.article?.["1"] > 0}
                    onClick={() => {
                      history.push({
                        pathname: "/articles",
                        state: { status_code: 1 },
                      });
                    }}
                  />
                  <div style={{ width: 10 }}></div>
                  <StatusBox
                    status={"承認済み(未公開)"}
                    topStyle={true}
                    count={statusAggregate?.article?.["3"]}
                    warning={statusAggregate?.article?.["3"] > 0}
                    onClick={() => {
                      history.push({
                        pathname: "/articles",
                        state: { status_code: 3 },
                      });
                    }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ maxWidth: "380px", flex: 1 }}></div>
            )}
            {account?.user_auth === "0" ||
            account?.user_auth === "1" ||
            account?.user_auth === "2" ? (
              <div className="status-item">
                <h2>タグ</h2>
                <div className="status-box-area">
                  <StatusBox
                    status={"承認待ち"}
                    topStyle={true}
                    count={parseInt(statusAggregate?.tag?.unapproved ?? 0)}
                    warning={parseInt(statusAggregate?.tag?.unapproved ?? 0) > 0}
                    onClick={() => {
                      history.push({
                        pathname: "/tag",
                        state: { status_code: '0' },
                      });
                    }}
                  />
                  <div style={{ width: 10 }}></div>
                  <StatusBox
                    status={"承認済み(未公開)"}
                    topStyle={true}
                    count={statusAggregate?.tag?.unpublised}
                    warning={parseInt(statusAggregate?.tag?.unpublised ?? 0) > 0}
                    onClick={() => {
                      history.push({
                        pathname: "/tag",
                        state: { status_code: '1' },
                      });
                    }}
                  />
                </div>
              </div>
            ) : (
              <div style={{ maxWidth: "380px", flex: 1 }}></div>
            )}
          </div>
          {/* 売上実績 */}
          <div className="sales-result">
            <h2>売上実績</h2>
            <div className="sales-result-header">コンテンツ</div>
            <div className="sales-contents-area">
              <SalesContentsResultCard data={contentsDaily} type="daily" />
              <SalesContentsResultCard data={contentsMonthly} type="monthly" />
            </div>
            <div className="sales-result-header">ライブ配信</div>
            <div className="sales-contents-area">
              <SalesArchiveDailyResultCard data={archiveDaily} title="アーカイブ" />
              <SalesLiveResultDoubleCard leftdata={archiveMonthly} rightdata={liveMonthly} lefttitle="アーカイブ" righttitle="オンラインライブ" />
            </div>
            <div className="sales-result-header">動画</div>
            <div className="sales-contents-area">
              <SalesLiveResultCard data={movieDaily} type="daily" />
              <SalesLiveResultCard data={movieMonthly} type="monthly" />
            </div>
            <div className="sales-sns-area">
              <div className="sales-sns-block">
                <div className="sales-sns-header">Instagram</div>
                <div className="sales-sns-card-area">
                  <SalesSnsResultCard data={instaDaily} type="daily" />
                  <SalesSnsResultCard data={instaMonthly} type="monthly" />
                </div>
              </div>
              <div className="sales-sns-block">
                <div className="sales-sns-header">Twitter</div>
                <div className="sales-sns-card-area">
                  <SalesSnsResultCard data={twDaily} type="daily" />
                  <SalesSnsResultCard data={twMonthly} type="monthly" />
                </div>
              </div>
            </div>
          </div>
          {/* ランキング */}
          {account?.user_auth === "0" ||
          account?.user_auth === "1" ||
          account?.user_auth === "2" ||
          account?.user_auth === "3" ? (
            <div className="ranking">
              <h2>ランキング</h2>
              <span className="ranking-term">{`${rankingDate()}`}</span>
              <div className="ranking-contents-area">
                <div className="ranking-box double">
                  <span className="ranking-title">コーディネート</span>
                  <RankingCards rankingList={coordinateRank} type="contents" accountAuth={Number(account?.user_auth)} />
                </div>
                <div className="ranking-box double">
                  <span className="ranking-title">ブログ</span>
                  <RankingCards rankingList={blogRank} type="contents" accountAuth={Number(account?.user_auth)} />
                </div>
              </div>
              <div className="ranking-live-area">
                <div className="ranking-box triple">
                  <span className="ranking-title">オンラインライブ</  span>
                  <LiveRankingCards rankingList={liveRank} type="live" accountAuth={Number(account?.user_auth)} />
                </div>
                <div className="ranking-box triple">
                  <span className="ranking-title">アーカイブ</span>
                  <LiveRankingCards rankingList={archiveRank} type="archive" accountAuth={Number(account?.user_auth)} />
                </div>
                <div className="ranking-box triple">
                  <span className="ranking-title">動画</span>
                  <LiveRankingCards rankingList={movieRank} type="movie" accountAuth={Number(account?.user_auth)} />
                </div>
              </div>
              <div className="ranking-sns-area">
                <div className="ranking-box double">
                  <span className="ranking-title">Instagram</span>
                  <RankingCards rankingList={instaRank} type="sns" accountAuth={Number(account?.user_auth)} />
                </div>
                <div className="ranking-box double">
                  <span className="ranking-title">Twitter</span>
                  <RankingCards rankingList={twRank} type="sns" accountAuth={Number(account?.user_auth)} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <Footer minWidth={1250} />
      </div>
    </div>
  );
};

export default Top;

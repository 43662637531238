import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "../slices/accountSlice";
import articlesReducer from "../slices/articlesSlice";
import brandReducer from "../slices/brandSlice";
import categoryReducer from "../slices/categorySlice";
import coordinateReducer from "../slices/coordinateSlice";
import liveReducer from "../slices/liveSlice";
import liveDeliveryReducer from "../slices/liveDeliverySlice";
import movieReducer from "../slices/movieSlice";
import corporationReducer from "../slices/corporationSlice";
import fileUploadReducer from "../slices/fileUploadSlice";
import pdfOutputReducer from "../slices/pdfOutputSlice";
import productReducer from "../slices/productSlice";
import staffReducer from "../slices/staffSlice";
import messageReducer from "../slices/messageSlice";
import tagsReducer from "../slices/tagsSlice";
import shopsReducer from "../slices/shopsSlice";
import notificationsReducer from "../slices/notificationsSlice";
import reservesReducer from "../slices/reservesSlice";
import paymentsReducer from "../slices/paymentsSlice";
import rankingReducer from "../slices/rankingSlice";
import shareUrlsReducer from "../slices/shareUrlsSlice";
import analyzeCoordinateReducer from "../slices/analyzeCoordinateSlice";
import analyzeArticlesReducer from "../slices/analyzeArticleSlice";
import analyzeLiveReducer from "../slices/analyzeLiveSlice";
import analyzeMovieReducer from "../slices/analyzeMovieSlice";
import analyzeShopReducer from "../slices/analyzeShopSlice";
import analyzeBrandReducer from "../slices/analyzeBrandSlice";
import analyzeContentsReduder from "../slices/analyzeContentsSlice";
import analyzeSnsListSlice from "../slices/analyzeSnsListSlice";
import { save, load } from "redux-localstorage-simple";
import staffResultReducer from "../slices/staffResultSlice";
import topSliceReducer from "../slices/topSlice";
import loadingSliceReducer from "../slices/loadingSlice";
import testAnalysisReducer from "../slices/testAnalysis";

export default configureStore({
  reducer: {
    account: accountReducer,
    articles: articlesReducer,
    brand: brandReducer,
    pdfOutput: pdfOutputReducer,
    category: categoryReducer,
    coordinate: coordinateReducer,
    live: liveReducer,
    liveDelivery: liveDeliveryReducer,
    movie: movieReducer,
    corporation: corporationReducer,
    fileUpload: fileUploadReducer,
    product: productReducer,
    staff: staffReducer,
    message: messageReducer,
    notifications: notificationsReducer,
    payments: paymentsReducer,
    reserves: reservesReducer,
    shops: shopsReducer,
    tags: tagsReducer,
    ranking: rankingReducer,
    shareUrls: shareUrlsReducer,
    analyzeCoordinate: analyzeCoordinateReducer,
    analyzeArticles: analyzeArticlesReducer,
    analyzeLive: analyzeLiveReducer,
    analyzeMovie: analyzeMovieReducer,
    analyzeShop: analyzeShopReducer,
    analyzeBrand: analyzeBrandReducer,
    analyzeContents: analyzeContentsReduder,
    analyzeSnsList: analyzeSnsListSlice,
    staffResult: staffResultReducer,
    top: topSliceReducer,
    loading: loadingSliceReducer,
    testAnalysis: testAnalysisReducer,
  },
  preloadedState: load({ states: ["account"] }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(save({ states: ["account"] })),
});

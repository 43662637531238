import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../utils/axiosUtil";
import { actions as messageActions } from "../slices/messageSlice";


export const fetchCorporationDetail = createAsyncThunk(
  "fetchCorporationDetail",
  async () => await axios.get("corporation/detail")
);

export const updateCorporation = createAsyncThunk(
  "updateCorporation",
  async (params, {dispatch}) => {
    const corpUpdatRes = await axios.post("corporation/update?img", params )
    corpUpdatRes?.code === "0" &&
      dispatch(
        messageActions.setMessage({
          messages: [
            `${params.division === "insert" ? "登録" : "更新"}が完了しました`,
          ],
        })
      );
  }
);

export const corporationSlice = createSlice({
  name: "corporation",
  initialState: {
    corporationDetail: {},
    editModalVisible: false,
    loginApi:false
  },
  reducers: {
    openEditModal: state => ({
      ...state,
      editModalVisible: true,
    }),
    closeEditModal: state => ({
      ...state,
      editModalVisible: false,
    }),
    loginApiStatus: state => ({
      ...state,
      loginApi: false,
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchCorporationDetail.fulfilled, (state, { payload }) => ({
      ...state,
      corporationDetail: payload ?? {},
    }));
    builder.addCase(updateCorporation.fulfilled, (state, { payload }) => ({
      ...state,
      editModalVisible: false,
      loginApi:true
    }));
  },
});

export const actions = corporationSlice.actions;

export default corporationSlice.reducer;

import { useEffect } from "react";
import { useFormik } from "formik";
import { Table, Image } from "antd";
import { useLocation } from "react-router";
import { useUpdateEffect } from "../../utils/hooksUtil";
import { useDispatch, useSelector } from "react-redux";
import {
  actions,
  liveListInit,
  fetchLiveDetail,
  fetchLiveList,
  fetchLivePreparation,
  fetchLiveArchive,
  deleteLive as deleteLiveRequest,
} from "../../slices/liveSlice";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Button from "../../components/button";
import StatusBox from "../../components/statusBox";
import GenernalInput from "../../components/generalInput";
import DeleteModal from "../../components/modal/deleteModal";
import LiveSignUpModal from "../../components/modal/liveSignUpModal";
import LiveEditModal from "../../components/modal/liveEditModal";
import LivePreparationModal from "../../components/modal/livePreparationModal";
import LiveArchiveModal from "../../components/modal/liveArchiveModal";
import Icon from "@ant-design/icons";
import Icons from "../../constants/Icons";
import TableColumnWidth from "../../constants/tableColumnWidth";
import moment from "moment";
import "./style.scss";

const LIVE_HEADER_STATUS = [
  {
    status: "配信予定",
    subStatus: "(EC公開前)",
    value: "00",
    searchParam: "00",
  },
  {
    status: "配信予定",
    subStatus: "(EC公開中)",
    value: "01",
    searchParam: "01",
  },
  {
    status: "配信準備中",
    value: "02",
    searchParam: "02",
  },
  {
    status: "配信中",
    value: "03",
    searchParam: "03",
  },
];

const ARCHIVE_HEADER_STATUS = [
  {
    status: "アーカイブ",
    value: ["10", "11"],
    searchParam: 1,
  },
];

const LIVE_STATUS = [
  {
    status: "配信予定(EC公開前)",
    value: "00",
  },
  {
    status: "配信予定(EC公開中)",
    value: "01",
  },
  {
    status: "配信準備中",
    value: "02",
  },
  {
    status: "配信中",
    value: "03",
  },
  {
    status: "配信済み",
    value: "04",
  },
  {
    status: "アーカイブ",
    value: "10",
  },
  {
    status: "アーカイブ",
    value: "11",
  },
  {
    status: "アーカイブ",
    value: "12",
  },
];

const LiveList = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const account = useSelector(state => state.account);

  const {
    shops,
    brands,
    liveList,
    total,
    statusAggregate,
    deleteModalVisible,
    deleteLive = {},
    searchParams,
  } = useSelector(state => state.live);

  const formik = useFormik({
    initialValues: searchParams,
    onSubmit: values => dispatch(actions.saveSearchParam(values)),
  });

  useEffect(() => {
    dispatch(liveListInit(location.state));
    return () => dispatch(actions.clear());
  }, [dispatch]);

  useUpdateEffect(
    () => dispatch(fetchLiveList(searchParams)),
    [searchParams, dispatch]
  );

  const createOnClikSort = field => () => {
    const desc = `${field}_desc`;
    const asc = `${field}_asc`;
    const targetSort =
      formik.values.sort === asc
        ? desc
        : formik.values.sort === desc
        ? asc
        : asc;
    dispatch(actions.saveSearchParam({ sort: targetSort }));
    formik.setFieldValue("sort", targetSort);
  };
  const columns = [
    {
      title: () => (
        <>
          <span>配信CD </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("live_code")}
          >
            {formik.values.sort === "live_code_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "live_code",
      key: "live_code",
      align: "center",
      width: TableColumnWidth.live.cd,
    },
    {
      title: "サムネイル画像",
      dataIndex: "title_image",
      key: "title_image",
      render: image =>
        image ? (
          <Image src={image} width={100} height={100} />
        ) : (
          <Icon component={Icons.NoImgaeSquare1} style={{ fontSize: 100 }} />
        ),
      align: "center",
      width: TableColumnWidth.live.image,
    },
    {
      title: () => (
        <>
          <span>タイトル </span>
          <span className="sort-span" onClick={createOnClikSort("live_title")}>
            {formik.values.sort === "live_title_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: TableColumnWidth.live.title,
    },
    {
      title: () => (
        <>
          <span>配信者 </span>
          <span className="sort-span" onClick={createOnClikSort("user_name")}>
            {formik.values.sort === "user_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      align: "center",
      key: "user_name",
      width: TableColumnWidth.live.userName,
      render: live => (
        <span className="display-linebreak">
          {live?.streamer_info?.[0]?.user_name
            ? `${live?.streamer_info?.[0]?.user_name}\n${
                live?.streamer_info?.[0]?.name_nickname ? `(${live?.streamer_info?.[0]?.name_nickname})` : ""
              }`
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>店舗 </span>
          <span className="sort-span" onClick={createOnClikSort("shop_name")}>
            {formik.values.sort === "shop_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "shop_name",
      align: "center",
      width: TableColumnWidth.live.shopName,
      render: live => live?.streamer_info?.[0]?.shop_name || "-",
    },
    {
      title: () => (
        <>
          <span>ブランド </span>
          <span className="sort-span" onClick={createOnClikSort("brand_name")}>
            {formik.values.sort === "brand_name_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "brand_name",
      align: "center",
      width: TableColumnWidth.live.brandName,
      render: live => live?.streamer_info?.[0]?.brand_name || "-",
    },
    {
      title: () => (
        <>
          <span>ステータス </span>
          <span className="sort-span" onClick={createOnClikSort("status")}>
            {formik.values.sort === "status_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      align: "center",
      width: TableColumnWidth.live.status,
      render: current => (
        <span className="display-linebreak">
          {current.delete_flag == 1 ? "削除済み" : (LIVE_STATUS.find(s => s.value === current.status_flag)?.status || "-")}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>配信日時 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("start_datetime")}
          >
            {formik.values.sort === "start_datetime_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      align: "center",
      width: TableColumnWidth.live.dateTime,
      render: ({ start_datetime, end_datetime }) => (
        <span className="display-linebreak">
          {start_datetime
            ? `${moment(start_datetime).format("YYYY.MM.DD")}\n${moment(
                start_datetime
              ).format("HH:mm")}`
            : "-"}
        </span>
      ),
    },
    {
      title: () => (
        <>
          <span>公開日時 </span>
          <span
            className="sort-span"
            onClick={createOnClikSort("opening_datetime")}
          >
            {formik.values.sort === "opening_datetime_asc" ? (
              <Icon
                component={Icons.SortAscendingIcon}
                style={{ fontSize: 10 }}
              />
            ) : (
              <Icon
                component={Icons.SortDescendingIcon}
                style={{ fontSize: 10 }}
              />
            )}
          </span>
        </>
      ),
      key: "opening_time",
      dataIndex: "opening_time",
      align: "center",
      width: TableColumnWidth.live.dateTime,
      render: opening_time => (
        <span className="display-linebreak">
          {opening_time
            ? `${moment(opening_time).format("YYYY.MM.DD")}\n${moment(
                opening_time
              ).format("HH:mm")}`
            : "-"}
        </span>
      ),
    },
    {
      align: "center",
      width: TableColumnWidth.live.button,
      render: current => (
        <div style={{ display: "flex" }}>
          {current.delete_flag == 1 ? <></> : current.live_flag == 0 && current.distribution_method_flag == 0 && ["02", "03"].includes(current.status_flag) && account?.user_code === current?.streamer_info?.[0]?.user_code ? (
            <Button
              text="配信開始"
              style={{ margin: "auto", display: "block", wordBreak: "keep-all", minWidth: "72px" }}
              theme="safetyyellow"
              onClick={() =>
                dispatch(
                  fetchLivePreparation({
                    live_code: current.live_code,
                    brand_code: current.brand_code,
                    live_flag: current.live_flag,
                  })
                )
              }
            />
          ) : current.live_flag == 1 && (
            <Button
              text="アーカイブ"
              style={{ margin: "auto", display: "block", wordBreak: "keep-all", minWidth: "72px" }}
              theme="black"
              onClick={() =>
                dispatch(
                  fetchLiveArchive({
                    live_code: current.live_code,
                    brand_code: current.brand_code,
                    live_flag: current.live_flag,
                  })
                )
              }
            />
          )}
        </div>
      ),
    },
    {
      title: "編集",
      align: "center",
      width: TableColumnWidth.live.icon,
      render: current => (
        <Icon
          component={current.delete_flag == 1 || account?.user_auth == 4 ? Icons.DisableEditIcon : Icons.EditIcon}
          style={{ fontSize: 25 }}
          disabled={current.delete_flag == 1 || account?.user_auth == 4}
          onClick={() => {
            if (current.delete_flag == 1 || account?.user_auth == 4) return;
            dispatch(
              fetchLiveDetail({
                live_code: current.live_code,
                brand_code: current.brand_code,
                live_flag: current.live_flag,
              })
            );
          }}
        />
      ),
    },
    {
      title: "削除",
      align: "center",
      width: TableColumnWidth.live.icon,
      render: current => (
        <Icon
          component={current.delete_flag == 1 || account?.user_auth == 4 ? Icons.DisableRemoveIcon : Icons.RemoveIcon}
          style={{ fontSize: 25 }}
          disabled={current.delete_flag == 1 || account?.user_auth == 4}
          onClick={() => {
            if (current.delete_flag == 1 || account?.user_auth == 4) return;
            dispatch(actions.openDeleteModal(current));
          }}
        />
      ),
    },
  ];
  return (
    <div className="content-body">
      <Header />
      <div className="live-container nf-page">
        <h2>ライブ一覧</h2>
        <div className="status-box-area">
          {LIVE_HEADER_STATUS.map((s, i) => (
            <StatusBox
              status={s.status}
              subStatus={s?.subStatus}
              count={statusAggregate?.[s.value]}
              key={i}
              onClick={() => dispatch(actions.refineSearch({ status_flag: s.searchParam }))}
            />
          ))}
          {ARCHIVE_HEADER_STATUS.map((s, i) => (
            <StatusBox
              status={s.status}
              subStatus={s?.subStatus}
              count={(() => {
                let count = 0;
                for (const value of s.value) {
                  count += statusAggregate?.[value] ?? 0;
                }
                return count;
              })() || 0}
              key={i}
              onClick={() => dispatch(actions.refineSearch({ live_flag: s.searchParam }))}
            />
          ))}
        </div>
        <form className="search-area" onSubmit={formik.handleSubmit}>
          <div className="search-item">
            <GenernalInput
              label="配信CD"
              placeholder="配信CDを入力してください"
              formik={formik}
              name={"live_code"}
            />
            <GenernalInput
              label="タイトル"
              placeholder="タイトルを入力してください"
              formik={formik}
              name={"title"}
            />
            <GenernalInput
              label="配信者"
              placeholder="社員名またはサイト表示名で入力してください"
              formik={formik}
              name={"user_name"}
            />
            <GenernalInput
              label="店舗"
              mode="multiple"
              name="shop_code"
              placeholder={"店舗を選択してください"}
              formik={formik}
              options={shops}
            />
            <GenernalInput
              label="ブランド"
              mode="multiple"
              name="brand_code"
              placeholder={"ブランドを選択してください"}
              formik={formik}
              options={brands}
            />
          </div>
          <Button text="検索" type="submit" />
        </form>
        <div className="table-header">
          <h3>検索結果一覧</h3>
          {account?.user_auth != 4 && account?.live_flag == 1 && <Button
            text="新規作成"
            theme="white"
            width="wider"
            onClick={() => dispatch(actions.openSignUpModal())}
          />}
        </div>

        <Table
          columns={columns}
          dataSource={liveList}
          onChange={pagination => {
            const offset =
              pagination.current === 1
                ? 0
                : pagination.pageSize * (pagination.current - 1);
            dispatch(actions.saveOffset(offset));
            formik.setFieldValue("offset", offset);
          }}
          pagination={{
            total,
            pageSize: 10,
            current: (searchParams?.offset || 0) / 10 + 1,
            position: ["bottomCenter"],
            showQuickJumper: false,
            size: "small",
          }}
          sticky={{ offsetHeader: 60 }}
        />
      </div>
      <LiveEditModal />
      <LiveSignUpModal />
      <LivePreparationModal />
      <LiveArchiveModal />
      <DeleteModal
        modalVisible={deleteModalVisible}
        onCancel={() => dispatch(actions.closeDeleteModal())}
        item={"配信CD"}
        itemValue={deleteLive.live_code}
        onOk={() => {
          dispatch(
            deleteLiveRequest({
              division: "delete",
              live_code: deleteLive.live_code,
              live_flag: deleteLive.live_flag,
            })
          );
        }}
      />
      <Footer />
    </div>
  );
};

export default LiveList;

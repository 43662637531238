import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { actions } from "../../../slices/liveSlice";
import { actions as messageActions } from "../../../slices/messageSlice";
import Button from "../../button";
import LiveChats from "../../liveChats";
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { searchChats } from '../../../graphql/queries'
import moment from "moment";
import "./style.scss";
import Player from "../../player";
import axios from "axios";

import awsExports from "../../../aws-exports";
Amplify.configure(awsExports);

const LiveArchiveModal = () => {
  const dispatch = useDispatch();

  const [chats, setChats] = useState([]);
  const [telop, setTelop] = useState('');
  const [dateList, setDateList] = useState([]);

  const {
    archiveModalVisible,
    activeLive,
  } = useSelector(state => state.live ?? {});

  const { corporation_code } = useSelector(state => state.account);

  const onBack = () => {
    dispatch(actions.closeArchiveModal());
    formik.resetForm();
  };

  const afterClose = () => {
    dispatch(actions.closeArchiveModal());
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      title: activeLive.title,
      start_date: activeLive.start_datetime && moment(activeLive.start_datetime).format("YYYY.MM.DD HH:mm"),
      end_date: activeLive.end_datetime && moment(activeLive.end_datetime).format("YYYY.MM.DD HH:mm"),
      archive_comment_flag: activeLive.archive_comment_flag == 1,
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const {
    title,
    start_date,
    end_date,
    archive_comment_flag,
  } = formik.values;

  useEffect(() => {
    if (archiveModalVisible) {
      fetchChats();
      fetchTelop();
      if (activeLive?.archive_url) {
        // 動画の存在確認
        axios.get(activeLive?.archive_url, {})
        .then(e => {
          // m3u8ファイルから日付の一覧を取得する
          const newDateList = [];
          const lines = e.data.split('\r\n');
          for (const line of lines) {
            if (line.indexOf('#EXT-X-PROGRAM-DATE-TIME') === 0) {
              const date = line.replace('#EXT-X-PROGRAM-DATE-TIME:', '');
              newDateList.push(date);
            }
          }
          setDateList(newDateList);
        })
        .catch(e => {
          console.log("エラー", e);
          dispatch(
            messageActions.setMessage({
              btnText: "閉じる",
              messages: ["動画の読み込みに失敗しました"],
              onCloseAction: actions.closeArchiveModal(),
            })
          );
        });
      } else {
        // そもそも動画URLが存在しない場合
        dispatch(
          messageActions.setMessage({
            btnText: "閉じる",
            messages: ["動画が存在しません"],
            onCloseAction: actions.closeArchiveModal(),
          })
        );
      }
    }
  }, [archiveModalVisible]);

  const getChatsItems = async (params) => {
    try {
      const chatData = await API.graphql(graphqlOperation(searchChats, params));
      return chatData?.data?.searchChats?.items;
    } catch (err) { console.log('error getChatsItems', err); }
  };

  const fetchChats = async () => {
    setChats([]);
    if (!activeLive.archive_comment_flag) return;
    const items = await getChatsItems(fetchParams("chat", { field: "createdAt", direction: "asc" }));
    setChats(items ?? []);
  };

  const fetchTelop = async () => {
    setTelop("");
    if (!activeLive.telop_flag) return;
    const items = await getChatsItems(fetchParams("telop", { field: "createdAt", direction: "desc" }));
    setTelop(items?.[0]?.signaling_text ?? '');
  };

  const fetchParams = (signaling_flag, sort) => {
    return {
      filter: {
        live_cd: { eq: activeLive.live_code },
        corporation_cd: { eq: corporation_code },
        signaling_flag: { eq: signaling_flag },
      },
      sort,
    };
  };

  return (
    <Modal
      visible={archiveModalVisible}
      footer={null}
      closable={false}
      width={archive_comment_flag ? 1000 : 800}
      style={{ minWidth: archive_comment_flag ? 1000 : 800 }}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
    >
      <div className="live-archive" style={archive_comment_flag ? {} : { width: "650px", marginLeft: "auto", marginRight: "auto" }}>
        <div className="title-area">
          {title}
        </div>
        <div className="date-area">
          配信日時：{start_date} ~ {end_date}
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: archive_comment_flag ? undefined : "650px", marginLeft: "auto", marginRight: "auto" }}>
          <div className="video-area">
            <Player url={activeLive?.archive_url}></Player>
            {telop && <marquee scrollamount="10" className="telop">{telop}</marquee>}
          </div>
          {archive_comment_flag && <LiveChats
            streamers={activeLive?.streamer_info}
            chats={chats}
            dateList={dateList}
            infoVisible={false}
            style={{ margin: 0, marginLeft: "10px" }}
            sycophancy={true}
          />}
        </div>
      </div>
      <div className="action-container">
        <Button text="閉じる" theme="white" onClick={onBack} />
      </div>
    </Modal>
  );
};

export default LiveArchiveModal;
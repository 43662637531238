import { useState, useCallback } from "react";
import { handlerFormikFieldChange } from "../../utils/fnUtil";
import "./style.scss";

const MemoTextArea = ({
  formik,
  name,
  disabled = false,
  placeholder,
  className,
  error,
  scrollQuery,
  ...restProps
}) => {

  const [value, setValue] = useState((formik?.values && formik?.values?.[name]) ?? "");
  const [touch, setTouch] = useState(false);

  const addScrollEvent = () => {
    scrollQuery && document?.querySelector(scrollQuery)?.addEventListener('scroll', isScrollToggle, { passive: true });
  };

  const removeScrollEvent = () => {
    scrollQuery && document?.querySelector(scrollQuery)?.removeEventListener('scroll', isScrollToggle, { passive: true });
  };

  const isScrollToggle = useCallback(() => {
    handlerFormikFieldChange(formik, name, value || "");
    removeScrollEvent();
  }, []);

  return (
    <div className="nf-input-wrapper">
      <textarea
        placeholder={placeholder}
        spellCheck={false}
        autoComplete={"false"}
        value={value}
        disabled={disabled}
        onChange={(e) => {
          setTouch(true);
          setValue(e.target.value);
          addScrollEvent();
        }}
        onBlur={e => {
          setTouch(true);
          handlerFormikFieldChange(formik, name, e?.target?.value || "");
          removeScrollEvent();
        }}
        style={{ width: "100%", marginTop: "8px", "background-color": "#ffffff", border: "1px solid #bdbdbd" }}
        {...restProps}
      />
      {error && touch && (
        <span className="nf-input-error">{error(value)}</span>
      )}
    </div>
  );
};

export default MemoTextArea;

import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Modal, DatePicker, Image } from "antd";
import { actions as productActions } from "../../../slices/productSlice";
import {
  swapArray,
  spliceArray,
  replaceArray,
  createTimeOptions,
  handlerFormikFieldChange,
} from "../../../utils/fnUtil";
import {
  actions,
  fetchTags,
  fetchTagCategory,
  deleteLive,
  updateMovie,
} from "../../../slices/movieSlice";
import Icon from "@ant-design/icons";
import Icons from "../../../constants/Icons";
import Button from "../../button";
import MovieProgressBar from "../../progressBar/movie";
import BaseUpload from "../../baseUpload";
import BaseMovieUpload from "../../baseUpload/movie";
import GeneralInput from "../../generalInput";
import MemoInput from "../../generalInput/memoInput";
import MemoTextArea from "../../generalInput/memoTextArea";
import SelectModal from "../selectModal";
import DeleteModal from "../deleteModal";
import ProductSearchModal from "../productSearchModal";
import DraggableContainer from "../liveSignUpModal/DraggableContainer";
import Yup from "../../../utils/yupUtil";
import moment from "moment";
import "./style.scss";

const LiveEditModal = () => {
  const dispatch = useDispatch();

  const account = useSelector(state => state.account);

  const [activeIndex, setActiveIndex] = useState(0);

  const [selectTagModalVisible, setSelectTagModalVisible] = useState(false);

  const [productSearchModalVisible, setProductSearchModalVisible] =
    useState(false);

  const {
    editModalVisible,
    editDeleteModalVisible,
    tags = [],
    categories = [],
    activeLive,
  } = useSelector(state => state.movie ?? {});

  const formik = useFormik({
    initialValues: {
      live_code: activeLive.live_code,
      streamer_info: activeLive.streamer_info,
      title: activeLive.title,
      description: activeLive.description,
      list_img_url: activeLive.list_img_url,
      detail_img_url: activeLive.detailed_img_url,
      live_flag: activeLive.live_flag,
      live_file_name: activeLive.live_file_name,
      movie_data: null,
      opening_date: activeLive.opening_time && moment(activeLive.opening_time),
      opening_hour: activeLive.opening_time && moment(activeLive.opening_time).format("HH"),
      opening_minute: activeLive.opening_time && moment(activeLive.opening_time).format("mm"),
      product: replaceArray(Array(30).fill(""), 0, activeLive?.product?.map(p => ({ ...p, color_name: null, size_name: null  })) ?? []),
      tag_code: activeLive.tags ?? [],
      status_flag: activeLive.status_flag,
      tag_category_code: null,
      preview: activeLive.movie_url,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      streamer_info: Yup.array()
        .of(
          Yup.object().shape({
            display_order: Yup.string().nullable().selected(),
          })
        ),
      title: Yup.string().required().max(30),
      list_img_url: Yup.string().nullable().required(),
      detail_img_url: Yup.string().nullable().required(),
      opening_date: Yup.string()
        .nullable()
        .test(
          "opening_date",
          "日時を選択してください",
          (value, testContext) =>
            value && testContext.parent.opening_hour && testContext.parent.opening_minute
        ),
      movie_data: Yup.string().nullable()
        .test(
          "movie_data",
          "動画を選択してください",
          (value, testContext) =>
            value || testContext.parent.preview
        ),
    }),
    onSubmit: values => {
      const {
        opening_date,
        opening_hour,
        opening_minute,
        tag_code,
        product,
        status_flag,
        movie_data,
        ...rest
      } = values;

      const params = {
        ...rest,
        division: "update",
        tags: tag_code.map(t => t.tag_code),
        products: product.filter(p => p),
        opening_time: `${opening_date.format("YYYY-MM-DD")} ${opening_hour}:${opening_minute}:00`,
        token: account.token,
        request_from: "2",
        operator_code: account.user_code,
      };

      const formData = new FormData();
      if (movie_data) {
        formData.append("upload_file", movie_data);
      }
      formData.append("jsonValue", new Blob([JSON.stringify(params)], { type: "application/json" }));

      dispatch(updateMovie(formData));
    },
  });

  const {
    tag_category_code,
    tag_code,
    list_img_url,
    detail_img_url,
    live_file_name,
    opening_date,
    product,
    description,
    streamer_info,
    preview,
    status_flag,
  } = formik.values;

  const isAuth = true;
  const beforePublic = ["20"].includes(status_flag);

  const handleFileChange = (url, name) => handlerFormikFieldChange(formik, name, url);

  useEffect(() => {
    tag_category_code &&
      dispatch(
        fetchTags({
          tag_category_code,
          brand_code: activeLive?.streamer_info?.[0].brand_code,
          sort: "tag_name_asc",
          count: 0,
        })
      );
  }, [tag_category_code, activeLive, dispatch]);

  useEffect(() => {
    editModalVisible && dispatch(productActions.saveBrand(activeLive?.streamer_info?.[0].brand_code));
    editModalVisible &&
      dispatch(
        fetchTagCategory({
          brand_code: activeLive?.streamer_info?.[0].brand_code,
          sort: "tag_category_code_asc",
          count: 0,
        })
      );
  }, [activeLive, editModalVisible, dispatch]);

  const onBack = () => {
    dispatch(actions.closeEditModal());
    formik.resetForm();
  };

  const afterClose = () => {
    dispatch(actions.clearActiveLive());
    formik.resetForm();
  };

  const numberSelect = (start, end) => {
    const list = [];
    for (let i = start; i <= end; i++) {
      list.push({ label: `${i}`, value: `${i}` });
    }
    return list;
  };

  const createInfoArea = (data, index) => {
    return (
      <div key={index + 1}>
        <div className="text-bold normal-label">
          出演者{index + 1}{index === 0 ? "（主催者）" : ""}
        </div>
        <div className="streamer-detail">
          <span>ブランド：{data.brand_name}</span>
          <span>店舗：{data.shop_name}</span>
          <span>出演者：{data.user_name}</span>
          <span style={{ alignSelf: 'center' }}>売上分配率：{data.distribution_rate}%</span>
          <GeneralInput
            label="表示優先順"
            disabled={!isAuth}
            options={numberSelect(1, 100)}
            styleType="inline-grey"
            mode={null}
            arrayName={'streamer_info'}
            arrayIndex={index}
            name={'display_order'}
            extraOnChange={(_, v) => {
            }}
            formik={formik}
          />
        </div>
      </div>
    );
  };

  const progressStatus = {
    "20": "0",
    "21": "1",
  }[activeLive?.status_flag];

  return (
    <Modal
      visible={editModalVisible}
      footer={null}
      closable={false}
      width={1000}
      style={{ minWidth: 1000 }}
      destroyOnClose={true}
      afterClose={afterClose}
      onCancel={onBack}
    >
      <MovieProgressBar activeProgress={progressStatus} />
      <div className="live-edit">
        <div className="live-edit-area">
          <span className="text-title text-bold">動画編集</span>
          <span className="nf-text-required-style">　*は必須項目です</span>
          <div className="text-bold" style={{ marginTop: '10px', marginBottom: '10px' }}>
            配信CD {activeLive.live_code}
          </div>
          <div className="text-bold text-required">出演者情報</div>
          <div className="info-area">
            {streamer_info?.map(createInfoArea)}
          </div>

          <div className="title-area">
            <div className="text-bold text-required">タイトル</div>
            <MemoInput
              placeholder="タイトルを入力してください"
              disabled={!isAuth}
              styleType="block-grey-normal"
              name="title"
              formik={formik}
              scrollQuery=".ant-modal-body"
              error={v => !v ? "必須項目です" : v.length > 30 ? "30文字以内で入力してください" : ""}
            />
          </div>

          <div className="description-area">
            <span className="text-bold">説明</span>
            <MemoTextArea
              placeholder="説明を入力してください"
              disabled={!isAuth}
              name="description"
              formik={formik}
              scrollQuery=".ant-modal-body"
            />
          </div>

          <div className="image-area">
            <span className="text-bold text-required">サムネイル画像</span>
            <span className="text-tip">
              ※画像を選択してください
            </span>
            <div className="image-select-area">
              <div>
                <span className="validation-error" style={{ position: "absolute", left: "130px" }}>
                  {formik.touched.list_img_url && formik.errors.list_img_url}
                </span>
                <div className="image-select">
                  {list_img_url && isAuth && (
                    <Icon
                      component={Icons.CloseIcon}
                      className="close-icon"
                      onClick={e => {
                        handlerFormikFieldChange(formik, "list_img_url", "");
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {list_img_url ? (
                    <Image src={list_img_url} width={150} height={200} preview={false} />
                  ) : (
                    <BaseUpload onChange={url => handleFileChange(url, "list_img_url")}>
                      <Button text="画像を追加" theme="white" />
                    </BaseUpload>
                  )}
                </div>
                <div className="image-select-label">一覧</div>
              </div>
              <div>
                <span className="validation-error" style={{ position: "absolute", left: "320px" }}>
                  {formik.touched.detail_img_url && formik.errors.detail_img_url}
                </span>
                <div className="image-select">
                  {detail_img_url && isAuth && (
                    <Icon
                      component={Icons.CloseIcon}
                      className="close-icon"
                      onClick={e => {
                        handlerFormikFieldChange(formik, "detail_img_url", "");
                        e.stopPropagation();
                      }}
                    />
                  )}
                  {detail_img_url ? (
                    <Image src={detail_img_url} width={150} height={200} preview={false} />
                  ) : (
                    <BaseUpload onChange={url => handleFileChange(url, "detail_img_url")}>
                      <Button text="画像を追加" theme="white" />
                    </BaseUpload>
                  )}
                </div>
                <div className="image-select-label">詳細</div>
              </div>
            </div>
          </div>

          <div className="delivery-public-area">
            <span className="text-bold text-required">公開設定</span>
            <div className="public-date-select">
              <span className="text-required">動画</span>
              <>
                <BaseMovieUpload
                  onChange={(file, name) => {
                    handleFileChange(null, "preview");
                    handleFileChange(file, "movie_data");
                    handlerFormikFieldChange(formik, "live_file_name", name);

                    const fileURL = URL.createObjectURL(file);
                    setTimeout(() => handlerFormikFieldChange(formik, "preview", fileURL));
                  }}
                  onClick={() => formik.setFieldTouched("movie_data", true)}
                  disabled={!isAuth}
                >
                  <Button
                    text="動画を変更"
                    theme="white"
                    style={{ marginLeft: "45px", marginTop: "8px", marginBottom: "8px" }}
                    disabled={!isAuth}
                  />
                </BaseMovieUpload>
                {live_file_name}
                <span className="validation-error">
                  {formik.touched.movie_data && formik.errors.movie_data}
                </span>
              </>
            </div>
            {preview && <div className="public-date-select video-area">
              <video id="local-video" controls>
                <source src={preview} />
              </video>
            </div>}
            <div className="public-date-select">
              <span className="validation-error" style={{ position: "absolute", top: "-5px", left: "315px" }}>
                {(formik.touched.opening_date ||
                  formik.touched.opening_hour ||
                  formik.touched.opening_minute) &&
                  formik.errors.opening_date}
              </span>
              <span className="text-required">公開日時</span>
              <DatePicker
                format={"YYYY.MM.DD"}
                value={opening_date}
                suffixIcon={<span style={{ paddingLeft: 10 }}></span>}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                onChange={value =>
                  handlerFormikFieldChange(formik, "opening_date", value)
                }
                disabled={!isAuth}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                options={createTimeOptions(24)}
                name={"opening_hour"}
                formik={formik}
                textAfter="時"
                placeholder="時"
                disabled={!isAuth}
              />
              <GeneralInput
                mode={null}
                styleType="block-grey-normal"
                options={createTimeOptions(60)}
                name={"opening_minute"}
                formik={formik}
                textAfter="分"
                placeholder="分"
                disabled={!isAuth}
              />
            </div>
          </div>

          <div>
            <span className="text-bold">商品情報</span>
            <span className="text-tip">
              <span className="text-subtip">
                30枚まで登録可、ドラッグで入れ替え可。
              </span>
            </span>
            <span className="validation-error error-images">
              {formik.touched.product && formik.errors.product}
            </span>
          </div>
          <DraggableContainer
            items={product}
            isProduct={true}
            onClick={index => {
              setProductSearchModalVisible(true);
              setActiveIndex(index);
            }}
            onCancle={(e, index) => {
              handlerFormikFieldChange(formik, "product", spliceArray(product, index, null));
              e.stopPropagation();
            }}
            onExchange={(dragIndex, dropIndex) => {
              handlerFormikFieldChange(formik, "product", swapArray(product, dragIndex, dropIndex));
            }}
            contentDisable={!isAuth || !beforePublic}
            editable={isAuth && beforePublic}
          />

          <div class="tag-area">
            <div className="tag-title">
              <span className="text-bold">タグ情報</span>
              <span className="text-tip">
                <span className="text-subtip">
                  20個まで登録可。
                </span>
              </span>
            </div>
            <>
              <GeneralInput
                label="カテゴリ"
                placeholder="選択してください"
                styleType="block-grey-normal"
                labelTextAlign="left"
                options={categories}
                mode={null}
                name={"tag_category_code"}
                formik={formik}
                disabled={!isAuth}
              />
              <div>
                <span className="text-tag">タグ</span>
                <Button
                  disabled={!tag_category_code || !isAuth}
                  text="タグを選択"
                  theme="white"
                  onClick={() => setSelectTagModalVisible(true)}
                />
                <span className="validation-error">
                  {formik.touched.tag_code && formik.errors.tag_code}
                </span>
              </div>
            </>
            <div className="tags-container">
              {tag_code.map((t, i) => (
                <div className="tag" key={i}>
                  {t.tag_name}
                  {isAuth && <Icon
                    className="close-icon"
                    component={Icons.CloseIcon}
                    onClick={() => {
                      handlerFormikFieldChange(
                        formik,
                        "tag_code",
                        tag_code.filter(i => i.tag_code !== t.tag_code)
                      );
                    }}
                  />}
                </div>
              ))}
            </div>
          </div>

          <div className="action-container">
            <Button text="戻る" theme="white" onClick={onBack} />
            {isAuth && <Button text="更新" onClick={formik.handleSubmit} disabled={!formik.isValid} />}
            {isAuth && <Button text="動画を削除する" theme="link" onClick={() => dispatch(actions.openEditDeleteModal())} />}
          </div>
        </div>

        <SelectModal
          checkBoxName={`${activeLive.live_code}-tags}`}
          modalVisible={selectTagModalVisible}
          onCancel={() => setSelectTagModalVisible(false)}
          onOk={value => {
            handlerFormikFieldChange(
              formik,
              "tag_code",
              [
                ...tag_code,
                ...tags.filter(
                  t =>
                    value.includes(t.value) &&
                    !tag_code.some(tag => t.value === tag.tag_code)
                ),
              ].filter(
                tag =>
                  !tags.map(t => t.value).includes(tag.tag_code) ||
                  value.includes(tag.tag_code)
              )
            );
            setSelectTagModalVisible(false);
          }}
          title={"タグを選択してください"}
          value={tag_code.map(t => t.tag_code)}
          options={tags}
          max={20}
        />
      </div>
      <ProductSearchModal
        group={true}
        visible={productSearchModalVisible}
        onCancel={() => setProductSearchModalVisible(false)}
        onOk={selectedProduct => {
          handlerFormikFieldChange(
            formik,
            "product",
            spliceArray(product, activeIndex, selectedProduct)
          );
          setProductSearchModalVisible(false);
        }}
      />
      <DeleteModal
        modalVisible={editDeleteModalVisible}
        onCancel={() => dispatch(actions.closeEditDeleteModal())}
        item={"配信CD"}
        itemValue={activeLive.live_code}
        onOk={() => {
          dispatch(
            deleteLive({
              division: "delete",
              live_code: activeLive.live_code,
              live_flag: activeLive.live_flag,
            })
          );
        }}
      />
    </Modal>
  );
};

export default LiveEditModal;

import DraggableItem from "./DraggableItem";

const DraggableContainer = ({
  items,
  onClick,
  onExchange,
  onCancle,
  contentDisable,
  editable = true,
  isProduct,
}) => {
  return (
    <div className="images-container">
      {items.map((image, index) => (
        <DraggableItem
          key={index}
          image={image}
          index={index}
          onClick={onClick}
          onExchange={onExchange}
          onCancle={onCancle}
          contentDisable={contentDisable}
          isProduct={isProduct}
          editable={editable}
        />
      ))}
    </div>
  );
};

export default DraggableContainer;
